<div class="font-bold text-left mb-4 ml-11">
  {{ title }}
</div>

<div
  *ngFor="let item of items"
  class="text-left py-3 border-b border-grayExtraLight"
>
  <span
    (click)="toggleTag(item)"
    class="cursor-pointer inline-flex items-center"
  >
    <input
      type="radio"
      [checked]="item.checked"
      class="cursor-pointer focus:ring-primary h-4 w-4 text-primary border-grayLight rounded mx-2.5"
    />

    <label
      class="cursor-pointer flex justify-between flex-row text-left text-grayMedium line-clamp-1 hover:text-primary"
      [innerHTML]="item.name"
    ></label>
  </span>
</div>
