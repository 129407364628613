<nav
  class="block w-full lg:bg-none h-20 lg:h-auto relative z-20"
  [ngClass]="{
    'lg:bg-white lg:border-t lg:border-[#C0D0E4] lg:h-[71px]':
      readerState.selectedBibles.bibleAbbrs.length > 1 ||
      readerState.ebcState.showEbc,
    'nav-grad-mobile': !readerState.ebcState.showEbc,
    'nav-grad-desktop lg:h-20':
      readerState.selectedBibles.bibleAbbrs.length === 1 &&
      !readerState.ebcState.showEbc
  }"
>
  <!-- <div
    class="absolute lg:hidden -top-4 h-4 left-0 right-0 nav-grad-mobile"
  ></div> -->
  <div
    class="flex justify-between h-full pb-2 items-end align-top"
    [ngClass]="{
      'max-w-screen-lg mx-auto':
        readerState.selectedBibles.bibleAbbrs.length === 1 &&
        !readerState.ebcState.showEbc,
      'bg-transparent': audioState.mode !== 'off'
    }"
  >
    <!-- Previous chapter  -->
    <div class="z-50">
      <a
        [ngClass]="{
          'text-grayDark': columns[0]?.chapter?.previous?.id,
          'text-grayLight pointer-events-none':
            !columns[0]?.chapter?.previous?.id
        }"
        class="hover:text-primary pl-3"
        [routerLink]="
          '/ROUTES.bible/' +
            readerState.selectedBibles?.bibleAbbrs +
            '/' +
            columns[0]?.chapter?.previous?.id | localize
        "
        (click)="
          chapterNavigationEvent(
            'previous_chapter',
            columns[0]?.chapter?.previous?.id
          )
        "
      >
        <ibep-prev-svg></ibep-prev-svg>
      </a>
    </div>
    <!-- Audio player  -->
    <div
      class="grid grid-cols-1 lg:grid-cols-{{ columns.length }} w-100vw z-10"
      [ngClass]="{
        'mb-1 lg:mb-[75px] absolute bottom-0 border-r border-grayExtraLight':
          columns.length > 1 || readerState.ebcState.showEbc,
        'lg:w-[66.6vw] xl:w-[75vw]': readerState.ebcState.showEbc
      }"
      *ngIf="showAudioPlayer"
    >
      <div
        class="flex lg:flex justify-center w-full col-start-{{
          src.colIndex + 1
        }} col-span-1"
        [ngClass]="{
          'px-12': columns.length > 1 || readerState.ebcState.showEbc,
          hidden: activeColumn !== src.abbreviation
        }"
        *ngFor="let src of audioSources; let i = index"
      >
        <ng-container *ngIf="src.src">
          <!-- audio mode enabled -->
          <div
            class="flex flex-col items-center justify-center w-full lg:max-w-screen-sm"
          >
            <div
              class="w-[150px] bg-none flex justify-center relative rounded-t-xl"
            >
              <div class="absolute -top-10 h-10 w-[150px] nav-grad"></div>
              <div
                class="absolute -left-10 w-10 h-14 nav-grad-left rounded-tl-xl"
              ></div>
              <div
                class="absolute -right-10 w-10 h-14 nav-grad-right rounded-tr-xl"
              ></div>
              <!-- 10sec back button  -->
              <button
                *ngIf="
                  audioState.mode !== 'off' && audioState.activePlayer === i
                "
                (click)="rewindAudio(i)"
              >
                <div
                  class="w-[51px] flex justify-center items-center text-[#62648C] hover:text-primary cursor-pointer bg-white"
                >
                  <svg
                    version="1.1"
                    width="25px"
                    height="28px"
                    viewBox="0 0 25.0 28.0"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <defs>
                      <clipPath id="i0">
                        <path
                          d="M1440,0 L1440,1024 L0,1024 L0,0 L1440,0 Z"
                        ></path>
                      </clipPath>
                      <clipPath id="i1">
                        <path
                          d="M3.65600586,0 L3.65600586,8.91723633 L2.17895508,8.91723633 L2.17895508,1.78222656 L0,2.52685547 L0,1.27563477 L3.46679688,0 L3.65600586,0 Z"
                        ></path>
                      </clipPath>
                      <clipPath id="i2">
                        <path
                          d="M2.90527344,0 C3.87776693,0 4.60306803,0.311279297 5.08117676,0.933837891 C5.55928548,1.55639648 5.80444336,2.48819987 5.81665039,3.72924805 L5.81665039,5.26733398 C5.81665039,6.54500326 5.57759603,7.50834147 5.0994873,8.15734863 C4.62137858,8.80635579 3.89404297,9.13085938 2.91748047,9.13085938 C1.95719401,9.13085938 1.23392741,8.81449382 0.747680664,8.1817627 C0.261433919,7.54903158 0.0122070312,6.61214193 0,5.37109375 L0,3.83300781 C0,2.55533854 0.239054362,1.59708659 0.717163086,0.958251953 C1.19527181,0.319417318 1.92464193,0 2.90527344,0 Z M2.90527344,1.19018555 C2.4210612,1.19018555 2.06502279,1.37430827 1.8371582,1.74255371 C1.60929362,2.11079915 1.48925781,2.68554688 1.47705078,3.46679688 L1.47705078,5.48095703 C1.47705078,6.31103516 1.59301758,6.92952474 1.82495117,7.33642578 C2.05688477,7.74332682 2.4210612,7.94677734 2.91748047,7.94677734 C3.39355469,7.94677734 3.74552409,7.75858561 3.97338867,7.38220215 C4.20125326,7.00581868 4.32128906,6.41682943 4.33349609,5.61523438 L4.33349609,3.60717773 C4.33349609,2.7730306 4.2195638,2.16166178 3.99169922,1.77307129 C3.76383464,1.38448079 3.40169271,1.19018555 2.90527344,1.19018555 Z"
                        ></path>
                      </clipPath>
                    </defs>
                    <g transform="translate(-656.0 -948.0)">
                      <g clip-path="url(#i0)">
                        <g transform="translate(657.125 948.875)">
                          <g transform="translate(0.0 9.3925)">
                            <path
                              d="M9.975,16.23 C4.3625,15.59625 0,10.835 0,5.0525 C0,3.235 0.43125,1.5175 1.19625,0"
                              stroke="currentColor"
                              stroke-width="1.25"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-dasharray=" 2.5 2.5"
                            ></path>
                          </g>
                          <g transform="translate(8.75 3.125)">
                            <path
                              d="M0,0 L2.5,0 C8.71375,0 13.75,5.105 13.75,11.31875 C13.75,17.11 9.3725,21.88125 3.74375,22.5"
                              stroke="currentColor"
                              stroke-width="1.25"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </g>
                          <g transform="translate(8.75 0.0)">
                            <path
                              d="M4.375,0 L0,3.125 L4.375,6.25"
                              stroke="currentColor"
                              stroke-width="1.25"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </g>
                          <g transform="translate(5.0 10.3662109375)">
                            <g transform="translate(0.0 0.091552734375)">
                              <g clip-path="url(#i1)">
                                <polygon
                                  points="0,0 3.65600586,0 3.65600586,8.91723633 0,8.91723633 0,0"
                                  stroke="none"
                                  fill="currentColor"
                                ></polygon>
                              </g>
                            </g>
                            <g transform="translate(5.469970703125 0.0)">
                              <g clip-path="url(#i2)">
                                <polygon
                                  points="0,0 5.81665039,0 5.81665039,9.13085938 0,9.13085938 0,0"
                                  stroke="none"
                                  fill="currentColor"
                                ></polygon>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
              </button>
              <!-- pause button  -->
              <div class="bg-white pt-1 mb-1">
                <!-- Play / Pause button  -->
                <button (click)="isPlayerOn(i) ? pauseAudio(i) : startAudio(i)">
                  <a
                    class="flex justify-center items-center text-grayMedium hover:text-primary cursor-pointer border-[#C0D0E4] border-[4px] rounded-full w-12 h-12 bg-white"
                  >
                    <ibep-pause-svg
                      *ngIf="isPlayerOn(i)"
                      svgStyles="w-6 h-6"
                    ></ibep-pause-svg>
                    <ibep-audio-fill-svg
                      *ngIf="isPlayerOff(i)"
                      svgStyles="w-5 h-5"
                    ></ibep-audio-fill-svg>
                  </a>
                </button>
              </div>

              <!-- It was made to prevent NotAllowedError for some devices https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/play -->
              <div #playBtnTemplate (click)="startAudio(i)"></div>

              <!-- playback speed  -->
              <div
                *ngIf="
                  audioState.mode !== 'off' && audioState.activePlayer === i
                "
                class="w-[51px] flex justify-center items-center text-[#62648C] hover:text-primary cursor-pointer bg-white"
              >
                <button (click)="changePlaybackRate(i)">
                  <ng-container
                    *ngFor="let rate of playbackRateOptions; let rateI = index"
                  >
                    <span *ngIf="audioState.playbackRate?.index === rateI">
                      {{ rate | number : '1.1-1' }}x
                    </span>
                  </ng-container>
                </button>
              </div>
            </div>
            <!-- time bar  -->
            <div
              *ngIf="audioState.mode !== 'off' && audioState.activePlayer === i"
              class="w-full bg-white lg:ml-0 relative text-grayLight h-4 flex items-center"
              [ngClass]="{ 'lg:w-100vw max-w-screen-sm': columns.length === 1 }"
            >
              <div
                class="relative mr-2 lg:absolute lg:-top-0.5 text-2xs lg:-left-9 bg-white"
                [ngClass]="{ ' ': columns.length === 1 }"
              >
                {{ src.currentTime }}
              </div>
              <input
                type="range"
                min="0"
                max="1000"
                (change)="changeProgress($event.target.value, i)"
                [value]="src.progress"
                class="w-full h-1 bg-secondary progress"
              />
              <div
                class="relative ml-2 lg:absolute lg:-right-9 lg:-top-0.5 text-2xs bg-white"
              >
                {{ src.duration }}
              </div>
            </div>
          </div>
        </ng-container>
        <!-- no access registered  -->
        <ng-container
          *ngIf="
            src.hasAudio && src.lockedIcon === 'registered' && !isAuthenticated
          "
        >
          <a
            class="relative text-[#C0D0E4] cursor-not-allowed pl-[10px] pt-[5px] mb-1 border-[#C0D0E4] border-[4px] rounded-full w-12 h-12 block bg-white"
            (click)="openSignup()"
          >
            <span
              class="absolute -top-1 -right-1 w-4 h-4 ml-1 rounded-full text-primary bg-highlightText border border-grayExtraLight px-[3px]"
              ><ibep-lock-svg
                svgStyles="w-2 h-2 absolute top-[3px] right-[3px]"
              ></ibep-lock-svg
            ></span>
            <ibep-audio-fill-svg svgStyles="w-5 h-5"></ibep-audio-fill-svg>
          </a>
        </ng-container>
        <!-- no access premium  -->
        <ng-container
          *ngIf="src.hasAudio && src.lockedIcon === 'premium' && !isPremium"
        >
          <a
            class="relative text-[#C0D0E4] pl-[10px] pt-[5px] mb-1 border-[#C0D0E4] border-[4px] rounded-full w-12 h-12 block bg-white cursor-not-allowed"
            (click)="openBecomePremium()"
          >
            <span
              class="absolute top-1 -right-7 text-secondary text-[9px] bg-white uppercase font-bold px-[2px]"
            >
              <span>{{ 'Premium' | translate }}</span></span
            >

            <ibep-audio-fill-svg svgStyles="w-5 h-5"></ibep-audio-fill-svg>
          </a>
        </ng-container>
      </div>
    </div>
    <!-- Next chapter  -->
    <div class="z-50">
      <a
        [ngClass]="{
          'text-grayDark': columns[0]?.chapter?.next?.id,
          'text-grayLight pointer-events-none': !columns[0]?.chapter?.next?.id
        }"
        class="text-grayDark hover:text-primary pr-2"
        [routerLink]="
          '/ROUTES.bible/' +
            readerState.selectedBibles?.bibleAbbrs +
            '/' +
            columns[0]?.chapter?.next?.id | localize
        "
        (click)="
          chapterNavigationEvent('next_chapter', columns[0]?.chapter?.next?.id)
        "
      >
        <ibep-next-svg></ibep-next-svg>
      </a>
    </div>
  </div>
</nav>

<!-- the audio bible element  -->
<ng-container *ngFor="let item of audioSources">
  <audio #stream src="{{ item.src }}">
    <p>{{ 'YourBrowserDoesNot*' | translate }}</p>
  </audio>
</ng-container>
