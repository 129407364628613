import { Pipe, PipeTransform } from '@angular/core';

/**
 * This pipe sanitezes HTML
 */
@Pipe({
  name: 'ignoreNotPublished',
})
export class IgnoreNotPublishedPipe implements PipeTransform {
  /**
   * Takes the array of episodes and filters out not published items.
   * @param array
   */
  transform(array: any[]): any[] {
    return array.filter((i) => i.published);
  }
}
