import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'ibep-inline-note-passage',
  templateUrl: './inline-note-passage.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InlineNotePassageComponent implements OnInit {
  @Input() props: {
    text: string;
    id: string;
    type: string;
    icon: string;
    biblepassage: any;
    isBrowser: boolean;
    index?: number;
    saveQeryCountMode?: boolean;
  };

  @Output() increaseByOnePassagesCounter = new EventEmitter();

  public passageId: string;
  public bibleAbbr: string;

  public maxPreloadLimit: number;

  ngOnInit(): void {
    this.maxPreloadLimit = this.props.isBrowser ? 10 : 0;

    if (this.props.saveQeryCountMode) {
      this.increaseByOnePassagesCounter.emit();
    }

    if (this.props.biblepassage) {
      this.bibleAbbr = this.props.biblepassage.bible_translation;
      this.passageId = `${this.props.biblepassage.from_book}.${this.props.biblepassage.from_chapter}.${this.props.biblepassage.from_verse}-${this.props.biblepassage.from_book}.${this.props.biblepassage.from_chapter}.${this.props.biblepassage.to_verse}`;
    }
  }
}
