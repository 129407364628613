<div class="absolute top-0 left-0 right-0 bottom-0">
  <div
    *ngIf="!signUpProps.success"
    class="relative h-4/5 lg:h-full"
    [ngClass]="{
      'lg:grid grid-cols-2':
        config?.accountSettings?.registrationSideContent?.title ||
        config?.accountSettings?.registrationSideContent?.description,
      'lg:mt-6':
        !config?.accountSettings?.registrationSideContent?.title &&
        !config?.accountSettings?.registrationSideContent?.description
    }"
  >
    <!-- left side  -->
    <div
      [ngClass]="{ 'hidden lg:block': mobileTab === 'form' }"
      class="h-full lg:pl-4 lg:pr-8"
      *ngIf="
        config?.accountSettings?.registrationSideContent?.title ||
        config?.accountSettings?.registrationSideContent?.description
      "
    >
      <div class="text-left pb-44 lg:pb-0">
        <h1
          class="font-bold text-3xl leading-10 mt-12 lg:mt-6 mb-4"
          *ngIf="config?.accountSettings?.registrationSideContent?.title"
        >
          {{ config?.accountSettings?.registrationSideContent?.title }}
        </h1>
        <ibep-paragraph
          *ngIf="config.accountSettings?.registrationSideContent?.description"
          [html]="config.accountSettings?.registrationSideContent?.description"
        ></ibep-paragraph>
      </div>
      <div
        class="fixed lg:relative bottom-0 lg:bottom-0 left-0 right-0 mt-6 px-4 bg-white"
      >
        <a [routerLink]="" class="lg:hidden" (click)="mobileTab = 'form'">
          <ibep-button
            [buttonProps]="{
              pushGtm: true,
              content: 'CreateYourFree*' | translate,
              style: 'secondary',
              size: 'l',
              fullWidth: true
            }"
            class="w-full pb-4"
          >
            <b class="font-bold px-2 text-center w-full">{{
              'CreateYourFree*' | translate
            }}</b>
          </ibep-button>
        </a>
        <div class="py-4 lg:text-left">
          <a
            [routerLink]="[]"
            [queryParams]="{ view: 'signIn' }"
            class="font-medium text-secondary hover:text-primary"
          >
            {{ 'AlreadyGotAnAccount*' | translate }}
          </a>
        </div>
      </div>
    </div>
    <!-- right side  -->
    <div
      class="lg:bg-primary lg:bg-opacity-10 rounded-xl px-1 pb-16 lg:p-1"
      [ngClass]="{ 'hidden lg:block': mobileTab === 'usp' }"
    >
      <div
        class="bg-primary bg-opacity-10 lg:bg-white lg:bg-opacity-100 rounded-t-xl"
        *ngIf="
          errors ||
          (form.invalid &&
            (email.dirty || email.touched) &&
            (password.dirty || password.touched) &&
            (firstName.dirty || firstName.touched) &&
            (lastName.dirty || lastName.touched) &&
            (optinEmail?.dirty || optinEmail?.touched) &&
            (agreeTerms?.dirty || agreeTerms?.touched))
        "
      >
        <div class="px-2 py-4">
          <div class="text-left text-xs flex gap-2">
            <div class="text-red-500">
              <ibep-exclamation-svg
                svgStyles="w-4 h-4"
                class="inline"
              ></ibep-exclamation-svg>
            </div>
            <div class="inner-html-has-link">
              <h4
                class="font-bold mb-1"
                [innerHTML]="'AlmostReadyBut*' | translate"
              ></h4>
              <p
                *ngIf="!errors"
                [innerHTML]="'PleaseCheckTheHighlighted*' | translate"
              ></p>
              <p *ngIf="errors" [innerHTML]="errors.code | translate"></p>
            </div>
          </div>
        </div>
      </div>

      <form (ngSubmit)="onSubmit()" [formGroup]="form" class="p-7">
        <h3 class="text-2xl lg:text-lg font-bold text-left text-grayDark mb-2">
          {{ 'CreateFreeAccount' | translate }}
        </h3>
        <h5 class="text-2xs text-left text-grayLight">
          {{ 'AllFieldsAre*' | translate }}
        </h5>
        <!-- Form fields  -->
        <div class="space-y-6 mt-5">
          <!-- Firstname  -->
          <div>
            <label
              for="firstName"
              class="block text-xs font-bold text-grayMedium text-left pb-1"
            >
              {{ 'FirstName' | translate }}
            </label>
            <div class="mt-1">
              <input
                #firstNameInput
                formControlName="firstName"
                id="firstName"
                name="firstName"
                autocomplete="given-name"
                type="text"
                required
                class="appearance-none block w-full px-3 py-2 border border-grayLight rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                [ngClass]="{
                  '!border-red-500 border-b border-l-0 border-r-0 border-t-0 rounded-b-none':
                    firstName.invalid && (firstName.dirty || firstName.touched)
                }"
              />
            </div>
            <div
              *ngIf="
                document.activeElement !== firstNameInput &&
                firstName.invalid &&
                (firstName.dirty || firstName.touched)
              "
              class="text-red-500 text-left py-2 text-2xs"
            >
              <div *ngIf="firstName.errors?.['required']" class="mb-1">
                {{ 'PleaseFillInFirstname*' | translate }}
              </div>
              <div *ngIf="firstName.errors?.['maxlength']" class="mb-1">
                {{
                  'FirstNameTooLong_VAR'
                    | translate
                      : {
                          N: 30
                        }
                }}
              </div>
              <div *ngIf="firstName.errors?.['pattern']" class="mb-1">
                {{
                  'FirstNameForbiddenCharacters_VAR'
                    | translate
                      : {
                          CHARACTERS: '/ : , > < @ www http'
                        }
                }}
              </div>
            </div>
          </div>
          <!-- Lastname  -->
          <div>
            <label
              for="lastName"
              class="block text-xs font-bold text-grayMedium text-left pb-1"
            >
              {{ 'LastName' | translate }}
            </label>
            <div class="mt-1">
              <input
                #lastNameInput
                formControlName="lastName"
                id="lastName"
                name="lastName"
                type="text"
                autocomplete="family-name"
                required
                class="appearance-none block w-full px-3 py-2 border border-grayLight rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                [ngClass]="{
                  '!border-red-500 border-b border-l-0 border-r-0 border-t-0 rounded-b-none':
                    lastName.invalid && (lastName.dirty || lastName.touched)
                }"
              />
            </div>
            <div
              *ngIf="
                document.activeElement !== lastNameInput &&
                lastName.invalid &&
                (lastName.dirty || lastName.touched)
              "
              class="text-red-500 text-left py-2 text-2xs"
            >
              <div *ngIf="lastName.errors?.['required']" class="mb-1">
                {{ 'PleaseFillInLastname*' | translate }}
              </div>
              <div *ngIf="lastName.errors?.['maxlength']" class="mb-1">
                {{
                  'LastNameTooLong_VAR'
                    | translate
                      : {
                          N: 30
                        }
                }}
              </div>
              <div *ngIf="lastName.errors?.['pattern']" class="mb-1">
                {{
                  'LastNameForbiddenCharacters_VAR'
                    | translate
                      : {
                          CHARACTERS: '/ : , > < @ www http'
                        }
                }}
              </div>
            </div>
          </div>
          <!-- Email  -->
          <div>
            <label
              for="email"
              class="block text-xs font-bold text-grayMedium text-left pb-1"
            >
              {{ 'EmailAddress' | translate }}
            </label>
            <div class="mt-1">
              <input
                #emailInput
                formControlName="email"
                id="email"
                name="email"
                type="username"
                autocomplete="username"
                required
                class="appearance-none block w-full px-3 py-2 border border-grayLight rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                [ngClass]="{
                  '!border-red-500 border-b border-l-0 border-r-0 border-t-0 rounded-b-none':
                    email.invalid && (email.dirty || email.touched)
                }"
              />
            </div>
            <div
              *ngIf="
                document.activeElement !== emailInput &&
                email.invalid &&
                (email.dirty || email.touched)
              "
              class="text-red-500 text-left py-2 text-2xs"
            >
              <div *ngIf="email.errors?.['required']">
                {{ 'PleaseFillInEmail*' | translate }}
              </div>
              <div *ngIf="email.errors?.['maxlength']">
                {{
                  'EmailTooLong_VAR'
                    | translate
                      : {
                          N: 50
                        }
                }}
              </div>
              <div *ngIf="email.errors?.['email']">
                {{ 'WeDoNot*' | translate }}
              </div>
              <div *ngIf="email.errors?.['notUnique']">
                {{ 'TheEmailAddress*' | translate }}
              </div>
            </div>
          </div>
          <!-- Password  -->
          <div>
            <label
              for="password"
              class="block text-xs font-bold text-grayMedium text-left pb-1"
            >
              {{ 'Password' | translate }}
            </label>
            <div class="mt-1">
              <input
                #passwordInput
                formControlName="password"
                id="password"
                name="password"
                type="password"
                autocomplete="current-password"
                required
                class="appearance-none block w-full px-3 py-2 border border-grayLight rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                [ngClass]="{
                  '!border-red-500 border-b border-l-0 border-r-0 border-t-0 rounded-b-none':
                    password.invalid && (password.dirty || password.touched)
                }"
              />
            </div>
            <div
              *ngIf="
                document.activeElement !== passwordInput &&
                password.invalid &&
                (password.dirty || password.touched)
              "
              class="text-red-500 text-left py-2 text-2xs"
            >
              <div *ngIf="password.errors?.['required']">
                {{ 'PleaseFillInA*' | translate }}
              </div>
              <div *ngIf="password.errors?.['minlength']">
                {{
                  'PasswordTooShort_VAR'
                    | translate
                      : {
                          N: 8
                        }
                }}
              </div>
              <div *ngIf="password.errors?.['maxlength']">
                {{
                  'PasswordTooLong_VAR'
                    | translate
                      : {
                          N: 40
                        }
                }}
              </div>
              <div *ngIf="password.errors?.['pattern']">
                {{ 'ThePasswordShould*' | translate }}
              </div>
            </div>
          </div>

          <!-- Opt-in email  -->
          <div
            *ngIf="
              config?.accountSettings?.registrationFormFields?.optInForEmail
            "
          >
            <div class="mt-1 text-left">
              <input
                formControlName="optinEmail"
                id="optinEmail"
                name="optinEmail"
                type="checkbox"
                class="mr-2 w-4 h-4 cursor-pointer"
              />
              <label
                for="optinEmail"
                class="text-xs text-grayMedium text-left pb-1 cursor-pointer"
              >
                {{ 'OptinEmail' | translate }}
              </label>
            </div>
          </div>
        </div>

        <!-- Agree to terms  -->
        <div
          *ngIf="
            config?.accountSettings?.registrationFormFields?.agreeWithTerms
          "
        >
          <div class="mt-1 text-left">
            <input
              formControlName="agreeTerms"
              id="agreeTerms"
              name="agreeTerms"
              type="checkbox"
              class="mr-2 w-4 h-4 cursor-pointer"
              required
            />
            <label
              for="agreeTerms"
              class="text-xs text-grayMedium text-left pb-1 cursor-pointer"
            >
              {{ 'IAgreeTo*' | translate }}
            </label>
          </div>
          <div
            *ngIf="
              agreeTerms.invalid && (agreeTerms.dirty || agreeTerms.touched)
            "
            class="text-red-500 text-left py-2 text-2xs"
          >
            <div *ngIf="agreeTerms.errors?.['required']" class="mb-1">
              {{ 'PleaseAgreeWith*' | translate }}
            </div>
          </div>
        </div>

        <div>
          <button
            type="submit"
            [disabled]="form.invalid || form.disabled"
            class="mt-6 w-full rounded-3xl text-center inline-flex bg-secondary hover:bg-primary text-white disabled:opacity-50 py-0 h-10 disabled:cursor-not-allowed justify-center items-center"
          >
            {{ 'SignUp' | translate }}
          </button>
          <div
            class="inner-html-has-link text-2xs text-left text-grayLight mt-2"
            [innerHTML]="'AfterRegistrationYou*' | translate"
          ></div>
          <div
            class="inner-html-has-link text-2xs text-left text-grayLight mt-2"
          >
            <span
              *ngIf="
                ('recaptchaMessage' | translate) !== 'recaptchaMessage';
                else recaptchaTemplate
              "
              [innerHTML]="'recaptchaMessage' | translate"
            ></span>
          </div>
          <ng-template #recaptchaTemplate>
            This site is protected by reCAPTCHA and the Google
            <a href="https://policies.google.com/privacy">Privacy Policy</a> and
            <a href="https://policies.google.com/terms">Terms of Service</a>
            apply.
          </ng-template>
        </div>
      </form>
    </div>
  </div>
  <div *ngIf="signUpProps.success" class="w-full py-8">
    <ibep-envelope-content (closeModal)="close()">
      <h2 class="text-3xl max-w-[647px] font-bold leading-10 my-8 mx-auto">
        {{ 'ThanksNowPlease*' | translate }}
      </h2>
      <p class="max-w-[396px] mx-auto mb-12 text-grayMedium">
        {{ 'YourAccountHas*' | translate }}
      </p>
    </ibep-envelope-content>
  </div>
</div>
