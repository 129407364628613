import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { AbstractBackendService } from '@ibep/interfaces';
import { Observable } from 'rxjs';

/**
 * This service is a backendService implementation that uses the default Angular HTTP client.
 * Because the api base url is injected we can reuse this service for multiple api's.
 * Just create a new instance and provide the base url and global headers (for example an api key).
 *
 * @export
 * @class HttpBackendService
 * @implements {AbstractBackendService}
 */
@Injectable()
export class HttpBackendService implements AbstractBackendService {
  constructor(
    private readonly http: HttpClient,
    @Inject('API_BASE_URL') private _baseURL: string,
    @Optional()
    @Inject('API_GLOBAL_HEADERS')
    private _globalHeaders: { [header: string]: string | string[] }
  ) {}

  /**
   * Generic GET function to get data from the API
   *
   * @template T
   * @param {({
   *     endpoint: string;
   *     options: {
   *       params?: HttpParams;
   *       headers?: { [header: string]: string | string[] };
   *     };
   *   })} {
   *     endpoint,
   *     options,
   *   }
   * @returns {Observable<T>}
   * @memberof HttpBackendService
   */
  public get<T>({
    baseUrl,
    endpoint,
    options,
  }: {
    baseUrl?: string;
    endpoint: string;
    options?: {
      params?: any;
      headers?: { [header: string]: string | string[] };
      clearHeaders?: boolean;
    };
  }): Observable<T> {
    // take the headers from the function parameters or fall back to empty object
    let headers = options?.headers ? options.headers : {};
    // add the global headers
    headers = { ...this._globalHeaders, ...headers };

    if (options?.clearHeaders) {
      headers = {};
    }
    // add the params
    const params = options?.params ? options.params : {};

    return this.http.get<T>(`${baseUrl || this._baseURL}/${endpoint}`, {
      params,
      headers,
    });
  }

  /**
   * Generic PUT function to put data on an API.
   *
   * @template T
   * @param {({
   *     endpoint: string;
   *     options?: {
   *       body?: any;
   *       params?: HttpParams;
   *       headers?: { [header: string]: string | string[] };
   *     };
   *   })} {
   *     endpoint,
   *     options,
   *   }
   * @returns {Observable<T>}
   * @memberof HttpBackendService
   */
  public put<T>({
    baseUrl,
    endpoint,
    options,
  }: {
    baseUrl?: string;
    endpoint: string;
    options?: {
      body?: any;
      params?: HttpParams;
      headers?: { [header: string]: string | string[] };
    };
  }): Observable<T> {
    // Take the headers from the function parameters or fall back to empty object
    let headers = options?.headers ? options.headers : {};
    // add the global headers
    headers = { ...headers, ...this._globalHeaders };
    // set the params
    const params = options?.params ? options.params : {};
    // set the body
    const body = options?.body ? options.body : {};

    return this.http.put<T>(`${baseUrl || this._baseURL}/${endpoint}`, body, {
      params,
      headers,
    });
  }

  /**
   * Generic POST function, to post data on the API
   *
   * @template T
   * @param {({
   *     endpoint: string;
   *     options?: {
   *       body?: any;
   *       params?: HttpParams;
   *       headers?: { [header: string]: string | string[] };
   *       withCredentials?: boolean;
   *     };
   *   })} {
   *     endpoint,
   *     options,
   *   }
   * @returns {Observable<T>}
   * @memberof HttpBackendService
   */
  public post<T>({
    baseUrl,
    endpoint,
    options,
  }: {
    baseUrl?: string;
    endpoint: string;
    options?: {
      body?: any;
      params?: HttpParams;
      headers?: { [header: string]: string | string[] };
      withCredentials?: boolean;
    };
  }): Observable<T> {
    // Take the headers from the function parameters or fall back to empty object
    let headers = options?.headers ? options.headers : {};
    console.info(this._globalHeaders);
    // add the global headers
    headers = { ...headers, ...this._globalHeaders };
    // set the params
    const params = options?.params ? options.params : {};
    // add the body
    const body = options?.body ? options.body : {};
    const withCredentials = !!options?.withCredentials;

    return this.http.post<T>(`${baseUrl || this._baseURL}/${endpoint}`, body, {
      params,
      headers,
      withCredentials,
    });
  }

  /**
   * Generic DELETE function, to delete a resource on the API
   *
   * @template T
   * @param {({
   *     endpoint: string;
   *     options?: {
   *       body?: any;
   *       params?: HttpParams;
   *       headers?: { [header: string]: string | string[] };
   *     };
   *   })} {
   *     endpoint,
   *     options,
   *   }
   * @returns {Observable<T>}
   * @memberof HttpBackendService
   */
  public delete<T>({
    baseUrl,
    endpoint,
    options,
  }: {
    baseUrl?: string;
    endpoint: string;
    options?: {
      body?: any;
      params?: HttpParams;
      headers?: { [header: string]: string | string[] };
    };
  }): Observable<T> {
    // Take the headers from the function parameters or fall back to empty object
    let headers = options?.headers ? options.headers : {};
    // add the global headers
    headers = { ...headers, ...this._globalHeaders };
    // set the params
    const params = options?.params ? options.params : {};
    // add the body
    const body = options?.body ? options.body : {};

    return this.http.request<T>(
      'delete',
      `${baseUrl || this._baseURL}/${endpoint}`,
      {
        body,
        params,
        headers,
      }
    );
  }
}
