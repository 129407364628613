import { Injectable } from '@angular/core';
import { EnvironmentService } from '@ibep/fe/shared/core';
import { Observable } from 'rxjs';
import { EnvironmentType } from '../interfaces/environment.interface';
import { PlatformType } from '../interfaces/platform.interface';

@Injectable({
  providedIn: 'root',
  useClass: EnvironmentService,
})
export abstract class AbstractEnvironmentService {
  /**
   * @returns: "dev" | "prod" | "staging"
   */
  public abstract environment: EnvironmentType;

  /**
   * @returns: "browser" | "server" | "ios" | "android"
   */
  public abstract platform: PlatformType;

  public abstract domain: string;

  public abstract apiBaseUrl: string;

  public abstract authBaseUrl: string;

  public abstract apiGlobalHeaders: { [header: string]: string | string[] };

  public abstract webSocketUrl: string;

  public abstract websocketApiKey: string;

  public abstract windowWidth$: Observable<number>;

  public abstract isPreview: boolean;

  public abstract initRedis: boolean;
}
