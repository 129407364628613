import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'ibep-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageComponent {
  @Input() disableLightbox: boolean;
  @Input() imageGallery: boolean;
  @Input() galleryIndex: number;
  @Input() galleryTotal: number;
  @Input() inline: boolean;
  @Input() set lightBoxState(value: boolean) {
    this.lightboxIsOpen = value;
  }
  @Input() set img(value: any) {
    this.image = value;
    // if not present in data get the width and height from the url
    // works if we have a Wordpress url structure like /app/uploads/sites/5/2023/01/afdr-1-of-5-1024x681.jpg
    if (!this.image.width && !this.image.height) {
      const split = value.src.split('-');
      const lastPart = split && split[split.length - 1];
      if (lastPart.includes('x') && lastPart.includes('.')) {
        const extensionRemoved = lastPart.split('.')[0];
        this.image.width = extensionRemoved.split('x')[0];
        this.image.height = extensionRemoved.split('x')[1];
      }
    }
  }

  @Output() openSiblingLightBox = new EventEmitter<number>();

  public image: any;
  public lightboxIsOpen = false;

  public toggleLightbox() {
    this.lightboxIsOpen = !this.lightboxIsOpen;
  }

  public moveToSiblingImage(index: number): void {
    this.lightboxIsOpen = false;
    this.openSiblingLightBox.emit(index);
  }
}
