import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { BibleService } from '@ibep/fe/shared/bible';
import { BaseComponent, LanguageService } from '@ibep/fe/shared/core';
import { ConfigData } from '@ibep/fe/shared/data';
import { takeUntil } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { EbcLinksFormatterService } from '@ibep/fe/shared/core';

@Component({
  selector: 'ibep-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],

  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent extends BaseComponent implements OnChanges {
  public defaultBibleAbbr: string;

  @Input() content: string;

  public HTMLElements: any;

  public ebcRoute: string;

  constructor(
    private readonly _configData: ConfigData,
    private readonly _bibleService: BibleService,
    private readonly _localize: LocalizeRouterService,
    private readonly _translate: TranslateService,
    private readonly _languageService: LanguageService,
    private readonly _ebcLinksFormatterService: EbcLinksFormatterService
  ) {
    super();
    this.ebcRoute = `${
      this._languageService.isDefaultLanguage
        ? ''
        : `/${this._languageService.currentLanguage}`
    }/${this._translate.instant('ROUTES.ebc')}`;

    this._configData
      .getConfig()
      .pipe(takeUntil(this._destroy))
      .subscribe((config) => {
        const defaultBibleId = this._bibleService.getDefaultBibleId(
          config.brand,
          config.bibles
        )?.[0] as string;
        this.defaultBibleAbbr =
          this._bibleService.getBibleAbbrFromId(defaultBibleId);
        if (this.content) {
          this.HTMLElements = this._ebcLinksFormatterService.formatEbcLinks(
            this.content,
            this.ebcRoute,
            this.defaultBibleAbbr
          );
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.content &&
      changes.content.currentValue !== changes.content.previousValue &&
      this.defaultBibleAbbr
    ) {
      this.HTMLElements = this._ebcLinksFormatterService.formatEbcLinks(
        this.content,
        this.ebcRoute,
        this.defaultBibleAbbr
      );
    }
  }
}
