import { ConfigData } from '@ibep/fe/shared/data';
import {
  AbstractBackendService,
  AbstractStorageService,
  Config,
} from '@ibep/interfaces';
import { WEEK } from '@ibep/shared/util';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { version } from '../../../../../../../../version';

export class TranslationLoader implements TranslateLoader {
  constructor(
    private readonly configData: ConfigData,
    private readonly storage: AbstractStorageService,
    private readonly backendService: AbstractBackendService
  ) {}

  /**
   * Get the translations
   * @param lang
   * @returns
   */
  getTranslation(lang: string): Observable<Config['localization']> {
    return this.configData.getConfig().pipe(
      filter<Config>(Boolean),
      switchMap((config: Config) => {
        // if we have a language that is not the default language
        // we fetch the localization data + localized config from the backend
        if (lang !== config.brand.defaultLanguageCode) {
          const language = config.brand.availableLanguages.find(
            (l) => l.languageCode === lang
          );
          const key = `localization:${version}-${language?.languageCode}`;
          return this.storage.get(key).pipe(
            switchMap((localization) => {
              if (localization) {
                return of(localization);
              }
              return this.backendService
                .get({
                  endpoint: `brands/localization/${
                    language?.languageCode
                      ? language.languageCode
                      : config.brand.defaultLanguageCode
                  }`,
                  options: {
                    headers: {
                      'x-brand': config.brand.id,
                    },
                  },
                })
                .pipe(
                  map((res: any) => res.data),
                  tap((localization) => {
                    if (localization) {
                      this.storage.set(key, localization, WEEK);
                    }
                  })
                );
            })
          );
        }
        // if we are on the default language, we just return the default localization
        return of(config.localization);
      })
    );
  }
}
