import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'ibep-columns',
  templateUrl: './columns.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColumnsComponent {
  @Input() isMobile: boolean;
  @Input() props: { block: any };
}
