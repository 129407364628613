import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { TestamentTotal } from '@ibep/interfaces';

@Component({
  selector: 'ibep-books-picker',
  templateUrl: './books-picker.component.html',
})
export class BooksPickerComponent implements OnDestroy {
  public query: string = '';

  @Input() total: number;

  @Input() totalsByTestament?: TestamentTotal[];

  @Input() hideZeroTotalItems: boolean;

  @Output() selectBook = new EventEmitter<{
    searchScope: string;
    id: string;
    name: string;
  }>();

  onSearch(event: string): void {
    const trimmedQuery = event?.trim().toLowerCase();
    this.totalsByTestament?.forEach((testament) => {
      testament.hidden = testament.books
        .map((book) => {
          book.hidden =
            Boolean(trimmedQuery) &&
            !book.name?.toLowerCase().includes(trimmedQuery);
          return book;
        })
        .every((book) => book.hidden);
    });
  }

  onBookSelect(event: { searchScope: string; id: string; name: string }): void {
    this.selectBook.emit(event);
  }

  ngOnDestroy(): void {
    this.onSearch('');
  }
}
