<div
  #columnViewport
  [id]="'columnViewport-' + column.bible.abbreviation"
  class="relative flex-grow"
  [ngClass]="{
    'border-r border-grayExtraLight':
      readerState?.selectedBibles?.bibleAbbrs.length > 1,
    'lg:overflow-y-scroll lg:h-[73vh] no-scrollbar scroll-smooth':
      scrollSyncIsActive || readerState.ebcState.showEbc,
    'lg:h-[69.5vh]':
      readerState.ebcState.showEbc &&
      readerState?.selectedBibles?.bibleAbbrs.length === 1
  }"
  (mouseenter)="onHover(true)"
  (mouseleave)="onHover(false)"
>
  <ng-content></ng-content>
</div>
