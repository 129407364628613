import { Component, Input } from '@angular/core';

@Component({
  selector: 'bible-q',
  template: `
    <p [class]="item?.style">
      <ng-content></ng-content>
    </p>
  `,
  styleUrls: ['./q.component.scss'],
})
/**
 * https://ubsicap.github.io/usx/parastyles.html#q
 * https://github.com/americanbible/scripture-styles/blob/master/scss/modules/_poetry.scss
 */
export class QComponent {
  @Input() item: any;
}
