import {
  AbstractBackendService,
  AbstractEnvironmentService,
  AbstractLanguageService,
  AbstractStorageService,
  Brand,
} from '@ibep/interfaces';
import { BehaviorSubject, Observable, of, ReplaySubject } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { version } from '../../../../../../version';

export abstract class Store<T> {
  private _store = new BehaviorSubject<T>(<T>{});
  private _storeReady = new ReplaySubject<boolean>(1);
  protected _currentLanguage;
  protected brand: Brand = <Brand>{};
  protected remoteDataItem$: Observable<any>;
  protected remoteDataMap$: Record<string, any> = {};
  protected localData$ = this.getState();
  protected debug = false;

  // ttl can be set for each env separately
  // for current values https://docs.google.com/spreadsheets/d/1n-z9Mwh1tWwFfP20OMJYjVwhvSWoXA6wxSwtI1GndR4/edit#gid=0

  constructor(
    protected readonly storage: AbstractStorageService,
    protected readonly environment: AbstractEnvironmentService,
    protected readonly backendService: AbstractBackendService,
    protected readonly languageService: AbstractLanguageService,
    protected storeSettings: StoreSettings
  ) {
    this.initializeStore();
  }

  /**
   * Initialize the store
   *
   * @protected
   * @memberof Store
   */
  private initializeStore(): void {
    // load brand data
    if (this.storeSettings.brand$) {
      this.storeSettings.brand$.subscribe((brand) => (this.brand = brand));
    }
    // check for situations we dont want to use the store.
    if (
      this.storeSettings.doNotStoreLocally ||
      this.environment.isPreview ||
      this.environment.platform === 'server'
    ) {
      this.languageService.currentLanguage$.subscribe((lang) => {
        this._currentLanguage = lang;
      });
      this._storeReady.next(true);
      return;
    }

    // check if there is already data persisted in storage
    this.languageService.currentLanguage$
      .pipe(
        filter((lang) => !!lang),
        switchMap((lang: string) => {
          this._currentLanguage = lang;
          return this.storage.get<T>(this.getStoreKeyWithVersion());
        }),
        switchMap((data) => {
          if (!data) {
            return this.storage
              .deleteKeysByPrefix(this.storeSettings.storeKey)
              .pipe(map(() => data));
          }
          return of(data);
        })
      )
      .subscribe((data) => {
        // if there is data persisted, send it to the store subject
        if (data) {
          this._store.next(data);
          // debug mode
          if (this.debug) {
            console.info('Added persisted data from storage to store: ', data);
          }
        }
        // the store is ready to be used
        this._storeReady.next(true);
      });
  }

  /**
   * Get the current state of the store
   *
   * @protected
   * @returns {Observable<T>}
   * @memberof Store
   */
  protected getState(): Observable<T> {
    // check if the store is ready
    return this._storeReady.pipe(
      switchMap(() => {
        // debug mode
        if (this.debug) {
          console.info(
            `getState ${this.getStoreKeyWithVersion()} :`,
            this._store.value
          );
        }
        // return the store observable
        return this._store.asObservable();
      })
    );
  }

  /**
   * Set the state of the store
   *
   * @protected
   * @param {T} state
   * @memberof Store
   */
  protected setState(state: T, deepMerge = false): void {
    let newState = state;
    // make a deep merge of the old and new state of the store
    if (deepMerge) {
      newState = this.mergeDeep(this._store.value, state);
    }
    // debug mode
    if (this.debug) {
      console.info('OldState ', this._store.value);
      console.info('NewState ', newState);
    }
    // persist the store
    if (
      !this.storeSettings.doNotStoreLocally &&
      this.environment.platform !== 'server'
    ) {
      // get cache ttl for current environment, or get default value
      const ttl = this.storeSettings.storeTtl[this.environment.environment]
        ? this.storeSettings.storeTtl[this.environment.environment]
        : this.storeSettings.storeTtl.default;
      // save to storage
      this.storage.set(this.getStoreKeyWithVersion(), newState, ttl as number);
    }
    // update the store subject
    this._store.next(newState);
  }

  protected clearState() {
    this._store.next({} as T);
  }

  /**
   * Performs a deep merge of objects and returns new object. Does not modify
   * objects (immutable)
   *
   * @param {...object} objects - Objects to merge
   * @returns {object} New object with merged key/values
   * @memberof Store
   */
  protected mergeDeep(...objects: any) {
    const isObject = (obj: any) => obj && typeof obj === 'object';

    return objects.reduce((prev: any, obj: any) => {
      Object.keys(obj).forEach((key) => {
        const pVal = prev[key];
        const oVal = obj[key];

        if (Array.isArray(pVal) && Array.isArray(oVal)) {
          prev[key] = oVal;
        } else if (isObject(pVal) && isObject(oVal)) {
          prev[key] = this.mergeDeep(pVal, oVal);
        } else {
          prev[key] = oVal;
        }
      });

      return prev;
    }, {});
  }

  private getStoreKeyWithVersion(): string {
    return `${this.storeSettings.storeKey}:${version}:${this.environment.domain}:${this._currentLanguage}`;
  }
}

export interface StoreSettingsTTL {
  dev?: number;
  test?: number;
  staging?: number;
  prod?: number;
  default: number;
}

export interface StoreSettings {
  storeTtl: StoreSettingsTTL;
  storeKey: string;
  brand$: Observable<Brand>;
  doNotStoreLocally: boolean; // false will be default value, so we don't need to set it
}
