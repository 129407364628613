import { Component, Input } from '@angular/core';

@Component({
  selector: 'bible-li',
  templateUrl: './li.component.html',
  styleUrls: ['./li.component.scss'],
})
/**
 * https://ubsicap.github.io/usx/parastyles.html#li
 */
export class LiComponent {
  @Input() item: any;
  @Input() style: string;
}
