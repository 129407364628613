<ng-container *ngIf="!config?.isFallback">
  <!-- TODO: move <header></header> content to header component in components lib (no styles in container component) -->
  <header
    class="m-auto grid grid-cols-1 xl:p-0 max-h-screen"
    style="transition: max-height 0.7s"
    [ngClass]="{
      'sticky top-0 z-50 bg-white': dropdownIsActive,
      'max-h-0 ease-out': !showHeader,
      'ease-in duration-700': showHeader
    }"
  >
    <div
      class="relative px-4 lg:px-0 border-b border-grayExtraLight lg:border-0"
    >
      <!-- Menubar  -->
      <div
        *ngIf="config"
        class="max-w-container mx-auto h-12 lg:h-[72px] grid grid-cols-3 lg:flex justify-between items-center lg:px-5 xl:px-0"
      >
        <!-- Mobile: Account/login  -->
        <div class="lg:hidden flex flex-row align-middle">
          <ibep-menu-auth-mobile
            *ngIf="userAccountSystem"
            [isAuthenticated]="isAuthenticated"
            [accountTabs]="accountTabs"
            (logout)="logout()"
          ></ibep-menu-auth-mobile>
          <div *ngIf="showSearchbar.canSwitchVisibility">
            <ibep-search-svg
              (click)="toggleSearchbar()"
              class="pl-4 cursor-pointer"
              svgStyles="w-[18px] h-[18px]"
            ></ibep-search-svg>
          </div>
        </div>

        <!-- Logo  -->
        <div class="flex justify-center -mx-4 sm:mx-0">
          <ibep-logo
            logoStyles="h-8 lg:h-12"
            [imageSrc]="config?.brand?.logoUrl"
            [imageAlt]="config?.brand?.name"
            [currentLanguage]="currentLanguage"
            [isDefaultLanguage]="isDefaultLanguage"
            link=""
          ></ibep-logo>
        </div>

        <!-- Mobile: Menu  -->
        <div class="lg:hidden flex justify-end">
          <ibep-menu-mobile
            [mobileMenu]="config?.menus?.main"
            [availableLanguages]="config?.brand?.availableLanguages"
          ></ibep-menu-mobile>
        </div>

        <div class="flex gap-6 items-center">
          <!-- Desktop: Menu -->
          <ibep-menu-fly-out
            class="hidden lg:block"
            [menuItems]="config?.menus?.main"
            [colWidth]="colWidth"
            (dropdownToggle)="dropdownIsActive = $event"
            (pushGtmTag)="pushGtmTag($event)"
          ></ibep-menu-fly-out>

          <div
            class="hidden lg:block"
            *ngIf="config?.brand?.availableLanguages.length > 1"
          >
            <ibep-language-picker
              [languages]="config?.brand?.availableLanguages"
            ></ibep-language-picker>
          </div>
        </div>
      </div>
    </div>
  </header>
  <!-- Signin template -->
  <ng-template #signInTemplate>
    <ibep-login
      (formSubmit)="login($event)"
      [loginProps]="loginProps"
    ></ibep-login>
  </ng-template>
  <!-- Signup template  -->
  <ng-template #signUpTemplate>
    <ibep-signup
      (formSubmit)="signup($event)"
      (closeModal)="closeModal()"
      [signUpProps]="signUpProps"
      [configData]="config"
      [locale]="locale"
    ></ibep-signup>
  </ng-template>
  <!-- Deleted account template  -->
  <ng-template #deletedAccountTemplate>
    <ibep-deleted-account></ibep-deleted-account>
  </ng-template>
  <!-- Become premium template  -->
  <ng-template #premiumTemplate>
    <ibep-become-premium
      [content]="becomePremiumModalContent"
    ></ibep-become-premium>
  </ng-template>
  <!-- Forgot Password template  -->
  <ng-template #forgotPasswordTemplate>
    <ibep-forgot-password
      (formSubmit)="resetPassword($event)"
      [resetPasswordProps]="resetPasswordProps"
    ></ibep-forgot-password>
  </ng-template>
</ng-container>
