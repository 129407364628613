import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ibep-envelope-content',
  templateUrl: './envelope-content.component.html',
})
export class EnvelopeContentComponent implements OnInit {
  @Output() closeModal = new EventEmitter<void>();

  ngOnInit(): void {}

  public close() {
    this.closeModal.emit();
  }
}
