<div class="relative mt-2">
  <div
    class="relative border-4 border-[#2E68E3] border-opacity-10 p-3 rounded-xl mb-6 overflow-hidden transition ease-in-out delay-150 text-grayMedium font-normal"
    [class]="maxHeigth"
    [ngClass]="{ '!max-h-min': expanded }"
  >
    <ng-content></ng-content>
    <div *ngIf="!expanded" class="grad absolute bottom-0 w-full h-[80px]"></div>
  </div>
  <div
    (click)="toggleExpanded()"
    class="absolute -bottom-4 left-1/2 -translate-x-1/2 bg-white h-8 w-14 rounded-xl border border-[#E5EAF1] cursor-pointer flex justify-center text-grayLight hover:text-primary items-center shadow-lg"
  >
    <ibep-expand-svg
      *ngIf="contentType === 'text' || contentType === 'table'"
      svgStyles="w-4 h-4"
    ></ibep-expand-svg>
    <ibep-zoom-out-svg
      *ngIf="contentType === 'image' && expanded"
      svgStyles="w-4 h-4"
    ></ibep-zoom-out-svg>
    <ibep-zoom-in-svg
      *ngIf="contentType === 'image' && !expanded"
      svgStyles="w-4 h-4"
    ></ibep-zoom-in-svg>
  </div>
</div>
