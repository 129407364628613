<div class="pb-4 border-l-8 border-highlightText pl-6 my-4">
  <ibep-render-gutenberg-blocks
    ng-if="renderBlocks"
    [blocks]="content.innerBlocks"
  ></ibep-render-gutenberg-blocks>
  <ibep-paragraph
    ng-if="!renderBlocks"
    [html]="content.innerHTML"
    [formatEbcLinks]="true"
  ></ibep-paragraph>
</div>
