import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BibleService } from '@ibep/fe/shared/bible';
import {
  BibleMetaData,
  ReaderStateInterface,
  ReaderStateSelectedBiblesInterface,
} from '@ibep/fe/shared/data';
import { ModalService } from '@ibep/fe/web/core';
import {
  Bible,
  BibleContentItem,
  BibleSortingItem,
  BibleVerseItem,
  ChapterContent,
  EbcItem,
  ReadingPlanOrEpisodeItem,
  SearchCategory,
  SearchCategoryButton,
  SearchResultCategory,
  SiteContentSearchResult,
  Tag,
  TestamentTotal,
} from '@ibep/interfaces';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ibep-search-results-content',
  templateUrl: './search-results-content.component.html',
  styleUrls: ['./search-results-content.component.scss'],
})
export class SearchResultsContentComponent implements OnChanges, OnDestroy {
  public readonly CONTENT_CATEGORIES = [
    SearchCategory.POSTS,
    SearchCategory.PAGES,
    SearchCategory.READING_PLANS,
    SearchCategory.EBC,
  ];

  public readonly STEP = 10;
  public readonly MOBILE_SHORT_LIMIT = 3;
  public readonly DESKTOP_SHORT_LIMIT = 5;

  public categoryEnum = SearchCategory;
  public selectedCategories: SearchCategory[] = [];
  public scriptDirection: 'LTR' | 'RTL' = 'LTR';
  public selectedBibles: ReaderStateSelectedBiblesInterface = {
    bibleAbbrs: [],
    bibleIds: [],
  };

  public selectedBook: {
    searchScope: string;
    id: string;
    name: string;
  } = { searchScope: '', id: '', name: this._translate.instant('AllBooks') };

  public checkedVerse?: BibleVerseItem;
  public fullMode = false;
  public fullModeCategory: SearchCategory;
  public page = 1;
  public shortLimit = this.DESKTOP_SHORT_LIMIT;
  public chapter?: ChapterContent;
  public contentType = 'text';
  public bibleTotal: number;

  @Input() bibles: Bible[];
  @Input() bibleId: string;
  @Input() buttonProps: SearchCategoryButton[];
  @Input() readerState: ReaderStateInterface;
  @Input() isAuthenticated: boolean;
  @Input() isPremium: boolean;
  @Input() isMobile: boolean;
  @Input() isTablet: boolean;
  @Input() isBrowser: boolean;
  @Input() userAccountSystem: boolean;
  @Input() query: string;
  @Input() tags: Tag[];
  @Input() bibleContentItems: BibleContentItem[];
  @Input() bibleSortingItems: BibleSortingItem[];
  @Input() isBibleFiltering?: boolean;
  @Input() isSearchResultsLoading: boolean;
  @Input() isChapterLoading: boolean;

  @Input() set chapterData(value: {
    chapter?: ChapterContent;
    pathId?: string;
  }) {
    this.chapter = value?.chapter;
    if (value?.pathId && this.checkedVerse) {
      this.checkedVerse.linkInternal = this.getReaderLink(
        this.selectedBibles.bibleAbbrs[0],
        value.pathId
      );
    }
  }

  @Input() set currentBibleAbbreviation(value: string) {
    if (value && value !== this.selectedBibles.bibleAbbrs[0]) {
      // define bible ids for abbr
      this.selectedBibles.bibleIds = this.bibles
        .filter((b) => b.abbreviation === value)
        .map((b) => b.id);
      // define abbrs for ids
      this.selectedBibles.bibleAbbrs = this.bibles
        .filter((b) => this.selectedBibles.bibleIds.includes(b.id))
        .map((b) => b.abbreviation);
      // update scriptDirection
      this.scriptDirection =
        this.bibles.find((bible) => bible.abbreviation === value)?.language
          ?.scriptDirection || 'LTR';
    }
  }

  @Input() searchResultsCategories: [
    SearchResultCategory<BibleVerseItem>,
    SearchResultCategory<EbcItem>,
    SearchResultCategory<ReadingPlanOrEpisodeItem>,
    SearchResultCategory<SiteContentSearchResult>,
    SearchResultCategory<SiteContentSearchResult>
  ];

  @Output() getSearchResults = new EventEmitter<{
    categories: SearchCategory[];
    limit: number;
    page: number;
    bibleAbbr?: string;
    isBibleFiltering?: boolean;
    sortBy?: 'canonical' | 'relevance';
    contentType?: string;
    searchScope?: string;
    searchScopeId?: string;
    tagIds?: string;
  }>();
  @Output() getChapter = new EventEmitter<{
    bibleId: string;
    chapterId: string;
    verseIds: string[];
    content?: string;
    type: 'verse' | 'heading' | 'studycontent';
  }>();
  @Output() getTags = new EventEmitter<SearchCategory>();
  @Output() closeChapter = new EventEmitter<void>();
  @Output() pushClickSearch = new EventEmitter<{
    item:
      | BibleVerseItem
      | SiteContentSearchResult
      | ReadingPlanOrEpisodeItem
      | EbcItem;
    category: SearchCategory;
  }>();
  @Output() pushGtmTag = new EventEmitter<object>();

  @ViewChild('tagsPickerTemplate', { static: false })
  tagsPickerTemp: TemplateRef<any>;

  @ViewChild('translationPickerTemplate', { static: false })
  translationPickerTemp: TemplateRef<any>;

  @ViewChild('booksPickerTemplate', { static: false })
  booksPickerTemp: TemplateRef<any>;

  @ViewChild('bibleContentPickerTemplate', { static: false })
  bibleContentPickerTemp: TemplateRef<any>;

  @ViewChild('bibleSortingPickerTemplate', { static: false })
  bibleSortingPickerTemp: TemplateRef<any>;

  constructor(
    private readonly _modalService: ModalService,
    private readonly _translate: TranslateService,
    private readonly _router: Router,
    private readonly _route: ActivatedRoute,
    private readonly _bibleService: BibleService,
    private readonly _bibleMetaData: BibleMetaData
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    // set up default value for selected categories
    if (
      !this.selectedCategories.length &&
      this._route.snapshot.params?.category
    ) {
      const category =
        this._route.snapshot.params?.category || SearchCategory.ALL;

      if (this._route.snapshot.queryParams?.page) {
        this.page = Number(this._route.snapshot.queryParams?.page);
      }
      if (
        this._route.snapshot.queryParams?.searchScope &&
        this._route.snapshot.queryParams?.searchScopeId
      ) {
        this.selectedBook = {
          searchScope: this._route.snapshot.queryParams?.searchScope,
          id: this._route.snapshot.queryParams?.searchScopeId,
          name: '',
        };
      }
      if (this._route.snapshot.queryParams?.contentType) {
        this.contentType = this._route.snapshot.queryParams?.contentType;
      }

      this.isSearchResultsLoading = true;
      this.toggleFullMode(category !== SearchCategory.ALL, category);
    }

    if (changes.isMobile?.currentValue) {
      // this.step = this.isMobile && !this.isTablet ? 10 : 20; // enable when the step will be different
      this.shortLimit = this.isMobile && !this.isTablet ? 3 : 5;
    }

    if (changes.searchResultsCategories?.currentValue) {
      this.searchResultsCategories.forEach((resultCategory) => {
        // update data for pagination
        resultCategory.lastPage = Math.ceil(resultCategory.total / this.STEP);

        if (resultCategory.name === SearchCategory.BIBLE) {
          this.clearCheckedVerse(resultCategory.items as BibleVerseItem[]);
          this.patchTestamentTotals(resultCategory.totalsByTestament);

          this.bibleTotal = resultCategory.total;
        }
        resultCategory.items.forEach((item) => {
          // make matched parts of verses bold and set metainfo below the verse text
          if (resultCategory.name === SearchCategory.BIBLE) {
            this.patchBibleVerse(item as BibleVerseItem);
          } else {
            if (resultCategory.name === SearchCategory.PAGES) {
              item.linkInternal = `/${item.slug}`;
            }
            if (resultCategory.name === SearchCategory.POSTS) {
              const postsRoute = this._translate.instant('ROUTES.posts');
              item.linkInternal = `/${postsRoute}/${item.slug}`;
            }
            if (resultCategory.name === SearchCategory.READING_PLANS) {
              const readingPlansRoute = this._translate.instant(
                'ROUTES.readingplans'
              );

              if ((item as ReadingPlanOrEpisodeItem).type === 'readingPlan') {
                item.linkInternal = `/${readingPlansRoute}/${item.slug}`;
              }
              if ((item as ReadingPlanOrEpisodeItem).type === 'episode') {
                if ((item as ReadingPlanOrEpisodeItem).plans) {
                  item.linkInternal = `/${readingPlansRoute}/${
                    (item as ReadingPlanOrEpisodeItem).plans[0]?.name
                  }/${item.slug}`;
                }
              }
            }
            if (resultCategory.name === SearchCategory.EBC) {
              const ebcRoute = this._translate.instant('ROUTES.ebc');
              item.linkInternal = `/${ebcRoute}/redirect/slug/${item.slug}`;
            }
          }
        });
      });
    }

    if (changes.query?.currentValue && !changes.query?.firstChange) {
      this.page = 1;
      this.getSearchResults.emit({
        categories: this.selectedCategories,
        limit: this.fullMode ? this.STEP : this.shortLimit,
        page: this.page,
        bibleAbbr: this.selectedBibles.bibleAbbrs[0],
        searchScope: this.selectedBook.searchScope,
        searchScopeId: this.selectedBook.id,
        contentType: this.contentType,
        sortBy: this.checkedSorting?.id,
      });
    }
  }

  public toggleFullMode(fullMode: boolean, category?: SearchCategory): void {
    if (category && category !== SearchCategory.ALL) {
      this.fullModeCategory = category;
    }

    this.fullMode = fullMode;

    if (category) {
      this.selectedCategories =
        category === SearchCategory.ALL ? this.getAllCategories() : [category];
      this.buttonProps.forEach((button) => {
        button.checked = button.value === category;
      });
    }
    this.getSearchResults.emit({
      categories: this.selectedCategories,
      limit: this.fullMode ? this.STEP : this.shortLimit,
      page: this.page,
      bibleAbbr: this.selectedBibles.bibleAbbrs[0],
      searchScope: this.selectedBook.searchScope,
      searchScopeId: this.selectedBook.id,
      contentType: this.contentType,
      sortBy: this.checkedSorting?.id,
    });

    this.updateTags();
  }

  public onVerseChecked(verse: BibleVerseItem): void {
    this.checkedVerse = verse;
    this.getChapter.emit({
      bibleId: this.bibleId,
      chapterId: verse.chapterId,
      verseIds: verse.idRange,
      content: verse.content,
      type: verse.type,
    });
  }

  private patchTestamentTotals(testamentTotals?: TestamentTotal[]): void {
    this._bibleMetaData
      .getBible({ bibleId: this.bibleId })
      .subscribe((response) => {
        testamentTotals?.forEach((testamentTotal) => {
          testamentTotal.books?.forEach((book) => {
            const testament = response.data.testaments.find(
              (item) => item.abbreviation === testamentTotal.id
            );
            book.name = testament?.books?.find(
              (item) => item.id === book.id
            )?.name;

            if (!this.selectedBook?.name) {
              if (this.selectedBook?.id === testament?.abbreviation) {
                this.selectedBook.name = this._translate.instant(
                  `${testament?.abbreviation}_ABBR`
                );
              }
              if (this.selectedBook?.id === book.id) {
                this.selectedBook.name = book.name as string;
              }
            }
          });
        });
      });
  }

  public filterByBook(event: {
    searchScope: string;
    id: string;
    name: string;
  }): void {
    this.selectedBook = event;
    this.getSearchResults.emit({
      categories: this.selectedCategories,
      limit: this.fullMode ? this.STEP : this.shortLimit,
      page: this.page,
      bibleAbbr: this.selectedBibles.bibleAbbrs[0],
      isBibleFiltering: true,
      searchScope: this.selectedBook.searchScope,
      searchScopeId: this.selectedBook.id,
      contentType: this.contentType,
      sortBy: this.checkedSorting?.id,
    });

    this.closeModal();

    this._router.navigate([], {
      relativeTo: this._route,
      queryParams: {
        searchScope: this.selectedBook.searchScope,
        searchScopeId: this.selectedBook.id,
      },
      queryParamsHandling: 'merge',
    });
  }

  public filterByContent(contentType: string): void {
    this.contentType = contentType;
    this.getSearchResults.emit({
      categories: this.selectedCategories,
      limit: this.fullMode ? this.STEP : this.shortLimit,
      page: this.page,
      bibleAbbr: this.selectedBibles.bibleAbbrs[0],
      isBibleFiltering: true,
      searchScope: this.selectedBook.searchScope,
      searchScopeId: this.selectedBook.id,
      contentType: this.contentType,
      sortBy: this.checkedSorting?.id,
    });

    this._router.navigate([], {
      relativeTo: this._route,
      queryParams: {
        contentType: this.contentType,
      },
      queryParamsHandling: 'merge',
    });
  }

  public changeSorting(sortingType: string): void {
    this.getSearchResults.emit({
      categories: this.selectedCategories,
      limit: this.fullMode ? this.STEP : this.shortLimit,
      page: this.page,
      bibleAbbr: this.selectedBibles.bibleAbbrs[0],
      isBibleFiltering: true,
      searchScope: this.selectedBook.searchScope,
      searchScopeId: this.selectedBook.id,
      contentType: this.contentType,
      sortBy: sortingType as 'canonical' | 'relevance',
    });

    this._router.navigate([], {
      relativeTo: this._route,
      queryParams: {
        sortBy: sortingType,
      },
      queryParamsHandling: 'merge',
    });
  }

  private patchBibleVerse(verse: BibleVerseItem): void {
    const splittedId = verse.id.split('.');
    let verseNumber: string;
    if (verse.type === 'heading') {
      verseNumber = '';
      // couldn't be done yet without API changes

      // verseNumber =
      //   splittedId[3] === '1'
      //     ? ''
      //     : `${this._translate.instant('verse')} ${splittedId[3]}`;
    } else {
      verseNumber = splittedId[2]
        ? `${this._translate.instant('verse')} ${splittedId[2]}`
        : '';
    }

    // set full name
    verse.fullName = verse.bookNameShortLocal
      ? `${verse.bookNameShortLocal} ${splittedId[1]} ${verseNumber}`
      : '';

    // set verse range
    if (splittedId[2]?.includes('-')) {
      const range = splittedId[2]
        .split('-')
        .map((verse) => `${splittedId[0]}.${splittedId[1]}.${verse}`);
      verse.idRange = this._bibleService.getVerseRange(range);
    } else {
      verse.idRange = [verse.id];
    }

    // set internal link
    verse.linkInternal = this.getReaderLink(
      this.selectedBibles.bibleAbbrs[0],
      verse.idRange[0]
    );
  }

  private getReaderLink(bibleAbbr: string, verseId: string): string {
    const route = this._translate.instant('ROUTES.bible');
    return `/${route}/${bibleAbbr}/${verseId}`;
  }

  private clearCheckedVerse(verses: BibleVerseItem[]): void {
    verses.forEach((verse: BibleVerseItem) => {
      if (verse.checked) {
        verse.checked = false;
      }
    });
  }

  private clearHoveredVerse(verses: BibleVerseItem[]): void {
    verses.forEach((verse: BibleVerseItem) => {
      if (verse.hover) {
        verse.hover = false;
      }
    });
  }

  public closeQuickView(): void {
    this.closeChapter.emit();
    this.clearCheckedVerse(this.searchResultsCategories[0].items);
  }

  public onPageChanged(page: number): void {
    this.page = page;
    this.getSearchResults.emit({
      categories: this.selectedCategories,
      limit: this.fullMode ? this.STEP : this.shortLimit,
      page: this.page,
      searchScope: this.selectedBook.searchScope,
      searchScopeId: this.selectedBook.id,
      contentType: this.contentType,
      sortBy: this.checkedSorting?.id,
    });

    this._router.navigate([], {
      relativeTo: this._route,
      queryParams: {
        page: this.page,
      },
      queryParamsHandling: 'merge',
    });

    globalThis.scrollTo(0, 0);
  }

  public openModal(type: string): void {
    switch (type) {
      case 'translation':
        this._modalService.createModal(this.translationPickerTemp, {
          closeOnClickOutSide: true,
        });
        break;
      case 'books':
        this._modalService.createModal(this.booksPickerTemp, {
          closeOnClickOutSide: true,
        });
        break;
      case 'bible-content':
        this._modalService.createModal(this.bibleContentPickerTemp, {
          closeOnClickOutSide: true,
        });
        break;
      case 'bible-sorting':
        this._modalService.createModal(this.bibleSortingPickerTemp, {
          closeOnClickOutSide: true,
        });
        break;
      case 'tags':
        this._modalService.createModal(this.tagsPickerTemp, {
          closeOnClickOutSide: true,
        });
        break;
      default:
        break;
    }

    const sub = this._modalService.clickedOutside$.subscribe(() => {
      this._modalService.closeModal();
      sub.unsubscribe();
    });
  }

  public closeModal(): void {
    this._modalService.closeModal();
  }

  public changeSelectedBibles(event: ReaderStateSelectedBiblesInterface): void {
    this.selectedBibles = event;
    this.getSearchResults.emit({
      categories: this.selectedCategories,
      limit: this.fullMode ? this.STEP : this.shortLimit,
      page: this.page,
      bibleAbbr: this.selectedBibles.bibleAbbrs[0],
      isBibleFiltering: true,
      searchScope: this.selectedBook.searchScope,
      searchScopeId: this.selectedBook.id,
      contentType: this.contentType,
      sortBy: this.checkedSorting?.id,
    });

    this._router.navigate([], {
      relativeTo: this._route,
      queryParams: { bibleId: this.selectedBibles.bibleIds[0] },
      queryParamsHandling: 'merge',
    });
  }

  public onCategoriesChanged(event: SearchCategory): void {
    this.page = 1;
    this.toggleFullMode(event !== SearchCategory.ALL, event);
  }

  public onTagsChanged(event: number[]): void {
    this.page = 1;
    this.getSearchResults.emit({
      categories: [this.fullModeCategory],
      limit: this.fullMode ? this.STEP : this.shortLimit,
      page: this.page,
      tagIds: event.length ? event.join() : undefined,
    });
  }

  public onSearchItemCliked(
    item: BibleVerseItem | SiteContentSearchResult,
    category: SearchCategory
  ): void {
    this.pushClickSearch.emit({ item, category });
  }

  private updateTags(): void {
    if (
      this.fullMode &&
      this.CONTENT_CATEGORIES.includes(this.fullModeCategory)
    ) {
      this.getTags.emit(this.fullModeCategory);
    }
  }

  private getAllCategories(): SearchCategory[] {
    return this.buttonProps?.map((button) => button.value) || [];
  }

  public onGtmTagPush(tag: object): void {
    this.pushGtmTag.emit(tag);
  }

  public get noResults(): boolean {
    return (
      !this.isSearchResultsLoading &&
      this.searchResultsCategories.every((category) => !category.items.length)
    );
  }

  public get checkedSorting(): BibleSortingItem | undefined {
    return this.bibleSortingItems.find((item) => item.checked);
  }

  public get contentTypeTitle(): string {
    return this.bibleContentItems.find((item) => item.checked)!.name;
  }

  ngOnDestroy(): void {
    this.clearCheckedVerse(this.searchResultsCategories[0].items);
    this.clearHoveredVerse(this.searchResultsCategories[0].items);
  }
}
