import { Injectable } from '@angular/core';
import { WebSocketService } from '@ibep/fe/shared/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
  useClass: WebSocketService,
})
export abstract class AbstractWebSocketService {
  public abstract openConnection(connectionParams: string): Observable<any>;
  public abstract send(message: any, queryParams?: string): void;
  public abstract closeConnection(
    code?: number,
    reason?: string
  ): Observable<any>;
  public abstract get isConnectionOpen(): boolean;
  public abstract get message$(): Observable<any>;
}
