<div class="relative inline-block text-left">
  <!-- Button toggle  -->
  <div>
    <div
      #pickerToggle
      id="pickerToggle"
      (click)="togglePicker()"
      id="menu-button"
      aria-haspopup="true"
    >
      <ibep-button
        [buttonProps]="{
          pushGtm: true,
          content: 'toggle picker',
          size: 'm'
        }"
      >
        <ng-content select="[toggleContent]"></ng-content>
      </ibep-button>
    </div>
  </div>

  <!-- Fullscreen transparant overlay  -->
  <div
    *ngIf="pickerIsOpen"
    class="fixed w-full h-full top-0 left-0 bg-grayDark opacity-[.55] z-50"
  ></div>

  <!-- Dropdown  -->
  <div
    #picker
    *ngIf="pickerIsOpen"
    class="fixed h-full w-full max-w-lg top-0 bottom-0 left-0 lg:min-w-max lg:h-90vh lg:inset-x-0 lg:inset-y-4 lg:m-auto p-8 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none overflow-y-scroll z-[999]"
  >
    <div class="absolute left-0 top-0">
      <!-- close button  -->
      <div class="py-7 px-8 cursor-pointer" (click)="close()">
        <ibep-arrow-left-svg svgStyles="w-4 h-4"></ibep-arrow-left-svg>
      </div>
    </div>
    <!-- The picker content goes here.. -->
    <ng-content select="[pickerContent]"></ng-content>
  </div>
</div>
