import { Component, Input } from '@angular/core';

@Component({
  selector: 'ibep-arrow-left-svg',
  template: `<svg
    ibepHideTillInit
    class="h-full w-full inline m-auto"
    fill="currentColor"
    class="inline m-auto"
    [ngClass]="svgStyles"
    viewBox="0 0 16 16"
  >
    <g stroke="none" stroke-width="1" fill="currentColor">
      <g transform="translate(-17.000000, -19.000000)" fill="#040632">
        <path
          d="M22.4582813,30.7315164 C22.6411573,30.9107995 22.8695342,31 23.0979065,31 C23.3262788,31 23.5546511,30.910795 23.7375317,30.7315164 C24.1032928,30.3729412 24.1032928,29.8132272 23.7375317,29.4555475 L20.1290206,25.8953186 L30.0865107,25.8953186 C30.5887471,25.8953186 31,25.4921453 31,24.9997761 C31,24.5074069 30.5887471,24.1042337 30.0865107,24.1042337 L20.1290206,24.1042337 L23.7596838,20.5449003 C24.1254449,20.1863251 24.1254449,19.626611 23.7596838,19.2689314 C23.3939227,18.9103562 22.8229919,18.9103562 22.4581442,19.2689314 L17.2743208,24.3728516 C16.9085597,24.7314268 16.9085597,25.2911408 17.2743208,25.6488205 L22.4582813,30.7315164 Z"
        ></path>
      </g>
    </g>
  </svg>`,
  styles: [],
})
export class ArrowLeftComponent {
  @Input() svgStyles: string;
}
