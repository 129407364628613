import { Component } from '@angular/core';

@Component({
  selector: 'bible-ip',
  template: `
    <p class="ip">
      <ng-content></ng-content>
    </p>
  `,
  styles: [''],
})
/**
 * Introduction Paragraphs
 * https://ubsicap.github.io/usx/parastyles.html#ip
 * https://github.com/americanbible/scripture-styles/blob/fb495aa3a07894fc00c2566d57a66718f88ea52f/scss/modules/_introductions.scss
 */
export class IpComponent {}
