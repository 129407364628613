<div
  class="lg:flex lg:items-center mb-5 lg:h-10 bg-primary bg-opacity-5 border-t-2"
>
  <div
    *ngFor="let tab of tabs"
    class="w-full h-full lg:max-w-[180px] px-5 py-2.5 text-2xs flex justify-between items-center cursor-pointer hover:text-primary"
    (click)="onTabChanged(tab)"
    [ngClass]="{
      'bg-white border-t-2 border-grayMedium h-[42px]':
        tab.value === activeTab.value
    }"
  >
    <span>{{ tab.title }}</span>
  </div>
</div>
