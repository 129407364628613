import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeWebComponentsModule } from '@ibep/fe/web/components';
import { FeWebComponentsSharedModule } from '@ibep/fe/web/components-shared';
import { FooterContainerComponent } from './footer-container/footer-container.component';
import { HeaderContainerComponent } from './header-container/header-container.component';
import { NotificationsContainerComponent } from './notifications-container/notifications-container.component';
import { ModalContainerComponent } from './modal-container/modal-container.component';
import { SearchbarContainerComponent } from './searchbar-container/searchbar-container.component';
import { AccountTriggerBarContainerComponent } from './account-trigger-bar-container/account-trigger-bar-container.component';
import { AccountTriggerBoxContainerComponent } from './account-trigger-box-container/account-trigger-box-container.component';
import { EbcArticleContainerComponent } from './ebc-article-container/ebc-article-container.component';
import { PageLoadingMainComponent } from './page-loading-main/page-loading-main.component';

@NgModule({
  imports: [CommonModule, FeWebComponentsModule, FeWebComponentsSharedModule],
  declarations: [
    AccountTriggerBarContainerComponent,
    AccountTriggerBoxContainerComponent,
    FooterContainerComponent,
    HeaderContainerComponent,
    NotificationsContainerComponent,
    ModalContainerComponent,
    SearchbarContainerComponent,
    EbcArticleContainerComponent,
    PageLoadingMainComponent,
  ],
  exports: [
    AccountTriggerBarContainerComponent,
    AccountTriggerBoxContainerComponent,
    FooterContainerComponent,
    HeaderContainerComponent,
    NotificationsContainerComponent,
    ModalContainerComponent,
    SearchbarContainerComponent,
    EbcArticleContainerComponent,
    PageLoadingMainComponent,
  ],
})
export class FeWebComponentsContainerModule {}
