import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { WordPressBlock } from '@ibep/interfaces';

@Component({
  selector: 'ibep-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListComponent {
  public content: string = '';

  @Input() set block(value: WordPressBlock) {
    if (value) {
      this.content = value.innerBlocks?.length
        ? `<ul>${value.innerBlocks
            .map((innerBlock) => innerBlock.innerHTML)
            .join('')}</ul>`
        : value.innerHTML;
    }
  }
}
