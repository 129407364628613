<!-- Breadcrumbs  -->
<div
  *ngIf="!isMobileAppView"
  class="border-b border-grayExtraLight h-10 bg-grayExtraLight lg:bg-white mb-2 lg:mb-0"
>
  <div class="max-w-container m-auto px-6 py-3">
    <ibep-breadcrumbs
      [props]="{
        currentRouteTitle: page.title?.rendered
          ? page.title.rendered
          : page.title,
        parentRoutes: parentRoutes
      }"
    ></ibep-breadcrumbs>
  </div>
</div>
<!-- Progressbar  -->
<div class="sticky top-0 z-10">
  <ibep-scroll-progress-bar></ibep-scroll-progress-bar>
</div>
<!-- Hero -->
<ibep-hero
  [heroProps]="{
    title: page.title?.rendered ? page.title.rendered : page.title,
    author: page.author,
    authors: authors,
    publishDate: page.publishDate,
    dateFormat,
    showPublishDate: page.showPublishDate,
    tags: page.tags,
    image: page.heroImage?.large,
    sidebarActive: showSidebar,
    contentType: page.type
  }"
></ibep-hero>
<div class="px-4 xl:px-0 max-w-container mx-auto">
  <div
    class="flex w-full flex-col lg:flex-row text-grayMedium text-base leading-7"
  >
    <!-- Main Content  -->
    <div
      class="w-full py-4 lg:py-10 flex flex-row overflow-x-hidden"
      [ngClass]="{
        'lg:w-4/6': showSidebar
      }"
    >
      <div class="w-full">
        <div class="lg:pr-4">
          <!-- EBC pdf download  -->
          <div
            *ngIf="page.type === 'ebc' && page.image?.subtype === 'pdf'"
            class="mb-4 max-w-[610px] lg:min-w-[610px] mx-auto lg:pr-4"
            [ngClass]="ebcImageIsLarge ? 'lg:w-full' : 'lg:w-1/2'"
          >
            <a
              [href]="page.image.url"
              target="_blank"
              [ngClass]="{ 'pointer-events-none': noAccess }"
            >
              <ibep-button
                [buttonProps]="{
                  pushGtm: true,
                  style: 'secondary',
                  size: 'l'
                }"
              >
                <b class="text-xs ml-4">{{ 'Download' | translate }}</b>
                <ibep-pdf-svg
                  svgStyles="w-5 h-5 ml-2 mr-4 font-bold text-white"
                ></ibep-pdf-svg>
              </ibep-button>
            </a>
          </div>
          <!-- EBC image  -->
          <div
            #ebcImageContainer
            *ngIf="page.type === 'ebc' && page.image"
            class="relative float-left lg:mr-4 mb-4 group"
            [ngClass]="ebcImageIsLarge ? 'lg:w-full' : 'lg:w-1/2'"
          >
            <ibep-image-picture
              [imgStyles]="'w-full object-cover pb-2 lg:cursor-pointer'"
              (click)="ebcImageIsLarge = !ebcImageIsLarge"
              [image]="page.image"
              [containerWidth]="ebcImageContainer?.clientWidth"
            ></ibep-image-picture>
            <span
              class="absolute top-3 right-3 text-white hidden group-hover:block"
            >
              <ibep-expand-svg
                *ngIf="!ebcImageIsLarge"
                svgStyles="w-5 h-5"
              ></ibep-expand-svg>
              <ibep-contract-svg
                *ngIf="ebcImageIsLarge"
                svgStyles="w-5 h-5"
              ></ibep-contract-svg>
            </span>
          </div>

          <!-- Render the WP Gutenberg Blocks -->
          <ibep-render-gutenberg-blocks
            [blocks]="page.gblocks"
            [formatEbcLinks]="formatEbcLinks"
          ></ibep-render-gutenberg-blocks>

          <!-- EBC related passages -->
          <div
            class="max-w-[610px] lg:pr-4 mx-auto mb-2 lg:mb-6 mt-8"
            *ngIf="page.type === 'ebc' && page.bibleVersesItemPage?.length"
          >
            <h2 id="anchor1" class="text-xl font-bold my-2">
              {{ 'RelatedBiblePassages' | translate }}
            </h2>
            <ng-container *ngIf="relatedPassages">
              <a
                [routerLink]="'/' + bibleVerse?.link | localize"
                *ngFor="let bibleVerse of relatedPassages"
              >
                <span
                  class="inline-block bg-primary hover:bg-secondary text-xs text-white px-2 py-1 rounded-lg mr-2"
                >
                  {{ bibleVerse?.name }}
                </span>
              </a>
            </ng-container>
          </div>
          <ng-container
            *ngIf="
              page.type === 'ebc' &&
              page.bibleVersesItemPage?.length &&
              !relatedPassages
            "
          >
            <ibep-page-loading></ibep-page-loading>
          </ng-container>
        </div>
      </div>
    </div>

    <!-- Sidebar Content  -->
    <div
      class="w-full m-auto mb-6 lg:mb-0 lg:mt-0 py-6 lg:py-10 px-4 lg:px-7 lg:pr-28 bg-grayExtraLight lg:bg-white rounded-lg lg:rounded-none max-w-[610px]"
      *ngIf="showSidebar"
      [ngClass]="{
        'lg:w-2/6': showSidebar
      }"
    >
      <div class="lg:sticky lg:top-4">
        <!-- Sidebar Pagetree navigation  -->
        <ibep-pagetree-nav
          *ngIf="showPageTree"
          [pagetreeNav]="page.siblingAndChildPages"
          [ebcTree]="page.type === 'ebc'"
          [parentPage]="page.parentPages?.[page.parentPages?.length - 1]"
          [currentPageId]="page.id"
        ></ibep-pagetree-nav>
        <!-- Sidebar Block  -->
        <ibep-sidebar-block
          *ngIf="page.sideContentBlock?.title"
          [props]="{
            imageSrc: page.sideContentBlock?.image?.sizes?.large,
            imageAlt: page.sideContentBlock?.image?.alt,
            title: page.sideContentBlock?.title,
            content: page.sideContentBlock?.content,
            buttonLink:
              page.sideContentBlock?.linkType === 'internal'
                ? page.sideContentBlock?.linkInternal
                : page.sideContentBlock?.linkExternal,
            buttonText: page.sideContentBlock?.buttonText,
            linkType: page.sideContentBlock?.linkType
          }"
        ></ibep-sidebar-block>
        <!-- EBC keywords  -->
        <div
          class="mt-12 pb-3 mb-8 lg:mb-0"
          *ngIf="page.type === 'ebc' && page.showKeywords"
        >
          <nav>
            <h3
              class="text-lg font-bold pb-2"
              [innerHTML]="page.termAbove"
            ></h3>
            <input
              class="appearance-none block w-full px-3 py-2 mb-1 border border-grayLight rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
              [placeholder]="'SearchForAKeyword' | translate"
              #siblingInput
            />
            <ng-container
              *ngIf="siblingArticles || moreSiblingArticlesAvailable"
            >
              <ul
                class="max-h-56 text-sm overflow-y-scroll overflow-x-hidden sidebar"
              >
                <ng-container *ngFor="let sibling of siblingArticles">
                  <a
                    *ngIf="sibling.slug"
                    [routerLink]="
                      '/ROUTES.ebc/redirect/slug/' + sibling.slug | localize
                    "
                    ><li
                      class="border-b border-[#EFF7FF] py-3 text-xs flex justify-between cursor-pointer hover:text-primary"
                    >
                      <span [innerHTML]="sibling.title"></span>
                      <ibep-chevron-right-svg
                        svgStyles="w-3 h-3 font-bold text-[#C0D0E4]"
                      ></ibep-chevron-right-svg></li
                  ></a>
                  <span *ngIf="!sibling.slug">{{
                    sibling.title | translate
                  }}</span>
                </ng-container>
                <!--Infinite loading -->
                <div
                  *ngIf="moreSiblingArticlesAvailable && isBrowser"
                  class="col-span-4 text-center h-24 w-full block"
                  observeVisibility
                  [threshold]="0.001"
                  (visible)="loadMore()"
                  [isBrowser]="isBrowser"
                >
                  <div
                    class="absolute h-[300px] top-[-300px] w-full pointer-events-none"
                  ></div>
                  <ibep-page-loading></ibep-page-loading>
                </div>
              </ul>
            </ng-container>
            <ng-container *ngIf="!siblingArticles">
              <ibep-page-loading></ibep-page-loading>
            </ng-container>
          </nav>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Related Items -->
<div
  class="lg:border-t lg:border-grayLight py-8 pl-5 xl:pl-0"
  *ngIf="page.relatedItems?.length"
>
  <div class="max-w-container mx-auto">
    <ibep-block-item-grid
      [props]="{
        items: page.relatedItems,
        sectionTitle: 'RelatedItems' | translate,
        isBrowser: isBrowser,
        autoWidth: false,
        horizontalScroll: true
      }"
    ></ibep-block-item-grid>
  </div>
</div>
