import {
  ChangeDetectorRef,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ConfigLocalizedData } from '@ibep/fe/shared/data';
import {
  AnalyticsService,
  ComponentVisibilityService,
  ModalService,
  SeoService,
} from '@ibep/fe/web/core';
import {
  AbstractAuthService,
  AbstractEnvironmentService,
  Config,
  UserInfo,
} from '@ibep/interfaces';
import { switchMap, takeUntil } from 'rxjs';
import { BaseComponent, LanguageService } from '@ibep/fe/shared/core';
import { ActivatedRoute, ActivationEnd, Event, Router } from '@angular/router';
import { AccountService } from 'libs/fe/web/module-account/src/lib/services/account.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'ibep-header-container',
  templateUrl: './header-container.component.html',
})
export class HeaderContainerComponent extends BaseComponent implements OnInit {
  public config: Config;

  @ViewChild('signInTemplate', { static: false })
  signInTemp: TemplateRef<any>;

  @ViewChild('signUpTemplate', { static: false })
  signUpTemp: TemplateRef<any>;

  @ViewChild('deletedAccountTemplate', { static: false })
  deletedAccountTemp: TemplateRef<any>;

  @ViewChild('forgotPasswordTemplate', { static: false })
  forgotPasswordTemp: TemplateRef<any>;

  @ViewChild('premiumTemplate', { static: false })
  premiumTemp: TemplateRef<any>;

  public showSearchbar = { canSwitchVisibility: false, isVisible: false };

  public showHeader = true;

  public isAuthenticated = false;

  public userAccountSystem: boolean;

  public userProfile: UserInfo;

  public currentLanguage: string;
  public isDefaultLanguage: boolean;
  public locale?: string;

  public colWidth: number;

  public dropdownIsActive: boolean;

  public accountTabs = this._accountService.accountTabs;

  public becomePremiumModalContent: any;

  public loginProps = {
    formDisabled: false,
    error: {},
  };

  public signUpProps = this.getDefaultSignUpProps();

  public resetPasswordProps = {
    formDisabled: false,
    error: undefined,
    success: false,
  };

  constructor(
    private readonly _configData: ConfigLocalizedData,
    private readonly _environmentService: AbstractEnvironmentService,
    private readonly _componentVisibility: ComponentVisibilityService,
    private readonly _modalService: ModalService,
    private readonly _authService: AbstractAuthService,
    private readonly _router: Router,
    private readonly _activeRoute: ActivatedRoute,
    private readonly _analyticsService: AnalyticsService,
    private readonly _seoService: SeoService,
    private readonly _recaptchaV3Service: ReCaptchaV3Service,
    private readonly _accountService: AccountService,
    private readonly _languageService: LanguageService,
    private readonly _ref: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this._configData
      .getConfig()
      .pipe(takeUntil(this._destroy))
      .subscribe((config) => {
        this.config = config;
        this.userAccountSystem =
          config.brand?.brandUIPreferences?.userAccountSystem;

        this.becomePremiumModalContent =
          config.accountMessages?.becomePremiumModal;
        // if the config call fails, we redirect the user to healthcheck page
        if (config?.isFallback) {
          this._router.navigate(['healthcheck']);
        }
      });

    this._languageService.currentLanguage$
      .pipe(takeUntil(this._destroy))
      .subscribe((language) => {
        this.currentLanguage = language;
        this.locale = this.config.brand.availableLanguages.find(
          (lang) => lang.languageCode === language
        )?.localeCode;
        this.isDefaultLanguage = this._languageService.isDefaultLanguage;
        this._ref.detectChanges();
      });

    // calculate the width of cols in menu dropdown
    this._environmentService.windowWidth$
      .pipe(takeUntil(this._destroy))
      .subscribe((width) => {
        if (width > 1250) {
          this.colWidth = 1250 / 4;
        } else {
          this.colWidth = width / 4;
        }
      });

    this._authService.isAuthenticated$
      .pipe(takeUntil(this._destroy))
      .subscribe((isAuthenticated: boolean) => {
        this.isAuthenticated = isAuthenticated;

        if (isAuthenticated) {
          this._authService
            .getUserProfile()
            .pipe(takeUntil(this._destroy))
            .subscribe((profile: UserInfo) => {
              this.userProfile = profile;
              this.pushGtmUserId();
            });
        }
      });

    this._componentVisibility.isSearchbarVisible$
      .pipe(takeUntil(this._destroy))
      .subscribe((visibilitySettings) => {
        this.showSearchbar = visibilitySettings;
      });

    this._activeRoute.queryParams
      .pipe(takeUntil(this._destroy))
      .subscribe((queryParams) => {
        if (queryParams['view']) {
          setTimeout(() => {
            this.openModal(queryParams['view']);
          }, 0);
          // some fallback for wildcard routes
        } else if (this._router.url.includes('view')) {
          const view = this._router.url
            .split('?')[1]
            .split('&')
            .filter((part) => part.includes('view'))[0]
            .split('=')[1];
          setTimeout(() => {
            this.openModal(view);
          }, 0);
        }
      });
    // fallback for wildcard routes
    this._router.events.subscribe((event: Event) => {
      if (event instanceof ActivationEnd) {
        if (event.snapshot.component) {
          this.pushGtmUserId();
        }

        if (event.snapshot.queryParams && event.snapshot.queryParams['view']) {
          setTimeout(() => {
            this.openModal(event.snapshot.queryParams['view']);
          }, 0);
        }
      }
    });
  }

  /**
   * Open a modal
   *
   * @param {string} type
   * @memberof HeaderContainerComponent
   */
  public openModal(type: string) {
    if (type === 'signIn') {
      this._modalService.createModal(this.signInTemp, {
        fitContentHeight: true,
        closeOnClickOutSide: true,
      });
      this._seoService.setMetaTags({
        type: 'website',
        title: this.config?.metadata?.login?.title,
        description: this.config?.metadata?.login?.metaDescription,
        canonical: this._router.url,
      });
      // close modal
      const sub = this._modalService.clickedOutside$.subscribe(() => {
        this._modalService.closeModal();
        this._router.navigate([], {
          queryParams: {},
        });
        sub.unsubscribe();
      });
    }
    if (type === 'signUp') {
      this._modalService.createModal(this.signUpTemp, {
        popUpStyles: 'lg:!max-w-[818px]',
        closeOnClickOutSide: true,
      });
      this._seoService.setMetaTags({
        type: 'website',
        title: this.config.metadata.register?.title,
        description: this.config.metadata.register?.metaDescription,
        canonical: this._router.url,
      });
      // close modal
      const sub = this._modalService.clickedOutside$.subscribe(() => {
        this._modalService.closeModal();
        this._router.navigate([], {
          queryParams: {},
        });
        sub.unsubscribe();
      });
    }
    if (type === 'deletedAccount') {
      this._modalService.createModal(this.deletedAccountTemp, {
        popUpStyles: 'lg:!max-w-[818px]',
        fitContentHeight: true,
        closeOnClickOutSide: true,
      });
      this._seoService.setMetaTags({
        type: 'website',
        title: 'Deleted account',
        description:
          'Your account is deleted. You can register newly at any time.',
        canonical: this._router.url,
      });
      // close modal
      const sub = this._modalService.clickedOutside$.subscribe(() => {
        this._modalService.closeModal();
        this._router.navigate([], {
          queryParams: {},
        });
        sub.unsubscribe();
      });
    }
    if (type === 'forgotPassword') {
      this._modalService.createModal(this.forgotPasswordTemp, {
        width: '3/5',
        fitContentHeight: true,
        closeOnClickOutSide: true,
      });
      this._seoService.setMetaTags({
        type: 'website',
        title: this.config.metadata.forgotPassword?.title,
        description: this.config.metadata.forgotPassword?.metaDescription,
        canonical: this._router.url,
      });
      // close modal
      const sub = this._modalService.clickedOutside$.subscribe(() => {
        this._modalService.closeModal();
        this._router.navigate([], {
          queryParams: {},
        });
        sub.unsubscribe();
      });
    }
    if (type === 'premium') {
      this._modalService.createModal(this.premiumTemp, {
        popUpStyles: 'lg:!max-w-[818px]',
        fitContentHeight: true,
        closeOnClickOutSide: true,
      });
      // close modal
      const sub = this._modalService.clickedOutside$.subscribe(() => {
        this._modalService.closeModal();
        this._router.navigate([], {
          queryParams: {},
        });
        sub.unsubscribe();
      });
    }
  }

  public closeModal() {
    this._modalService.closeModal();
    this.signUpProps = this.getDefaultSignUpProps();
  }

  /**
   * Toggle the searchbar visibility
   *
   * @memberof HeaderContainerComponent
   */
  public toggleSearchbar(): void {
    this._componentVisibility.isSearchbarVisible = {
      ...this.showSearchbar,
      isVisible: !this.showSearchbar.isVisible,
    };
  }

  /**
   * Execute login
   *
   * @param {{ username: string; password: string }} { username, password }
   * @returns
   * @memberof HeaderContainerComponent
   */
  public login({ username, password }: { username: string; password: string }) {
    if (this.loginProps.formDisabled) {
      return;
    }
    this.loginProps.formDisabled = true;
    this._authService
      .logIn(username, password)
      .pipe(takeUntil(this._destroy))
      .subscribe({
        next: () => {
          this.loginProps.formDisabled = false;
          this._router.navigate([], {
            queryParams: {},
          });
          this._modalService.closeModal();
        },
        error: (err) => {
          this.loginProps = {
            formDisabled: false,
            error: err.error,
          };
        },
      });
  }

  /**
   * Execute signup
   *
   * @param {object} formData
   * @memberof HeaderContainerComponent
   */
  public signup(formData: any): void {
    this.signUpProps.formDisabled = true;

    this._recaptchaV3Service
      .execute('registration')
      .pipe(
        switchMap((token) => {
          return this._authService.register(formData, token);
        }),
        takeUntil(this._destroy)
      )
      .subscribe({
        next: () => {
          this.signUpProps.formDisabled = false;
          this.signUpProps.success = true;
        },
        error: (err) => {
          this.signUpProps = {
            formDisabled: false,
            error: err.error,
            success: false,
          };
          console.error(err);
        },
      });
  }

  /**
   * execute reset password
   *
   * @param {string} email
   * @memberof HeaderContainerComponent
   */
  public resetPassword(email: string): void {
    this.resetPasswordProps.formDisabled = true;

    this._recaptchaV3Service
      .execute('resetPassword')
      .pipe(
        switchMap((token) => {
          return this._authService.resetPassword(email, token);
        }),
        takeUntil(this._destroy)
      )
      .subscribe({
        next: () => {
          this.resetPasswordProps = {
            formDisabled: false,
            error: undefined,
            success: true,
          };
        },
        error: (err) => {
          this.resetPasswordProps = {
            formDisabled: false,
            error: err.error,
            success: false,
          };
        },
      });
  }

  /**
   * logout the user
   *
   * @memberof HeaderContainerComponent
   */
  public logout(): void {
    this._authService.logOut(true);
  }

  private getDefaultSignUpProps() {
    return {
      formDisabled: false,
      error: {},
      success: false,
    };
  }

  public pushGtmTag(tag: object): void {
    this._analyticsService.pushGtmTag(tag);
  }

  private pushGtmUserId(): void {
    if (this.isAuthenticated) {
      this.pushGtmTag({
        userId: this.userProfile?.userId,
      });
    }
  }
}
