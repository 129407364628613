import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

/**
 * This pipe localize date months according to the language
 */
@Pipe({
  name: 'localizeDate',
})
export class LocalizeDatePipe implements PipeTransform {
  private readonly _fullMonths = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  private readonly _shortMonths = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  constructor(private readonly _translateService: TranslateService) {}

  transform(date: string | null, format: string): string {
    // we check if the format contains at least short month letter form
    if (date && format.includes('MMM')) {
      // we check if the format contains full or short month letter form
      const isFuuMonth = format.includes('MMMM');

      const month = (isFuuMonth ? this._fullMonths : this._shortMonths).find(
        (monthItem) => date.includes(monthItem)
      )!;

      return month
        ? date.replace(
            month,
            this._translateService.instant(
              `MONTH.${isFuuMonth ? 'FULL' : 'SHORT'}.${month}`
            )
          )
        : date;
    } else {
      return date || '';
    }
  }
}
