<div>
  <h3 class="text-[24px] leading-[32px] font-bold text-grayDark">
    {{ 'Episodes' | translate }}
  </h3>

  <!-- Tabs -->
  <div
    *ngIf="
      hasFixedDates && upcomingEpisodesCount > 0 && archiveEpisodesCount > 0
    "
    class="hide-scrollbar flex gap-4 lg:gap-12 border-t border-b mt-5 text-xs"
  >
    <!--href is adding tab query param with value to the current url -->
    <a
      [routerLink]="'/ROUTES.readingplans/' + planSlug | localize"
      [queryParams]="{ tab: 'upcoming' }"
      class="py-3 min-w-fit border-b-[3px]"
      [ngClass]="
        tab == 'upcoming'
          ? 'border-grayDark'
          : 'border-white hover:text-primary cursor-pointer'
      "
      >{{ 'Upcoming' | translate }}</a
    >
    <a
      [routerLink]="'/ROUTES.readingplans/' + planSlug | localize"
      [queryParams]="{ tab: 'archive' }"
      class="py-3 min-w-fit border-b-[3px]"
      [ngClass]="
        tab == 'archive'
          ? 'border-grayDark'
          : 'border-white hover:text-primary cursor-pointer'
      "
      >{{ 'Archive' | translate }}</a
    >
  </div>

  <!-- Episodes  -->
  <div class="mt-5 flex-row">
    <div
      *ngFor="let ep of filteredEpisodes | ignoreNotPublished"
      class="flex flex-row justify-between"
      style="box-shadow: 0px -1px 0px rgb(239 247 255)"
    >
      <span>
        <span class="text-[14px] leading-[40px]" [innerHTML]="ep.title"></span>
        <span *ngIf="hasFixedDates && ep.formattedFixedDate">
          {{ ' | ' }}
          {{
            ep.formattedFixedDate
              | date : dateFormat
              | localizeDate : dateFormat
          }}
        </span>
      </span>
      <a
        class="whitespace-nowrap ml-1 text-grayLight hover:text-primary text-[14px] leading-[40px]"
        [routerLink]="
          '/ROUTES.readingplans/' + planSlug + '/' + ep.slug | localize
        "
      >
        <span>
          {{
            ep.minutes_to_read
              ? ('VAR_minutesToRead'
                | translate
                  : {
                      N: ep.minutes_to_read
                    })
              : ('readEpisode' | translate)
          }}
          <ibep-chevron-right-svg
            class="text-grayLight mr-2"
            svgStyles="w-3 h-3 font-bold"
          ></ibep-chevron-right-svg>
        </span>
      </a>
    </div>
  </div>
</div>
