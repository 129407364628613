import { Injectable } from '@angular/core';
import { HammerGestureConfig } from '@angular/platform-browser';
import { DIRECTION_HORIZONTAL } from '@egjs/hammerjs';

@Injectable()
export class HorizontalHammerConfig extends HammerGestureConfig {
  overrides = {
    swipe: { direction: DIRECTION_HORIZONTAL },
  };
}
