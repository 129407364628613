import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'ibep-biblepassage',
  templateUrl: './biblepassage.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BiblepassageComponent implements OnInit {
  @Input() props: {
    biblepassage: any;
    passageId: string;
    bibleAbbr: string;
    dontAddMarginBottom: boolean;
  };

  @Input() showBibleTitle = true;

  public dontAddMarginBottom: boolean;
  public passageId: string;
  public bibleAbbr: string;

  ngOnInit(): void {
    if (this.props.biblepassage) {
      this.bibleAbbr = this.props.biblepassage.bible_translation;
      this.passageId = `${this.props.biblepassage.from_book}.${this.props.biblepassage.from_chapter}.${this.props.biblepassage.from_verse}-${this.props.biblepassage.from_book}.${this.props.biblepassage.from_chapter}.${this.props.biblepassage.to_verse}`;
    }
    if (this.props.passageId && this.props.bibleAbbr) {
      this.bibleAbbr = this.props.bibleAbbr;
      this.passageId = this.props.passageId;
    }
  }
}
