import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { BaseComponent, WINDOW } from '@ibep/fe/shared/core';
import { AbstractEnvironmentService, ScreenSize } from '@ibep/interfaces';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'ibep-render-gutenberg-blocks',
  templateUrl: './render-gutenberg-blocks.component.html',
})
export class RenderGutenbergBlocksComponent extends BaseComponent {
  public isMobile: boolean;
  public isBrowser: boolean;
  public inlineNotePassageCounter: number = 0;

  @Input() blocks: any;
  @Input() noMaxWidth = false;
  @Input() formatEbcLinks = false;

  @Output() closeModal = new EventEmitter<boolean>();

  constructor(
    private readonly _environmentService: AbstractEnvironmentService,
    @Inject(WINDOW) private _window: Window,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    super();
    this.isBrowser = isPlatformBrowser(this.platformId);
    this._environmentService.windowWidth$
      .pipe(takeUntil(this._destroy))
      .subscribe((width) => {
        this.isMobile = width <= ScreenSize.lg;
      });
  }

  public syncInlineNotesCount() {
    this.inlineNotePassageCounter++;
  }

  public closeEbcModal(arg) {
    this.closeModal.emit(true);
  }

  public redirect(block) {
    if (this.isBrowser) {
      this._window.location.href = block.url;
    }
  }

  public setSiblingLightBox(event: number, innerBlocks: any[]): void {
    innerBlocks.forEach((innerBlock, i) => {
      innerBlock.lightBox = i === event;
    });
  }
}
