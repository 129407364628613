import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ibep-ebc-page',
  templateUrl: './ebc-page.component.html',
})
export class EbcPageComponent {
  @Input() page: any;
  @Output() modalClosed = new EventEmitter<boolean>();
  @Output() shared = new EventEmitter<string>();

  public tab: 'default' | 'share' = 'default';
  public copied = false;

  public closeModal(prevenRedirect?: boolean): void {
    if (prevenRedirect) {
      this.modalClosed.next(true);
    } else {
      this.modalClosed.next(false);
    }
  }

  public share(platform: string): void {
    this.shared.emit(platform);
  }
}
