import { Injectable } from '@angular/core';
import {
  AbstractAuthService,
  AbstractBackendService,
  AbstractEnvironmentService,
  AbstractLanguageService,
  AbstractStorageService,
  AbstractWebSocketService,
  SearchSuggestion,
  SearchSuggestionsWebSocketMessage,
  SearchSuggestionsWebSocketResponse,
} from '@ibep/interfaces';
import { HOUR } from '@ibep/shared/util';
import { BehaviorSubject, take, tap } from 'rxjs';
import { ConfigData } from './config.data';
import { Store, StoreSettings, StoreSettingsTTL } from './store';

@Injectable({
  providedIn: 'root',
})
export class SearchSuggestionsData extends Store<{
  [key: string]: { [key: string]: SearchSuggestion[] };
}> {
  private _apiKey: string;
  private _token: string;
  private _bibleId: string;

  public suggestions$ = new BehaviorSubject<SearchSuggestion[]>([]);

  constructor(
    storage: AbstractStorageService,
    environment: AbstractEnvironmentService,
    backendService: AbstractBackendService,
    languageService: AbstractLanguageService,
    config: ConfigData,
    private readonly wsService: AbstractWebSocketService,
    private readonly authService: AbstractAuthService
  ) {
    super(storage, environment, backendService, languageService, {
      storeTtl: { default: 1 * HOUR } as StoreSettingsTTL,
      storeKey: 'searchSuggestions',
      brand$: config.getBrand(),
    } as StoreSettings);
    this._apiKey = this.environment.websocketApiKey;
    this.authService.getTokenDetails().subscribe((data) => {
      this._token = `Bearer ${data?.idToken || 'anonymous'}`;
    });
  }

  connectSuggestionsWs(): void {
    const queryParams = this.queryParams;
    this.wsService.openConnection(queryParams);
    this.wsService.message$
      .pipe(
        tap((message: SearchSuggestionsWebSocketResponse) => {
          this.setState(
            {
              [this._bibleId]: { [message.query]: message.suggestions },
            },
            true
          );
          return message;
        })
      )
      .subscribe((message: SearchSuggestionsWebSocketResponse) => {
        this.suggestions$.next(message.suggestions);
      });
  }

  sendMessage(message: SearchSuggestionsWebSocketMessage): void {
    if (this._bibleId !== message.bibleId) {
      this._bibleId = message.bibleId;
    }

    this.localData$.pipe(take(1)).subscribe((localData) => {
      // check if there is local data available
      if (localData?.[message.bibleId]?.[message.query]) {
        this.suggestions$.next(localData[message.bibleId][message.query]);
        return;
      }
      const queryParams = this.queryParams;
      this.wsService.send(message, queryParams);
    });
  }

  closeSuggestionsWs(code?: number, reason?: string): void {
    this.wsService.closeConnection(code, reason);
  }

  get isWebSocketOpen(): boolean {
    return this.wsService.isConnectionOpen;
  }

  get queryParams(): string {
    return `Authorization=${this._token}&apiKey=${this._apiKey}&brandId=${this.brand.id}`;
  }
}
