import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { BibleService } from '@ibep/fe/shared/bible';
import { BaseComponent } from '@ibep/fe/shared/core';
import {
  BibleMetaData,
  ReaderStateInterface,
  UserNote,
} from '@ibep/fe/shared/data';
import { CustomStyling } from '@ibep/interfaces';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'ibep-render-bible-content',
  templateUrl: './render-bible-content.component.html',
})
export class RenderBibleContentComponent
  extends BaseComponent
  implements OnChanges
{
  public customStyling: CustomStyling;

  // TODO: add interfaces
  @Input() bibleContent: any;
  @Input() bibleAbbr: string;
  @Input() scriptDirection: 'LTR' | 'RTL' = 'LTR';
  @Input() scriptLanguage: string | undefined;
  @Input() readerState: ReaderStateInterface;
  @Input() hovered?: any;
  @Input() selected?: any;
  @Input() highlighted?: any;
  @Input() flashed?: any;
  @Input() studyContent?: any;
  @Input() userHighlights: any;
  @Input() userNotes: any;
  @Input() isBrowser: boolean;
  @Input() isLoading = false;
  @Input() isAuthenticated?: boolean;
  @Input() createdNoteVerseOrgIds: string[];
  @Input() isSearchPage: boolean;
  @Input() isHighlightsPage: boolean;

  @Output() pushGtmTag = new EventEmitter<object>();
  @Output() userNoteDeleted = new EventEmitter<string>();
  @Output() userNoteUpdated = new EventEmitter<UserNote>();

  constructor(
    private readonly _bibleService: BibleService,
    private readonly _bibleMetaData: BibleMetaData
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['bibleAbbr']?.currentValue &&
      changes['bibleAbbr']?.currentValue !== changes['bibleAbbr']?.previousValue
    ) {
      const bibleId = this._bibleService.getBibleIdFromAbbr(
        changes['bibleAbbr']?.currentValue
      );
      this._bibleMetaData
        .getBible({ bibleId })
        .pipe(takeUntil(this._destroy))
        .subscribe((response) => {
          this.customStyling = response?.data?.customStyling;
        });
    }
  }

  public onGtmTagPush(tag: object): void {
    this.pushGtmTag.emit(tag);
  }

  public deleteUserNote(noteId: string) {
    this.userNoteDeleted.emit(noteId);
  }

  public updateUserNote(note: UserNote) {
    this.userNoteUpdated.emit(note);
  }
}
