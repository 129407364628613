import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ReaderStateSelectedBiblesInterface } from '@ibep/fe/shared/data';
import { Bible, BibleMetadata, ColumnsLimit } from '@ibep/interfaces';
@Component({
  selector: 'ibep-bible-picker',
  templateUrl: './bible-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BiblePickerComponent {
  @Input() selectedChapter: string;
  @Input() selectedChapterLongName: string;
  @Input() selectedBibles: ReaderStateSelectedBiblesInterface;
  @Input() bibleMetadata: BibleMetadata;
  @Input() bibles: Bible[];
  @Input() maxActiveColumns: ColumnsLimit;
  @Input() isAuthenticated: boolean;
  @Input() isPremium: boolean;
  @Input() isBrowser: boolean;
  @Input() isBibleReaderPage: boolean;
  @Input() userAccountSystem: boolean;
  @Input() activeTab: 'chapters' | 'stories' | 'translations' = 'chapters';

  @Output() modalClosed = new EventEmitter<boolean>();
  @Output() pushGtmTag = new EventEmitter<object>();

  public closeModal() {
    this.modalClosed.emit(true);
  }

  public onGtmTagPush(tag: object): void {
    this.pushGtmTag.emit(tag);
  }

  public changeSelectedBibles(bibles: ReaderStateSelectedBiblesInterface) {
    this.selectedBibles = { ...bibles };
  }
}
