<div
  class="relative flex items-end justify-center min-h-screen text-center sm:block sm:p-0"
  [ngClass]="{ 'pt-4 px-4 pb-20': !modalOptions.noPadding }"
  (click)="clickedOutside($event)"
>
  <div
    class="modal-layover fixed inset-0 bg-grayDark opacity-[.55] transition-opacity z-50"
  ></div>

  <div
    id="full-pop-up"
    class="
      fixed
      h-100vh
      w-full
      lg:max-w-lg
      top-0
      bottom-0
      left-0
      lg:inset-x-0
      lg:m-auto
      lg:rounded-md
      lg:shadow-lg
      bg-white
      ring-1
      ring-black
      ring-opacity-5
      focus:outline-none
      !overflow-y-auto
      z-50
      {{
      modalOptions.fitContentHeight ? 'lg:h-fit lg:max-h-[90vh]' : 'lg:h-90vh'
    }}
      {{ modalOptions.popUpStyles }}
    "
    [ngClass]="{ 'p-4': !modalOptions.noPadding }"
  >
    <!-- close button  -->
    <div
      *ngIf="!modalOptions.hideCloseArrow"
      class="
        absolute
        left-0
        top-0
        px-4
        py-4
        cursor-pointer
        z-50
        {{ modalOptions.arrowStyles }}
      "
    >
      <a (click)="close()"
        ><ibep-arrow-left-svg svgStyles="w-4 h-4"></ibep-arrow-left-svg
      ></a>
    </div>
    <!-- modal content  -->
    <div
      id="modalViewport"
      class="modal overflow-y-scroll h-full relative scroll-smooth"
      [ngClass]="{ 'lg:h-fit': modalOptions.fitContentHeight }"
    >
      <ng-content></ng-content>
    </div>
  </div>
</div>
