import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ReaderStateInterface } from '@ibep/fe/shared/data';
import { arrayMove } from '@ibep/shared/util';

@Component({
  selector: 'ibep-bible-info',
  templateUrl: './bible-info.component.html',
  styles: ['.info { box-shadow: 0 10px 10px -11px rgb(0 0 0 / 15%); }'],
})
export class BibleInfoComponent {
  @Input() name = '';
  @Input() abbr = '';
  @Input() readerState: ReaderStateInterface = <ReaderStateInterface>{};
  @Input() activeColumn: string;
  @Output() isActiveColumn = new EventEmitter<string>();
  @Output() redirect = new EventEmitter<any>();

  /**
   * Close this bible column
   *
   * @param {string} closeAbbr
   * @memberof BibleInfoComponent
   */
  public close(closeAbbr: string): void {
    const abbrToRemove = closeAbbr || this.abbr;
    const newAbbrs = this.readerState.selectedBibles.bibleAbbrs.filter(
      (abbr: string) => abbr !== abbrToRemove
    );
    this.redirect.emit({
      abbrs: newAbbrs,
      chapter: this.readerState.selectedChapter,
    });
  }

  /**
   * Move this bible column to the left
   *
   * @memberof BibleInfoComponent
   */
  public moveLeft(): void {
    const index = this.readerState.selectedBibles.bibleAbbrs.indexOf(this.abbr);
    const newAbbrs = arrayMove(
      this.readerState.selectedBibles.bibleAbbrs as [],
      index,
      index - 1
    );
    this.redirect.emit({
      abbrs: newAbbrs,
      chapter: this.readerState.selectedChapter,
    });
  }

  /**
   * Move this bible column to the right
   *
   * @memberof BibleInfoComponent
   */
  public moveRight(): void {
    const index = this.readerState.selectedBibles.bibleAbbrs.indexOf(this.abbr);
    const newAbbrs = arrayMove(
      this.readerState.selectedBibles.bibleAbbrs as [],
      index,
      index + 1
    );
    this.redirect.emit({
      abbrs: newAbbrs,
      chapter: this.readerState.selectedChapter,
    });
  }

  /**
   * Set this bible column as the active (scroll) column
   *
   * @param {string} abbr
   * @memberof BibleInfoComponent
   */
  public setActiveColumn(abbr: string) {
    this.isActiveColumn.emit(abbr);
  }
}
