import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'ibep-audio-embed',
  templateUrl: './audio-embed.component.html',
  styleUrls: ['./audio-embed.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AudioEmbedComponent {
  @Input() content: string;
}
