import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { LanguageService } from '@ibep/fe/shared/core';
import { ConfigData } from '@ibep/fe/shared/data';
import { BrandUIPreferences } from '@ibep/interfaces';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
/**
 * Validation Source === Config.brand.brandUIPreferences
 * Validation Origin ===  The routes include a "data: { guard: string }" object
 *
 * @export
 * @class BrandGuard
 * @implements {CanActivate}
 */
@Injectable({
  providedIn: 'root',
})
export class BrandGuard implements CanActivate {
  constructor(
    private configData: ConfigData,
    private languageService: LanguageService,
    private router: Router,
    private localize: LocalizeRouterService
  ) {}

  /**
   * Will be trigger when navigating
   * IF is included in routes with "BrandGuard"
   * @param {ActivatedRouteSnapshot} route
   * @return {*}  {Observable<boolean>}
   */
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.configData
      .getBrand()
      .pipe(
        map(({ brandUIPreferences, id }) =>
          this.authorizeModule(
            route.data.guard,
            brandUIPreferences,
            id,
            this.languageService.currentLanguage
          )
        )
      );
  }

  /**
   * Will match the route.data.guard value with brandUIPreferences
   * @param {string} guard
   * @param {BrandUIPreferences} brandUIPreferences
   * @return {*}  {boolean}
   */
  authorizeModule(
    guard: string,
    brandUIPreferences: BrandUIPreferences,
    brandId: string,
    currentLanguage: string
  ): boolean {
    let canActivate = true;
    switch (guard) {
      case 'module-bible-bssa-af':
        canActivate = brandId === 'BSSA' && currentLanguage === 'af';
        break;
      case 'module-bible-bssa-xh':
        canActivate = brandId === 'BSSA' && currentLanguage === 'xh';
        break;
      case 'module-bible-nbg':
        canActivate = brandId === 'NBG';
        break;
      case 'module-plans':
        canActivate = brandUIPreferences.showReadingPlans;
        break;
      case 'module-ebc':
        canActivate = brandUIPreferences.showEBC;
        break;
      case 'module-auth_sign-in':
        canActivate = brandUIPreferences?.userAccountSystem;
        break;
      case 'module-auth_register':
        canActivate = brandUIPreferences?.userAccountSystem;
        break;
      case 'module-liturgical_texts':
        // FIXME:
        canActivate = true;
        // canActivate = brandUIPreferences?.showLiturgicalTexts;
        break;
      default:
        break;
    }

    if (!canActivate) {
      return this.returnHome();
    }

    return canActivate;
  }

  /**
   * Will always let the user navigate to the root of the application
   * @return {false}  {boolean}
   */
  returnHome(): boolean {
    const translatedPath = this.localize.translateRoute('/');
    this.router.navigate([translatedPath]);
    return false;
  }
}
