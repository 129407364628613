import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from '@ibep/fe/shared/core';
import { ReaderStateInterface } from '@ibep/fe/shared/data';
import { BibleMetadata, Column } from '@ibep/interfaces';
import { fromEvent, Subscription, takeUntil, throttleTime } from 'rxjs';

@Component({
  selector: 'ibep-bible-nav',
  templateUrl: './bible-nav.component.html',
  styleUrls: ['./bible-nav.component.scss'],
})
export class BibleNavComponent extends BaseComponent implements OnChanges {
  @Input() columns: Column[] = <Column[]>[];

  @Input() activeColumn: string;

  @Input() isAuthenticated: boolean;
  @Input() isPremium: boolean;

  @Input() set readerStateVal(value: ReaderStateInterface) {
    this.readerState = value;
    this.audioState = value.audioState;
  }

  @Input() audioSource: {
    resourceUrl: string;
    expiresAt: string;
    signedPolicy: {
      'CloudFront-Key-Pair-Id': string;
      'CloudFront-Signature': string;
      'CloudFront-Policy': string;
    }[];
  };

  @Output() pushGtmTag = new EventEmitter<object>();
  @Output() updateAudioState = new EventEmitter<object>();
  @Output() requestNextChapter = new EventEmitter<boolean>();

  public showAudioPlayer = false;
  public readerState: ReaderStateInterface;
  // array of audio sources
  public audioSources: {
    src: string;
    bibleId: string;
    currentTime: string;
    duration: string;
    progress: number;
    abbreviation: string;
    lockedIcon: string;
    hasAudio: boolean;
    colIndex: number;
  }[];
  // holds the current audio state
  public audioState: {
    mode: 'on' | 'off' | 'paused';
    activePlayer: number;
    playbackRate: { rate: number; index: number };
  } = {
    mode: 'off',
    activePlayer: 0,
    playbackRate: { rate: 1.0, index: 0 },
  };
  public playbackRateOptions = [1.0, 1.2, 1.5, 2.0, 0.5, 0.8];
  public $players: HTMLAudioElement[] = [];

  private _audioSubscription: Subscription;
  private _audioEndedSubscription: Subscription;

  @ViewChild('playBtnTemplate', { static: false })
  playBtnTemp: ElementRef<HTMLDivElement>;

  // get a reference to the html 5 audio player(s)
  @ViewChildren('stream') set playerRef(refs: ElementRef<HTMLAudioElement>[]) {
    if (refs) {
      refs.forEach((ref, index) => (this.$players[index] = ref.nativeElement));
    }
  }

  constructor(
    private readonly _ref: ChangeDetectorRef,
    private readonly _router: Router
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.audioSource || changes.columns) {
      const columnsChanged = changes.columns.currentValue.some(
        (column: { bible: { id: any } }, index: string | number) => {
          return (
            column?.bible.id !==
            changes.columns.previousValue?.[index]?.bible.id
          );
        }
      );

      if (
        changes.audioSource.firstChange ||
        changes.audioSource.currentValue?.[0]?.[0]?.data?.resourceUrl !==
          changes.audioSource.previousValue?.[0]?.[0]?.data?.resourceUrl ||
        columnsChanged
      ) {
        // if one or more of the selected chapters have audio
        if (changes.audioSource.currentValue?.length) {
          this.showAudioPlayer = true;
          // we have to create columns with audio players for selected bibles that have related audio,
          // and empty cols for bibles that dont have audio
          this.audioSources = this.columns.map((col) => {
            const source = changes.audioSource.currentValue.filter(
              (src) => src[1] === col.bible.id
            );

            const chapterHasAudio = col?.chapter?.id
              ? this.checkChapterHasAudio(col.chapter.id, col.bible)
              : false;

            if (source[0]?.[0]?.data) {
              const value = source[0][0].data;
              // when we receive a new audio chapter, we create the signed audio source url
              const expires = Date.parse(value.expiresAt) / 1000;

              return {
                bibleId: value.bibleId,
                src: `${value.resourceUrl}?Key-Pair-Id=${value.signedPolicy['CloudFront-Key-Pair-Id']}&Signature=${value.signedPolicy['CloudFront-Signature']}&Expires=${expires}&Policy=${value.signedPolicy['CloudFront-Policy']}`,
                progress: 0,
                duration: '',
                currentTime: '',
                abbreviation: col.bible.abbreviation,
                lockedIcon: '',
                hasAudio: chapterHasAudio,
                colIndex: this.columns.indexOf(col),
              };
            }
            // if there is an audio bible but the user does not have right access level we show locked audio icon
            const lockedIcon = col.bible.audioAccessLevelWeb;
            // if no audio for this column, push an empty object
            return {
              bibleId: '',
              duration: '',
              src: '',
              progress: 0,
              currentTime: '',
              abbreviation: col.bible.abbreviation,
              lockedIcon,
              hasAudio: chapterHasAudio,
              colIndex: this.columns.indexOf(col),
            };
          });

          // if audio was playing in prev chapter, we continue playing
          if (this.audioState.mode === 'on') {
            // we reset some values
            this._resetValues();
            setTimeout(() => {
              this.playBtnTemp.nativeElement.click();
            }, 0);
          }

          // if no selected chapter has audio
        } else {
          this.showAudioPlayer = false;
          this.audioState.mode = 'off';
          this._resetValues();
        }

        this._ref.detectChanges();
      }
    }
  }

  checkChapterHasAudio(
    chapterId: string,
    bibleMetadata: BibleMetadata
  ): boolean {
    let hasAudio = false;
    bibleMetadata.testaments.forEach((testament) => {
      testament.books.forEach((book) => {
        book.chapters.forEach((chapter) => {
          if (chapter.id === chapterId) {
            hasAudio = Boolean(chapter.hasAudio);
          }
        });
      });
    });
    return hasAudio;
  }

  /**
   * Start audio playback
   *
   * @memberof BibleNavComponent
   */
  public async startAudio(i: number) {
    if (this.$players[i]) {
      // stop already playing audio
      this.$players[this.audioState.activePlayer].pause();

      // start the audio
      try {
        await this.$players[i].play();
      } catch (error) {
        console.info(error);
      }
      // set the playback rate
      this.$players[i].playbackRate = this.audioState.playbackRate.rate;
      this.audioState.activePlayer = i;
      // write audio is playing to readerstate
      if (this.audioState.mode !== 'on') {
        this.audioState.mode = 'on';
        this.updateAudioState.next(this.audioState);
      }

      // subscribe to timeUpdate events of the html5 audio player
      this._audioSubscription = fromEvent(this.$players[i], 'timeupdate')
        .pipe(takeUntil(this._destroy), throttleTime(500))
        .subscribe((event) => {
          // set the current playback time
          this.audioSources[i].currentTime = this._getTime(
            +this.$players[i].currentTime.toFixed()
          );
          // set the total duration of the audio track
          this.audioSources[i].duration = isNaN(this.$players[i].duration)
            ? ''
            : this._getTime(+this.$players[i].duration.toFixed());
          // set the current playback progress
          this.audioSources[i].progress = isNaN(this.$players[i].duration)
            ? 0
            : (+this.$players[i].currentTime.toFixed() /
                +this.$players[i].duration.toFixed()) *
              1000;
          this._ref.detectChanges();
        });

      this._audioEndedSubscription = fromEvent(this.$players[i], 'ended')
        .pipe(takeUntil(this._destroy))
        .subscribe((event) => {
          // pause playback when end is reached
          this.requestNextChapter.emit(true);
        });

      this.audioEvent('audio_bible', 'play');
    }
  }

  /**
   * Play audio faster or slower
   *
   * @param {number} playerIndex
   * @memberof BibleNavComponent
   */
  public changePlaybackRate(playerIndex: number): void {
    const currentIndex = this.audioState.playbackRate.index;
    // calculate index of next playback rate option
    const newIndex =
      currentIndex < this.playbackRateOptions.length - 1 ? currentIndex + 1 : 0;
    // change the playback rate
    this.$players[playerIndex].playbackRate =
      this.playbackRateOptions[newIndex];
    // store the playback rate setting
    this.audioState.playbackRate = {
      rate: this.playbackRateOptions[newIndex],
      index: newIndex,
    };

    this.updateAudioState.emit(this.audioState);
  }

  /**
   * Pause playback
   *
   * @memberof BibleNavComponent
   */
  public pauseAudio(i: number) {
    if (this.$players[i] && this.audioState.mode === 'on') {
      this.audioState.mode = 'paused';
      this.$players[i].pause();
      // write audio is paused to readerstate
      this.updateAudioState.next(this.audioState);

      this.audioEvent('audio_bible', 'pause');
    }
  }

  /**
   * Go back 10 seconds
   *
   * @memberof BibleNavComponent
   */
  public rewindAudio(i: number) {
    this.$players[i].currentTime -= 10;

    this.audioEvent('audio_rewind', 'rewind');
  }

  /**
   * Change the progress when user clicks on the progressbar/ moves the progress slider
   *
   * @param {number} progress
   * @memberof BibleNavComponent
   */
  public changeProgress(progress: number, i: number) {
    const newTime = (progress / 1000) * this.$players[i].duration;
    this.$players[i].currentTime = newTime;
    this.audioSources[i].progress = progress;
  }

  /**
   * Analytics
   *
   * @param {string} event
   * @param {string} [chapter]
   * @memberof BibleNavComponent
   */
  public onGtmTagPush(tag: object): void {
    this.pushGtmTag.emit(tag);
  }

  public chapterNavigationEvent(event: string, chapter?: string): void {
    const tag = {
      event,
      bible_book: chapter?.split('.')?.[0],
      bible_chapter: chapter,
      bible_translation: this.readerState.selectedBibles?.bibleAbbrs,
    };

    this.onGtmTagPush(tag);
  }

  public audioEvent(event: string, playerEvent: string): void {
    const tag = {
      event,
      bible_book: this.readerState.selectedChapterFullname?.split(' ')?.[0],
      bible_chapter: this.readerState.selectedChapterFullname,
      bible_translation: this.readerState.selectedBibles?.bibleAbbrs,
      audio_bible: playerEvent,
      audio_speed: this.audioState.playbackRate.rate,
    };

    this.onGtmTagPush(tag);
  }

  public openSignup() {
    this._router.navigate([], {
      queryParams: { view: 'signUp' },
    });
  }

  public openBecomePremium() {
    this._router.navigate([], {
      queryParams: { view: 'premium' },
    });
  }

  /**
   * reset audio sources and unsubscribe
   *
   * @private
   * @memberof BibleNavComponent
   */
  private _resetValues() {
    if (this._audioSubscription) {
      this._audioSubscription.unsubscribe();
    }
    if (this._audioEndedSubscription) {
      this._audioEndedSubscription.unsubscribe();
    }

    this.audioSources = this.audioSources?.map((src) => {
      src.duration = '';
      src.currentTime = '';
      src.progress = 0;
      src.lockedIcon = '';
      return src;
    });

    this._ref.detectChanges();
  }
  /**
   * from seconds to minutes:seconds
   *
   * @private
   * @param {number} s
   * @returns
   * @memberof BibleNavComponent
   */
  private _getTime(s: number) {
    return (s - (s %= 60)) / 60 + (9 < s ? ':' : ':0') + s;
  }

  public isPlayerOn(i: number) {
    return this.audioState.mode === 'on' && i === this.audioState.activePlayer;
  }

  public isPlayerOff(i: number) {
    return (
      this.audioState.mode === 'paused' ||
      this.audioState.mode === 'off' ||
      i != this.audioState.activePlayer
    );
  }
}
