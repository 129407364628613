<!-- Search books -->
<div class="w-full h8 sticky top-[84px] pt-2 pb-4 bg-white z-20">
  <ibep-search-svg
    class="absolute"
    svgStyles="w-3 h-3 ml-5 mt-1.5"
  ></ibep-search-svg>
  <input
    class="w-full outline-offset-[-3px] h-8 text-sm rounded-[40px] text-content100 pl-10 pr-4 border border-grayExtraLight"
    name="search"
    [placeholder]="'SearchForABook' | translate"
    #searchInput
  />
</div>
<!-- Testaments  -->
<div class="h-full pb-16 lg:pb-5 pt-5">
  <ng-container *ngFor="let testament of filteredTestaments; let i = index">
    <!-- Testament menu -->
    <div
      class="text-4xs truncate uppercase text-left w-full max-w-[460px] fixed h-10 bg-white border-b border-grayExtraLight flex items-center cursor-pointer hover:text-primary"
      *ngIf="!this.searchIsActive || (this.searchIsActive && i === 0)"
      (click)="scrollToElement(testament.abbreviation, 'testament', i)"
      [ngClass]="{
        top1: checkPositionIsActive('top1', i),
        top2: checkPositionIsActive('top2', i),
        top3: checkPositionIsActive('top3', i),
        bottom1: checkPositionIsActive('bottom1', i),
        bottom2: checkPositionIsActive('bottom2', i),
        borderTop: checkPositionIsActive('borderTop', i),
        borderBottom: checkPositionIsActive('borderBottom', i),
        'font-bold':
          !this.searchIsActive &&
          activeTestament.abbr === testament.abbreviation
      }"
    >
      <span class="mr-2" *ngIf="!this.searchIsActive">
        <ibep-chevron-up-svg
          *ngIf="
            checkPositionIsActive('top1', i) ||
            checkPositionIsActive('top2', i) ||
            checkPositionIsActive('top3', i)
          "
          svgStyles="w-3 h-3"
        ></ibep-chevron-up-svg>
        <ibep-chevron-down-svg
          *ngIf="
            checkPositionIsActive('bottom1', i) ||
            checkPositionIsActive('bottom2', i)
          "
          svgStyles="w-3 h-3"
        ></ibep-chevron-down-svg>
      </span>
      <span
        (click)="
          onGtmTagPush({
            event: 'book_area_picker',
            bible_translation: bibleMetadata.abbreviation,
            bible_testament: testament.abbreviation
          })
        "
        >{{ testament.abbreviation + '_ABBR' | translate }}</span
      >
    </div>
    <div class="pb-3 w-full text-grayMedium">
      <!-- Testament title  -->
      <div
        [id]="testament.abbreviation"
        *ngIf="i !== 0"
        class="text-4xs font-medium truncate uppercase text-left w-full h-10 bg-white flex items-center"
      >
        <span>{{ testament.abbreviation + '_ABBR' | translate }}</span>
      </div>
      <!-- Books  -->
      <div
        class="py-2"
        observeVisibility
        [root]="'modalViewport'"
        [rootMargin]="'-1px'"
        [threshold]="0.3"
        (visible)="isVisible(testament.abbreviation, i)"
        [isBrowser]="isBrowser"
      >
        <ng-container role="none" *ngFor="let book of testament.books">
          <div class="border-grayExtraLight border-b">
            <div
              [id]="book.id"
              class="py-3 text-xs cursor-pointer flex flex-row justify-between text-grayMedium hover:text-primary"
              (click)="setActiveBook(book.id)"
            >
              <div [ngClass]="{ 'font-bold': activeBook === book.id }">
                {{ book.name }}
              </div>
              <div class="text-grayLight">
                <!-- chevron-up -->
                <ibep-chevron-right-svg
                  svgStyles="w-3 h-3"
                ></ibep-chevron-right-svg>
              </div>
            </div>
            <div
              *ngIf="activeBook === book.id"
              class="w-full flex flex-wrap pb-6"
            >
              <!-- Chapters  -->
              <ng-container *ngFor="let chapter of book.chapters">
                <a
                  [routerLink]="
                    '/ROUTES.bible/' +
                      selectedBibles.bibleAbbrs.join(',') +
                      '/' +
                      chapter.id | localize
                  "
                  (click)="selectChapter(chapter.id, chapter.number)"
                  class="inline-block m-0.5 hover:text-primary rounded w-16 h-12 text-center"
                  [ngClass]="{
                    'font-bold': selectedChapter === chapter.id
                  }"
                  style="line-height: 3rem"
                >
                  {{ chapter.number }}
                </a>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>
