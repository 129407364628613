import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { BibleService } from '@ibep/fe/shared/bible';
import { BibleMediaData } from '@ibep/fe/shared/data';

@Component({
  selector: 'ibep-media-package-item-container',
  templateUrl: './media-package-item-container.component.html',
})
export class MediaPackageContainerComponent {
  @Input() set props(value) {
    this._getBibleMedia(value);
  }

  imageUrl: string;

  constructor(
    private readonly _bibleMediaData: BibleMediaData,
    private readonly _bibleService: BibleService,
    private readonly _ref: ChangeDetectorRef
  ) {}

  private _getBibleMedia({ bibleId, fileName }) {
    this.imageUrl = '';
    this._bibleMediaData
      .getBibleMediaItem({
        bibleId: this._bibleService.getBibleIdFromAbbr(bibleId),
        filename: fileName,
      })
      .subscribe((res) => {
        this.imageUrl = res.data.url;
        this._ref.detectChanges();
      });
  }
}
