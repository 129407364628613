import { Component, Input } from '@angular/core';
import { CustomStyling } from '@ibep/interfaces';

@Component({
  selector: 'ibep-verse-text-renderer',
  templateUrl: './verse-text-renderer.component.html',
})
export class VerseTextRendererComponent {
  @Input() parentStyle: string;
  @Input() style: string;
  @Input() content: string;
  @Input() customStyling: CustomStyling;
}
