import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { footerArg } from './footer.interface';

@Component({
  selector: 'ibep-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FooterComponent {
  @Input() config: any;
  @Input() appVersion: string;
  @Input() ftrArg: footerArg | undefined;
  @Input() currentLanguage: string;
  @Input() isDefaultLanguage: boolean;

  @Output() pushStoreTag = new EventEmitter<string>();
  @Output() pushSocialTag = new EventEmitter<string>();

  public onStoreClick(link: string): void {
    this.pushStoreTag.emit(link);
  }

  public onSocialClick(link: string): void {
    this.pushSocialTag.emit(link);
  }
}
