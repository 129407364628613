import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'ibep-become-premium',
  templateUrl: './become-premium.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BecomePremiumComponent {
  @Input() content: any;
}
