<div class="w-full h-8 ml-6 mb-2 font-semibold text-left">
  {{ 'FilterByTestament*' | translate }}
</div>
<div class="w-full pt-2 pb-4">
  <ibep-search-svg
    class="absolute"
    svgStyles="w-3 h-3 ml-5 mt-1.5"
  ></ibep-search-svg>
  <input
    class="w-full outline-offset-[-3px] h-8 text-sm rounded-[40px] pl-10 pr-4 border border-grayExtraLight"
    name="search"
    [placeholder]="'SearchForABook' | translate"
    [(ngModel)]="query"
    (ngModelChange)="onSearch($event)"
  />
</div>

<div
  (click)="
    onBookSelect({ searchScope: '', id: '', name: titleElement.innerText })
  "
  class="cursor-pointer font-semibold flex items-center justify-between py-3 border-b border-grayExtraLight"
>
  <div #titleElement class="text-grayMedium hover:text-primary">
    {{ 'AllBooks' | translate }}
  </div>
  <div class="text-[#C0D0E4]">
    <span>{{
      'VAR_results'
        | translate
          : {
              N: total
            }
    }}</span>
    <ibep-chevron-right-svg
      svgStyles="w-3 h-3"
      class="inline ml-4"
    ></ibep-chevron-right-svg>
  </div>
</div>

<ng-container *ngFor="let testament of totalsByTestament || []">
  <div
    *ngIf="!testament.hidden && (!hideZeroTotalItems || testament.total)"
    (click)="
      onBookSelect({
        searchScope: 'testament',
        id: testament.id,
        name: testamentName.innerText
      })
    "
    class="mb-4"
  >
    <div
      class="cursor-pointer flex items-center justify-between py-3 border-b border-grayExtraLight"
    >
      <div
        #testamentName
        class="text-grayMedium font-bold line-clamp-1 hover:text-primary"
      >
        {{ testament.id + '_ABBR' | translate }}
      </div>
      <div class="text-[#C0D0E4]">
        <span>{{
          'VAR_results'
            | translate
              : {
                  N: testament.total
                }
        }}</span>
        <ibep-chevron-right-svg
          svgStyles="w-3 h-3"
          class="inline ml-4"
        ></ibep-chevron-right-svg>
      </div>
    </div>
  </div>
  <ng-container *ngFor="let book of testament?.books || []">
    <div
      *ngIf="!book.hidden && (!hideZeroTotalItems || book.total)"
      (click)="
        onBookSelect({ searchScope: 'book', id: book.id, name: book.name! })
      "
      class="cursor-pointer flex items-center justify-between py-3 border-b border-grayExtraLight"
    >
      <div class="line-clamp-1 text-grayMedium hover:text-primary">
        {{ book.name || book.id }}
      </div>
      <div class="text-[#C0D0E4]">
        <span>{{
          'VAR_results'
            | translate
              : {
                  N: book.total
                }
        }}</span>
        <ibep-chevron-right-svg
          svgStyles="w-3 h-3"
          class="inline ml-4"
        ></ibep-chevron-right-svg>
      </div>
    </div>
  </ng-container>
</ng-container>
