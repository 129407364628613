import { Component, Input } from '@angular/core';
/**
 * Section Heading
 * The typical (common) section division heading.
 * # represents the level of division.
 * https://ubsicap.github.io/usx/master/parastyles.html#s
 * @export
 * @class SComponent
 */
@Component({
  selector: 'bible-s',
  templateUrl: './s.component.html',
  styleUrls: ['./s.component.scss'],
})
export class SComponent {
  @Input() item: any;
}
