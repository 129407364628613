<div class="my-6 w-full">
  <div class="relative" style="padding-top: 56.25%">
    <iframe
      class="absolute inset-0 w-full h-full z-0"
      [src]="safeURL"
      frameborder="0"
      allowfullscreen
    ></iframe>
  </div>
  <figcaption class="text-2xs text-grayLight mt-1">{{ caption }}</figcaption>
</div>
