import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Scrolled } from '@ibep/fe/shared/bible';
import { ReaderStateInterface } from '@ibep/fe/shared/data';
import { Column } from '@ibep/interfaces';
import { distinctUntilChanged, filter, fromEvent, map } from 'rxjs';

@Component({
  selector: 'ibep-bible-column',
  templateUrl: './bible-column.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      /* Hide scrollbar for Chrome, Safari and Opera */
      .no-scrollbar::-webkit-scrollbar {
        display: none;
      }

      /* Hide scrollbar for IE, Edge and Firefox */
      .no-scrollbar {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
      }
    `,
  ],
})
export class BibleColumnComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() column: Column;
  @Input() set scrolled(value: Scrolled) {
    if (value && Object.keys(value).length !== 0) {
      this.scrollToSync(value);
    }
  }
  @Input() set activeScrollColumn(value: string) {
    this._activeScrollColumn = value;
  }
  @Input() set scrollStateValue(value: any) {
    if (value?.scrolledToTop) {
      this.scrollToTop();
    }
    if (value?.scrolledToBottom) {
      this.scrollToBottom();
    }
  }
  @Input() isMobile: boolean;
  @Input() readerState: ReaderStateInterface;
  @Input() flashed: string[];

  private _activeScrollColumn = '';
  @ViewChild('columnViewport', { static: false })
  private _columnViewport: ElementRef<HTMLDivElement>;

  @Output() scrolledTo = new EventEmitter<any>();
  @Output() activeScrollColumnSet = new EventEmitter<string>();
  @Output() scrollStateChange = new EventEmitter<any>();

  public scrollSyncIsActive = false;
  public scrollSyncIsPaused = false;
  public scrollState: {
    column?: string;
    scrolledToTop: boolean;
    scrolledToBottom?: boolean;
  } = { scrolledToTop: true };

  constructor(
    @Inject(DOCUMENT)
    private document: Document
  ) {}

  ngOnInit(): void {
    // we only want to scroll sync columns when multiple columns are active and we are in desktop mode
    if (
      !this.isMobile &&
      this.readerState?.selectedBibles?.bibleAbbrs.length > 1
    ) {
      this.scrollSyncIsActive = true;
    }
  }

  ngAfterViewInit(): void {
    // check if the column is scrolled to the top or bottom
    fromEvent(this._columnViewport?.nativeElement, 'scroll')
      .pipe(
        // only listen to the column the user is scrolling
        filter(
          () => this._activeScrollColumn === this.column.bible.abbreviation
        ),
        // create object with relevant data
        map((event) => {
          const element = event.target as HTMLElement;

          return {
            column: this.column.bible.abbreviation,
            scrolledToTop: element.scrollTop === 0,
            scrolledToBottom:
              element.scrollHeight - element.scrollTop - 1 <=
              element.clientHeight,
          };
        }),
        // only pass values if there is a change
        distinctUntilChanged(
          (x, y) =>
            x.scrolledToTop === y.scrolledToTop &&
            x.scrolledToBottom === y.scrolledToBottom
        )
      )
      .subscribe((res) => {
        this.scrollState = res;
        this.scrollStateChange.emit(res);
      });
    // if there are flashed verses, scroll to the first verse of the flash range
    if (this.flashed?.length) {
      this.scrollToVerse(this.flashed[0]);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // check if there are changes to the viewport (mobile or desktop view) and the number of active columns
    if (changes.isMobile || changes.readerState) {
      this.scrollSyncIsActive =
        !this.isMobile &&
        this.readerState?.selectedBibles?.bibleAbbrs.length > 1;
    }
    // scroll columns to top when change to column (multi col mode)
    if (
      !this.isMobile &&
      this.readerState?.selectedBibles?.bibleAbbrs.length > 1 &&
      changes.readerState
    ) {
      this.scrollToTop();
    }
    // check if we need to scroll to flashed verse
    if (changes.column) {
      if (this.flashed) {
        setTimeout(() => {
          this.scrollToVerse(this.flashed[0]);
        }, 1000);
      }
    }
  }

  /**
   * Sync the bible columns on scroll
   *
   * @private
   * @param {Scrolled} scrolled
   * @memberof BibleColumnComponent
   */
  private scrollToSync(scrolled: Scrolled) {
    // we only want to sync the columns the user is not scrolling
    if (
      scrolled.bibleAbbr !== this.column?.bible?.abbreviation &&
      scrolled.bibleAbbr === this._activeScrollColumn &&
      !this.scrollSyncIsPaused
    ) {
      // construct the verse id
      const verseId = `${this.column?.bible?.abbreviation}.${scrolled.verseId}`;
      // get the verse HTML element by its id
      const el = this.document.getElementById(verseId) as HTMLDivElement;
      if (el?.offsetTop) {
        // set the scroll position we need to scroll to
        let scrollPosition = 0;
        // if user scrolled up
        if (scrolled.direction === 'up') {
          scrollPosition =
            el.offsetTop - this._columnViewport.nativeElement.clientHeight / 2;
          // if user scrolled down
        } else {
          scrollPosition =
            el.offsetTop -
            this._columnViewport.nativeElement.clientHeight / 2 +
            100;
        }
        // execute the scroll
        this.scrolledTo.emit({
          element: this._columnViewport,
          offsetTop: scrollPosition,
        });
      }
    }
  }

  /**
   * Scroll the bible column to the start of a verse
   *
   * @memberof BibleColumnComponent
   */
  public scrollToVerse(verseId: string): void {
    let el: HTMLDivElement;
    // get the verse HTML element by its id
    el = this.document.getElementById(
      `${this.column?.bible?.abbreviation}.${verseId}`
    ) as HTMLDivElement;
    // if combined verse (verse with an id range) we need to find the element in another way
    if (!el) {
      el = this.document.querySelector(
        `[id*="${this.column?.bible?.abbreviation}.${verseId}"]`
      ) as HTMLDivElement;
    }

    if (el) {
      if (
        this.readerState?.selectedBibles?.bibleAbbrs.length > 1 ||
        this.readerState?.ebcState?.showEbc
      ) {
        // execute the scroll on this column div
        this.scrolledTo.emit({
          element: this._columnViewport,
          offsetTop: el.offsetTop - 100,
        });
      } else {
        // execute the scroll on main scroll window
        this.scrolledTo.emit({
          offsetTop: el.offsetTop,
        });
      }
    }
  }

  /**
   * Scroll the bible column to the top
   *
   * @memberof BibleColumnComponent
   */
  public scrollToTop() {
    this.scrollSyncIsPaused = true;
    // execute the scroll
    this.scrolledTo.emit({
      element: this._columnViewport,
      offsetTop: 0,
    });

    setTimeout(() => {
      this.scrollSyncIsPaused = false;
    }, 1000);
  }

  /**
   * Scroll the bible column to the bottom
   *
   * @memberof BibleColumnComponent
   */
  public scrollToBottom() {
    this.scrollSyncIsPaused = true;
    const element = this._columnViewport;
    // execute the scroll
    this.scrolledTo.emit({
      element,
      offsetTop: element?.nativeElement.scrollHeight,
    });

    setTimeout(() => {
      this.scrollSyncIsPaused = false;
    }, 1000);
  }

  /**
   * When the user hovers a column, we set it as the active column
   *
   * @param {boolean} isHovering
   * @memberof BibleColumnComponent
   */
  public onHover(isHovering: boolean) {
    if (isHovering) {
      this.activeScrollColumnSet.emit(this.column.bible.abbreviation);
    }
  }
}
