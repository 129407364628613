<!-- Hero Section -->
<ibep-teaser-hero
  [tags]="tags"
  [route]="'ROUTES.readingplans' | localize"
  [title]="'ReadingPlans' | translate"
  [selectedTagSlug]="selectedTagSlug"
  [description]="description"
></ibep-teaser-hero>

<!-- List Section -->
<ibep-teaser-list
  [items]="readingPlans"
  [isBrowser]="isBrowser"
  [moreItemsAvailable]="morePlansAvailable"
  [contentType]="contentTypeEnum.READING_PLAN"
  [readMoreText]="'ViewReadingPlan' | translate"
  (loadMoreClicked)="onLoadMore()"
  [route]="'ROUTES.readingplans' | localize"
>
</ibep-teaser-list>

<ng-container *ngIf="!readingPlans?.length || isLoading">
  <ibep-page-loading></ibep-page-loading>
</ng-container>
