import { Component } from '@angular/core';

/**
 * https://ubsicap.github.io/usx/parastyles.html#r
 * https://github.com/americanbible/scripture-styles/blob/master/scss/modules/_titles-headings.scss
 * @export
 * @class RComponent
 */
@Component({
  selector: 'bible-r',
  template: `
    <h5 class="r">
      <ng-content></ng-content>
    </h5>
  `,
  styleUrls: ['./r.component.scss'],
})
export class RComponent {}
