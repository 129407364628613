import { Pipe, PipeTransform } from '@angular/core';
import { BibleMetadata, Book } from '@ibep/interfaces';

/**
 * This pipe formats verse description
 */
@Pipe({
  name: 'formatChapterTitle',
})
export class FormatChapterTitlePipe implements PipeTransform {
  findBookByShortName(bible: BibleMetadata, bookId: string): Book | undefined {
    const testament = bible.testaments.find((t) =>
      t.books.find((b) => b.id === bookId)
    );

    return testament?.books.find((b) => b.id === bookId);
  }

  transform(
    inputStr: string,
    bible: BibleMetadata,
    withBibleAbbreviation?: boolean
  ): string {
    if (bible) {
      const rangeChunks = inputStr.split(/(?:-|\.)+/);
      const book = this.findBookByShortName(bible, rangeChunks[0]);

      const shortForm = rangeChunks[1] && rangeChunks[1] === rangeChunks[4];

      // set parts of title
      const bookName = book?.name || rangeChunks[0] || inputStr;
      const chapter = rangeChunks[1] ? ` ${rangeChunks[1]}` : '';
      const verse = rangeChunks[2] ? `:${rangeChunks[2]}` : '';
      const rangeDash =
        rangeChunks[4] && rangeChunks[5] && rangeChunks[5] !== rangeChunks[2]
          ? '-'
          : '';
      const chapterRange =
        shortForm || !rangeChunks[4] || !rangeDash ? '' : `${rangeChunks[4]}:`;
      const verseRange = rangeChunks[5] && rangeDash ? rangeChunks[5] : '';
      const bibleAbbr =
        withBibleAbbreviation && bible.abbreviationLocal
          ? ` ${bible.abbreviationLocal}`
          : '';

      return `${bookName}${chapter}${verse}${rangeDash}${chapterRange}${verseRange}${bibleAbbr}`;
    }
    return inputStr;
  }
}
