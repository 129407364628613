<div
  class="hidden lg:block search-header pt-5 pb-14 text-white text-2xl font-bold mb-2"
>
  <div class="max-w-container mx-auto">
    <div class="ml-4 xl:ml-0">
      {{
        'SearchResultsFor_VAR'
          | translate
            : {
                query: query
              }
      }}
    </div>
  </div>
</div>

<div class="max-w-container mx-auto mb-2">
  <div class="px-4 pt-4 lg:-mt-8 bg-white">
    <div class="hidden lg:block text-grayLight text-xs">
      {{ 'FilterByCategory*' | translate }}
    </div>
    <div class="mt-2 pb-4 border-b border-b-grayExtraLight">
      <ibep-button-toggle-group
        [props]="buttonProps"
        [bibleId]="bibleId"
        [query]="query"
        (singleValueChanged)="onCategoriesChanged($event)"
      ></ibep-button-toggle-group>
    </div>
  </div>

  <ng-container *ngFor="let category of searchResultsCategories">
    <div
      *ngIf="
        (category.items.length || category.name === categoryEnum.BIBLE) &&
        selectedCategories.includes(category.name)
      "
      class="lg:flex lg:items-center lg:flex-wrap lg:gap-x-3 lg:gap-y-2 p-4 text-2xl font-bold text-grayDark"
    >
      <div class="mb-2 lg:mb-0">{{ category.title }}</div>
      <ng-container *ngIf="category.name === categoryEnum.BIBLE">
        <ibep-button
          [buttonProps]="{
            pushGtm: true,
            content: selectedBibles.bibleAbbrs[0],
            size: 'm'
          }"
          (click)="openModal('translation')"
          class="text-grayLight text-xs"
        >
          <div class="flex justify-between w-full">
            <span>{{ selectedBibles.bibleAbbrs[0] }}</span>
            <ibep-chevron-down-svg
              svgStyles="w-3 h-3 ml-1"
            ></ibep-chevron-down-svg>
          </div>
        </ibep-button>
        <ibep-button
          [buttonProps]="{
            pushGtm: true,
            content: checkedSorting?.name,
            size: 'm'
          }"
          (click)="openModal('bible-sorting')"
          class="text-grayLight text-xs"
          [ngClass]="{ 'ml-3 mb-2': isTablet }"
        >
          <div class="flex justify-between w-full">
            <span>{{ checkedSorting?.name }}</span>
            <ibep-chevron-down-svg
              svgStyles="w-3 h-3 ml-1"
            ></ibep-chevron-down-svg>
          </div>
        </ibep-button>
        <ibep-button
          [buttonProps]="{
            pushGtm: true,
            content: contentTypeTitle,
            size: 'm'
          }"
          (click)="openModal('bible-content')"
          class="text-grayLight text-xs"
          [ngClass]="{ 'ml-3 mb-2': isTablet }"
        >
          <div class="flex justify-between w-full">
            <span>{{ contentTypeTitle }}</span>
            <ibep-chevron-down-svg
              svgStyles="w-3 h-3 ml-1"
            ></ibep-chevron-down-svg>
          </div>
        </ibep-button>
        <ibep-button
          [buttonProps]="{
            pushGtm: true,
            content: selectedBook.name,
            size: 'm'
          }"
          (click)="openModal('books')"
          class="text-grayLight text-xs"
          [ngClass]="{ 'ml-3 mb-2': isTablet }"
        >
          <div class="flex justify-between w-full">
            <span>{{ selectedBook.name }}</span>
            <ibep-chevron-down-svg
              svgStyles="w-3 h-3 ml-1"
            ></ibep-chevron-down-svg>
          </div>
        </ibep-button>
      </ng-container>
    </div>

    <ng-container *ngIf="category.items.length">
      <div
        *ngIf="
          fullMode
            ? category.name === fullModeCategory
            : selectedCategories.includes(category.name)
        "
        class="flex"
        [ngClass]="{
          'mb-8': category.name !== categoryEnum.BIBLE,
          'mb-6': category.name === categoryEnum.BIBLE
        }"
      >
        <div
          class="w-full lg:w-3/5 lg:pr-7"
          [ngClass]="{ 'w-3/5 pr-7': isTablet }"
        >
          <div [ngClass]="{ 'border-b border-grayExtraLight': fullMode }">
            <ng-container
              *ngIf="fullMode && category.name !== categoryEnum.BIBLE"
            >
              <!-- temporary commented till API changes -->
              <!-- <div
                *ngIf="tags?.length"
                class="pb-3 pl-3 border-b border-grayExtraLight"
              >
                <ibep-button
                  [buttonProps]="{
                    pushGtm: true,
                    content: 'FilterByTags' | translate,
                    size: 'm',
                    autoWidth: true
                  }"
                  (click)="openModal('tags')"
                >
                  {{ 'FilterByTags' | translate }}
                </ibep-button>
              </div> -->
              <div class="font-bold my-2.5 pl-4">
                {{
                  'VAR_results'
                    | translate
                      : {
                          N: category.total
                        }
                }}
              </div>
            </ng-container>
          </div>

          <ng-container
            *ngIf="
              isSearchResultsLoading &&
              (category.name === categoryEnum.BIBLE || !isBibleFiltering)
            "
          >
            <ibep-page-loading></ibep-page-loading>
          </ng-container>

          <div
            *ngIf="!isSearchResultsLoading && !category.items.length"
            class="px-4 py-2 text-xs text-[#7A8592]"
          >
            {{ 'NoResultsAvailable*' | translate }}
          </div>

          <ng-container
            *ngIf="
              !isSearchResultsLoading ||
              (category.name !== categoryEnum.BIBLE && isBibleFiltering)
            "
          >
            <ibep-search-result-items
              [CONTENT_CATEGORIES]="CONTENT_CATEGORIES"
              [isAuthenticated]="isAuthenticated"
              [isPremium]="isPremium"
              [isTablet]="isTablet"
              [items]="category.items"
              [category]="category.name"
              [selectedBible]="selectedBibles.bibleAbbrs[0]"
              [query]="query"
              (checkedVerse)="onVerseChecked($event)"
              (clickedItem)="onSearchItemCliked($event, category.name)"
              (pushGtmTag)="onGtmTagPush($event)"
            ></ibep-search-result-items>
          </ng-container>

          <div
            class="m-4 mb-0 py-3 border border-grayExtraLight rounded text-center lg:border-none lg:text-left"
            [ngClass]="{
              hidden: fullMode || category.total <= MOBILE_SHORT_LIMIT,
              'lg:hidden': fullMode || category.total <= DESKTOP_SHORT_LIMIT
            }"
          >
            <div class="text-xs text-secondary hover:text-primary font-bold">
              <span
                class="cursor-pointer"
                (click)="toggleFullMode(true, category.name)"
                [routerLink]="
                  ('/ROUTES.search' | localize) + '/' + category.name
                "
                [queryParams]="{
                  query: query,
                  bibleId:
                    category.name === categoryEnum.BIBLE ||
                    category.name === categoryEnum.ALL
                      ? bibleId
                      : null
                }"
                [queryParamsHandling]="'merge'"
              >
                <ibep-arrow-right-svg
                  svgStyles="w-6 h-6 -ml-1"
                  class="hidden lg:inline"
                ></ibep-arrow-right-svg>
                <span class="group-hover:underline group-hover:text-primary">
                  {{
                    'ViewAll_VAR_resultsIn_VAR'
                      | translate
                        : {
                            N: category.total,
                            CATEGORY: category.title
                          }
                  }}
                </span>
                <ibep-chevron-right-svg
                  svgStyles="w-4 h-4"
                  class="inline lg:hidden"
                ></ibep-chevron-right-svg>
              </span>
            </div>
            <div class="text-grayLight lg:ml-6">
              <ng-container [ngSwitch]="category.name">
                <ng-container *ngSwitchCase="categoryEnum.BIBLE">{{
                  'AndFilterTheBible*' | translate
                }}</ng-container>
                <ng-container *ngSwitchCase="categoryEnum.POSTS">{{
                  'AndFilterTheSiteContent*' | translate
                }}</ng-container>
                <ng-container *ngSwitchCase="categoryEnum.PAGES">{{
                  'AndFilterTheSiteContent*' | translate
                }}</ng-container>
                <ng-container *ngSwitchCase="categoryEnum.READING_PLANS">{{
                  'AndFilterTheReadingPlans*' | translate
                }}</ng-container>
                <ng-container *ngSwitchCase="categoryEnum.EBC">{{
                  'AndFilterTheBackgroundInformation*' | translate
                }}</ng-container>
              </ng-container>
            </div>
          </div>

          <ibep-pagination
            *ngIf="fullMode"
            [step]="STEP"
            [actualPage]="page"
            [lastPage]="category.lastPage"
            (pageChange)="onPageChanged($event)"
          ></ibep-pagination>
        </div>

        <div *ngIf="isChapterLoading" class="mx-auto">
          <ibep-page-loading></ibep-page-loading>
        </div>

        <div
          class="hidden lg:w-2/5 lg:border-l lg:border-l-grayExtraLight"
          [ngClass]="{
            'w-2/5 border-l border-l-grayExtraLight': isTablet,
            block:
              !isChapterLoading &&
              chapter &&
              isTablet &&
              category.name === categoryEnum.BIBLE,
            'lg:block':
              !isChapterLoading &&
              chapter &&
              category.name === categoryEnum.BIBLE
          }"
        >
          <div
            class="py-5 pl-5 mr-4 flex items-start text-secondary text-xs lg:border-b lg:border-b-grayExtraLight"
            [ngClass]="{ 'border-b border-b-grayExtraLight': isTablet }"
          >
            <ibep-book-svg svgStyles="w-4 h-4"></ibep-book-svg>
            <a
              [routerLink]="checkedVerse?.linkInternal || '' | localize"
              (click)="
                onGtmTagPush({
                  event: 'button_click',
                  button_url: checkedVerse?.linkInternal,
                  button_name: 'open_bible_reader',
                  button_text: 'Open this Bible part in the Bible reader'
                })
              "
              class="ml-2"
            >
              {{ 'OpenThisBible*' | translate }}
            </a>
            <div
              (click)="closeQuickView()"
              class="px-1 ml-auto -mt-1 text-[#C0D0E4] cursor-pointer"
            >
              <ibep-close-svg svgStyles="w-6 h-6"></ibep-close-svg>
            </div>
          </div>
          <div *ngIf="readerState" class="p-5">
            <ibep-bible-chapter>
              <h1 class="text-xl font-bold mb-4 text-[#62648C]">
                {{ chapter?.title }}
              </h1>
              <ibep-render-bible-content
                [bibleContent]="chapter?.content"
                [scriptDirection]="scriptDirection"
                [isBrowser]="isBrowser"
                [isSearchPage]="true"
                [readerState]="readerState"
                [highlighted]="checkedVerse?.idRange"
              ></ibep-render-bible-content>
            </ibep-bible-chapter>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="noResults">
    <div class="font-bold">{{ 'NoResults' | translate }}</div>
  </ng-container>
</div>

<ng-template #tagsPickerTemplate>
  <ibep-tags-picker
    [tags]="tags"
    (valueChanged)="onTagsChanged($event)"
  ></ibep-tags-picker>
</ng-template>

<ng-template #translationPickerTemplate>
  <ibep-translation-picker
    [bibles]="bibles"
    [selectedBibles]="selectedBibles"
    [isSearchPage]="true"
    [userAccountSystem]="userAccountSystem"
    [isAuthenticated]="isAuthenticated"
    [isPremium]="isPremium"
    (modalClosed)="closeModal()"
    (changeSelectedBibles)="changeSelectedBibles($event)"
  ></ibep-translation-picker>
</ng-template>

<ng-template #booksPickerTemplate>
  <ibep-books-picker
    [total]="bibleTotal"
    [totalsByTestament]="searchResultsCategories[0].totalsByTestament"
    (selectBook)="filterByBook($event)"
  ></ibep-books-picker>
</ng-template>

<ng-template #bibleContentPickerTemplate>
  <ibep-bible-content-picker
    [title]="'ContentToSearchOn' | translate"
    [items]="bibleContentItems"
    (valueChanged)="filterByContent($event)"
  ></ibep-bible-content-picker>
</ng-template>

<ng-template #bibleSortingPickerTemplate>
  <ibep-bible-content-picker
    [title]="'BibleSortingTitle' | translate"
    [items]="bibleSortingItems"
    (valueChanged)="changeSorting($event)"
  ></ibep-bible-content-picker>
</ng-template>
