import {
  Component,
  Output,
  EventEmitter,
  Input,
  ViewChild,
  ElementRef,
  Renderer2,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ibep-bible-ebc',
  templateUrl: './bible-ebc.component.html',
  styles: [
    `
      .grad {
        background: linear-gradient(
          0deg,
          white 20%,
          rgba(255, 255, 255, 0) 100%
        );
      }

      /* Hide scrollbar for Chrome, Safari and Opera */
      .no-scrollbar::-webkit-scrollbar {
        display: none;
      }

      /* Hide scrollbar for IE, Edge and Firefox */
      .no-scrollbar {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
      }
    `,
  ],
})
export class BibleEbcComponent implements OnDestroy {
  public articles: any[] = [];
  public filteredArticles: any[] = [];
  public categories: any[] = [];
  public tags: any[] = [];
  public showCategoryFilter = false;
  public chosenTagSlug?: string;

  @Output() pushGtmTag = new EventEmitter<object>();
  @Output() changeModalPosition = new EventEmitter<boolean>();

  @Input() isLoadingEbc: boolean;
  @Input() isAuthenticated: boolean;
  @Input() isPremium: boolean;
  @Input() ebcCategories: any[];
  @Input() ebcModalPosition: '15%' | '50%' | '100%';

  @Input() set ebcArticles(data: any) {
    // TODO: check if sorting works, registered and premium articles should go first
    this.articles = data?.articles
      .sort((a, b) => {
        if (a.accesslevel === 'Premium' && b.accesslevel === 'Free') {
          return -1;
        }
        if (a.accesslevel === 'Premium' && b.accesslevel === 'Registered') {
          return -1;
        }
        if (a.accesslevel === 'Registered' && b.accesslevel === 'Free') {
          return -1;
        }
        if (a.accesslevel === 'Registered' && b.accesslevel === 'Premium') {
          return 1;
        }
        return 0;
      })
      .map((article) => {
        // format links to other articles
        const split = article.description
          ?.split(/{{|}}/)
          .map((part) => {
            if (part.startsWith('topic_')) {
              const sPart = part.split('_');
              return `<a data-href="/${this._translate.instant(
                'ROUTES.ebc'
              )}/redirect/legacyId/${sPart[1]}" id="routerlink">${
                sPart[2]
              }</a>`;
            }
            return part;
          })
          .join('');
        article.description = split;
        return article;
      });

    this.filteredArticles = this.articles ? [...this.articles] : [];

    this.articles?.forEach((article) => {
      // get tags existing in current articles
      article.tags?.forEach((tag: any) => {
        if (!this.tags.find((item) => item.slug === tag.slug)) {
          this.tags.push(tag);
        }
      });
    });
  }

  @ViewChild('categoryFilterButton')
  private categoryFilterButton!: ElementRef;
  @ViewChild('categoryFilter')
  private categoryFilter!: ElementRef;
  private listenFn: any;

  constructor(
    private readonly _renderer: Renderer2,
    private readonly _ref: ChangeDetectorRef,
    private readonly _router: Router,
    private readonly _translate: TranslateService
  ) {}

  public filterEbcByTag(tagSlug: string) {
    this.chosenTagSlug = this.chosenTagSlug === tagSlug ? undefined : tagSlug;
    this._updateFilteredArticles();
  }

  public filterEbcByCategory(category: any, subcategory?: any) {
    if (subcategory) {
      // make 1-level category checked if all subcategories are checked
      category.checked =
        subcategory.checked &&
        category.subcategories.every((item: any) => item.checked);
    } else {
      // update all subcategories check state depending on the parent state
      category.subcategories.forEach((item: any) => {
        item.checked = category.checked;
      });
    }

    this._updateFilteredArticles();
  }

  private _updateFilteredArticles(): void {
    this.filteredArticles = this.articles.filter((article) => {
      return (
        // filter by tags
        (!this.chosenTagSlug ||
          article.tags?.some(
            (articleTag: any) => articleTag.slug === this.chosenTagSlug
          )) &&
        // filter by categories
        // firstly we check if all categories and subcategories are not checked
        (this.ebcCategories?.every(
          (cat: any) =>
            !cat.checked &&
            cat.subcategories.every((subcat: any) => !subcat.checked)
        ) ||
          // then we match checked categories and subcategories with ebc articles
          this.ebcCategories?.some((category) => {
            if (category.checked) {
              return article.categories?.some(
                (cat: any) =>
                  cat.slug.toUpperCase() ===
                  encodeURIComponent(category.slug).toUpperCase()
              );
            }

            // filter by subcategories
            const checkedSubcategories = category.subcategories.filter(
              (subcat: any) => subcat.checked
            );
            if (checkedSubcategories.length) {
              return article.categories?.some((cat: any) => {
                return cat.subcategories?.some((subcat: any) => {
                  return checkedSubcategories.find(
                    (chekedSubcat: any) => chekedSubcat.slug === subcat.slug
                  );
                });
              });
            }
          }))
      );
    });
  }

  public changeEbcModalPosition() {
    this.changeModalPosition.emit(true);
  }

  public openModal(article: any) {
    this.pushEbcArticleGtmEvent(article);

    if (
      (article.accesslevel === 'Premium' && this.isPremium) ||
      (article.accesslevel === 'Registered' && this.isAuthenticated)
    ) {
      this._router.navigate([], {
        queryParams: { slug: article.slug, ebc: true },
      });
    } else {
      this._router.navigate([], {
        queryParams: { view: 'signUp' },
      });
    }
  }

  public pushEbcArticleGtmEvent(article: any): void {
    this.pushGtmTag.emit({
      event: 'open_ebc_item',
      ebc_category: article.categories
        ?.map((category: any) => category.title)
        ?.join(', '),
      ebc_article_name: article.title,
    });
  }

  /**
   *  Toggle the category filter popup
   */
  public toggleCategoryFilter() {
    if (!this.showCategoryFilter) {
      this._registerListener();
    }
    this.showCategoryFilter = !this.showCategoryFilter;
  }

  /**
   *  To be able to close the category filter popup when clicking outside the popup, we need to set up a listener
   */
  private _registerListener() {
    // listen for clicks outside popup
    this.listenFn = this._renderer.listen('window', 'click', (e: Event) => {
      if (
        !this.categoryFilterButton?.nativeElement.contains(e.target) &&
        !this.categoryFilter?.nativeElement.contains(e.target) &&
        this.showCategoryFilter
      ) {
        // close the popup
        this.showCategoryFilter = false;
        // unregister the listener
        this.listenFn();
        // we need to detect changes manually
        this._ref.detectChanges();
      }
    });
  }

  /**
   *  Stop listening for clicks on destroy of component
   */
  ngOnDestroy(): void {
    if (this.listenFn) {
      this.listenFn();
    }
  }
}
