import { NgModule } from '@angular/core';
import { FeWebCoreModule } from '@ibep/fe/web/core';
import { FeWebComponentsSharedModule } from '@ibep/fe/web/components-shared';
import { FeWebComponentsBibleSharedModule } from '@ibep/fe/web/components-bible-shared';
import { FormsModule } from '@angular/forms';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { RouterModule } from '@angular/router';
import { BibleChapterComponent } from './bible-chapter/bible-chapter.component';
import { BibleColumnComponent } from './bible-column/bible-column.component';
import { OptionsPickerComponent } from './options-picker/options-picker.component';
import { BibleNavComponent } from './bible-nav/bible-nav.component';
import { BibleInfoComponent } from './bible-info/bible-info.component';
import { BibleSelectionToolbarComponent } from './bible-toolbar/bible-selection-toolbar.component';
import { BibleReaderComponent } from './bible-reader/bible-reader.component';
import { UserNoteToolbarComponent } from './user-note-toolbar/user-note-toolbar.component';
import { BibleEbcComponent } from './bible-ebc/bible-ebc.component';
import { CopyOptionsPickerComponent } from './copy-options-picker/copy-options-picker.component';

@NgModule({
  imports: [
    CommonModule,
    FeWebCoreModule,
    RouterModule,
    TranslateModule.forChild(),
    FormsModule,
    LocalizeRouterModule,
    FeWebComponentsSharedModule,
    FeWebComponentsBibleSharedModule,
    NgOptimizedImage,
  ],
  declarations: [
    BibleChapterComponent,
    BibleColumnComponent,
    OptionsPickerComponent,
    BibleNavComponent,
    BibleInfoComponent,
    BibleSelectionToolbarComponent,
    UserNoteToolbarComponent,
    BibleReaderComponent,
    BibleEbcComponent,
    CopyOptionsPickerComponent,
  ],
  exports: [
    BibleChapterComponent,
    BibleColumnComponent,
    OptionsPickerComponent,
    BibleNavComponent,
    BibleInfoComponent,
    BibleSelectionToolbarComponent,
    UserNoteToolbarComponent,
    BibleReaderComponent,
    BibleEbcComponent,
  ],
})
export class FeWebComponentsBibleModule {}
