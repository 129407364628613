<div [id]="props.id" class="hidden">
  <div class="flex gap-4 bg-primary bg-opacity-10 p-4 mb-4">
    <ng-container [ngSwitch]="props.icon">
      <div *ngSwitchCase="'bible'">
        <ibep-book-svg svgStyles="w-4 h-4"></ibep-book-svg>
      </div>
      <div *ngSwitchCase="'note'">
        <ibep-pencil-square-svg svgStyles="w-4 h-4"></ibep-pencil-square-svg>
      </div>
      <div *ngSwitchCase="'globe'">
        <ibep-globe-svg svgStyles="w-4 h-4"></ibep-globe-svg>
      </div>
      <div *ngSwitchCase="'anchor'">
        <ibep-link-svg svgStyles="w-4 h-4"></ibep-link-svg>
      </div>
    </ng-container>
    <div *ngIf="props.text">
      <ibep-paragraph [html]="props.text"></ibep-paragraph>
    </div>
  </div>
</div>
