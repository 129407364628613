<div class="flex flex-wrap gap-2">
  <ng-container *ngFor="let button of props">
    <a
      *ngIf="!button.hidden"
      [routerLink]="('/ROUTES.search' | localize) + '/' + button.value"
      [queryParams]="{
        query: query,
        bibleId:
          button.value === searchCategoryEnum.BIBLE ||
          button.value === searchCategoryEnum.ALL
            ? bibleId
            : null
      }"
      [queryParamsHandling]="'merge'"
    >
      <ibep-button
        [buttonProps]="{
          pushGtm: true,
          content: button.text,
          style: button.checked ? 'secondary' : 'gray',
          autoWidth: true
        }"
        (click)="onButtonClicked(button)"
      >
        {{ button.text }}
      </ibep-button>
    </a>
  </ng-container>
</div>
