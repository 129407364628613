import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  ReaderStateInterface,
  ReaderStateSelectedBiblesInterface,
} from '@ibep/fe/shared/data';
import { Bible, BibleMetadata, ColumnsLimit, Language } from '@ibep/interfaces';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, distinctUntilChanged, filter, fromEvent } from 'rxjs';

@Component({
  selector: 'ibep-translation-picker',
  templateUrl: './translation-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TranslationPickerComponent implements OnInit, AfterViewInit {
  @Input() bibles!: Bible[];

  @Input()
  selectedBibles!: ReaderStateSelectedBiblesInterface;

  @Input() selectedChapter!: ReaderStateInterface['selectedChapter'];

  @Input() bibleMetadata: BibleMetadata;

  @Input() maxActiveColumns = ColumnsLimit.SINGLE;

  @ViewChild('searchInput') searchInput: ElementRef;

  @Input() isAuthenticated = false;
  @Input() isPremium = false;
  @Input() isSearchPage = false;
  @Input() userAccountSystem: boolean;
  @Input() isUserNotesTab: boolean;

  @Output() modalClosed = new EventEmitter<boolean>();

  @Output() changeSelectedBibles =
    new EventEmitter<ReaderStateSelectedBiblesInterface>();

  @Output() pushGtmTag = new EventEmitter<object>();

  public languages!: Language[];

  public bibleSelection: string[];

  public columnsLimitEnum = ColumnsLimit;

  constructor(
    private readonly _cdRef: ChangeDetectorRef,
    private readonly _translateService: TranslateService,
    private readonly _router: Router
  ) {}

  ngOnInit(): void {
    if (this.bibles) {
      if (!this.selectedBibles || !this.selectedBibles.bibleIds) {
        this.selectedBibles = {
          bibleAbbrs: [this.bibleMetadata.abbreviation],
          bibleIds: [this.bibleMetadata.id],
        };
      }
      if (!this.selectedChapter && !this.isSearchPage) {
        this.selectedChapter =
          this.bibleMetadata.testaments[0]?.books[0]?.chapters[0]?.id;
      }
      this.bibles = this._mapBibles(this.bibles, this.selectedBibles);
      this.languages = this._getLanguages(this.bibles);
      this.bibleSelection = [...this.selectedBibles.bibleIds];
    }
  }

  ngAfterViewInit(): void {
    // Listen for search queries
    fromEvent(this.searchInput.nativeElement, 'keyup')
      .pipe(filter(Boolean), debounceTime(400), distinctUntilChanged())
      .subscribe((res) => {
        this._filterBibles(this.searchInput.nativeElement.value);
      });
  }

  public toggleBible(bible: Bible): void {
    if (this.maxActiveColumns > ColumnsLimit.SINGLE) {
      if (this.bibleSelection.includes(bible.id)) {
        // if already selected, remove from bible selection
        this.bibleSelection = this.bibleSelection.filter(
          (bibleItem) => bibleItem !== bible.id
        );
      } else {
        // if bible is not yet selected, add the bible to bible Selection
        this.bibleSelection.push(bible.id);
      }
    } else if (this.maxActiveColumns === ColumnsLimit.SINGLE) {
      this.bibleSelection = [bible.id];
      this.languages.forEach((language: any) => {
        language.bibles.forEach((bibleItem: any) => {
          bibleItem.isSelected = bibleItem.id === bible.id;
        });
      });
    }
    this.changeSelectedBibles.emit({
      bibleAbbrs: this.getBibleSelectedAbbrs(),
      bibleIds: this.bibleSelection,
    });
  }

  public getBibleSelectedAbbrs(): string[] {
    return this.bibles
      .filter((b) => this.bibleSelection.includes(b.id))
      .map((b) => b.abbreviation);
  }

  public checkAudio(bible: Bible): boolean {
    if (!bible.hasAudio) {
      return false;
    }
    if (bible.audioAccessLevelWeb === 'nolicense') {
      return false;
    }
    // if (bible.audioAccessLevelWeb === 'Registered' && !this.isAuthorized) {
    //   return false;
    // }
    // if (bible.audioAccessLevelWeb === 'Premium' && !this.isPremium) {
    //   return false;
    // }
    return true;
  }

  /**
   * Close the modal
   *
   * @memberof TranslationPickerComponent
   */
  public closeModal(): void {
    setTimeout(() => {
      this.modalClosed.emit(true);
    }, 100);
  }

  public openDisabledModal(accessLevel: string): void {
    this.closeModal();
    if (accessLevel === 'registered') {
      this._router.navigate([], {
        queryParams: { view: 'signUp' },
      });
    }
    if (accessLevel === 'premium') {
      this._router.navigate([], {
        queryParams: { view: 'premium' },
      });
    }
  }

  public onGtmTagPush(translation: string): void {
    this.pushGtmTag.emit({
      event: 'add_translation',
      bible_translation: translation,
      bible_chapter: this.selectedChapter,
    });
  }

  /**
   * Search: filter the bibles
   *
   * @private
   * @param {string} keyword
   * @memberof TranslationPickerComponent
   */
  private _filterBibles(keyword: string): void {
    // check if keyword is in bible abbreviation or name
    const filteredBibles = this.bibles.filter(
      (bible) =>
        bible.name.toLowerCase().includes(keyword.toLowerCase()) ||
        bible.abbreviation.toLowerCase().includes(keyword.toLowerCase())
    );
    this.languages = this._getLanguages(filteredBibles);
    // we need to manually detect the changes
    this._cdRef.detectChanges();
  }

  private _getLanguages(bibles: Bible[]): Language[] {
    // create languages array
    return bibles
      .map((bible: Bible) => ({
        ...bible.language,
        nameLocal: this._translateService.instant(
          `LANGUAGES.${bible.language.name}` || 'Other'
        ),
      }))
      .filter(
        (lang: Language, pos: number, langs: Language[]) =>
          langs.map((l: Language) => l.id).indexOf(lang.id) === pos
      )
      .map((language: Language) => {
        language.bibles = bibles
          .filter((bible: Bible) => bible.language.id === language.id)
          .sort((a, b) => a.position - b.position);
        return language;
      });
  }

  private _mapBibles(
    bibles: Bible[],
    selectedBibles: ReaderStateSelectedBiblesInterface
  ): Bible[] {
    return bibles
      .map((bible: Bible) => {
        const isSelected = selectedBibles.bibleIds.includes(bible.id);
        let isDisabled = false;
        if (bible.accessLevel === 'registered') {
          isDisabled = !this.isAuthenticated;
        }
        if (bible.accessLevel === 'premium') {
          isDisabled =
            !this.isAuthenticated || (this.isAuthenticated && !this.isPremium);
        }

        return {
          ...bible,
          isSelected,
          isDisabled,
        };
      })
      .filter((bible) => this.userAccountSystem || !bible.isDisabled);
  }
}
