import { ElementRef, Inject, Injectable, OnDestroy } from '@angular/core';
import { Params, Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { LanguageService, WINDOW } from '@ibep/fe/shared/core';
import {
  BibleMetaData,
  ChapterAudioData,
  ChapterData,
  ConfigData,
  EbcArticlesData,
  ReaderStateData,
  ReaderStateInterface,
  SearchBibleData,
  UserHighlightsData,
  UserNote,
  UserNotesData,
} from '@ibep/fe/shared/data';
import {
  AbstractAuthService,
  AvailableLanguage,
  Bible,
  BibleMetadata,
  Brand,
  Chapter,
  Column,
  ColumnsLimit,
  Config,
  GetBibleMetadataResponse,
  GetChapterStudyContentResponse,
  Testament,
} from '@ibep/interfaces';
import { arrayIntersect, range } from '@ibep/shared/util';
import { TranslateService } from '@ngx-translate/core';
import {
  BehaviorSubject,
  combineLatest,
  forkJoin,
  Observable,
  of,
  ReplaySubject,
  Subscription,
  throttleTime,
  zip,
} from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  pairwise,
  startWith,
  switchMap,
  take,
} from 'rxjs/operators';

export type Scrolled = {
  verseId: string;
  bibleAbbr: string;
  direction: 'up' | 'down';
};
@Injectable({
  providedIn: 'root',
})
export class BibleService implements OnDestroy {
  private _hovered$ = new BehaviorSubject(['']);
  private _selected$ = new BehaviorSubject({
    activeColumn: '',
    verseOrgIds: [''],
    verseIds: [''],
    combinedVerse: false,
  });
  private _flashed$ = new BehaviorSubject(['']);
  private _scrolled$ = new ReplaySubject<Omit<Scrolled, 'direction'>>(1);
  private _activeScrollColumn$ = new ReplaySubject<string>(1);
  private _isLoading$ = new BehaviorSubject(true);
  private _isLoadingEbc$ = new BehaviorSubject(true);
  private _config: Config;
  private _isAuthenticated: boolean;
  private _isPremium: boolean;
  private _maxActiveColumns = ColumnsLimit.SINGLE;
  private _subscriptions: Subscription[] = [];
  private _readerState: ReaderStateInterface;
  private _updateReader$ = new BehaviorSubject(true);
  private _currentLanguage: string;
  private _isDefaultLanguage: boolean;

  public readonly hovered$ = this._hovered$.asObservable().pipe(
    distinctUntilChanged(),
    filter((hovered) => !hovered.includes(''))
  );

  public readonly selected$ = this._selected$
    .asObservable()
    .pipe(filter((selected) => !selected.verseOrgIds.includes('')));

  public readonly flashed$ = this._flashed$
    .asObservable()
    .pipe(filter((flashed) => !flashed.includes('')));

  public readonly scrolled$: Observable<Scrolled> = this._scrolled$
    .asObservable()
    .pipe(
      distinctUntilChanged((x, y) => x.verseId === y.verseId),
      startWith(undefined),
      pairwise(),
      throttleTime(100),
      map(([prev, current]) => {
        const prevId = prev?.verseId?.split('.').pop() || '-1';
        const currentId = current?.verseId?.split('.').pop() || '0';
        let scrolledUp = false;
        if (!Number.isNaN(+prevId) || !Number.isNaN(+currentId)) {
          scrolledUp = +prevId > +currentId;
        }

        return { ...current!, direction: scrolledUp ? 'up' : 'down' };
      })
    );

  public readonly activeScrollColumn$ =
    this._activeScrollColumn$.asObservable();

  public readonly isLoading$ = this._isLoading$.asObservable();
  public readonly isLoadingEbc$ = this._isLoadingEbc$.asObservable();

  public set hovered(verseOrgIds: string[]) {
    this._hovered$.next(verseOrgIds);
  }

  public set selected({
    verseOrgIds,
    verseIds,
    activeColumn,
  }: {
    verseOrgIds: string[];
    verseIds: string[];
    activeColumn: string;
  }) {
    // clear
    if (!verseOrgIds.length) {
      this._selected$.next({
        verseOrgIds: [],
        verseIds: [],
        activeColumn: '',
        combinedVerse: false,
      });
    }
    // get the current selection
    const currentOrgIdSelection =
      this._selected$.value.verseOrgIds[0] === ''
        ? []
        : this._selected$.value.verseOrgIds;
    const currentIdSelection =
      this._selected$.value.verseIds[0] === ''
        ? []
        : this._selected$.value.verseIds;
    // no verse selected yet = select verse
    if (currentOrgIdSelection.length === 0) {
      this._selected$.next({
        verseOrgIds: [...verseOrgIds],
        verseIds: [...verseIds],
        activeColumn,
        combinedVerse: verseOrgIds.length > 1,
      });
      // second click on same verse = deselect all verse(s)
    } else if (arrayIntersect(currentOrgIdSelection, verseOrgIds)) {
      this._selected$.next({
        verseOrgIds: [],
        verseIds: [],
        activeColumn: '',
        combinedVerse: false,
      });
      // click on second verse = select multiple verses
    } else if (currentOrgIdSelection.length === 1) {
      const verseOrgIdRange = this.getVerseRange([
        ...currentOrgIdSelection,
        verseOrgIds[0],
      ]);
      const verseIdRange = this.getVerseRange([
        ...currentIdSelection,
        verseIds[0],
      ]);
      this._selected$.next({
        verseOrgIds: verseOrgIdRange,
        verseIds: verseIdRange,
        activeColumn,
        combinedVerse: false,
      });
      // click on second verse, when first click is on a combined verse, for example NUM.1.2-3
    } else if (this._selected$.value.combinedVerse) {
      const startOrgIdRange =
        currentOrgIdSelection[0].split('.')[2] > verseOrgIds[0].split('.')[2]
          ? currentOrgIdSelection[currentOrgIdSelection.length - 1]
          : currentOrgIdSelection[0];
      const verseOrgIdRange = this.getVerseRange([
        startOrgIdRange,
        verseOrgIds[0],
      ]);

      const startIdRange =
        currentIdSelection[0].split('.')[2] > verseIds[0].split('.')[2]
          ? currentIdSelection[currentIdSelection.length - 1]
          : currentIdSelection[0];
      const verseIdRange = this.getVerseRange([startIdRange, verseIds[0]]);
      this._selected$.next({
        verseOrgIds: verseOrgIdRange,
        verseIds: verseIdRange,
        activeColumn,
        combinedVerse: false,
      });
      // already multiple verses selected = deselect all verses
    } else if (currentOrgIdSelection.length > 1) {
      this._selected$.next({
        verseOrgIds: [],
        verseIds: [],
        activeColumn: '',
        combinedVerse: false,
      });
    }
  }

  public set flashed(verseOrgIds: string[]) {
    this._flashed$.next(verseOrgIds);
  }

  public set scrolled(scrolled: Omit<Scrolled, 'direction'>) {
    this._scrolled$.next(scrolled);
  }

  public set activeScrollColumn(bibleAbbr: string) {
    this._activeScrollColumn$.next(bibleAbbr);
  }

  public get maxActiveColumns(): ColumnsLimit {
    return this._maxActiveColumns;
  }

  public set maxActiveColumns(columns: ColumnsLimit) {
    this._maxActiveColumns = columns;
  }

  public set updateReader(val: boolean) {
    this._updateReader$.next(true);
  }

  constructor(
    @Inject(WINDOW) private _window: Window,
    private readonly _router: Router,
    private readonly _localizeRouterService: LocalizeRouterService,
    private readonly _chapterData: ChapterData,
    private readonly _chapterAudioData: ChapterAudioData,
    private readonly _bibleMetaData: BibleMetaData,
    private readonly _searchBibleData: SearchBibleData,
    private readonly _userHighlightsData: UserHighlightsData,
    private readonly _userNotesData: UserNotesData,
    private readonly _configData: ConfigData,
    private readonly _readerStateData: ReaderStateData,
    private readonly _ebcArticlesData: EbcArticlesData,
    private readonly _languageService: LanguageService,
    private readonly _translateService: TranslateService,
    private readonly _authService: AbstractAuthService
  ) {
    this._configData.getConfig().subscribe((config) => (this._config = config));

    this._languageService.currentLanguage$.subscribe((language) => {
      this._currentLanguage = language;
      this._isDefaultLanguage = this._languageService.isDefaultLanguage;
    });

    this._authService.isAuthenticated$.subscribe((isAuthenticated) => {
      this._isAuthenticated = isAuthenticated;
    });
    this._authService.isPremium$.subscribe(
      (isPremium) => (this._isPremium = isPremium)
    );
    this._readerStateData
      .getReaderState()
      .subscribe((state) => (this._readerState = state));
  }

  /**
   * This function contains the main business logic for the bible reader.
   * It starts with changes in the route.
   *
   * @param {*} routeParams$
   * @returns
   * @memberof BibleService
   */
  public getBibleDataForCurrentRoute(
    routeParams$: Observable<Params>
  ): Observable<Column[]> {
    // check auth status
    return combineLatest([
      this._authService.isAuthenticated$,
      this._authService.isPremium$,
      this._readerStateData.getReaderState().pipe(take(1)),
    ]).pipe(
      switchMap(([isAuthenticated, isPremium, readerState]) => {
        this._isAuthenticated = isAuthenticated;
        this._isPremium = isPremium;
        this._readerState = readerState;
        // check route params
        return routeParams$;
      }),
      switchMap(({ bibleAbbrs, chapterId }: Params) => {
        this._isLoading$.next(true);
        this._isLoadingEbc$.next(true);
        // clear some data that needs to be cleared
        this._selected$.next({
          verseOrgIds: [],
          verseIds: [],
          activeColumn: '',
          combinedVerse: false,
        });
        this._hovered$.next([]);

        // checks for corrupt/duplicate data
        let bibleIds: Bible['id'][] | undefined;
        const bibles = this._config.bibles
          // remove entitlement if api data is corrupt (null)
          .filter((bible) => bible)
          // remove duplicate entitlements from api data
          .filter(
            (v, i, a) =>
              a.findIndex((v2) => v2.abbreviation === v.abbreviation) === i
          );

        // take the bibleAbbreviation(s) from the url and look for the corresponding apiBibleId(s)
        if (bibleAbbrs && bibleAbbrs !== '0') {
          bibleAbbrs = bibleAbbrs.split(',');
          // find bibleIds
          bibleIds = bibles
            .filter((bible: Bible) => bibleAbbrs.includes(bible.abbreviation))
            // make sure the bibleIds are sorted the same way as the abbrs from url
            .sort(
              (a: Bible, b: Bible) =>
                bibleAbbrs.indexOf(a.abbreviation) -
                bibleAbbrs.indexOf(b.abbreviation)
            )
            .map((bible: Bible) => bible.id);
          // if there is no abbr provided in the url
        } else {
          // and the user has read a bible before, we select the last used bibles
          // eslint-disable-next-line no-lonely-if
          if (this._readerState.selectedBibles?.bibleIds?.length) {
            bibleIds = this._readerState.selectedBibles?.bibleIds;
          } else {
            // else we select the default bible
            bibleIds = this.getDefaultBibleId(this._config.brand, bibles);
          }
        }
        // fallback for not existing bible in url
        if (bibleIds?.length === 0) {
          // bibleIds = this.getDefaultBibleId(this._config.brand, bibles);
          this._redirectTo404();
        }
        // get the bible metadata for the selected bibles
        const biblesMetadata$: Observable<GetBibleMetadataResponse>[] =
          bibleIds!.map((bibleId: string) =>
            this._bibleMetaData
              .getBible({ bibleId })
              // fallback if getBible api call goes wrong
              .pipe(
                catchError((error) => {
                  if (error.status === 403 && bibleIds!.length === 1) {
                    const defaultBibleId = this.getDefaultBibleId(
                      this._config.brand,
                      this._config.bibles
                    )?.[0];
                    if (defaultBibleId) {
                      const defaultBibleAbbr =
                        this.getBibleAbbrFromId(defaultBibleId);
                      const forbiddenBible = this._config.bibles.find(
                        (bible) => bible.id === bibleId
                      );

                      let paramValue = '';
                      if (forbiddenBible?.accessLevel === 'registered') {
                        paramValue = 'signUp';
                      }
                      if (forbiddenBible?.accessLevel === 'premium') {
                        paramValue = 'premium';
                      }

                      this.redirect(
                        [defaultBibleAbbr],
                        chapterId,
                        false,
                        true,
                        { view: paramValue }
                      );

                      return this._bibleMetaData
                        .getBible({
                          bibleId: defaultBibleId,
                        })
                        .pipe(
                          catchError((error) => {
                            if (error.status === 403) {
                              this._authService.logOut(true);
                            }
                            return of(
                              null as unknown as GetBibleMetadataResponse
                            );
                          })
                        );
                    }
                  }
                  return of(null as unknown as GetBibleMetadataResponse);
                })
              )
          );

        return combineLatest([
          forkJoin(biblesMetadata$),
          // we pass on the following data, because we need it in the next switchMap
          of({
            selectedChapter: chapterId,
            selectedBibles: {
              bibleAbbrs,
              bibleIds,
            },
          }),
          this._updateReader$,
        ]);
      }),
      switchMap(
        ([biblesMetadata, incomingSelected, update]: [
          GetBibleMetadataResponse[],
          any,
          boolean
        ]) => {
          this._isLoading$.next(true);
          this._isLoadingEbc$.next(true);
          const selected = incomingSelected;
          this._searchBibleData.searchBibleId =
            incomingSelected.selectedBibles.bibleIds[0];
          // if there is no chapterId provided in the url: get the default chapterId
          if (selected.selectedChapter) {
            selected.selectedChapter = this.formatChapter(
              selected.selectedChapter,
              biblesMetadata?.[0]?.data?.testaments
            );
          } else {
            // check if the user has read a chapter before
            if (this._readerState.selectedChapter) {
              selected.selectedChapter = this._readerState.selectedChapter;
              // else get the default chapterId
            } else {
              selected.selectedChapter = this.getDefaultChapterId(
                biblesMetadata[0]?.data
              );
            }
            // redirect if we know the bibleAbbrs
            if (selected.selectedBibles?.bibleAbbrs?.length) {
              this.redirect(
                selected.selectedBibles.bibleAbbrs,
                selected.selectedChapter
              );
            }
          }
          // redirect if we know the bibleIds from the readerstate but not the bibleAbbrs
          if (
            selected.selectedChapter &&
            this._readerState.selectedBibles?.bibleAbbrs?.length &&
            !selected.selectedBibles?.bibleAbbrs?.length &&
            selected.selectedBibles.bibleIds.length
          ) {
            selected.selectedBibles.bibleAbbrs =
              this._readerState.selectedBibles?.bibleAbbrs;
            this.redirect(
              selected.selectedBibles.bibleAbbrs,
              selected.selectedChapter
            );
          }
          // if there is no bible abbreviation provided in the url: get the defaults and redirect
          if (
            !selected.selectedBibles.bibleAbbrs ||
            selected.selectedBibles.bibleAbbrs === '0'
          ) {
            selected.selectedBibles.bibleAbbrs = [
              biblesMetadata[0]?.data.abbreviation,
            ];
            selected.selectedBibles.bibleIds = [biblesMetadata[0]?.data.id];
            // rewrite the url
            this.redirect(
              selected.selectedBibles.bibleAbbrs,
              selected.selectedChapter
            );
          }
          // If there are more bibles selected then allowed, remove them
          if (
            selected.selectedBibles.bibleAbbrs.length > this._maxActiveColumns
          ) {
            selected.selectedBibles.bibleAbbrs =
              selected.selectedBibles.bibleAbbrs.slice(
                0,
                this._maxActiveColumns
              );
            selected.selectedBibles.bibleIds =
              selected.selectedBibles.bibleIds.slice(0, this._maxActiveColumns);
            this.redirect(
              selected.selectedBibles.bibleAbbrs,
              selected.selectedChapter
            );
          }
          // get the full chaptername
          if (selected.selectedChapter && biblesMetadata[0]?.data) {
            selected.selectedChapterFullname = this.getFullChapterName(
              selected.selectedChapter,
              biblesMetadata[0]?.data.testaments
            );
            // get the full bookname
            selected.selectedBookFullname = this.getFullBookName(
              selected.selectedChapter,
              biblesMetadata[0]?.data.testaments
            );
            // get the chapter number
            selected.selectedChapterNumber =
              selected.selectedChapter.split('.')[1];
          }
          // if the selected chapter contains a (range of) verse(s): flash the verse(s)
          const splittedChapter = selected?.selectedChapter.split('.');

          if (splittedChapter.length > 2) {
            // get array of verseOrgIds
            // TODO: currently supported flash format GEN.1.1 or GEN.1.1-GEN.1.3, not GEN.1.1-3 or GEN.1.1-1.3
            const verseOrgIds =
              splittedChapter.length === 3
                ? [selected.selectedChapter]
                : this.getVerseRange(selected.selectedChapter.split('-'));
            // flash verses
            this.flashed = verseOrgIds;
            // replace the verse range for chapterId
            selected.selectedChapter = splittedChapter.slice(0, 2).join('.');
            // rewrite the url
            this.redirect(
              selected.selectedBibles.bibleAbbrs,
              selected.selectedChapter
            );
          }

          // PERSIST the current Reader State to local storage
          this._readerStateData.setReaderState({ ...selected });

          // CHAPTER DATA: fetch the chapter data with study content for all active bible translations
          const chapters$: Observable<GetChapterStudyContentResponse>[] =
            selected.selectedBibles.bibleIds.map((bibleId: string) =>
              this._chapterData
                .getChapter({
                  bibleId,
                  chapterId: selected.selectedChapter,
                })
                // fallback for 404, chapter not found, or other api error
                .pipe(catchError(() => of(null)))
            );
          // AUDIO DATA: If the chapter has audio, and the user has right accesslevel fetch the chapter audio
          const audioBiblesToFetch = this._createAudioBiblesToFetch(
            this._config.bibles,
            biblesMetadata,
            selected
          );

          let chapterAudio$;
          if (audioBiblesToFetch.length) {
            chapterAudio$ = audioBiblesToFetch.map((selectedBible: any) => {
              const audioChap$ = this._chapterAudioData
                .getAudioChapter({
                  bibleId: selectedBible.id,
                  chapterId: selected.selectedChapter,
                }) // fallback for 404, chapter not found, or other api error
                .pipe(catchError(() => of(null)));
              return combineLatest([audioChap$, of(selectedBible.id)]);
            });
          } else {
            chapterAudio$ = of([]);
          }

          // USER HIGHLIGHTS / NOTES DATA: if authenticated, fetch the user highlights and notes
          let userHighlights$;
          let userNotes$;
          if (this._isAuthenticated) {
            // highlights are connected to a translation, so we need to make a call for all active translations
            userHighlights$ = selected.selectedBibles.bibleIds.map(
              (bibleId: string) =>
                this._userHighlightsData
                  .get({
                    bibleId,
                    chapterId: selected.selectedChapter,
                  })
                  // fallback for 404 or other api error
                  .pipe(catchError(() => of(null)))
            );
            // user notes are only connected to a chapter
            userNotes$ = this._userNotesData
              .get({
                chapterId: selected.selectedChapter,
                bibleId: selected.selectedBibles.bibleIds[0],
              })
              .pipe(catchError(() => of(null)));
          } else {
            // if not authenticated we return empty array
            userHighlights$ = of([]);
            userNotes$ = of([]);
          }

          // EBC ARTICLES
          let ebcArticles$;
          if (this._readerState?.ebcState?.showEbc) {
            ebcArticles$ = this._ebcArticlesData
              .getEbcArticles({
                chapterId: selected.selectedChapter,
                mode: 'short',
              })
              .pipe(
                startWith(null),
                catchError(() => of(null))
              );
          } else {
            ebcArticles$ = of([]);
          }

          return combineLatest([
            forkJoin(chapters$),
            of(biblesMetadata),
            zip(userHighlights$),
            userNotes$,
            zip(chapterAudio$),
            ebcArticles$,
          ]);
        }
      ),
      switchMap(
        ([
          chapters,
          biblesMetadata,
          userHighlights,
          userNotes,
          chapterAudio,
          ebcArticles,
        ]: Array<any>) => {
          // create a bible column for each bible, and add the chapter and user data
          const columns: Column[] = biblesMetadata.map(
            (bible: GetBibleMetadataResponse) => {
              const chapter: GetChapterStudyContentResponse[] = chapters.filter(
                (chap: any) => chap?.data?.chapter.bibleId === bible?.data.id
              );
              const userHighlightsForBible = userHighlights.filter(
                (highlightResponse: any) =>
                  highlightResponse?.[0]?.bibleId === bible?.data.id
              );

              // prepare study sidebar content data
              if (chapter[0]?.data.studyContent?.sidebars) {
                chapter[0]?.data.chapter.content.map((part) => {
                  if (part.type === 'study') {
                    part.content =
                      chapter[0].data.studyContent.sidebars[
                        `${chapter[0].data.chapter.id}.${part.verseId}-${part.id}`
                      ];
                    if (
                      part.content?.content?.[0]?.content?.[1]?.type ===
                      'figure'
                    ) {
                      part.image = true;
                    }
                  }
                  return part;
                });
              }

              return {
                bible: bible?.data,
                chapter: chapter[0] ? chapter[0]?.data.chapter : null,
                studyContent: chapter[0] ? chapter[0].data.studyContent : null,
                userHighlights: userHighlightsForBible[0],
                userNotes,
                chapterAudio,
                ebcArticles: ebcArticles?.data,
              };
            }
          );
          // redirect to default chapter if the first column has no content
          if (!columns[0].chapter) {
            const defaultChapter = this.getDefaultChapterId(columns[0].bible);
            this.redirect(
              columns.map((column: Column) => column.bible.abbreviation),
              defaultChapter
            );
          }
          this._isLoading$.next(false);
          if (ebcArticles?.data) {
            this._isLoadingEbc$.next(false);
          }
          return of(columns);
        }
      )
    );
  }

  private _createAudioBiblesToFetch(
    bibles: any,
    biblesMetadata: any,
    selected: any
  ) {
    return bibles.filter((bible: any) => {
      // check if bible is selected
      if (!selected.selectedBibles.bibleIds.includes(bible.id)) {
        return false;
      }
      // check access level
      if (bible.audioAccessLevelWeb === 'nolicense') {
        return false;
      }
      if (
        bible.audioAccessLevelWeb === 'registered' &&
        !this._isAuthenticated
      ) {
        return false;
      }
      if (bible.audioAccessLevelWeb === 'premium' && !this._isPremium) {
        return false;
      }
      // check if current chapter has related audio
      let chapterHasAudio = false;
      biblesMetadata
        .filter((b: any) => bible.id === b.data.id)[0]
        .data.testaments.find((testament: any) =>
          testament.books.find((book: any) =>
            book.chapters.find((chapter: any) => {
              if (chapter.id === selected.selectedChapter) {
                if (chapter.hasAudio) {
                  chapterHasAudio = true;
                }
                return true;
              }
              return false;
            })
          )
        );
      if (!chapterHasAudio) {
        return false;
      }
      return true;
    });
  }

  /**
   * Get the range of chapters between 2 chapters (inside single book)
   * accepts values like GEN.1 GEN.5
   *
   * @public
   * @param {string} fromChapter
   * @param {string} toChapter
   * @returns {string[]} chapterIds
   * @memberof BibleService
   */

  public getChapterRange(fromChapter: string, toChapter: string): string[] {
    if (fromChapter?.includes('.') && toChapter?.includes('.')) {
      const splittedFromChapter = fromChapter.split('.');
      const splittedToChapter = toChapter.split('.');

      // we check that the book is the same, for example GEN
      if (splittedFromChapter[0] === splittedToChapter[0]) {
        const fromNumber = Number(splittedFromChapter[1]);
        const toNumber = Number(splittedToChapter[1]);

        if (fromNumber < toNumber) {
          const chapterIds = [];
          for (let index = fromNumber; index <= toNumber; index++) {
            chapterIds.push(`${splittedFromChapter[0]}.${index}`);
          }

          return chapterIds;
        }
      }
    }
    return [];
  }

  /**
   * Get the range of verses between 2 verses (inside single chapter)
   *
   * @public
   * @param {string[]} verseOrgIds
   * @returns {string[]} verseOrgIds
   * @memberof BibleService
   */
  public getVerseRange(verseOrgIds: string[]): string[] {
    if (verseOrgIds.length !== 2) {
      return [];
    }
    const chapterA = +verseOrgIds[0].split('.')[1];
    const chapterB = +verseOrgIds[1].split('.')[1];
    // if different chapters are selected we return the verses of the first chapter
    if (chapterA !== chapterB) {
      return [verseOrgIds[0]];
    }
    let verseAStart = +verseOrgIds[0].split('-')[0].split('.')[2];
    let verseBStart = +verseOrgIds[1].split('-')[0].split('.')[2];

    // solution for range verse ids like NUM.2.3-NUM.2.9
    const verseAEnd = verseOrgIds[0].includes('-')
      ? +verseOrgIds[0].split('-')[1].split('.')[2]
      : 0;
    const verseBEnd = verseOrgIds[1].includes('-')
      ? +verseOrgIds[1].split('-')[1].split('.')[2]
      : 0;

    if (verseAEnd && verseAStart > verseBStart) {
      verseAStart = verseAEnd;
    }

    if (verseBEnd && verseBStart > verseAStart) {
      verseBStart = verseBEnd;
    }

    // we make sure the order of verses is sorted
    // somehow Array.sort works in Mozilla and Chrome differently, so we couldn't use it
    if (verseAStart > verseBStart) {
      verseOrgIds = [verseOrgIds[1], verseOrgIds[0]];
    }
    // check if two successive verses are selected
    if (verseAStart + 1 === verseBStart) {
      return verseOrgIds;
    }
    // get the numbers inbetween the two selected verses
    const numberRange =
      verseAStart > verseBStart
        ? range(verseBStart, verseAStart, 1)
        : range(verseAStart, verseBStart, 1);
    // firstPart can be for example: GEN.1
    const firstPart = verseOrgIds[0].split('.');

    return numberRange.map(
      (number) => `${firstPart[0]}.${firstPart[1]}.${number}`
    );
  }

  /**
   * Calculate the distance between verses
   *
   * @param {string} verseId1
   * @param {string} verseId2
   * @returns {number}
   * @memberof BibleService
   */
  public getDistanceBetweenVerses(verseId1: string, verseId2: string): number {
    return Math.abs(
      Number(verseId1?.split('.')?.pop()) - Number(verseId2?.split('.')?.pop())
    );
  }

  /**
   * Write the bibleAbbr(s) and chapterId to the url
   *
   * @param {Array<string>} bibleAbbrs
   * @param {string} chapterId
   * @memberof BibleService
   */
  public redirect(
    bibleAbbrs: Array<string>,
    chapterId: string,
    skipLocationChange = false,
    replaceUrl = true,
    queryParams = {}
  ) {
    const bibleRoute = this._translateService.instant('ROUTES.bible');
    this._router.navigate(
      [
        `${
          this._isDefaultLanguage ? '' : `${this._currentLanguage}/`
        }${bibleRoute}/${bibleAbbrs.join(',')}/${chapterId}/`,
      ],
      { skipLocationChange, replaceUrl, queryParams }
    );
  }

  /**
   * get the default bibleId
   *
   * @param {Brand} brand
   * @param {Bible[]} entitlements
   * @returns {Bible['id'][]}
   * @memberof BibleService
   */
  public getDefaultBibleId(
    brand: Brand,
    bibles: Bible[]
  ): string[] | undefined {
    if (brand && bibles) {
      // get the default bible for the current language from the brand object
      const defaultBibleId = brand.availableLanguages.find(
        (language: AvailableLanguage) =>
          language.languageCode === this._currentLanguage
      )?.defaultBibleId;

      // fallback for situation the selected default bible is not in the bibles / entitlements (error in api/brand editor)
      const isDefaultBibleAvailable =
        bibles.find((bible) => bible.id === defaultBibleId) !== undefined;
      if (isDefaultBibleAvailable) {
        return [defaultBibleId as string];
      }
      return [bibles[0]?.id as string];
    }
  }

  /**
   * get the default chapterId
   *
   * @param {BibleMetadata} bible
   * @returns {Chapter['id']}
   * @memberof BibleService
   */
  public getDefaultChapterId(bible: BibleMetadata): Chapter['id'] {
    return bible?.testaments[0]?.books[0]?.chapters[0].id;
  }

  /**
   * Get the bibleId from a bible abbreviation
   * NBV21 -> 01b58d476a09e6a6-01
   *
   * @param {string} abbr
   * @returns {string}
   * @memberof BibleService
   */
  public getBibleIdFromAbbr(abbr: string): string {
    const filteredBibles = this._config.bibles.filter(
      (bible) => bible.abbreviation === abbr
    );
    return filteredBibles[0]?.id;
  }

  /**
   * Get the bibleAbbreviation from a bibleId
   * 01b58d476a09e6a6-01 -> NBV21
   *
   * @param {string} bibleId
   * @returns {string}
   * @memberof BibleService
   */
  public getBibleAbbrFromId(bibleId: string): string {
    const filteredBibles = this._config.bibles.filter(
      (bible) => bible.id === bibleId
    );
    return filteredBibles[0]?.abbreviation;
  }

  /**
   * Format chapter name
   * we check if we get chapter long name or abbreviation name from the url
   * then we return chapterId instead of that
   *
   * GEN.1 --> GEN.1
   * Genesis 1 --> GEN.1
   * Genesis-1 --> GEN.1
   * Gen.1 --> GEN.1
   *
   * @param {string} chapterId
   * @param {Testament[]} testaments
   * @returns {string}
   * @memberof BibleService
   */

  formatChapter(chapterId: string, testaments: Testament[]): string {
    let formattedChapterId = chapterId;
    const lowercasedChapterId = chapterId.toLowerCase();

    if (testaments && (chapterId.includes(' ') || chapterId.includes(':'))) {
      // expecting value formats for fullIndex 1, 1:1, 1:1-2
      let fullIndex = '';

      testaments.forEach((testament) => {
        testament.books.forEach((book) => {
          if (!fullIndex) {
            const searchBridgeAbbreviations =
              this._config.searchBridgeBooks?.find(
                (searchBridgeBook) => searchBridgeBook.bookId === book.id
              )?.possibleAbbreviations;

            const searchBridgeMatch = searchBridgeAbbreviations?.find((abbr) =>
              lowercasedChapterId.startsWith(abbr.toLowerCase())
            );

            if (searchBridgeMatch) {
              fullIndex = lowercasedChapterId
                .replace(searchBridgeMatch.toLowerCase(), '')
                .slice(1);
            } else if (
              lowercasedChapterId.startsWith(book.nameLong?.toLowerCase())
            ) {
              fullIndex = lowercasedChapterId
                .replace(book.nameLong.toLowerCase(), '')
                .slice(1);
            } else if (
              lowercasedChapterId.startsWith(book.name?.toLowerCase())
            ) {
              fullIndex = lowercasedChapterId
                .replace(book.name.toLowerCase(), '')
                .slice(1);
            } else if (
              lowercasedChapterId.startsWith(book.abbreviation?.toLowerCase())
            ) {
              fullIndex = lowercasedChapterId.replace(
                book.abbreviation.toLowerCase(),
                ''
              );

              if (!Number(fullIndex?.[0])) {
                fullIndex = fullIndex.slice(1);
              }
            }

            if (fullIndex) {
              let chapterIndex = '';
              let startVerseIndex = '';
              let endVerseIndex = '';

              if (fullIndex.includes(':')) {
                [chapterIndex, startVerseIndex] = fullIndex.split(':');

                if (startVerseIndex.includes('-')) {
                  [startVerseIndex, endVerseIndex] = startVerseIndex.split('-');
                }
              } else {
                chapterIndex = fullIndex;
              }

              formattedChapterId = `${book.id}.${chapterIndex}${
                startVerseIndex ? `.${startVerseIndex}` : ''
              }${
                endVerseIndex
                  ? `-${book.id}.${chapterIndex}.${endVerseIndex}`
                  : ''
              }`;
            }
          }
        });
      });
    }

    return formattedChapterId;
  }

  /**
   * Create long chapter name
   * GEN.1 --> Genesis 1
   *
   * @param {string} chapterId
   * @param {Testament[]} testaments
   * @returns {string}
   * @memberof BibleService
   */
  public getFullChapterName(
    chapterId: string,
    testaments: Testament[]
  ): string {
    const splitted = chapterId.split('.');
    let bookLongname = '';
    testaments.find((testament) =>
      testament.books.find((book) => {
        if (book.id === splitted[0]) {
          bookLongname = book.name;
        }
        return book.id === splitted[0];
      })
    );
    return `${bookLongname} ${splitted[1]}`;
  }

  /**
   * Create long book name
   * GEN.1 --> Genesis
   *
   * @param {string} chapterId
   * @param {Testament[]} testaments
   * @returns {string}
   * @memberof BibleService
   */
  public getFullBookName(chapterId: string, testaments: Testament[]): string {
    const splitted = chapterId.split('.');
    let bookLongname = '';
    testaments.find((testament) =>
      testament.books.find((book) => {
        if (book.id === splitted[0]) {
          bookLongname = book.name;
        }
        return book.id === splitted[0];
      })
    );
    return bookLongname;
  }

  /**
   * Create a long passage name
   * --> Genesis 1:2-4
   * @param param0
   * @returns
   */
  public getFullPassageName({
    chapterTitle,
    fromVerse,
    toVerse,
  }: {
    chapterTitle: string;
    fromChapter: string;
    fromVerse?: string;
    toChapter?: string;
    toVerse?: string;
  }): string {
    return `${chapterTitle}${
      // eslint-disable-next-line no-nested-ternary
      fromVerse !== undefined && fromVerse !== 'null'
        ? // eslint-disable-next-line no-nested-ternary
          toVerse && fromVerse !== toVerse
          ? `:${fromVerse}-${toVerse}`
          : `:${fromVerse}`
        : ''
    }`;
  }

  public getLinkToReader({
    bible,
    fromBook,
    fromChapter,
    fromVerse,
    toVerse,
  }: {
    bible: string;
    fromBook: string;
    fromChapter: string;
    fromVerse?: string;
    toVerse?: string;
  }): string {
    return `/ROUTES.bible/${bible}/${fromBook}.${fromChapter}${
      // eslint-disable-next-line no-nested-ternary
      fromVerse !== undefined && fromVerse !== 'null'
        ? fromVerse !== toVerse
          ? `.${fromVerse}-${fromBook}.${fromChapter}.${toVerse}`
          : `.${fromVerse}`
        : ''
    }`;
  }
  /**
   * navigate to the next chapter
   *
   * @param {Column[]} columns
   * @memberof BibleService
   */
  public goToNextChapter(columns: Column[]) {
    if (columns[0]?.chapter?.next?.id) {
      this.redirect(
        columns.map((column: Column) => column.bible.abbreviation),
        columns[0].chapter.next.id,
        false
      );
    }
  }

  /**
   * navigate to the previous chapter
   *
   * @param {Column[]} columns
   * @memberof BibleService
   */
  public goToPreviousChapter(columns: Column[]) {
    if (columns[0]?.chapter?.previous?.id) {
      this.redirect(
        columns.map((column: Column) => column.bible.abbreviation),
        columns[0].chapter.previous.id,
        false
      );
    }
  }

  /**
   * Scrolls an HTML element to a position
   *
   * @param {ElementRef<HTMLDivElement>} element
   * @param {number} scrollPosition
   * @memberof BibleService
   */
  public scrollTo(
    scrollPosition: number,
    element?: ElementRef<HTMLDivElement>
  ) {
    if (this._config.platform === 'browser') {
      if (element) {
        element.nativeElement.scrollTop = Math.max(0, scrollPosition);
      } else {
        this._window.scroll({
          top: scrollPosition,
          behavior: 'smooth',
        });
      }
    }
  }

  /**
   * Used in bible picker: for each entitlement check if the bible is selected or disabled
   * Create a string with the new selected bible abbrevatiations, so we can create the routerlink for each bible
   *
   * @param {Bible[]} entitlements
   * @param {string[]} selectedBibles
   * @returns
   * @memberof BibleService
   */
  public mapEntitlements(bibles: Bible[], selectedBibles: string[]) {
    return bibles.map((bible: Bible) => {
      const isSelected = selectedBibles.includes(bible.abbreviation);
      let isDisabled = false;
      if (bible.accessLevel === 'registered') {
        isDisabled = !this._isAuthenticated;
      }
      if (bible.accessLevel === 'premium') {
        isDisabled =
          !this._isAuthenticated || (this._isAuthenticated && !this._isPremium);
      }

      let newAbbrs;
      if (this.maxActiveColumns === ColumnsLimit.SINGLE) {
        newAbbrs = bible.abbreviation;
      } else {
        // remove the bible from url if already selected
        newAbbrs = isSelected
          ? selectedBibles.length === 1
            ? selectedBibles
            : selectedBibles.filter((i: string) => i !== bible.abbreviation)
          : // add the bible to url if not yet selected
          selectedBibles.length < this.maxActiveColumns
          ? [...selectedBibles, bible.abbreviation]
          : [selectedBibles.slice(0, -1), bible.abbreviation];

        newAbbrs = newAbbrs.join(',');
      }
      return {
        ...bible,
        newAbbrs,
        isSelected,
        isDisabled,
      };
    });
  }

  /**
   * Add, or update existing user highlights
   *
   * @param {string} color
   * @memberof BibleService
   */
  public addUserHighlight(color: string, columns: Column[]) {
    this._selected$
      .pipe(
        take(1),
        switchMap((selected) => {
          const bibleId = this.getBibleIdFromAbbr(selected.activeColumn);

          let highlightsToUpdate: string[] = [];
          let highlightsToAdd: string[] = selected.verseIds;

          // get the bible column the user selected
          const col = columns.filter(
            (column) => column.bible.abbreviation === selected.activeColumn
          );
          // check if the column has highlights
          if (col.length && col[0].userHighlights) {
            col[0].userHighlights?.forEach((highlight: any) => {
              // if selected verse already has a highlight, we need to PUT instead of POST the highlight
              if (selected.verseOrgIds.includes(highlight.verseId)) {
                // add to list of highlights to PUT
                highlightsToUpdate.push(highlight.highlightId);
                // remove from highlights to POST
                highlightsToAdd = highlightsToAdd.filter(
                  (verseId) => verseId !== highlight.verseId
                );
              }
            });
          }
          // create the observables
          let updateHighlights$;
          let addHighlights$;
          if (highlightsToUpdate.length) {
            updateHighlights$ = highlightsToUpdate.map((highlightId) =>
              this._userHighlightsData.update({
                highlightId,
                color,
              })
            );
          } else {
            updateHighlights$ = [of([])];
          }
          if (highlightsToAdd.length) {
            addHighlights$ = this._userHighlightsData.add({
              bibleId,
              verseIds: highlightsToAdd,
              color,
            });
          } else {
            addHighlights$ = of([]);
          }
          return combineLatest([
            addHighlights$,
            forkJoin(updateHighlights$),
            of(bibleId),
          ]);
        })
      )
      .subscribe((res: any) => {
        let addHighlightRes = [];
        let updateHighlightRes = [];
        let oldEntriesRemoved = [];
        let oldState;
        const key = `${res[2]}-${this._readerState.selectedChapter}`;
        // if added highlights
        if (res[0][0]) {
          addHighlightRes = res[0][0].data;
          oldState = res[0][1];
          if (Object.keys(oldState).length !== 0 && oldState[key]) {
            oldEntriesRemoved = oldState[key];
          }
        }
        // if updated highlights
        if (res[1] && res[1][0]?.length) {
          updateHighlightRes = res[1].map((r: any) => r[0].data);
          oldState = res[1][0][1];
          const oldEntries = updateHighlightRes.map(
            (highlight: any) => highlight.highlightId
          );
          oldEntriesRemoved = oldState[key].filter(
            (highlight: any) => !oldEntries.includes(highlight.highlightId)
          );
        }
        // write to store
        this._userHighlightsData.storeHighlights({
          [key]: [
            ...oldEntriesRemoved,
            ...addHighlightRes,
            ...updateHighlightRes,
          ],
        });

        // clear the selected verses
        this._selected$.next({
          verseOrgIds: [],
          verseIds: [],
          activeColumn: '',
          combinedVerse: false,
        });

        this._updateReader$.next(true);
      });
  }

  /**
   * Delete user user highlights
   *
   * @param {Column[]} columns
   * @param {*} selected
   * @memberof BibleService
   */
  public deleteUserHighlight(columns: Column[], selected: any) {
    // get the ids from the highlights we need to delete for the current selection
    const highlightIdsToDelete: string[] = [];

    selected.verseOrgIds.forEach((verseOrgId: any) => {
      columns
        .filter(
          (column) => column.bible.abbreviation === selected.activeColumn
        )[0]
        .userHighlights?.forEach((highlight: any) => {
          if (highlight.verseId === verseOrgId) {
            highlightIdsToDelete.push(highlight.highlightId);
          }
        });
    });
    const bibleId = this.getBibleIdFromAbbr(selected.activeColumn);
    const chapterId = this._readerState.selectedChapter;
    // delete the user highlights, create the observables.
    const deleteHighlights$ = highlightIdsToDelete.map((highlightId) =>
      this._userHighlightsData.delete({ highlightId, bibleId, chapterId })
    );
    // we have to make one call for each highlight to delete, so we use forkjoin
    forkJoin(deleteHighlights$).subscribe((res) => {
      this._selected$.next({
        verseOrgIds: [],
        verseIds: [],
        activeColumn: '',
        combinedVerse: false,
      });
      this._updateReader$.next(true);
    });
  }

  /**
   * Add an user note
   *
   * @param {*} userNote
   * @param {*} selected
   * @memberof BibleService
   */
  public addUserNote(userNote: any, selected: any): Observable<string[]> {
    const { activeColumn } = selected;
    const bibleId =
      this.getBibleIdFromAbbr(this._selected$.value.activeColumn) ||
      this.getDefaultBibleId(this._config.brand, this._config.bibles)?.[0];
    const note = { ...userNote };
    const chapterId = this._readerState.selectedChapter;

    return this._userNotesData.add(note, chapterId, activeColumn, bibleId).pipe(
      map((res) => {
        this._updateReader$.next(true);
        return userNote.verseOrgIds;
      })
    );
  }

  /**
   * Update an user note
   *
   * @param {UserNote} note
   * @memberof BibleService
   */
  public updateUserNote(note: UserNote): void {
    const bibleId =
      this.getBibleIdFromAbbr(this._selected$.value.activeColumn) ||
      this.getDefaultBibleId(this._config.brand, this._config.bibles)?.[0];
    const chapterId = this._readerState.selectedChapter;
    if (!note.content) {
      note.content = '';
    }
    this._userNotesData.update({ note, chapterId, bibleId }).subscribe();
  }

  /**
   * Delete a user note
   *
   * @param {string} noteId
   * @memberof BibleService
   */
  public deleteUserNote(noteId: string): void {
    const bibleId =
      this.getBibleIdFromAbbr(this._selected$.value.activeColumn) ||
      this.getDefaultBibleId(this._config.brand, this._config.bibles)?.[0];
    const chapterId = this._readerState.selectedChapter;
    this._userNotesData.delete({ noteId, chapterId, bibleId }).subscribe();
  }

  /**
   * Used in bible picker: get an array of languages for the bible translations
   *
   * @param {Bible[]} entitlements
   * @returns
   * @memberof BibleService
   */
  public getLanguages(bibles: Bible[]) {
    // create languages array
    return bibles
      .map((bible: any) => ({
        ...bible.language,
        nameLocal: this._translateService.instant(
          `LANGUAGES.${bible.language.name}` || 'Other'
        ),
      }))
      .filter(
        (lang: any, pos: any, langs: any) =>
          langs.map((l: any) => l.id).indexOf(lang.id) === pos
      )
      .map((language: any) => {
        language.bibles = bibles.filter(
          (bible: any) => bible.language.id === language.id
        );
        return language;
      });
  }

  public getUpdatedReaderstate(): Observable<ReaderStateInterface> {
    return this._readerStateData.getReaderState().pipe(
      take(1),
      switchMap((readerState) => {
        let bibleMetadata$: Observable<GetBibleMetadataResponse | null> =
          of(null);

        if (!readerState.selectedBibles) {
          const defaultBibleId = this.getDefaultBibleId(
            this._config.brand,
            this._config.bibles
          ) as string[];

          readerState.selectedBibles = {
            bibleIds: defaultBibleId,
            bibleAbbrs: defaultBibleId.map((bibleId) =>
              this.getBibleAbbrFromId(bibleId)
            ),
          };

          bibleMetadata$ = this._bibleMetaData.getBible({
            bibleId: defaultBibleId[0],
          });
        } else if (
          readerState.selectedBibles.bibleIds?.length &&
          !readerState.selectedBibles.bibleAbbrs?.length
        ) {
          readerState.selectedBibles.bibleAbbrs =
            readerState.selectedBibles.bibleIds.map((bibleId) =>
              this.getBibleAbbrFromId(bibleId)
            );

          bibleMetadata$ = this._bibleMetaData.getBible({
            bibleId: readerState.selectedBibles.bibleIds[0],
          });
        }
        return combineLatest([of(readerState), bibleMetadata$]);
      }),
      map(([readerState, bibleMetadata]) => {
        if (readerState.selectedChapter && bibleMetadata?.data) {
          readerState.selectedChapterFullname = this.getFullChapterName(
            readerState.selectedChapter,
            bibleMetadata.data.testaments
          );
          readerState.selectedBookFullname = this.getFullBookName(
            readerState.selectedChapter,
            bibleMetadata.data.testaments
          );
          readerState.selectedChapterNumber = Number(
            readerState.selectedChapter.split('.')[1]
          );
        }
        this._readerStateData.setReaderState(readerState);
        return readerState;
      })
    );
  }

  private _redirectTo404() {
    const translatedPath = this._localizeRouterService.translateRoute(
      '/404'
    ) as string;
    this._router.navigate([translatedPath], { replaceUrl: true });
  }

  ngOnDestroy() {
    this._subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
