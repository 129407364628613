<ibep-toast
  *ngFor="let notification of toasts; let i = index"
  [notification]="notification"
  [i]="i"
  (remove)="remove($event)"
></ibep-toast>
<ibep-alert
  *ngFor="let notification of alerts; let i = index"
  [notificationProps]="notification"
  [i]="i"
  (remove)="remove($event)"
></ibep-alert>

<ibep-modal-container></ibep-modal-container>
