import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'ibep-testimonial',
  templateUrl: './testimonial.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TestimonialComponent {
  @Input() props: {
    name: string;
    text: string;
    jobTitle: string;
    image: string;
  };
}
