<ng-template #content><ng-content></ng-content></ng-template>
<a
  *ngIf="linkData.link && !linkData.isExternalLink"
  [ngClass]="{ disabled: linkData.isDisabled }"
  class="{{ linkData.linkStyles }}"
  [routerLink]="linkData.link | localize"
  [queryParams]="linkData.queryParams"
  (click)="handleClick()"
>
  <ng-container *ngTemplateOutlet="content"></ng-container>
</a>
<a
  *ngIf="linkData.link && linkData.isExternalLink"
  [ngClass]="{ disabled: linkData.isDisabled }"
  class="{{ linkData.linkStyles }}"
  [href]="linkData.link"
  (click)="handleClick()"
  target="_blank"
>
  <ng-container *ngTemplateOutlet="content"></ng-container>
</a>

<a
  *ngIf="!linkData.link"
  [ngClass]="{ disabled: linkData.isDisabled }"
  class="{{ linkData.linkStyles }}"
  (click)="handleClick()"
>
  <ng-container *ngTemplateOutlet="content"></ng-container>
</a>
