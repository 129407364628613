import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeWebCoreModule } from '@ibep/fe/web/core';
import { FeWebComponentsSharedModule } from '@ibep/fe/web/components-shared';
import { TranslateModule } from '@ngx-translate/core';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { RouterModule } from '@angular/router';
import { NdComponent } from './char/special-text/nd/nd.component';
import { VComponent } from './custom/v/v.component';
import { BiblePassageComponent } from './bible-passage/bible-passage.component';
import { BkComponent } from './char/special-text/bk/bk.component';
import { AddComponent } from './char/special-text/add/add.component';
import { DcComponent } from './char/special-text/dc/dc.component';
import { KComponent } from './char/special-text/k/k.component';
import { LitComponent } from './char/special-text/lit/lit.component';
import { PnComponent } from './char/special-text/pn/pn.component';
import { SlsComponent } from './char/special-text/sls/sls.component';
import { TlComponent } from './char/special-text/tl/tl.component';
import { WjComponent } from './char/special-text/wj/wj.component';
import { EmComponent } from './char/special-text/em/em.component';
import { BdComponent } from './char/special-text/bd/bd.component';
import { ItComponent } from './char/special-text/it/it.component';
import { BditComponent } from './char/special-text/bdit/bdit.component';
import { NoComponent } from './char/special-text/no/no.component';
import { ScComponent } from './char/special-text/sc/sc.component';
import { QtComponent } from './char/special-text/qt/qt.component';
import { SigComponent } from './char/special-text/sig/sig.component';
import { XComponent } from './custom/x/x.component';
import { FComponent } from './custom/f/f.component';
import { HComponent } from './paragraph/identification/h/h.component';
import { ImtComponent } from './paragraph/introductions/imt/imt.component';
import { IsComponent } from './paragraph/introductions/is/is.component';
import { IpComponent } from './paragraph/introductions/ip/ip.component';
import { IpiComponent } from './paragraph/introductions/ipi/ipi.component';
import { ImComponent } from './paragraph/introductions/im/im.component';
import { ImiComponent } from './paragraph/introductions/imi/imi.component';
import { IpqComponent } from './paragraph/introductions/ipq/ipq.component';
import { ImqComponent } from './paragraph/introductions/imq/imq.component';
import { IprComponent } from './paragraph/introductions/ipr/ipr.component';
import { IqComponent } from './paragraph/introductions/iq/iq.component';
import { IliComponent } from './paragraph/introductions/ili/ili.component';
import { IotComponent } from './paragraph/introductions/iot/iot.component';
import { IoComponent } from './paragraph/introductions/io/io.component';
import { IexComponent } from './paragraph/introductions/iex/iex.component';
import { ImteComponent } from './paragraph/introductions/imte/imte.component';
import { IeComponent } from './paragraph/introductions/ie/ie.component';
import { LhComponent } from './paragraph/lists/lh/lh.component';
import { LiComponent } from './paragraph/lists/li/li.component';
import { LfComponent } from './paragraph/lists/lf/lf.component';
import { LimComponent } from './paragraph/lists/lim/lim.component';
import { LitlComponent } from './paragraph/lists/litl/litl.component';
import { QComponent } from './paragraph/poetry/q/q.component';
import { QrComponent } from './paragraph/poetry/qr/qr.component';
import { QcComponent } from './paragraph/poetry/qc/qc.component';
import { QaComponent } from './paragraph/poetry/qa/qa.component';
import { QmComponent } from './paragraph/poetry/qm/qm.component';
import { QdComponent } from './paragraph/poetry/qd/qd.component';
import { BComponent } from './paragraph/poetry/b/b.component';
import { PComponent } from './paragraph/paragraphs/p/p.component';
import { MComponent } from './paragraph/paragraphs/m/m.component';
import { PoComponent } from './paragraph/paragraphs/po/po.component';
import { PrComponent } from './paragraph/paragraphs/pr/pr.component';
import { ClsComponent } from './paragraph/paragraphs/cls/cls.component';
import { PmoComponent } from './paragraph/paragraphs/pmo/pmo.component';
import { PmComponent } from './paragraph/paragraphs/pm/pm.component';
import { PmcComponent } from './paragraph/paragraphs/pmc/pmc.component';
import { PmrComponent } from './paragraph/paragraphs/pmr/pmr.component';
import { PiComponent } from './paragraph/paragraphs/pi/pi.component';
import { MiComponent } from './paragraph/paragraphs/mi/mi.component';
import { PcComponent } from './paragraph/paragraphs/pc/pc.component';
import { PhComponent } from './paragraph/paragraphs/ph/ph.component';
import { MteComponent } from './paragraph/titles-headings/mte/mte.component';
import { ClComponent } from './paragraph/titles-headings/cl/cl.component';
import { CdComponent } from './paragraph/titles-headings/cd/cd.component';
import { MsComponent } from './paragraph/titles-headings/ms/ms.component';
import { MrComponent } from './paragraph/titles-headings/mr/mr.component';
import { SComponent } from './paragraph/titles-headings/s/s.component';
import { SrComponent } from './paragraph/titles-headings/sr/sr.component';
import { RComponent } from './paragraph/titles-headings/r/r.component';
import { DComponent } from './paragraph/titles-headings/d/d.component';
import { SpComponent } from './paragraph/titles-headings/sp/sp.component';
import { SdComponent } from './paragraph/titles-headings/sd/sd.component';
import { RenderBibleContentComponent } from './render-bible-content/render-bible-content.component';
import { BibleContentContainerComponent } from './bible-content-container/bible-content-container.component';
import { RqComponent } from './char/special-text/rq/rq.component';
import { VerseTextRendererComponent } from './char/verse-text-renderer/verse-text-renderer.component';
import { MediaPackageContainerComponent } from './media-package-item-container/media-package-container.component';
import { FqComponent } from './char/footnote/fq/fq.component';
import { JmpComponent } from './char/footnote/jmp/jmp.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule.forChild(),
    LocalizeRouterModule,
    FeWebCoreModule,
    FeWebComponentsSharedModule,
  ],
  declarations: [
    RenderBibleContentComponent,
    BibleContentContainerComponent,
    FqComponent,
    HComponent,
    ImtComponent,
    IsComponent,
    IpComponent,
    IpiComponent,
    ImComponent,
    ImiComponent,
    IpqComponent,
    ImqComponent,
    IprComponent,
    IqComponent,
    IliComponent,
    IotComponent,
    IoComponent,
    IexComponent,
    ImteComponent,
    IeComponent,
    JmpComponent,
    LhComponent,
    LiComponent,
    LfComponent,
    LimComponent,
    LitlComponent,
    QComponent,
    QrComponent,
    QcComponent,
    QaComponent,
    QmComponent,
    QdComponent,
    BComponent,
    PComponent,
    MComponent,
    PoComponent,
    PrComponent,
    ClsComponent,
    PmoComponent,
    PmComponent,
    PmcComponent,
    PmrComponent,
    PiComponent,
    MiComponent,
    PcComponent,
    PhComponent,
    MteComponent,
    ClComponent,
    CdComponent,
    MsComponent,
    MrComponent,
    SComponent,
    SrComponent,
    RComponent,
    DComponent,
    SpComponent,
    SdComponent,
    NdComponent,
    VComponent,
    BiblePassageComponent,
    BkComponent,
    AddComponent,
    DcComponent,
    KComponent,
    LitComponent,
    PnComponent,
    SlsComponent,
    TlComponent,
    WjComponent,
    EmComponent,
    BdComponent,
    ItComponent,
    BditComponent,
    NoComponent,
    ScComponent,
    QtComponent,
    SigComponent,
    XComponent,
    FComponent,
    RqComponent,
    VerseTextRendererComponent,
    MediaPackageContainerComponent,
  ],
  exports: [
    RenderBibleContentComponent,
    BibleContentContainerComponent,
    VerseTextRendererComponent,
    FqComponent,
    HComponent,
    ImtComponent,
    IsComponent,
    IpComponent,
    IpiComponent,
    ImComponent,
    ImiComponent,
    IpqComponent,
    ImqComponent,
    IprComponent,
    IqComponent,
    IliComponent,
    IotComponent,
    IoComponent,
    IexComponent,
    ImteComponent,
    IeComponent,
    JmpComponent,
    LhComponent,
    LiComponent,
    LfComponent,
    LimComponent,
    LitlComponent,
    QComponent,
    QrComponent,
    QcComponent,
    QaComponent,
    QmComponent,
    QdComponent,
    BComponent,
    PComponent,
    MComponent,
    PoComponent,
    PrComponent,
    ClsComponent,
    PmoComponent,
    PmComponent,
    PmcComponent,
    PmrComponent,
    PiComponent,
    MiComponent,
    PcComponent,
    PhComponent,
    MteComponent,
    ClComponent,
    CdComponent,
    MsComponent,
    MrComponent,
    SComponent,
    SrComponent,
    RComponent,
    DComponent,
    SpComponent,
    SdComponent,
    NdComponent,
    BkComponent,
    AddComponent,
    DcComponent,
    KComponent,
    LitComponent,
    PnComponent,
    SlsComponent,
    TlComponent,
    WjComponent,
    EmComponent,
    BdComponent,
    ItComponent,
    BditComponent,
    NoComponent,
    ScComponent,
    QtComponent,
    SigComponent,
    XComponent,
    FComponent,
    VComponent,
    RqComponent,
    BiblePassageComponent,
    MediaPackageContainerComponent,
  ],
})
export class FeWebComponentsBibleSharedModule {}
