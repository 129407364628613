import { Injectable } from '@angular/core';
import {
  AbstractBackendService,
  AbstractEnvironmentService,
  AbstractLanguageService,
  AbstractStorageService,
  AccountSettings,
  Config,
  ConfigDTO,
  EnvironmentType,
  GetBrandConfigResponse,
  MenuItem,
  Menus,
  PlatformType,
  ThemeColors,
} from '@ibep/interfaces';
import { HOUR } from '@ibep/shared/util';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { filter, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { ConfigData } from './config.data';
import { Store, StoreSettings, StoreSettingsTTL } from './store';

@Injectable({
  providedIn: 'root',
})
export class ConfigLocalizedData extends Store<any> {
  private _domain: string;
  private _platform: PlatformType;
  private _environment: EnvironmentType;
  private _menuItemsMapped = false;
  private _gtmId: string;
  private _config: Config;

  private _config$ = new BehaviorSubject<Config>(
    undefined as unknown as Config
  );

  private currentLanguage: string;

  constructor(
    storage: AbstractStorageService,
    environment: AbstractEnvironmentService,
    backendService: AbstractBackendService,
    languageService: AbstractLanguageService,
    private readonly configData: ConfigData
  ) {
    super(storage, environment, backendService, languageService, {
      storeTtl: { default: 1 * HOUR } as StoreSettingsTTL,
      storeKey: 'configLocalized',
    } as StoreSettings);

    this._domain = this.environment.domain;
    this._platform = this.environment.platform;
    this._environment = this.environment.environment;

    // subscribe to language changes
    combineLatest([
      this.configData.getConfig(),
      this.languageService.currentLanguage$.pipe(filter((lang) => !!lang)),
    ])
      .pipe(
        switchMap(([config, lang]) => {
          this._config = config;
          this.currentLanguage = lang;
          // if the language changes to a non default language, fetch localized config
          if (this._config?.brand.defaultLanguageCode !== lang) {
            return this._fetchConfig();
          }
          return this.configData.getConfig();
        })
      )
      .subscribe((res) => {
        if (res) {
          this._config = res;
          this._setGtmId(this._config?.tagManagerSettings?.gtmContainerId);
          this._config$.next(res);
        }
      });
  }

  /**
   * Private method to fetch a localised version of the config data
   *
   * @returns {Observable<Config>}
   * @memberof ConfigData
   */
  private _fetchConfig(): Observable<any> {
    const params: any = {
      brandEntitlements: true,
      platform: 'web',
      domain: this._domain,
      language: this.currentLanguage,
      includeLocalization: true,
    };

    this.remoteDataMap$[this.currentLanguage] = this.backendService
      .get<GetBrandConfigResponse>({
        endpoint: `brands/config`,
        options: {
          params,
          headers: {
            redisTTL: `${this.storeSettings.storeTtl.default}`,
          },
        },
      })
      .pipe(
        shareReplay(1),
        map((config: GetBrandConfigResponse) => config.data)
      );

    return this.localData$.pipe(
      take(1),
      shareReplay(),
      switchMap((localData: any) => {
        const lang = this.currentLanguage;
        // check if there is local data available
        if (localData?.[lang]) {
          this._setGtmId(localData?.tagManagerSettings?.gtmContainerId);
          return of(localData?.[lang]);
        }
        // if no local data, fetch data from api
        return this.remoteDataMap$[this.currentLanguage].pipe(
          map((config: ConfigDTO) => {
            if (
              this._platform === 'browser' &&
              config.menus &&
              config.menus.main
            ) {
              config.menus.main = {
                ...config.menus.main,
                items: this.mapMenuItems(config.menus.main.items),
              };
            }
            this._setGtmId(config?.tagManagerSettings?.gtmContainerId);
            return {
              ...config,
              domain: this._domain,
              environment: this._environment,
              platform: this._platform,
            };
          }),
          tap((remoteData: any) => {
            // write returned data from api to the store
            this.setState({ [lang]: remoteData });
          })
        );
      })
    );
  }

  /**
   * Public method to get the config data
   * @returns {Config}
   */
  public getConfig(): Observable<Config> {
    return this._config$.asObservable().pipe(filter((config) => !!config));
  }

  /**
   * Get the account settings from the config object
   * @returns {Observable<AccountSettings>}
   * @memberof ConfigData
   */
  public getAccountSettings(): Observable<AccountSettings> {
    return this.getConfig().pipe(
      filter((config: Config) => Boolean(config)),
      map((config: Config) => config.accountSettings)
    );
  }

  public getUserAccountSystem(): Observable<boolean> {
    return this.getConfig().pipe(
      filter((config: Config) => Boolean(config)),
      map(
        (config: Config) => config.brand.brandUIPreferences?.userAccountSystem
      )
    );
  }

  /**
   * Get the menus from the config object
   * @returns {Observable<Menus>}
   * @memberof ConfigData
   */
  public getMenus(): Observable<Menus> {
    return this.getConfig().pipe(map((config) => config.menus));
  }

  /**
   * Get the colors from the custom theme object
   * @returns {Observable<ThemeColors>}
   * @memberof ConfigData
   */
  public getColors(): Observable<ThemeColors> {
    return this.getConfig().pipe(map((config) => config.theme?.colors));
  }

  /**
   * Map menu items
   *
   * @private
   * @param {*} oldMenuItems
   * @returns
   * @memberof ConfigData
   */
  private mapMenuItems(oldMenuItems: MenuItem[]) {
    if (!this._menuItemsMapped) {
      const newMenuItems = oldMenuItems.map((oldItem: any) => {
        const item = { ...oldItem };
        // For the 2nd level menu items, we check if there are sections (a section has a section title + menu items).
        // if there is a section, we add an extra layer to the menu data
        if (item.items.length) {
          const newItems: any[] = [];
          item.items.forEach((subItem: any) => {
            // section with title
            if (subItem.linkType === 'sectionTitle') {
              newItems.push(subItem);
              // if the first menu item does not start with a title
            } else if (newItems.length === 0) {
              newItems.push({ items: [subItem] });
            } else if (newItems[newItems.length - 1]?.items) {
              newItems[newItems.length - 1].items.push(subItem);
            } else {
              newItems[newItems.length - 1].items = [subItem];
            }
          });
          item.items = newItems;
        }

        return item;
      });
      this._menuItemsMapped = true;
      return newMenuItems;
    }
    return oldMenuItems;
  }

  private _setGtmId(id: string | undefined): void {
    if (this._platform === 'browser' && id && id !== this._gtmId) {
      this._gtmId = id;
    }
  }

  get gtmId(): string {
    return this._gtmId;
  }
}
