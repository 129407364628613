import { Component } from '@angular/core';

@Component({
  selector: 'bible-cls',
  template: `
    <p class="cls">
      <ng-content></ng-content>
    </p>
  `,
  styles: ['.cls { text-align: right; }'],
})
/**
 * https://ubsicap.github.io/usx/parastyles.html#cls
 */
export class ClsComponent {}
