<div class="relative group">
  <!-- angular image directive (width and height needed) -->
  <img
    *ngIf="image && image.height && image.width"
    [ngSrc]="image.src"
    [height]="image.height"
    [width]="image.width"
    [alt]="image.alt"
    [ngStyle]="{
      float: image.style.float
    }"
    (click)="!disableLightbox ? toggleLightbox() : ''"
    [ngClass]="{
      'cursor-pointer hover:opacity-80': !disableLightbox,
      '!mr-[20px] mb-[10px] !mt-2': image.style.float === 'left',
      '!ml-[20px] mb-[10px] mt-2': image.style.float === 'right',
      'max-w-[50%] float-left mr-[20px]': inline
    }"
    class="pb-2"
  />
  <!-- if no width or height of the image (or outer container) are known we cannot use the image directive  -->
  <img
    *ngIf="image && !image.height && !image.width"
    [src]="image.src"
    [alt]="image.alt"
    [ngStyle]="{
      float: image.style.float
    }"
    (click)="!disableLightbox ? toggleLightbox() : ''"
    [ngClass]="{
      'cursor-pointer hover:opacity-80': !disableLightbox,
      '!mr-[20px] mb-[10px] !mt-2': image.style.float === 'left',
      '!ml-[20px] mb-[10px] mt-2': image.style.float === 'right',
      'max-w-[50%] float-left mr-[20px]': !image.style.float && inline
    }"
    class="pb-2"
  />
  <!-- lightbox expand icon  -->
  <span
    *ngIf="!disableLightbox && !imageGallery"
    class="absolute top-6 text-white hidden group-hover:block left-6"
    [ngClass]="{
      '!left-6': image?.style.float === 'left',
      '!right-6 left-[unset]': image?.style.float === 'right',
      '!left-6': !image?.style.float
    }"
  >
    <ibep-expand-svg svgStyles="w-5 h-5"></ibep-expand-svg>
  </span>
  <!-- lightbox expand icon for gallery item -->
  <span
    *ngIf="!disableLightbox && imageGallery"
    class="absolute top-3 right-3 text-white"
  >
    <ibep-arrow-angle-expand-svg
      svgStyles="w-5 h-5"
    ></ibep-arrow-angle-expand-svg>
  </span>
</div>

<!-- lightbox -->
<div
  class="modal"
  *ngIf="!disableLightbox && lightboxIsOpen"
  (click)="imageGallery ? null : (lightboxIsOpen = false)"
>
  <span class="gallery-icon top-2.5 right-6" (click)="lightboxIsOpen = false">
    &times;
  </span>
  <span
    *ngIf="galleryTotal > 1"
    class="gallery-icon bottom-6 lg:top-1/2 left-6"
    (click)="
      moveToSiblingImage(
        galleryIndex === 0 ? galleryTotal - 1 : galleryIndex - 1
      )
    "
  >
    <ibep-chevron-left-svg svgStyles="w-6 h-6"></ibep-chevron-left-svg>
  </span>
  <span
    *ngIf="galleryTotal > 1"
    class="gallery-icon bottom-6 lg:top-1/2 right-6"
    (click)="
      moveToSiblingImage(
        galleryIndex === galleryTotal - 1 ? 0 : galleryIndex + 1
      )
    "
  >
    <ibep-chevron-right-svg svgStyles="w-6 h-6"></ibep-chevron-right-svg>
  </span>

  <div class="modal-content w-full lg:w-[calc(100vw-100px)] h-100vh">
    <div
      class="w-full max-h-[calc(100vh-120px)] lg:h-[calc(100vh-50px)] lg:max-h-none"
    >
      <img
        *ngIf="image"
        [src]="image.srcLg || image.src"
        [alt]="image.alt"
        class="w-full h-full object-contain"
      />
      <div class="mx-auto text-white text-center pt-2" *ngIf="image.caption">
        {{ image.caption }}
      </div>
    </div>
  </div>
</div>
