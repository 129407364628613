<ibep-account-trigger-bar-content
  [ngClass]="{
    hidden:
      isAuthenticated ||
      !userAccountSystem ||
      hidden ||
      !isUrlAllowed ||
      platform === 'server'
  }"
  [triggerBarSettings]="triggerBarSettings"
  (closeBar)="closeBar()"
  (handleOpenModal)="openModal($event)"
  (pushGtmTag)="pushGtmTag($event)"
></ibep-account-trigger-bar-content>
