<div class="max-w-container mx-auto">
  <div
    class="grid md:grid-cols-2 lg:grid-cols-4 lg:gap-10 gap-4 px-5 xl:px-0 bg-[#E5EAF1] pb-14 lg:bg-white"
    [ngClass]="{
      '!grid-cols-1 md:!grid-cols-1 lg:!grid-cols-1 lg:!gap-1 !gap-1 pt-12':
        template === 'list',
      '-mt-36 md:-mt-30 lg:-mt-30': template === 'list' && categories?.length,
      '-mt-8 lg:-mt-[55px]': template !== 'list'
    }"
  >
    <!-- No items available  -->
    <ng-container *ngIf="items && items.length === 0">
      <div class="col-span-1 text-center h-12">
        <p class="text-white">
          <ng-container [ngSwitch]="contentType">
            <ng-container *ngSwitchCase="contentTypeEnum.POST">
              {{ 'ThereAreNoPosts*' | translate }}
            </ng-container>
            <ng-container *ngSwitchCase="contentTypeEnum.READING_PLAN">
              {{ 'ThereAreNoReading*' | translate }}
            </ng-container>
            <ng-container *ngSwitchCase="contentTypeEnum.EBC">
              {{ 'ThereAreNoItems*' | translate }}
            </ng-container>
          </ng-container>
        </p>
      </div>
    </ng-container>
    <!-- EBC List view category select dropdown -->
    <div
      *ngIf="template === 'list' && categories?.length"
      class="mb-20 lg:w-1/2 mx-auto col-span-1"
    >
      <label class="text-[10px] leading-[25px] text-white uppercase">{{
        'FilterByCategory' | translate
      }}</label>
      <div class="relative mt-1">
        <button
          #categoryFilterButton
          (click)="toggleCategoryFilter()"
          type="button"
          class="relative w-full rounded-md bg-white py-1 lg:py-2 pl-3 pr-10 text-left shadow-sm border border-grayExtraLight focus:outline-none sm:text-sm cursor-pointer group"
        >
          <span class="inline-flex w-full truncate group-hover:text-primary">
            <span class="truncate">{{ 'ChooseACategory' | translate }}</span>
          </span>
          <span class="absolute inset-y-0 right-0 flex items-center pr-2">
            <ibep-chevron-down-svg
              *ngIf="!showCategoryFilter"
              svgStyles="w-3 h-3"
            ></ibep-chevron-down-svg>
            <ibep-chevron-up-svg
              *ngIf="showCategoryFilter"
              svgStyles="w-3 h-3"
            ></ibep-chevron-up-svg>
          </span>
        </button>
        <!-- category filter popup  -->
        <div
          #categoryFilter
          *ngIf="showCategoryFilter"
          [ngClass]="{
            'opacity-0': !showCategoryFilter,
            'opacity-100': showCategoryFilter
          }"
          class="fixed left-0 right-0 top-0 lg:top-[29px] bottom-0 lg:absolute z-[666] lg:-mt-1 lg:h-fit lg:max-h-60 overflow-auto rounded-b-md bg-white py-1 text-base shadow-lg border border-grayExtraLight border-t-0 focus:outline-none sm:text-sm transition ease-in duration-100 pt-2"
        >
          <div class="lg:hidden px-3 mb-4" (click)="toggleCategoryFilter()">
            <ibep-arrow-left-svg svgStyles="w-4 h-4 mr-2"></ibep-arrow-left-svg>
            <span class="truncate">{{ 'ChooseACategory' | translate }}</span>
          </div>
          <ul>
            <a
              *ngFor="let category of categories"
              [routerLink]="
                tagView
                  ? createCategoryLink(category.slug)
                  : (category.slug | localize)
              "
            >
              <li
                class="text-grayMedium hover:text-primary relative cursor-pointer select-none py-2 px-3 text-xs flex justify-between"
              >
                <span
                  >{{ category.name }}
                  <span class="text-3xs text-grayLight ml-1">
                    ({{
                      'VAR_items'
                        | translate
                          : {
                              N: category.count
                            }
                    }})</span
                  ></span
                >
                <ibep-chevron-right-svg
                  class="lg:hidden"
                  svgStyles="w-3 h-3 font-bold text-[#C0D0E4]"
                ></ibep-chevron-right-svg>
              </li>
            </a>
          </ul>
        </div>
      </div>
    </div>
    <div
      class="lg:w-1/2 lg:mx-auto col-span-1"
      *ngIf="contentType === contentTypeEnum.EBC && template === 'list'"
    >
      <h3 class="text-lg font-bold pb-2" [innerHTML]="termAbove"></h3>
      <input
        class="appearance-none block w-full px-3 py-2 mb-1 border border-grayLight rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
        [placeholder]="'SearchForAKeyword' | translate"
        #siblingInput
      />
    </div>
    <!-- Render the items  -->
    <ng-container *ngFor="let item of items; let i = index">
      <!-- teaser items: Regular  -->
      <a
        *ngIf="template !== 'list'"
        [routerLink]="'/' + route + '/' + item.slug | localize"
        class="bg-white rounded-lg flex flex-col min-h-[160px] shadow-sm hover:shadow-lg group"
        [ngClass]="{
          'lg:col-span-2':
            (template === 'default' && i < 2) || template === 'large',
          'border border-solid border-blockBorder': i > 1
        }"
      >
        <div class="h-full flex justify-between">
          <div
            #teaserItemContainer
            class="flex flex-col w-full"
            [ngClass]="{
              'gap-2': (template === 'default' && i < 2) || template === 'large'
            }"
          >
            <ibep-image-picture
              *ngIf="item.image"
              [imgStyles]="{
                'w-full rounded-tl-lg rounded-tr-lg': true,
                'aspect-video object-cover': !variableHeightImage,
                'lg:h-80':
                  !variableHeightImage &&
                  ((template === 'default' && i < 2) || template === 'large')
              }"
              [image]="item.image"
              [containerWidth]="teaserItemContainer?.clientWidth"
            ></ibep-image-picture>

            <div class="mx-4 mt-3">
              <!-- Accesslevel  -->
              <div class="flex w-full justify-end">
                <div
                  *ngIf="item.accesslevel === 'Registered' && !isAuthenticated"
                  class="relative w-4 h-4 rounded-full text-primary bg-highlightText border border-grayExtraLight"
                >
                  <ibep-lock-svg
                    class="absolute -top-1.5 left-[3px]"
                    svgStyles="w-2 h-2 "
                  ></ibep-lock-svg>
                </div>
                <div
                  *ngIf="item.accesslevel === 'Premium' && !isPremium"
                  class="text-secondary text-4xs bg-secondary bg-opacity-10 ml-auto uppercase font-bold px-[2px]"
                >
                  <span>{{ 'Premium' | translate }}</span>
                </div>
              </div>

              <!-- title  -->
              <h6
                class="mt-4 text-[16px] text-dark leading-[16px] font-bold"
                [innerHtml]="item.title"
              ></h6>
              <!-- number of episodes (reading plans)  -->
              <span
                *ngIf="item.episodes && item.showNumberOfEpisodes"
                class="text-[12px] text-grayLight"
              >
                {{
                  'VAR_episodes'
                    | translate
                      : {
                          N: item.episodes.length
                        }
                }}
              </span>
              <!-- number of Items (ebc)  -->
              <span *ngIf="item.count" class="text-[12px] pl-1 text-grayLight">
                ({{
                  'VAR_items'
                    | translate
                      : {
                          N: item.count
                        }
                }})
              </span>

              <!-- description  -->
              <p
                *ngIf="item.summary"
                style="
                  -webkit-mask-image: -webkit-gradient(
                    linear,
                    left top,
                    left bottom,
                    from(rgba(0, 0, 0, 1)),
                    to(rgba(0, 0, 0, 0.2))
                  );
                "
                class="mt-2 mb-2 text-[14px] text-grayLight overflow-hidden max-h-16"
                [ngClass]="{
                  'lg:pr-10':
                    (template === 'default' && i < 2) || template === 'large'
                }"
                [innerHTML]="item.summary"
              ></p>
              <!-- FIXME: duplicate code, change api response so we can reuse 1 code block. -->
              <!-- Cropped description  -->
              <p
                *ngIf="item.teaserDescription && !fullTeaserDescription"
                style="
                  -webkit-mask-image: -webkit-gradient(
                    linear,
                    left top,
                    left bottom,
                    from(rgba(0, 0, 0, 1)),
                    to(rgba(0, 0, 0, 0.2))
                  );
                "
                class="mt-2 mb-2 text-[14px] text-grayLight overflow-hidden max-h-16"
                [ngClass]="{
                  'lg:pr-10':
                    (template === 'default' && i < 2) || template === 'large'
                }"
                [innerHTML]="item.teaserDescription"
              ></p>
              <!-- Full description  -->
              <p
                *ngIf="item.teaserDescription && fullTeaserDescription"
                class="mt-2 mb-2 text-[14px] text-grayLight line-clamp-8"
                [ngClass]="{
                  'lg:pr-10':
                    (template === 'default' && i < 2) || template === 'large'
                }"
              >
                <ibep-paragraph
                  [html]="item.teaserDescription"
                ></ibep-paragraph>
              </p>
            </div>
          </div>
        </div>
        <div class="my-3 mx-4">
          <a
            class="text-secondary group-hover:text-primary mt-1 mb-3 text-[14px] font-bold"
            [routerLink]="'/' + route + '/' + item.slug | localize"
            ><ibep-arrow-right-svg
              svgStyles="w-6 h-6"
              class="inline -ml-1"
            ></ibep-arrow-right-svg>
            <span [innerHTML]="readMoreText"></span
          ></a>
        </div>
      </a>
      <!-- teaser items: EBC List items with subcategory dropdown  -->
      <div *ngIf="template === 'list'" class="lg:w-1/2 lg:mx-auto">
        <a
          [routerLink]="'/ROUTES.ebc/redirect/slug/' + item.slug | localize"
          class="hover:text-primary flex justify-between cursor-pointer underline text-grayMedium"
        >
          <span
            class="text-[16px] leading-[16px]"
            [innerHtml]="item.title"
          ></span>
          <ibep-chevron-right-svg
            svgStyles="w-3 h-3 font-bold text-[#C0D0E4]"
          ></ibep-chevron-right-svg>
        </a>
      </div>
    </ng-container>
  </div>
  <!--Infinite loading -->
  <ng-container *ngIf="moreItemsAvailable">
    <div
      class="col-span-4 text-center h-24 block"
      observeVisibility
      [threshold]="0.01"
      (visible)="loadMore()"
      [isBrowser]="isBrowser"
    >
      <div
        class="absolute h-[300px] top-[-300px] w-full pointer-events-none"
      ></div>
      <ibep-page-loading></ibep-page-loading>
    </div>
  </ng-container>
</div>
