import { Component, Input } from '@angular/core';

@Component({
  selector: 'ibep-prev-svg',
  template: `
    <svg
      ibepHideTillInit
      class="h-12 w-12 inline"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <title>previous</title>
      <g
        id="Symbols"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g transform="translate(-10.000000, -65.000000)">
          <g id="Left" transform="translate(11.000000, 66.000000)">
            <circle
              id="Oval"
              stroke="#C0D0E4"
              fill="#FFFFFF"
              cx="20"
              cy="20"
              r="20"
            ></circle>
            <path
              d="M21.9955084,29.0019618 C21.559565,29.0026941 21.1401588,28.8232598 20.8268162,28.5010029 L14.4675822,21.9768572 C13.962775,21.4597929 13.6776585,20.7471747 13.6776585,20.0023831 C13.6776585,19.2575914 13.962775,18.5449357 14.4675822,18.0279089 L20.8268162,11.5037632 C21.4955319,10.8175064 22.5609108,10.838018 23.2054906,11.5491718 C23.8507934,12.2610587 23.8315058,13.3933205 23.1627919,14.0795586 L17.3888824,20.0024187 L23.1627919,25.9252788 C23.6565802,26.4313561 23.8122201,27.2062215 23.5553473,27.8823158 C23.2984744,28.5590481 22.6814154,29.002147 21.9954749,29.002147 L21.9955084,29.0019618 Z"
              id="Fill-1"
              fill="currentColor"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  `,
  styles: [],
})
export class PrevComponent {
  @Input() type: string;
}
