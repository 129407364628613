<div class="bg-primary bg-opacity-10 py-8 my-4 lg:my-8 px-4 lg:px-0">
  <div class="max-w-[610px] mx-auto lg:px-3">
    <h3 class="text-2xl font-bold pb-3" [innerHTML]="props.headline"></h3>
    <p class="pb-4 text-grayLight text-sm">
      <ibep-paragraph [html]="props.text"></ibep-paragraph>
    </p>
    <a
      [href]="
        props.linkType === 'internal' ? props.linkInternal : props.linkExternal
      "
      target="{{ props.linkType === 'internal' ? '_self' : '_blank' }}"
    >
      <ibep-button
        [buttonProps]="{
          pushGtm: true,
          content: props.buttonText,
          link:
            props.linkType === 'internal'
              ? props.linkInternal
              : props.linkExternal,
          style: 'secondary',
          size: 'l'
        }"
      >
        <b
          class="text-xs px-16 line-clamp-1"
          [innerHTML]="props.buttonText"
        ></b> </ibep-button
    ></a>
  </div>
</div>
