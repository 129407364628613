import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Tag } from '@ibep/interfaces';

@Component({
  selector: 'ibep-tags-picker',
  templateUrl: './tags-picker.component.html',
})
export class TagsPickerComponent {
  @Input() title: string;

  @Input() tags: Tag[];

  @Output() valueChanged = new EventEmitter<number[]>();

  public toggleTag(tag: Tag): void {
    tag.checked = !tag.checked;
    this.valueChanged.emit(this.tags.filter(tag => tag.checked).map(tag => tag.id));
  }
}
