<span
  class="nd"
  [style.font-variant]="
    ndCustomStyling?.enabled && ndCustomStyling?.disableSmallCaps
      ? 'normal'
      : 'small-caps'
  "
  [style.color]="
    ndCustomStyling?.enabled && ndCustomStyling?.customColor
      ? ndCustomStyling?.customColor
      : null
  "
  [ngClass]="ndCustomStyling?.enabled ? {
    'font-bold': ndCustomStyling?.enableBold,
    'italic': ndCustomStyling?.enableItalic,
  } : null"
  ><ng-content></ng-content
></span>
