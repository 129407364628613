import { Component, Input } from '@angular/core';

@Component({
  selector: 'ibep-arrow-curved-svg',
  template: `<svg
    ibepHideTillInit
    class="h-full w-full inline m-auto"
    fill="currentColor"
    class="inline m-auto"
    [ngClass]="svgStyles"
    width="59px"
    height="23px"
    viewBox="0 0 59 23"
  >
    <defs>
      <clipPath id="i0">
        <path d="M1440,0 L1440,1024 L0,1024 L0,0 L1440,0 Z"></path>
      </clipPath>
    </defs>
    <g transform="translate(-1163.0 -123.0)">
      <g clip-path="url(#i0)">
        <g transform="translate(0.0 120.0)">
          <g transform="translate(1164.077880859375 5.4317932128904705)">
            <path
              d="M57.2823888,-2.40462269 L57.2480936,-0.627175738 L56.9824913,13.138432 L54.9828635,13.0998499 L55.175,3.149 L51.0323091,10.1939935 L50.9086479,10.3614883 C41.3884092,20.7824879 24.2941065,22.4456347 -0.270960264,15.5307973 L0.270960264,13.6056163 C23.9475376,20.2703521 40.2114861,18.7776755 49.1630379,9.30211296 L49.363,9.086 L53.453,2.134 L44.8489806,7.13932027 L43.8435704,5.41040432 L55.7455746,-1.51092475 L57.2823888,-2.40462269 Z"
              stroke="none"
              fill="#FFFFFF"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>`,
  styles: [],
})
export class ArrowCurvedComponent {
  @Input() svgStyles: string;
}
