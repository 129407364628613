import { Component, Input } from '@angular/core';

@Component({
  selector: 'bible-imt',
  templateUrl: './imt.component.html',
  styleUrls: ['./imt.component.scss'],
})
/**
 * Introduction major title.
 * https://ubsicap.github.io/usx/parastyles.html#imt
 */
export class ImtComponent {
  @Input() item: any;
}
