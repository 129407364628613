import { DOCUMENT } from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Config } from '@ibep/interfaces';

@Component({
  selector: 'ibep-signup',
  templateUrl: './signup.component.html',
})
export class SignupComponent implements OnChanges, OnInit, OnDestroy {
  public config: Config;
  public tab: 'confirmation' | 'signup' = 'signup';
  public mobileTab: 'usp' | 'form' = 'usp';

  public errors: { code: string; message: string } | undefined;

  public form: UntypedFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl(undefined, [
      Validators.email,
      Validators.required,
      Validators.maxLength(50),
    ]),
    password: new UntypedFormControl(undefined, [
      Validators.required,
      Validators.minLength(8),
      Validators.maxLength(40),
      Validators.pattern(/[\d]/),
      Validators.pattern(/[a-z]/),
      Validators.pattern(/[A-Z]/),
    ]),
    firstName: new UntypedFormControl(undefined, [
      Validators.required,
      Validators.maxLength(30),
      Validators.pattern('[^/:,><@]*'),
      Validators.pattern(/^(?!.*(www|http))/),
    ]),
    lastName: new UntypedFormControl(undefined, [
      Validators.required,
      Validators.maxLength(30),
      Validators.pattern('[^/:,><@]*'),
      Validators.pattern(/^(?!.*(www|http))/),
    ]),
  });

  @Output() formSubmit = new EventEmitter<{
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    optinEmail?: boolean;
    agreeTerms?: boolean;
    locale: string;
  }>();

  @Output() closeModal = new EventEmitter<void>();

  @Input() signUpProps: {
    error: object;
    formDisabled: boolean;
    success: boolean;
  };

  @Input() locale: string;

  @Input() set configData(value: Config) {
    this.config = value;

    if (
      !this.config?.accountSettings?.registrationSideContent?.title &&
      !this.config?.accountSettings?.registrationSideContent?.description
    ) {
      this.mobileTab = 'form';
    }
  }

  constructor(
    @Inject(DOCUMENT)
    public document: Document
  ) {}

  public ngOnInit(): void {
    // dynamically add form controls, depending on wordpress input
    if (this.config?.accountSettings?.registrationFormFields?.agreeWithTerms) {
      this.form.addControl(
        'agreeTerms',
        new UntypedFormControl(undefined, [Validators.required])
      );
    }
    if (this.config?.accountSettings?.registrationFormFields?.optInForEmail) {
      this.form.addControl('optinEmail', new UntypedFormControl(undefined, []));
    }
    this.form.addControl(
      'locale',
      new UntypedFormControl(
        this.locale ? this.locale : this.config.brand.defaultLocaleCode,
        []
      )
    );
  }

  public ngOnChanges(changes: SimpleChanges): void {
    // check if we need to disable or enable the form
    if (
      !changes.signUpProps?.isFirstChange() &&
      changes.signUpProps?.currentValue.formDisabled !==
        changes.signUpProps?.previousValue.formDisabled
    ) {
      this.form[
        changes.signUpProps?.currentValue.formDisabled ? 'disable' : 'enable'
      ]();
    }

    // check for errors
    if (
      !changes.signUpProps?.isFirstChange() &&
      changes.signUpProps?.currentValue.error !==
        changes.signUpProps?.previousValue.error
    ) {
      this.errors = changes.signUpProps?.currentValue.error;
      if (this.errors?.code === 'AccountAlreadyExisting') {
        this.form.controls['email'].setErrors({ notUnique: true });
      }
    }
  }

  get email() {
    return this.form.get('email');
  }

  get password() {
    return this.form.get('password');
  }

  get firstName() {
    return this.form.get('firstName');
  }

  get lastName() {
    return this.form.get('lastName');
  }

  get optinEmail() {
    return this.form.get('optinEmail');
  }

  get agreeTerms() {
    return this.form.get('agreeTerms');
  }

  public close() {
    this.closeModal.emit();
  }

  public onSubmit() {
    if (this.form.invalid) {
      return;
    }

    this.errors = undefined;
    this.form.disable();

    const {
      email,
      password,
      firstName,
      lastName,
      optinEmail,
      agreeTerms,
      locale,
    } = this.form.value;

    this.formSubmit.emit({
      email,
      password,
      firstName,
      lastName,
      optinEmail,
      agreeTerms,
      locale,
    });
  }

  ngOnDestroy(): void {
    this.close();
  }
}
