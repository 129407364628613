import { isPlatformBrowser } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Environment, EnvironmentType } from '@ibep/interfaces';
import { BrowserTracing } from '@sentry/angular-ivy';
import { version } from '../../../../../../../../version';

/**
 * This global error handler provides a hook for centralized exception handling. The default implementation of the angular
 * ErrorHandler prints error messages to the console. We can differentiate between server and client side errors.
 * With this error handler we can, for example, show customized error messages to the user
 *
 * @export
 * @class GlobalErrorHandler
 * @implements {ErrorHandler}
 */
@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: any,
    @Inject('ENVIRONMENT') private environment: Environment,
    @Inject('SENTRY') private sentry: any
  ) {
    if (isPlatformBrowser(this.platformId) && this.environment.sentry) {
      sentry.init({
        dsn: 'https://b5c80e4957b64276b589810b3056c182@o1244655.ingest.sentry.io/6408739',
        environment: this.environment.name,
        release: `ibep-v2-frontend@${version}`,
        integrations:
          this.environment.name === EnvironmentType.test ||
          this.environment.name === EnvironmentType.staging
            ? [
                // Registers and configures the Tracing integration,
                // which automatically instruments your application to monitor its
                // performance
                new BrowserTracing({
                  tracingOrigins: ['localhost', /^\//],
                  routingInstrumentation: sentry.routingInstrumentation,
                }),
              ]
            : [],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        tracesSampleRate: 0.5,
      });
    }
  }

  handleError(error: any) {
    const extractedError = this._extractError(error) || 'Handled unknown error';
    if (isPlatformBrowser(this.platformId)) {
      const eventId = this.sentry.captureException(
        error.originalError || error
      );
      // this.sentry.showReportDialog({ eventId });
    }

    console.error(extractedError);
  }

  /**
   * Used to pull a desired value that will be used to capture an event out of the raw value captured by ErrorHandler.
   */
  protected _extractError(errorCandidate: any): any {
    let error = errorCandidate;
    // Try to unwrap zone.js error.
    // https://github.com/angular/angular/blob/master/packages/core/src/util/errors.ts
    if (error && (error as { ngOriginalError: Error }).ngOriginalError) {
      error = (error as { ngOriginalError: Error }).ngOriginalError;
    }

    // We can handle messages and Error objects directly.
    if (typeof error === 'string' || error instanceof Error) {
      return error;
    }

    // If it's http module error, extract as much information from it as we can.
    if (error instanceof HttpErrorResponse) {
      // The `error` property of http exception can be either an `Error` object, which we can use directly...
      if (error.error instanceof Error) {
        return error.error;
      }

      // ... or an`ErrorEvent`, which can provide us with the message but no stack...
      // if (error.error instanceof ErrorEvent && error.error.message) {
      //   return error.error.message;
      // }

      // ...or the request body itself, which we can use as a message instead.
      if (typeof error.error === 'string') {
        return `Server returned code ${error.status} with body "${error.error}"`;
      }

      // If we don't have any detailed information, fallback to the request message itself.
      return error.message;
    }

    // Nothing was extracted, fallback to default error message.
    return null;
  }
}
