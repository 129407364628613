<div class="mb-4">
  <div class="pb-3 flex gap-3">
    <div *ngIf="props.image" class="min-w-[55px]">
      <img [src]="props.image" alt="" class="max-h-14 w-14 h-14 rounded-full" />
    </div>
    <div>
      <h4 class="font-bold text-base mb-1" [innerHTML]="props.name"></h4>
      <h5 class="text-sm text-grayLight" [innerHTML]="props.jobTitle"></h5>
    </div>
  </div>
  <ibep-paragraph *ngIf="props.text" [html]="props.text"></ibep-paragraph>
</div>
