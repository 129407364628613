import { Injectable } from '@angular/core';
import { IdbStorageService } from '@ibep/fe/web/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
  useClass: IdbStorageService,
})
export abstract class AbstractStorageService {
  public abstract get<T>(key: string): Observable<T | undefined>;
  public abstract set(key: string, value: any, ttl: number): Observable<void>;
  public abstract update<T>(key: string, value: any): Observable<void>;
  public abstract getMany<T>(key: string[]): Observable<T[]>;
  public abstract setMany(entries: [string, any][]): Observable<void>;
  public abstract delete(key: string): Observable<void>;
  public abstract clear(): Observable<void>;
  public abstract deleteKeysByPrefix(prefix: string): Observable<void>;
  public abstract getAllKeys(): Observable<string[]>;
}
