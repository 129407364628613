import { Location } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  LocalizeParser,
  LocalizeRouterModule,
  LocalizeRouterSettings,
} from '@gilsdav/ngx-translate-router';
import { LocalizeRouterTranslationLoader } from '@ibep/fe/shared/core';
import { ConfigData } from '@ibep/fe/shared/data';
import { TranslateService } from '@ngx-translate/core';
import { APP_ROUTES } from './app.routes';

/**
 * This function is used the determine which language is the default language for the localized router.
 * From an array of langs we always choose the lang with index = 0. In the LocalizeRouterHttpBrandLoader
 * class we make sure the default lang is placed at the beginning of this array.
 *
 * @export
 * @param {string[]} langs
 * @returns
 */
export function pickFirstLangFunction(langs: string[]) {
  return langs[0];
}

/**
 * Factory function for the loader of route localization files
 *
 * @export
 * @param {TranslateService} translate
 * @param {Location} location
 * @param {LocalizeRouterSettings} settings
 * @param {ConfigData} configData
 * @returns
 */
export function LocalizeRouterHttpBrandLoaderFactory(
  translate: TranslateService,
  location: Location,
  settings: LocalizeRouterSettings,
  configData: ConfigData
) {
  return new LocalizeRouterTranslationLoader(
    translate,
    location,
    settings,
    configData
  );
}

@NgModule({
  imports: [
    RouterModule.forRoot(APP_ROUTES, {
      // see https://github.com/gilsdav/ngx-translate-router#deal-with-initialNavigation
      initialNavigation: 'disabled',
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    }),
    LocalizeRouterModule.forRoot(APP_ROUTES, {
      parser: {
        provide: LocalizeParser,
        useFactory: LocalizeRouterHttpBrandLoaderFactory,
        deps: [TranslateService, Location, LocalizeRouterSettings, ConfigData],
      },
      alwaysSetPrefix: false,
      defaultLangFunction: pickFirstLangFunction,
      initialNavigation: true,
    }),
  ],
  exports: [RouterModule, LocalizeRouterModule],
})
export class AppRoutingModule {}
