import { Injectable } from '@angular/core';
import {
  AbstractBackendService,
  AbstractEnvironmentService,
  AbstractLanguageService,
  AbstractStorageService,
  ReadingPlansOrEpisodesSearchResult,
  ResponseData,
  SearchCategory,
} from '@ibep/interfaces';
import { HOUR } from '@ibep/shared/util';
import { Observable, of } from 'rxjs';
import { map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { ConfigData } from './config.data';
import { Store, StoreSettings, StoreSettingsTTL } from './store';

@Injectable({
  providedIn: 'root',
})
export class SearchEpisodesData extends Store<{
  [key: string]: { [key: string]: ReadingPlansOrEpisodesSearchResult };
}> {
  constructor(
    storage: AbstractStorageService,
    environment: AbstractEnvironmentService,
    backendService: AbstractBackendService,
    languageService: AbstractLanguageService,
    config: ConfigData
  ) {
    super(storage, environment, backendService, languageService, {
      storeTtl: { default: 1 * HOUR } as StoreSettingsTTL,
      storeKey: 'searchResults',
      brand$: config.getBrand(),
    } as StoreSettings);
  }

  /**
   * Get the reading plans search results
   *
   * @returns {Observable<SearchResult>}
   * @memberof SearchReadingPlansData
   */
  public getSearchResults({
    query,
    limit,
    page = 1,
    tags = '',
  }: {
    query: string;
    limit: number;
    page?: number;
    tags?: string;
  }): Observable<ReadingPlansOrEpisodesSearchResult> {
    if (!this.remoteDataMap$[SearchCategory.EPISODES]) {
      this.remoteDataMap$[SearchCategory.EPISODES] = {};
    }
    // To prevent multiple subscribers for same search at the same time causing duplicate http calls we store the observable in property of this class.
    this.remoteDataMap$[SearchCategory.EPISODES] = {
      ...this.remoteDataMap$[SearchCategory.EPISODES],
    };
    this.remoteDataMap$[SearchCategory.EPISODES][
      `${query}-${limit}-${page}-${tags}`
    ] = this.backendService
      .get<ResponseData<ReadingPlansOrEpisodesSearchResult>>({
        endpoint: `search/episodes/${query}`,
        options: {
          params: {
            limit,
            page,
            tags,
            language: encodeURIComponent(this._currentLanguage),
          },
          headers: {
            'x-brand': this.brand.id,
            Authorization: 'Bearer anonymous',
          },
        },
      })
      .pipe(
        map(
          (searchResult: ResponseData<ReadingPlansOrEpisodesSearchResult>) =>
            searchResult.data
        ),
        shareReplay(1)
      );

    return this.localData$.pipe(
      take(1),
      switchMap((localData: any) => {
        // check if there is local data available
        if (
          localData?.[SearchCategory.EPISODES]?.[
            `${query}-${limit}-${page}-${tags}`
          ]
        ) {
          return of(
            localData[SearchCategory.EPISODES][
              `${query}-${limit}-${page}-${tags}`
            ]
          );
        }
        // if no local data, fetch data from api
        return this.remoteDataMap$[SearchCategory.EPISODES][
          `${query}-${limit}-${page}-${tags}`
        ].pipe(
          tap((remoteData: ReadingPlansOrEpisodesSearchResult) => {
            // write returned data from api to the store
            this.setState(
              {
                [SearchCategory.EPISODES]: {
                  [`${query}-${limit}-${page}-${tags}`]: remoteData,
                },
              },
              true
            );
          })
        ) as Observable<ReadingPlansOrEpisodesSearchResult>;
      })
    );
  }
}
