import { Component } from '@angular/core';
/**
 * https://ubsicap.github.io/usx/parastyles.html#lim
 * Embedded list entry.
 * An out-dented paragraph meant to highlight the items of an embedded list.
 * Has a multi-level indentation
 * NOTE: scss from bible-chapter.component.scss [class^='li'] applicable
 * @example https://debijbel.nl/bijbel/NBV21/NEH.7
 * @export
 * @class LimComponent
 */
@Component({
  selector: 'bible-lim',
  template: '<p class="lim"><ng-content></ng-content></p>',
  styleUrls: ['./lim.component.scss'],
})
export class LimComponent {}
