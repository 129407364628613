import { Component } from '@angular/core';

@Component({
  selector: 'bible-sr',
  template: `
    <h4 class="sr">
      <ng-content></ng-content>
    </h4>
  `,
  styleUrls: ['./sr.component.scss'],
})
export class SrComponent {}
