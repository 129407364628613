import { Component } from '@angular/core';

/**
 * https://ubsicap.github.io/usx/charstyles.html#add
 *
 * @export
 * @class AddComponent
 */
@Component({
  selector: 'bible-add',
  template: `
    <span class="add">
      <ng-content></ng-content>
    </span>
  `,
  styleUrls: ['./add.component.scss'],
})
export class AddComponent {}
