<div
  class="flex flex-col gap-2 lg:gap-0 lg:flex-row bg-primary bg-opacity-5 lg:bg-white lg:mx-0 px-5 lg:px-0"
>
  <div
    class="hero h-40 w-full rounded-lg lg:rounded-l-2xl lg:rounded-r-none lg:h-hero px-5 pt-2 pb-4 lg:pl-14 flex flex-col justify-around items-center lg:py-24 lg:items-start lg:min-w-[533px]"
    [ngClass]="{
      'lg:w-db-hero-lft': heroProps?.dailyVerse?.content,
      'lg:w-2/5':
        heroProps?.dailyVerseImage?.length || heroProps?.dailyVerse?.content,
      'lg:rounded-r-2xl':
        !heroProps?.dailyVerseImage?.length && !heroProps?.dailyVerse?.content
    }"
  >
    <h1
      class="font-bold text-lg lg:text-[36px] lg:leading-[48px] mb-2 lg:mb-0 lg:pr-32 text-white"
    >
      {{ 'ReadAndDiscover*' | translate }}
    </h1>

    <div class="flex flex-col lg:flex-row items-center w-full">
      <a
        [routerLink]="
          '/ROUTES.bible' +
            '/' +
            currentBibleAbbrs.join(',') +
            '/' +
            currentBibleChapter | localize
        "
        class="w-full lg:w-auto flex justify-center items-center"
      >
        <ibep-button
          class="text-sm mb-1 lg:mb-0 lg:mr-3 w-full sm:w-auto m-auto"
          [buttonProps]="{
            pushGtm: true,
            name: 'read_bible',
            content:
              'StartIn_VAR'
              | translate
                : {
                    CHAPTER: currentBibleChapterFullname
                  },
            link: '/ROUTES.bible' | localize,
            style: 'white',
            size: 'l',
            shadow: true
          }"
        >
          <b class="text-xs">{{
            'StartIn_VAR'
              | translate
                : {
                    CHAPTER: currentBibleChapterFullname
                  }
          }}</b>
          <span class="text-grayLight text-xs ml-2">
            {{ currentBibleAbbrs[0] }}
          </span>
        </ibep-button>
      </a>

      <div class="pt-3 lg:pt-0">
        <a
          class="text-grayExtraLight text-2xs hover:underline transition cursor-pointer ml-1"
          (click)="
            openModal();
            onGtmTagPush({
              event: 'button_click',
              button_name: 'chapter_selector_home',
              button_text: 'Select book or chapter'
            })
          "
          >{{ 'SelectBookOr*' | translate }}
          <span class="ml-1" *ngIf="isBrowser"
            ><ibep-chevron-down-svg
              svgStyles="w-3 h-3"
            ></ibep-chevron-down-svg></span
        ></a>
      </div>
    </div>
  </div>
  <div
    *ngIf="heroProps?.dailyVerseImage?.length || heroProps?.dailyVerse?.content"
    class="verse py-6 lg:px-0 lg:h-hero lg:flex-grow flex flex-col lg:rounded-r-2xl justify-around items-center lg:justify-start lg:items-start lg:bg-gradient-to-r lg:from-gray-300 lg:to-gray-200 lg:w-3/5 overflow-hidden"
    [style.background-image]="
      heroProps.dailyVerseImage ? 'url(' + heroProps.dailyVerseImage + ')' : ''
    "
    aria-label="daily verse"
  >
    <div
      class="flex flex-col items-center justify-center px-4 lg:m-auto"
      *ngIf="heroProps?.dailyVerse?.content"
    >
      <div
        class="text-black font-bold uppercase text-base mb-5 text-center lg:text-left"
      >
        {{
          'BibleVerseOf_VAR'
            | translate
              : {
                  DAY: 'Today' | translate | lowercase
                }
        }}
      </div>

      <div>
        <div #verseWrapper class="max-h-28 overflow-hidden">
          <div
            *ngFor="let verse of heroProps?.dailyVerse?.content || []"
            class="text-black text-base max-w-lg italic leading-10 text-center lg:text-left"
          >
            <ng-container *ngFor="let verseContent of verse; let i = index">
              <ibep-verse-text-renderer
                *ngIf="verseContent.type === 'verse-text'"
                [style]="verseContent.style"
                [content]="verseContent.content"
              ></ibep-verse-text-renderer>
              <span
                *ngIf="
                  !verseContent.style && verse[i + 1] && !verse[i + 1]?.style
                "
              >
                {{ ' ' }}
              </span>
            </ng-container>
          </div>
        </div>
        <div
          *ngIf="verseWrapper.scrollHeight > verseWrapper.clientHeight"
          class="italic leading-10 text-center lg:text-left"
        >
          ...
        </div>
      </div>

      <div
        class="mt-3 flex flex-wrap text-center justify-center items-center lg:justify-start lg:text-left"
      >
        <a
          [routerLink]="
            '/ROUTES.bible' +
              '/' +
              heroProps?.bible?.abbreviationLocal +
              '/' +
              heroProps?.dailyVerse?.id | localize
          "
        >
          <ibep-button
            class="text-sm mb-4 lg:mb-0 mr-3"
            aria-label="read the daily verse"
            [buttonProps]="{
              pushGtm: true,
              content: heroProps?.dailyVerse?.reference,
              link:
                '/ROUTES.bible' +
                  '/' +
                  heroProps?.bible?.abbreviationLocal +
                  '/' +
                  heroProps?.dailyVerse?.id | localize,
              size: 'm'
            }"
          >
            <b class="text-black font-normal">{{
              heroProps.dailyVerse.id
                | formatChapterTitle : heroProps.bibleMetaData
            }}</b>
            <span class="text-grayLight ml-2">{{
              heroProps?.bible?.abbreviationLocal
            }}</span>
          </ibep-button>
        </a>

        <a
          *ngIf="heroProps?.dailyVerse?.showExplanationButton"
          [routerLink]="
            '/ROUTES.readingplans' +
              '/' +
              heroProps?.dailyVerse?.readingPlan +
              '/' +
              heroProps?.dailyVerse?.episodeSlug | localize
          "
        >
          <ibep-button
            class="text-sm mb-4 lg:mb-0 lg:mr-3"
            [buttonProps]="{
              pushGtm: true,
              content: 'Explanation' | translate,
              link:
                '/ROUTES.readingplans' +
                  '/' +
                  heroProps?.dailyVerse?.readingPlan +
                  '/' +
                  heroProps?.dailyVerse?.episodeId | localize,
              size: 'm'
            }"
          >
            <b class="text-black font-normal">{{
              'Explanation' | translate
            }}</b>
          </ibep-button>
        </a>
      </div>
    </div>
  </div>
</div>
