import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigLocalizedData } from '@ibep/fe/shared/data';
import {
  AbstractAuthService,
  AbstractBackendService,
  AbstractEnvironmentService,
  AccountTab,
  ChangePasswordData,
  Config,
  ResponseData,
  UserInfo,
} from '@ibep/interfaces';
import { TranslateService } from '@ngx-translate/core';
import { catchError, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  // TODO: get it from config when it will be implemented on the API side
  private _baseUrl: string;
  private _voucherMessage;
  private _isPremium = false;
  private _hasPremiumLayer;
  private _enableVouchers;

  constructor(
    private readonly backendService: AbstractBackendService,
    private readonly environment: AbstractEnvironmentService,
    private readonly configData: ConfigLocalizedData,
    private readonly _authService: AbstractAuthService,
    private readonly _translateService: TranslateService
  ) {
    this.configData.getConfig().subscribe((config: Config) => {
      this._baseUrl = `https://${config.brand.id}${this.environment.authBaseUrl}`;
      this._voucherMessage = config.accountMessages?.premiumVoucher;
      this._hasPremiumLayer = config.brand.brandUIPreferences?.hasPremiumLayer;
      this._enableVouchers = config.brand.brandUIPreferences?.enableVouchers;
    });

    this._authService.isPremium$.subscribe(
      (isPremium) => (this._isPremium = isPremium)
    );
  }

  public updateUserInfo(
    userInfo: UserInfo
  ): Observable<ResponseData<{ userId: string }> | HttpErrorResponse> {
    return this.backendService
      .put<ResponseData<{ userId: string }> | HttpErrorResponse>({
        baseUrl: this._baseUrl,
        endpoint: 'userInfo',
        options: {
          body: userInfo,
        },
      })
      .pipe(catchError((error) => of(error)));
  }

  public changePassword(
    passwordData: ChangePasswordData
  ): Observable<ResponseData<{ userId: string }> | HttpErrorResponse> {
    return this.backendService
      .post<ResponseData<{ userId: string }> | HttpErrorResponse>({
        baseUrl: this._baseUrl,
        endpoint: 'changePassword',
        options: {
          body: passwordData,
        },
      })
      .pipe(catchError((error) => of(error)));
  }

  public deleteUser(
    password: string
  ): Observable<ResponseData<{ userId: string }> | HttpErrorResponse> {
    return this.backendService
      .delete<ResponseData<{ userId: string }> | HttpErrorResponse>({
        baseUrl: this._baseUrl,
        endpoint: 'deleteAccount',
        options: {
          body: { password },
        },
      })
      .pipe(catchError((error) => of(error)));
  }

  get accountTabs() {
    const tabs = [
      {
        value: AccountTab.YOUR_DATA,
        title: this._translateService.instant('YourData'),
      },
      {
        value: AccountTab.PASSWORD,
        title: this._translateService.instant('Password'),
      },
      {
        value: AccountTab.DELETE_ACCOUNT,
        title: this._translateService.instant('DeleteAccount'),
      },
    ];
    if (this._hasPremiumLayer && this._enableVouchers) {
      const voucherTab = {
        value: AccountTab.PREMIUM_VOUCHER,
        title: this._translateService.instant('PremiumVoucher'),
        description: this._isPremium
          ? this._voucherMessage?.premiumMessage
          : this._voucherMessage?.registeredMessage,
      };
      tabs.push(voucherTab);
    }
    return tabs;
  }
}
