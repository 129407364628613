import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  BibleMetaData,
  ReaderStateInterface,
  UserNote,
} from '@ibep/fe/shared/data';
import { BibleService, Scrolled } from '@ibep/fe/shared/bible';
import { BiblePassage, CustomStyling } from '@ibep/interfaces';
import { arrayIntersect } from '@ibep/shared/util';

@Component({
  selector: 'ibep-bible-passage',
  templateUrl: './bible-passage.component.html',
  styleUrls: ['./bible-passage.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BiblePassageComponent implements OnInit, OnChanges {
  @Input() item: BiblePassage[];
  @Input() chapter: [];
  @Input() customStyling: CustomStyling;
  @Input() hovered: string[] = [''];
  @Input() selected: string[] = [''];
  @Input() highlighted: string[] = [''];
  @Input() flashed: string[] = [''];
  @Input() scrolled: Scrolled = <Scrolled>{};
  @Input() bibleAbbr = '';
  @Input() readerState: ReaderStateInterface = <ReaderStateInterface>{};
  @Input() isBrowser: boolean;
  @Input() studyContent?: any;
  @Input() userNotes?: any[];
  @Input() userHighlights?: any;
  @Input() isAuthenticated?: boolean;
  @Input() isSearchPage: boolean;
  @Input() passageIndex: number;
  @Input() isHighlightsPage: boolean;
  @Output() pushGtmTag = new EventEmitter<object>();
  @Output() userNoteDeleted = new EventEmitter<string>();
  @Output() userNoteUpdated = new EventEmitter<UserNote>();

  @Input() set createdNoteVerseOrgIds(value: string[]) {
    if (value?.length) {
      this.toggleUserNote(value);
    }
  }

  @ViewChild('userNoteTextArea') set userNoteTextAreaEl(ref: ElementRef) {
    if (ref) {
      // set usernote text area as active when in view
      ref.nativeElement.focus();
    }
  }

  public activePopover: {
    id: string | undefined;
    position: 'left' | 'right' | undefined;
    content: any;
  };

  public activeFootnote: {
    id: string | undefined;
    content: any;
  };

  public activeUsernote: {
    noteId: string | undefined;
    content: string | undefined;
    title: string | undefined;
    contentEditValue?: string | undefined;
    titleEditValue?: string | undefined;
  };

  public userNoteEditMode = false;
  public Array = Array;

  public isSelected = false;
  public verseIds: string;

  // FIXME: remove bible service from here
  constructor(private readonly _bibleService: BibleService) {}

  ngOnInit(): void {
    // check if a passage has a related footnote or usernote, so we can show dotted underline/border
    this._checkIfPassageHasNote();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['userNotes'] && !changes['userNotes']?.firstChange) {
      // when notes are changing we must update the dotted underlines
      if (
        changes['userNotes'].currentValue?.length !==
        changes['userNotes'].previousValue?.length
      ) {
        this._checkIfPassageHasNote();
      }
      // open the note inline editor when a new note is added
      if (
        changes['userNotes'].currentValue?.length >
        changes['userNotes'].previousValue?.length
      ) {
        const newNote = changes['userNotes'].currentValue.filter(
          (x: any) => !changes['userNotes'].previousValue.includes(x)
        );
        if (newNote.length && newNote[0].activeColumn === this.bibleAbbr) {
          this.activeUsernote = newNote[0];
          this.userNoteEditMode = true;
        }
      }
    }
  }

  /**
   * Used by DOM, each versetext <span> triggers this when hovered
   * @param {string} verseOrgId
   * @memberof BiblePassageComponent
   */
  public hover(verseOrgIds: string[]): void {
    this._bibleService.hovered = verseOrgIds;
  }

  /**
   * Used by DOM, each versetext <span> triggers this when clicked
   * @param {string} verseOrgId
   * @memberof BiblePassageComponent
   */
  public select(verseOrgIds: string[], verseIds: string[]): void {
    this._bibleService.selected = {
      verseOrgIds,
      verseIds,
      activeColumn: this.bibleAbbr,
    };

    this.onGtmTagPush('bibleverse_click', verseOrgIds[0]);
  }

  /**
   * This function is triggered when the passage scrolls into the viewport
   *
   * @param {string} verseOrgId
   * @memberof BiblePassageComponent
   */
  public scroll(verseId: string, bibleAbbr: string) {
    this._bibleService.scrolled = { verseId, bibleAbbr };
  }

  /**
   * Toggle a popover (used for cross references)
   *
   * @param {boolean} isHovering
   * @param {string} id
   * @param {*} [el]
   * @memberof BiblePassageComponent
   */
  public togglePopover(verseId: string, id: string, el?: any) {
    const crossRefId = `${verseId}-${id}`;
    let gtmEvent: string;
    if (
      !this.activePopover ||
      (this.activePopover && this.activePopover.id !== crossRefId)
    ) {
      const content = this.studyContent.crossReferences[crossRefId];
      // create link part
      if (Array.isArray(content?.content) && content?.content[1]?.content) {
        if (Array.isArray(content?.content[1]?.content)) {
          content.content[1].content = content.content[1].content?.map(
            (item: any) => {
              item.link = this.createRefLink(item.loc);
              return item;
            }
          );
        }
      }

      // crate active popover object
      this.activePopover = {
        id: crossRefId,
        position: el ? this._getPopoverPosition(el) : undefined,
        content,
      };

      gtmEvent = 'reference_open';
    } else {
      // clear popover
      this.activePopover = {
        id: undefined,
        position: undefined,
        content: undefined,
      };

      gtmEvent = 'reference_close';
    }

    this.onGtmTagPush(gtmEvent, verseId);
  }

  /**
   * Toggle footnote
   *
   * @param {string} id
   * @memberof BiblePassageComponent
   */
  public toggleFootnote(verseId: string, id: string): void {
    const footNoteId = `${verseId}-${id}`;
    let gtmEvent: string;

    if (
      !this.activeFootnote ||
      (this.activeFootnote && this.activeFootnote.id !== footNoteId)
    ) {
      const content = this.studyContent.footNotes[footNoteId]
        ? this.studyContent.footNotes[footNoteId]
        : this.studyContent.extendedFootnotes[footNoteId];
      // create active footnote object
      this.activeFootnote = {
        id: footNoteId,
        content,
      };

      gtmEvent = 'footnote_open';
    } else {
      // clear popover
      this.activeFootnote = {
        id: undefined,
        content: undefined,
      };

      gtmEvent = 'footnote_close';
    }

    this.onGtmTagPush(gtmEvent, verseId);
  }

  public toggleUserNote(verseOrgIds: string[], index?: number) {
    let gtmEvent: string;
    // get the active note data
    const notes =
      this.userNotes?.filter((userNote) =>
        verseOrgIds?.includes(
          userNote.verseOrgIds[userNote.verseOrgIds.length - 1]
        )
      ) || [];
    const note = notes[index !== undefined ? index : notes?.length - 1];
    if (
      !this.activeUsernote?.noteId ||
      this.activeUsernote?.noteId !== note?.noteId
    ) {
      this.activeUsernote = note;
      if (!this.activeUsernote?.noteId) {
        this.userNoteEditMode = true;
      } else {
        this.userNoteEditMode = false;
      }
      gtmEvent = 'usernote_open';
    } else {
      this.activeUsernote = {
        noteId: undefined,
        content: undefined,
        title: undefined,
        contentEditValue: '',
        titleEditValue: '',
      };
      gtmEvent = 'usernote_close';
    }

    this.onGtmTagPush(gtmEvent, verseOrgIds[0]);
  }

  cancelUserNoteEdit() {
    if (
      this.activeUsernote.contentEditValue ||
      this.activeUsernote.titleEditValue
    ) {
      this.userNoteEditMode = false;
      this.activeUsernote.contentEditValue = this.activeUsernote.content;
      this.activeUsernote.titleEditValue = this.activeUsernote.title;
    }
  }

  public toggleUserNoteEditMode() {
    this.userNoteEditMode = !this.userNoteEditMode;
    this.activeUsernote.contentEditValue = this.activeUsernote.content;
    this.activeUsernote.titleEditValue = this.activeUsernote.title;
  }

  public getNoteId(verseOrgIds: string[], noteIndex?: number) {
    const notes = this.userNotes?.filter((userNote) =>
      verseOrgIds?.includes(
        userNote.verseOrgIds[userNote.verseOrgIds.length - 1]
      )
    );
    return notes?.[noteIndex || 0].noteId;
  }

  public onNoteChange(event: any) {
    this.activeUsernote.contentEditValue = event.target.value;
  }

  public onNoteTitleChange(event: any) {
    this.activeUsernote.titleEditValue = event.target.value;
  }

  public deleteUserNote() {
    this.userNoteDeleted.emit(this.activeUsernote.noteId);
  }

  public updateUserNote() {
    if (this.activeUsernote.contentEditValue) {
      this.userNoteEditMode = false;
      this.activeUsernote.content = this.activeUsernote.contentEditValue;
      this.activeUsernote.title = this.activeUsernote.titleEditValue;
      this.userNoteUpdated.emit(this.activeUsernote as UserNote);
    }
  }

  /**
   * If an element is on the right side of the bible column we want to display the popover on the left side of the element
   * and the other way around.
   *
   * @private
   * @param {*} el
   * @returns {('left' | 'right')}
   * @memberof BiblePassageComponent
   */
  private _getPopoverPosition(el: any): 'left' | 'right' {
    const elementOffsetLeft = el.offsetLeft;
    const columnWidth = this.getColumnWidth(el);
    return columnWidth / 2 >= elementOffsetLeft ? 'right' : 'left';
  }

  /**
   * Get the column width
   *
   * @private
   * @param {*} el
   * @returns
   * @memberof BiblePassageComponent
   */
  public getColumnWidth(el: any) {
    return el?.offsetParent?.offsetWidth;
  }

  /**
   * Checks if 2 arrays have an intersection
   *
   * @param {string[]} arr1
   * @param {string[]} arr2
   * @returns
   * @memberof BiblePassageComponent
   */
  public checkIntersection(arr1: string[], arr2: string[]) {
    return arrayIntersect(arr1, arr2);
  }

  /**
   * checks if verse contains a highlight
   *
   * @param {string[]} verseOrgIds
   * @returns
   * @memberof BiblePassageComponent
   */
  public checkHighlight(verseOrgIds: string[], color?: string) {
    if (!this.isAuthenticated || !Array.isArray(verseOrgIds)) {
      return false;
    }
    return verseOrgIds?.some((verseOrgId) =>
      this.userHighlights?.some((highlight: any) => {
        if (color) {
          return verseOrgId === highlight.verseId && color === highlight.color;
        }
        return verseOrgId === highlight.verseId;
      })
    );
  }

  /**
   * Check if passage has a related user note
   *
   * @param {string[]} verseOrgIds
   * @param {*} index
   * @param {any[]} nodes
   * @returns
   * @memberof BiblePassageComponent
   */
  public checkUserNote(verseOrgIds: string[], index: number, nodes: any[]) {
    if (!this.isAuthenticated) {
      return false;
    }
    // filter the notes
    const notes = this.userNotes?.filter((note) =>
      verseOrgIds?.includes(note.verseOrgIds[note.verseOrgIds.length - 1])
    );
    if (!notes?.length) {
      return false;
    }
    // we only want to show the note icon after the latest selected passage
    const mappedNodes = nodes.map((node) => node.verseOrgId);
    const filteredNodes = mappedNodes.filter((verseIds) =>
      this.checkIntersection(verseIds, verseOrgIds)
    );
    const lastIndex = mappedNodes.lastIndexOf(
      filteredNodes[filteredNodes.length - 1]
    );
    let isLastVerse = false;
    if (notes?.length && notes[0].verseOrgIds) {
      isLastVerse =
        notes?.[0].verseOrgIds[notes[0].verseOrgIds.length - 1] ===
        verseOrgIds[verseOrgIds.length - 1];
    }
    if (!!notes?.length && lastIndex === index && isLastVerse) {
      return notes;
    }
    return false;
  }

  /**
   * Create link for cross references
   * TODO: now link only highlights start passage, we need to link to verse range and highlight the full range
   * also when range is spanning multiple chapters
   *
   * @param {*} loc  loc: "GEN 2:4-25"
   * @returns GEN.2.4-GEN.2.25
   * @memberof BiblePassageComponent
   */
  public createRefLink(loc: string): string {
    if (loc) {
      const book = loc.split(' ')[0];
      const startChapter = loc.split(' ')[1]?.split('-')[0]?.split(':')[0];
      const startPassage = loc.split(' ')[1]?.split('-')[0]?.split(':')[1];
      const stopPassage = loc.split(' ')[1]?.split('-')[1];
      return `${book}.${startChapter}.${startPassage}${
        stopPassage ? `-${book}.${startChapter}.${stopPassage}` : ''
      }`;
    }
    return '';
  }

  public isThisAnArray(arr: any): boolean {
    return Array.isArray(arr);
  }

  public createVerseId(verseOrgId: string[]): string | void {
    if (verseOrgId && Array.isArray(verseOrgId)) {
      return verseOrgId
        .map((verseId: string) => `${this.bibleAbbr}.${verseId}`)
        .join(' ');
    }
  }

  public isArray(input: any) {
    return Array.isArray(input);
  }

  public onGtmTagPush(
    event: string,
    verseId = '',
    mouseEvent?: MouseEvent
  ): void {
    const tag = {
      event,
      bible_book: this.readerState.selectedChapter?.split('.')?.[0],
      bible_chapter: this.readerState.selectedChapter,
      bible_chapter_verse: verseId,
      bible_translation: this.bibleAbbr,
    };

    this.pushGtmTag.emit(tag);

    if (mouseEvent) {
      mouseEvent.stopPropagation();
    }
  }

  private _checkIfPassageHasNote() {
    // check if a passage has a related footnote, so we can show dotted underline/border
    if (this.item) {
      // footnotes
      const footnotes = this.item
        .filter((passage) => passage.subtype === 'footnote')
        .map((passage) => passage.verseId);
      if (footnotes.length) {
        this.item = this.item.map((passage) => {
          if (footnotes.includes(passage.verseId)) {
            return { ...passage, hasFootnote: true };
          }
          return passage;
        });
      }
      // user notes
      if (this.isAuthenticated) {
        const userNotes = this.userNotes?.map((note) => note.verseOrgIds);
        this.item = this.item.map((passage, index) => {
          let lastOccurance = false;
          if (
            userNotes?.some((userNote) =>
              this.checkIntersection(userNote, passage.verseOrgId)
            )
          ) {
            // FIXME: ugly hotfix: if passage has a usernote, check if it is the last occurance in chapter
            const mapp = this.chapter
              .map((section: { content: BiblePassage[] }, i: number) => {
                if (
                  section.content?.some(
                    (pas) => pas.verseId === passage.verseId
                  )
                ) {
                  return { ...section, i };
                }
              })
              .filter((section) => section);
            if (mapp.length) {
              const lastI = mapp[mapp.length - 1];
              if (lastI) {
                if (lastI.i === this.passageIndex) {
                  lastOccurance = true;
                }
              }
            }

            return { ...passage, hasFootnote: true, lastOccurance };
          }
          return { ...passage, hasFootnote: false };
        });
      }
    }
  }

  get columnsNumber(): number {
    return this.readerState?.selectedBibles?.bibleAbbrs?.length || 0;
  }

  typeChecker(value: any, type: string): boolean {
    return typeof value === type;
  }
}
