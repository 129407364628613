import { Component } from '@angular/core';
/**
 * Queted Text
 * Old Testament quotations in the New Testament, or other quotations.
 * https://ubsicap.github.io/usx/master/charstyles.html#qt
 *
 * @export
 * @class QtComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'bible-qt',
  templateUrl: './qt.component.html',
  styleUrls: ['./qt.component.scss'],
})
export class QtComponent {}
