import { Component, Input } from '@angular/core';
import { NDCustomStyling } from '@ibep/interfaces';

@Component({
  selector: 'bible-nd',
  templateUrl: './nd.component.html',
  styleUrls: ['./nd.component.scss'],
})
export class NdComponent {
  @Input() ndCustomStyling?: NDCustomStyling;
}
