import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  Inject,
  Injectable,
  PLATFORM_ID,
  Renderer2,
  RendererFactory2,
  TemplateRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalOptions } from '@ibep/interfaces';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private isOpenSub = new BehaviorSubject<boolean>(false);

  public isOpen$ = this.isOpenSub.asObservable();

  private currentModalSub = new ReplaySubject<{
    options: Partial<ModalOptions>;
    template: TemplateRef<any> | undefined;
  }>(1);

  public currentModal$ = this.currentModalSub
    .asObservable()
    .pipe(filter((val) => !!val));

  private clickedOutsideSub = new Subject<void>();

  public clickedOutside$ = this.clickedOutsideSub.asObservable();

  private _renderer: Renderer2;

  constructor(
    private readonly _rendererFactory: RendererFactory2,
    private readonly _router: Router,
    private readonly _activatedRoute: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(DOCUMENT)
    public document: Document
  ) {
    this._renderer = _rendererFactory.createRenderer(null, null);
  }

  public createModal(
    template: TemplateRef<any>,
    options: Partial<ModalOptions> = {}
  ) {
    this.currentModalSub.next({ template, options });
    this.isOpenSub.next(true);
    // prevent viewport scroll
    if (isPlatformBrowser(this.platformId)) {
      this._renderer.addClass(this.document.body, 'overflow-hidden');
    }
  }

  public closeModal() {
    this.currentModalSub.next({ template: undefined, options: {} });
    this.isOpenSub.next(false);
    this._renderer.removeClass(this.document.body, 'overflow-hidden');

    const { snapshot } = this._activatedRoute;
    const params = { ...snapshot.queryParams };
    delete params.view;
    this._router.navigate([], { queryParams: params });

    // this._router.navigate([this._router.url], {
    //   queryParams: {},
    // });
  }

  public clickedOutside() {
    this.clickedOutsideSub.next();
  }
}
