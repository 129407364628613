import { DOCUMENT } from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'ibep-login',
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnChanges {
  public errors: { code: string; message: string } | undefined;

  @Output() formSubmit = new EventEmitter<{
    username: string;
    password: string;
  }>();

  @Input() loginProps: { error: object; formDisabled: boolean };

  public form: UntypedFormGroup = new UntypedFormGroup({
    username: new UntypedFormControl(undefined, [
      Validators.email,
      Validators.required,
    ]),
    password: new UntypedFormControl(undefined, [Validators.required]),
  });

  constructor(
    @Inject(DOCUMENT)
    public document: Document
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    // check if we need to disable or enable the form
    if (
      !changes.loginProps?.isFirstChange() &&
      changes.loginProps?.currentValue.formDisabled !==
        changes.loginProps?.previousValue.formDisabled
    ) {
      this.form[
        changes.loginProps?.currentValue.formDisabled ? 'disable' : 'enable'
      ]();
    }

    // check for errors
    if (
      !changes.loginProps?.isFirstChange() &&
      changes.loginProps?.currentValue.error !==
        changes.loginProps?.previousValue.error
    ) {
      this.errors = changes.loginProps?.currentValue.error;
    }
  }

  get username() {
    return this.form.get('username');
  }

  get password() {
    return this.form.get('password');
  }

  public onSubmit() {
    if (this.form.invalid) {
      return;
    }

    this.errors = undefined;
    this.form.disable();

    const { username, password } = this.form.value;
    this.formSubmit.emit({ username, password });
  }
}
