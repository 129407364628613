<!-- TODO: we need to add transparencies to "bg-grayExtraLight"  -->
<div
  class="py-1 px-4 w-full bg-[#C0D0E4] bg-opacity-20 lg:flex justify-between z-10 hidden"
  [ngClass]="{
    'border-t border-b border-grayExtraLight h-8':
      readerState.selectedBibles.bibleAbbrs.length > 1
  }"
>
  <div class="flex items-center overflow-hidden">
    <!-- Move column to the left  -->
    <div
      (click)="moveLeft()"
      *ngIf="readerState.selectedBibles.bibleAbbrs[0] !== abbr"
      class="pr-2 cursor-pointer"
    >
      <ibep-move-left-svg></ibep-move-left-svg>
    </div>

    <span class="text-grayMedium text-2xs truncate" [title]="name">{{
      name
    }}</span>
  </div>
  <div class="flex">
    <!-- Close  -->
    <div
      (click)="close()"
      *ngIf="readerState.selectedBibles.bibleAbbrs.length > 1"
      class="px-1 text-[#C0D0E4] cursor-pointer"
    >
      <ibep-close-svg svgStyles="w-6 h-6"></ibep-close-svg>
    </div>
    <!-- Move column to right  -->
    <div
      (click)="moveRight()"
      *ngIf="
        readerState.selectedBibles.bibleAbbrs[
          readerState.selectedBibles.bibleAbbrs.length - 1
        ] !== abbr
      "
      class="px-1 cursor-pointer"
    >
      <ibep-move-right-svg></ibep-move-right-svg>
    </div>
  </div>
</div>
<!-- Show Bible tabs on mobile  -->
<div
  class="flex lg:hidden mb-5 items-center h-10 bg-[#C0D0E4] bg-opacity-20 border-t-2 sticky top-12"
>
  <div
    *ngFor="let bibleAbbr of readerState.selectedBibles.bibleAbbrs"
    class="w-full h-full max-w-[180px] px-5 py-2.5 text-2xs flex justify-between items-center cursor-pointer hover:text-primary"
    (click)="setActiveColumn(bibleAbbr)"
    [ngClass]="{
      'bg-white border-t-2 border-grayMedium h-[42px]':
        activeColumn === bibleAbbr
    }"
  >
    <span>{{ bibleAbbr }}</span>
    <div
      (click)="close(bibleAbbr)"
      *ngIf="readerState.selectedBibles.bibleAbbrs.length > 1"
      class="text-[#C0D0E4] cursor-pointer"
    >
      <ibep-close-svg svgStyles="w-6 h-6"></ibep-close-svg>
    </div>
  </div>
</div>
