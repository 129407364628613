import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
  Renderer2,
  RendererFactory2,
  ViewEncapsulation,
} from '@angular/core';
import { Scrolled } from '@ibep/fe/shared/bible';
import { ReaderStateInterface, UserNote } from '@ibep/fe/shared/data';
import { Bible, Column, ColumnsLimit, Config } from '@ibep/interfaces';
import {
  CopyOption,
  CopyOptionsEnum,
} from '../copy-options-picker/copy-options-picker.component';

@Component({
  selector: 'ibep-bible-reader',
  templateUrl: './bible-reader.component.html',
  // TODO: remove from here, styling :after should be possible with tailwind, also gradient
  styles: [
    `
      .toolbar-shadow {
        background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, white 53%);
      }

      .bible-copyright a {
        text-decoration: underline;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class BibleReaderComponent {
  public columnsLimitEnum = ColumnsLimit;

  @Input() columns: Column[];
  @Input() readerState: ReaderStateInterface;
  @Input() bibles: Bible[];
  @Input() categories: any[];
  @Input() brandId: string;
  @Input() isAuthenticated: boolean;
  @Input() isPremium: boolean;
  @Input() isMobile: boolean;
  @Input() isBrowser: boolean;
  @Input() isLoading: boolean;
  @Input() isLoadingEbc: boolean;
  @Input() userAccountSystem: boolean;
  @Input() allowParallelReadingFor: 'all' | 'registered' | 'premium';
  @Input() allowBibleCopyingFor: 'all' | 'registered' | 'premium';
  @Input() useEBC: boolean;
  @Input() config: Config;
  @Input() createdNoteVerseOrgIds: string[];
  @Input() copyOptions: CopyOption[];

  @Input() activeScrollColumn: string;
  @Input() activeColumn: string;
  @Input() activeBiblePickerTab: string;
  @Input() scrollStateValue: any;

  @Input() scrolled: Scrolled;
  @Input() hovered: string[];
  @Input() selected: {
    verseIds: string[];
    verseOrgIds: string[];
    activeColumn: string;
  };
  @Input() flashed: string[];

  @Output() modalClosed = new EventEmitter<boolean>();
  @Output() modalOpened = new EventEmitter<string>();
  @Output() verseCopied = new EventEmitter<CopyOptionsEnum[]>();
  @Output() verseShared = new EventEmitter<string>();
  @Output() redirected = new EventEmitter<any>();
  @Output() swiped = new EventEmitter<any>();
  @Output() toolbarClosed = new EventEmitter<boolean>();
  @Output() activeColumnSet = new EventEmitter<string>();
  @Output() readerStateSet = new EventEmitter<any>();
  @Output() webfontLoad = new EventEmitter<any>();
  @Output() scrolledTo = new EventEmitter<any>();
  @Output() activeScrollColumnSet = new EventEmitter<string>();
  @Output() scrollStateChanged = new EventEmitter<any>();
  @Output() pushGtmTag = new EventEmitter<object>();
  @Output() userHighlightAdded = new EventEmitter<string>();
  @Output() userHighlightDeleted = new EventEmitter<boolean>();
  @Output() userNoteAdded = new EventEmitter<boolean>();
  @Output() userNoteUpdated = new EventEmitter<UserNote>();
  @Output() userNoteDeleted = new EventEmitter<string>();
  @Output() updateAudioState = new EventEmitter<object>();
  @Output() goToNextChapter = new EventEmitter<boolean>();
  @Output() audioPlaybackRateUpdated = new EventEmitter<object>();

  public ebcModalPosition: '15%' | '50%' | '100%' = '15%';
  public noteToolbarIsOpen = false;
  private _ebcModalPositionOptions = ['15%', '50%', '100%'];
  private _renderer: Renderer2;

  constructor(
    private readonly _rendererFactory: RendererFactory2,
    @Inject(DOCUMENT)
    public document: Document
  ) {
    this._renderer = _rendererFactory.createRenderer(null, null);
  }

  public closeToolbar(): void {
    this.toolbarClosed.emit(true);
  }

  public copyVerse(event: CopyOptionsEnum[]): void {
    this.verseCopied.emit(event);
  }

  public shareVerse(arg: string): void {
    this.verseShared.emit(arg);
  }

  public openModal(arg: string): void {
    this.modalOpened.emit(arg);
    let gtmEvent = '';

    switch (arg) {
      case 'biblePicker':
        gtmEvent = 'chapter_picker';
        break;
      case 'translationPicker':
        gtmEvent = 'translation_picker';
        break;
      default:
        break;
    }

    if (gtmEvent) {
      this.onGtmTagPush({
        event: gtmEvent,
        bible_book: this.readerState.selectedChapter?.split('.')?.[0],
        bible_chapter: this.readerState?.selectedChapter,
        bible_translation: this.readerState.selectedBibles?.bibleAbbrs,
      });
    }
  }

  public closeModal(): void {
    this.modalClosed.emit(true);
  }

  public onSwipe(arg: string): void {
    this.swiped.emit(arg);
  }

  public setActiveColumn(args: any): void {
    this.activeColumnSet.emit(args);
  }

  public redirect(args: any): void {
    this.redirected.emit(args);
  }

  public loadWebfont(webfont: any): void {
    this.webfontLoad.emit(webfont);
  }

  public setReaderState(args: any): void {
    this.readerStateSet.emit(args);
  }

  public scrollTo(args: any): void {
    this.scrolledTo.emit(args);
  }

  public setAudioState(args: any): void {
    this.updateAudioState.emit(args);
  }

  public setActiveScrollColumn(args: string): void {
    this.activeScrollColumnSet.emit(args);
  }

  public scrollStateChange(args: any): void {
    this.scrollStateChanged.emit(args);
  }

  public onGtmTagPush(tag: object): void {
    this.pushGtmTag.emit(tag);
  }

  public addUserHighlight(color: string): void {
    this.userHighlightAdded.emit(color);
  }

  public deleteUserHighlight(): void {
    this.userHighlightDeleted.emit(true);
  }

  public addUserNote(): void {
    this.userNoteAdded.emit(true);
  }

  public updateUserNote(noteId: UserNote): void {
    this.userNoteUpdated.emit(noteId);
  }

  public deleteUserNote(note: string): void {
    this.userNoteDeleted.emit(note);
  }

  public requestNextChapter(): void {
    this.goToNextChapter.emit(true);
  }

  public toggleEbc(): void {
    const showEbc = !this.readerState.ebcState.showEbc;
    this.readerStateSet.next({ ebcState: { showEbc } });

    this.onGtmTagPush({
      event: showEbc ? 'enable_additional_info' : 'disable_additional_info',
      bible_book: this.readerState.selectedChapter?.split('.')?.[0],
      bible_chapter: this.readerState?.selectedChapter,
      bible_translation: this.readerState.selectedBibles?.bibleAbbrs,
      studybible_content_type: 'EBC',
    });
  }

  public openEbcItem(): void {
    this.modalOpened.next('ebc');
  }

  public changeEbcModalPosition(): void {
    const index = this._ebcModalPositionOptions.indexOf(this.ebcModalPosition);
    if (index === 2) {
      this.ebcModalPosition = this._ebcModalPositionOptions[0] as
        | '15%'
        | '50%'
        | '100%';
    } else {
      this.ebcModalPosition = this._ebcModalPositionOptions[index + 1] as
        | '15%'
        | '50%'
        | '100%';
    }
    // prevent body scroll when modal is open
    if (index === 1) {
      this._renderer.addClass(document.body, 'overflow-hidden');
    } else {
      this._renderer.removeClass(document.body, 'overflow-hidden');
    }
  }

  /**
   * Bible column trackBy function
   *
   * @param {number} index
   * @param {Column} column
   * @returns
   * @memberof BibleReaderContainerComponent
   */
  public trackByBible(index: number, column: Column): string | undefined {
    if (!column.bible) return;
    const {
      bible: { id },
    } = column;
    return id;
  }
}
