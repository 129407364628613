import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserInfo } from '../interfaces/user-data.interface';

@Injectable()
export abstract class AbstractAuthService {
  protected _isAuthenticated = new BehaviorSubject<boolean>(undefined);
  protected _isPremium$ = new BehaviorSubject<boolean>(false);

  public _isPremium: boolean;
  public isAuthenticated$ = this._isAuthenticated.asObservable();
  public isPremium$ = this._isPremium$.asObservable();
  public isRefreshing$ = new BehaviorSubject<boolean>(false);

  public init() {
    throw new Error('Method not implemented');
  }

  public logIn(username: string, password: string): Observable<UserInfo> {
    throw new Error('Method not implemented');
  }

  public logOut(redirect?: boolean) {
    throw new Error('Method not implemented');
  }

  public refreshToken(): Observable<UserInfo> {
    throw new Error('Method not implemented');
  }

  public getTokenDetails(): Observable<{
    idToken: string;
    refreshToken: string;
  }> {
    throw new Error('Method not implemented');
  }

  public register(body: any, captchaToken: string): Observable<any> {
    throw new Error('Method not implemented');
  }

  public verifyEmail(oobCode): Observable<void> {
    throw new Error('Method not implemented');
  }

  public resetPassword(body: any, captchaToken: string): Observable<void> {
    throw new Error('Method not implemented');
  }

  public changePasswordByCode(newPassword, oobCode): Observable<void> {
    throw new Error('Method not implemented');
  }

  public getUserEntitlements(): Observable<any[]> {
    throw new Error('Method not implemented');
  }

  public getUserProfile(): Observable<UserInfo> {
    throw new Error('Method not implemented');
  }
}
