import { Component } from '@angular/core';

@Component({
  selector: 'bible-cl',
  template: `
    <h3 class="cl">
      <ng-content></ng-content>
    </h3>
  `,
  styleUrls: ['./cl.component.scss'],
})
/**
 * https://ubsicap.github.io/usx/parastyles.html#cl
 */
export class ClComponent {}
