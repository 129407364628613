import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, filter, fromEvent } from 'rxjs';

@Component({
  selector: 'ibep-content-page',
  templateUrl: './content-page.component.html',
  styles: [
    `
      :host {
        scroll-behavior: smooth;
      }

      /* Hide scrollbar for Chrome, Safari and Opera */
      .sidebar::-webkit-scrollbar {
        display: none;
      }

      /* Hide scrollbar for IE, Edge and Firefox */
      .sidebar {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
      }
    `,
  ],
})
export class ContentPageComponent implements AfterViewInit {
  public page: any;
  public showSidebar: boolean;
  public showPageTree: boolean;
  public ebcImageIsLarge = false;
  public isMobileAppView: boolean;

  @Input() moreSiblingArticlesAvailable: boolean;
  @Input() parentRoutes: any;
  @Input() noAccess: boolean;
  @Input() isBrowser: boolean;
  @Input() dateFormat: string;
  @Input() siblingArticles: any[];
  @Input() authors: any;
  @Input() formatEbcLinks = false;
  @Input() relatedPassages: any;

  @Input() set contentPage(value: any) {
    if (!value.siblingAndChildPages) {
      value.siblingAndChildPages = [
        {
          id: value.id,
          slug: value.slug,
          title: value.title,
        },
      ];
    }

    this.page = value;
    this.showPageTree = this.checkPageTreeVisibility(value);
    // check if we need to show a sidebar
    if (
      (value.type === 'post' && value.sideContentBlock?.title) ||
      (value.type === 'page' && value.sideContentBlock?.title) ||
      value.type === 'ebc' ||
      this.showPageTree
    ) {
      this.showSidebar = true;
    } else {
      this.showSidebar = false;
    }
  }

  @Output() getSiblingArticles = new EventEmitter<{
    search: string;
    page?: number;
  }>();

  @ViewChild('siblingInput') siblingInput: ElementRef;

  constructor(private readonly _router: Router) {
    this.isMobileAppView = this._router.url.includes('viewMode=mobileApp');
  }

  /**
   * For sidebar keyword box (ebc content) we need to do some search/filtering
   */
  ngAfterViewInit(): void {
    // Listen for EBC sibling queries
    if (this.siblingInput) {
      fromEvent(this.siblingInput?.nativeElement, 'keyup')
        .pipe(filter(Boolean), debounceTime(400), distinctUntilChanged())
        .subscribe((res) => {
          this.getSiblingArticles.emit({
            search: this.siblingInput.nativeElement.value,
            page: 1,
          });
        });
    }
  }

  public loadMore(): void {
    this.getSiblingArticles.emit({
      search: this.siblingInput.nativeElement.value,
    });
  }

  public checkPageTreeVisibility(page: any): boolean {
    return (
      (page.type === 'page' || page.type === 'ebc') &&
      (page.parentPages?.[page.parentPages?.length - 1] ||
        page.siblingAndChildPages?.length > 1 ||
        page.siblingAndChildPages?.some((item: any) => item?.children?.length))
    );
  }
}
