import { Component } from '@angular/core';

/**
 * https://ubsicap.github.io/usx/parastyles.html#sd
 *
 * @export
 * @class SdComponent
 */
@Component({
  selector: 'bible-sd',
  template: `
    <p class="sd">
      <ng-content></ng-content>
    </p>
  `,
  styleUrls: ['./sd.component.scss'],
})
export class SdComponent {}
