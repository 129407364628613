import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BibleContentItem } from '@ibep/interfaces';

@Component({
  selector: 'ibep-bible-content-picker',
  templateUrl: './bible-content-picker.component.html',
})
export class BibleContentPickerComponent {
  @Input() title: string;
  @Input() items: BibleContentItem[];

  @Output() valueChanged = new EventEmitter<string>();

  public toggleTag(clickedItem: BibleContentItem): void {
    if (!clickedItem.checked) {
      this.items.forEach((item) => {
        item.checked = item.id === clickedItem.id;
      });
      this.valueChanged.emit(clickedItem.id);
    }
  }
}
