import { Injectable } from '@angular/core';
import {
  AbstractBackendService,
  AbstractEnvironmentService,
  AbstractLanguageService,
  AbstractStorageService,
} from '@ibep/interfaces';
import { shareReplay } from 'rxjs/operators';
import { ConfigData } from '..';
import { Store, StoreSettings } from './store';

@Injectable({
  providedIn: 'root',
})
export class PremiumVoucherData extends Store<any> {
  constructor(
    storage: AbstractStorageService,
    environment: AbstractEnvironmentService,
    backendService: AbstractBackendService,
    languageService: AbstractLanguageService,
    config: ConfigData
  ) {
    super(storage, environment, backendService, languageService, {
      storeKey: 'voucher',
      brand$: config.getBrand(),
      doNotStoreLocally: true,
    } as StoreSettings);
  }

  public add(code: string) {
    const postVoucher$ = this.backendService
      .post<any>({
        baseUrl: `https://${this.brand.id}${this.environment.authBaseUrl}`,
        endpoint: 'voucher',
        options: {
          body: { code },
          headers: {
            'x-brand': this.brand.id,
          },
        },
      })
      .pipe(shareReplay(1));

    return postVoucher$;
  }
}
