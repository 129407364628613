import { Component } from '@angular/core';
/**
 * @export
 * @class JmpComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'bible-jmp',
  templateUrl: './jmp.component.html',
  styleUrls: ['./jmp.component.scss'],
})
export class JmpComponent {}
