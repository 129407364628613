import { Component, Input, OnInit } from '@angular/core';
import { BibleService } from '@ibep/fe/shared/bible';
import { LanguageService } from '@ibep/fe/shared/core';
import {
  BibleMetaData,
  ConfigData,
  DailyVerseData,
} from '@ibep/fe/shared/data';
import { Config, DailyVerse, GetBibleMetadataResponse } from '@ibep/interfaces';
import { combineLatest, of, switchMap } from 'rxjs';

@Component({
  selector: 'ibep-vod-container',
  templateUrl: './verse-of-the-day-container.component.html',
})
export class VerseOfTheDayContainerComponent implements OnInit {
  public vod;
  @Input() headline: string;

  constructor(
    private readonly _dailyVerseData: DailyVerseData,
    private readonly _language: LanguageService,
    private readonly _configData: ConfigData,
    private readonly _bibleMetaData: BibleMetaData,
    private readonly _bibleService: BibleService
  ) {}

  ngOnInit(): void {
    // get the daily verse
    this._language.currentLanguage$
      .pipe(
        // with the lang we can fetch the correct dailyVerse
        switchMap((language: string) =>
          this._dailyVerseData.getDailyVerse({ language })
        ),
        switchMap((dailyVerse: DailyVerse) => {
          // we also need the brandEnt to match the Abbreviation with dailyVerse
          const config = this._configData.getConfig();
          const bibleMetadata = dailyVerse?.bibleId
            ? this._bibleMetaData.getBible({
                bibleId: dailyVerse?.bibleId,
              })
            : of(null);
          return combineLatest([config, bibleMetadata, of(dailyVerse)]);
        })
      )
      .subscribe(
        ([config, bibleMetadata, dailyVerse]: [
          Config,
          GetBibleMetadataResponse,
          DailyVerse
        ]) => {
          if (dailyVerse) {
            this.vod = {
              bible_translation: this._bibleService.getBibleAbbrFromId(
                dailyVerse.bibleId
              ),
              from_book: dailyVerse.bookId,
              from_chapter: dailyVerse.chapterId.split('.')[1],
              id: dailyVerse.id,
              rpSlug: dailyVerse.readingPlan,
              episodeSlug: dailyVerse.episodeSlug,
            };
          }
        }
      );
  }
}
