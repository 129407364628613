import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@ibep/fe/shared/core';
import {
  AbstractEnvironmentService,
  AbstractStorageService,
  PlatformType,
} from '@ibep/interfaces';
import {
  clear,
  del,
  delMany,
  get,
  getMany,
  keys,
  set,
  setMany,
  update,
} from 'idb-keyval';
import { from, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

export interface storageItem<T> {
  value: T;
  expiry: number;
}
/**
 * This service can be used to persist data to the browsers indexedDB storage.
 * idb-keyval is used (https://github.com/jakearchibald/idb-keyval)
 *
 * @export
 * @class IdbStorageService
 * @implements {AbstractStorageService}
 */
@Injectable()
export class IdbStorageService implements AbstractStorageService {
  private _platform: PlatformType;
  private _storeEnabled: boolean;

  /**
   * Creates an instance of IdbStorageService.
   * @param {AbstractEnvironmentService} environmentService
   * @memberof IdbStorageService
   */
  constructor(
    @Inject(WINDOW) private _window: Window,
    private readonly environmentService: AbstractEnvironmentService
  ) {
    this._platform = this.environmentService.platform;
    // we need it to prevent error in Firefox private window
    // see https://bugzilla.mozilla.org/show_bug.cgi?id=781982
    setTimeout(() => {
      const db = this._window.indexedDB?.open('');

      if (db) {
        db.onerror = () => {
          this._storeEnabled = false;
        };
        db.onsuccess = () => {
          this._storeEnabled = true;
        };
      }
    }, 0);
  }

  /**
   * Get one item from the storage
   *
   * @template T
   * @param {string} key
   * @returns {(Observable<T | undefined>)}
   * @memberof IdbStorageService
   */
  public get<T>(key: string): Observable<T | undefined> {
    if (this._platform === 'browser' && this._storeEnabled) {
      const now = new Date().getTime();
      return from(get<storageItem<T>>(key)).pipe(
        switchMap((item) => {
          if (item) {
            if (now < item.expiry) {
              return of(item.value);
            }
            return this.delete(key).pipe(switchMap(() => of(undefined)));
          }
          return of(undefined);
        })
      );
    }
    return of(undefined);
  }

  /**
   * Set one item in the storage
   *
   * @param {string} key
   * @param {*} value
   * @returns {Observable<void>}
   * @memberof IdbStorageService
   */
  public set(key: string, value: any, ttl: number): Observable<void> {
    if (this._platform === 'browser' && this._storeEnabled) {
      const item = { value: value, expiry: new Date().getTime() + ttl };
      return from(set(key, item));
    }
    return of(undefined);
  }

  /**
   * Update one item in the storage
   *
   * @template T
   * @param {string} key
   * @param {*} value
   * @returns {Observable<void>}
   * @memberof IdbStorageService
   */
  public update<T>(key: string, value: any): Observable<void> {
    return from(update<T>(key, value));
  }

  /**
   * Get multiple items from the storage
   *
   * @template T
   * @param {string[]} keys
   * @returns {Observable<T[]>}
   * @memberof IdbStorageService
   */
  public getMany<T>(keys: string[]): Observable<T[]> {
    return from(getMany<T>(keys));
  }

  /**
   * Set multiple items in the storage
   *
   * @param {[string, any][]} entries
   * @returns {Observable<void>}
   * @memberof IdbStorageService
   */
  public setMany(entries: [string, any][]): Observable<void> {
    return from(setMany(entries));
  }

  /**
   * Delete one item from the storage
   *
   * @param {string} key
   * @returns {Observable<void>}
   * @memberof IdbStorageService
   */
  public delete(key: string): Observable<void> {
    return from(del(key));
  }

  /**
   * Clear the whole storage
   *
   * @returns {Observable<void>}
   * @memberof IdbStorageService
   */
  public clear(): Observable<void> {
    return from(clear());
  }

  /**
   * Delete cache keys by prefix
   *
   * @param {string} prefix
   * @returns {Observable<void>}
   * @memberof IdbStorageService
   */
  public deleteKeysByPrefix(prefix: string): Observable<void> {
    if (this._platform === 'browser' && this._storeEnabled) {
      return this.getAllKeys().pipe(
        map((keys) => keys.filter((key: string) => key.indexOf(prefix) === 0)),
        switchMap((keys) => {
          if (keys.length) {
            return from(delMany(keys));
          }
          return of(undefined);
        })
      );
    }
    return of(undefined);
  }

  /**
   * Get a list of all keys currently present in the storage
   *
   * @returns {Observable<string[]>}
   * @memberof IdbStorageService
   */
  public getAllKeys(): Observable<string[]> {
    return from(keys().then((keys) => keys.map((key) => String(key))));
  }

  /**
   * Function to check the available browser storage that is available
   *
   * @private
   * @memberof StorageService
   */
  // private async checkAvailableStorage() {
  //   if (navigator.storage && navigator.storage.estimate) {
  //     const quota = await navigator.storage.estimate();
  //     // quota.usage -> Number of bytes used.
  //     // quota.quota -> Maximum number of bytes available.
  //     if (quota.quota && quota.usage) {
  //       const percentageUsed = (quota.usage / quota.quota) * 100;
  //       console.info(
  //         `You've used ${percentageUsed}% of the available storage.`
  //       );
  //       const remaining = quota.quota - quota.usage;
  //       console.info(`You can write up to ${remaining} more bytes.`);
  //     }
  //   }
  // }
}
