import { Routes } from '@angular/router';
import { BrandGuard } from '@ibep/fe/web/module-auth';

// TODO: add seo metadata for each route
export const APP_ROUTES: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () =>
      import('@ibep/fe/web/module-dashboard').then(
        (module) => module.FeWebModuleDashboardModule
      ),
  },
  // redirects for mobile app
  {
    path: 'mobileRedirectBible',
    redirectTo: 'ROUTES.bible',
  },
  {
    path: 'mobileRedirectPosts',
    redirectTo: 'ROUTES.posts',
  },
  {
    path: 'mobileRedirectEbc',
    redirectTo: 'ROUTES.ebc',
  },
  {
    path: 'mobileRedirectReadingPlans',
    redirectTo: 'ROUTES.readingplans',
  },
  {
    path: 'Bijbel',
    canActivate: [BrandGuard],
    loadChildren: () =>
      import('@ibep/fe/web/module-bible').then(
        (module) => module.FeWebModuleBibleModule
      ),
    data: {
      guard: 'module-bible-nbg',
    },
  },
  {
    path: 'Bybel',
    canActivate: [BrandGuard],
    loadChildren: () =>
      import('@ibep/fe/web/module-bible').then(
        (module) => module.FeWebModuleBibleModule
      ),
    data: {
      guard: 'module-bible-bssa-af',
    },
  },
  {
    path: 'IBhayibhile',
    canActivate: [BrandGuard],
    loadChildren: () =>
      import('@ibep/fe/web/module-bible').then(
        (module) => module.FeWebModuleBibleModule
      ),
    data: {
      guard: 'module-bible-bssa-xh',
    },
  },
  {
    path: 'ROUTES.bible',
    canActivate: [BrandGuard],
    loadChildren: () =>
      import('@ibep/fe/web/module-bible').then(
        (module) => module.FeWebModuleBibleModule
      ),
    data: {
      guard: 'module-bible',
    },
  },
  {
    path: 'ROUTES.readingplans',
    canActivate: [BrandGuard],
    loadChildren: () =>
      import('@ibep/fe/web/module-plans').then(
        (module) => module.FeWebModulePlansModule
      ),
    data: {
      guard: 'module-plans',
    },
  },
  {
    path: 'ROUTES.posts',
    canActivate: [BrandGuard],
    loadChildren: () =>
      import('@ibep/fe/web/module-posts').then(
        (module) => module.ModulePostsModule
      ),
    data: {
      guard: 'module-posts',
    },
  },
  {
    path: 'kekes',
    canActivate: [BrandGuard],
    redirectTo: 'ROUTES.post',
    data: {
      guard: 'module-posts',
    },
  },
  {
    path: 'ROUTES.post',
    canActivate: [BrandGuard],
    loadChildren: () =>
      import('@ibep/fe/web/module-posts').then(
        (module) => module.ModulePostsModule
      ),
    data: {
      guard: 'module-posts',
    },
  },
  {
    path: 'ROUTES.search',
    loadChildren: () =>
      import('@ibep/fe/web/module-search').then(
        (module) => module.FeWebModuleSearchModule
      ),
    data: {
      guard: 'module-search',
    },
  },
  {
    path: 'ROUTES.ebc',
    canActivate: [BrandGuard],
    loadChildren: () =>
      import('@ibep/fe/web/module-ebc').then(
        (module) => module.ModuleOspModule
      ),
    data: {
      guard: 'module-ebc',
    },
  },
  {
    path: 'ROUTES.liturgical-texts',
    canActivate: [BrandGuard],
    loadChildren: () =>
      import('@ibep/fe/web/module-liturgical-texts').then(
        (module) => module.ModuleLiturgicalTextsModule
      ),
    data: {
      guard: 'module-liturgical_texts',
    },
  },
  {
    path: 'ROUTES.notes-highlights',
    // canActivate: [AuthGuard],
    loadChildren: () =>
      import('@ibep/fe/web/module-notes-highlights').then(
        (module) => module.FeWebModuleNotesHighlightsModule
      ),
    data: {
      guard: 'module-notes-highlights',
    },
  },
  {
    path: 'ROUTES.keyword',
    canActivate: [BrandGuard],
    loadChildren: () =>
      import('@ibep/fe/web/module-ebc').then(
        (module) => module.ModuleOspModule
      ),
    data: {
      guard: 'module-ebc',
    },
  },
  {
    path: 'ROUTES.login',
    canActivate: [BrandGuard],
    redirectTo: '/?view=signIn',
    data: {
      guard: 'module-auth_sign-in',
    },
  },
  {
    path: 'ROUTES.register',
    canActivate: [BrandGuard],
    redirectTo: '/?view=signUp',
    data: {
      guard: 'module-auth_register',
    },
  },
  {
    path: 'ROUTES.forgot-password',
    canActivate: [BrandGuard],
    redirectTo: '/?view=forgotPassword',
  },

  {
    path: 'ROUTES.account',
    loadChildren: () =>
      import('@ibep/fe/web/module-account').then(
        (module) => module.ModuleAccountModule
      ),
  },
  {
    path: '404',
    canActivate: [BrandGuard],
    loadChildren: () =>
      import('@ibep/fe/web/module-not-found').then(
        (module) => module.FeWebModuleNotFoundModule
      ),
    data: {
      guard: 'module-wildcard',
    },
  },
  {
    path: 'preview',
    loadChildren: () =>
      import('@ibep/fe/web/module-preview').then(
        (module) => module.FeWebModulePreviewModule
      ),
  },
  {
    path: 'confirmAccount',
    loadChildren: () =>
      import('@ibep/fe/web/module-verification').then(
        (module) => module.FeWebModuleVerificationModule
      ),
  },
  // we need these two redirects to interpret correctly links
  // coming from API email confirmation with the default language locale prefix, for example
  // https://www.sbb.org.br/pt/confirmAccount --> https://www.sbb.org.br/confirmAccount
  {
    path: ':lang/confirmAccount',
    redirectTo: 'confirmAccount',
  },
  {
    path: ':lang/verifyEmail',
    redirectTo: 'verifyEmail',
  },
  {
    path: 'confirmAccount',
    loadChildren: () =>
      import('@ibep/fe/web/module-verification').then(
        (module) => module.FeWebModuleVerificationModule
      ),
  },
  {
    path: 'verifyEmail',
    loadChildren: () =>
      import('@ibep/fe/web/module-verification').then(
        (module) => module.FeWebModuleVerificationModule
      ),
  },
  {
    path: 'resetPassword',
    loadChildren: () =>
      import('@ibep/fe/web/module-verification').then(
        (module) => module.FeWebModuleVerificationModule
      ),
  },
  {
    path: 'healthcheck',
    loadChildren: () =>
      import('@ibep/fe/web/module-healthcheck').then(
        (module) => module.FeWebModuleHealthcheckModule
      ),
  },
  {
    path: '**',
    loadChildren: () =>
      import('@ibep/fe/web/module-wildcard').then(
        (module) => module.ModuleWildcardModule
      ),
    data: {
      guard: 'module-wildcard',
    },
  },
];
