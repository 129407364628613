import { DOCUMENT } from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  AccountTab,
  AvailableLanguage,
  ChangePasswordData,
  UserInfo,
} from '@ibep/interfaces';
import { Tab } from '../../molecules/tabs/tabs.component';

@Component({
  selector: 'ibep-account-settings',
  templateUrl: './account-settings.component.html',
})
export class AccountSettingsComponent implements OnInit {
  public passwordRegExp: RegExp;
  public accountTabEnum = AccountTab;

  public isChangePasswordAllowed: boolean;

  public baseForm: UntypedFormGroup;
  public passwordForm: UntypedFormGroup;
  public voucherForm: UntypedFormGroup;
  public deleteAccountForm: UntypedFormGroup;
  public userInfoData: UserInfo;
  public locale;

  @Input() set userInfo(value: UserInfo) {
    this.userInfoData = value;
    setTimeout(() => {
      if (value && this.baseForm) {
        this.baseForm.patchValue(value);
      }
    }, 0);
  }

  @Input() availableLanguages: AvailableLanguage[];

  @Input() activeTab: Tab;
  @Input() accountTabs: Tab[] = [];
  @Input() updateUserServerError?: string;
  @Input() passwordServerError?: string;
  @Input() showPasswordChangeSuccessMessage?: boolean;
  @Input() deleteAccountServerError?: string;
  @Input() addVoucherServerError?: string;
  @Input() isPremium: boolean;
  @Input() addVoucherSuccess: boolean;
  @Input() dateFormat: string;

  @Output() activeTabChanged = new EventEmitter<Tab>();
  @Output() logOut = new EventEmitter<void>();
  @Output() updateUserInfo = new EventEmitter<UserInfo>();
  @Output() changePassword = new EventEmitter<ChangePasswordData>();
  @Output() deleteUser = new EventEmitter<string>();
  @Output() voucherSend = new EventEmitter<string>();

  constructor(
    @Inject(DOCUMENT)
    public document: Document
  ) {}

  ngOnInit(): void {
    this.baseForm = new UntypedFormGroup({
      email: new UntypedFormControl({ value: undefined, disabled: true }, [
        Validators.required,
      ]),
      firstName: new UntypedFormControl(undefined, [
        Validators.required,
        Validators.pattern(/[\S]/),
      ]),
      lastName: new UntypedFormControl(undefined, [
        Validators.required,
        Validators.pattern(/[\S]/),
      ]),
      locale: new UntypedFormControl(undefined, [Validators.required]),
    });

    this.passwordForm = new UntypedFormGroup({
      oldPassword: new UntypedFormControl(undefined, [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(/[\d]/),
        Validators.pattern(/[a-z]/),
        Validators.pattern(/[A-Z]/),
      ]),
      newPassword: new UntypedFormControl(undefined, [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(/[\d]/),
        Validators.pattern(/[a-z]/),
        Validators.pattern(/[A-Z]/),
      ]),
      newPasswordConfirm: new UntypedFormControl(undefined, [
        Validators.required,
      ]),
    });

    this.voucherForm = new UntypedFormGroup({
      voucherCode: new UntypedFormControl(undefined, [Validators.required]),
    });

    this.deleteAccountForm = new UntypedFormGroup({
      currentPassword: new UntypedFormControl(undefined, [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(/[\d]/),
        Validators.pattern(/[a-z]/),
        Validators.pattern(/[A-Z]/),
      ]),
    });

    this.validateChangePassword();
  }

  onTabChanged(tab: Tab): void {
    this.activeTabChanged.emit(tab);
  }

  onLogOut(): void {
    this.logOut.emit();
  }

  savePersonalData(): void {
    if (
      this.baseForm.controls.firstName.status === 'VALID' &&
      this.baseForm.controls.lastName.status === 'VALID' &&
      this.baseForm.dirty
    ) {
      this.updateUserInfo.emit({ ...this.baseForm.value, locale: this.locale });
    }
  }

  onChangePassword(): void {
    if (this.isChangePasswordAllowed) {
      this.changePassword.emit({
        email: this.baseForm.controls.email.value,
        oldPassword: this.passwordForm.controls.oldPassword.value,
        newPassword: this.passwordForm.controls.newPassword.value,
      });
    }
  }

  validateChangePassword(): void {
    this.isChangePasswordAllowed =
      this.passwordForm.valid &&
      this.passwordForm.touched &&
      this.passwordForm.controls.newPasswordConfirm.value ===
        this.passwordForm.controls.newPassword.value;
  }

  sendVoucher(): void {
    this.voucherSend.emit(this.voucherForm.controls.voucherCode.value);
  }

  onDeleteUser(): void {
    if (this.deleteAccountForm.valid) {
      this.deleteUser.emit(
        this.deleteAccountForm.controls.currentPassword.value
      );
    }
  }

  setLanguage(event: string): void {
    this.locale = event;
    this.baseForm.markAsDirty();
  }
}
