import { Component, EventEmitter, Input, Output } from '@angular/core';

export enum CopyOptionsEnum {
  INCLUDE_TITLES = 'include-titles',
  INCLUDE_CHAPTER_NUMBERS = 'include-chapter-numbers',
  INCLUDE_VERSE_NUMBERS = 'include-verse-numbers',
}

export interface CopyOption {
  id: CopyOptionsEnum;
  name: string;
  checked: boolean;
}

@Component({
  selector: 'ibep-copy-options-picker',
  templateUrl: './copy-options-picker.component.html',
})
export class CopyOptionsPickerComponent {
  @Input() options: CopyOption[] = [];

  @Output() valueChanged = new EventEmitter<CopyOptionsEnum[]>();
  @Output() copyClicked = new EventEmitter<void>();

  public toggleOption(option: CopyOption): void {
    option.checked = !option.checked;
    this.valueChanged.emit(
      this.options.filter((option) => option.checked).map((option) => option.id)
    );
  }

  public onCopyButtonClicked(): void {
    this.copyClicked.emit();
  }
}
