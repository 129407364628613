<div
  class="lg:bg-white pb-6 lg:pb-0 lg:pr-5 xl:pr-0"
  [ngClass]="{ 'bg-primary bg-opacity-5': props.background }"
>
  <h2
    *ngIf="props.sectionTitle"
    class="px-4 lg:px-0 py-8 text-2xl font-bold"
    [innerHTML]="props.sectionTitle"
  ></h2>

  <div
    id="teaserViewport"
    #teaserViewport
    class="justify-between lg:grid grid-cols-1 lg:grid-cols-4 overflow-y-scroll no-scrollbar lg:overflow-hidden lg:pb-8 gap-4 scroll-smooth"
    [ngClass]="{ flex: props.horizontalScroll, grid: !props.horizontalScroll }"
  >
    <ng-container *ngFor="let item of props.items; let i = index">
      <ibep-block-item
        observeVisibility
        [root]="'teaserViewport'"
        [threshold]="0.5"
        (visible)="isVisible(i)"
        [isBrowser]="props.isBrowser"
        [isMobile]="isMobile"
        [ngClass]="{
          'pl-4 lg:pl-0': i === 0 && props.horizontalScroll,
          'pr-4 lg:pr-0': i === props.items.length - 1 && props.horizontalScroll
        }"
        [autoWidth]="props.autoWidth"
        [horizontalScroll]="props.horizontalScroll"
        [blockItemProps]="item"
      ></ibep-block-item>
    </ng-container>
  </div>
  <div
    class="flex justify-center gap-1 pt-6 lg:hidden"
    *ngIf="props.horizontalScroll"
  >
    <ng-container *ngFor="let item of props.items; let i = index">
      <div
        (click)="setActive(i)"
        [ngClass]="{ 'bg-opacity-40': activeItem !== i }"
        class="bg-primary rounded-full w-2 p-2 scale-50 cursor-pointer"
      ></div>
    </ng-container>
  </div>
</div>
