<div
  class="flex gap-4 bg-primary bg-opacity-10 px-4 py-5 (dontAddMarginBottom ? '' : 'mb-4')"
>
  <div class="text-xs">
    <div class="text-base" *ngIf="bibleAbbr">
      <ibep-bible-content-container
        [props]="{
          passageId,
          bibleAbbr,
          passage: props.biblepassage
        }"
        [showLinkToReader]="true"
        [showBibleTitle]="showBibleTitle"
      ></ibep-bible-content-container>
    </div>
  </div>
</div>
