import { Component } from '@angular/core';
/**
 * @export
 * @class FqComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'bible-fq',
  templateUrl: './fq.component.html',
  styleUrls: ['./fq.component.scss'],
})
export class FqComponent {}
