import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigLocalizedData } from '@ibep/fe/shared/data';
import {
  AnalyticsService,
  PerformanceService,
  SeoService,
} from '@ibep/fe/web/core';
import {
  AbstractAuthService,
  AbstractThemeService,
  Config,
} from '@ibep/interfaces';

@Component({
  selector: 'ibep-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  config: Config;
  config$ = this._configService.getConfig();
  public isMobileAppView: boolean;

  constructor(
    private readonly _themeService: AbstractThemeService,
    private readonly _authService: AbstractAuthService,
    private readonly _analyticsService: AnalyticsService,
    private readonly _performanceService: PerformanceService,
    private readonly _seoService: SeoService,
    private readonly _configService: ConfigLocalizedData,
    private readonly _router: Router
  ) {
    this.isMobileAppView = this._router.url.includes('viewMode=mobileApp');

    this.config$.subscribe((config) => {
      this.config = config;
      this._authService.init();
      // initialize the theme/styles
      this._themeService.init();

      // initialize seo service
      this._seoService.init();

      // initialize performance service
      this._performanceService.init();

      // initialize GTM
      this._analyticsService.init();
    });
  }
}
