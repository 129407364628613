import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Params } from '@angular/router';

export interface LinkProps {
  isExternalLink: boolean;
  link?: string;
  queryParams?: Params;
  linkStyles?: string;
  isDisabled?: boolean;
}

@Component({
  selector: 'ibep-link',
  templateUrl: './link.component.html',
})
export class LinkComponent {
  linkData: LinkProps = {
    isExternalLink: false,
    link: '',
    linkStyles: '',
    isDisabled: false,
  };

  @Input() set linkProps(value: LinkProps) {
    this.linkData = { ...value };
    if (!value?.isExternalLink && value?.link?.includes('?')) {
      const splittedLink = value.link.split('?');

      this.linkData.link = splittedLink[0];
      this.linkData.queryParams = {};

      const searchParams = new URLSearchParams(splittedLink[1]);
      const keys = searchParams.keys();
      for (const key of keys) {
        this.linkData.queryParams[key] = searchParams.get(key);
      }
    }
  }

  @Output() linkClicked = new EventEmitter<boolean>();

  public handleClick() {
    this.linkClicked.emit(true);
  }
}
