<div
  *ngIf="selectedVerses?.length"
  class="fixed left-0 w-full lg:w-[348px] transition-all z-10"
  [ngClass]="{
    'lg:left-[33.3%] xl:left-[37.5%] lg:-translate-x-1/3 xl:-translate-x-[37.5%] bottom-[13%] lg:bottom-7':
      readerState.ebcState.showEbc,
    'lg:left-[50%] lg:-translate-x-2/4': !readerState.ebcState.showEbc,
    'opacity-0  bottom-0': !selectedVerses?.length,
    'opacity-100 bottom-0 lg:bottom-7': selectedVerses?.length
  }"
>
  <div
    class="relative bg-white shadow-2xl px-6 py-6 lg:rounded-xl border border-grayExtraLight"
  >
    <div class="font-bold mb-3">
      <span class="text-sm">{{ getSelectedVersesTitle() }}</span
      ><span class="ml-2 text-xs text-grayLight">{{ selectedBible }}</span>
    </div>
    <div class="flex flex-col gap-2">
      <!-- Main tab  -->
      <ng-container *ngIf="tab === 'start'">
        <div class="flex gap-2 flex-row">
          <!-- Copy verse  -->
          <span class="relative">
            <ng-container
              *ngIf="
                !(
                  allowBibleCopyingFor === 'all' ||
                  (allowBibleCopyingFor === 'registered' && isAuthenticated) ||
                  (allowBibleCopyingFor === 'premium' &&
                    isAuthenticated &&
                    isPremium)
                )
              "
            >
              <span
                *ngIf="allowBibleCopyingFor === 'registered'"
                class="absolute -top-2 right-1 w-4 h-4 ml-1 rounded-full text-primary bg-highlightText border border-grayExtraLight px-[3px]"
                ><ibep-lock-svg
                  svgStyles="w-2 h-2 absolute top-[3px] right-[3px]"
                ></ibep-lock-svg
              ></span>
              <ng-container *ngIf="allowBibleCopyingFor === 'premium'">
                <!-- hidden premium badge -->
                <!-- we need this fake badge not to see the button border while hovering -->
                <div
                  class="absolute -top-2 right-1 text-secondary text-4xs bg-white ml-auto uppercase font-bold px-[2px]"
                >
                  <span class="text-transparent">{{
                    'Premium' | translate
                  }}</span>
                </div>
                <!-- visible premium badge -->
                <div
                  class="absolute -top-2 right-1 text-secondary text-4xs bg-secondary bg-opacity-10 ml-auto uppercase font-bold px-[2px]"
                >
                  <span>{{ 'Premium' | translate }}</span>
                </div>
              </ng-container>
            </ng-container>
            <ibep-button
              [buttonProps]="{
                pushGtm: true,
                content: 'Copy' | translate,
                locked:
                  (allowBibleCopyingFor === 'registered' && !isAuthenticated) ||
                  (allowBibleCopyingFor === 'premium' &&
                    !isAuthenticated &&
                    !isPremium),
                size: 'm',
                fullWidth: false,
                title: 'Copy' | translate
              }"
              (click)="openModal()"
              ><span class="text-secondary text-xs font-bold"
                >{{ 'Copy' | translate
                }}<svg
                  *ngIf="copied"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="w-6 h-6 inline"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
                  /></svg></span
            ></ibep-button>
          </span>

          <!-- Share verse  -->
          <ibep-button
            [buttonProps]="{
              pushGtm: true,
              content: 'Share' | translate,
              size: 'm',
              fullWidth: false,
              title: 'Share' | translate
            }"
            (click)="onShareClick()"
            ><span class="text-secondary text-xs font-bold">{{
              'Share' | translate
            }}</span></ibep-button
          >
          <!-- Add Note to verse  -->
          <div *ngIf="userAccountSystem" class="relative">
            <span
              *ngIf="!isAuthenticated"
              class="absolute -top-2 right-1 w-4 h-4 ml-1 rounded-full text-primary bg-highlightText border border-grayExtraLight px-[3px]"
              ><ibep-lock-svg
                svgStyles="w-2 h-2 absolute top-[3px] right-[3px]"
              ></ibep-lock-svg
            ></span>
            <ibep-button
              [buttonProps]="{
                pushGtm: true,
                content: 'AddANote' | translate,
                size: 'm',
                fullWidth: false,
                locked: !isAuthenticated,
                title: 'AddANote' | translate
              }"
              (click)="addNote()"
              ><span class="text-secondary text-xs font-bold">{{
                'AddANote' | translate
              }}</span></ibep-button
            >
          </div>
        </div>
        <!-- Highlight verse  -->
        <div *ngIf="userAccountSystem" class="mt-2">
          <h4 class="uppercase text-4xs text-grayMedium">
            {{ 'HighlightVerse' | translate }}
            <span
              *ngIf="!isAuthenticated"
              class="w-4 h-4 ml-1 rounded-full text-primary bg-highlightText border border-grayExtraLight px-[3px]"
              ><ibep-lock-svg svgStyles="w-2 h-2 mb-[3px]"></ibep-lock-svg
            ></span>
          </h4>
          <div class="flex flex-row gap-1 my-2">
            <div
              class="cursor-pointer rounded-full border-2 border-white shadow-md hover:shadow-lg bg-highlightYellow w-8 h-8"
              [title]="'Yellow' | translate"
              [ngClass]="{ 'cursor-not-allowed': !isAuthenticated }"
              (click)="addHighlight('yellow')"
            ></div>
            <div
              class="cursor-pointer rounded-full border-2 border-white shadow-md hover:shadow-lg bg-highlightOrange w-8 h-8"
              [ngClass]="{ 'cursor-not-allowed': !isAuthenticated }"
              (click)="addHighlight('orange')"
              [title]="'Orange' | translate"
            ></div>
            <div
              class="cursor-pointer rounded-full border-2 border-white shadow-md hover:shadow-lg bg-highlightBlue w-8 h-8"
              [ngClass]="{ 'cursor-not-allowed': !isAuthenticated }"
              (click)="addHighlight('blue')"
              [title]="'Blue' | translate"
            ></div>
            <div
              class="cursor-pointer rounded-full border-2 border-white shadow-md hover:shadow-lg bg-highlightGreen w-8 h-8"
              [ngClass]="{ 'cursor-not-allowed': !isAuthenticated }"
              (click)="addHighlight('green')"
              [title]="'Green' | translate"
            ></div>
            <div
              class="cursor-pointer rounded-full border-2 border-white shadow-md hover:shadow-lg bg-grayExtraLight w-8 h-8 text-grayMedium flex justify-center items-center"
              [ngClass]="{ 'cursor-not-allowed': !isAuthenticated }"
              (click)="deleteHighlight()"
              [title]="'DeleteHighlights' | translate"
            >
              <ibep-trash-svg svgStyles="w-4 h-4"></ibep-trash-svg>
            </div>
          </div>
        </div>
      </ng-container>
      <!-- Share tab  -->
      <ng-container *ngIf="tab === 'share'">
        <div class="flex flex-row gap-2">
          <!-- Facebook  -->
          <ibep-button
            [buttonProps]="{
              pushGtm: true,
              content: 'facebook',
              size: 'm',
              fullWidth: false
            }"
            (click)="share('facebook')"
            [title]="'Facebook'"
          >
            <ibep-socials-svg
              svgStyles="w-5 h-5 text-grayLight hover:text-primary"
              socialProps="facebook"
            ></ibep-socials-svg>
          </ibep-button>
          <!-- Twitter  -->
          <ibep-button
            [buttonProps]="{
              pushGtm: true,
              content: 'twitter',
              size: 'm',
              fullWidth: false
            }"
            (click)="share('twitter')"
            [title]="'Twitter'"
          >
            <ibep-socials-svg
              svgStyles="w-5 h-5 text-grayLight hover:text-primary"
              socialProps="twitter"
            ></ibep-socials-svg>
          </ibep-button>
          <!-- URL  -->
          <ibep-button
            [buttonProps]="{
              pushGtm: true,
              content: 'url',
              size: 'm',
              fullWidth: false
            }"
            (click)="share('url'); copied = true"
            title="url"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              class="w-6 h-6 text-grayLight hover:text-primary"
              viewBox="0 0 16 16"
            >
              <path
                d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9c-.086 0-.17.01-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z"
              />
              <path
                d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4.02 4.02 0 0 1-.82 1H12a3 3 0 1 0 0-6H9z"
              />
            </svg>
            <svg
              *ngIf="copied"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="w-6 h-6 inline"
              viewBox="0 0 16 16"
            >
              <path
                d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
              />
            </svg>
          </ibep-button>
        </div>
      </ng-container>
    </div>
    <div class="absolute top-2 right-2 text-grayLight">
      <div
        (click)="close()"
        class="px-1 cursor-pointer"
        [title]="'CloseThisWindow' | translate"
      >
        <ibep-close-svg svgStyles="w-6 h-6"></ibep-close-svg>
      </div>
    </div>
  </div>
</div>

<ng-template #copyPickerTemplate>
  <ibep-copy-options-picker
    [options]="copyOptions"
    (copyClicked)="closeModal()"
    (valueChanged)="copyOptionsChanged($event)"
  ></ibep-copy-options-picker>
</ng-template>
