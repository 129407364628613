import { ViewportScroller } from '@angular/common';
import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnInit,
} from '@angular/core';
import { slugify } from '@ibep/shared/util';

@Component({
  selector: 'ibep-table-of-contents',
  templateUrl: './table-of-contents.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableOfContentsComponent implements OnInit {
  @Input() props: {
    title: string;
    blocks: any[];
  };

  public headings: any[];

  constructor(private readonly _viewportScroller: ViewportScroller) {}

  ngOnInit(): void {
    // get all headings in this post
    this.headings = this.props.blocks
      .filter((block) => block.blockName === 'core/heading')
      .map((heading) => {
        heading.title = heading.innerHTML.replace(/<\/?[^>]+(>|$)/g, '');
        heading.slug = slugify(heading.title);
        if (!heading.attrs.level) {
          heading.attrs.level = heading.innerHTML.includes('<h2')
            ? 2
            : heading.innerHTML.includes('<h3')
            ? 3
            : heading.innerHTML.includes('<h4')
            ? 4
            : 5;
        }
        return heading;
      });
  }

  /**
   * scroll the window to the heading
   * @param anchorId
   */
  scrollToAnchor(anchorId: string) {
    this._viewportScroller.scrollToAnchor(anchorId);
  }
}
