import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AnalyticsService } from '@ibep/fe/web/core';
import { SearchCategory, SearchCategoryButton } from '@ibep/interfaces';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ibep-button-toggle-group',
  templateUrl: './button-toggle-group.component.html',
  styleUrls: ['./button-toggle-group.component.scss'],
})
export class ButtonToggleGroupComponent {
  public searchCategoryEnum = SearchCategory;

  @Input() props: SearchCategoryButton[];
  @Input() multiple: boolean;
  @Input() bibleId: string;
  @Input() query: string;

  @Output() singleValueChanged = new EventEmitter<SearchCategory>();
  @Output() multipleValueChanged = new EventEmitter<SearchCategory[]>();

  constructor(
    private readonly _analyticsService: AnalyticsService,
    private readonly _translateService: TranslateService
  ) {}

  public onButtonClicked(button: SearchCategoryButton): void {
    if (this.multiple) {
      this.multipleCheck(button);
    } else {
      this.singleCheck(button);
    }
  }

  private singleCheck(button: SearchCategoryButton): void {
    if (!button.checked) {
      this.props.forEach((btn) => {
        btn.checked = btn.value === button.value;
      });
      this.pushIbepButtonTag(button);
      this.singleValueChanged.emit(button.value);
    }
  }

  private multipleCheck(button: SearchCategoryButton): void {
    // prevent to unckeck all buttons
    if (
      !button.checked ||
      this.props.filter((btn) => btn.checked).length !== 1
    ) {
      const checked = !button.checked;
      if (button.value === SearchCategory.ALL) {
        // uncheck other categories if "All" is checked
        this.props.forEach((btn) => {
          btn.checked = checked && btn.value === SearchCategory.ALL;
        });
      } else {
        button.checked = checked;
        const allBtn = this.props.find(
          (btn) => btn.value === SearchCategory.ALL
        );
        if (allBtn?.checked) {
          allBtn.checked = false;
        }
        // if all categories are checked we will unckeck them and check the "All" button
        this.checkAll();
      }
      this.pushIbepButtonTag(button);
      this.multipleValueChanged.emit(
        this.props
          .filter((btn) => {
            // to emit all categories when the "All" button is checked
            return button.checked && button.value === SearchCategory.ALL
              ? !btn.checked
              : btn.checked;
          })
          .map((btn) => btn.value)
      );
    }
  }

  private checkAll(): void {
    const unchecked = this.props.filter((btn) => !btn.checked);
    if (unchecked.length === 1 && unchecked[0].value === SearchCategory.ALL) {
      this.props.forEach((btn) => {
        btn.checked = !btn.checked;
      });
    }
  }

  private pushIbepButtonTag(button: SearchCategoryButton): void {
    this._analyticsService.pushGtmTag({
      event: 'button_click',
      button_url:
        `${this._translateService.instant('ROUTES.search')}` +
        `/${button.value}` +
        `?query=${this.query}` +
        `${
          button.value === SearchCategory.BIBLE ||
          button.value === SearchCategory.ALL
            ? `&bibleId=${this.bibleId}`
            : ''
        }`,
      button_text: button.text,
    });
  }
}
