import { Injectable } from '@angular/core';
import {
  AbstractBackendService,
  AbstractEnvironmentService,
  AbstractLanguageService,
  AbstractStorageService,
} from '@ibep/interfaces';
import { HOUR } from '@ibep/shared/util';
import { Observable, of } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';
import { ConfigData } from '..';
import { Store, StoreSettings, StoreSettingsTTL } from './store';

@Injectable({
  providedIn: 'root',
})
export class LtSundaysData extends Store<any> {
  constructor(
    storage: AbstractStorageService,
    environment: AbstractEnvironmentService,
    backendService: AbstractBackendService,
    languageService: AbstractLanguageService,
    config: ConfigData
  ) {
    super(storage, environment, backendService, languageService, {
      storeTtl: { default: 1 * HOUR } as StoreSettingsTTL,
      storeKey: 'ltSundays',
      brand$: config.getBrand(),
    } as StoreSettings);
  }

  /**
   * Get the lt sundays data
   *
   * @returns {Observable<LtSundays>}
   * @memberof LtSundaysData
   */

  public getSundays(language: string, category: string): Observable<any> {
    const endPoint = `content/lt/sundays?language=${this._currentLanguage}&${
      language ? `ltLanguage=${language}` : ''
    }${category ? `${language ? '&' : ''}category=${category}` : ''}`;

    const cacheKey = endPoint;

    this.remoteDataMap$[cacheKey] = this.backendService.get<any>({
      endpoint: endPoint,
      options: {
        headers: {
          'x-brand': this.brand.id,
          redisTTL: `${this.storeSettings.storeTtl.default}`,
        },
      },
    });

    return this.localData$.pipe(
      take(1),
      switchMap((localData) => {
        if (localData?.[cacheKey]) {
          return of(localData[cacheKey]);
        }

        return this.remoteDataMap$[cacheKey].pipe(
          tap((remoteData: any) => {
            // write returned data from api to the store
            this.setState(
              {
                [cacheKey]: remoteData,
              },
              true
            );
          })
        );
      })
    );
  }
}
