<h3 class="mb-4 text-2xl font-bold">
  {{ 'YourAccountIsDeleted*' | translate }}
</h3>

<a
  [routerLink]="[] | localize"
  [queryParams]="{ view: 'signUp' }"
  class="font-medium text-secondary hover:text-primary"
>
  {{ 'RegisterNow' | translate }}
</a>
