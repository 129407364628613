import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ibep-pagination',
  templateUrl: './pagination.component.html',
})
export class PaginationComponent {
  public middlePage = 3;

  @Input() actualPage = 1;
  @Input() step = 20;
  @Input() lastPage = 1;
  @Input() set changeFilter(value: string) {
    this.goToPage(1);
  }

  @Output() pageChange = new EventEmitter<number>();

  public goToPage(page: number): void {
    if (page < 1 || page > this.lastPage) {
      return;
    }

    if (page > 2 && page < this.lastPage - 1) {
      this.middlePage = page;
    }

    this.pageChange.emit(page);
  }
}
