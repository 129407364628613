import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '@ibep/fe/shared/core';
import { AbstractEnvironmentService, Image, ImageSize } from '@ibep/interfaces';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'ibep-image-picture',
  templateUrl: './image-picture.component.html',
  styleUrls: ['./image-picture.component.scss'],
})
export class ImagePictureComponent extends BaseComponent implements OnInit {
  private readonly _imageSizes: (
    | 'small'
    | 'medium'
    | 'mediumLarge'
    | 'large'
    | 'extraLarge'
  )[] = ['small', 'medium', 'mediumLarge', 'large', 'extraLarge'];

  public allImageSizes: Image;
  public optimalImageSize?: ImageSize;
  public imageWidth = 0;

  @Input() imgStyles: string | { [klass: string]: any };
  @Input() set image(value: Image) {
    this.allImageSizes = value;
    this._setImageSize();
  }
  @Input() set containerWidth(value: number | undefined) {
    if (value) {
      this.imageWidth = value;
      this._setImageSize();
    }
  }

  constructor(
    private readonly _environmentService: AbstractEnvironmentService
  ) {
    super();
  }

  ngOnInit(): void {
    this._environmentService.windowWidth$
      .pipe(takeUntil(this._destroy))
      .subscribe(() => {
        this._setImageSize();
      });
  }

  private _setImageSize(): void {
    if (this.imageWidth) {
      let defaultSize: ImageSize | undefined = undefined;
      let optimalSize: ImageSize | undefined = undefined;

      this._imageSizes.forEach((imageSize) => {
        if (this.allImageSizes?.[imageSize]) {
          if (this.allImageSizes[imageSize].url) {
            defaultSize = this.allImageSizes[imageSize];
          }

          if (
            !optimalSize &&
            this.allImageSizes[imageSize].width >= this.imageWidth
          ) {
            optimalSize = this.allImageSizes[imageSize];
          }
        }
      });

      if (optimalSize || defaultSize) {
        this.optimalImageSize = optimalSize || defaultSize;
      }
    }
  }
}
