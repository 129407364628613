import { Environment, EnvironmentType } from '@ibep/interfaces';

export const environment: Environment = {
  name: EnvironmentType.staging,
  app: 'ibep',
  production: true,
  localWordpress: false,
  displayLogInfo: false,
  displayPerformanceLogs: false,
  sentry: false,
  recaptchaKey: {
    dev: '6LeitsMoAAAAAECXkLJl6XeDtfwQbY3GGz-dZGae',
    staging: '6LeitsMoAAAAAECXkLJl6XeDtfwQbY3GGz-dZGae',
    prod: '6LeGgsQoAAAAAKTGr8qry2EP0PGWYvfcuz97tN_y',
    test: '6LeitsMoAAAAAECXkLJl6XeDtfwQbY3GGz-dZGae',
  },
  authApi: {
    // without ending slash!
    dev: '-auth.ibep-dev.com/auth',
    staging: '-auth.ibep-staging.com/auth',
    prod: '-auth.ibep-prod.com/auth',
    test: '-auth.ibep-test.com/auth',
  },
  backendApi: {
    // without ending slash!
    dev: 'https://api.ibep-dev.com',
    staging: 'https://api.ibep-staging.com',
    prod: 'https://api.ibep-prod.com',
    test: 'https://api.ibep-test.com',
    key: '3dcb5548-affa-4588-99e7-68fd34ba2245',
    prodKey: '896f6f87-fc95-4605-b782-804b99b83800',
  },
  webSocket: {
    dev: 'wss://u0f66ngvbb.execute-api.us-east-2.amazonaws.com/dev',
    staging: 'wss://cny3wvor6f.execute-api.us-east-2.amazonaws.com/staging',
    prod: 'wss://hd0agw1xri.execute-api.us-east-2.amazonaws.com/prod',
    test: 'wss://e5cbjrq6gk.execute-api.us-east-2.amazonaws.com/test',
    key: '3dcb5548-affa-4588-99e7-68fd34ba2245',
    prodKey: '896f6f87-fc95-4605-b782-804b99b83800',
  },
  redis: {
    initRedis: false,
    host: 'http://127.0.0.1',
    port: 6379,
  },
  serviceWorker: {
    initServiceWorker: false,
    file: '',
    updateInterval: 0,
  },
};
