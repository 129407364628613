import { Injectable } from '@angular/core';
import {
  AbstractBackendService,
  AbstractEnvironmentService,
  AbstractLanguageService,
  AbstractStorageService,
  DailyVerse,
  GetDailyVerse,
} from '@ibep/interfaces';
import { HOUR } from '@ibep/shared/util';
import { Observable, of } from 'rxjs';
import {
  catchError,
  map,
  shareReplay,
  switchMap,
  take,
  tap,
  timeout,
} from 'rxjs/operators';
import { ConfigData } from './config.data';
import { Store, StoreSettings, StoreSettingsTTL } from './store';

@Injectable({
  providedIn: 'root',
})
export class DailyVerseData extends Store<any> {
  constructor(
    storage: AbstractStorageService,
    environment: AbstractEnvironmentService,
    backendService: AbstractBackendService,
    languageService: AbstractLanguageService,
    config: ConfigData
  ) {
    super(storage, environment, backendService, languageService, {
      storeTtl: { default: 1 * HOUR } as StoreSettingsTTL,
      storeKey: 'dailyVerse',
      brand$: config.getBrand(),
    } as StoreSettings);
  }

  public getDailyVerse({
    language,
  }: {
    language: string;
  }): Observable<DailyVerse> {
    // get current date, that handles timezone offset
    let date = new Date();
    const offset = date.getTimezoneOffset();
    date = new Date(date.getTime() - offset * 60 * 1000);
    const dateString = String(date.toISOString().split('T')[0]);

    this.remoteDataItem$ = this.backendService
      .get<GetDailyVerse>({
        endpoint: `content/daily-verse/${dateString}`,
        options: {
          params: {
            language,
          },
          headers: {
            'x-brand': this.brand.id,
            redisTTL: `${this.storeSettings.storeTtl.default}`,
          },
        },
      })
      .pipe(
        timeout(1000),
        catchError(() => of({ data: null } as unknown as GetDailyVerse)),
        map((dto: GetDailyVerse) => dto.data),
        shareReplay(1)
      );

    return this.localData$.pipe(
      take(1),
      switchMap((localData) => {
        if (localData?.[dateString]) {
          return of(localData[dateString]);
        }

        return this.remoteDataItem$.pipe(
          tap((dailyVerse: DailyVerse) => {
            this.setState({
              [dateString]: dailyVerse,
            });
          })
        );
      })
    );
  }
}
