import { Component, Input } from '@angular/core';

@Component({
  selector: 'ibep-expandable-section',
  templateUrl: './expandable-section.component.html',
  styles: [``],
})
export class ExpandableSectionComponent {
  @Input() contentType: 'image' | 'text' | 'table';
  @Input() maxHeigth = 'max-h-[300px]';

  public expanded = false;

  public toggleExpanded() {
    this.expanded = !this.expanded;
  }
}
