<button
  id="toggle"
  type="button"
  class="relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none scale-[.6]"
  [disabled]="isDisabled"
  [ngClass]="{
    'bg-successLight cursor-pointer': isActive && !isDisabled,
    'bg-grayExtraLight cursor-pointer': !isActive && !isDisabled,
    'bg-gray-200': isDisabled
  }"
  role="switch"
>
  <!-- @Martijn i "turned it off" since it was actually confusing with voice over  -->
  <!-- <span class="sr-only">Use setting</span> -->
  <span
    aria-hidden="true"
    [ngClass]="{
      'translate-x-5 bg-primary': isActive && !isDisabled,
      'translate-x-0 bg-grayLight': !isActive && !isDisabled,
      'bg-gray-300': isDisabled
    }"
    class="translate-x-0 pointer-events-none inline-block h-5 w-5 rounded-full shadow transform ring-0 transition ease-in-out duration-200"
  ></span>
</button>
