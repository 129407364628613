import { Component } from '@angular/core';

@Component({
  selector: 'bible-iex',
  template: `
    <p class="iex">
      <ng-content></ng-content>
    </p>
  `,
  styleUrls: ['./iex.component.scss'],
})
export class IexComponent {}
