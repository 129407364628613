import { Component, Input } from '@angular/core';
import { ReadingPlan } from '@ibep/interfaces';

@Component({
  selector: 'ibep-reading-plan-page',
  templateUrl: './reading-plan-page.component.html',
})
export class ReadingPlanPageComponent {
  @Input() isMobileAppView: boolean;
  @Input() parentRoutes: any;
  @Input() plan: ReadingPlan;
  @Input() dateFormat: string;
  @Input() tab: string;
}
