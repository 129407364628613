import { Component } from '@angular/core';

@Component({
  selector: 'ibep-next-svg',
  template: `<svg
    ibepHideTillInit
    class="h-12 w-12 inline"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>Right</title>
    <g
      id="Symbols"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="bible-reader-mobile-bottom-nav"
        transform="translate(-324.000000, -65.000000)"
      >
        <g id="Right" transform="translate(325.000000, 66.000000)">
          <circle
            id="Oval"
            stroke="#C0D0E4"
            fill="#FFFFFF"
            cx="20"
            cy="20"
            r="20"
          ></circle>
          <path
            d="M21.8268162,28.5010029 L15.4675822,21.9768572 C14.962775,21.4597929 14.6776585,20.7471747 14.6776585,20.0023831 C14.6776585,19.2575914 14.962775,18.5449357 15.4675822,18.0279089 L21.8268162,11.5037632 C22.4955319,10.8175064 23.5609108,10.838018 24.2054906,11.5491718 C24.8507934,12.2610587 24.8315058,13.3933205 24.1627919,14.0795586 L18.3888824,20.0024187 L24.1627919,25.9252788 C24.6565802,26.4313561 24.8122201,27.2062215 24.5553473,27.8823158 C24.2984744,28.5590481 23.6814154,29.002147 22.9954749,29.002147 C22.559565,29.0026941 22.1401588,28.8232598 21.8268162,28.5010029 Z"
            id="Fill-1"
            fill="currentColor"
            transform="translate(19.677658, 20.002148) scale(-1, 1) translate(-19.677658, -20.002148) "
          ></path>
        </g>
      </g>
    </g>
  </svg>`,
  styles: [],
})
export class NextComponent {}
