import { Component } from '@angular/core';

@Component({
  selector: 'bible-pmo',
  template: `
    <p class="pmo">
      <ng-content></ng-content>
    </p>
  `,
  styleUrls: ['./pmo.component.scss'],
})
export class PmoComponent {}
