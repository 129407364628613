import { Component, EventEmitter, Output, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { UserInfo } from '@ibep/interfaces';

@Component({
  selector: 'ibep-menu-searchbar',
  templateUrl: './menu-searchbar.component.html',
})
export class MenuSearchbarComponent {
  @Output() handleFocus: EventEmitter<any> = new EventEmitter();

  @Output() handleOpenModal: EventEmitter<string> = new EventEmitter();

  @Input() isSuggestionModalOpen: boolean;

  @Input() isAuthenticated = false;
  @Input() isPremium = false;

  @Input() userAccountSystem: boolean;

  @Input() displaySticky: boolean;

  @Input() userProfile?: UserInfo;

  @Input() hasPremiumLayer: boolean;

  @Input() searchControl: UntypedFormControl;

  public openModal(type: string): void {
    this.handleOpenModal.emit(type);
  }

  public onSearchInputFocus(): void {
    this.handleFocus.emit();
  }

  public onSearchActivated(): void {
    if (!this.isSuggestionModalOpen && this.searchControl.value) {
      this.openModal('suggestions');
    }
  }
}
