import { Injectable } from '@angular/core';
import {
  AbstractBackendService,
  AbstractEnvironmentService,
  AbstractLanguageService,
  AbstractStorageService,
  ResponseData,
  SearchCategory,
  Tag,
} from '@ibep/interfaces';
import { HOUR } from '@ibep/shared/util';
import { Observable, of } from 'rxjs';
import { map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { ConfigData } from './config.data';
import { Store, StoreSettings, StoreSettingsTTL } from './store';

@Injectable({
  providedIn: 'root',
})
export class PagesTagsData extends Store<{ [key: string]: Tag[] }> {
  constructor(
    storage: AbstractStorageService,
    environment: AbstractEnvironmentService,
    backendService: AbstractBackendService,
    languageService: AbstractLanguageService,
    config: ConfigData
  ) {
    super(storage, environment, backendService, languageService, {
      storeTtl: { default: 1 * HOUR } as StoreSettingsTTL,
      storeKey: 'tags',
      brand$: config.getBrand(),
    } as StoreSettings);
  }

  /**
   * Get the bible search results
   *
   * @returns {Observable<SearchResult>}
   * @memberof PagesTagsData
   */
  public getTags(): Observable<Tag[]> {
    this.remoteDataMap$[SearchCategory.PAGES] = this.backendService
      .get<ResponseData<Tag[]>>({
        endpoint: `content/pages/tags?language=${this._currentLanguage}`,
        options: {
          headers: {
            'x-brand': this.brand.id,
            Authorization: 'Bearer anonymous',
            redisTTL: `${this.storeSettings.storeTtl.default}`,
          },
        },
      })
      .pipe(
        map((tags: ResponseData<Tag[]>) => tags.data),
        shareReplay(1)
      );

    return this.localData$.pipe(
      take(1),
      switchMap((localData: any) => {
        // check if there is local data available
        if (localData?.[SearchCategory.PAGES]) {
          return of(localData[SearchCategory.PAGES]);
        }
        // if no local data, fetch data from api
        return this.remoteDataMap$[SearchCategory.PAGES].pipe(
          tap((remoteData: Tag[]) => {
            // write returned data from api to the store
            this.setState(
              {
                [SearchCategory.PAGES]: remoteData,
              },
              true
            );
          })
        ) as Observable<Tag[]>;
      })
    );
  }
}
