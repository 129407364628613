import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { ContentType } from '@ibep/interfaces';
import { debounceTime, distinctUntilChanged, filter, fromEvent } from 'rxjs';

@Component({
  selector: 'ibep-teaser-list',
  templateUrl: './teaser-list.component.html',
})
export class TeaserListComponent {
  public contentTypeEnum = ContentType;
  public showCategoryFilter = false;

  @ViewChild('siblingInput') siblingInput: ElementRef;
  @ViewChild('categoryFilterButton')
  private categoryFilterButton!: ElementRef;
  @ViewChild('categoryFilter')
  private categoryFilter!: ElementRef;
  private listenFn: any;

  @Input() items: any;
  @Input() categories: any[];
  @Input() tags: any[];
  @Input() termAbove: string;
  @Input() readMoreText: string;
  @Input() moreItemsAvailable: boolean;
  @Input() contentType: ContentType;
  @Input() route: string;
  @Input() template: 'default' | 'large' | 'small' | 'list' = 'default';
  @Input() fullTeaserDescription = false;
  @Input() variableHeightImage = false;
  @Input() isAuthenticated: boolean;
  @Input() isPremium: boolean;
  @Input() isBrowser: boolean;
  @Input() tagView: boolean;
  @Input() isLoading: boolean;

  @Output() loadMoreClicked = new EventEmitter<{
    search: string;
    page?: number;
  }>();

  constructor(
    private readonly _renderer: Renderer2,
    private readonly _ref: ChangeDetectorRef,
    private readonly _router: Router
  ) {}

  ngAfterViewInit(): void {
    // Listen for EBC sibling queries
    if (this.siblingInput) {
      fromEvent(this.siblingInput?.nativeElement, 'keyup')
        .pipe(filter(Boolean), debounceTime(400), distinctUntilChanged())
        .subscribe((res) => {
          this.loadMoreClicked.emit({
            search: this.siblingInput.nativeElement.value,
            page: 1,
          });
        });
    }
  }

  /**
   * Next teaser page requested
   *
   * @memberof TeaserListComponent
   */

  public loadMore(): void {
    if (this.siblingInput) {
      this.loadMoreClicked.emit({
        search: this.siblingInput.nativeElement.value,
      });
    } else {
      this.loadMoreClicked.emit();
    }
  }

  /**
   *  Toggle the category filter popup
   */
  public toggleCategoryFilter(): void {
    if (!this.showCategoryFilter) {
      this._registerListener();
    }
    this.showCategoryFilter = !this.showCategoryFilter;
  }

  /**
   *  To be able to close the category filter popup when clicking outside the popup, we need to set up a listener
   */
  private _registerListener(): void {
    // listen for clicks outside popup
    this.listenFn = this._renderer.listen('window', 'click', (e: Event) => {
      if (
        !this.categoryFilterButton?.nativeElement.contains(e.target) &&
        !this.categoryFilter?.nativeElement.contains(e.target) &&
        this.showCategoryFilter
      ) {
        // close the popup
        this.showCategoryFilter = false;
        // unregister the listener
        this.listenFn();
        // we need to detect changes manually
        this._ref.detectChanges();
      }
    });
  }

  public createCategoryLink(slug: string): string {
    return `${this._router.url.split('/tags')[0]}/${slug}`;
  }
}
