import { Component } from '@angular/core';
/**
 * [USX-Documentation](https://ubsicap.github.io/usx/parastyles.html#sp)
 * @style sp
 * Use Speaker identification (e.g. Job and Song of Songs).
 * Valid In: [Chapter-Content](https://ubsicap.github.io/usx/structure.html#usx-div-chaptercontent)
 * @export
 * @class SpComponent
 */
@Component({
  selector: 'bible-sp',
  template: `
    <h5 class="sp">
      <ng-content></ng-content>
    </h5>
  `,
  styleUrls: ['./sp.component.scss'],
})
export class SpComponent {}
