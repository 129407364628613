<span [ngSwitch]="item.style">
  <h2 *ngSwitchCase="'ms1'" [class]="item.style" class="!text-xl text-[#040632">
    <ng-container [ngTemplateOutlet]="contentTemplate"></ng-container>
  </h2>
  <h3
    *ngSwitchCase="'ms2'"
    [class]="item.style"
    class="!text-3lg text-[#040632"
  >
    <ng-container [ngTemplateOutlet]="contentTemplate"></ng-container>
  </h3>
  <h4 *ngSwitchDefault [class]="item.style" class="!text-lg text-[#040632">
    <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
  </h4>
</span>

<ng-template #contentTemplate> <ng-content></ng-content></ng-template>
