import { Country } from './country.interface';
import { Language } from './language.interface';
// #region GetChapterContentResponse
export interface BibleVerse {
  type: BibleVerseType; // => "verse-number" | "verse-text" | "text"
  style: string; // => "v" | "nd" |
  content: any;
  pubnumber?: number;
  verseId: string;
  verseOrgId: string;
  hasFootnotes?: boolean;
  pathId?: string;
}

export enum BibleVerseType {
  paragraph = 'paragraph',
  verseNumber = 'verse-number',
  verseText = 'verse-text',
  text = 'text',
}

export const headerStyles = [
  'd',
  'mr',
  'ms',
  'ms1',
  'ms2',
  'ms3',
  'mt',
  'mt1',
  'mt2',
  'mt3',
  'mte',
  'mte1',
  'mte2',
  'mte3',
  'r',
  's',
  's1',
  's2',
  's3',
  'sr',
  'sp',
];

export const paragraphStyles = [
  'cls',
  'li',
  'li1',
  'li2',
  'li3',
  'lit',
  'm',
  'mi',
  'nb',
  'p',
  'pc',
  'ph',
  'ph1',
  'ph2',
  'ph3',
  'pi',
  'pi1',
  'pi2',
  'pi3',
  'pm',
  'pmc',
  'pmo',
  'pmr',
  'pr',
  'table',
];

export const poetryStyles = [
  'q',
  'q1',
  'q2',
  'q3',
  'q4',
  'qr',
  'qc',
  'qa',
  'qd',
  'qm',
  'qm1',
  'qm2',
  'qm3',
];

export interface BiblePassage {
  type: 'paragraph' | 'table' | 'study';
  subtype?: string;
  image?: boolean;
  hasFootnote?: boolean;
  style:
    | 'p'
    | 's'
    | 's1'
    | 's2'
    | 's3'
    | 'q'
    | 'q1'
    | 'q2'
    | 'q3'
    | 'q4'
    | 'pm'
    | 'pmc'
    | 'pmo'
    | 'pmr'
    | 'po'
    | 'imt'
    | 'imt1'
    | 'imt2'
    | 'imt3'
    | 'imt4'
    | 'b'
    | 'cd'
    | 'cl'
    | 'cls'
    | 'cp'
    | 'd'
    | 'iex'
    | 'ip'
    | 'lf'
    | 'lh'
    | 'li'
    | 'lim'
    | 'm'
    | 'mi'
    | 'mr'
    | 'ms'
    | 'ms1'
    | 'ms2'
    | 'ms3'
    | 'pc'
    | 'pi'
    | 'pi1'
    | 'pi2'
    | 'pi3'
    | 'qa'
    | 'qc'
    | 'qd'
    | 'qm'
    | 'qm1'
    | 'qm2'
    | 'qm3'
    | 'qr'
    | 'r'
    | 'sd'
    | 'sp'
    | 'sr';
  content: any;
  pubnumber?: number;
  id: string;
  verseId: string;
  verseOrgId: string[];
}

export interface SiblingChapter {
  id: string;
  bookId: string;
  number: string;
}

/**
 * Name is Self Asigned
 * @interface ChapterContent
 */
export interface ChapterContent {
  id: string; // ! - "GEN.1"
  bibleId: string; // ! - "a31452bf933bcd2d-01"
  bookId: string; // ! "GEN"
  number: string; // ! - "1"
  content: BiblePassage[]; // required <<== actuall typeof([{ undefined }])
  copyright: string; // ! - "© 2004/2007 Nederlands Bijbelgenootschap"
  next?: SiblingChapter; // optional
  previous?: SiblingChapter; // optional
  verseCount?: number; // ? - 31
  title?: string;
}

export interface GetChapterStudyContentResponse {
  data: {
    chapter: ChapterContent; // required;
    studyContent: any;
  };
}
// #endregion GetChapterContentResponse

// #region GetBibleMetadataResponse

export interface Chapter {
  id: string; // required
  bibleId: string; // required
  bookId: string; // required
  number: string; // required
  position: number; // required
  hasAudio?: boolean;
}

/**
 * @childOf
 * - GetBibleMetadataResponse
 * - BibleMetadata
 * - Testament
 * @export
 * @interface Book
 */
export interface Book {
  id: string; // required
  bibleId: string; // required
  abbreviation: string; // required
  name: string; // required
  nameLong: string; // required
  chapters: Chapter[]; // required
}

/**
 * @childOf
 * - GetBibleMetadataResponse
 * - BibleMetadata
 * @export
 * @interface Testament
 */
export interface Testament {
  abbreviation: string; // required
  books: Book[]; // required
}

// custom styling interfaces

export interface NDCustomStyling {
  enabled: boolean;
  disableSmallCaps: boolean;
  enableBold: boolean;
  enableItalic: boolean;
  customColor: string;
}

export interface WJCustomStyling {
  enabled: boolean;
  disableRedColor: boolean;
  enableBold: boolean;
  enableItalic: boolean;
  customColor: string;
}

export interface CustomStyling {
  nd?: NDCustomStyling;
  wj?: WJCustomStyling;
}

/**
 * could also be named Bible...
 * @childOf
 * - GetBibleMetadataResponse
 * @export
 * @interface BibleMetadata
 */
export interface BibleMetadata {
  id: string; // required
  dblId: string; // required
  abbreviation: string; // required
  abbreviationLocal: string; // required
  name: string; // name should always be there, removed "?"
  nameLocal?: string;
  description?: string;
  descriptionLocal?: string;
  copyright?: string;
  language?: Language;
  countries?: Country[];
  updatedAt?: Date;
  testaments: Testament[]; // required 2076
  audioAccessLevelWeb: 'nolicense' | 'anonymous' | 'registered' | 'premium';
  audioAccessLevelMobile: 'nolicense' | 'anonymous' | 'registered' | 'premium';
  hasAudio: boolean;
  customStyling: CustomStyling;
}

export interface GetBibleMetadataResponse {
  data: BibleMetadata;
}
// #endregion GetBibleMetadataResponse

export interface Column {
  bible: BibleMetadata;
  chapter: ChapterContent | null;
  studyContent: any;
  userHighlights: any;
  userNotes: any;
  chapterAudio: any;
  columnAudioBible: any;
  ebcArticles: any;
}
