import { Injectable } from '@angular/core';
import {
  AbstractBackendService,
  AbstractEnvironmentService,
  AbstractLanguageService,
  AbstractStorageService,
  GetPageResponse,
} from '@ibep/interfaces';
import { HOUR } from '@ibep/shared/util';
import { Observable, of } from 'rxjs';
import { switchMap, take, tap, shareReplay } from 'rxjs/operators';
import { ConfigData } from '..';
import { Store, StoreSettings, StoreSettingsTTL } from './store';

@Injectable({
  providedIn: 'root',
})
export class PageData extends Store<any> {
  constructor(
    storage: AbstractStorageService,
    environment: AbstractEnvironmentService,
    backendService: AbstractBackendService,
    languageService: AbstractLanguageService,
    config: ConfigData
  ) {
    super(storage, environment, backendService, languageService, {
      storeTtl: { default: 1 * HOUR } as StoreSettingsTTL,
      storeKey: 'page',
      brand$: config.getBrand(),
    } as StoreSettings);
  }

  /**
   * Get the page data
   *
   * @returns {Observable<Page>}
   * @memberof PageData
   */
  public getPage({
    pageIdType,
    pageId,
  }: {
    pageIdType: string;
    pageId?: string;
  }): Observable<any> {
    this.remoteDataMap$[`${pageIdType}-${pageId}`] = this.backendService
      .get<GetPageResponse>({
        endpoint: `content/pages/${pageIdType}${pageId ? '/' : ''}${
          pageId ? pageId : ''
        }?platform=web&language=${this._currentLanguage}`,
        options: {
          headers: {
            'x-brand': this.brand.id,
          },
        },
      })
      .pipe(shareReplay(1));

    return this.localData$.pipe(
      take(1),
      switchMap((localData) => {
        // check if there is local data available
        if (localData?.[`${pageIdType}-${pageId}`]) {
          return of(localData[`${pageIdType}-${pageId}`]);
        }
        // if no local data, fetch data from api
        return this.remoteDataMap$[`${pageIdType}-${pageId}`].pipe(
          tap((remoteData: GetPageResponse) => {
            // write returned data from api to the store
            this.setState(
              {
                [`${pageIdType}-${pageId}`]: remoteData,
              },
              true
            );
          })
        );
      })
    );
  }
}
