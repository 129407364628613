<div
  class="lg:bg-[#fff]"
  [ngClass]="{ 'sticky -top-[1px] pt-[1px] z-20': displaySticky }"
>
  <div
    class="lg:bg-gradientPrimary lg:bg-opacity-[.08] border-b border-gradientPrimary lg:border-0 h-12 lg:px-5 xl:px-0"
  >
    <div
      class="max-w-container mx-auto grid grid-cols-1 lg:grid-cols-12 gap-1 justify-between items-center content-center h-full lg:px-0"
    >
      <div
        class="hidden lg:flex items-center lg:col-span-4"
        [ngClass]="{
          '!xl:col-span-3': !isAuthenticated
        }"
      >
        <ng-container *ngIf="isAuthenticated && !isPremium && hasPremiumLayer">
          <a [routerLink]="[]" [queryParams]="{ view: 'premium' }">
            <ibep-button
              [buttonProps]="{
                size: 'm-flexible',
                style: 'premium',
                pushGtm: true,
                content: 'GoPlusfor*' | translate,
                name: 'go_plus'
              }"
              class="text-secondary"
            >
              <ibep-star-svg svgStyles="w-4 h-4 mb-1"></ibep-star-svg>
              <span class="ml-1 text-xs">{{
                'GoPlusfor*' | translate
              }}</span></ibep-button
            ></a
          >
        </ng-container>
      </div>
      <div
        class="w-full h-full grow relative lg:col-span-4 flex items-center"
        [ngClass]="{
          '!xl:col-span-3': !isAuthenticated
        }"
      >
        <ibep-search-svg
          class="absolute"
          svgStyles="w-4 h-4 ml-5 mt-4 lg:mt-1"
        ></ibep-search-svg>
        <input
          class="autofill:transition-[background-color] autofill:duration-[5000s] w-full h-[47px] lg:h-8 text-sm lg:rounded-[40px] text-content100 pl-12 pr-4 border border-grayExtraLight"
          [formControl]="searchControl"
          [value]="searchControl.value"
          (focus)="onSearchInputFocus()"
          autocomplete="off"
          name="search"
          [placeholder]="'SearchTheBible*' | translate"
          [title]="'SearchTheBible*' | translate"
          (input)="onSearchActivated()"
          (focus)="onSearchActivated()"
        />
      </div>

      <div
        class="text-sm shrink hidden lg:flex gap-1 justify-self-end items-center overflow-hidden lg:col-span-4"
        [ngClass]="{
          '!xl:col-span-3': !isAuthenticated
        }"
      >
        <!-- Signin button  -->
        <a [routerLink]="[]" [queryParams]="{ view: 'signIn' }">
          <ibep-button
            class="text-secondary hover:text-primary"
            [buttonProps]="{
              pushGtm: true,
              name: 'login_button',
              content: 'SignIn' | translate,
              size: 'm-flexible'
            }"
            *ngIf="!isAuthenticated && userAccountSystem"
          >
            <b class="text-xs">{{ 'SignIn' | translate }}</b>
          </ibep-button>
        </a>
        <!-- SignUp button  -->
        <a [routerLink]="[]" [queryParams]="{ view: 'signUp' }">
          <ibep-button
            *ngIf="!isAuthenticated && userAccountSystem"
            [buttonProps]="{
              pushGtm: true,
              name: 'signup_button',
              content: 'SignUp' | translate,
              style: 'secondary',
              size: 'm-flexible'
            }"
          >
            <b class="text-xs">{{ 'SignUp' | translate }}</b>
          </ibep-button>
        </a>
        <!-- Notes and highlights button  -->
        <a
          [routerLink]="'/' + 'ROUTES.notes-highlights' | localize"
          *ngIf="isAuthenticated"
        >
          <ibep-button
            class="text-primary"
            [buttonProps]="{
              size: 'm-flexible'
            }"
          >
            <span class="text-xs overflow-clip">{{
              'YourNotesAndHighlights' | translate
            }}</span>
          </ibep-button>
        </a>
        <!-- Account button  -->
        <a
          [routerLink]="'/' + 'ROUTES.account' | localize"
          *ngIf="isAuthenticated"
        >
          <ibep-button
            class="text-primary"
            [buttonProps]="{
              pushGtm: true,
              name: 'my_account',
              content: userProfile?.firstName,
              size: 'm-flexible'
            }"
          >
            <span class="whitespace-nowrap">{{ 'MyAccount' | translate }}</span>
            <ibep-person-svg
              class="text-primary pl-1 cursor-pointer"
              svgStyles="w-5 h-5"
            ></ibep-person-svg>
          </ibep-button>
        </a>
      </div>
    </div>
  </div>
</div>
