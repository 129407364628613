import { Component, Input } from '@angular/core';

@Component({
  selector: 'ibep-arrow-right-svg',
  template: `<svg
    ibepHideTillInit
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    class="inline m-auto"
    [ngClass]="svgStyles"
    viewBox="0 0 16 16"
  >
    <path
      fill-rule="evenodd"
      d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
    />
  </svg>`,
  styles: [],
})
export class ArrowRightComponent {
  @Input() svgStyles: string;
}
