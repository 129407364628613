export enum EnvironmentType {
  dev = 'dev',
  prod = 'prod',
  staging = 'staging',
  test = 'test',
}

export interface Environment {
  app: string;
  name: EnvironmentType;
  sentry: boolean;
  production: boolean;
  localWordpress: boolean;
  displayLogInfo: boolean;
  displayPerformanceLogs: boolean;
  recaptchaKey: {
    dev: string;
    staging: string;
    prod: string;
    test: string;
  };
  authApi: {
    dev: string;
    staging: string;
    prod: string;
    test: string;
  };
  backendApi: {
    dev: string;
    staging: string;
    prod: string;
    test: string;
    key: string;
    prodKey: string;
  };
  webSocket: {
    dev: string;
    staging: string;
    prod: string;
    test: string;
    key: string;
    prodKey: string;
  };
  redis: {
    initRedis: boolean;
    host: string;
    port: number;
  };
  serviceWorker: {
    initServiceWorker: boolean;
    file: string;
    updateInterval: number;
  };
}
