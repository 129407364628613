import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { BaseComponent } from '@ibep/fe/shared/core';
import { ReaderStateSelectedBiblesInterface } from '@ibep/fe/shared/data';
import {
  AbstractEnvironmentService,
  BibleMetadata,
  ScreenSize,
  Testament,
} from '@ibep/interfaces';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, distinctUntilChanged, fromEvent, takeUntil } from 'rxjs';

@Component({
  selector: 'ibep-chapter-picker',
  templateUrl: './chapter-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./chapter-picker.component.scss'],
})
export class ChapterPickerComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  @Input() testaments: Testament[];
  @Input() selectedBibles: ReaderStateSelectedBiblesInterface;
  @Input() selectedChapter: string;
  @Input() bibleMetadata: BibleMetadata;
  @Input() isBrowser: boolean;
  @Input() isBibleReaderPage: boolean;

  @Output() modalClosed = new EventEmitter<boolean>();
  @Output() pushGtmTag = new EventEmitter<object>();

  @ViewChild('searchInput') searchInput: ElementRef;
  public modalViewport: ElementRef<HTMLDivElement>;

  public activeTestament = { abbr: '', index: 0 };
  public activeBook = '';
  public filteredTestaments: Testament[];
  public searchIsActive = false;
  public screenWidth = 0;

  constructor(
    private readonly _cdRef: ChangeDetectorRef,
    @Inject(DOCUMENT)
    private _document: any,
    private readonly _environmentService: AbstractEnvironmentService,
    private readonly _translateService: TranslateService
  ) {
    super();
  }

  ngOnInit(): void {
    this._environmentService.windowWidth$
      .pipe(takeUntil(this._destroy))
      .subscribe((width) => {
        if (width) {
          this.screenWidth = width;
        }
      });
    // set active book if you come from the bible reader page
    if (this.isBibleReaderPage) {
      this.activeBook = this.selectedChapter.split('.')[0];
    }

    // set first testament as the active tab
    this.setDefaultTestament();

    // by default we show all testaments (not filtered)
    this.filteredTestaments = this.testaments;

    if (!this.selectedBibles || !this.selectedBibles.bibleIds) {
      this.selectedBibles = {
        bibleAbbrs: [this.bibleMetadata.abbreviation],
        bibleIds: [this.bibleMetadata.id],
      };
    }
    if (!this.selectedChapter) {
      this.selectedChapter =
        this.bibleMetadata.testaments[0].books[0].chapters[0].id;
    }

    this._cdRef.detectChanges();
  }

  ngAfterViewInit() {
    // Listen for search queries
    fromEvent(this.searchInput.nativeElement, 'keyup')
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((res) => {
        this.searchIsActive = !!this.searchInput.nativeElement.value;
        this._filterBooks(
          this.searchInput.nativeElement.value,
          this.testaments
        );
      });

    if (this.isBibleReaderPage) {
      // without timeout it somehow breaks layout of testaments in Mac, Safary
      setTimeout(() => {
        this.scrollToElement(
          this.activeBook,
          'book',
          this.activeTestament.index
        );
      }, 0);
    }
  }

  // set the active book
  public setActiveBook(bookId: string) {
    this.activeBook = this.activeBook === bookId ? '' : bookId;

    this.onGtmTagPush({
      event: 'book_picker',
      bible_book: bookId,
      bible_translation: this.bibleMetadata.abbreviation,
    });
  }

  selectChapter(chapterId: string, chapterNumber: string): void {
    this.onGtmTagPush({
      event: 'button_click',
      button_name: 'chapter_selector_home',
      button_url: `/ROUTES.bible/${this.selectedBibles.bibleAbbrs.join(
        ','
      )}/${this._translateService.instant(chapterId)}`,
      button_text: chapterNumber,
    });

    setTimeout(() => {
      this.closeModal();
    }, 100);
  }

  // scroll to element (top of testament OR book)
  public scrollToElement(
    elementId: string,
    elementType: 'testament' | 'book',
    testamentIndex: number
  ) {
    let scrollPosition = 0;
    if (testamentIndex !== 0 || elementType === 'book') {
      const el = this._document.getElementById(elementId);
      scrollPosition =
        el.offsetTop -
        (testamentIndex === 2
          ? 205
          : this.screenWidth < ScreenSize.xl
          ? 175
          : 245);

      if (elementType === 'book' && testamentIndex > 0) {
        scrollPosition = scrollPosition - 40;
      }
    }
    // get the modalViewport element by its id
    const modalViewport = this._document.getElementById('modalViewport');
    modalViewport.scrollTop = Math.max(0, scrollPosition);
  }

  /**
   * calculate the position for the testament menu item
   *
   * @param {('top1' | 'top2' | 'bottom1' | 'bottom2')} position
   * @param {number} index
   * @returns
   * @memberof ChapterPickerComponent
   */
  public checkPositionIsActive(
    position:
      | 'top1'
      | 'top2'
      | 'top3'
      | 'bottom1'
      | 'bottom2'
      | 'borderTop'
      | 'borderBottom',
    index: number
  ) {
    if (position === 'top1' && index === 0) {
      return true;
    }
    if (position === 'top2' && index === 1 && this.activeTestament.index > 0) {
      return true;
    }
    if (
      position === 'top3' &&
      index === 2 &&
      this.activeTestament.index === 2
    ) {
      return true;
    }
    if (
      (position === 'bottom1' &&
        index === 1 &&
        this.activeTestament.index === 0 &&
        this.testaments.length === 2) ||
      (position === 'bottom1' &&
        index === 2 &&
        this.activeTestament.index !== 2)
    ) {
      return true;
    }

    if (
      position === 'bottom2' &&
      index === 1 &&
      this.activeTestament.index === 0 &&
      this.testaments.length === 3
    ) {
      return true;
    }

    if (
      (position === 'borderTop' &&
        index === 1 &&
        this.activeTestament.index === 0 &&
        this.testaments.length === 3) ||
      (position === 'borderTop' &&
        index === 2 &&
        this.activeTestament.index === 1 &&
        this.testaments.length === 3) ||
      (position === 'borderTop' &&
        index === 1 &&
        this.activeTestament.index === 0 &&
        this.testaments.length === 2)
    ) {
      return true;
    }

    if (
      (position === 'borderBottom' &&
        index === 0 &&
        this.activeTestament.index === 0) ||
      (position === 'borderBottom' &&
        index === 1 &&
        this.activeTestament.index > 0)
    ) {
      return true;
    }

    return false;
  }

  // Search: filter the bible books
  private _filterBooks(keyword: string, oldTestaments: any) {
    // make deep copy of testaments
    const testaments = JSON.parse(JSON.stringify(oldTestaments));
    const searchResult = testaments
      // filter the testaments
      .filter((testament: Testament) => {
        const filteredTestament = testament.books.filter((book: any) =>
          book.name.toLowerCase().includes(keyword.toLowerCase())
        );
        return !!filteredTestament.length;
      })
      // filter the books
      .map((testament: Testament) => {
        testament.books = testament.books.filter((book: any) =>
          book.name.toLowerCase().includes(keyword.toLowerCase())
        );
        return testament;
      });

    this.filteredTestaments = searchResult;
    this.activeTestament = {
      abbr: this.filteredTestaments[0]?.abbreviation,
      index: 0,
    };
    // we need to manually detect the changes
    this._cdRef.detectChanges();
  }

  private setDefaultTestament(): void {
    if (this.isBibleReaderPage) {
      const testamentIndex = this.testaments.findIndex((testament) => {
        return testament.books.find((book) => book.id === this.activeBook);
      });
      this.activeTestament = {
        abbr: this.testaments[testamentIndex].abbreviation,
        index: testamentIndex,
      };
    } else {
      this.activeTestament = {
        abbr: this.testaments[0].abbreviation,
        index: 0,
      };
    }
  }

  public isVisible(abbr: string, index: number) {
    this.activeTestament = { abbr, index };
  }

  public closeModal() {
    this.modalClosed.emit(true);
  }

  public onGtmTagPush(tag: object): void {
    this.pushGtmTag.emit(tag);
  }
}
