import { Component } from '@angular/core';

@Component({
  selector: 'bible-pmr',
  template: `
    <p class="pmr">
      <ng-content></ng-content>
    </p>
  `,
  styleUrls: ['./pmr.component.scss'],
})
export class PmrComponent {}
