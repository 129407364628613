import { Component } from '@angular/core';

@Component({
  selector: 'bible-pc',
  template: `
    <p class="pc">
      <ng-content></ng-content>
    </p>
  `,
  styleUrls: ['./pc.component.scss'],
})
export class PcComponent {}
