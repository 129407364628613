import { Location } from '@angular/common';
import { Routes } from '@angular/router';
import {
  LocalizeParser,
  LocalizeRouterSettings,
} from '@gilsdav/ngx-translate-router';
import { ConfigData } from '@ibep/fe/shared/data';
import { AvailableLanguage, Config } from '@ibep/interfaces';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';

/**
 * Config interface
 */
export interface ILocalizeRouterParserConfig {
  locales: string[];
  prefix?: string;
  escapePrefix?: string;
}

export class LocalizeRouterTranslationLoader extends LocalizeParser {
  constructor(
    translate: TranslateService,
    location: Location,
    settings: LocalizeRouterSettings,
    private configData: ConfigData
  ) {
    super(translate, location, settings);
  }

  load(routes: Routes): Promise<any> {
    return new Promise(
      (resolve: (val: any) => void, reject: (val: any) => void) => {
        // Get the available languages from the Brand config
        this.configData
          .getConfig()
          .pipe(
            map((config: Config) => {
              // All available languages except the default language
              const availableLangs: AvailableLanguage['languageCode'][] =
                config.brand.availableLanguages
                  .map((langObj) => langObj.languageCode)
                  .filter((lang) => lang !== config.brand.defaultLanguageCode);
              // Return like this to make sure the default language has index 0
              return [config.brand.defaultLanguageCode].concat(availableLangs);
            })
          )
          .subscribe({
            next: (languages: string[]) => {
              this.locales = languages;
              // This would prefix all URL parts
              this.prefix = '';
              this.init(routes).then(resolve);
            },
            error: (err) => {
              reject(err);
            },
          });
      }
    );
  }
}
