import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

/**
 * This directive uses the Intersection Observer API. Calculations by this API are not performed on the js main thread.
 * So they are not blocking. The callbacks are running on the main thread.
 * @export
 * @class ObserveVisibilityDirective
 * @implements {OnDestroy}
 * @implements {OnInit}
 * @implements {AfterViewInit}
 */
@Directive({
  selector: '[observeVisibility]',
})
export class ObserveVisibilityDirective
  implements OnDestroy, OnInit, AfterViewInit
{
  /**
   * Threshold can be 0 to 1.0
   * We use a low threshold, so the element has to be only for 0.01 percent inside the scoll area
   * @memberof ObserveVisibilityDirective
   */
  @Input() threshold = 0.01;
  @Input() root: string;
  @Input() rootMargin = '0px';
  @Input() isBrowser: boolean;
  @Output() visible = new EventEmitter<HTMLElement>();

  private observer?: IntersectionObserver;
  private subject$ = new Subject<{
    entry: IntersectionObserverEntry;
    observer: IntersectionObserver;
  }>();

  constructor(
    private element: ElementRef,
    @Inject(DOCUMENT)
    private document: any
  ) {}

  ngOnInit(): void {
    this._createObserver();
  }

  ngAfterViewInit(): void {
    this._startObservingElement();
  }

  /**
   * Create the intersection Observer
   * see https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_AP
   * for explanation and configuration
   * @private
   * @memberof ObserveVisibilityDirective
   */
  private _createObserver(): void {
    if (this.isBrowser) {
      // options object
      const options: {
        root?: HTMLDivElement | null;
        rootMargin: string;
        threshold: number;
      } = {
        rootMargin: this.rootMargin,
        threshold: this.threshold,
      };

      if (this.root) {
        options.root = this.document.getElementById(this.root);
      } else {
        options.root = null;
      }

      // create observer, define the callback
      this.observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry: IntersectionObserverEntry) => {
          // check if the element is intersecting or intersectionRatio is above the threshold
          // we dont want to trigger the subject on page load for all visible elements
          // that is why we make sure the intersectionration is smaller than 1
          if (
            (entry.isIntersecting && entry.intersectionRatio < 1) ||
            (entry.intersectionRatio >= this.threshold &&
              entry.intersectionRatio < 1)
          ) {
            this.subject$.next({ entry, observer });
          }
        });
      }, options);
    }
  }

  /**
   * Start observing the HTML element this directive is placed on
   *
   * @private
   * @returns
   * @memberof ObserveVisibilityDirective
   */
  private _startObservingElement(): void {
    if (!this.observer) {
      return;
    }
    // Start observing
    this.observer.observe(this.element.nativeElement);
    // subcribe to the subject
    this.subject$.pipe(filter(Boolean)).subscribe(({ entry }: any) => {
      const target = entry.target as HTMLElement;
      // emit value outside directive
      this.visible.emit(target);
    });
  }

  private _removeObserver(): void {
    if (this.observer) {
      this.observer.disconnect();
      this.observer = undefined;
    }

    this.subject$.complete();
  }

  /**
   * Remove the observer on destroy
   *
   * @memberof ObserveVisibilityDirective
   */
  ngOnDestroy(): void {
    this._removeObserver();
  }
}
