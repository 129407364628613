<div class="grid grid-cols-1 lg:grid-cols-2 my-8 gap-12 lg:gap-8 px-5 lg:px-0">
  <div class="" *ngIf="informationContent.items[0]">
    <h2
      *ngIf="informationContent.items[0]?.title"
      class="text-2xl font-bold text-grayDark"
    >
      {{ informationContent.items[0].title }}
    </h2>

    <div *ngIf="informationContent.items[0]?.text" class="my-6">
      <ibep-paragraph
        [html]="informationContent.items[0].text"
      ></ibep-paragraph>
    </div>

    <ibep-link
      *ngIf="informationContent.items[0].showButton"
      [linkProps]="{
        link:
          informationContent.items[0].linkType === 'internal'
            ? informationContent.items[0].linkInternal
            : informationContent.items[0].linkExternal,
        isExternalLink: informationContent.items[0].linkType === 'external',
        linkStyles: 'h-10 text-xs text-grayMedium'
      }"
    >
      <ibep-button
        [buttonProps]="{
          pushGtm: true,
          content: informationContent.items[0].buttonTitle,
          link:
            informationContent.items[0].linkType === 'internal'
              ? informationContent.items[0].linkInternal
              : informationContent.items[0].linkExternal,
          style: 'secondary',
          size: 'xl'
        }"
      >
        <b class="text-xs px-16 line-clamp-1">{{
          informationContent.items[0].buttonTitle
        }}</b>
      </ibep-button>
    </ibep-link>
  </div>
  <div class="" *ngIf="informationContent.items[1]">
    <h2
      *ngIf="informationContent.items[1]?.title"
      class="text-2xl font-bold text-grayDark"
    >
      {{ informationContent.items[1].title }}
    </h2>

    <div *ngIf="informationContent.items[1]?.text" class="my-6">
      <ibep-paragraph
        [html]="informationContent.items[1].text"
      ></ibep-paragraph>
    </div>

    <ibep-link
      *ngIf="informationContent.items[1]?.showButton"
      [linkProps]="{
        link:
          informationContent.items[1].linkType === 'internal'
            ? informationContent.items[1]?.linkInternal
            : informationContent.items[1]?.linkExternal,
        isExternalLink: informationContent.items[1].linkType === 'external',
        linkStyles: 'h-10 text-xs text-grayMedium no-underline'
      }"
    >
      <ibep-button
        [buttonProps]="{
          pushGtm: true,
          name: 'contact_us',
          content: informationContent.items[1].buttonTitle,
          link:
            informationContent.items[1].linkType === 'internal'
              ? informationContent.items[1]?.linkInternal
              : informationContent.items[1]?.linkExternal,
          style: 'secondary',
          size: 'xl'
        }"
      >
        <b class="text-xs px-16">{{
          informationContent.items[1].buttonTitle
        }}</b>
      </ibep-button></ibep-link
    >
  </div>
</div>
