import { Injectable } from '@angular/core';
import {
  AbstractBackendService,
  AbstractEnvironmentService,
  AbstractLanguageService,
  AbstractStorageService,
} from '@ibep/interfaces';
import { HOUR } from '@ibep/shared/util';
import { Observable, of } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';
import { ConfigData } from '..';
import { Store, StoreSettings, StoreSettingsTTL } from './store';

@Injectable({
  providedIn: 'root',
})
export class LtSundayData extends Store<any> {
  constructor(
    storage: AbstractStorageService,
    environment: AbstractEnvironmentService,
    backendService: AbstractBackendService,
    languageService: AbstractLanguageService,
    config: ConfigData
  ) {
    super(storage, environment, backendService, languageService, {
      storeTtl: { default: 1 * HOUR } as StoreSettingsTTL,
      storeKey: 'ltSunday',
      brand$: config.getBrand(),
    } as StoreSettings);
  }

  /**
   * Get the lt sunday data
   *
   * @returns {Observable<LtSunday>}
   * @memberof LtSundayData
   */

  public getSunday(slug: string): Observable<any> {
    const endPoint = `content/lt/sundays/${slug}?language=${this._currentLanguage}`;

    const cacheKey = endPoint;

    this.remoteDataMap$[cacheKey] = this.backendService.get<any>({
      endpoint: endPoint,
      options: {
        headers: {
          'x-brand': this.brand.id,
          redisTTL: `${this.storeSettings.storeTtl.default}`,
        },
      },
    });

    return this.localData$.pipe(
      take(1),
      switchMap((localData) => {
        if (localData?.[cacheKey]) {
          return of(localData[cacheKey]);
        }

        return this.remoteDataMap$[cacheKey].pipe(
          tap((remoteData: any) => {
            // write returned data from api to the store
            this.setState(
              {
                [cacheKey]: remoteData,
              },
              true
            );
          })
        );
      })
    );
  }
}
