import { Injectable } from '@angular/core';
import { ConfigLocalizedData } from '@ibep/fe/shared/data';
import { AbstractStorageService } from '@ibep/interfaces';
import { MONTH } from '@ibep/shared/util';
import { map, Observable, of, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AccountTriggerBarService {
  private readonly _key = `accountTriggerBar`;
  private readonly _ttl = 1 * MONTH;

  constructor(
    protected readonly _storage: AbstractStorageService,
    private readonly _configData: ConfigLocalizedData
  ) {}

  closeBar(): void {
    this._storage.set(this._key, { hidden: true }, this._ttl);
  }

  isBarHidden(): Observable<boolean> {
    return this._configData.getAccountSettings().pipe(
      switchMap((accountSettings) => {
        if (accountSettings?.triggerBar?.visible) {
          return this._storage
            .get<{ hidden: true }>(this._key)
            .pipe(map((data) => Boolean(data?.hidden)));
        }
        return of(true);
      })
    );
  }
}
