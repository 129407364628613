import { Injectable } from '@angular/core';
import {
  AbstractBackendService,
  AbstractEnvironmentService,
  AbstractLanguageService,
  AbstractStorageService,
  ReadingPlansTagsResponse,
  ResponseData,
  SearchCategory,
  Tag,
} from '@ibep/interfaces';
import { HOUR } from '@ibep/shared/util';
import { Observable, of } from 'rxjs';
import { map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { ConfigData } from './config.data';
import { Store, StoreSettings, StoreSettingsTTL } from './store';

@Injectable({
  providedIn: 'root',
})
export class ReadingPlansTagsData extends Store<{ [key: string]: Tag[] }> {
  constructor(
    storage: AbstractStorageService,
    environment: AbstractEnvironmentService,
    backendService: AbstractBackendService,
    languageService: AbstractLanguageService,
    config: ConfigData
  ) {
    super(storage, environment, backendService, languageService, {
      storeTtl: { default: 1 * HOUR } as StoreSettingsTTL,
      storeKey: 'tags',
      brand$: config.getBrand(),
    } as StoreSettings);
  }

  /**
   * Get the bible search results
   *
   * @returns {Observable<Tag[]>}
   * @memberof ReadingPlansTagsData
   */
  public getTags(language: string): Observable<Tag[]> {
    this.remoteDataMap$[SearchCategory.READING_PLANS] = this.backendService
      .get<ResponseData<ReadingPlansTagsResponse>>({
        endpoint: 'content/reading-plans/tags',
        options: {
          params: {
            language: encodeURIComponent(this._currentLanguage),
          },
          headers: {
            'x-brand': this.brand.id,
            Authorization: 'Bearer anonymous',
            redisTTL: `${this.storeSettings.storeTtl.default}`,
          },
        },
      })
      .pipe(
        map(
          (response: ResponseData<ReadingPlansTagsResponse>) =>
            response.data.tags
        ),
        shareReplay(1)
      );

    return this.localData$.pipe(
      take(1),
      switchMap((localData: any) => {
        // check if there is local data available
        if (localData?.[SearchCategory.READING_PLANS]) {
          return of(localData[SearchCategory.READING_PLANS]);
        }
        // if no local data, fetch data from api
        return this.remoteDataMap$[SearchCategory.READING_PLANS].pipe(
          tap((remoteData: Tag[]) => {
            // write returned data from api to the store
            this.setState(
              {
                [SearchCategory.READING_PLANS]: remoteData,
              },
              true
            );
          })
        ) as Observable<Tag[]>;
      })
    );
  }
}
