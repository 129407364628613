import { Injectable } from '@angular/core';
import {
  AbstractBackendService,
  AbstractEnvironmentService,
  AbstractLanguageService,
  AbstractStorageService,
} from '@ibep/interfaces';
import { MONTH } from '@ibep/shared/util';
import { combineLatest, Observable, of } from 'rxjs';
import { switchMap, take, tap, shareReplay, map } from 'rxjs/operators';
import { ConfigData } from '..';
import { Store, StoreSettings, StoreSettingsTTL } from './store';

export interface UserHighlight {
  bibleId: string;
  verseIds: string[];
  color: string | undefined;
  highlightId?: string;
}

export const highlightNameToHexrMap = new Map<string, string>([
  ['yellow', '#FFF598'],
  // #fff598
  ['orange', '#FF885F'],
  // #ff885f
  ['green', '#A5D6A6'],
  // #a4d6a6
  ['blue', '#80D5FC'],
  // #7fd5fc
]);

@Injectable({
  providedIn: 'root',
})
export class UserHighlightsData extends Store<any> {
  private _updatedHighlights: any[] = [];

  constructor(
    storage: AbstractStorageService,
    environment: AbstractEnvironmentService,
    backendService: AbstractBackendService,
    languageService: AbstractLanguageService,
    config: ConfigData
  ) {
    super(storage, environment, backendService, languageService, {
      storeTtl: { default: 1 * MONTH } as StoreSettingsTTL,
      storeKey: 'userHighlight',
      brand$: config.getBrand(),
      doNotStoreLocally: true,
    } as StoreSettings);
  }

  /**
   * Get user highlights for a chapter, user notes are connected to a specific bible translation
   *
   * @returns {Observable<any>}
   * @memberof UserHighlightsData
   */
  public get({
    bibleId,
    chapterId,
  }: {
    bibleId: string;
    chapterId: string;
  }): Observable<any> {
    const remoteData$ = this.backendService
      .get<any>({
        endpoint: `user/highlights/${bibleId}/${chapterId}`,
        options: {
          headers: {
            'x-brand': this.brand.id,
          },
        },
      })
      .pipe(shareReplay(1));

    return this.localData$.pipe(
      switchMap((localData) => {
        // if no local data, fetch data from api
        return remoteData$.pipe(
          map((remoteData: any) => {
            return remoteData.data;
          })
        );
      })
    );
  }

  /**
   * Store an user highlight
   *
   * @param {{
   *     bibleId: string;
   *     verseIds: string[];
   *     color: string;
   *   }} {
   *     bibleId,
   *     verseIds,
   *     color,
   *   }
   * @returns
   * @memberof UserHighlightsData
   */
  public add({
    bibleId,
    verseIds,
    color,
  }: {
    bibleId: string;
    verseIds: string[];
    color: string;
  }) {
    const highlight: UserHighlight = {
      bibleId,
      verseIds,
      color: highlightNameToHexrMap.get(color),
    };

    const postHighlights$ = this.backendService.post<any>({
      endpoint: `user/highlights`,
      options: {
        body: highlight,
        headers: {
          'x-brand': this.brand.id,
        },
      },
    });

    return this.localData$.pipe(
      take(1),
      switchMap((localData) => combineLatest([postHighlights$, of(localData)]))
    );
  }

  /**
   * Update an user highlight
   *
   * @param {{ highlightId: string; color: string }} { highlightId, color }
   * @returns
   * @memberof UserHighlightsData
   */
  public update({
    highlightId,
    color,
  }: {
    highlightId: string;
    color: string;
  }) {
    const highlight = {
      highlightId,
      color: highlightNameToHexrMap.get(color),
    };
    const updateHighlight$ = this.backendService
      .put<any>({
        endpoint: `user/highlights`,
        options: {
          body: highlight,
          headers: {
            'x-brand': this.brand.id,
          },
        },
      })
      .pipe(
        shareReplay(1),
        map((res) => {
          res.data.color = highlightNameToHexrMap.get(color);
          return res;
        })
      );

    return this.localData$.pipe(
      take(1),
      switchMap((localData) => combineLatest([updateHighlight$, of(localData)]))
    );
  }

  public storeHighlights(data: object) {
    this.setState(data, true);
  }

  /**
   * Delete an user highlight
   *
   * @param {{ highlightId: string }} { highlightId }
   * @returns
   * @memberof UserHighlightsData
   */
  public delete({
    bibleId,
    chapterId,
    highlightId,
  }: {
    bibleId?: string;
    chapterId?: string;
    highlightId: string;
  }) {
    const key = `${bibleId}-${chapterId}`;
    const deleteHighlights$ = this.backendService.delete<any>({
      endpoint: `user/highlights/${highlightId}`,
      options: {
        headers: {
          'x-brand': this.brand.id,
        },
      },
    });

    return this.localData$.pipe(
      take(1),
      switchMap((localData) =>
        combineLatest([deleteHighlights$, of(localData)])
      ),
      tap(([remoteData, oldState]) => {
        if (bibleId && chapterId) {
          const newState = oldState;
          newState[key] = newState[key].filter(
            (highlight) => highlight.highlightId !== highlightId
          );
          // write returned data from api to the store
          this.setState(
            {
              ...newState,
            },
            true
          );
        }
      })
    );
  }
}
