import { DOCUMENT } from '@angular/common';
import {
  Inject,
  Injectable,
  Optional,
  Renderer2,
  RendererFactory2,
} from '@angular/core';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { capitalizeFirstChar } from '@ibep/shared/util';
import { AbstractLanguageService, Config, UserInfo } from '@ibep/interfaces';
import { TranslateService } from '@ngx-translate/core';
import { where } from 'langs';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { ConfigData } from '@ibep/fe/shared/data';
import { WINDOW } from '../../providers/window.provider';
import { Auth } from '@ibep/fe/web/module-auth';

// ISO-639 consists of 'parts', each of which is a standard for language codes
type Iso639Part = '1' | '2' | '2B' | '3';

/**
 * Service to handle the language of the application.
 */
@Injectable({
  providedIn: 'root',
})
export class LanguageService implements AbstractLanguageService {
  /**
   * Private behavioursubject that holds the current active language
   *
   * @private
   * @memberof LanguageService
   */
  private _currentLanguage = new BehaviorSubject<string>(
    this.localize.parser.currentLang
  );

  /**
   * Public accessible observable, subscribe to changes of the active language from other parts of the application
   *
   * @memberof LanguageService
   */
  public readonly currentLanguage$ = this._currentLanguage.asObservable();

  /**
   * Property holds a reference to the custom DOM renderer, so we can inject HTML into the DOM
   *
   * @private
   * @type {Renderer2}
   * @memberof LanguageService
   */
  private _renderer: Renderer2;
  private _config: Config;

  constructor(
    private localize: LocalizeRouterService,
    private translateService: TranslateService,
    private rendererFactory: RendererFactory2,
    private configData: ConfigData,
    @Optional()
    @Inject(DOCUMENT)
    private document: Document,
    @Inject(WINDOW) private readonly _window: Window
  ) {
    this._renderer = this.rendererFactory.createRenderer(null, null);

    combineLatest([
      this.configData.getConfig(),
      // event that fires when the localize router is initialized
      localize.hooks.initialized,
    ]).subscribe(([config, isInit]) => {
      this._config = config;
      if (!this._currentLanguage.getValue()) {
        const lang = this.localize.parser.currentLang
          ? this.localize.parser.currentLang
          : config.brand.defaultLanguageCode;
        // set the current language to lozalize parser language or to the default brand language.
        this._currentLanguage.next(lang);
        // we need to know the currentlanguage in the configData service, so we can fetch the correct config
        // this.configData.currentLanguage$.next(lang);
      }
    });

    // When the language changes ..
    this._currentLanguage
      .pipe(
        filter((lang) => !!lang),
        switchMap((lang) => {
          // fetch the translations for the language, if not already fetched
          const getTranslation$ =
            this.translateService.currentLang !== lang
              ? this.translateService.getTranslation(lang)
              : of(null);
          return combineLatest([of(lang), getTranslation$]);
        })
      )
      .subscribe(([lang, translation]) => {
        // change the language of the localized router
        this.localize.changeLanguage(lang);

        // Set the lang property on the <html> element so browsers will show the 'do you want to translate this site?' popup
        const htmlElements = this.document.getElementsByTagName('html');
        if (htmlElements && htmlElements.length > 0) {
          this._renderer.setProperty(htmlElements[0], 'lang', lang);
        }
      });
  }

  public changeLanguage(
    lang: string | undefined,
    userInfo: UserInfo | null,
    userAuth: Auth | null
  ): void {
    // temporary store auth and user profile info in the local storage. Because the indexedDB store is connected to a language the user profile and auth data is lost.
    if (userInfo) {
      this._window.localStorage.userInfo = JSON.stringify(userInfo);
    }
    if (userAuth) {
      this._window.localStorage.userAuth = JSON.stringify(userAuth);
    }
    // redirect to the user preferred language
    if (lang && typeof lang === 'string') {
      this._window.location.assign(
        `/${lang === this._config.brand.defaultLanguageCode ? '' : lang}`
      );
    }
  }

  /**
   * Set the current active language
   *
   * @memberof LanguageService
   */
  public set currentLanguage(val: string) {
    this._currentLanguage.next(val);
  }

  /**
   * Get the current active language
   *
   * @memberof LanguageService
   */
  public get currentLanguage(): string {
    return this._currentLanguage.value;
  }

  /**
   * check if current language is the default language
   *
   * @memberof LanguageService
   */
  public get isDefaultLanguage(): boolean {
    return (
      this._config.brand.defaultLanguageCode === this._currentLanguage.value
    );
  }

  /**
   * Takes a language code and returns the full name of that language.
   * @param lang the language code
   * @param iso the ISO-639 part of the provided langcode. Defaults to 1.
   * @param local if set to true, the return value is in the local language (e.g. 'Nederlands' vs. 'Dutch')
   */
  public getLanguageName(
    lang: string,
    local = false,
    iso: Iso639Part = '1'
  ): string {
    let isoToUse: Iso639Part = iso;

    // fix: sometimes when iso = '3', it still comes back (from the 'entitlements') as iso 1.
    // so we do a quick fix.
    if (iso === '3' && lang.length === 2) {
      isoToUse = '1';
    }

    if (lang === 'nso') {
      return local ? 'Sepedi' : 'Northern Sotho';
    }
    // now find the language.
    const language = where(isoToUse, lang);

    // if a local language hasn't been found, we need to return an empty string for error handling.
    if (language === undefined) {
      return '';
    }

    if (lang === 'ss' && local) {
      return 'Siswati';
    }

    return local
      ? capitalizeFirstChar(language.local)
      : capitalizeFirstChar(language.name);
  }
}
