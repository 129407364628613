import { Component, Input } from '@angular/core';

@Component({
  selector: 'bible-ms',
  templateUrl: './ms.component.html',
  styleUrls: ['./ms.component.scss'],
})
export class MsComponent {
  @Input() item: any;
}
