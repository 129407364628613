import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TriggerBarSettings } from '@ibep/interfaces';

@Component({
  selector: 'ibep-account-trigger-bar-content',
  templateUrl: './account-trigger-bar-content.component.html',
})
export class AccountTriggerBarContentComponent {
  @Input() triggerBarSettings?: TriggerBarSettings;

  @Output() closeBar = new EventEmitter();
  @Output() handleOpenModal: EventEmitter<string> = new EventEmitter();
  @Output() pushGtmTag = new EventEmitter<object>();

  onCloseBar(): void {
    this.closeBar.emit();
  }

  public openModal(type: string): void {
    this.handleOpenModal.emit(type);
  }

  public onGtmTagPush(tag: object): void {
    this.pushGtmTag.emit(tag);
  }
}
