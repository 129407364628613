import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'ibep-content-row',
  templateUrl: './content-row.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentRowComponent {
  @Input() props: {
    attrs: any;
  };
}
