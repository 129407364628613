<div class="relative pb-20 text-grayMedium text-left" *ngIf="page">
  <a
    [href]="'/ROUTES.ebc/redirect/slug/' + page.slug | localize"
    target="_blank"
    class="flex justify-end text-lg text-secondary hover:text-primary group"
  >
    <span>{{ 'openThisArticle*' | translate }}</span>
    <ibep-box-arrow-up-right-svg
      class="pl-1 text-secondary group-hover:text-primary"
      svgStyles="w-4 h-4"
    ></ibep-box-arrow-up-right-svg>
  </a>
  <div>
    <span
      *ngFor="let tag of page.tags"
      [innerHTML]="tag.name"
      class="inline-block bg-grayExtraLight text-xs px-2 py-1 lg:opacity-70 lg:bg-opacity-10 rounded-lg mr-2"
    ></span>
  </div>

  <div class="text-left lg:pb-4 h-full relative">
    <h1 class="font-bold text-3xl" [innerHTML]="page.title"></h1>
    <!-- image  -->
    <div #imageContainer *ngIf="page.image" class="mr-4 mb-4 w-full">
      <ibep-image-picture
        *ngIf="page.image"
        [imgStyles]="'w-full object-cover pb-2'"
        [image]="page.image"
        [containerWidth]="imageContainer?.clientWidth"
      ></ibep-image-picture>
    </div>
    <!-- Render the WP Gutenberg Blocks -->
    <ibep-render-gutenberg-blocks
      [blocks]="page?.gblocks"
      [noMaxWidth]="true"
      [formatEbcLinks]="true"
      (closeModal)="closeModal(true)"
    ></ibep-render-gutenberg-blocks>
  </div>
</div>
<!-- Button toolbar  -->
<div
  class="fixed lg:absolute bottom-0 left-0 w-full grid p-4 lg:p-0 bg-white"
  [ngClass]="{
    'grid-cols-2': tab === 'default',
    'grid-cols-1': tab === 'share'
  }"
>
  <ng-container *ngIf="tab === 'default'">
    <ibep-button
      [buttonProps]="{
        link: '',
        content: 'close',
        style: 'default',
        size: 'm',
        fullWidth: true
      }"
      (click)="closeModal()"
      class="text-secondary font-bold mr-2"
    >
      {{ 'CloseThisWindow' | translate }}
    </ibep-button>
    <ibep-button
      [buttonProps]="{
        link: '',
        content: 'share',
        style: 'default',
        size: 'm',
        fullWidth: true
      }"
      (click)="tab = 'share'"
      class="text-secondary font-bold"
    >
      {{ 'Share' | translate }}
    </ibep-button>
  </ng-container>
  <!-- share options  -->
  <ng-container *ngIf="tab === 'share'">
    <div class="grid grid-cols-3 gap-2 w-full">
      <!-- Facebook  -->
      <ibep-button
        [buttonProps]="{
          pushGtm: true,
          content: 'facebook',
          size: 'm',
          fullWidth: true
        }"
        (click)="share('facebook')"
        [title]="'Facebook'"
      >
        <ibep-socials-svg
          svgStyles="w-5 h-5 text-grayLight hover:text-primary"
          socialProps="facebook"
        ></ibep-socials-svg>
      </ibep-button>
      <!-- Twitter  -->
      <ibep-button
        [buttonProps]="{
          pushGtm: true,
          content: 'twitter',
          size: 'm',
          fullWidth: true
        }"
        (click)="share('twitter')"
        [title]="'Twitter'"
      >
        <ibep-socials-svg
          svgStyles="w-5 h-5 text-grayLight hover:text-primary"
          socialProps="twitter"
        ></ibep-socials-svg>
      </ibep-button>
      <!-- URL  -->
      <ibep-button
        [buttonProps]="{
          pushGtm: true,
          content: 'url',
          size: 'm',
          fullWidth: true
        }"
        (click)="share('url'); copied = true"
        title="url"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          class="w-6 h-6 text-grayLight hover:text-primary"
          viewBox="0 0 16 16"
        >
          <path
            d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9c-.086 0-.17.01-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z"
          />
          <path
            d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4.02 4.02 0 0 1-.82 1H12a3 3 0 1 0 0-6H9z"
          />
        </svg>
        <svg
          *ngIf="copied"
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="w-6 h-6 inline"
          viewBox="0 0 16 16"
        >
          <path
            d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
          />
        </svg>
      </ibep-button>
    </div>
  </ng-container>
</div>
<ng-container *ngIf="!page">
  <div class="min-h-[130px]">
    <ibep-page-loading></ibep-page-loading>
  </div>
</ng-container>
