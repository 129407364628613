import { Component } from '@angular/core';

/**
 * https://ubsicap.github.io/usx/charstyles.html#bdit
 *
 * @export
 * @class BditComponent
 */
@Component({
  selector: 'bible-bdit',
  templateUrl: './bdit.component.html',
  styleUrls: ['./bdit.component.scss'],
})
export class BditComponent {}
