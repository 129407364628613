import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Episode } from '@ibep/interfaces';

@Component({
  selector: 'ibep-episodes-list',
  templateUrl: './episodes-list.component.html',
})
export class EpisodesListComponent implements OnChanges {
  @Input() episodes: Episode[];
  @Input() planSlug: string;
  @Input() hasFixedDates: boolean;
  @Input() dateFormat: string;
  @Input() tab: string;

  public filteredEpisodes: Episode[];
  public upcomingEpisodesCount: number = 0;
  public archiveEpisodesCount: number = 0;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.tab?.currentValue !== changes.tab?.previousValue) {
      this.filterEpisodes();
    }
  }

  /**
   * Filters the episodes based on the selected tab and updates the filteredEpisodes array.
   */
  filterEpisodes(): void {
    const upcomingEpisodes = this._filterUpcomingEpisodes();
    const archiveEpisodes = this._filterArchiveEpisodes();

    this.upcomingEpisodesCount = upcomingEpisodes.length;
    this.archiveEpisodesCount = archiveEpisodes.length;

    if (this.hasFixedDates) {
      if (this.tab === 'upcoming' && upcomingEpisodes.length > 0) {
        this.filteredEpisodes = upcomingEpisodes;
        this._sortEpisodesByDate(this.filteredEpisodes, { asc: true });
      } else {
        this.filteredEpisodes = archiveEpisodes;
        this._sortEpisodesByDate(this.filteredEpisodes, { asc: false });
      }
    } else {
      this.filteredEpisodes = this.episodes;
      this._sortEpisodesByOrder(this.filteredEpisodes);
    }
  }

  /**
   * Filters the episodes array to return only the upcoming episodes.
   * An episode is considered upcoming if its date is greater than or equal to the current date.
   * @returns An array of upcoming episodes.
   */
  private _filterUpcomingEpisodes(): Episode[] {
    return this.episodes.filter((episode) => {
      const episodeDate = new Date(episode.formattedFixedDate);
      const currentDate = new Date();

      // Set both dates to start of the day (midnight) to ignore time component
      episodeDate.setHours(0, 0, 0, 0);
      currentDate.setHours(0, 0, 0, 0);

      // Compare the dates
      return episodeDate >= currentDate;
    });
  }

  /**
   * Filters the episodes array to only include episodes that are in the past.
   * @returns An array of Episode objects that are in the past.
   */
  private _filterArchiveEpisodes(): Episode[] {
    return this.episodes.filter((episode) => {
      const episodeDate = new Date(episode.formattedFixedDate);
      const currentDate = new Date();

      // Set both dates to start of the day (midnight) to ignore time component
      episodeDate.setHours(0, 0, 0, 0);
      currentDate.setHours(0, 0, 0, 0);

      // Compare the dates
      return episodeDate < currentDate;
    });
  }

  /**
   * Sorts an array of episodes by date.
   *
   * @param array - The array of episodes to be sorted.
   * @param asc - Optional. Specifies whether to sort in ascending order (default: true).
   * @returns The sorted array of episodes.
   */
  private _sortEpisodesByDate(
    array: Episode[],
    { asc = true }: { asc?: boolean } = {}
  ): any[] {
    return array.sort((obj1, obj2) => {
      const date1 = new Date(obj1.formattedFixedDate).getTime();
      const date2 = new Date(obj2.formattedFixedDate).getTime();
      return asc ? date1 - date2 : date2 - date1;
    });
  }

  private _sortEpisodesByOrder(array: Episode[]): any[] {
    return array.sort((obj1, obj2) => {
      return obj1.order - obj2.order;
    });
  }
}
