import { DOCUMENT } from '@angular/common';
import { Inject, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

/**
 * This pipe sanitezes HTML
 */
@Pipe({
  name: 'safeHtml',
})
export class SafeHtmlPipe implements PipeTransform {
  constructor(
    @Inject(DOCUMENT)
    public document: Document,
    private sanitizer: DomSanitizer
  ) {}

  /**
   * Takes the HTML string and passes it on to the sanitizer.
   * @param html
   */
  transform(html: string, contextElement?: HTMLElement): SafeHtml {
    // we implement it to run scripts coming from WordPress content
    let parsedHtml = html.replace(/{%/g, '').replace(/%}/g, '');
    const regex = new RegExp(
      `\<script(.|\n|\r|\u2028|\u2029){1,}\/script\>`,
      'g'
    );

    if (html?.includes('<script')) {
      parsedHtml = html.replace(regex, '');
      const elementsContainer = this.document.createElement('html');
      elementsContainer.innerHTML = html;
      Array.from(elementsContainer.getElementsByTagName('script')).forEach(
        (scriptItem) => {
          const scriptElement = this.document.createElement('script');
          if (scriptItem.type) {
            scriptElement.type = scriptItem.type;
          }
          if (scriptItem.src) {
            scriptElement.src = scriptItem.src.replace('http://', 'https://');
          }

          // this hack is added to prevent the page reload after running document.write
          scriptElement.text = scriptItem.text?.includes('document.write')
            ? scriptItem.text.replace(
                /document\.write/g,
                'document.currentScript.parentElement.innerHTML = '
              )
            : scriptItem.text;

          const parentElement = contextElement || this.document.body;

          setTimeout(() => {
            parentElement.appendChild(scriptElement);
          }, 0);
        }
      );
    }

    return this.sanitizer.bypassSecurityTrustHtml(parsedHtml);
  }
}
