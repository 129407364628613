<ng-container *ngIf="config$ | async; let config">
  <ng-container *ngIf="!config?.isFallback">
    <ibep-footer
      *ngIf="isFooterVisible"
      [appVersion]="appVersion"
      [currentLanguage]="currentLanguage"
      [isDefaultLanguage]="isDefaultLanguage"
      [config]="config"
      (pushStoreTag)="pushStoreTag($event)"
      (pushSocialTag)="pushSocialTag($event)"
    ></ibep-footer>
  </ng-container>
</ng-container>
