import { Component } from '@angular/core';

/**
 * https://ubsicap.github.io/usx/charstyles.html#bd
 *
 * @export
 * @class BdComponent
 */
@Component({
  selector: 'bible-bd',
  templateUrl: './bd.component.html',
  styleUrls: ['./bd.component.scss'],
})
export class BdComponent {}
