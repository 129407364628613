<div class="p-4">
  <div
    class="flex items-center border-grayExtraLight ml-6 border rounded-full lg:ml-0 lg:-mx-4 lg:px-4 lg:pb-2 lg:border-0 lg:border-b lg:rounded-none"
  >
    <div class="w-full h-full relative">
      <ibep-search-svg
        class="absolute left-3 lg:left-0"
        svgStyles="w-4 h-4 mt-1"
      ></ibep-search-svg>
      <form (ngSubmit)="goToSearchResults(searchCategoryEnum.ALL)">
        <input
          #searchInput
          class="autofill:transition-[background-color] autofill:duration-[5000s] outline-none bg-transparent w-full h-8 text-sm pl-8 lg:pl-6 pr-2"
          autocomplete="off"
          [formControl]="searchControl"
          [placeholder]="'SearchTheBible*' | translate"
          [title]="'SearchTheBible*' | translate"
          [value]="searchControl.value"
        />
      </form>
    </div>
    <div
      (click)="clearSearch()"
      class="text-grayMedium text-2xs pr-3 cursor-pointer"
    >
      <span class="hidden lg:inline">{{ 'clear' | translate }}</span>
      <ibep-close-svg svgStyles="w-6 h-6" class="lg:hidden"></ibep-close-svg>
    </div>
    <button
      (click)="closeAndClear()"
      class="hidden lg:inline px-1 ml-auto text-[#C0D0E4] border-l border-grayExtraLight"
    >
      <ibep-close-svg svgStyles="w-6 h-6"></ibep-close-svg>
    </button>
  </div>

  <ng-container *ngIf="isSuggestionLoading">
    <ibep-page-loading></ibep-page-loading>
  </ng-container>

  <ng-container *ngIf="!isSuggestionLoading">
    <div
      *ngFor="let suggestion of items"
      class="py-3 border-b border-grayExtraLight cursor-pointer flex items-center text-left text-xs"
      (click)="goToBibleReader(suggestion, suggestionText)"
    >
      <span #suggestionText [innerHTML]="suggestion.text"></span>
      <span class="text-grayLight italic ml-1 whitespace-nowrap">
        <ng-container [ngSwitch]="suggestion.type">
          <ng-container *ngSwitchCase="0"
            >— {{ 'inBibleBooks' | translate }}</ng-container
          >
          <ng-container *ngSwitchCase="1">
            —
            {{
              'in_VAR'
                | translate
                  : {
                      CHAPTER: suggestion.sourceLocal
                    }
            }}
          </ng-container>
        </ng-container>
      </span>
      <span
        (click)="goToSearchResults(searchCategoryEnum.BIBLE)"
        class="ml-auto px-2 text-grayLight text-xs whitespace-nowrap"
      >
        <span class="hidden lg:inline">{{ 'more' | translate }} </span>
        <ibep-chevron-right-svg
          svgStyles="w-3 h-3 ml-1"
        ></ibep-chevron-right-svg>
      </span>
      <!-- <span
      (click)="openTranslationPicker($event)"
      class="ml-auto px-2 text-grayLight text-xs whitespace-nowrap"
    >
      {{ bibleAbbr }}
      <ibep-chevron-down-svg svgStyles="w-3 h-3 ml-1"></ibep-chevron-down-svg>
    </span> -->
    </div>
  </ng-container>

  <div
    *ngIf="searchControl.value"
    (click)="goToSearchResults(searchCategoryEnum.ALL)"
    class="text-left text-xs border-b border-grayExtraLight relative pl-6 py-3 flex items-center w-full cursor-pointer"
  >
    <ibep-search-svg
      class="absolute left-0"
      svgStyles="w-4 h-4"
    ></ibep-search-svg>
    {{ 'Search' | translate }}<b class="ml-1">'{{ searchControl.value }}'</b>
    <span class="text-grayLight italic ml-1"
      >— {{ 'inBibleAnd*' | translate }}</span
    >
  </div>
</div>

<div
  class="suggestions-bottom-hint p-4 bg-primary bg-opacity-[0.15] text-left text-2xs text-grayMedium m-4 lg:m-0 rounded lg:rounded-none absolute lg:static bottom-24 w-[calc(100%-2rem)] lg:w-full"
  [innerHTML]="'SearchSuggestionsBottomHint' | translate"
></div>
