<div class="my-8">
  <div
    class="bg-primary bg-opacity-10 rounded-t-xl"
    *ngIf="
      errors ||
      (form.invalid &&
        (username.dirty || username.touched) &&
        (password.dirty || password.touched))
    "
  >
    <div class="px-2 py-4">
      <div class="text-left text-xs flex gap-2">
        <div class="text-red-500">
          <ibep-exclamation-svg
            svgStyles="w-4 h-4"
            class="inline"
          ></ibep-exclamation-svg>
        </div>
        <div>
          <h4 class="font-bold mb-1">
            {{ 'AlmostReadyBut*' | translate }}
          </h4>
          <p *ngIf="!errors">{{ 'PleaseCheckTheHighlighted*' | translate }}</p>

          <p *ngIf="errors" [innerHTML]="errors.code | translate"></p>
        </div>
      </div>
    </div>
  </div>

  <form (ngSubmit)="onSubmit()" class="space-y-6" [formGroup]="form">
    <h1 class="text-3xl font-bold text-left text-grayDark pb-3">
      {{ 'LoginToYour*' | translate }}
    </h1>
    <div>
      <label
        for="username"
        class="block text-xs font-bold text-grayMedium text-left pb-1"
      >
        {{ 'EmailAddress' | translate }}
      </label>
      <div class="mt-1">
        <input
          #usernameInput
          formControlName="username"
          id="username"
          name="username"
          type="username"
          autocomplete="username"
          required
          class="appearance-none block w-full px-3 py-2 border border-grayLight rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
        />
      </div>
      <div
        *ngIf="
          document.activeElement !== usernameInput &&
          username.invalid &&
          (username.dirty || username.touched)
        "
        class="text-red-500 text-left py-2 text-2xs"
      >
        <div *ngIf="username.errors?.['required']">
          {{ 'PleaseFillInEmail*' | translate }}
        </div>
        <div *ngIf="username.errors?.['email']">
          {{ 'WeDoNot*' | translate }}
        </div>
      </div>
    </div>

    <div>
      <label
        for="password"
        class="block text-xs font-bold text-grayMedium text-left pb-1"
      >
        {{ 'Password' | translate }}
      </label>
      <div class="mt-1">
        <input
          #passwordInput
          formControlName="password"
          id="password"
          name="password"
          type="password"
          autocomplete="current-password"
          required
          class="appearance-none block w-full px-3 py-2 border border-grayLight rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
        />
      </div>
      <div
        *ngIf="
          document.activeElement !== passwordInput &&
          password.invalid &&
          (password.dirty || password.touched)
        "
        class="text-red-500 text-left py-2 text-2xs"
      >
        <div *ngIf="password.errors?.['required']">
          {{ 'PleaseFillIn*' | translate }}
        </div>
      </div>

      <div class="text-sm text-left mt-2">
        <a
          [routerLink]="[] | localize"
          [queryParams]="{ view: 'forgotPassword' }"
          class="font-medium text-primary hover:text-secondary"
        >
          {{ 'IForgotMy*' | translate }}
        </a>
      </div>
    </div>

    <div>
      <button
        type="submit"
        [disabled]="form.invalid || form.disabled"
        class="mt-6 w-full rounded-3xl text-center text-white text-bold inline-flex bg-secondary hover:bg-primarytext-white disabled:opacity-50 py-0 h-10 disabled:cursor-not-allowed justify-center items-center"
      >
        {{ 'SignIn' | translate }}
      </button>

      <div class="text-sm mt-2">
        <a
          [routerLink]="[] | localize"
          [queryParams]="{ view: 'signUp' }"
          class="font-medium text-secondary hover:text-primary"
        >
          {{ 'TryForFree' | translate }}
        </a>
      </div>
    </div>
  </form>
</div>
