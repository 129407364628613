import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NotificationInterface } from '@ibep/interfaces';

@Component({
  selector: 'ibep-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent {
  @Input() notification: NotificationInterface = <NotificationInterface>{};
  @Input() i = 0;

  @Output() remove = new EventEmitter<number>();
}
