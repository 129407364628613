<div class="mb-6">
  <nav>
    <h2
      *ngIf="props.title"
      class="text-2xl font-bold mb-2"
      [innerHTML]="props.title"
    ></h2>
    <ng-container *ngFor="let heading of headings">
      <a
        *ngIf="heading.attrs.level !== 6 && heading.attrs.level !== 5"
        (click)="scrollToAnchor(heading.slug)"
        class="block text-secondary hover:text-primary cursor-pointer"
        [ngClass]="{
          'ml-5': heading.attrs.level === 3,
          'ml-10': heading.attrs.level === 4,
          'ml-16': heading.attrs.level === 5
        }"
        ><span [innerHTML]="heading.title"></span>
        <ibep-box-arrow-up-right-svg
          transform="rotate(90)"
          svgStyles="w-3 h-3 ml-1 inline"
        ></ibep-box-arrow-up-right-svg
      ></a>
    </ng-container>
  </nav>
</div>
