// Capitalize first char
export function capitalizeFirstChar(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function arrayToObject(arr, keyName) {
  const res = {};
  for (let i = 0; i < arr.length; i++) {
    const key = arr[i][keyName];
    res[key] = arr[i];
    delete res[key][keyName];
  }
  return res;
}

export function slugify(str: string) {
  return str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');
}

// Get an array of numbers between two numbers (commonly referred to as "range", e.g. Clojure, PHP etc)
export function range(start: number, stop: number, step: number) {
  return Array.from(
    { length: (stop - start) / step + 1 },
    (_, i) => start + i * step
  );
}

// Move an element in an array
export function arrayMove(arr: [], fromIndex: number, toIndex: number) {
  const copy = [...arr];
  const valueToMove = copy.splice(fromIndex, 1)[0];
  copy.splice(toIndex, 0, valueToMove);
  return copy;
}

// check if two arrays intersect
export function arrayIntersect(arr1: string[], arr2: string[]) {
  if (!arr1 || !arr2 || arr1.length < 1 || arr2.length < 1) {
    return false;
  }
  return arr1.some((el) => arr2.includes(el));
}

export function hexToRGB(hex: string): string {
  if (hex) {
    let r = '';
    let g = '';
    let b = '';

    // 3 digits
    if (hex.length === 4) {
      r = '0x' + hex[1] + hex[1];
      g = '0x' + hex[2] + hex[2];
      b = '0x' + hex[3] + hex[3];

      // 6 digits
    } else if (hex.length === 7) {
      r = '0x' + hex[1] + hex[2];
      g = '0x' + hex[3] + hex[4];
      b = '0x' + hex[5] + hex[6];
    }

    return +r + ',' + +g + ',' + +b;
  } else {
    return '';
  }
}
