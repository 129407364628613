import { Component } from '@angular/core';

/**
 * https://ubsicap.github.io/usx/parastyles.html#qc
 * https://github.com/americanbible/scripture-styles/blob/master/scss/modules/_poetry.scss
 *
 * @export
 * @class QcComponent
 */
@Component({
  selector: 'bible-qc',
  template: `
    <p class="qc">
      <ng-content></ng-content>
    </p>
  `,
  styleUrls: ['./qc.component.scss'],
})
export class QcComponent {}
