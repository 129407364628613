<div class="flex gap-2 text-2xs overflow-y-auto breadcrumb">
  <!-- Home -->
  <div>
    <a [routerLink]="'/' | localize" class="hover:text-primary"
      ><ibep-house-svg svgStyles="w-4 h-4 font-bold"></ibep-house-svg>
    </a>
  </div>
  <!-- Parent Routes -->
  <ng-container *ngIf="props.parentRoutes">
    <div *ngFor="let parent of props.parentRoutes" class="flex min-w-fit">
      <ibep-chevron-right-svg
        class="text-grayLight mr-2"
        svgStyles="w-3 h-3 font-bold"
      ></ibep-chevron-right-svg>
      <a
        *ngIf="parent.link"
        [routerLink]="parent.link | localize"
        class="hover:text-primary"
      >
        <span class="font-bold truncate" [innerHTML]="parent.title"></span>
      </a>
      <ng-container *ngIf="!parent.link">
        <span class="font-bold truncate" [innerHTML]="parent.title"></span>
      </ng-container>
    </div>
  </ng-container>
  <!-- Current Route  -->
  <div class="flex">
    <ibep-chevron-right-svg
      class="text-grayLight mr-2"
      svgStyles="w-3 h-3 font-bold"
    ></ibep-chevron-right-svg>
    <span class="truncate" [innerHTML]="props.currentRouteTitle"></span>
  </div>
</div>
