<div
  class="flex justify-start flex-col gap-2 lg:flex-row bg-primary bg-opacity-5 lg:bg-white"
>
  <div
    class="hero w-full pb-12 lg:pt-9 pt-6 items-start"
    [ngClass]="{
      'lg:!pb-[85px] !pb-32': template === 'list' && categories?.length
    }"
  >
    <div
      class="max-w-container mx-auto px-5 xl:px-0 gap-4 flex justify-start flex-col"
    >
      <div
        class="lg:mb-8"
        [ngClass]="{ 'lg:w-1/2 mx-auto': template === 'list' }"
      >
        <h1
          class="font-bold text-lg text-[34px] leading-[40px] lg:text-[36px] lg:leading-[48px] text-white"
          [innerHTML]="title"
        ></h1>
        <div class="flex flex-row" *ngIf="description">
          <div [ngClass]="{ 'lg:w-1/2': template !== 'list' }">
            <p
              class="text-white text-[17px] leading-[28px]"
              [ngClass]="{ 'line-clamp-4': shortenDescription }"
              [innerHTML]="description"
            ></p>
          </div>
        </div>
        <div *ngIf="tags && tags.length > 1" class="pb-3 lg:pb-8">
          <label class="text-[10px] leading-[25px] text-white uppercase">{{
            'FilterByTag' | translate
          }}</label>
          <div class="flex flex-row gap-2">
            <a
              *ngFor="let t of tags"
              [routerLink]="
                t.slug
                  ? (['/' + route + '/tags/' + t.slug] | localize)
                  : (['/' + route] | localize)
              "
            >
              <ibep-button
                [buttonProps]="{
                  pushGtm: true,
                  link: t.slug
                    ? (['/' + route + '/tags/' + t.slug] | localize)
                    : (['/' + route] | localize),
                  content: t.name,
                  style: t.slug === selectedTagSlug ? 'white' : 'secondary',
                  size: 's32'
                }"
              >
                <span
                  class="text-xs"
                  [ngClass]="
                    t.slug === selectedTagSlug
                      ? 'inline-block text-grayDark text-xs px-2 py-1 min-w-fit bg-white rounded-lg'
                      : 'inline-block bg-secondary hover:bg-white text-white hover:text-grayDark text-xs px-2 py-1 rounded-lg'
                  "
                >
                  {{ t.name }}</span
                >
              </ibep-button>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
