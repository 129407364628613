<!--Toast-->
<div
  class="alert-toast fixed bottom-0 right-0 m-8 w-5/6 md:w-full max-w-sm"
  [style.bottom.px]="i * 100"
>
  <input type="checkbox" class="hidden" [id]="'toast' + i" />
  <label
    class="close cursor-pointer flex items-start justify-between w-full p-2 h-24 rounded shadow-lg text-white"
    [ngClass]="{
      'bg-primary': notification.style === 'primary',
      'bg-secondary': notification.style === 'secondary',
      'bg-success': notification.style === 'success',
      'bg-error': notification.style === 'error',
      'bg-warning': notification.style === 'warning',
      'bg-info': notification.style === 'info'
    }"
    title="close"
    [for]="'toast' + i"
  >
    <h4 *ngIf="notification.title" [innerHTML]="notification.title"></h4>
    <p [innerHTML]="notification.body"></p>

    <svg
      ibepHideTillInit
      class="fill-current text-white"
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <path
        d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
      ></path>
    </svg>
  </label>
</div>
