import {
  Component,
  EventEmitter,
  Input,
  Output,
  Renderer2,
} from '@angular/core';
import { Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';

@Component({
  selector: 'ibep-menu-auth-mobile',
  templateUrl: './menu-auth-mobile.component.html',
})
export class MenuAuthMobileComponent {
  public showMobileMenu = false;
  public accountRoute: string;

  @Input() isAuthenticated: boolean;
  @Input() accountTabs;

  @Output() logout = new EventEmitter<boolean>();

  constructor(
    private renderer: Renderer2,
    private readonly _router: Router,
    private readonly _localize: LocalizeRouterService
  ) {
    this.accountRoute = this._localize.translateRoute(
      '/ROUTES.account'
    ) as string;
  }
  /**
   * Toggle the menu, prevent body from scrolling when menu is open.
   *
   * @memberof MenuMobileComponent
   */
  public toggleMenu() {
    this.showMobileMenu = !this.showMobileMenu;
    if (this.showMobileMenu) {
      this.renderer.addClass(document.body, 'overflow-hidden');
    } else {
      this.renderer.removeClass(document.body, 'overflow-hidden');
    }
  }

  public logOut() {
    this.logout.emit(true);
  }
}
