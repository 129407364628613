import { Component } from '@angular/core';

@Component({
  selector: 'bible-po',
  template: `
    <p class="po">
      <ng-content></ng-content>
    </p>
  `,
  styleUrls: ['./po.component.scss'],
})
/**
 * TODO: could not find any documentation
 */
export class PoComponent {}
