import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  Renderer2,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'ibep-picker',
  templateUrl: './picker.component.html',
})
export class PickerComponent implements OnDestroy {
  public pickerIsOpen = false;

  @ViewChild('pickerToggle')
  private pickerToggle!: ElementRef;
  @ViewChild('picker')
  private picker!: ElementRef;
  private listenFn: any;

  constructor(
    private readonly renderer: Renderer2,
    private readonly ref: ChangeDetectorRef
  ) {}

  public close() {
    this.pickerIsOpen = false;
  }

  public togglePicker() {
    if (!this.pickerIsOpen) {
      this.registerListener();
    }
    this.pickerIsOpen = !this.pickerIsOpen;
  }

  private registerListener() {
    // listen for clicks outside picker
    this.listenFn = this.renderer.listen('window', 'click', (e: Event) => {
      if (
        !this.pickerToggle?.nativeElement.contains(e.target) &&
        !this.picker?.nativeElement.contains(e.target) &&
        this.pickerIsOpen
      ) {
        // close the picker
        this.pickerIsOpen = false;
        // unregister the listener
        this.listenFn();
        // because of onpush change detection we need to detect changes
        this.ref.detectChanges();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.listenFn) {
      this.listenFn();
    }
  }
}
