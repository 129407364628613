import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnInit,
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'ibep-embed',
  templateUrl: './embed.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmbedComponent implements OnInit {
  @Input() props: { embedUrl: string; content: string };
  public embedType: 'vimeo' | 'youtube' | 'spotify';

  public safeURL: SafeResourceUrl;

  public caption: string;

  constructor(private _sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    if (this.props.embedUrl?.includes('youtu')) {
      this.embedType = 'youtube';
      if (!this.props.embedUrl.includes('/embed/')) {
        this.props.embedUrl = `https://www.youtube.com/embed/${this._getYoutubeId(
          this.props.embedUrl
        )}`;
      }
    }
    if (this.props.embedUrl?.includes('vimeo')) {
      this.embedType = 'vimeo';
      if (!this.props.embedUrl.includes('player')) {
        this.props.embedUrl = `https://player.vimeo.com/video/${this._getVimeoId(
          this.props.embedUrl
        )}`;
      }
    }

    if (this.props.embedUrl?.includes('spotify')) {
      this.embedType = 'spotify';
      if (!this.props.embedUrl.includes('/embed/')) {
        // fallback if embedding a playlist
        if (this.props.embedUrl.includes('/playlist/')) {
          this.props.embedUrl = `https://open.spotify.com/embed/playlist/${this._getSpotifyId(
            this.props.embedUrl
          )}`;
        }
        // fallback if embedding a track
        if (this.props.embedUrl.includes('/track/')) {
          this.props.embedUrl = `https://open.spotify.com/embed/track/${this._getSpotifyId(
            this.props.embedUrl
          )}`;
        }
      }
    }
    // sanitize url
    if (this.props.embedUrl) {
      this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(
        this.props.embedUrl
      );
    }

    // If there is a caption get it from html
    if (this.props.content && this.props.content.includes('<figcaption>')) {
      const startPattern = '<figcaption>';
      this.caption = this.props.content.substring(
        this.props.content.indexOf(startPattern) + startPattern.length,
        this.props.content.indexOf(startPattern) +
          startPattern.length +
          this.props.content.length
      );
      const stopPattern = '</figcaption>';
      this.caption = this.caption.substring(
        0,
        this.caption.indexOf(stopPattern)
      );
    }
  }

  private _getYoutubeId(url: string): string | null {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return match && match[2]?.length === 11 ? match[2] : null;
  }

  private _getVimeoId(url: string): string | null {
    const split = url?.split('/');
    return split[split.length - 1];
  }

  // for example https://open.spotify.com/playlist/37i9dQZF1DX9XIFQuFvzM4?si=1815cf861f4b4443
  private _getSpotifyId(url: string): string | null {
    const split = url?.split('/');
    return split[split.length - 1]?.split('?')[0];
  }
}
