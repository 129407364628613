import { Component } from '@angular/core';

/**
 * https://ubsicap.github.io/usx/parastyles.html#h
 * Running header text.
 * Deprecated use of numbered variable syntax (use is strongly discouraged).
 * usx 3.0
 * The variable # in <para style="h#">text...</para> represented distinct
 * components or levels of text required for the running header
 * presentation (e.g. inside, outside, sub-division/section etc.).
 * Formatting Sample - Matthew: MAT.2.4
 * @export
 * @class HComponent
 */
@Component({
  selector: 'bible-h',
  templateUrl: './h.component.html',
  styleUrls: ['./h.component.scss'],
})
export class HComponent {}
