import { Component } from '@angular/core';
/**
 * Small Caps
 * https://ubsicap.github.io/usx/master/charstyles.html#sc
 * @export
 * @class ScComponent
 */
@Component({
  selector: 'bible-sc',
  templateUrl: './sc.component.html',
  styleUrls: ['./sc.component.scss'],
})
export class ScComponent {}
