import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import {
  APP_ID,
  APP_INITIALIZER,
  ErrorHandler,
  NgModule,
  PLATFORM_ID,
} from '@angular/core';
import { Router, UrlSerializer } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import {
  CustomUrlSerializer,
  GlobalErrorHandler,
  NAVIGATOR_PROVIDERS,
  ServerErrorInterceptor,
  TranslationLoader,
  WINDOW,
  WINDOW_PROVIDERS,
} from '@ibep/fe/shared/core';
import { ConfigData } from '@ibep/fe/shared/data';
import { FeWebComponentsContainerModule } from '@ibep/fe/web/components-container';
import {
  BrowserStateInterceptor,
  FeWebCoreModule,
  ServiceWorkerService,
} from '@ibep/fe/web/core';
import { ModuleAuthModule } from '@ibep/fe/web/module-auth';
import {
  AbstractBackendService,
  AbstractEnvironmentService,
  AbstractServiceWorkerService,
  AbstractStorageService,
} from '@ibep/interfaces';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { Angulartics2Module } from 'angulartics2';
import * as Sentry from '@sentry/angular-ivy';
import { fromEvent, take, of } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing.module';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { CookieService } from 'ngx-cookie-service';

// TODO: move the factory functions and providers below to a lib, so we can reuse them in other apps if needed

/**
 * Factory function for the document object
 * TODO: create document provider in the same way we did for Window and Navigator
 * @export
 * @returns
 */
export function DocumentFactory() {
  if (typeof document === 'undefined') {
    return null;
  }
  return document;
}

/**
 * Factory function to fetch the config from the IBEP API.
 * This is executed with APP_INITIALIZER so it is executed before the application bootstraps
 * @export
 * @param {ConfigData} configData
 * @returns
 */
export function ConfigFactory(configData: ConfigData) {
  return () => configData.getConfig().pipe(take(1));
}

/**
 * Initiate the service worker.
 * This is executed with APP_INITIALIZER so it is executed before the application bootstraps
 * @export
 * @param {ServiceWorkerService} environmentService
 * @returns
 */
export function serviceWorkerFactory(
  serviceWorkerService: ServiceWorkerService,
  environmentService: AbstractEnvironmentService
) {
  return () => {
    if (environmentService.platform === 'server') {
      return of(true);
    }
    return serviceWorkerService
      .isEnvironmentReady()
      .catch((e) => console.info('Could not initialize application', e));
  };
}

/**
 * Factory function to get the translations for the current brand from the API
 *
 * @export
 * @param {ConfigData} configData
 * @param {BackendService} backendService
 * @returns
 */
export function TranslationLoaderFactory(
  configData: ConfigData,
  backendService: AbstractBackendService,
  storage: AbstractStorageService
) {
  return new TranslationLoader(configData, storage, backendService);
}

export function apiBaseUrlFactory(abstractEnvironmentService) {
  return abstractEnvironmentService.apiBaseUrl;
}

export function apiGlobalHeadersFactory(abstractEnvironmentService) {
  return abstractEnvironmentService.apiGlobalHeaders;
}

export function recaptchaKeyFactory(abstractEnvironmentService) {
  return abstractEnvironmentService.recaptchaKey;
}

export function TagManagerFactory(configData: ConfigData): string {
  return configData.gtmId;
}

export function fixPageFlickering(platformId: string): () => void {
  return () => {
    if (isPlatformBrowser(platformId)) {
      const transitionStyles = Array.from(
        document.querySelectorAll('style[ng-transition]')
      );

      const serverRoot = document.body.querySelector(
        'ibep-root'
      ) as HTMLElement;
      const clientRoot = serverRoot.cloneNode() as HTMLElement;

      serverRoot.setAttribute('ng-non-bindable', '');
      clientRoot.style.display = 'none';

      document.body.insertBefore(clientRoot, serverRoot);

      transitionStyles.forEach((element: HTMLElement) => {
        element.removeAttribute('ng-transition');
      });

      fromEvent(window, 'load').subscribe(() => {
        transitionStyles.forEach((el: HTMLElement) => el.remove());

        clientRoot.style.display = 'block';
        serverRoot.remove();
      });
    }
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslationLoaderFactory,
        deps: [ConfigData, AbstractBackendService, AbstractStorageService],
      },
    }),
    AppRoutingModule,
    FeWebCoreModule,
    FeWebComponentsContainerModule,
    HttpClientModule,
    Angulartics2Module.forRoot(),
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    LoadingBarModule,
    ModuleAuthModule,
    RecaptchaV3Module,
  ],
  providers: [
    {
      provide: APP_ID,
      useValue: 'IBEP-main',
    },
    {
      provide: 'googleTagManagerId',
      useFactory: TagManagerFactory,
      deps: [ConfigData],
    },
    {
      provide: 'ENVIRONMENT',
      useValue: environment,
    },
    {
      provide: 'SENTRY',
      useValue: Sentry,
    },
    {
      provide: 'API_BASE_URL',
      useFactory: apiBaseUrlFactory,
      deps: [AbstractEnvironmentService],
    },
    {
      provide: 'API_GLOBAL_HEADERS',
      useFactory: apiGlobalHeadersFactory,
      deps: [AbstractEnvironmentService],
    },
    { provide: 'DOCUMENT', useFactory: DocumentFactory },
    ...WINDOW_PROVIDERS,
    ...NAVIGATOR_PROVIDERS,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {
      provide: APP_INITIALIZER,
      useFactory: fixPageFlickering,
      deps: [PLATFORM_ID],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: ConfigFactory,
      multi: true,
      deps: [ConfigData, HttpClient, WINDOW, PLATFORM_ID],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: serviceWorkerFactory,
      deps: [AbstractServiceWorkerService, AbstractEnvironmentService],
      multi: true,
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BrowserStateInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerErrorInterceptor,
      multi: true,
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useFactory: recaptchaKeyFactory,
      deps: [AbstractEnvironmentService],
    },
    {
      provide: UrlSerializer,
      useClass: CustomUrlSerializer,
    },
    CookieService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
