<div class="my-6">
  <div
    class="bg-primary bg-opacity-10 lg:bg-white lg:bg-opacity-100 rounded-t-xl"
    *ngIf="
      errors ||
      (form.invalid && (email.dirty || email.touched)) ||
      resetPasswordProps.success
    "
  >
    <div class="px-2 py-4">
      <div class="text-left text-xs flex gap-2">
        <ng-container *ngIf="!resetPasswordProps.success">
          <div class="text-red-500">
            <ibep-exclamation-svg
              svgStyles="w-4 h-4"
              class="inline"
            ></ibep-exclamation-svg>
          </div>
          <div>
            <h4 class="font-bold mb-1">
              {{ 'AlmostReadyBut*' | translate }}
            </h4>
            <p *ngIf="!errors">
              {{ 'PleaseCheckTheHighlighted*' | translate }}
            </p>

            <p *ngIf="errors" [innerHTML]="errors.code | translate"></p>
          </div>
        </ng-container>
        <ng-container *ngIf="resetPasswordProps.success && !errors">
          <div class="text-green-500">
            <ibep-check-circle-fill-svg
              svgStyles="w-4 h-4"
              class="inline"
            ></ibep-check-circle-fill-svg>
          </div>
          <div>
            <h4 class="font-bold mb-1">
              {{ 'ResetPasswordLinkSent' | translate }}
            </h4>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <form (ngSubmit)="onSubmit()" class="space-y-6" [formGroup]="form">
    <h1 class="text-3xl font-bold text-left text-grayDark">
      {{ 'ChangePassword' | translate }}
    </h1>
    <div>
      <label
        for="email"
        class="block text-xs font-bold text-grayMedium text-left pb-1"
      >
        {{ 'EmailAddress' | translate }}
      </label>
      <div class="mt-1">
        <input
          #emailInput
          formControlName="email"
          id="email"
          name="email"
          type="username"
          autocomplete="username"
          required
          class="appearance-none block w-full px-3 py-2 border border-grayLight rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
        />
      </div>
      <div
        *ngIf="
          document.activeElement !== emailInput &&
          email.invalid &&
          (email.dirty || email.touched)
        "
        class="text-red-500 text-left py-2 text-2xs"
      >
        <div *ngIf="email.errors?.['required']">
          {{ 'PleaseFillInEmail*' | translate }}
        </div>
        <div *ngIf="email.errors?.['email']">
          {{ 'WeDoNot*' | translate }}
        </div>
      </div>
    </div>

    <div>
      <button
        type="submit"
        [disabled]="form.invalid || form.disabled"
        class="mt-5 w-full rounded-3xl text-center inline-flex bg-secondary hover:bg-primary text-white disabled:opacity-50 py-0 h-10 disabled:cursor-not-allowed justify-center items-center"
      >
        {{ 'ChangePassword' | translate }}
      </button>
      <div class="mt-3">
        <a
          [routerLink]="[] | localize"
          [queryParams]="{ view: 'signIn' }"
          class="font-medium text-secondary hover:text-primary"
        >
          {{ 'LoginToYour*' | translate }}
        </a>
      </div>
    </div>

    <div class="inner-html-has-link text-2xs text-left text-grayLight mt-2">
      <span
        *ngIf="
          ('recaptchaMessage' | translate) !== 'recaptchaMessage';
          else recaptchaTemplate
        "
        [innerHTML]="'recaptchaMessage' | translate"
      ></span>
    </div>
    <ng-template #recaptchaTemplate>
      This site is protected by reCAPTCHA and the Google
      <a href="https://policies.google.com/privacy">Privacy Policy</a> and
      <a href="https://policies.google.com/terms">Terms of Service</a>
      apply.
    </ng-template>
  </form>
</div>
