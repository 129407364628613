<div class="font-bold text-left mb-4 ml-11">
  {{ 'CopyOptions' | translate }}
</div>

<div class="text-left">{{ 'CopyTextWith' | translate }}</div>

<div
  *ngFor="let option of options"
  class="flex items-center py-3 border-b border-grayExtraLight"
>
  <input
    [id]="option.id"
    [name]="option.id"
    type="checkbox"
    [checked]="option.checked"
    (click)="toggleOption(option)"
    class="focus:ring-primary h-4 w-4 text-primary border-grayLight rounded cursor-pointer mx-2.5"
  />

  <label
    [for]="option.id"
    class="cursor-pointer flex justify-between flex-row text-left text-grayMedium line-clamp-1 hover:text-primary"
    [innerHTML]="option.name"
  >
  </label>
</div>

<ibep-button
  [buttonProps]="{
    pushGtm: true,
    content: 'Copy' | translate,
    style: 'secondary',
    size: 'l'
  }"
  class="float-left mt-3"
  (click)="onCopyButtonClicked()"
>
  <b class="font-bold px-2 text-center w-full">{{ 'Copy' | translate }}</b>
</ibep-button>
