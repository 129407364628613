<div
  *ngFor="let item of items"
  class="px-4 py-2 rounded-lg text-xs leading-5"
  [ngClass]="{
    'bg-[#FFF0C8]': item.checked,
    'cursor-pointer': item.linkInternal,
    'hover:bg-primary hover:bg-opacity-[0.06]':
      !item.checked && item.linkInternal
  }"
  (mouseenter)="onHover(item, true)"
  (mouseleave)="onHover(item, false)"
  (click)="clickItem(item)"
  [routerLink]="item.linkInternal | localize"
>
  <div class="flex">
    <div
      [ngClass]="{
        'max-w-[calc(100%-130px)]':
          CONTENT_CATEGORIES.includes(category) &&
          item?.acf?.heroImage?.sizes?.thumbnail
      }"
    >
      <div
        *ngIf="category !== categoryEnum.BIBLE"
        class="font-bold flex items-center"
      >
        <div [innerHTML]="item.title"></div>
        <div
          *ngIf="item.acf?.access === 'Registered' && !isAuthenticated"
          class="relative w-4 h-4 ml-2 rounded-full text-primary bg-highlightText border border-grayExtraLight"
        >
          <ibep-lock-svg
            class="absolute -top-1 left-[3px]"
            svgStyles="w-2 h-2 "
          ></ibep-lock-svg>
        </div>
        <div
          *ngIf="item.acf?.access === 'Premium' && !isPremium"
          class="text-secondary text-4xs bg-secondary bg-opacity-10 ml-2 uppercase font-bold px-[2px]"
        >
          <span>{{ 'Premium' | translate }}</span>
        </div>
      </div>
      <div
        [innerHTML]="
          category === categoryEnum.BIBLE
            ? item.content || item.acf.description
            : (item.content || item.acf.description | highlightSearch : query)
        "
        [ngClass]="{
          'text-smooth-opacity max-h-[3.75rem]': category !== categoryEnum.BIBLE
        }"
      ></div>
    </div>
    <div
      class="ml-auto w-[130px] h-[73px]"
      *ngIf="item?.acf?.heroImage?.sizes?.thumbnail"
    >
      <img
        class="w-full h-full object-cover"
        [alt]="item.acf.heroImage?.alt"
        [ngSrc]="item.acf.heroImage?.sizes?.thumbnail"
        [width]="item.acf.heroImage?.sizes?.['thumbnail-width']"
        [height]="item.acf.heroImage?.sizes?.['thumbnail-height']"
      />
    </div>
  </div>
  <div class="flex items-center" *ngIf="category === categoryEnum.BIBLE">
    <div
      class="mr-1 text-[#7A8592] headline-segment"
      *ngIf="item.type === 'heading'"
      [innerHTML]="'HeadlineOf' | translate"
    ></div>
    <div class="text-[#7A8592] py-2">{{ item.fullName }}</div>
    <div class="text-grayLight ml-1 py-2">{{ selectedBible }}</div>
    <div
      class="hidden bg-white text-secondary text-2xs border border-secondary rounded-md ml-auto px-2 py-1"
      [ngClass]="{ block: isTablet, 'lg:block': item.hover || item.checked }"
      (click)="checkItem($event, item)"
    >
      <ibep-search-svg
        class="text-secondary"
        svgStyles="w-3 h-3 mr-1 fill-secondary"
      ></ibep-search-svg>
      {{ 'QuickView' | translate }}
    </div>
  </div>
</div>
