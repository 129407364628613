import { Component } from '@angular/core';

/**
 * https://ubsicap.github.io/usx/parastyles.html#m
 *
 * @export
 * @class MComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'bible-m',
  template: `
    <p class="m">
      <ng-content></ng-content>
    </p>
  `,
  styleUrls: ['./m.component.scss'],
})
export class MComponent {}
