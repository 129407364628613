import { Component, Input } from '@angular/core';

@Component({
  selector: 'ibep-chevron-right-svg',
  template: `
    <svg
      ibepHideTillInit
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      class="m-auto inline"
      [ngClass]="svgStyles"
      viewBox="0 0 16 16"
    >
      <path
        fill-rule="evenodd"
        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
      />
    </svg>
  `,
  styles: [],
})
export class ChevronRightComponent {
  @Input() svgStyles: string;
}
