import { Component, Input } from '@angular/core';

@Component({
  selector: 'ibep-pause-svg',
  template: `
    <svg
      ibepHideTillInit
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      class="inline m-auto"
      viewBox="0 0 16 16"
      [ngClass]="svgStyles"
    >
      <path
        d="M5.5 3.5A1.5 1.5 0 0 1 7 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5zm5 0A1.5 1.5 0 0 1 12 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5z"
      />
    </svg>
  `,
  styles: [],
})
export class PauseComponent {
  @Input() svgStyles: string;
}
