import { DOCUMENT } from '@angular/common';
import { Component, Input, Output, Inject, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { BibleService } from '@ibep/fe/shared/bible';
import { BaseComponent, LanguageService } from '@ibep/fe/shared/core';
import { ChapterData, ConfigData } from '@ibep/fe/shared/data';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs';
import { EbcLinksFormatterService } from '@ibep/fe/shared/core';

@Component({
  selector: 'ibep-paragraph',
  templateUrl: './paragraph.component.html',
  styleUrls: ['./paragraph.component.scss'],
})
export class ParagraphComponent extends BaseComponent {
  @Input() formatEbcLinks = true;

  @Input() set html(value: string) {
    // FIXME: only format the links for ebc content
    if (this.formatEbcLinks) {
      this.HTMLElements = this._ebcLinksFormatterService.formatEbcLinks(
        value,
        this.ebcRoute,
        this.defaultBibleAbbr
      );
    } else {
      this.HTMLElements = this._ebcLinksFormatterService.createDOMTree(value);
    }
  }
  @Input() paragraphId: number;

  @Input() showRegularBullets = false;

  @Output() closeModal = new EventEmitter<boolean>();

  @Output() openSiblingLightBox = new EventEmitter<number>();

  @Input() inlineImage: boolean;
  @Input() disableLightbox: boolean;
  @Input() imageGallery: boolean;
  @Input() galleryIndex: number;
  @Input() galleryTotal: number;
  @Input() imageLightBox: boolean;

  public activeNotes: Map<string, HTMLDivElement> = new Map();

  public HTMLElements: any;

  public ebcRoute: string;

  public defaultBibleAbbr: string;

  constructor(
    @Inject(DOCUMENT)
    private document: Document,
    private readonly _router: Router,
    private readonly _configData: ConfigData,
    private readonly _bibleService: BibleService,
    private readonly _languageService: LanguageService,
    private readonly _localize: LocalizeRouterService,
    private readonly _translate: TranslateService,
    private readonly _chapterData: ChapterData,
    private readonly _route: ActivatedRoute,
    private readonly _ebcLinksFormatterService: EbcLinksFormatterService
  ) {
    super();
    this.ebcRoute = `${
      this._languageService.isDefaultLanguage
        ? ''
        : `/${this._languageService.currentLanguage}`
    }/${this._translate.instant('ROUTES.ebc')}`;

    this._configData
      .getConfig()
      .pipe(takeUntil(this._destroy))
      .subscribe((config) => {
        const defaultBibleId = this._bibleService.getDefaultBibleId(
          config.brand,
          config.bibles
        )?.[0] as string;
        this.defaultBibleAbbr =
          this._bibleService.getBibleAbbrFromId(defaultBibleId);
      });
  }

  /**
   * Open / close the inline notes
   *
   * @param {*} ref
   * @memberof ParagraphComponent
   */
  public handleInlineNoteClick(ref: string, index: number): void {
    const noteDomId = `${ref}-${index}`;

    if (this.activeNotes.has(noteDomId)) {
      this.activeNotes.delete(noteDomId);
    } else {
      this._chapterData.EmitGetChapter$.next(ref);
      const foundElement = this.document.getElementById(ref) as HTMLDivElement;
      this.activeNotes.set(noteDomId, foundElement);
    }
  }

  public closeEbcModal(): void {
    this.closeModal.next(true);
  }

  /**
   * Redirects to a different route based on the provided dataset.
   * If the dataset contains a 'href' property, the method will navigate to the corresponding route.
   * If the formatEbcLinks property is true, it will close the Ebc modal before navigating.
   * If the current route contains a 'ebc' query parameter, it will handle special cases for Ebc links.
   * @param dataset - The dataset containing the necessary information for redirection.
   */
  public internalRedirect(dataset: DOMStringMap): void {
    if (dataset.href === undefined) {
      return;
    }

    if (this.formatEbcLinks) {
      this.closeEbcModal();
    }

    if (this._route.snapshot.queryParams.ebc) {
      if (dataset.legacy_id !== undefined) {
        const redirectQueryParams = { ebc: true, legacyId: dataset.legacy_id };

        this._router.navigate([], {
          queryParams: redirectQueryParams,
        });
        return;
      } else if (
        dataset.href.includes(`${this._localize.translateRoute('/ROUTES.ebc')}`)
      ) {
        let splittedUrl = dataset.href.split('/');
        const slug = splittedUrl.pop();
        const redirectQueryParams = { ebc: true, slug };

        this._router.navigate([], {
          queryParams: redirectQueryParams,
        });
        return;
      }
    }

    // Remove base url from dataset.href
    if (
      dataset.href.startsWith('https://') ||
      dataset.href.startsWith('http://')
    ) {
      dataset.href = new URL(dataset.href).pathname;
    }
    this._router.navigate([this._translate.instant(dataset.href)]);
  }

  public siblingLightBox(event: number): void {
    this.openSiblingLightBox.emit(event);
  }
}
