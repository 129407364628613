<div class="border-b border-grayExtraLight h-10 bg-grayExtraLight lg:bg-white">
  <div class="max-w-container m-auto px-6 py-3">
    <ibep-breadcrumbs
      [props]="{
        currentRouteTitle: activeTab.title,
        parentRoutes: [{ title: 'Account' | translate }]
      }"
    ></ibep-breadcrumbs>
  </div>
</div>

<div class="hidden lg:block relative px-4 lg:px-0 mb-4 lg:mb-0">
  <ibep-tabs
    [activeTab]="activeTab"
    [tabs]="accountTabs"
    (activeTabChanged)="onTabChanged($event)"
  ></ibep-tabs>

  <ibep-button
    [buttonProps]="{
      pushGtm: true,
      content: 'LogOut' | translate,
      style: 'secondary',
      size: 's'
    }"
    class="lg:absolute lg:top-2 lg:right-5"
    (click)="onLogOut()"
  >
    <b class="text-xs">{{ 'LogOut' | translate }}</b>
  </ibep-button>
</div>

<div class="mt-8 lg:mt-0 lg:flex mb-4">
  <div class="lg:w-1/4 pl-4 pt-4 xl:pl-0 lg:text-left">
    <h3 class="text-2xl font-bold mb-2">
      {{ activeTab.title }}
    </h3>
    <ibep-paragraph
      *ngIf="activeTab.description"
      [html]="activeTab.description"
    ></ibep-paragraph>
  </div>
  <div class="lg:w-3/4 px-5 pb-5">
    <ng-container [ngSwitch]="activeTab.value">
      <!-- Your data -->
      <ng-container *ngSwitchCase="accountTabEnum.YOUR_DATA">
        <form [formGroup]="baseForm">
          <!-- Base form fields  -->

          <div class="space-y-6 mt-5">
            <!-- Email  -->
            <div>
              <label
                for="email"
                class="block text-xs font-bold text-grayMedium text-left pb-1"
              >
                {{ 'EmailAddress' | translate }}
              </label>
              <div class="mt-1 pointer-events-none">
                <input
                  formControlName="email"
                  id="email"
                  name="email"
                  type="email"
                  autocomplete="email"
                  required
                  class="appearance-none block w-full px-3 py-2 focus:outline-none focus:ring-primary sm:text-sm"
                />
              </div>
            </div>

            <!-- First name  -->
            <div>
              <label
                for="firstName"
                class="block text-xs font-bold text-grayMedium text-left pb-1"
              >
                {{ 'FirstName' | translate }}
              </label>
              <div class="mt-1">
                <input
                  #firstNameInput
                  formControlName="firstName"
                  id="firstName"
                  name="firstName"
                  autocomplete="given-name"
                  type="text"
                  required
                  class="appearance-none block w-full px-3 py-2 border border-grayLight rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                />
              </div>
              <ibep-validation-message
                *ngIf="
                  document.activeElement !== firstNameInput &&
                  baseForm.controls['firstName'].invalid &&
                  baseForm.controls['firstName'].touched
                "
                [message]="'FirstNameIsRequired' | translate"
              ></ibep-validation-message>
            </div>

            <!-- Last name  -->
            <div>
              <label
                for="lastName"
                class="block text-xs font-bold text-grayMedium text-left pb-1"
              >
                {{ 'LastName' | translate }}
              </label>
              <div class="mt-1">
                <input
                  #lastNameInput
                  formControlName="lastName"
                  id="lastName"
                  name="lastName"
                  type="text"
                  autocomplete="family-name"
                  required
                  class="appearance-none block w-full px-3 py-2 border border-grayLight rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                />
              </div>
              <ibep-validation-message
                *ngIf="
                  document.activeElement !== lastNameInput &&
                  baseForm.controls['lastName'].invalid &&
                  baseForm.controls['lastName'].touched
                "
                [message]="'LastNameIsRequired' | translate"
              ></ibep-validation-message>
            </div>

            <!-- Language  -->
            <div *ngIf="availableLanguages?.length > 1">
              <label
                for="locale"
                class="block text-xs font-bold text-grayMedium text-left pb-1"
              >
                {{ 'Language' | translate }}
              </label>
              <div class="mt-1">
                <select
                  #localeInput
                  (change)="setLanguage($event.target.value)"
                  id="locale"
                  name="locale"
                  class="appearance-none block w-full px-3 py-2 border border-grayLight rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                >
                  <option
                    *ngFor="let language of availableLanguages"
                    [value]="language.localeCode"
                    [selected]="language.selected"
                  >
                    {{ language.localName }}
                  </option>
                </select>
              </div>
            </div>

            <ibep-validation-message
              *ngIf="updateUserServerError"
              [message]="updateUserServerError | translate"
            ></ibep-validation-message>
            <div class="mt-5">
              <ibep-button
                [buttonProps]="{
                  pushGtm: true,
                  content: 'Save' | translate,
                  disabled:
                    baseForm.pristine ||
                    baseForm.controls.firstName.status === 'INVALID' ||
                    baseForm.controls.lastName.status === 'INVALID',
                  locked:
                    baseForm.pristine ||
                    baseForm.controls.firstName.status === 'INVALID' ||
                    baseForm.controls.lastName.status === 'INVALID',
                  style: 'secondary',
                  size: 'm'
                }"
                (click)="savePersonalData()"
              >
                <b class="text-xs">{{ 'Save' | translate }}</b>
              </ibep-button>
            </div>
          </div>
        </form>
      </ng-container>

      <!-- Password -->
      <ng-container *ngSwitchCase="accountTabEnum.PASSWORD">
        <form [formGroup]="passwordForm">
          <!-- Password form fields  -->

          <div class="space-y-6 mt-5">
            <!-- Old password  -->

            <div>
              <!-- TODO: verify text and add translation after that -->
              <label
                for="oldPassword"
                class="block text-xs font-bold text-grayMedium text-left pb-1"
              >
                {{ 'CurrentPassword' | translate }}
              </label>
              <div class="mt-1">
                <input
                  #oldPasswordInput
                  (ngModelChange)="validateChangePassword()"
                  formControlName="oldPassword"
                  id="oldPassword"
                  name="oldPassword"
                  type="password"
                  autocomplete="off"
                  required
                  class="appearance-none block w-full px-3 py-2 border border-grayLight rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                />
              </div>
              <ibep-validation-message
                *ngIf="
                  document.activeElement !== oldPasswordInput &&
                  passwordForm.controls['oldPassword'].invalid &&
                  passwordForm.controls['oldPassword'].touched
                "
                [message]="'WrongPassword' | translate"
              ></ibep-validation-message>
              <ibep-validation-message
                *ngIf="passwordServerError"
                [message]="passwordServerError | translate"
              ></ibep-validation-message>
            </div>

            <!-- New password  -->

            <div>
              <!-- TODO: verify text and add translation after that -->
              <label
                for="newPassword"
                class="block text-xs font-bold text-grayMedium text-left pb-1"
              >
                {{ 'NewPassword' | translate }}
              </label>
              <div class="mt-1">
                <input
                  #newPasswordInput
                  (ngModelChange)="validateChangePassword()"
                  formControlName="newPassword"
                  id="newPassword"
                  name="newPassword"
                  type="password"
                  autocomplete="off"
                  required
                  class="appearance-none block w-full px-3 py-2 border border-grayLight rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                />
              </div>
              <ibep-validation-message
                *ngIf="
                  document.activeElement !== newPasswordInput &&
                  passwordForm.controls['newPassword'].invalid &&
                  passwordForm.controls['newPassword'].touched
                "
                [message]="'ThePasswordShould*' | translate"
              ></ibep-validation-message>
            </div>

            <!-- Confirm new password  -->

            <div>
              <!-- TODO: verify text and add translation after that -->
              <label
                for="newPasswordConfirm"
                class="block text-xs font-bold text-grayMedium text-left pb-1"
              >
                {{ 'ConfirmNewPassword' | translate }}
              </label>
              <div class="mt-1">
                <input
                  #newPasswordConfirmInput
                  (ngModelChange)="validateChangePassword()"
                  formControlName="newPasswordConfirm"
                  id="newPasswordConfirm"
                  name="newPasswordConfirm"
                  type="password"
                  autocomplete="off"
                  required
                  class="appearance-none block w-full px-3 py-2 border border-grayLight rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                />
              </div>
              <ibep-validation-message
                *ngIf="
                  document.activeElement !== newPasswordConfirmInput &&
                  passwordForm.controls['newPasswordConfirm'].value !==
                    passwordForm.controls['newPassword'].value &&
                  passwordForm.controls['newPasswordConfirm'].touched
                "
                [message]="'ConfirmationDoesNotMatch*' | translate"
              ></ibep-validation-message>
              <ibep-validation-message
                *ngIf="showPasswordChangeSuccessMessage"
                [type]="'success'"
                [message]="'YourPasswordIsSuccessfully*' | translate"
              ></ibep-validation-message>
            </div>

            <div class="mt-5">
              <ibep-button
                [buttonProps]="{
                  pushGtm: true,
                  content: 'ChangePassword' | translate,
                  style: 'secondary',
                  disabled: !isChangePasswordAllowed,
                  locked: !isChangePasswordAllowed,
                  size: 'm'
                }"
                (click)="onChangePassword()"
              >
                <b class="text-xs">{{ 'ChangePassword' | translate }}</b>
              </ibep-button>
            </div>
          </div>
        </form>
      </ng-container>
      <ng-container *ngSwitchCase="accountTabEnum.PREMIUM_VOUCHER">
        <form [formGroup]="voucherForm">
          <!-- Voucher form fields  -->

          <div class="space-y-6 mt-5 bg-primary bg-opacity-5 p-4">
            <!-- Expiration date  -->
            <div *ngIf="isPremium">
              <!-- TODO: verify text and add translation after that -->
              <label
                class="block text-xs font-bold text-grayMedium text-left pb-1"
              >
                {{ 'YouWillHaveAccess*' | translate }}
              </label>
              <div class="mt-1">
                <span>{{
                  userInfoData.premiumExpirationDate
                    | date : dateFormat
                    | localizeDate : dateFormat
                }}</span>
              </div>
            </div>

            <!-- Voucher code  -->
            <div *ngIf="!isPremium" class="mb-2">
              <label
                for="voucherCode"
                class="block text-xs font-bold text-grayMedium text-left pb-1"
              >
                {{ 'VoucherCode' | translate }}
              </label>
              <div class="mt-1">
                <input
                  formControlName="voucherCode"
                  id="voucherCode"
                  name="voucherCode"
                  type="text"
                  required
                  class="appearance-none block w-full px-3 py-2 border border-grayLight rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                />
              </div>
            </div>

            <ibep-validation-message
              *ngIf="addVoucherServerError && !addVoucherSuccess"
              [message]="
                addVoucherServerError === 'formatError'
                  ? ('WrongVoucherCode' | translate)
                  : ('SomethingWentWrong' | translate)
              "
            ></ibep-validation-message>
            <ibep-validation-message
              *ngIf="addVoucherSuccess"
              [type]="'success'"
              [message]="'VoucherSuccessfullyApllied' | translate"
            ></ibep-validation-message>

            <div class="mt-5" *ngIf="!isPremium">
              <ibep-button
                [buttonProps]="{
                  pushGtm: true,
                  content: 'SendVoucherAnd*' | translate,
                  disabled: voucherForm.invalid || voucherForm.pristine,
                  locked: voucherForm.invalid || voucherForm.pristine,
                  style: 'secondary',
                  size: 'm'
                }"
                (click)="sendVoucher()"
              >
                <b class="text-xs">{{ 'SendVoucherAnd*' | translate }}</b>
              </ibep-button>
            </div>
          </div>
        </form>
      </ng-container>
      <ng-container *ngSwitchCase="accountTabEnum.DELETE_ACCOUNT">
        <form [formGroup]="deleteAccountForm">
          <!-- Delete account form fields  -->

          <div class="space-y-6 mt-5">
            <!-- Current password  -->

            <div>
              <!-- TODO: verify text and add translation after that -->
              <label
                for="currentPassword"
                class="block text-xs font-bold text-grayMedium text-left pb-1"
              >
                {{ 'CurrentPassword' | translate }}
              </label>
              <div class="mt-1">
                <input
                  #currentPasswordInput
                  formControlName="currentPassword"
                  id="currentPassword"
                  name="currentPassword"
                  type="password"
                  autocomplete="off"
                  required
                  class="appearance-none block w-full px-3 py-2 border border-grayLight rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                />
              </div>

              <ibep-validation-message
                *ngIf="
                  document.activeElement !== currentPasswordInput &&
                  deleteAccountForm.controls['currentPassword'].invalid &&
                  deleteAccountForm.controls['currentPassword'].touched
                "
                [message]="'WrongPassword' | translate"
              ></ibep-validation-message>

              <ibep-validation-message
                *ngIf="deleteAccountServerError"
                [message]="deleteAccountServerError | translate"
              ></ibep-validation-message>
            </div>

            <div class="mt-5">
              <ibep-button
                [buttonProps]="{
                  pushGtm: true,
                  disabled:
                    deleteAccountForm.controls['currentPassword'].invalid,
                  locked: deleteAccountForm.controls['currentPassword'].invalid,
                  content: 'DeleteAccount' | translate,
                  style: 'secondary',
                  size: 'm'
                }"
                (click)="onDeleteUser()"
              >
                <b class="text-xs">{{ 'DeleteAccount' | translate }}</b>
              </ibep-button>
            </div>
          </div>
        </form>
      </ng-container>
    </ng-container>
  </div>
</div>
