import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { slugify } from '@ibep/shared/util';

@Component({
  selector: 'ibep-heading',
  templateUrl: './heading.component.html',
  styleUrls: ['./heading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeadingComponent {
  @Input() content: string;

  public id: string;

  ngOnInit(): void {
    // create id
    const HTMLtagsRemoved = this.content.replace(/<\/?[^>]+(>|$)/g, '');
    this.id = slugify(HTMLtagsRemoved);
  }
}
