<div class="mt-8 mb-1 text-left">
  <h1
    *ngIf="content.headline"
    class="text-2xl mb-1"
    [innerHTML]="content.headline"
  ></h1>
  <div class="pt-1 z-0">
    <ibep-paragraph [html]="content.description"></ibep-paragraph>
  </div>

  <a [href]="content.button.link" target="_blank">
    <ibep-button
      [buttonProps]="{
        style: 'secondary',
        size: 'm'
      }"
      >{{ content.button.name }}</ibep-button
    >
  </a>
</div>
