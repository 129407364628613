import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeWebCoreModule } from '@ibep/fe/web/core';
import { PencilSquareComponent } from './icons/pencil-square.component';
import { BookComponent } from './icons/book.component';
import { HouseComponent } from './icons/house.component';
import { CheckCircleFillComponent } from './icons/check-circle-fill.component';
import { CheckCircleComponent } from './icons/check-circle.component';
import { SocialComponent } from './icons/socials.component';
import { ChevronUpComponent } from './icons/chevron-up.component';
import { CrossReferenceComponent } from './icons/cross-reference.component';
import { AudioComponent } from './icons/audio.component';
import { VideoComponent } from './icons/video.component';
import { PodcastComponent } from './icons/podcast.component';
import { PersonComponent } from './icons/person.component';
import { HamburgerComponent } from './icons/hamburger.component';
import { SearchComponent } from './icons/search.component';
import { ChevronDownComponent } from './icons/chevron-down.component';
import { ChevronLeftComponent } from './icons/chevron-left.component';
import { ChevronRightComponent } from './icons/chevron-right.component';
import { ArrowCurvedComponent } from './icons/arrow-curved.component';
import { ArrowRightComponent } from './icons/arrow-right.component';
import { AddTranslationComponent } from './icons/add-translation.component';
import { ArrowLeftComponent } from './icons/arrow-left.component';
import { GearComponent } from './icons/gear.component';
import { NextComponent } from './icons/next.component';
import { PrevComponent } from './icons/prev.component';
import { MoveLeftComponent } from './icons/move-left.component';
import { MoveRightComponent } from './icons/move-right.component';
import { CloseComponent } from './icons/close.component';
import { ToggleComponent } from './toggle/toggle.component';
import { PickerComponent } from './picker/picker.component';
import { ButtonComponent } from './button/button.component';
import { BoxArrowUpRightComponent } from './icons/box-arrow-up-right.component';
import { LoadingComponent } from './icons/loading.component';
import { PageLoadingComponent } from './page-loading/page-loading.component';
import { PencilComponent } from './icons/pencil.component';
import { TrashComponent } from './icons/trash.component';
import { ExpandComponent } from './icons/expand.component';
import { CheckComponent } from './icons/check.component';
import { LockComponent } from './icons/lock.component';
import { ExclamationComponent } from './icons/exclamation.component';
import { AudioFillComponent } from './icons/audio-fill.component';
import { PauseComponent } from './icons/pause.component';
import { SwipeComponent } from './icons/swipe.component';
import { ExpandableSectionComponent } from './expandable-section/expandable-section.component';
import { ZoomInComponent } from './icons/zoom-in-component';
import { ZoomOutComponent } from './icons/zoom-out-component';
import { StarComponent } from './icons/star.component';
import { GlobeComponent } from './icons/globe.component';
import { LinkSvgComponent } from './icons/link.component';
import { PdfComponent } from './icons/pdf.component';
import { ContractComponent } from './icons/contract.component';
import { ArrowAngleExpandComponent } from './icons/arrow-angle-expand.component';
import { ImagePictureComponent } from './image-picture/image-picture.component';

@NgModule({
  imports: [CommonModule, FeWebCoreModule],
  declarations: [
    AddTranslationComponent,
    ArrowCurvedComponent,
    ArrowLeftComponent,
    ArrowRightComponent,
    ChevronDownComponent,
    ChevronLeftComponent,
    ChevronRightComponent,
    GearComponent,
    NextComponent,
    PrevComponent,
    SocialComponent,
    MoveLeftComponent,
    MoveRightComponent,
    CloseComponent,
    AudioComponent,
    VideoComponent,
    PodcastComponent,
    PersonComponent,
    HamburgerComponent,
    SearchComponent,
    ChevronUpComponent,
    CheckCircleComponent,
    CheckCircleFillComponent,
    HouseComponent,
    PencilSquareComponent,
    PencilComponent,
    BookComponent,
    ToggleComponent,
    PickerComponent,
    ButtonComponent,
    CrossReferenceComponent,
    BoxArrowUpRightComponent,
    LoadingComponent,
    PageLoadingComponent,
    TrashComponent,
    CheckComponent,
    LockComponent,
    ExclamationComponent,
    AudioFillComponent,
    PauseComponent,
    SwipeComponent,
    ExpandComponent,
    ExpandableSectionComponent,
    ZoomInComponent,
    ZoomOutComponent,
    StarComponent,
    GlobeComponent,
    LinkSvgComponent,
    PdfComponent,
    ExpandComponent,
    ContractComponent,
    ArrowAngleExpandComponent,
    ImagePictureComponent,
  ],
  exports: [
    AddTranslationComponent,
    ArrowCurvedComponent,
    ArrowLeftComponent,
    ArrowRightComponent,
    ChevronDownComponent,
    ChevronLeftComponent,
    ChevronRightComponent,
    GearComponent,
    NextComponent,
    PrevComponent,
    SocialComponent,
    MoveLeftComponent,
    MoveRightComponent,
    CloseComponent,
    AudioComponent,
    VideoComponent,
    PodcastComponent,
    PersonComponent,
    HamburgerComponent,
    SearchComponent,
    ChevronUpComponent,
    CheckCircleComponent,
    CheckCircleFillComponent,
    HouseComponent,
    PencilSquareComponent,
    BookComponent,
    ToggleComponent,
    PickerComponent,
    ButtonComponent,
    CrossReferenceComponent,
    BoxArrowUpRightComponent,
    LoadingComponent,
    PageLoadingComponent,
    PencilComponent,
    TrashComponent,
    CheckComponent,
    LockComponent,
    ExclamationComponent,
    AudioFillComponent,
    PauseComponent,
    SwipeComponent,
    ExpandComponent,
    ExpandableSectionComponent,
    ZoomInComponent,
    ZoomOutComponent,
    StarComponent,
    GlobeComponent,
    LinkSvgComponent,
    PdfComponent,
    ExpandComponent,
    ContractComponent,
    ArrowAngleExpandComponent,
    ImagePictureComponent,
  ],
})
export class FeWebComponentsSharedModule {}
