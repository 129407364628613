<ng-container [ngSwitch]="parentStyle || style">
  <!-- ADD  -->
  <bible-add *ngSwitchCase="'add'">
    <ng-container [ngTemplateOutlet]="verseContent"></ng-container>
  </bible-add>
  <!-- BD  -->
  <bible-bd *ngSwitchCase="'bd'">
    <ng-container [ngTemplateOutlet]="verseContent"></ng-container>
  </bible-bd>
  <!-- BDIT  -->
  <bible-bdit *ngSwitchCase="'bdit'">
    <ng-container [ngTemplateOutlet]="verseContent"></ng-container>
  </bible-bdit>
  <!-- BK  -->
  <bible-bk *ngSwitchCase="'bk'">
    <ng-container [ngTemplateOutlet]="verseContent"></ng-container>
  </bible-bk>
  <!-- DC  -->
  <bible-dc *ngSwitchCase="'dc'">
    <ng-container [ngTemplateOutlet]="verseContent"></ng-container>
  </bible-dc>
  <!-- EM  -->
  <bible-em *ngSwitchCase="'em'">
    <ng-container [ngTemplateOutlet]="verseContent"></ng-container>
  </bible-em>
  <!-- FQ  -->
  <bible-fq *ngSwitchCase="'fq'">
    <ng-container [ngTemplateOutlet]="verseContent"></ng-container>
  </bible-fq>
  <!-- IT  -->
  <bible-it *ngSwitchCase="'it'">
    <ng-container [ngTemplateOutlet]="verseContent"></ng-container>
  </bible-it>
  <!-- JMP  -->
  <bible-jmp *ngSwitchCase="'jmp'">
    <ng-container [ngTemplateOutlet]="verseContent"></ng-container>
  </bible-jmp>
  <!-- K  -->
  <bible-k *ngSwitchCase="'k'">
    <ng-container [ngTemplateOutlet]="verseContent"></ng-container>
  </bible-k>
  <!-- LIT  -->
  <bible-lit *ngSwitchCase="'lit'">
    <ng-container [ngTemplateOutlet]="verseContent"></ng-container>
  </bible-lit>
  <!-- ND  -->
  <bible-nd *ngSwitchCase="'nd'" [ndCustomStyling]="customStyling?.nd">
    <ng-container [ngTemplateOutlet]="verseContent"></ng-container>
  </bible-nd>
  <!-- NO  -->
  <bible-no *ngSwitchCase="'no'">
    <ng-container [ngTemplateOutlet]="verseContent"></ng-container>
  </bible-no>
  <!-- PN  -->
  <bible-pn *ngSwitchCase="'pn'">
    <ng-container [ngTemplateOutlet]="verseContent"></ng-container>
  </bible-pn>
  <!-- QT  -->
  <bible-qt *ngSwitchCase="'qt'">
    <ng-container [ngTemplateOutlet]="verseContent"></ng-container>
  </bible-qt>
  <!-- RQ -->
  <bible-rq *ngSwitchCase="'rq'">
    <ng-container [ngTemplateOutlet]="verseContent"></ng-container>
  </bible-rq>
  <!-- SC  -->
  <bible-sc *ngSwitchCase="'sc'">
    <ng-container [ngTemplateOutlet]="verseContent"></ng-container>
  </bible-sc>
  <!-- SIG  -->
  <bible-sig *ngSwitchCase="'sig'">
    <ng-container [ngTemplateOutlet]="verseContent"></ng-container>
  </bible-sig>
  <!-- SLS  -->
  <bible-sls *ngSwitchCase="'sls'">
    <ng-container [ngTemplateOutlet]="verseContent"></ng-container>
  </bible-sls>
  <!-- TL  -->
  <bible-tl *ngSwitchCase="'tl'">
    <ng-container [ngTemplateOutlet]="verseContent"></ng-container>
  </bible-tl>
  <!-- WJ  -->
  <bible-wj *ngSwitchCase="'wj'" [wjCustomStyling]="customStyling?.wj">
    <ng-container [ngTemplateOutlet]="verseContent"></ng-container>
  </bible-wj>
  <!-- DEFAULT Bible Text  -->
  <span *ngSwitchDefault>
    <ng-container [ngTemplateOutlet]="verseContent"></ng-container>
  </span>
</ng-container>

<ng-template #verseContent>
  <ibep-verse-text-renderer
    *ngIf="parentStyle; else verseText"
    [style]="style"
    [content]="content"
    [customStyling]="customStyling"
  ></ibep-verse-text-renderer>
  <ng-template #verseText>{{ content }}</ng-template>
</ng-template>
