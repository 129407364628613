import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { BibleService } from '@ibep/fe/shared/bible';
import { BaseComponent } from '@ibep/fe/shared/core';
import {
  ConfigLocalizedData,
  ReaderStateData,
  ReaderStateInterface,
  ReaderStateSelectedBiblesInterface,
  SearchBibleData,
  SearchSuggestionsData,
} from '@ibep/fe/shared/data';
import {
  AnalyticsService,
  ComponentVisibilityService,
  ModalService,
} from '@ibep/fe/web/core';
import {
  AbstractAuthService,
  AbstractEnvironmentService,
  Bible,
  Brand,
  Config,
  ScreenSize,
  SearchCategory,
  SearchSuggestion,
  SearchSuggestionsWebSocketMessage,
  UserInfo,
} from '@ibep/interfaces';
import { TranslateService } from '@ngx-translate/core';
import {
  combineLatest,
  debounce,
  Observable,
  skip,
  take,
  takeUntil,
  tap,
  timer,
} from 'rxjs';

@Component({
  selector: 'ibep-searchbar-container',
  templateUrl: './searchbar-container.component.html',
})
export class SearchbarContainerComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  @ViewChild('signInTemplate', { static: false })
  signInTemp: TemplateRef<any>;

  @ViewChild('suggestionsTemplate', { static: false })
  suggestionsTemp: TemplateRef<any>;

  @ViewChild('translationPickerTemplate', { static: false })
  translationPickerTemp: TemplateRef<any>;

  private _brand: Brand;

  public config: Config;

  public bibles!: Bible[];

  public isMobile = true;

  public showSearchbar = { canSwitchVisibility: false, isVisible: false };

  public isSuggestionModalOpen: boolean;

  public isSuggestionLoading: boolean;

  public isAuthenticated = false;
  public isPremium = false;

  public hasPremiumLayer: boolean;

  public userAccountSystem: boolean;

  public userProfile: UserInfo;

  public bibleId: string;

  public bibleAbbreviation: string;

  public suggestions: SearchSuggestion[] = [];

  public searchControl = new UntypedFormControl('');

  public selectedBibles = {} as ReaderStateSelectedBiblesInterface;
  public readerState$: Observable<ReaderStateInterface> =
    this._readerState.getReaderState();

  constructor(
    private readonly _router: Router,
    private readonly _localize: LocalizeRouterService,
    private readonly _translateService: TranslateService,
    private readonly _environmentService: AbstractEnvironmentService,
    private readonly _componentVisibility: ComponentVisibilityService,
    private readonly _modalService: ModalService,
    private readonly _authService: AbstractAuthService,
    private readonly _searchSuggestionsData: SearchSuggestionsData,
    private readonly _searchBibleData: SearchBibleData,
    private readonly _readerState: ReaderStateData,
    private readonly _configData: ConfigLocalizedData,
    private readonly _bibleService: BibleService,
    private readonly _analyticsService: AnalyticsService,
    private readonly _route: ActivatedRoute
  ) {
    super();
  }

  ngOnInit(): void {
    this._route.queryParams
      .pipe(takeUntil(this._destroy))
      .subscribe((queryParams) => {
        if (queryParams['query']) {
          this.searchControl.setValue(queryParams['query']);
        } else if (this.searchControl.value) {
          this.searchControl.setValue('');
        }
      });

    this._configData
      .getConfig()
      .pipe(takeUntil(this._destroy))
      .subscribe((config: Config) => {
        this.config = config;
        this._brand = config.brand;
        this.bibles = config.bibles;
        this.userAccountSystem =
          config.brand.brandUIPreferences?.userAccountSystem;
        this.hasPremiumLayer = config.brand.brandUIPreferences?.hasPremiumLayer;
      });

    this._modalService.isOpen$
      .pipe(takeUntil(this._destroy))
      .subscribe((isOpen: boolean) => {
        if (this.isSuggestionModalOpen && !isOpen) {
          this.isSuggestionModalOpen = isOpen;
        }
      });

    combineLatest([
      this._componentVisibility.isSearchbarVisible$,
      this._environmentService.windowWidth$,
    ])
      .pipe(takeUntil(this._destroy))
      .subscribe(([showSearchbar, width]) => {
        this.isMobile = width <= ScreenSize.lg;
        if (this.isMobile) {
          this.showSearchbar = showSearchbar;
        } else {
          this.showSearchbar = { canSwitchVisibility: true, isVisible: true };
        }
      });

    this._authService.isAuthenticated$
      .pipe(takeUntil(this._destroy))
      .subscribe((isAuthenticated: boolean) => {
        this.isAuthenticated = isAuthenticated;

        if (isAuthenticated) {
          this._authService
            .getUserProfile()
            .pipe(takeUntil(this._destroy))
            .subscribe((profile: UserInfo) => {
              this.userProfile = profile;
            });
        }
      });

    this._authService.isPremium$
      .pipe(takeUntil(this._destroy))
      .subscribe((isPremium: boolean) => {
        this.isPremium = isPremium;
      });

    this._router.events.pipe(takeUntil(this._destroy)).subscribe((event) => {
      // we need it to handle Angular routing redirects outside of bible reader
      // because the readerState$ Observable does not do that

      if (event instanceof NavigationEnd && !this.isBibleReader) {
        this.updateBibleInfo();
      }
    });

    this.readerState$
      .pipe(takeUntil(this._destroy))
      .subscribe((readerState: ReaderStateInterface) => {
        if (!this.config?.isFallback) {
          this.bibleId =
            this._route.snapshot.queryParams['bibleId'] ||
            this._searchBibleData.searchBibleId ||
            readerState?.selectedBibles?.bibleIds?.[0] ||
            this._bibleService.getDefaultBibleId(this._brand, this.bibles)?.[0];
          this.bibleAbbreviation = this._bibleService.getBibleAbbrFromId(
            this.bibleId
          );
          this.selectedBibles = {
            bibleAbbrs: [this.bibleAbbreviation],
            bibleIds: [this.bibleId],
          };
        }
        this.updateBibleInfo(readerState);
      });

    this._searchSuggestionsData.suggestions$
      .pipe(takeUntil(this._destroy))
      .pipe(
        tap((suggestions: SearchSuggestion[]) => {
          this.suggestions = suggestions || [];
          const separator = this._translateService.instant(
            'ChapterVerseSeparatorCharacter'
          );

          if (separator !== ':') {
            this.suggestions.forEach((suggestion) => {
              suggestion.sourceLocal = suggestion.sourceLocal?.replace(
                ':',
                separator
              );
            });
          }
          this.isSuggestionLoading = false;
        }),
        skip(1),
        debounce(() => timer(1000))
      )
      .subscribe((s) => {
        this._analyticsService.pushGtmTag({
          event: 'view_search_autocomplete_results',
          search_term: this.searchControl.value,
          search_category: SearchCategory.BIBLE,
        });
      });

    this.searchControl.valueChanges
      .pipe(takeUntil(this._destroy))
      .subscribe((search: string) => {
        if (search) {
          this.doSearch(search);
        } else {
          this.suggestions = [];
        }
      });
  }

  private updateBibleInfo(readerState?: ReaderStateInterface): void {
    // According to the expected logic if we are doing the search being in bible reader
    // we need to use the first chosen bible in the bible reader for the search.
    // In other cases we need to use the default brand bible

    if (!this.config?.isFallback) {
      this.bibleId =
        this._route.snapshot.queryParams['bibleId'] ||
        this._searchBibleData.searchBibleId ||
        readerState?.selectedBibles?.bibleIds?.[0] ||
        this._bibleService.getDefaultBibleId(this._brand, this.bibles)?.[0];
      this.bibleAbbreviation = this._bibleService.getBibleAbbrFromId(
        this.bibleId
      );
      this.selectedBibles = {
        bibleAbbrs: [this.bibleAbbreviation],
        bibleIds: [this.bibleId],
      };
    }
  }

  public onMenuSearchBarFocus() {
    if (!this._searchSuggestionsData.isWebSocketOpen) {
      this._searchSuggestionsData.connectSuggestionsWs();
    }
  }

  public pushSuggestionClickTag({
    itemName,
    itemPath,
  }: {
    itemName: string;
    itemPath: string;
  }): void {
    this._analyticsService.pushGtmTag({
      event: 'click_search_autocomplete_results',
      search_term: this.searchControl.value,
      search_category: SearchCategory.BIBLE,
      search_item: itemName,
      search_path: itemPath,
    });
  }

  public closeWs(): void {
    this._searchSuggestionsData.closeSuggestionsWs();
  }

  public doSearch(query: string): void {
    this.isSuggestionLoading = true;
    this._searchSuggestionsData.sendMessage(this.getWsMessage(query));
  }

  private getWsMessage(query: string): SearchSuggestionsWebSocketMessage {
    return {
      action: 'getSuggestions',
      query,
      bibleId: this.bibleId,
    };
  }

  public get isBibleReader(): boolean {
    return this._router.url.includes(
      `/${this._localize.translateRoute('ROUTES.bible')}/` as string
    );
  }

  public openModal(type: string): void {
    switch (type) {
      case 'suggestions':
        this.openSuggestionsModal();
        break;
      case 'translation':
        this.openTranslationModal();
        break;
      case 'signIn':
        this.openSignInModal();
        break;
      case 'signUp':
        console.info('signUp');
        break;
      default:
        break;
    }
  }

  private openSignInModal(): void {
    this._modalService.createModal(this.signInTemp, {
      width: '3/5',
      closeOnClickOutSide: true,
    });
    const sub = this._modalService.clickedOutside$.subscribe(() => {
      this._modalService.closeModal();
      sub.unsubscribe();
    });
  }

  private openSuggestionsModal(): void {
    this.isSuggestionModalOpen = true;
    this._modalService.createModal(this.suggestionsTemp, {
      popUpStyles: 'lg:!max-w-2xl lg:mt-[62px] !p-0',
      arrowStyles: '!pt-[22px]',
      fitContentHeight: true,
      closeOnClickOutSide: true,
      hideCloseArrow: !this.isMobile,
    });
    const sub = this._modalService.clickedOutside$.subscribe(() => {
      this.isSuggestionModalOpen = false;
      this._modalService.closeModal();
      sub.unsubscribe();
    });
  }

  private openTranslationModal(): void {
    this._modalService.createModal(this.translationPickerTemp, {
      closeOnClickOutSide: true,
    });

    this._modalService.clickedOutside$.pipe(take(1)).subscribe(() => {
      this._modalService.closeModal();
      this.openSuggestionsModal();
    });

    this._modalService.isOpen$.pipe(take(1)).subscribe((isOpen) => {
      if (!isOpen) {
        this.openSuggestionsModal();
      }
    });
  }

  public closeModal(): void {
    if (this.isSuggestionModalOpen) {
      this.isSuggestionModalOpen = false;
    }

    this.closeWs();
    this._modalService.closeModal();
  }

  public closeTranslationModal(): void {
    this._modalService.closeModal();
    this.openSuggestionsModal();
  }

  public changeSelectedBibles(event: ReaderStateSelectedBiblesInterface): void {
    this.bibleAbbreviation = event.bibleAbbrs[0];
    this.bibleId = event.bibleIds[0];
    this.selectedBibles = {
      bibleAbbrs: [this.bibleAbbreviation],
      bibleIds: [this.bibleId],
    };
    this.doSearch(this.searchControl.value);
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.closeWs();
  }
}
