<h2 *ngIf="item.style === 'imt' || item.style === 'imt1'" [class]="item.style">
  <ng-content select="[innerContent]"></ng-content>
</h2>
<h3 *ngIf="item.style === 'imt2'" [class]="item.style">
  <ng-content select="[innerContent]"></ng-content>
</h3>
<h4 *ngIf="item.style === 'imt3'" [class]="item.style">
  <ng-content select="[innerContent]"></ng-content>
</h4>
<h5 *ngIf="item.style === 'imt4'" [class]="item.style">
  <ng-content select="[innerContent]"></ng-content>
</h5>
