<div class="">
  <!-- Selected chapter and bibles  -->
  <div
    class="sticky top-0 left-0 text-left pl-6 cursor-pointer bg-white z-20"
    (click)="closeModal()"
  >
    <span class="mr-3 text-xs">{{ selectedChapterLongName }}</span>
    <span *ngIf="selectedBibles?.bibleAbbrs" class="text-2xs text-grayLight"
      ><span
        *ngFor="let selectedBible of selectedBibles.bibleAbbrs; let i = index"
        class="mr-[2px]"
        >{{ selectedBible
        }}<span
          *ngIf="
            selectedBibles.bibleAbbrs.length > 1 &&
            i !== selectedBibles.bibleAbbrs.length - 1
          "
          >,
        </span></span
      >
    </span>
  </div>

  <!-- Picker tab menu -->
  <div
    class="sticky top-6 flex flex-row gap-4 h-full w-full text-grayMedium pt-2 pb-2 bg-white z-10"
  >
    <div
      class="cursor-pointer hover:text-primary hover:border-b-4 hover:border-primary py-2"
      [ngClass]="{
        'border-b-4 border-grayDark font-bold': activeTab === 'chapters'
      }"
      (click)="activeTab = 'chapters'"
    >
      {{ 'BookChapter' | translate }}
    </div>
    <div
      class="cursor-pointer hover:text-primary hover:border-b-4 hover:border-primary py-2"
      [ngClass]="{
        'border-b-4 border-grayDark font-bold': activeTab === 'translations'
      }"
      (click)="activeTab = 'translations'"
    >
      {{ 'Translations' | translate }}
    </div>
  </div>

  <!-- Book and chapter picker -->
  <div *ngIf="activeTab === 'chapters'">
    <ibep-chapter-picker
      [testaments]="bibleMetadata.testaments"
      [selectedBibles]="selectedBibles"
      [selectedChapter]="selectedChapter"
      [bibleMetadata]="bibleMetadata"
      [isBrowser]="isBrowser"
      [isBibleReaderPage]="isBibleReaderPage"
      (modalClosed)="closeModal()"
      (pushGtmTag)="onGtmTagPush($event)"
    ></ibep-chapter-picker>
  </div>

  <!-- Story picker  -->
  <!-- <ng-container *ngIf="activeTab === 'stories'"> -->
  <!-- <ibep-story-picker></ibep-story-picker> -->
  <!-- </ng-container> -->

  <!-- Translation picker  -->
  <ng-container *ngIf="activeTab === 'translations'">
    <ibep-translation-picker
      [bibles]="bibles"
      [maxActiveColumns]="maxActiveColumns"
      [selectedBibles]="selectedBibles"
      [selectedChapter]="selectedChapter"
      [isAuthenticated]="isAuthenticated"
      [isPremium]="isPremium"
      [userAccountSystem]="userAccountSystem"
      [bibleMetadata]="bibleMetadata"
      (modalClosed)="closeModal()"
      (changeSelectedBibles)="changeSelectedBibles($event)"
      (pushGtmTag)="onGtmTagPush($event)"
    ></ibep-translation-picker>
  </ng-container>
</div>
