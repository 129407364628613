import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AccountTab } from '@ibep/interfaces';

export interface Tab {
  title: string;
  value: AccountTab;
  description?: string;
}

@Component({
  selector: 'ibep-tabs',
  templateUrl: './tabs.component.html',
})
export class TabsComponent {
  @Input() activeTab: Tab;
  @Input() tabs: Tab[] = [];
  @Output() activeTabChanged = new EventEmitter<Tab>();

  onTabChanged(tab: Tab): void {
    this.activeTabChanged.emit(tab);
  }
}
