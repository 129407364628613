import { Component, Input } from '@angular/core';
import { Tag } from '@ibep/interfaces';

@Component({
  selector: 'ibep-teaser-hero',
  templateUrl: './teaser-hero.component.html',
  styleUrls: ['./teaser-hero.component.scss'],
})
export class TeaserHeroComponent {
  @Input() tags: Tag[];
  @Input() categories: any[];
  @Input() title: string;
  @Input() route: string;
  @Input() selectedTagSlug: string;
  @Input() description: string;
  @Input() template: string;
  @Input() shortenDescription = true;
}
