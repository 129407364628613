import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

/**
 * TODO:
 *
 * @export
 * @class ServerErrorInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next
      .handle(request)
      .pipe
      // retry(1)
      // TODO: fix this
      // catchError((error: HttpErrorResponse) => {
      //   if (error.status === 401) {
      //     // TODO: Handle refresh token
      //     return;
      //   } else {
      //     return throwError(error);
      //   }
      // })
      ();
  }
}
