import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import {
  AbstractBackendService,
  AbstractEnvironmentService,
  AbstractLanguageService,
  AbstractStorageService,
  BibleSearchResult,
  ResponseData,
  SearchCategory,
} from '@ibep/interfaces';
import { MONTH } from '@ibep/shared/util';
import { Observable, of } from 'rxjs';
import { map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { ConfigData } from './config.data';
import { Store, StoreSettings, StoreSettingsTTL } from './store';

@Injectable({
  providedIn: 'root',
})
export class SearchBibleData extends Store<{
  [key: string]: { [key: string]: { [key: string]: BibleSearchResult } };
}> {
  private _searchBibleId: string;

  constructor(
    storage: AbstractStorageService,
    environment: AbstractEnvironmentService,
    backendService: AbstractBackendService,
    languageService: AbstractLanguageService,
    config: ConfigData
  ) {
    super(storage, environment, backendService, languageService, {
      storeTtl: { default: 1 * MONTH } as StoreSettingsTTL,
      storeKey: 'searchResults',
      brand$: config.getBrand(),
    } as StoreSettings);
  }

  public set searchBibleId(value: string) {
    this._searchBibleId = value;
  }

  public get searchBibleId(): string {
    return this._searchBibleId;
  }

  /**
   * Get the bible search results
   *
   * @returns {Observable<SearchResult>}
   * @memberof SearchBibleData
   */
  public getSearchResults({
    query,
    bibleId,
    contentType,
    sortBy,
    searchScope,
    searchScopeId,
    limit,
    page = 1,
  }: {
    query: string;
    bibleId: string;
    contentType?: string;
    sortBy?: 'canonical' | 'relevance';
    searchScope?: string;
    searchScopeId?: string;
    limit: number;
    page?: number;
  }): Observable<BibleSearchResult> {
    const params: Params = {
      query,
      limit,
      page,
      sortBy,
    };

    if (contentType) {
      params.contentType = contentType;
    }

    if (searchScope && searchScopeId) {
      params.searchScope = searchScope;
      params.searchScopeId = searchScopeId;
    }

    if (!this.remoteDataMap$[SearchCategory.BIBLE]) {
      this.remoteDataMap$[SearchCategory.BIBLE] = {};
    }
    // To prevent multiple subscribers for same search at the same time causing duplicate http calls we store the observable in property of this class.
    this.remoteDataMap$[SearchCategory.BIBLE][bibleId] = {
      ...this.remoteDataMap$[SearchCategory.BIBLE][bibleId],
    };
    this.remoteDataMap$[SearchCategory.BIBLE][bibleId][
      `${query}-${limit}-${page}-${contentType}-${sortBy}-${searchScope}-${searchScopeId}`
    ] = this.backendService
      .get<ResponseData<BibleSearchResult>>({
        endpoint: `bibles/${bibleId}/search`,
        options: {
          params,
          headers: {
            'x-brand': this.brand.id,
            Authorization: 'Bearer anonymous',
            redisTTL: `${this.storeSettings.storeTtl.default}`,
          },
        },
      })
      .pipe(
        map((config: ResponseData<BibleSearchResult>) => config.data),
        shareReplay(1)
      );

    return this.localData$.pipe(
      take(1),
      switchMap((localData: any) => {
        // check if there is local data available
        if (
          localData?.[SearchCategory.BIBLE]?.[bibleId]?.[
            `${query}-${limit}-${page}-${contentType}-${sortBy}-${searchScope}-${searchScopeId}`
          ]
        ) {
          return of(
            localData[SearchCategory.BIBLE][bibleId][
              `${query}-${limit}-${page}-${contentType}-${sortBy}-${searchScope}-${searchScopeId}`
            ]
          );
        }
        // if no local data, fetch data from api
        return this.remoteDataMap$[SearchCategory.BIBLE][bibleId][
          `${query}-${limit}-${page}-${contentType}-${sortBy}-${searchScope}-${searchScopeId}`
        ].pipe(
          tap((remoteData: BibleSearchResult) => {
            // write returned data from api to the store
            this.setState(
              {
                [SearchCategory.BIBLE]: {
                  [bibleId]: {
                    [`${query}-${limit}-${page}-${contentType}-${sortBy}-${searchScope}-${searchScopeId}`]:
                      remoteData,
                  },
                },
              },
              true
            );
          })
        ) as Observable<BibleSearchResult>;
      })
    );
  }

  /**
   * Get the bible search results by categories
   *
   * @returns {Observable<SearchResult>}
   * @memberof SearchBibleData
   */
  public getSearchResultsByCategories({
    query,
    bibleId,
    contentType,
    sortBy,
    searchScope,
    searchScopeId,
    limit,
    page = 1,
  }: {
    query: string;
    bibleId: string;
    contentType?: string;
    sortBy?: 'canonical' | 'relevance';
    searchScope?: string;
    searchScopeId?: string;
    limit: number;
    page?: number;
  }): Observable<BibleSearchResult> {
    const params: Params = {
      categories: 'text,headers,footnotes',
      limit,
      page,
      sortBy,
    };

    if (contentType) {
      params.contentType = contentType;
    }

    if (searchScope && searchScopeId) {
      params.searchScope = searchScope;
      params.searchScopeId = searchScopeId;
    }

    if (!this.remoteDataMap$[SearchCategory.BIBLE]) {
      this.remoteDataMap$[SearchCategory.BIBLE] = {};
    }
    // To prevent multiple subscribers for same search at the same time causing duplicate http calls we store the observable in property of this class.
    this.remoteDataMap$[SearchCategory.BIBLE][bibleId] = {
      ...this.remoteDataMap$[SearchCategory.BIBLE][bibleId],
    };
    this.remoteDataMap$[SearchCategory.BIBLE][bibleId][
      `${query}-${limit}-${page}-${contentType}-${sortBy}-${searchScope}-${searchScopeId}`
    ] = this.backendService
      .get<ResponseData<BibleSearchResult>>({
        endpoint: `search/bible/${bibleId}/${query}`,
        options: {
          params,
          headers: {
            'x-brand': this.brand.id,
            Authorization: 'Bearer anonymous',
          },
        },
      })
      .pipe(
        map((config: ResponseData<BibleSearchResult>) => config.data),
        shareReplay(1)
      );

    return this.localData$.pipe(
      take(1),
      switchMap((localData: any) => {
        // check if there is local data available
        if (
          localData?.[SearchCategory.BIBLE]?.[bibleId]?.[
            `${query}-${limit}-${page}-${contentType}-${sortBy}-${searchScope}-${searchScopeId}`
          ]
        ) {
          return of(
            localData[SearchCategory.BIBLE][bibleId][
              `${query}-${limit}-${page}-${contentType}-${sortBy}-${searchScope}-${searchScopeId}`
            ]
          );
        }
        // if no local data, fetch data from api
        return this.remoteDataMap$[SearchCategory.BIBLE][bibleId][
          `${query}-${limit}-${page}-${contentType}-${sortBy}-${searchScope}-${searchScopeId}`
        ].pipe(
          tap((remoteData: BibleSearchResult) => {
            // write returned data from api to the store
            this.setState(
              {
                [SearchCategory.BIBLE]: {
                  [bibleId]: {
                    [`${query}-${limit}-${page}-${contentType}-${sortBy}-${searchScope}-${searchScopeId}`]:
                      remoteData,
                  },
                },
              },
              true
            );
          })
        ) as Observable<BibleSearchResult>;
      })
    );
  }
}
