<button
  class="rounded-3xl text-center inline-flex items-center w-full sm:w-auto justify-center overflow-hidden text-ellipsis"
  [ngClass]="{
    default: buttonProps?.style === 'default' || !buttonProps.style,
    primary: buttonProps?.style === 'primary',
    secondary: buttonProps?.style === 'secondary',
    premium: buttonProps?.style === 'premium',
    white:
      buttonProps?.style === 'white' ||
      buttonProps?.style === 'white-transparent',
    cta: buttonProps?.style === 'cta',
    gray: buttonProps?.style === 'gray',
    'gray-light': buttonProps?.disabled,
    transparent: buttonProps?.style === 'white-transparent',
    conversion: buttonProps?.style === 'conversion',
    highlight: buttonProps?.style === 'highlight',
    shadow: buttonProps.shadow,
    s: buttonProps?.size === 's',
    m: buttonProps?.size === 'm' || !buttonProps.size,
    'm-flexible': buttonProps?.size === 'm-flexible',
    l: buttonProps?.size === 'l',
    xl: buttonProps?.size === 'xl',
    inactive: buttonProps?.state === 'inactive',
    fullWidth: buttonProps?.fullWidth,
    autoWidth: buttonProps?.autoWidth,
    disabled: buttonProps?.disabled,
    locked: buttonProps?.locked
  }"
  [title]="buttonProps.title ? buttonProps.title : ''"
  [disabled]="buttonProps?.disabled"
  (click)="pushIbepButtonTag()"
>
  <!-- Button content  -->
  <ng-content></ng-content>
</button>
