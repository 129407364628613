import { Component } from '@angular/core';

@Component({
  selector: 'bible-lh',
  template: `
    <p class="lh">
      <ng-content></ng-content>
    </p>
  `,
  styleUrls: ['./lh.component.scss'],
})
/**
 * List header.
 * Some lists include an introductory and concluding remark (lf).
 * They are an integral part of the list content, but are not list items.
 * A list does not require either or both of these elements.
 * https://ubsicap.github.io/usx/parastyles.html#lh
 *
 * TODO: could not find any documentation
 * As a fallback, just in case, made it a <p> to have at least someting printed
 */
export class LhComponent {}
