import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  Component,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID,
  Renderer2,
} from '@angular/core';

@Component({
  selector: 'ibep-menu-mobile',
  templateUrl: './menu-mobile.component.html',
})
export class MenuMobileComponent implements OnInit {
  // TODO: add interfaces
  @Input() mobileMenu: any;

  @Input() availableLanguages: any;

  public menuItems: any;

  public showMobileMenu = false;

  public activeMenuItem: number[]; // index of menu item

  public activeMenuLevel: 1 | 2 | 3 | 4 = 1; // could also use activeMenuItem.lenght, but decided to keep variable for clarity

  constructor(
    private renderer: Renderer2,
    @Inject(DOCUMENT)
    public document: Document,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}

  ngOnInit(): void {
    const menu = JSON.parse(JSON.stringify(this.mobileMenu?.items));
    this._mapMenuItems(menu);
  }

  private _mapMenuItems(menuItems: any) {
    this.menuItems = menuItems.map((item: any) => {
      if (item.items.length) {
        const newItems: any = [];
        item.items.forEach((subItem: any) => {
          if (!subItem.title && subItem.items.length) {
            subItem.items.forEach((subsubItem: any) => {
              newItems.push(subsubItem);
            });
          } else {
            newItems.push(subItem);
          }
          item.items = newItems;
        });
      }
      return item;
    });
  }

  /**
   * Set the active menu item and what level it is on.
   *
   * @param {number[]} index
   * @param {(1 | 2 | 3 | 4)} activeMenuLevel
   * @memberof MenuMobileComponent
   */
  public setActiveMenuItem(index: number[], activeMenuLevel: 1 | 2 | 3 | 4) {
    this.activeMenuItem = index;
    this.activeMenuLevel = activeMenuLevel;
  }

  /**
   * Compare 2 arrays
   *
   * @param {number[]} a
   * @param {number[]} b
   * @returns
   * @memberof MenuMobileComponent
   */
  public compare(a: number[], b: number[]) {
    return JSON.stringify(a) == JSON.stringify(b);
  }

  /**
   * Toggle the menu, prevent body from scrolling when menu is open.
   *
   * @memberof MenuMobileComponent
   */
  public toggleMenu() {
    this.showMobileMenu = !this.showMobileMenu;
    if (isPlatformBrowser(this.platformId)) {
      if (this.showMobileMenu) {
        this.renderer.addClass(this.document.body, 'overflow-hidden');
      } else {
        this.renderer.removeClass(this.document.body, 'overflow-hidden');
      }
    }
    this.activeMenuItem = [];
    this.activeMenuLevel = 1;
  }
}
