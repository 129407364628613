import { Inject, Injectable } from '@angular/core';
import {
  ConfigLocalizedData,
  UserData,
  UserInfoData,
} from '@ibep/fe/shared/data';
import { AnalyticsService } from '@ibep/fe/web/core';
import {
  AbstractAuthService,
  AbstractBackendService,
  AbstractEnvironmentService,
  AbstractLanguageService,
  AbstractStorageService,
  Brand,
  UserInfo,
} from '@ibep/interfaces';
import { MINUTE } from '@ibep/shared/util';
import { Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, switchMap, take, tap } from 'rxjs/operators';
import { Auth, AuthDTO } from '../interfaces/auth.interface';
import { WINDOW } from '@ibep/fe/shared/core';

@Injectable()
export class AuthService extends AbstractAuthService {
  private _refreshInterval: NodeJS.Timer;
  private _refreshTokenSubscription: Subscription;
  private _brand: Brand = <Brand>{};
  private _baseUrl: string;

  constructor(
    private readonly _environment: AbstractEnvironmentService,
    private readonly _configData: ConfigLocalizedData,
    private readonly _userDataStore: UserData,
    private readonly _backendService: AbstractBackendService,
    private readonly _storage: AbstractStorageService,
    private readonly _userInfoData: UserInfoData,
    private readonly _analyticsService: AnalyticsService,
    private readonly _languageService: AbstractLanguageService,
    @Inject(WINDOW) private _window: Window
  ) {
    super();

    this._configData
      .getConfig()
      .pipe(
        switchMap((config) => {
          this._brand = config.brand;
          this._baseUrl = `https://${this._brand.id}${this._environment.authBaseUrl}`;
          return this._userDataStore.getAuthData();
        }),
        take(1),
        switchMap((auth) => {
          const isAuthenticated =
            this._brand.brandUIPreferences?.userAccountSystem &&
            Boolean(auth?.idToken && auth?.refreshToken);
          this._isAuthenticated.next(isAuthenticated);
          let userInfo$;
          if (isAuthenticated) {
            this._setRefreshInterval();
            userInfo$ = this._userInfoData.getUserInfo();
          } else {
            userInfo$ = of(null);
          }
          return userInfo$;
        })
      )
      .subscribe((profile: any) => {
        this._isPremium = Boolean(profile?.premium);
        this._isPremium$.next(Boolean(profile?.premium));
      });
  }

  public init() {}

  public logIn(username: string, password: string): Observable<UserInfo> {
    let authRes: AuthDTO;
    return this._backendService
      .post<AuthDTO>({
        baseUrl: this._baseUrl,
        endpoint: 'token',
        options: {
          body: {
            email: username,
            password,
            brand: this._brand.id,
            grant_type: 'password',
          },
        },
      })
      .pipe(
        switchMap((authResponse: AuthDTO) => {
          authRes = authResponse;
          return this._storage.clear();
        }),
        switchMap(() => {
          this._window.localStorage.userAuth = JSON.stringify(authRes.data);
          return this._userDataStore.setAuthData(authRes.data);
        }),
        tap(() => {
          this._setRefreshInterval();
          this._isAuthenticated.next(true);
        }),
        switchMap(() => this._userInfoData.getUserInfo()),
        tap((profile) => {
          this._isPremium = Boolean(profile.premium);
          this._isPremium$.next(Boolean(profile.premium));
          this._analyticsService.pushGtmTag({
            event: 'login',
            method: 'email',
            subscription_type: this._isPremium ? 'premium' : 'free',
          });
          // check if user has a preferred locale. If so, redirect to that locale. But only if the user is not already on that locale.
          if (
            profile.locale &&
            !profile.locale.includes(this._languageService.currentLanguage)
          ) {
            const lang = this._brand.availableLanguages.filter(
              (lang) => lang.localeCode === profile.locale
            )[0].languageCode;
            this._languageService.changeLanguage(lang, profile, authRes.data);
          }
        }),
        catchError((tokenError) => {
          if (
            tokenError.status === 400 ||
            tokenError.status === 401 ||
            tokenError.status === 403
          ) {
            this.logOut();
          }
          return throwError(() => tokenError);
        })
      );
  }

  public logOut(redirect?: boolean): void {
    if (this._refreshInterval) {
      clearInterval(this._refreshInterval);
    }
    this._refreshTokenSubscription?.unsubscribe();
    // clear the store
    this._window.localStorage.removeItem('userInfo');
    this._window.localStorage.removeItem('userAuth');
    this._storage.clear().subscribe();
    this._userInfoData.clear();

    this._analyticsService.pushGtmTag({
      event: 'logout',
      method: 'email',
      subscription_type: this._isPremium ? 'premium' : 'free',
    });

    this._userDataStore.resetData();

    if (redirect) {
      this._window.location.href = `/${
        this._languageService.isDefaultLanguage
          ? ''
          : this._languageService.currentLanguage
      }`;
    }
  }

  public refreshToken(): Observable<UserInfo> {
    return this.getTokenDetails().pipe(
      take(1),
      switchMap(({ refreshToken }) =>
        this._backendService.post<AuthDTO>({
          baseUrl: this._baseUrl,
          endpoint: 'token',
          options: {
            body: {
              grant_type: 'refresh_token',
              refresh_token: refreshToken,
              clientType: 'web',
              brand: this._brand.id,
            },
          },
        })
      ),
      switchMap((authResponse: AuthDTO) => {
        this._window.localStorage.userAuth = JSON.stringify(authResponse.data);
        return this._userDataStore.setAuthData(authResponse.data);
      }),
      switchMap(() => {
        this._userInfoData.clear();
        return this._userInfoData.getUserInfo();
      }),
      take(1),
      tap((profile) => {
        this._isPremium = Boolean(profile.premium);
        this._isPremium$.next(Boolean(profile.premium));
      }),
      catchError((refreshError) => {
        if (
          refreshError.status === 400 ||
          refreshError.status === 401 ||
          refreshError.status === 403
        ) {
          this.logOut(true);
        }
        return throwError(() => new Error(refreshError));
      })
    );
  }

  public register(body: any, captchaToken: string): Observable<any> {
    body.brandId = this._brand.id;
    return this._backendService
      .post<any>({
        baseUrl: this._baseUrl,
        endpoint: 'registration',
        options: {
          headers: {
            'g-recaptcha-response': captchaToken,
          },
          body,
        },
      })
      .pipe(
        tap(() => {
          this._analyticsService.pushGtmTag({
            event: 'sign_up',
            method: 'email',
            subscription_type: this._isPremium ? 'premium' : 'free',
          });
        })
      );
  }

  public resetPassword(body: any, captchaToken: string): Observable<any> {
    return this._backendService.post<any>({
      baseUrl: this._baseUrl,
      endpoint: 'resetPassword',
      options: {
        headers: {
          'g-recaptcha-response': captchaToken,
        },
        body,
      },
    });
  }

  public changePasswordByCode(
    newPassword: string,
    oobCode: string
  ): Observable<any> {
    return this._backendService.post<any>({
      baseUrl: this._baseUrl,
      endpoint: 'changePasswordByCode',
      options: {
        body: { newPassword, oobCode },
      },
    });
  }

  public verifyEmail(oobCode: string): Observable<any> {
    return this._backendService.post<any>({
      baseUrl: this._baseUrl,
      endpoint: 'verifyEmail',
      options: {
        body: { oobCode },
      },
    });
  }

  public getUserProfile(): Observable<UserInfo> {
    return this._userInfoData.getUserInfo();
  }

  public getTokenDetails(): Observable<Auth> {
    return this._userDataStore.getAuthData();
  }

  private _setRefreshInterval(ms = 59 * MINUTE): void {
    // we need to refresh a token because on the API side the user token will be expired in 1 hour
    if (!this._refreshInterval) {
      this._refreshInterval = setInterval(() => {
        this.isRefreshing$.next(true);
        this._refreshTokenSubscription?.unsubscribe();
        this._refreshTokenSubscription = this.refreshToken()
          .pipe(tap(() => this.isRefreshing$.next(false)))
          .subscribe();
      }, ms);
    }
  }
}
