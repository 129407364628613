import { Inject, Injectable } from '@angular/core';
import {
  AbstractBackendService,
  AbstractEnvironmentService,
  AbstractLanguageService,
  AbstractStorageService,
  ResponseData,
  UserInfo,
} from '@ibep/interfaces';
import { HOUR } from '@ibep/shared/util';
import { Observable, of } from 'rxjs';
import { map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { ConfigData } from './config.data';
import { Store, StoreSettings, StoreSettingsTTL } from './store';
import { WINDOW } from '@ibep/fe/shared/core';
import { ReaderStateData } from './reader-state.data';
import { UserPreferences } from './user-preferences.data';

@Injectable({
  providedIn: 'root',
})
export class UserInfoData extends Store<{ [key: string]: UserInfo }> {
  constructor(
    storage: AbstractStorageService,
    environment: AbstractEnvironmentService,
    backendService: AbstractBackendService,
    languageService: AbstractLanguageService,
    config: ConfigData,
    private readonly _readerStateData: ReaderStateData,
    @Inject(WINDOW) private readonly _window: Window
  ) {
    super(storage, environment, backendService, languageService, {
      storeTtl: { default: 1 * HOUR } as StoreSettingsTTL,
      storeKey: 'userInfo',
      brand$: config.getBrand(),
    } as StoreSettings);
  }

  /**
   * Get user info
   *
   * @returns {Observable<UserInfo>}
   * @memberof UserInfoData
   */
  public getUserInfo(): Observable<UserInfo> {
    this.remoteDataMap$ = this.backendService
      .get<ResponseData<UserInfo>>({
        baseUrl: `https://${this.brand.id}${this.environment.authBaseUrl}`,
        endpoint: 'userInfo',
      })
      .pipe(
        map((userInfo: ResponseData<UserInfo>) => {
          if (
            userInfo.data.premium &&
            typeof userInfo.data.premium === 'string'
          ) {
            const now = new Date().getTime();
            const premiumExpires = new Date(userInfo.data.premium).getTime();
            userInfo.data.premium = premiumExpires > now;
            userInfo.data.premiumExpirationDate = premiumExpires;
          }
          return userInfo.data;
        }),
        shareReplay(1)
      );

    return this.localData$.pipe(
      take(1),
      switchMap((localData: any) => {
        // check if there is local data available
        if (localData.info) {
          return of(localData.info);
        }
        // fallback solution for multilang brands where user has a non default preferred language and is redirect to this language (see auth service login method)
        if (
          this.environment.platform === 'browser' &&
          this._window.localStorage?.userInfo
        ) {
          const userInfo = JSON.parse(this._window.localStorage.userInfo);
          this.setState({ info: userInfo }, true);
          return of(userInfo);
        }
        // if no local data, fetch data from api
        return this.remoteDataMap$.pipe(
          take(1),
          tap((remoteData: UserInfo) => {
            // write returned data from api to the store
            this.setState({ info: remoteData }, true);
            this._window.localStorage.userInfo = JSON.stringify(remoteData);

            if (remoteData.userPreferences) {
              this._readerStateData.setReaderState({
                ebcState: { showEbc: remoteData.userPreferences.showEbc },
                readerOptions: remoteData.userPreferences.readerOptions,
                selectedChapter: remoteData.userPreferences.chapterId,
                selectedBibles: {
                  bibleIds: remoteData.userPreferences.bibleIds,
                  bibleAbbrs: [],
                },
              });
            }
          })
        ) as Observable<UserInfo>;
      })
    );
  }

  public updateUserPreferences(userPreferences: UserPreferences): void {
    this.localData$
      .pipe(
        take(1),
        tap((localData) => {
          if (localData?.info) {
            const userInfo: UserInfo = localData?.info;
            userInfo.userPreferences = userPreferences;
            this.setState({ info: userInfo }, true);
            this._window.localStorage.userInfo = JSON.stringify(userInfo);
          }
        })
      )
      .subscribe();
  }

  public clear(): void {
    this.clearState();
  }
}
