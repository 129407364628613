import { Injectable } from '@angular/core';
import {
  NotificationInterface,
  AbstractNotificationService,
} from '@ibep/interfaces';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NotificationService implements AbstractNotificationService {
  private _notification: BehaviorSubject<NotificationInterface>;

  public notification$: Observable<NotificationInterface>;

  constructor() {
    this._notification = new BehaviorSubject<NotificationInterface>(
      <NotificationInterface>{}
    );
    this.notification$ = this._notification
      .asObservable()
      // filter out the first empty object
      .pipe(filter((notification) => Object.keys(notification).length !== 0));
  }

  public notify(notification: NotificationInterface): void {
    this._notification.next(notification);
  }
}
