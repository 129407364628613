import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

interface SearchbarSetting {
  canSwitchVisibility: boolean;
  isVisible: boolean;
  height?: 'lg' | 'sm';
}

@Injectable({
  providedIn: 'root',
})
export class ComponentVisibilityService {
  // Footer
  private _footerIsVisible$ = new BehaviorSubject(true);
  public readonly isFooterVisible$ = this._footerIsVisible$.asObservable();

  public set isFooterVisible(bool: boolean) {
    this._footerIsVisible$.next(bool);
  }

  // Searchbar
  private _searchbarIsVisible$ = new BehaviorSubject({
    canSwitchVisibility: false,
    isVisible: false,
    height: 'lg',
  } as SearchbarSetting);
  public readonly isSearchbarVisible$ =
    this._searchbarIsVisible$.asObservable();

  public set isSearchbarVisible(visibilitySettings: SearchbarSetting) {
    this._searchbarIsVisible$.next(visibilitySettings);
  }

  public setStandardSearchbarVisibility(): void {
    this.isSearchbarVisible = {
      canSwitchVisibility: true,
      isVisible: false,
    };
  }

  // Header
  private _headerIsVisible$ = new BehaviorSubject(true);
  public readonly isHeaderVisible$ = this._headerIsVisible$.asObservable();

  public set isHeaderVisible(isVisible: boolean) {
    this._headerIsVisible$.next(isVisible);
  }
}
