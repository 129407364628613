import { Component } from '@angular/core';

/**
 * https://ubsicap.github.io/usx/charstyles.html#no
 *
 * @export
 * @class NoComponent
 */
@Component({
  selector: 'bible-no',
  templateUrl: './no.component.html',
  styleUrls: ['./no.component.scss'],
})
export class NoComponent {}
