import { Component } from '@angular/core';

@Component({
  selector: 'bible-mr',
  template: `
    <h3 class="mr">
      <ng-content></ng-content>
    </h3>
  `,
  styleUrls: ['./mr.component.scss'],
})
export class MrComponent {}
