import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import {
  AbstractSeoService,
  Brand,
  Config,
  Environment,
  EnvironmentType,
  PlatformType,
} from '@ibep/interfaces';
import { ConfigLocalizedData } from '@ibep/fe/shared/data';

// TODO: Some code of this service is copied from the old application, ngx translate is removed, instead the native angular Meta and Title services are used. Maybe more can be improved?
@Injectable({
  providedIn: 'root',
})
export class SeoService implements AbstractSeoService {
  private readonly _srcsExcludedForMobileAppView = [
    'matomo.rackfish.com',
    '*.acast.com',
    '*.die-bibel.de',
    'static.ads-twitter.com',
    'nexus.ensighten.com',
    'td.doubleclick.net',
    'stats.g.doubleclick.net',
    '*.jotform.com',
    'spenden.twingle.de',
    '*.customgpt.ai',
    '*.licdn.com',
    'www.gstatic.com',
    'www.google.com',
    'surveys.enalyzer.com',
    '*.omappapi.com',
    'optinmonster.com',
    'http://engage.biblijosdraugija.lt',
    'https://engage.biblijosdraugija.lt',
    'code.etracker.com',
    'www.etracker.de',
    'facebook.com',
    '*.facebook.com',
    'connect.facebook.net',
    'cdn.cookielaw.org',
    'geolocation.onetrust.com',
    'privacyportal-eu.onetrust.com',
    'file-examples.com',
    'www.googletagmanager.com',
    'www.bytesroute.com',
    'app.bytesroute.com',
    'bytesroute-backend.herokuapp.com',
    'use.typekit.net',
    '*.typekit.net',
    '*.google-analytics.com',
    'analytics.google.com',
    '*.analytics.google.com',
    '*.ingest.sentry.io',
    'd1weibdish4e0y.cloudfront.net',
    'd3t5ogzx22a7ri.cloudfront.net',
    'd1hkpuz2o5a2xw.cloudfront.net',
    'mautic.bijbelgenootschap.nl',
    'fonts.googleapis.com',
    'www.google.pt',
    'fonts.gstatic.com',
    'prezi-nocookies.com',
    '*.prezicdn.net',
    'wss://ws.hotjar.com',
    '*.hotjar.com',
    '*.hotjar.io',
    'buzzsprout.com',
    'www.buzzsprout.com',
    'cdn.onesignal.com',
    'onesignal.com',
    'bytesroute.com',
    '*.bytesroute.com',
    '*.webradiosite.com',
    'widget.spreaker.com',
    '*.spreaker.com',
    '*.canva.com',
    // prod brand subdomains
    'm.debijbel.nl',
  ];

  private readonly _srcsIncludedForMobileAppView = [
    // media players
    'www.youtube.com',
    'www.youtube-nocookie.com',
    'player.vimeo.com',
    'open.spotify.com',
    'public-player-widget.webradiosite.com',
    // forms
    '*.hs-analytics.net',
    '*.hs-banner.com',
    '*.hscollectedforms.net',
    '*.hubspot.com',
    '*.hsforms.com',
    '*.hsforms.net',
    '*.hs-scripts.com',
    'hubspot-forms-static-embed-eu1.s3.amazonaws.com',
  ];

  private _brand: Brand = <Brand>{};
  private _config: Config;
  private _domain = '';
  private _platform: PlatformType = 'browser';

  constructor(
    private configData: ConfigLocalizedData,
    private meta: Meta,
    private title: Title,
    @Inject(DOCUMENT) private dom: any,
    @Inject('ENVIRONMENT') private environment: Environment,
    private router: Router
  ) {
    this.configData.getConfig().subscribe((config: Config) => {
      this._brand = config.brand;
      this._config = config;
      this._domain = config.domain?.includes('www.')
        ? config.domain.replace('www.', '')
        : config.domain;
      this._platform = config.platform;
      // set base meta tags (these will be overridden on specific pages)
      this.setMetaTags({
        title: undefined,
        type: 'website',
        description: this._brand.summaryDescription,
      });
    });
  }

  public init() {
    // prevent crawlers from indexing if not on prod env
    if (
      this.environment.name !== EnvironmentType.prod ||
      this._domain.includes('ibep-prod.com')
    ) {
      this.meta.addTag({ name: 'robots', content: 'noindex,nofollow' });
    }
    if (this._platform === 'server') {
      this.addCSP();
    }
  }

  private addCSP(): void {
    // add CSP
    const envs = Object.values(EnvironmentType);

    const internalSrcs = envs
      .map((env) => [
        this.environment.backendApi[env],
        this.environment.webSocket[env],
        `cms.ibep-${env}.com`,
        `${this._brand.id.toLowerCase()}-auth.ibep-${env}.com`,
      ])
      .flat()
      // the filter method removes duplicated srcs
      .filter((value, index, array) => {
        return array.findIndex((el) => el === value) === index;
      });

    const src = this.router.url.includes('viewMode=mobileApp')
      ? [...internalSrcs, ...this._srcsIncludedForMobileAppView].join(' ')
      : [
          ...internalSrcs,
          ...this._srcsIncludedForMobileAppView,
          ...this._srcsExcludedForMobileAppView,
        ].join(' ');

    this.meta.addTag({
      'http-equiv': 'Content-Security-Policy',
      content: `default-src 'self' ${src} 'unsafe-inline'; img-src * data:`,
    });
  }

  /**
   * Set meta tags in Head of document
   *
   * @param {{
   *     title: string;
   *     description?: string;
   *     type: string;
   *     image?: string;
   *     canonical?: string;
   *     prev?: string;
   *     next?: string;
   *   }}
   * @memberof SeoService
   */
  public setMetaTags({
    title,
    description,
    type,
    image,
    canonical,
    prev,
    next,
  }: {
    title: string | undefined;
    description?: string;
    type: string;
    image?: string;
    canonical?: string;
    prev?: string;
    next?: string;
  }): void {
    // set the meta title
    if (title || title === undefined) {
      let metaTitle;
      let subTitle =
        this._config?.brand?.website.webDomain &&
        this._config?.brand?.website.webDomain !== title
          ? ` - ${this._config?.brand?.website.webDomain}`
          : '';
      if (title) {
        metaTitle = `${title
          ?.replace(/&#8216;/g, '')
          .replace(/&#8217;/g, '')
          .replace(/&#8220;/g, '"')
          .replace(/&#8221;/g, '"')} ${subTitle}`.trim();
      } else {
        metaTitle = `${subTitle}`.trim();
      }

      this.title.setTitle(metaTitle);
    }

    // set the meta description
    if (description && description?.length) {
      description = description?.replace(/\s+/g, ' ').trim();
      this.meta.updateTag({
        name: 'description',
        content: this.createDescription(description),
      });

      this.meta.updateTag({
        name: 'og:description',
        content: this.createDescription(description),
      });
    }

    // set the open graph meta type
    if (type?.length) {
      this.meta.updateTag({ name: 'og:type', content: type });
    }

    // set the open graph image tag
    if (image && image.length) {
      this.meta.updateTag({ name: 'og:image', content: image });
    } else if (this._brand?.uiStyle?.logoUrl) {
      this.meta.updateTag({
        name: 'og:image',
        content: this._brand?.website?.titleImageUrl,
      });
    }
    // set the open graph url tag
    this.meta.updateTag({
      name: 'og:url',
      content: `https://${this._domain}${this.router.url}`,
    });

    // If we receive a CANONICAL we need to set it
    if (canonical || canonical === undefined) {
      // Make sure we are on the browser!
      if (this._platform === 'browser') {
        if (canonical === 'dashboard') {
          canonical = '';
        }
        const link: HTMLLinkElement = this.dom.createElement('link');
        link.setAttribute('rel', 'canonical');
        link.setAttribute('id', 'canonical');
        link.setAttribute(
          'href',
          `https://${this._domain}${canonical !== undefined ? canonical : ''}`
        );

        // Check if we already have a canonical in the head:
        if (this.dom.getElementById('canonical') === null) {
          this.dom.head.appendChild(link);
        } else {
          // we need to update the present canonical with the new href
          this.dom.getElementById('canonical').href = `https://${this._domain}${
            canonical !== undefined ? canonical : ''
          }`;
        }
      }
    }

    if (prev && prev.length) {
      // Make sure we are on the browser!
      if (this._platform === 'browser') {
        const link: HTMLLinkElement = this.dom.createElement('link');
        link.setAttribute('rel', 'prev');
        link.setAttribute('id', 'prev');
        link.setAttribute('href', this._domain + prev);

        // Check if we allready have a canonical in the head:
        if (this.dom.getElementById('prev') === null) {
          this.dom.head.appendChild(link);
        } else {
          // we need to update the present canonical with the new href
          this.dom.getElementById('prev').href = this._domain + prev;
        }
      }
    }

    if (next && next.length) {
      // Make sure we are in the browser!
      if (this._platform === 'browser') {
        const link: HTMLLinkElement = this.dom.createElement('link');
        link.setAttribute('rel', 'next');
        link.setAttribute('id', 'next');
        link.setAttribute('href', this._domain + next);

        // Check if we allready have a canonical in the head:
        if (this.dom.getElementById('next') === null) {
          this.dom.head.appendChild(link);
        } else {
          // we need to update the present canonical with the new href
          this.dom.getElementById('next').href = this._domain + next;
        }
      }
    }
  }

  public removeMetaTags(): void {
    this.title.setTitle(this._brand?.website?.title);
    this.meta.removeTag('property="og:url"');
    this.meta.removeTag('property="og:image"');
    this.meta.removeTag('property="og:type"');
    this.meta.removeTag('property="og:description"');
    this.meta.removeTag('name="description"');

    const next = this.dom.getElementById('next');
    next && next.parentNode.removeChild(next);
    const prev = this.dom.getElementById('prev');
    prev && prev.parentNode.removeChild(prev);
    const canonical = this.dom.getElementById('canonical');
    canonical && canonical.parentNode.removeChild(canonical);
  }

  private createDescription(text: string, length = 160, wholeWords = true) {
    if (text === null || text === '') {
      return '';
    }

    // first remove all the html tags
    text = text.toString();
    text = text.replace(/<[^>]*>/g, '');

    if (text.length <= length) {
      return text;
    }

    const maxLength = length; // maximum number of characters to extract
    text = text.substring(0, maxLength); // trim the string to the maximum length
    if (wholeWords) {
      text = text.substring(0, Math.min(text.length, text.lastIndexOf(' '))); // re-trim if we are in the middle of a word
    }
    text += ' ...'; // add ellipses
    return text;
  }
}
