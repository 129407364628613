import { Component } from '@angular/core';
/**
 * Secondary Languege String
 * Passage of text based on a secondary language or alternate text source.
 * For example: The Nouvelle Bible Segond 2002 (NBS02) has large sections
 * of text in EZR and DAN in italics, to represent where the original text
 * is in Aramaic, not Hebrew.
 * https://ubsicap.github.io/usx/master/charstyles.html#sls
 * @export
 * @class SlsComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'bible-sls',
  templateUrl: './sls.component.html',
  styleUrls: ['./sls.component.scss'],
})
export class SlsComponent {}
