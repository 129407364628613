import { Inject, Injectable } from '@angular/core';
import {
  AbstractBackendService,
  AbstractEnvironmentService,
  AbstractLanguageService,
  AbstractStorageService,
} from '@ibep/interfaces';
import { YEAR } from '@ibep/shared/util';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store, StoreSettings, StoreSettingsTTL } from './store';
import { CopyOptionsEnum } from 'libs/fe/web/components-bible/src/lib/copy-options-picker/copy-options-picker.component';
import { WINDOW } from '@ibep/fe/shared/core';
import { UserPreferences } from './user-preferences.data';

export interface ReaderOptions {
  readingMode: 'easy' | 'advanced' | 'custom';
  readingStyle: 'light' | 'sepia' | 'dark';
  readingDirection: 'LTR' | 'RTL';
  showVerseNumbers: boolean;
  showFootnotes: boolean;
  showCrossReferences: boolean;
  showUserNotes: boolean;
  showUserHighlights: boolean;
  fontSize: 'text-bible-sm' | 'text-bible-md' | 'text-bible-lg';
  fontType: 'notoSans' | 'noto-serif' | 'quicksand' | 'fira-sans' | any;
}
export interface ReaderStateInterface {
  selectedChapter: string; // GEN.1
  selectedChapterFullname: string; // Genesis 1
  selectedBookFullname: string; // Genesis
  selectedChapterNumber: number; // 1
  selectedBibles: ReaderStateSelectedBiblesInterface;
  readerOptions: ReaderOptions;
  copyOptions: CopyOptionsEnum[];
  audioState: {
    mode: 'on' | 'off' | 'paused';
    activePlayer: number;
    playbackRate: { rate: number; index: number };
  };
  ebcState: {
    showEbc: boolean;
    selectedCategory: string;
    selectedTag: string;
  };
}

export interface ReaderStateSelectedBiblesInterface {
  bibleAbbrs: string[];
  bibleIds: string[];
}

@Injectable({
  providedIn: 'root',
})
export class ReaderStateData extends Store<ReaderStateInterface> {
  constructor(
    storage: AbstractStorageService,
    environment: AbstractEnvironmentService,
    backendService: AbstractBackendService,
    languageService: AbstractLanguageService,
    @Inject(WINDOW) private readonly _window: Window
  ) {
    super(storage, environment, backendService, languageService, {
      storeTtl: { default: 1 * YEAR } as StoreSettingsTTL,
      storeKey: 'readerState',
    } as StoreSettings);
  }

  public getReaderState(): Observable<ReaderStateInterface> {
    return this.localData$.pipe(
      map((localData) => {
        let userPreferences: UserPreferences | undefined = undefined;
        // set default readerOptions
        if (!localData.readerOptions) {
          localData.readerOptions = {
            showVerseNumbers: true,
            showFootnotes: false,
            showCrossReferences: false,
            showUserNotes: false,
            showUserHighlights: false,
            fontSize: 'text-bible-md',
            fontType: 'notoSans',
            readingStyle: 'light',
            readingDirection: 'LTR',
            readingMode: 'easy',
          };

          if (
            (this.environment.platform === 'browser' &&
              this._window.localStorage?.userInfo) ||
            this._window.localStorage?.userPreferences
          ) {
            if (this._window.localStorage?.userInfo) {
              userPreferences = JSON.parse(
                this._window.localStorage.userInfo
              )?.userPreferences;
            }

            if (
              !userPreferences &&
              this._window.localStorage?.userPreferences
            ) {
              userPreferences = JSON.parse(
                this._window.localStorage.userPreferences
              );
            }

            if (localData.ebcState) {
              localData.ebcState.showEbc = Boolean(userPreferences?.showEbc);
            }

            if (userPreferences?.readerOptions) {
              localData.readerOptions = {
                ...localData.readerOptions,
                ...userPreferences.readerOptions,
              };
            }

            if (userPreferences?.chapterId) {
              localData.selectedChapter = userPreferences.chapterId;
            }

            if (userPreferences?.bibleIds?.length) {
              localData.selectedBibles = {
                bibleIds: userPreferences.bibleIds,
                bibleAbbrs: [],
              };
            }
          }
        }

        if (!localData.audioState) {
          localData.audioState = {
            mode: 'off',
            activePlayer: 0,
            playbackRate: { rate: 1, index: 0 },
          };
        }
        if (!localData.ebcState) {
          localData.ebcState = {
            showEbc: Boolean(userPreferences?.showEbc),
            selectedCategory: '',
            selectedTag: '',
          };
        }
        return localData;
      })
    );
  }

  public setReaderState(data: any) {
    this.setState(data, true);
  }
}
