// TTL in ms
export const MINUTE = 60 * 1000;
export const HOUR = 60 * MINUTE;
export const DAY = 24 * HOUR;
export const WEEK = 7 * DAY;
export const MONTH = 30 * DAY;
export const YEAR = 365 * DAY;

export const BOOKS: { [key: string]: string } = {
  '1Mose': 'GEN',
  '1.mose': 'GEN',
  '1.Mose': 'GEN',
  '1mose': 'GEN',
  Gen: 'GEN',
  gen: 'GEN',
  '2Mose': 'EXO',
  '2mose': 'EXO',
  '2.mose': 'EXO',
  '2.Mose': 'EXO',
  Ex: 'EXO',
  ex: 'EXO',
  '3Mose': 'LEV',
  '3mose': 'LEV',
  '3.mose': 'LEV',
  '3.Mose': 'LEV',
  Lev: 'LEV',
  lev: 'LEV',
  '4Mose': 'NUM',
  '4mose': 'NUM',
  '4.mose': 'NUM',
  '4.Mose': 'NUM',
  Num: 'NUM',
  num: 'NUM',
  '5Mose': 'DEU',
  '5mose': 'DEU',
  '5.mose': 'DEU',
  '5.Mose': 'DEU',
  Dtn: 'DEU',
  dtn: 'DEU',
  Jos: 'JOS',
  jos: 'JOS',
  Ri: 'JDG',
  ri: 'JDG',
  Rut: 'RUT',
  rut: 'RUT',
  '1Sam': '1SA',
  '1sam': '1SA',
  '1.sam': '1SA',
  '1.Sam': '1SA',
  '2Sam': '2SA',
  '2sam': '2SA',
  '2.sam': '2SA',
  '2.Sam': '2SA',
  '1Kön': '1KI',
  '1kön': '1KI',
  '1.kön': '1KI',
  '1.Kön': '1KI',
  '1.K%C3%B6n': '1KI',
  '2Kön': '2KI',
  '2kön': '2KI',
  '2.kön': '2KI',
  '2.Kön': '2KI',
  '2.K%C3%B6n': '2KI',
  '1Chr': '1CH',
  '1chr': '1CH',
  '1.chr': '1CH',
  '1.Chr': '1CH',
  '2Chr': '2CH',
  '2chr': '2CH',
  '2.chr': '2CH',
  '2.Chr': '2CH',
  Esra: 'EZR',
  esra: 'EZR',
  Esr: 'EZR',
  esr: 'EZR',
  Neh: 'NEH',
  neh: 'NEH',
  Est: 'EST',
  est: 'EST',
  Hiob: 'JOB',
  hiob: 'JOB',
  Hi: 'JOB',
  hi: 'JOB',
  Ijob: 'JOB',
  ijob: 'JOB',
  Ps: 'PSA',
  ps: 'PSA',
  Spr: 'PRO',
  spr: 'PRO',
  Pred: 'ECC',
  pred: 'ECC',
  Hld: 'SNG',
  hld: 'SNG',
  Hhld: 'SNG',
  hhld: 'SNG',
  Jes: 'ISA',
  jes: 'ISA',
  Jer: 'JER',
  jer: 'JER',
  Klgl: 'LAM',
  klgl: 'LAM',
  Hes: 'EZK',
  hes: 'EZK',
  Ez: 'EZK',
  ez: 'EZK',
  Dan: 'DAN',
  dan: 'DAN',
  Hos: 'HOS',
  hos: 'HOS',
  Joel: 'JOL',
  joel: 'JOL',
  Jo: 'JOL',
  jo: 'JOL',
  Am: 'AMO',
  am: 'AMO',
  Obd: 'OBA',
  obd: 'OBA',
  Ob: 'OBA',
  ob: 'OBA',
  Jona: 'JON',
  jona: 'JON',
  Jon: 'JON',
  jon: 'JON',
  Mi: 'MIC',
  mi: 'MIC',
  Nah: 'NAM',
  nah: 'NAM',
  Hab: 'HAB',
  hab: 'HAB',
  Zef: 'ZEP',
  zef: 'ZEP',
  Hag: 'HAG',
  hag: 'HAG',
  Sach: 'ZEC',
  sach: 'ZEC',
  Mal: 'MAL',
  mal: 'MAL',
  Mt: 'MAT',
  mt: 'MAT',
  Mk: 'MRK',
  mk: 'MRK',
  Lk: 'LUK',
  lk: 'LUK',
  Joh: 'JHN',
  joh: 'JHN',
  Apg: 'ACT',
  apg: 'ACT',
  Röm: 'ROM',
  röm: 'ROM',
  '1Kor': '1CO',
  '1kor': '1CO',
  '1.kor': '1CO',
  '1.Kor': '1CO',
  '2Kor': '2CO',
  '2.kor': '2CO',
  '2.Kor': '2CO',
  '2kor': '2CO',
  Gal: 'GAL',
  gal: 'GAL',
  Eph: 'EPH',
  eph: 'EPH',
  Phil: 'PHP',
  phil: 'PHP',
  Kol: 'COL',
  kol: 'COL',
  '1Thess': '1TH',
  '1.thess': '1TH',
  '1.Thess': '1TH',
  '1thess': '1TH',
  '2Thess': '2TH',
  '2.thess': '2TH',
  '2.Thess': '2TH',
  '2thess': '2TH',
  '1Tim': '1TI',
  '1.tim': '1TI',
  '1.Tim': '1TI',
  '1tim': '1TI',
  '2Tim': '2TI',
  '2.tim': '2TI',
  '2.Tim': '2TI',
  '2tim': '2TI',
  Tit: 'TIT',
  tit: 'TIT',
  Phlm: 'PHM',
  phlm: 'PHM',
  '1Petr': '1PE',
  '1.petr': '1PE',
  '1.Petr': '1PE',
  '1petr': '1PE',
  '2Petr': '2PE',
  '2.petr': '2PE',
  '2.Petr': '2PE',
  '2petr': '2PE',
  '1Joh': '1JN',
  '1.joh': '1JN',
  '1.Joh': '1JN',
  '1joh': '1JN',
  '2Joh': '2JN',
  '2.joh': '2JN',
  '2.Joh': '2JN',
  '2joh': '2JN',
  '3Joh': '3JN',
  '3.joh': '3JN',
  '3.Joh': '3JN',
  '3joh': '3JN',
  Hebr: 'HEB',
  hebr: 'HEB',
  Jak: 'JAS',
  jak: 'JAS',
  Jud: 'JUD',
  jud: 'JUD',
  Offb: 'REV',
  offb: 'REV',
  Apk: 'REV',
  apk: 'REV',
  Jdt: 'JDT',
  jdt: 'JDT',
  Weish: 'WIS',
  weish: 'WIS',
  Tob: 'TOB',
  tob: 'TOB',
  Sir: 'SIR',
  sir: 'SIR',
  Bar: 'BAR',
  bar: 'BAR',
  '1Makk': '1MA',
  '1makk': '1MA',
  '1.makk': '1MA',
  '1.Makk': '1MA',
  '2Makk': '2MA',
  '2makk': '2MA',
  '2.makk': '2MA',
  '2.Makk': '2MA',
  StzuEst: 'ESG',
  stzuEst: 'ESG',
  ZusEst: 'ESG',
  zusEst: 'ESG',
  GebMan: 'MAN',
  gebMan: 'MAN',
  StzuDan: 'DAG',
  stzuDan: 'DAG',
  ZusDan: 'DAG',
  zusDan: 'DAG',
};
