<ng-container *ngIf="config && !config?.isFallback">
  <ibep-menu-searchbar
    *ngIf="showSearchbar.isVisible"
    (handleOpenModal)="openModal($event)"
    (handleFocus)="onMenuSearchBarFocus()"
    [searchControl]="searchControl"
    [isSuggestionModalOpen]="isSuggestionModalOpen"
    [isAuthenticated]="isAuthenticated"
    [isPremium]="isPremium"
    [hasPremiumLayer]="hasPremiumLayer"
    [userAccountSystem]="userAccountSystem"
    [userProfile]="userProfile"
    [displaySticky]="!isMobile && isBibleReader"
  ></ibep-menu-searchbar>

  <ng-template #suggestionsTemplate>
    <ibep-search-suggestions
      [searchControl]="searchControl"
      [items]="suggestions"
      [bibleId]="bibleId"
      [bibleAbbr]="bibleAbbreviation"
      [isSuggestionLoading]="isSuggestionLoading"
      (openModal)="openModal($event)"
      (closeModal)="closeModal()"
      (clickSuggestion)="pushSuggestionClickTag($event)"
    ></ibep-search-suggestions>
  </ng-template>

  <ng-template #translationPickerTemplate>
    <ibep-translation-picker
      [bibles]="bibles"
      [selectedBibles]="selectedBibles"
      [isSearchPage]="true"
      [userAccountSystem]="userAccountSystem"
      [isAuthenticated]="isAuthenticated"
      [isPremium]="isPremium"
      (modalClosed)="closeTranslationModal()"
      (changeSelectedBibles)="changeSelectedBibles($event)"
    ></ibep-translation-picker>
  </ng-template>
</ng-container>
