<div class="my-6 flex flex-col lg:flex-row">
  <div
    *ngFor="let column of props.block.innerBlocks"
    [style.width]="
      isMobile
        ? null
        : column?.attrs?.width || 100 / props.block.innerBlocks.length + '%'
    "
  >
    <ibep-render-gutenberg-blocks
      [blocks]="column.innerBlocks"
    ></ibep-render-gutenberg-blocks>
  </div>
</div>
