import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import {
  AbstractEnvironmentService,
  Environment,
  EnvironmentType,
  PlatformType,
} from '@ibep/interfaces';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { fromEvent, ReplaySubject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { WINDOW } from './../../providers/window.provider';
import { CookieService } from 'ngx-cookie-service';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { YEAR } from '@ibep/shared/util';

/**
 * TODO: this service should eventually also handle mobile applications (if possible?)
 *
 * @export
 * @class EnvironmentService
 * @implements {AbstractEnvironmentService}
 */
@Injectable({
  providedIn: 'root',
})
export class EnvironmentService implements AbstractEnvironmentService {
  private _request: Request;
  private _domain: string;
  private _platform: PlatformType;
  private _environment!: EnvironmentType;
  private _authBaseUrl!: string;
  private _apiBaseUrl!: string;
  private _recaptchaKey!: string;
  private _apiGlobalHeaders!: { [header: string]: string | string[] };
  private _isPreview: boolean;

  private _webSocketUrl: string;
  private _websocketApiKey: string;

  private _windowWidth: number;
  private windowWidthSub = new ReplaySubject<number>(1);

  public windowWidth$ = this.windowWidthSub.asObservable();

  constructor(
    @Optional()
    @Inject(REQUEST)
    request: Request,
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(WINDOW) private window: Window,
    @Inject('ENVIRONMENT') private env: Environment,
    private readonly _cookieService: CookieService,
    private readonly _ssrCookieService: SsrCookieService
  ) {
    this._request = request;
    // set the platform and domain
    if (isPlatformServer(this.platformId)) {
      this._platform = 'server';
      const host = this._request?.headers['host']! as string;
      // check if we are on wordpress content preview page
      this._isPreview =
        request.url.includes('/preview') ||
        request.url.includes('preview=true');
      // nbg.ibep-test.com or nbg.bibleplatform.local:5000
      this._domain = host.includes(':') ? host.split(':')[0] : host;
      this._windowWidth = this._ssrCookieService.check('windowWidth')
        ? Number(this._ssrCookieService.get('windowWidth'))
        : 0;

      this.windowWidthSub.next(this._windowWidth);
    } else {
      this._platform = 'browser';
      this._domain = this.window.location.hostname;
      // check if we are on wordpress content preview page
      this._isPreview =
        this.window.location.href.includes('/preview') ||
        this.window.location.href.includes('preview=true');

      fromEvent(this.window, 'resize')
        .pipe(
          map((event) => (<Window>event.target).innerWidth),
          startWith(this.window.innerWidth)
        )
        .subscribe((windowWidth) => {
          if (this._windowWidth !== windowWidth) {
            this._cookieService.set(
              'windowWidth',
              String(windowWidth),
              +new Date() + YEAR
            );
            this._windowWidth = windowWidth;
            this.windowWidthSub.next(windowWidth);
          }
        });
    }

    // see if there is a env prefix in url, for example: prod-nbg
    const prefix = this._domain?.split('-')[0] as EnvironmentType;
    // set the environment
    this._switchDomain(prefix);
    // if no prefix
    if (!this._environment) {
      // and we are on local environment, set defaults
      if (this._domain.includes('local')) {
        this._switchDomain(EnvironmentType.test);
      } else {
        // if not local, we should be on aws environment
        const split = this._domain?.split('-');
        const envPrefix = split[split.length - 1].split(
          '.'
        )[0] as EnvironmentType;
        this._switchDomain(envPrefix);
        if (!this._environment) {
          this._switchDomain(EnvironmentType.prod);
        }
      }
    }
  }

  private _switchDomain(prefix: EnvironmentType): void {
    switch (prefix) {
      case EnvironmentType.dev:
        this._environment = prefix;
        this._apiBaseUrl = this.env.backendApi.dev;
        this._authBaseUrl = this.env.authApi.dev;
        this._apiGlobalHeaders = {
          'X-api-key': this.env.backendApi.key,
        };
        this._webSocketUrl = this.env.webSocket.dev;
        this._websocketApiKey = this.env.webSocket.key;
        this._recaptchaKey = this.env.recaptchaKey.dev;
        break;
      case EnvironmentType.test:
        this._environment = prefix;
        this._apiBaseUrl = this.env.backendApi.test;
        this._authBaseUrl = this.env.authApi.test;
        this._apiGlobalHeaders = {
          'X-api-key': this.env.backendApi.key,
        };
        this._webSocketUrl = this.env.webSocket.test;
        this._websocketApiKey = this.env.webSocket.key;
        this._recaptchaKey = this.env.recaptchaKey.test;
        break;
      case EnvironmentType.staging:
        this._environment = prefix;
        this._apiBaseUrl = this.env.backendApi.staging;
        this._authBaseUrl = this.env.authApi.staging;
        this._apiGlobalHeaders = {
          'X-api-key': this.env.backendApi.key,
        };
        this._webSocketUrl = this.env.webSocket.staging;
        this._websocketApiKey = this.env.webSocket.key;
        this._recaptchaKey = this.env.recaptchaKey.staging;
        break;
      case EnvironmentType.prod:
        this._environment = prefix;
        this._apiBaseUrl = this.env.backendApi.prod;
        this._authBaseUrl = this.env.authApi.prod;
        this._apiGlobalHeaders = {
          'X-api-key': this.env.backendApi.prodKey,
        };
        this._webSocketUrl = this.env.webSocket.prod;
        this._websocketApiKey = this.env.webSocket.prodKey;
        this._recaptchaKey = this.env.recaptchaKey.prod;
        break;
      default:
        break;
    }
  }

  public get domain(): string {
    return this._domain;
  }

  public get platform(): PlatformType {
    return this._platform;
  }

  public get environment(): EnvironmentType {
    return this._environment;
  }

  public get apiBaseUrl(): string {
    return this._apiBaseUrl;
  }

  public get isPreview(): boolean {
    return this._isPreview;
  }

  public get authBaseUrl(): string {
    return this._authBaseUrl;
  }

  public get apiGlobalHeaders(): { [header: string]: string | string[] } {
    return this._apiGlobalHeaders;
  }

  public get webSocketUrl(): string {
    return this._webSocketUrl;
  }

  public get websocketApiKey(): string {
    return this._websocketApiKey;
  }

  public get recaptchaKey(): string {
    return this._recaptchaKey;
  }

  public get initRedis(): boolean {
    return this.env.redis.initRedis;
  }
}
