import { Component, Input } from '@angular/core';

@Component({
  selector: 'ibep-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss'],
})
export class HeroComponent {
  @Input() set heroProps(value: {
    title: string;
    subtitle: string;
    author?: string;
    authors?: any[];
    showPublishDate: boolean;
    publishDate: string;
    dateFormat: string;
    tags?: string[];
    image?: any;
    minutes: string;
    sidebarActive?: boolean;
    contentType?: string;
  }) {
    this.props = value;
    if (value.image) {
      this.heroType = 'image';
    } else {
      this.heroType = 'noImage';
    }
  }

  public decodeSlug(slug: string): string {
    return decodeURIComponent(slug);
  }

  public props: any;
  public heroType: 'image' | 'noImage' | 'simple';
}
