import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ibep-logo',
  templateUrl: './logo.component.html',
})
export class LogoComponent implements OnInit {
  @Input() imageSrc = '/assets/logo_ubs@2x.png';
  @Input() imageAlt = '';
  @Input() link = '/';
  @Input() currentLanguage = '';
  @Input() isDefaultLanguage: boolean;
  @Input() logoStyles: string;

  ngOnInit() {
    if (!this.isDefaultLanguage) {
      this.link = `/${this.currentLanguage}`;
    }
  }
}
