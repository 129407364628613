<div class="font-bold text-left mb-4 ml-11">
  {{ title || ('Tags' | translate) }}
</div>

<div
  *ngFor="let tag of tags"
  class="flex items-center py-3 border-b border-grayExtraLight"
>
  <input
    [id]="tag.id"
    [name]="tag.id"
    type="checkbox"
    [checked]="tag.checked"
    (click)="toggleTag(tag)"
    class="focus:ring-primary h-4 w-4 text-primary border-grayLight rounded cursor-pointer mx-2.5"
  />

  <label
    [for]="tag.id"
    class="cursor-pointer flex justify-between flex-row text-left text-grayMedium line-clamp-1 hover:text-primary"
    [innerHTML]="tag.name"
  >
  </label>
</div>
