<div class="relative bg-cta text-white py-2.5 px-5 lg:px-0 text-xs">
  <div
    class="max-w-container mx-auto lg:flex lg:justify-between lg:px-4 xl:p-0"
  >
    <div
      class="pb-2 lg:pb-0"
      *ngFor="let bullet of triggerBarSettings?.bullets || []"
    >
      <ibep-check-circle-fill-svg
        class="mr-2"
        svgStyles="fill-white"
      ></ibep-check-circle-fill-svg>
      <!-- bullet for desktop view -->
      <span class="hidden lg:inline">{{ bullet.desktop }}</span>
      <!-- bullet for mobile view -->
      <span class="lg:hidden">{{ bullet.mobile || bullet.desktop }}</span>
    </div>
    <div
      class="lg:min-w-[200px] flex items-center justify-between lg:block lg:mr-5"
      [ngClass]="{ 'ml-auto': !triggerBarSettings?.bullets?.length }"
    >
      <span class="hidden lg:inline-flex font-bold">
        {{ triggerBarSettings?.tryItOutButton?.name }}
        <ibep-arrow-curved-svg class="-mt-2 ml-3"></ibep-arrow-curved-svg>
      </span>
      <ibep-button
        class="lg:hidden"
        [buttonProps]="{
          pushGtm: true,
          content: 'CreateAccount' | translate,
          style: 'secondary',
          size: 'm'
        }"
        (click)="openModal('signUp')"
      >
        <b class="text-xs">{{ 'CreateAccount' | translate }}</b>
      </ibep-button>
      <a
        *ngIf="
          triggerBarSettings?.moreInfoButton?.name &&
          triggerBarSettings?.moreInfoButton?.link
        "
        class="ml-4 underline"
        [href]="triggerBarSettings?.moreInfoButton?.link"
        (click)="
          onGtmTagPush({
            event: 'button_click',
            button_url: triggerBarSettings?.moreInfoButton?.link,
            button_name: 'more_info',
            button_text: triggerBarSettings?.moreInfoButton?.name
          })
        "
      >
        {{ triggerBarSettings?.moreInfoButton?.name }}
      </a>
    </div>
  </div>
  <span class="cursor-pointer" (click)="onCloseBar()">
    <ibep-close-svg
      class="absolute top-2 right-1"
      svgStyles="w-6 h-6"
    ></ibep-close-svg>
  </span>
</div>
