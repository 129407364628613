import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { HammerModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { Angulartics2Module } from 'angulartics2';
import { HorizontalSwiperDirective } from './directives/horizontal-swiper/horizontal-swiper.directive';
import { InfiniteScrollDirective } from './directives/infinite-scroll/infinite-scroll.directive';
import { HorizontalHammerConfig } from './hammer.config';
import { ObserveVisibilityDirective } from './directives/observe-visibility/observe-visibility.directive';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { IgnoreNotPublishedPipe } from './pipes/ignore-not-published.pipe';
import { FormatChapterTitlePipe } from './pipes/format-chapter-title.pipe';
import { SortPipe } from './pipes/sort-array.pipe';
import { HighlightSearchPipe } from './pipes/highlight-search.pipe';
import { HideTillInitDirective } from './directives/hide-till-init/hide-till-init.directive';
import { LocalizeDatePipe } from './pipes/localize-date.pipe';
import { TildeToSpacePipe } from './pipes/tilde-to-space.pipe';

@NgModule({
  imports: [
    CommonModule,
    Angulartics2Module.forRoot(),
    HammerModule,
    RouterModule,
    TranslateModule.forChild(),
    LocalizeRouterModule,
  ],
  declarations: [
    InfiniteScrollDirective,
    HorizontalSwiperDirective,
    ObserveVisibilityDirective,
    HideTillInitDirective,
    SafeHtmlPipe,
    SortPipe,
    IgnoreNotPublishedPipe,
    FormatChapterTitlePipe,
    HighlightSearchPipe,
    LocalizeDatePipe,
    TildeToSpacePipe,
  ],
  exports: [
    InfiniteScrollDirective,
    HorizontalSwiperDirective,
    ObserveVisibilityDirective,
    HideTillInitDirective,
    SafeHtmlPipe,
    SortPipe,
    IgnoreNotPublishedPipe,
    FormatChapterTitlePipe,
    HighlightSearchPipe,
    LocalizeDatePipe,
    TildeToSpacePipe,
  ],
  providers: [
    { provide: HAMMER_GESTURE_CONFIG, useClass: HorizontalHammerConfig },
  ],
})
export class FeWebCoreModule {}
