import { Component } from '@angular/core';

@Component({
  selector: 'ibep-add-translation-svg',
  template: `
    <svg
      ibepHideTillInit
      class="h-5 w-5 m-auto"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <title>icon-add-translation</title>
      <g stroke="none" stroke-width="1" fill="currentColor">
        <g transform="translate(-580.000000, -17.000000)">
          <g id="Group" transform="translate(415.000000, 3.857143)">
            <g id="Add-Translation" transform="translate(152.000000, 4.142857)">
              <g
                id="icon-add-translation"
                transform="translate(13.000000, 9.000000)"
              >
                <g id="book" fill="currentColor" fill-rule="nonzero">
                  <path
                    d="M1,1.93212885 C1.885,1.56212885 3.154,1.16312885 4.388,1.03912885 C5.718,0.905128849 6.846,1.10212885 7.5,1.79112885 L7.5,11.5371288 C6.565,11.0071288 5.38,10.9341288 4.287,11.0441288 C3.107,11.1641288 1.917,11.5051288 1,11.8551288 L1,1.93212885 Z M8.5,1.79112885 C9.154,1.10212885 10.282,0.905128849 11.612,1.03912885 C12.846,1.16312885 14.115,1.56212885 15,1.93212885 L15,11.8551288 C14.082,11.5051288 12.893,11.1631288 11.713,11.0451288 C10.619,10.9341288 9.435,11.0061288 8.5,11.5371288 L8.5,1.79112885 Z M8,0.887128849 C7.015,0.0401288486 5.587,-0.0858711514 4.287,0.0441288486 C2.773,0.197128849 1.245,0.716128849 0.293,1.14912885 C0.114601736,1.23026556 0,1.40814644 0,1.60412885 L0,12.6041288 C0,12.7741228 0.0864615871,12.9324473 0.229410819,13.0244443 C0.372360051,13.1164414 0.552258458,13.1295063 0.707,13.0591288 C1.589,12.6591288 3.01,12.1781288 4.387,12.0391288 C5.796,11.8971288 6.977,12.1261288 7.61,12.9161288 C7.70489838,13.0344145 7.84835174,13.1032391 8,13.1032391 C8.15164826,13.1032391 8.29510162,13.0344145 8.39,12.9161288 C9.023,12.1261288 10.204,11.8971288 11.612,12.0391288 C12.99,12.1781288 14.412,12.6591288 15.293,13.0591288 C15.4477415,13.1295063 15.6276399,13.1164414 15.7705892,13.0244443 C15.9135384,12.9324473 16,12.7741228 16,12.6041288 L16,1.60412885 C16,1.40814644 15.8853983,1.23026556 15.707,1.14912885 C14.755,0.716128849 13.227,0.197128849 11.713,0.0441288486 C10.413,-0.0868711514 8.985,0.0401288486 8,0.887128849 Z"
                    id="Shape"
                  ></path>
                </g>
                <g id="Group-2" transform="translate(10.671849, 3.000000)">
                  <ellipse
                    id="Oval"
                    fill="#FFFFFF"
                    cx="6.13747954"
                    cy="6.0952381"
                    rx="6.13747954"
                    ry="6.0952381"
                  ></ellipse>
                  <g
                    id="+"
                    transform="translate(2.243044, 1.857143)"
                    fill="currentColor"
                    fill-rule="nonzero"
                  >
                    <polygon
                      id="Path"
                      points="3.35064935 4.65800866 0 4.65800866 0 3.35930736 3.35064935 3.35930736 3.35064935 0 4.64935065 0 4.64935065 3.35930736 8 3.35930736 8 4.65800866 4.64935065 4.65800866 4.64935065 8 3.35064935 8"
                    ></polygon>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  `,
  styles: [],
})
export class AddTranslationComponent {}
