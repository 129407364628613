import { Component } from '@angular/core';

@Component({
  selector: 'bible-qa',
  template: `
    <p class="qa">
      <ng-content></ng-content>
    </p>
  `,
  styleUrls: ['./qa.component.scss'],
})
export class QaComponent {}
