import {
  Component,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { ReaderStateData, ReaderStateInterface } from '@ibep/fe/shared/data';
import { ModalService } from '@ibep/fe/web/core';
import {
  CopyOption,
  CopyOptionsEnum,
} from '../copy-options-picker/copy-options-picker.component';

@Component({
  selector: 'ibep-bible-selection-toolbar',
  templateUrl: './bible-selection-toolbar.component.html',
})
export class BibleSelectionToolbarComponent implements OnChanges {
  @Input() selectedChapterFullName: string;
  @Input() selectedVerses: string[];
  @Input() selectedBible: string;
  @Input() readerState: ReaderStateInterface;
  @Input() isAuthenticated: boolean;
  @Input() isPremium: boolean;
  @Input() userAccountSystem: boolean;
  @Input() allowBibleCopyingFor: 'all' | 'registered' | 'premium';
  @Input() copyOptions: CopyOption[] = [];

  @Output() toolbarClosed = new EventEmitter<boolean>();
  @Output() copySelection = new EventEmitter<CopyOptionsEnum[]>();
  @Output() shareSelection = new EventEmitter<string>();
  @Output() pushGtmTag = new EventEmitter<object>();
  @Output() addUserNote = new EventEmitter<boolean>();
  @Output() addUserHighlight = new EventEmitter<string>();
  @Output() deleteUserHighlight = new EventEmitter<boolean>();

  @ViewChild('copyPickerTemplate', { static: false })
  copyPickerTemp: TemplateRef<any>;

  public tab: 'start' | 'share' = 'start';
  public copied = false;

  constructor(
    private readonly _router: Router,
    private readonly _readerState: ReaderStateData,
    private readonly _modalService: ModalService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedVerses) {
      this.tab = 'start';
      this.copied = false;
    }
  }

  /**
   * Copy verse (range)
   *
   * @memberof BibleSelectionToolbarComponent
   */
  public copy(): void {
    this.copied = true;
    this.onGtmTagPush('bibleverse_copy');
    this.copySelection.emit(
      this.copyOptions
        .filter((copyOption) => copyOption.checked)
        .map((copyOption) => copyOption.id)
    );
  }

  public copyOptionsChanged(event: CopyOptionsEnum[]): void {
    this.readerState.copyOptions = event;
    this._readerState.setReaderState(this.readerState);
  }

  /**
   * Share verse (range)
   *
   * @memberof BibleSelectionToolbarComponent
   */
  public share(platform: string): void {
    this.shareSelection.emit(platform);
  }

  /**
   * Go to share tab in toolbar
   *
   * @memberof BibleSelectionToolbarComponent
   */
  public onShareClick(): void {
    this.tab = 'share';
    this.copied = false;
    this.onGtmTagPush('bibleverse_share');
  }

  /**
   * Add a user note
   *
   * @memberof BibleSelectionToolbarComponent
   */
  public addNote(): void {
    if (this.isAuthenticated) {
      this.addUserNote.emit(true);
      this.close();
      this.onGtmTagPush('bibleverse_note');
    } else {
      this._router.navigate([], {
        queryParams: { view: 'signUp' },
      });
    }
  }

  /**
   * Add an user highlight
   *
   * @param {string} color
   * @memberof BibleSelectionToolbarComponent
   */
  public addHighlight(color: string): void {
    if (this.isAuthenticated) {
      this.addUserHighlight.emit(color);
      this.onGtmTagPush('bibleverse_highlight');
    } else {
      this._router.navigate([], {
        queryParams: { view: 'signUp' },
      });
    }
  }

  /**
   * Delete an user highlight
   *
   * @memberof BibleSelectionToolbarComponent
   */
  public deleteHighlight(): void {
    if (this.isAuthenticated) {
      this.deleteUserHighlight.emit(true);
    } else {
      this._router.navigate([], {
        queryParams: { view: 'signUp' },
      });
    }
  }

  /**
   * Close the toolbar
   *
   * @memberof BibleSelectionToolbarComponent
   */
  public close(): void {
    this.toolbarClosed.emit(true);
    this.copied = false;
  }

  public openModal(): void {
    if (
      this.allowBibleCopyingFor === 'all' ||
      (this.allowBibleCopyingFor === 'registered' && this.isAuthenticated) ||
      (this.allowBibleCopyingFor === 'premium' &&
        this.isAuthenticated &&
        this.isPremium)
    ) {
      this._modalService.createModal(this.copyPickerTemp, {
        closeOnClickOutSide: true,
      });
      const sub = this._modalService.clickedOutside$.subscribe(() => {
        this._modalService.closeModal();
        sub.unsubscribe();
      });
    } else {
      this._router.navigate([], {
        queryParams: {
          view: this.allowBibleCopyingFor === 'premium' ? 'premium' : 'signUp',
        },
      });
    }
  }

  public closeModal(): void {
    this._modalService.closeModal();
    this.copy();
  }

  public onGtmTagPush(event: string): void {
    const tag: object = {
      event,
      bible_book: this.readerState.selectedChapter?.split('.')?.[0],
      bible_chapter: this.readerState.selectedChapter,
      bible_chapter_verse: this.selectedVerses[0],
      bible_translation: this.selectedBible,
    };
    this.pushGtmTag.emit(tag);
  }

  public getSelectedVersesTitle(): string {
    const splittedStartIndex = this.selectedVerses?.[0]?.split('-');
    const startIndex = splittedStartIndex[0]?.split('.')[2];
    const endIndex =
      this.selectedVerses?.length > 1
        ? this.selectedVerses[this.selectedVerses.length - 1]?.split('.')[2]
        : splittedStartIndex[1]?.split('.')[2] || '';

    return `${this.selectedChapterFullName}:${startIndex}${
      endIndex ? `-${endIndex}` : ''
    }`;
  }
}
