<!-- Render the Wordpress Gutenberg blocks  -->
<ng-container
  *ngFor="let block of blocks; let i = index"
  [ngSwitch]="block.blockName"
>
  <!-- Paragraph -->
  <ng-container *ngSwitchCase="'core/paragraph'">
    <div
      class="max-w-[610px] lg:pr-4 m-auto"
      [ngClass]="{ '!max-w-none': noMaxWidth }"
    >
      <ibep-paragraph
        [html]="block.innerHTML"
        [paragraphId]="i"
        [formatEbcLinks]="formatEbcLinks"
        (closeModal)="closeEbcModal($event)"
        [inlineImage]="true"
      ></ibep-paragraph>
    </div>
  </ng-container>

  <!-- Disgression -->
  <ng-container *ngSwitchCase="'acf/disgression'">
    <div
      class="max-w-[610px] lg:pr-4 pl-[2rem] m-auto !text-[0.85rem] !leading-5"
      [ngClass]="{ '!max-w-none': noMaxWidth }"
    >
      <ibep-paragraph
        [html]="block.attrs?.disgression"
        [formatEbcLinks]="formatEbcLinks"
      ></ibep-paragraph>
    </div>
  </ng-container>
  <!-- Cta -->
  <ng-container *ngSwitchCase="'acf/cta'">
    <ibep-call-to-action
      [props]="{
        headline: block.attrs.headline,
        text: block.attrs.text,
        buttonText: block.attrs.buttonText,
        linkExternal: block.attrs.linkExternal,
        linkInternal: block.attrs.linkInternal,
        linkType: block.attrs.linkType
      }"
    ></ibep-call-to-action>
  </ng-container>
  <!-- Button -->
  <ng-container *ngSwitchCase="'acf/button'">
    <div
      class="max-w-[610px] lg:pr-4 m-auto mb-3"
      [ngClass]="{ '!max-w-none': noMaxWidth }"
    >
      <a
        [href]="
          block.attrs.linkInternal
            ? block.attrs.linkInternal
            : block.attrs.linkExternal
        "
        target="{{ block.attrs.linkType === 'internal' ? '_self' : '_blank' }}"
      >
        <ibep-button
          [buttonProps]="{
            pushGtm: true,
            content: block.attrs.text,
            link: block.attrs.linkInternal
              ? block.attrs.linkInternal
              : block.attrs.linkExternal,
            style: 'secondary',
            size: 'l',
            fullWidth: true
          }"
        >
          <b
            class="text-xs text-center line-clamp-1"
            [innerHTML]="block.attrs.text"
          ></b>
        </ibep-button>
      </a>
    </div>
  </ng-container>
  <!-- Teasers -->
  <ng-container *ngSwitchCase="'acf/teaser'">
    <div
      class="max-w-[610px] lg:max-w-none lg:pr-4 m-auto"
      [ngClass]="{ '!max-w-none': noMaxWidth }"
    >
      <!-- {{ block.attrs.teaserItems | json }} -->
      <ibep-block-item-grid
        [props]="{
          items: block.attrs.teaserItems,
          sectionTitle: '',
          isBrowser: isBrowser,
          autoWidth: true,
          horizontalScroll: false
        }"
      ></ibep-block-item-grid>
    </div>
  </ng-container>
  <!-- Inline Note -->
  <ng-container *ngSwitchCase="'acf/note'">
    <div
      class="max-w-[610px] lg:pr-4 m-auto"
      [ngClass]="{ '!max-w-none': noMaxWidth }"
    >
      <ibep-inline-note
        *ngIf="block.attrs.type === 'text'"
        [props]="{
          id: block.attrs.id,
          type: block.attrs.type,
          icon: block.attrs.icon,
          text: block.attrs.text
        }"
      ></ibep-inline-note>
      <ibep-inline-note-passage
        *ngIf="block.attrs.type === 'passage'"
        [props]="{
          index: inlineNotePassageCounter,
          saveQeryCountMode: true,
          isBrowser: isBrowser,
          id: block.attrs.id,
          type: block.attrs.type,
          icon: block.attrs.icon,
          biblepassage: block.attrs.biblepassage
        }"
        (increaseByOnePassagesCounter)="syncInlineNotesCount()"
      >
      </ibep-inline-note-passage>
    </div>
  </ng-container>
  <!-- Donation -->
  <ng-container *ngSwitchCase="'acf/donation'">
    <ibep-donation
      [props]="{
        headline: block.attrs.headline,
        text: block.attrs.text,
        donationItems: block.attrs.donationItems,
        currency: block.attrs.currency
      }"
    ></ibep-donation>
  </ng-container>
  <!-- Testimonial -->
  <ng-container *ngSwitchCase="'acf/testimonial'">
    <div
      class="max-w-[610px] lg:pr-4 m-auto"
      [ngClass]="{ '!max-w-none': noMaxWidth }"
    >
      <ibep-testimonial
        [props]="{
          name: block.attrs.name,
          text: block.attrs.text,
          jobTitle: block.attrs.jobTitle,
          image: block.attrs.image?.sizes?.thumbnail
        }"
      ></ibep-testimonial>
    </div>
  </ng-container>
  <!-- Content Row  -->
  <ng-container *ngSwitchCase="'acf/contentrow'">
    <div
      class="mb-4"
      [ngClass]="{
        'bg-primary bg-opacity-10 py-4 sm:py-8': block.attrs.highlighted
      }"
    >
      <div
        class="max-w-[610px] lg:pr-4 m-auto"
        [ngClass]="{ '!max-w-none': noMaxWidth }"
      >
        <ibep-content-row
          [props]="{
            attrs: block.attrs
          }"
        ></ibep-content-row>
      </div>
    </div>
  </ng-container>
  <!-- Table -->
  <ng-container *ngSwitchCase="'core/table'">
    <div
      class="max-w-[610px] lg:pr-4 m-auto"
      [ngClass]="{ '!max-w-none': noMaxWidth }"
    >
      <ibep-table [content]="block.innerHTML"></ibep-table>
    </div>
    <div class="lg:hidden text-center -mt-5 mb-3">
      <ibep-swipe-svg svgStyles="w-6 h-6"></ibep-swipe-svg>
      <span class="text-grayLight text-bible-sm ml-2">{{
        'SwipeToSee*' | translate
      }}</span>
    </div>
  </ng-container>
  <!-- Columns -->
  <ng-container *ngSwitchCase="'core/columns'">
    <div class="max-w-[610px] m-auto" [ngClass]="{ '!max-w-none': noMaxWidth }">
      <ibep-columns
        [props]="{ block: block }"
        [isMobile]="isMobile"
      ></ibep-columns>
    </div>
  </ng-container>
  <!-- Separator -->
  <ng-container *ngSwitchCase="'core/separator'">
    <ibep-separator></ibep-separator>
  </ng-container>
  <!-- Quote -->
  <ng-container *ngSwitchCase="'core/quote'">
    <div
      class="max-w-[610px] lg:pr-4 m-auto"
      [ngClass]="{ '!max-w-none': noMaxWidth }"
    >
      <ibep-quote [content]="block"></ibep-quote>
    </div>
  </ng-container>
  <!-- Video -->
  <ng-container *ngSwitchCase="'core/video'">
    <div
      class="max-w-[610px] lg:pr-4 m-auto"
      [ngClass]="{ '!max-w-none': noMaxWidth }"
    >
      <ibep-video></ibep-video>
    </div>
  </ng-container>
  <!-- Audio -->
  <ng-container *ngSwitchCase="'core/audio'">
    <div
      class="max-w-[610px] lg:pr-4 m-auto"
      [ngClass]="{ '!max-w-none': noMaxWidth }"
    >
      <ibep-audio-embed [content]="block.innerHTML"></ibep-audio-embed>
    </div>
  </ng-container>
  <!-- List -->
  <ng-container *ngSwitchCase="'core/list'">
    <div
      class="max-w-[610px] lg:pr-4 m-auto"
      [ngClass]="{ '!max-w-none': noMaxWidth }"
    >
      <ibep-list [block]="block"></ibep-list>
    </div>
  </ng-container>
  <!-- Verse of the day  -->
  <ng-container *ngSwitchCase="'acf/vod'">
    <div
      class="max-w-[610px] lg:pr-4 m-auto"
      [ngClass]="{ '!max-w-none': noMaxWidth }"
    >
      <ibep-vod-container [headline]="block.attrs.headline">
      </ibep-vod-container>
    </div>
  </ng-container>
  <!-- Heading -->
  <ng-container *ngSwitchCase="'core/heading'">
    <div
      class="max-w-[610px] lg:pr-4 m-auto"
      [ngClass]="{ '!max-w-none': noMaxWidth }"
    >
      <ibep-heading [content]="block.innerHTML"></ibep-heading>
    </div>
  </ng-container>
  <!-- Embed -->
  <ng-container *ngSwitchCase="'core/embed'">
    <div
      class="max-w-[610px] lg:pr-4 m-auto"
      [ngClass]="{ '!max-w-none': noMaxWidth }"
    >
      <ibep-embed
        [props]="{ embedUrl: block.attrs.url, content: block.innerHTML }"
      ></ibep-embed>
    </div>
  </ng-container>
  <!-- Embed -->
  <ng-container *ngSwitchCase="'acf/youtubenocookie'">
    <div
      class="max-w-[610px] lg:pr-4 m-auto"
      [ngClass]="{ '!max-w-none': noMaxWidth }"
    >
      <ibep-embed
        [props]="{ embedUrl: block.attrs.embedLink, content: block.innerHTML }"
      ></ibep-embed>
    </div>
  </ng-container>
  <!-- Redirect -->
  <ng-container *ngSwitchCase="'acf/redirect'">
    <ng-container *ngIf="redirect(block.attrs)"></ng-container>
  </ng-container>
  <!-- Image -->
  <ng-container *ngSwitchCase="'core/image'">
    <div
      class="max-w-[610px] lg:pr-4 m-auto"
      [ngClass]="{ '!max-w-none': noMaxWidth }"
    >
      <ibep-paragraph
        [html]="block.innerHTML"
        [inlineImage]="false"
        [disableLightbox]="true"
      ></ibep-paragraph>
    </div>
  </ng-container>
  <!-- Image gallery -->
  <ng-container *ngSwitchCase="'core/gallery'">
    <div
      *ngIf="block?.innerBlocks?.length"
      class="max-w-[610px] lg:pr-4 m-auto"
      [ngClass]="{ '!max-w-none': noMaxWidth }"
    >
      <div class="grid grid-cols-2 lg:grid-cols-3 gap-2 py-2">
        <div
          *ngFor="let imageBlock of block.innerBlocks; let i = index"
          class="w-full text-2xs text-grayMedium"
        >
          <ibep-paragraph
            [html]="imageBlock.innerHTML"
            [inlineImage]="false"
            [disableLightbox]="false"
            [imageGallery]="true"
            [galleryIndex]="i"
            [galleryTotal]="block.innerBlocks.length"
            [imageLightBox]="imageBlock.lightBox"
            (openSiblingLightBox)="
              setSiblingLightBox($event, block.innerBlocks)
            "
          ></ibep-paragraph>
        </div>
      </div>
    </div>
  </ng-container>
  <!-- Custom HTML -->
  <ng-container *ngSwitchCase="'core/html'">
    <div
      #customHtmlContainer
      id="ibep-custom-html-container"
      class="max-w-[610px] pb-4 lg:pr-4 m-auto"
      [ngClass]="{ '!max-w-none': noMaxWidth }"
      [innerHTML]="block.innerHTML | safeHtml : customHtmlContainer"
    ></div>
  </ng-container>
  <!-- Bible passage  -->
  <ng-container *ngSwitchCase="'acf/biblepassage'">
    <div
      class="max-w-[610px] lg:pr-4 m-auto"
      [ngClass]="{ '!max-w-none': noMaxWidth }"
    >
      <ibep-biblepassage
        [props]="{
          biblepassage: block.attrs
        }"
      >
      </ibep-biblepassage>
    </div>
  </ng-container>
  <!-- Table of contents -->
  <ng-container *ngSwitchCase="'acf/tableofcontents'">
    <div
      class="max-w-[610px] lg:pr-4 m-auto"
      [ngClass]="{ '!max-w-none': noMaxWidth }"
    >
      <ibep-table-of-contents
        [props]="{
          title: block.attrs.title,
          blocks
        }"
      ></ibep-table-of-contents>
    </div>
  </ng-container>
</ng-container>
