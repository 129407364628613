import { Component } from '@angular/core';

/**
 * https://ubsicap.github.io/usx/parastyles.html#cd
 *
 * @export
 * @class CdComponent
 */
@Component({
  selector: 'bible-cd',
  template: `
    <p class="cd">
      <ng-content></ng-content>
    </p>
  `,
  styleUrls: ['./cd.component.scss'],
})
export class CdComponent {}
