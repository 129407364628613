<div class="flex">
  <!-- TODO: use grid layout  -->
  <div class="block relative w-full h-full">
    <!-- Toolbar  -->
    <div class="sticky z-20 top-0 lg:top-12">
      <div
        class="mx-auto grid grid-cols-2 bg-white h-12 lg:h-14 py-2 lg:py-3 px-3 sm:px-0"
        [ngClass]="{
          'max-w-screen-sm mx-auto': columns.length === 1,
          'sm:px-3': columns.length !== 1
        }"
      >
        <div class="flex w-full">
          <!-- Bible picker  -->
          <ibep-button
            [buttonProps]="{
              content: readerState.selectedChapterFullname,
              size: 'm'
            }"
            (click)="openModal('biblePicker')"
          >
            <div
              class="text-xs group h-full flex items-center"
              aria-label="bible chapter selector"
            >
              <span
                class="group-hover:text-primary w-max-content max-w-[90px] sm:max-w-none truncate"
                >{{ readerState.selectedBookFullname }}</span
              >
              <span class="pr-3 pl-1 group-hover:text-primary min-w-fit">{{
                readerState.selectedChapterNumber
              }}</span>
              <span class="text-grayLight text-2xs">{{
                readerState.selectedBibles.bibleAbbrs
              }}</span>
              <ibep-chevron-down-svg
                svgStyles="w-2 h-2 ml-1 text-grayLight"
              ></ibep-chevron-down-svg>
            </div>
          </ibep-button>
          <div
            *ngIf="userAccountSystem || allowParallelReadingFor === 'all'"
            class="relative hidden lg:block"
          >
            <ng-container *ngIf="!isAuthenticated && !isPremium">
              <span
                *ngIf="allowParallelReadingFor === 'registered'"
                class="hidden lg:inline absolute -top-2 right-0 w-4 h-4 ml-1 rounded-full text-primary bg-highlightText border border-grayExtraLight px-[3px]"
                ><ibep-lock-svg
                  svgStyles="w-2 h-2 absolute top-[3px] right-[3px]"
                ></ibep-lock-svg
              ></span>
              <span
                *ngIf="allowParallelReadingFor === 'premium'"
                class="absolute top-1 -right-7 text-secondary text-[9px] bg-white uppercase font-bold px-[2px]"
              >
                <span>{{ 'Premium' | translate }}</span></span
              >
            </ng-container>
            <ibep-button
              [buttonProps]="{
                pushGtm: true,
                content: 'ibep-add-translation-svg icon',
                size: 'm'
              }"
              (click)="openModal('translationPicker')"
              class="text-grayLight ml-1"
            >
              <ibep-add-translation-svg class="mt-1"></ibep-add-translation-svg>
            </ibep-button>
          </div>
        </div>
        <div class="flex gap-4 items-right justify-end">
          <!-- EBC toggle  -->
          <div
            *ngIf="useEBC"
            class="hidden lg:flex text-xs text-grayLight cursor-pointer hover:text-grayDark items-center"
            (click)="toggleEbc()"
          >
            <div>{{ 'AdditionalInfo' | translate }}</div>
            <ibep-toggle
              [isActive]="readerState.ebcState.showEbc"
              [isDisabled]="false"
            ></ibep-toggle>
          </div>
          <!-- Options picker  -->
          <ibep-options-picker
            [readerOptions]="readerState.readerOptions"
            [readerState]="readerState"
            [config]="config"
            [useEBC]="useEBC"
            [allowParallelReadingFor]="allowParallelReadingFor"
            [isAuthenticated]="isAuthenticated"
            [userAccountSystem]="userAccountSystem"
            (webfontLoad)="loadWebfont($event)"
            (readerStateSet)="setReaderState($event)"
            (pushGtmTag)="onGtmTagPush($event)"
            (ebcToggle)="toggleEbc()"
            (translationPickerOpen)="openModal('translationPicker')"
          >
          </ibep-options-picker>
        </div>
      </div>
      <div class="toolbar-shadow h-7" *ngIf="columns.length === 1"></div>
    </div>
    <div
      class="max-w-[100vw] lg:overflow-x-scroll"
      [ngClass]="{
        'lg:max-w-[100vw]': !readerState.ebcState.showEbc,
        'lg:max-w-[66.6vw] xl:max-w-[75vw]': readerState.ebcState.showEbc
      }"
    >
      <!-- Bible Content  -->
      <div
        ibepHorizontalSwiper
        [swiperEnabled]="columns.length < columnsLimitEnum.MOBILE && isMobile"
        (swipeLeftEvent)="onSwipe('left')"
        (swipeRightEvent)="onSwipe('right')"
        class="grid mx-auto justify-center pb-4 lg:grid-flow-col auto-cols-fr 2xl:auto-cols-fr"
        [ngClass]="{
          '!select-auto':
            allowBibleCopyingFor === 'all' ||
            (allowBibleCopyingFor === 'registered' && isAuthenticated) ||
            (allowBibleCopyingFor === 'premium' &&
              isAuthenticated &&
              isPremium),
          'lg:auto-cols-33vw':
            columns.length > 2 && !readerState.ebcState.showEbc,
          'lg:auto-cols-33vw xl:auto-cols-25vw':
            columns.length > 2 && readerState.ebcState.showEbc,
          'bg-sepia text-grayDark':
            readerState.readerOptions.readingStyle === 'sepia',
          'bg-white text-grayDark':
            readerState.readerOptions.readingStyle === 'light',
          'bg-gray-800 text-white':
            readerState.readerOptions.readingStyle === 'dark'
        }"
      >
        <ng-container
          *ngFor="let column of columns; let i = index; trackBy: trackByBible"
        >
          <!-- Bible Column(s)  -->
          <ibep-bible-column
            [ngClass]="{
              hidden: column.bible.abbreviation !== activeColumn,
              'lg:block': columnsLimitEnum.DESKTOP > i
            }"
            [column]="column"
            [scrolled]="scrolled"
            [flashed]="flashed"
            [isMobile]="isMobile"
            [activeScrollColumn]="activeScrollColumn"
            [readerState]="readerState"
            [scrollStateValue]="scrollStateValue"
            (activeScrollColumnSet)="setActiveScrollColumn($event)"
            (scrolledTo)="scrollTo($event)"
            (scrollStateChange)="scrollStateChange($event)"
          >
            <!-- Bible Translation Info  -->
            <div
              class="sticky top-0 z-10 bg-white text-grayMedium"
              *ngIf="column.bible && columns.length > 1"
            >
              <ibep-bible-info
                [name]="column.bible.name"
                [abbr]="column.bible.abbreviation"
                [readerState]="readerState"
                [activeColumn]="activeColumn"
                (isActiveColumn)="setActiveColumn($event)"
                (redirect)="redirect($event)"
              ></ibep-bible-info>
            </div>
            <div
              [ngClass]="{
                'px-4': column?.chapter?.content,
                'pb-28 lg:pb-4': readerState.ebcState.showEbc
              }"
            >
              <!-- Bible Chapter  -->
              <div
                class="py-0 lg:pb-4 max-w-screen-sm mx-auto leading-8"
                [ngClass]="{
                  'lg:py-12 px-5':
                    readerState?.selectedBibles?.bibleAbbrs.length > 1,
                  '!text-bible-sm':
                    readerState.readerOptions?.fontSize === 'text-bible-sm',
                  '!text-bible-md':
                    readerState.readerOptions?.fontSize === 'text-bible-md',
                  '!text-bible-lg':
                    readerState.readerOptions?.fontSize === 'text-bible-lg',
                  'font-noto-sans':
                    readerState.readerOptions?.fontType === 'notoSans',
                  'font-noto-serif':
                    readerState.readerOptions?.fontType === 'noto-serif',
                  'font-fira-sans':
                    readerState.readerOptions?.fontType === 'fira-sans',
                  'font-quicksand':
                    readerState.readerOptions?.fontType === 'quicksand',
                  'font-ubuntu':
                    readerState.readerOptions?.fontType === 'ubuntu'
                }"
                [ngStyle]=""
                *ngIf="column?.chapter?.content"
              >
                <ibep-bible-chapter>
                  <h1
                    class="text-xl font-bold mb-4 text-grayMedium"
                    *ngIf="!isLoading"
                  >
                    {{ column?.chapter?.title }}
                  </h1>
                  <ibep-render-bible-content
                    [bibleContent]="column.chapter?.content"
                    [bibleAbbr]="column.bible?.abbreviation"
                    [scriptDirection]="
                      column.bible?.language?.scriptDirection || 'LTR'
                    "
                    [scriptLanguage]="column.bible?.language?.id"
                    [hovered]="hovered"
                    [isLoading]="isLoading"
                    [isAuthenticated]="isAuthenticated"
                    [selected]="selected"
                    [flashed]="flashed"
                    [isBrowser]="isBrowser"
                    [readerState]="readerState"
                    [studyContent]="column.studyContent"
                    [userHighlights]="column.userHighlights"
                    [userNotes]="column.userNotes"
                    [createdNoteVerseOrgIds]="createdNoteVerseOrgIds"
                    (pushGtmTag)="onGtmTagPush($event)"
                    (userNoteUpdated)="updateUserNote($event)"
                    (userNoteDeleted)="deleteUserNote($event)"
                  ></ibep-render-bible-content>
                </ibep-bible-chapter>
                <!-- Copyright  -->
                <div
                  class="bible-copyright mt-8 text-2xs text-grayLight"
                  *ngIf="column.bible.copyright && !isLoading"
                  [innerHTML]="column.bible.copyright"
                ></div>
              </div>
              <!-- Bible Translation Not Found  -->
              <div *ngIf="!column.bible" class="py-2 text-gray-600">
                {{ 'ThisBibleTranslation*' | translate }}
              </div>
              <!-- Bible Chapter Not Found  -->
              <div *ngIf="!column.chapter" class="py-8 px-5">
                {{ 'ThisChapterIs*' | translate }}
                <!-- TODO: add link to a chapter that this bible has in common with the main selected bible  -->
              </div>
            </div>
          </ibep-bible-column>
        </ng-container>
      </div>
    </div>

    <!-- Reader Navigation -->
    <div
      class="sticky bottom-0"
      [ngClass]="{ 'bottom-[16%] lg:bottom-0': readerState.ebcState.showEbc }"
    >
      <ibep-bible-nav
        [columns]="columns"
        [readerStateVal]="readerState"
        [audioSource]="columns[0].chapterAudio"
        [isAuthenticated]="isAuthenticated"
        [isPremium]="isPremium"
        [activeColumn]="activeColumn"
        (pushGtmTag)="onGtmTagPush($event)"
        (updateAudioState)="setAudioState($event)"
        (requestNextChapter)="requestNextChapter()"
      ></ibep-bible-nav>
    </div>
    <!-- Bible selection toolbar  -->
    <ibep-bible-selection-toolbar
      [selectedChapterFullName]="readerState.selectedChapterFullname"
      [selectedVerses]="selected?.verseIds"
      [selectedBible]="selected?.activeColumn"
      [isAuthenticated]="isAuthenticated"
      [isPremium]="isPremium"
      [userAccountSystem]="userAccountSystem"
      [readerState]="readerState"
      [allowBibleCopyingFor]="allowBibleCopyingFor"
      [copyOptions]="copyOptions"
      (toolbarClosed)="closeToolbar()"
      (copySelection)="copyVerse($event)"
      (addUserNote)="addUserNote()"
      (addUserHighlight)="addUserHighlight($event)"
      (deleteUserHighlight)="deleteUserHighlight()"
      (shareSelection)="shareVerse($event)"
      (pushGtmTag)="onGtmTagPush($event)"
    ></ibep-bible-selection-toolbar>
    <!-- User Note toolbar
    <ibep-user-note-toolbar
      *ngIf="userAccountSystem"
      [readerState]="readerState"
      [toolbarIsOpen]="noteToolbarIsOpen"
      (addUserNote)="addUserNote()"
    ></ibep-user-note-toolbar> -->
  </div>

  <!-- EBC content sidebar  -->
  <div
    *ngIf="useEBC && readerState.ebcState.showEbc"
    class="fixed bottom-0 lg:translate-y-0 border-t-[3px] lg:border-t-0 border-highlightText rounded-t-2xl lg:rounded-t-none lg:relative min-w-[100vw] max-w-[100vw] lg:min-w-[33.3vw] lg:max-w-[33.3vw] xl:min-w-[25vw] xl:max-w-[25vw] lg:border-l lg:border-grayExtraLight text-grayMedium bg-grayExtraLight lg:h-[87.5vh] overflow-hidden transition-all duration-500 lg:duration-1000 ease-in-out z-40"
    [ngClass]="{
      'h-[14vh]': ebcModalPosition === '15%',
      'h-[50vh]': ebcModalPosition === '50%',
      'top-0 h-full': ebcModalPosition === '100%'
    }"
  >
    <ibep-bible-ebc
      (pushGtmTag)="onGtmTagPush($event)"
      (changeModalPosition)="changeEbcModalPosition()"
      [ebcModalPosition]="ebcModalPosition"
      [ebcArticles]="columns[0].ebcArticles"
      [ebcCategories]="categories"
      [isLoadingEbc]="isLoadingEbc"
      [isPremium]="isPremium"
      [isAuthenticated]="isAuthenticated"
    ></ibep-bible-ebc>
  </div>
</div>
