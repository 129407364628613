import { Component, OnInit } from '@angular/core';

/**
 * This is a custom style for the verse number element (not part of usx)
 *
 * @export
 * @class VComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'bible-v',
  templateUrl: './v.component.html',
  styleUrls: ['./v.component.scss'],
})
export class VComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
