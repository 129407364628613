import { Inject, Injectable } from '@angular/core';
import {
  AbstractBackendService,
  AbstractEnvironmentService,
  AbstractLanguageService,
  AbstractStorageService,
} from '@ibep/interfaces';
import { shareReplay, tap } from 'rxjs/operators';
import { ConfigData, ReaderOptions, UserInfoData } from '..';
import { Store, StoreSettings } from './store';
import { Observable } from 'rxjs';
import { WINDOW } from '@ibep/fe/shared/core';

export interface UserPreferences {
  bibleIds: string[];
  chapterId: string;
  showEbc: boolean;
  readerOptions: ReaderOptions;
}

@Injectable({
  providedIn: 'root',
})
export class UserPreferencesData extends Store<any> {
  private _isBrowser: boolean;

  constructor(
    private readonly _userInfoData: UserInfoData,
    @Inject(WINDOW) private readonly _window: Window,
    storage: AbstractStorageService,
    environment: AbstractEnvironmentService,
    backendService: AbstractBackendService,
    languageService: AbstractLanguageService,
    config: ConfigData
  ) {
    super(storage, environment, backendService, languageService, {
      storeKey: 'userPreferences',
      brand$: config.getBrand(),
      doNotStoreLocally: true,
    } as StoreSettings);
    config.getConfig().subscribe((data) => {
      this._isBrowser = data.platform === 'browser';
    });
  }

  public update(userPreferences: UserPreferences): Observable<UserPreferences> {
    const putUserPreferences$ = this.backendService
      .put<UserPreferences>({
        baseUrl: `https://${this.brand.id}${this.environment.authBaseUrl}`,
        endpoint: 'userPreferences',
        options: {
          body: userPreferences,
          headers: {
            'x-brand': this.brand.id,
          },
        },
      })
      .pipe(
        shareReplay(1),
        tap(() => {
          this._userInfoData.updateUserPreferences(userPreferences);
          this.storeLocally(userPreferences);
        })
      );

    return putUserPreferences$;
  }

  public storeLocally(userPreferences: UserPreferences): void {
    if (this._isBrowser) {
      this._window.localStorage?.setItem(
        'userPreferences',
        JSON.stringify(userPreferences)
      );
    }
  }
}
