import { Component } from '@angular/core';

/**
 * https://ubsicap.github.io/usx/parastyles.html#d
 *
 * @export
 * @class DComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'bible-d',
  template: `
    <p class="d">
      <ng-content></ng-content>
    </p>
  `,
  styleUrls: ['./d.component.scss'],
})
export class DComponent {}
