<!-- Reusable template to render verse text styles  -->
<ng-template
  #verseText
  let-bibleVerse="bibleVerse"
  let-verseOrgId="verseOrgId"
  let-parentStyle="parentStyle"
>
  <!-- FIXME: we need to refactor the way classes are applied via ngClass because it is too memory intensive. Preferable dont call functions from template to make true/false calculations, but make calculations from the controller and set a boolean property inside data object. -->
  <span
    [attr.data-verse-org-id]="Array.isArray(verseOrgId) && verseOrgId.join(' ')"
    [id]="createVerseId(verseOrgId)"
    (mouseover)="hover(verseOrgId)"
    (click)="select(verseOrgId, [bibleVerse.verseId])"
    [ngClass]="{
      hovered: checkIntersection(hovered, verseOrgId),
      selected:
        selected?.activeColumn === bibleAbbr &&
        checkIntersection(selected?.verseOrgIds, verseOrgId),
      highlighted: checkIntersection(
        highlighted,
        isSearchPage ? [bibleVerse.verseId] : verseOrgId
      ),
      flashed:
        !checkHighlight(verseOrgId) &&
        checkIntersection(
          flashed,
          columnsNumber > 1 ? verseOrgId : [bibleVerse.verseId]
        ),
      'bg-highlightYellow':
        readerState?.readerOptions?.showUserHighlights &&
        checkHighlight(verseOrgId, '#FFF598'),
      'bg-highlightOrange':
        readerState?.readerOptions?.showUserHighlights &&
        checkHighlight(verseOrgId, '#FF885F'),
      'bg-highlightBlue':
        readerState?.readerOptions?.showUserHighlights &&
        (checkHighlight(verseOrgId, '#80D5FC') ||
          checkHighlight(verseOrgId, '#7FD5FC')),
      'bg-highlightGreen':
        readerState?.readerOptions?.showUserHighlights &&
        (checkHighlight(verseOrgId, '#A5D6A6') ||
          checkHighlight(verseOrgId, '#A4D6A6')),
      'border-b border-dashed border-grayMedium':
        isAuthenticated &&
        readerState?.readerOptions?.showUserNotes &&
        bibleVerse.hasFootnote,
      '!text-[#424362]': isHighlightsPage
    }"
    [class]="createVerseId(verseOrgId)"
    class="verse relative text-grayMedium"
    observeVisibility
    [rootMargin]="'-49% 0px -49% 0px'"
    [threshold]="0.001"
    [root]="'columnViewport-' + bibleAbbr"
    (visible)="scroll(bibleVerse.verseId, bibleAbbr)"
    [isBrowser]="isBrowser"
  >
    <ibep-verse-text-renderer
      [parentStyle]="parentStyle"
      [style]="bibleVerse.style"
      [content]="bibleVerse.content"
      [customStyling]="customStyling"
    ></ibep-verse-text-renderer>
  </span>
</ng-template>

<ng-container
  *ngFor="let bibleVerse of item; let i = index"
  [ngSwitch]="bibleVerse.type"
>
  <!-- Text  -->
  <ng-container *ngSwitchCase="'text'">
    <span class="text-grayMedium">{{ bibleVerse.content }}</span>
  </ng-container>

  <!-- Image  -->
  <ng-container *ngSwitchCase="'figure'">
    <ibep-media-package-item-container
      [props]="{ fileName: bibleVerse.file, bibleId: bibleAbbr }"
    ></ibep-media-package-item-container>
  </ng-container>

  <!-- Verse Number  -->
  <ng-container *ngSwitchCase="'verse-number'">
    <bible-v *ngIf="readerState?.readerOptions?.showVerseNumbers">{{
      bibleVerse.pubnumber || bibleVerse.content
    }}</bible-v>
  </ng-container>

  <ng-container *ngSwitchCase="'study'">
    <!-- Cross References -->
    <!-- TODO: refactor cross reference -> create sub components  -->
    <span
      #crossRefElement
      *ngIf="
        bibleVerse.subtype === 'crossreference' &&
        readerState?.readerOptions?.showCrossReferences
      "
      class="crossRef relative cursor-pointer"
      (mouseenter)="
        togglePopover(bibleVerse.verseId, bibleVerse.id, crossRefElement)
      "
      (mouseleave)="
        togglePopover(bibleVerse.verseId, bibleVerse.id, crossRefElement)
      "
    >
      <svg
        ibepHideTillInit
        aria-hidden="true"
        focusable="false"
        class="h-3 w-3 inline text-grayLight hover:text-primary mx-1"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <path
          fill="currentColor"
          d="M504.971 359.029c9.373 9.373 9.373 24.569 0 33.941l-80 79.984c-15.01 15.01-40.971 4.49-40.971-16.971V416h-58.785a12.004 12.004 0 0 1-8.773-3.812l-70.556-75.596 53.333-57.143L352 336h32v-39.981c0-21.438 25.943-31.998 40.971-16.971l80 79.981zM12 176h84l52.781 56.551 53.333-57.143-70.556-75.596A11.999 11.999 0 0 0 122.785 96H12c-6.627 0-12 5.373-12 12v56c0 6.627 5.373 12 12 12zm372 0v39.984c0 21.46 25.961 31.98 40.971 16.971l80-79.984c9.373-9.373 9.373-24.569 0-33.941l-80-79.981C409.943 24.021 384 34.582 384 56.019V96h-58.785a12.004 12.004 0 0 0-8.773 3.812L96 336H12c-6.627 0-12 5.373-12 12v56c0 6.627 5.373 12 12 12h110.785c3.326 0 6.503-1.381 8.773-3.812L352 176h32z"
        ></path>
      </svg>
      <!-- cross reference content  -->
      <div
        *ngIf="activePopover?.id === bibleVerse.verseId + '-' + bibleVerse.id"
        class="crossRefContent popover absolute top-[22px] cursor-default z-10 px-3 py-3 rounded-xl shadow-xl border border-grayExtraLight text-grayMedium"
        [ngClass]="{
          'right-0': activePopover?.position === 'left',
          'left-0': activePopover?.position === 'right'
        }"
      >
        <h4 class="uppercase text-left text-3xs pb-2">
          {{ 'References' | translate }}
          {{ readerState.selectedChapterFullname }}:{{
            activePopover?.content?.content[0]?.content.includes(':')
              ? activePopover?.content?.content[0]?.content.split(':')[1]
              : activePopover?.content?.content[0]?.content.split(',')[1]
          }}
        </h4>
        <ng-container
          *ngFor="let item of activePopover?.content.content[1].content"
        >
          <div class="border-b border-grayExtraLight last:border-b-0">
            <a
              *ngIf="item.type === 'ref'"
              [routerLink]="
                '/ROUTES.bible/' +
                  readerState.selectedBibles?.bibleAbbrs +
                  '/' +
                  item.link | localize
              "
              (click)="
                onGtmTagPush('reference_click', bibleVerse.verseId, $event)
              "
              class="text-left py-2 text-xs cursor-pointer justify-between flex hover:text-primary"
              ><span [innerHTML]="item.text | tildeToSpace"></span
              ><span>
                <ibep-chevron-right-svg
                  class="text-grayLight"
                  svgStyles="w-2 h-2"
                ></ibep-chevron-right-svg></span
            ></a>
            <span
              class="text-xs"
              *ngIf="item.type === 'text' && item.content.length > 2"
            >
              {{ item.content }}
            </span>
          </div>
        </ng-container>
      </div>
    </span>
    <!-- Footnotes -->
    <!-- TODO: refactor footnotes -> create sub components  -->
    <span
      #footnoteElement
      *ngIf="
        (bibleVerse.subtype === 'footnote' &&
          readerState?.readerOptions?.showFootnotes) ||
        (bibleVerse.subtype === 'extendedfootnote' &&
          readerState?.readerOptions?.showFootnotes)
      "
      class="footnote relative cursor-pointer ml-1"
      (click)="toggleFootnote(bibleVerse.verseId, bibleVerse.id)"
    >
      <span class="text-grayLight hover:text-primary">
        <svg
          ibepHideTillInit
          *ngIf="
            activeFootnote?.id !== bibleVerse.verseId + '-' + bibleVerse.id
          "
          id="caretDown"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          style="width: 14px; height: 14px"
          class="inline cursor-pointer"
          viewBox="0 0 512 512"
        >
          <path
            d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM377.6 248.3l-104 112C269 365.2 262.7 368 256 368s-13.05-2.781-17.59-7.656l-104-112c-6.484-7-8.219-17.19-4.406-25.94C133.8 213.7 142.5 208 152 208h208c9.547 0 18.19 5.656 21.1 14.41C385.8 231.2 384.1 241.3 377.6 248.3z"
          />
        </svg>
        <svg
          ibepHideTillInit
          *ngIf="
            activeFootnote?.id === bibleVerse.verseId + '-' + bibleVerse.id
          "
          id="caretUp"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          style="width: 14px; height: 14px"
          class="inline cursor-pointer"
          viewBox="0 0 512 512"
        >
          <path
            d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM381.1 289.6C378.2 298.3 369.5 304 360 304h-208c-9.547 0-18.19-5.656-22-14.41c-3.812-8.75-2.078-18.94 4.407-25.94l104-112c9.094-9.75 26.09-9.75 35.19 0l104 112C384.1 270.7 385.8 280.8 381.1 289.6z"
          />
        </svg>
      </span>

      <!-- footnote content  -->
      <div
        *ngIf="activeFootnote?.id === bibleVerse.verseId + '-' + bibleVerse.id"
        class="bg-primary bg-opacity-5 rounded-xl my-1 py-2 px-5 text-grayMedium transition-height duration-1000"
        [ngClass]="{
          'h-0':
            activeFootnote?.id !== bibleVerse.verseId + '-' + bibleVerse.id,
          'h-fit':
            activeFootnote?.id === bibleVerse.verseId + '-' + bibleVerse.id
        }"
      >
        <ng-container *ngFor="let item of activeFootnote.content.content">
          <ibep-verse-text-renderer
            *ngIf="item.style !== 'fr' && !isThisAnArray(item.content)"
            [style]="item.style"
            [content]="item.content"
            [customStyling]="customStyling"
          ></ibep-verse-text-renderer>
          <ng-container *ngIf="isThisAnArray(item.content)">
            <ng-container *ngFor="let subItem of item.content">
              <ng-container *ngIf="!isThisAnArray(subItem.content)">
                <a
                  *ngIf="
                    subItem.style === 'jmp' && subItem.linkHref;
                    else footnoteText
                  "
                  (click)="$event.stopPropagation()"
                  [routerLink]="[]"
                  [queryParams]="{
                    ebc: true,
                    legacyId: subItem.linkHref.replace('#', '')
                  }"
                >
                  <ibep-verse-text-renderer
                    [style]="subItem.style"
                    [content]="subItem.content"
                    [customStyling]="customStyling"
                  ></ibep-verse-text-renderer>
                </a>
                <ng-template #footnoteText>
                  <ibep-verse-text-renderer
                    [style]="subItem.style"
                    [content]="subItem.content"
                    [customStyling]="customStyling"
                  ></ibep-verse-text-renderer>
                </ng-template>
              </ng-container>
              <ng-container *ngIf="isThisAnArray(subItem.content)">
                <ng-container *ngFor="let subsubItem of subItem.content">
                  <a
                    *ngIf="subsubItem.type === 'ref'"
                    class="underline hover:text-primary text-grayMedium mr-2"
                    [routerLink]="
                      '/ROUTES.bible/' +
                        readerState.selectedBibles?.bibleAbbrs +
                        '/' +
                        createRefLink(subsubItem.loc) | localize
                    "
                    (click)="
                      onGtmTagPush('footnote_click', bibleVerse.verseId, $event)
                    "
                    [innerHTML]="subsubItem.text | tildeToSpace"
                  ></a>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </span>
  </ng-container>

  <!-- Verse Text  -->
  <ng-container *ngSwitchCase="'verse-text'">
    <ng-container
      [ngTemplateOutlet]="verseText"
      [ngTemplateOutletContext]="{
        bibleVerse: bibleVerse,
        verseOrgId: bibleVerse.verseOrgId
      }"
    ></ng-container>
  </ng-container>

  <!-- Char -->
  <ng-container *ngSwitchCase="'char'">
    <ibep-verse-text-renderer
      *ngIf="typeChecker(bibleVerse.content, 'string')"
      [style]="bibleVerse.style"
      [content]="bibleVerse.content"
      [customStyling]="customStyling"
      class="text-grayMedium"
    ></ibep-verse-text-renderer>

    <ng-container *ngIf="isThisAnArray(bibleVerse.content)">
      <ng-container
        *ngFor="let subBibleVerse of bibleVerse.content"
        [ngSwitch]="bibleVerse.style"
      >
        <ng-container
          [ngTemplateOutlet]="verseText"
          [ngTemplateOutletContext]="{
            bibleVerse: subBibleVerse,
            verseOrgId: subBibleVerse.verseOrgId,
            parentStyle: bibleVerse.style,
          }"
        ></ng-container>
      </ng-container>
    </ng-container>
  </ng-container>

  <!-- Ref  -->
  <ng-container *ngSwitchCase="'ref'">
    <a
      class="underline hover:text-primary text-grayMedium"
      [routerLink]="
        '/ROUTES.bible/' +
          readerState.selectedBibles?.bibleAbbrs +
          '/' +
          createRefLink(bibleVerse.loc) | localize
      "
      [innerHTML]="bibleVerse.text | tildeToSpace"
    ></a>
  </ng-container>

  <!-- User notes  -->
  <ng-container
    *ngIf="
      readerState?.readerOptions?.showUserNotes &&
      isAuthenticated &&
      checkUserNote(bibleVerse.verseOrgId, i, item) &&
      bibleVerse.lastOccurance
    "
  >
    <span>
      <div
        *ngFor="
          let note of checkUserNote(bibleVerse.verseOrgId, i, item);
          let noteIndex = index
        "
        class="cursor-pointer inline-block bg-secondary bg-opacity-20 hover:bg-primary hover:text-primary hover:bg-opacity-20 rounded-full text-secondary w-4 h-4 relative mb-[-3px] mx-1"
        [title]="'Edit' | translate"
        (click)="toggleUserNote(bibleVerse.verseOrgId, noteIndex)"
      >
        <ibep-pencil-svg
          svgStyles="w-2 h-2 absolute top-[4px] left-[4px]"
        ></ibep-pencil-svg>
        <div
          class="w-6 overflow-hidden absolute -bottom-[14px] -left-1"
          *ngIf="
            activeUsernote?.noteId &&
            getNoteId(bibleVerse.verseOrgId, noteIndex) ===
              activeUsernote?.noteId
          "
        >
          <div
            class="h-4 w-4 bg-primary bg-opacity-[6%] rotate-45 transform origin-bottom-left"
          ></div>
        </div>
      </div>

      <!-- show content / edit user note  -->
      <ng-container
        *ngFor="
          let note of checkUserNote(bibleVerse.verseOrgId, i, item);
          let noteIndex = index
        "
      >
        <div
          *ngIf="
            getNoteId(bibleVerse.verseOrgId, noteIndex) ===
            activeUsernote?.noteId
          "
          class="bg-primary bg-opacity-[6%] rounded-xl my-2 text-grayMedium transition-height duration-1000 flex flex-col indent-0"
        >
          <div
            class="py-2 px-5 h-full"
            (click)="onGtmTagPush('usernote_click', activeUsernote.noteId)"
          >
            <ng-container *ngIf="!userNoteEditMode">
              <h3 class="font-bold italic cursor-default">
                {{ activeUsernote.title }}
              </h3>
              <span class="italic cursor-default">{{
                activeUsernote.content
              }}</span>
            </ng-container>

            <div *ngIf="userNoteEditMode" class="h-full">
              <input
                #userNoteTitleArea
                [value]="
                  activeUsernote.titleEditValue
                    ? activeUsernote.titleEditValue
                    : ''
                "
                type="text"
                [placeholder]="'Title' | translate"
                class="rounded-md px-2 mt-2 focus:outline-none focus:ring-1 focus:ring-grayExtraLight"
                (input)="onNoteTitleChange($event)"
              />
              <textarea
                #userNoteTextArea
                [placeholder]="'NoteText' | translate"
                class="rounded-md resize-none w-full h-full min-h-[90px] mt-2 px-2 focus:outline-none focus:ring-1 focus:ring-grayExtraLight"
                (input)="onNoteChange($event)"
                >{{ activeUsernote.contentEditValue }}</textarea
              >
            </div>
          </div>
          <div
            class="bg-white border-highlightText border w-full pb-1 px-5 flex justify-end align-middle items-center gap-3 text-grayLight rounded-b-xl"
          >
            <!-- cancel edit  -->
            <div
              (click)="cancelUserNoteEdit()"
              *ngIf="
                userNoteEditMode &&
                (activeUsernote.content || activeUsernote.title)
              "
              [title]="'Cancel' | translate"
              (disabled)="
                (!activeUsernote.contentEditValue &&
                  !activeUsernote.titleEditValue)
              "
              [ngClass]="{
                'cursor-pointer':
                  activeUsernote.contentEditValue ||
                  activeUsernote.titleEditValue,
                'cursor-not-allowed':
                  !activeUsernote.contentEditValue &&
                  !activeUsernote.titleEditValue
              }"
            >
              <ibep-close-svg
                class="hover:text-primary -mr-2"
                svgStyles="w-7 h-7"
              ></ibep-close-svg>
            </div>
            <!-- edit  -->
            <div
              *ngIf="!userNoteEditMode"
              [title]="'Edit' | translate"
              (click)="toggleUserNoteEditMode()"
              class="hover:text-primary ml-1 cursor-pointer"
            >
              <ibep-pencil-svg svgStyles="w-4 h-4"></ibep-pencil-svg>
            </div>
            <!-- save  -->
            <div
              *ngIf="userNoteEditMode"
              [title]="'Save' | translate"
              (click)="updateUserNote()"
              (disabled)="(!activeUsernote.contentEditValue)"
              [ngClass]="{
                'cursor-pointer': activeUsernote.contentEditValue,
                'cursor-not-allowed': !activeUsernote.contentEditValue
              }"
              class="hover:text-primary"
            >
              <ibep-check-svg svgStyles="w-8 h-8"></ibep-check-svg>
            </div>
            <!-- delete  -->
            <div
              (click)="deleteUserNote()"
              class="hover:text-primary cursor-pointer"
              [title]="'Delete' | translate"
            >
              <ibep-trash-svg svgStyles="w-4 h-4"></ibep-trash-svg>
            </div>
          </div></div
      ></ng-container>
    </span>
  </ng-container>

  <!-- Catch All for bibleVerse.type -->
  <span *ngSwitchDefault>{{ bibleVerse.content }}</span>
</ng-container>
