<ng-template #htmlElements let-item="item">
  <ng-container [ngSwitch]="item.nodeName">
    <figure *ngSwitchCase="'FIGURE'" [class]="item.className">
      <ng-container
        [ngTemplateOutlet]="subHtmlElements"
        [ngTemplateOutletContext]="{
          item: item
        }"
      ></ng-container>
    </figure>

    <table *ngSwitchCase="'TABLE'">
      <ng-container
        [ngTemplateOutlet]="subHtmlElements"
        [ngTemplateOutletContext]="{
          item: item
        }"
      ></ng-container>
    </table>

    <tbody *ngSwitchCase="'TBODY'">
      <ng-container
        [ngTemplateOutlet]="subHtmlElements"
        [ngTemplateOutletContext]="{
          item: item
        }"
      ></ng-container>
    </tbody>

    <th *ngSwitchCase="'TH'">
      <ng-container
        [ngTemplateOutlet]="subHtmlElements"
        [ngTemplateOutletContext]="{
          item: item
        }"
      ></ng-container>
    </th>

    <tr *ngSwitchCase="'TR'">
      <ng-container
        [ngTemplateOutlet]="subHtmlElements"
        [ngTemplateOutletContext]="{
          item: item
        }"
      ></ng-container>
    </tr>

    <td *ngSwitchCase="'TD'">
      <ng-container *ngIf="item.innerHTML != undefined">
        <ibep-paragraph [html]="item.innerHTML"></ibep-paragraph>
      </ng-container>
    </td>

    <ng-container *ngSwitchDefault>
      <ibep-paragraph
        *ngIf="item.outerHTML != undefined"
        [html]="item.outerHTML"
      >
      </ibep-paragraph>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #subHtmlElements let-item="item">
  <!-- <span *ngIf="!item.childNodes" [innerHTML]="item.innerHTML | safeHtml"></span> -->
  <ng-container *ngIf="item.childNodes">
    <ng-container *ngFor="let subItem of item.childNodes">
      <ng-container
        [ngTemplateOutlet]="htmlElements"
        [ngTemplateOutletContext]="{
          item: subItem
        }"
      ></ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<div class="mb-8 text-2xs">
  <!-- Loop over the HTML elements from the table -->
  <ng-container *ngFor="let item of HTMLElements; let i = index">
    <ng-container
      [ngTemplateOutlet]="htmlElements"
      [ngTemplateOutletContext]="{
        item: item
      }"
    ></ng-container>
  </ng-container>
</div>
