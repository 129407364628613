<div class="flex flex-col lg:flex-row items-center lg:px-5 xl:px-0">
  <ibep-logo
    logoStyles="h-12 lg:h-8 mt-4 lg:mt-0"
    [imageSrc]="
      config.brand.footerLogoUrl
        ? config.brand.footerLogoUrl
        : config.brand.logoUrl
    "
    [imageAlt]="config.brand.name"
    [currentLanguage]="currentLanguage"
    [isDefaultLanguage]="isDefaultLanguage"
    routerLink="/"
  ></ibep-logo>
  <span class="ml-3 text-3xs">v.{{ appVersion }}</span>

  <div
    class="self flex flex-col lg:flex-row lg:ml-auto mt-4 lg:mt-0 mb-4 lg:mb-0"
  >
    <div
      class="text-grayLight self-center mt-4 lg:mr-4 lg:mt-0 pb-4 lg:pb-0"
      [innerHTML]="config.socialIcons?.text"
    ></div>

    <div
      class="social-icons flex justify-between lg:justify-center space-x-8 lg:space-x-4 self-center"
    >
      <a
        *ngIf="config.socialIcons?.facebook"
        class="cursor-pointer"
        [href]="config.socialIcons.facebook"
        target="_blank"
        [title]="'Facebook'"
        (click)="onSocialClick(config.socialIcons.facebook)"
      >
        <ibep-socials-svg
          svgStyles="w-8 h-8 lg:w-4 lg:h-4 text-grayLight hover:text-primary"
          socialProps="facebook"
        ></ibep-socials-svg
      ></a>
      <a
        *ngIf="config.socialIcons?.instagram"
        class="cursor-pointer"
        [href]="config.socialIcons.instagram"
        target="_blank"
        [title]="'Instagram'"
        (click)="onSocialClick(config.socialIcons.instagram)"
      >
        <ibep-socials-svg
          svgStyles="w-8 h-8 lg:w-4 lg:h-4 text-grayLight hover:text-primary"
          socialProps="instagram"
        ></ibep-socials-svg
      ></a>
      <a
        *ngIf="config.socialIcons?.youtube"
        class="cursor-pointer"
        [href]="config.socialIcons.youtube"
        target="_blank"
        [title]="'YouTube'"
        (click)="onSocialClick(config.socialIcons.youtube)"
      >
        <ibep-socials-svg
          svgStyles="w-8 h-8 lg:w-4 lg:h-4 text-grayLight hover:text-primary"
          socialProps="youtube"
        ></ibep-socials-svg
      ></a>
      <a
        *ngIf="config.socialIcons?.twitter"
        class="cursor-pointer"
        [href]="config.socialIcons.twitter"
        target="_blank"
        [title]="'Twitter'"
        (click)="onSocialClick(config.socialIcons.twitter)"
      >
        <ibep-socials-svg
          svgStyles="w-8 h-8 lg:w-4 lg:h-4 text-grayLight hover:text-primary"
          socialProps="twitter"
        ></ibep-socials-svg>
      </a>
      <a
        *ngIf="config.socialIcons?.linkedin"
        class="cursor-pointer"
        [href]="config.socialIcons.linkedin"
        target="_blank"
        [title]="'LinkedIn'"
        (click)="onSocialClick(config.socialIcons.linkedin)"
      >
        <ibep-socials-svg
          svgStyles="w-8 h-8 lg:w-4 lg:h-4 text-grayLight hover:text-primary"
          socialProps="linkedin"
        ></ibep-socials-svg
      ></a>
    </div>
  </div>
</div>
