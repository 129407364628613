import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  AbstractAuthService,
  AbstractEnvironmentService,
} from '@ibep/interfaces';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';
import { isPlatformServer } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authService: AbstractAuthService,
    private environmentService: AbstractEnvironmentService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const requestUrl = req.url;
    if (isPlatformServer(this.platformId)) {
      return next.handle(req);
    }

    if (requestUrl.includes(`${this.environmentService.authBaseUrl}/token`)) {
      return next.handle(req);
    }
    const executeRequest = switchMap(
      (authData: { idToken: string; refreshToken: string }) =>
        next.handle(
          req.clone({
            headers: req.headers.set(
              'Authorization',
              `Bearer ${authData?.idToken || 'anonymous'}`
            ),
            // .set('user-token', `Bearer ${authData?.token || 'anonymous'}`), FIXME: [WEB-87] Some API's use this, but others will give CORS errors
          })
        )
    );

    return this.authService.isAuthenticated$.pipe(
      filter((isAuthenticated) => {
        if (
          requestUrl.includes('/brands/config') ||
          requestUrl.includes('/brands/localization') ||
          requestUrl.includes('/auth/verifyEmail') ||
          requestUrl.includes('/auth/resetPassword')
        ) {
          return true;
        }
        return isAuthenticated !== undefined;
      }),
      take(1),
      switchMap((isAuthenticated) => {
        if (!isAuthenticated) {
          return next.handle(req);
        }
        return this.authService.getTokenDetails().pipe(
          take(1),
          executeRequest,
          catchError((error) => {
            if (error.status !== 401) {
              return throwError(() => error);
            }

            if (error.status === 403 && !requestUrl.includes('/metadata')) {
              this.authService.logOut(true);
            }

            if (this.authService.isRefreshing$.getValue()) {
              return this.authService.isRefreshing$.pipe(
                filter((isRefreshing) => !isRefreshing),
                take(1),
                switchMap(() => this.authService.getTokenDetails()),
                executeRequest
              );
            }

            this.authService.isRefreshing$.next(true);

            return this.authService.refreshToken().pipe(
              tap(() => {
                this.authService.isRefreshing$.next(false);
              }),
              switchMap(() => this.authService.getTokenDetails()),
              executeRequest
            );
          })
        );
      })
    );
  }
}
