import { Injectable } from '@angular/core';
import {
  AbstractBackendService,
  AbstractEnvironmentService,
  AbstractLanguageService,
  AbstractStorageService,
} from '@ibep/interfaces';
import { MINUTE } from '@ibep/shared/util';
import { Observable, of } from 'rxjs';
import { shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { ConfigData } from './config.data';
import { Store, StoreSettings, StoreSettingsTTL } from './store';

@Injectable({
  providedIn: 'root',
})
export class BibleMediaData extends Store<any> {
  constructor(
    storage: AbstractStorageService,
    environment: AbstractEnvironmentService,
    backendService: AbstractBackendService,
    languageService: AbstractLanguageService,
    config: ConfigData
  ) {
    super(storage, environment, backendService, languageService, {
      storeTtl: { default: 1 * MINUTE } as StoreSettingsTTL,
      storeKey: 'bibleMedia',
      brand$: config.getBrand(),
    } as StoreSettings);
  }

  public getBibleMediaItem({
    bibleId,
    filename,
  }: {
    bibleId: string;
    filename: string;
  }): Observable<any> {
    const endpoint = `bibles/${bibleId}/media-package-item/${encodeURIComponent(
      filename
    )}`;

    this.remoteDataMap$[endpoint] = this.backendService
      .get<any>({
        endpoint,
        options: {
          headers: {
            'x-brand': this.brand.id,
            Authorization: 'Bearer anonymous',
            redisTTL: `${this.storeSettings.storeTtl.default}`,
          },
        },
      })
      .pipe(shareReplay(1));

    return this.localData$.pipe(
      take(1),
      switchMap((localData) => {
        // check if there is local data available
        if (localData?.[endpoint]) {
          return of(localData[endpoint]);
        }
        // if no local data, fetch data from api
        return this.remoteDataMap$[endpoint].pipe(
          tap((remoteData) => {
            // save data from api to the store
            this.setState(
              {
                [endpoint]: remoteData,
              },
              true
            );
          })
        );
      })
    ) as Observable<any>;
  }
}
