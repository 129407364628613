import { Component } from '@angular/core';

@Component({
  selector: 'ibep-move-left-svg',
  template: `<svg
    ibepHideTillInit
    class="h-5 w-5 inline"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>compare-left</title>
    <g
      id="Design"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="Search-Results--&gt;-Bible-&gt;-Compare-(with-preview)"
        transform="translate(-475.000000, -419.000000)"
      >
        <g
          id="compare-left"
          transform="translate(484.000000, 428.000000) scale(-1, 1) translate(-484.000000, -428.000000) translate(476.000000, 420.000000)"
        >
          <circle id="Oval" stroke="#C0D0E4" cx="8" cy="8" r="8"></circle>
          <path
            d="M6.5088951,11.8210109 C6.62645824,11.940533 6.77327196,12 6.92008274,12 C7.06689352,12 7.2137043,11.94053 7.33127037,11.8210109 C7.56640252,11.5819608 7.56640252,11.2088181 7.33127037,10.970365 L5.01151323,8.59687903 L11.4127569,8.59687903 C11.7356231,8.59687903 12,8.3280969 12,7.99985074 C12,7.67160459 11.7356231,7.40282245 11.4127569,7.40282245 L5.01151323,7.40282245 L7.34551102,5.02993351 C7.58064316,4.79088338 7.58064316,4.4177407 7.34551102,4.1792876 C7.11037887,3.94023747 6.74335192,3.94023747 6.50880702,4.1792876 L3.17634911,7.58190109 C2.94121696,7.82095122 2.94121696,8.1940939 3.17634911,8.432547 L6.5088951,11.8210109 Z"
            id="Fill-1"
            fill="#889FBC"
            transform="translate(7.500000, 8.000000) scale(-1, -1) translate(-7.500000, -8.000000) "
          ></path>
        </g>
      </g>
    </g>
  </svg>`,
  styles: [],
})
export class MoveLeftComponent {}
