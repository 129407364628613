import { Component } from '@angular/core';

@Component({
  selector: 'bible-p',
  template: `
    <p class="p">
      <ng-content></ng-content>
    </p>
  `,
  styleUrls: ['./p.component.scss'],
})
export class PComponent {}
