<!-- Menu button  -->
<div class="flex justify-start lg:hidden">
  <div
    class="w-6 h-6 mt-[2px] rounded-full flex justify-center items-center"
    [ngClass]="{
      'bg-white border border-primary': isAuthenticated,
      'bg-primary': !isAuthenticated
    }"
    (click)="toggleMenu()"
  >
    <ibep-person-svg
      class="pb-1 cursor-pointer"
      [ngClass]="{
        'text-primary': isAuthenticated,
        'text-white': !isAuthenticated
      }"
      svgStyles="w-4 h-4"
    ></ibep-person-svg>
  </div>
</div>
<!-- Mobile Menu -->
<div
  class="fixed inset-x-0 inset-y-0 w-100vw h-100vh z-50 overflow-y-hidden lg:hidden bg-white"
  *ngIf="showMobileMenu"
>
  <div>
    <!-- close menu button  -->
    <div class="flex justify-end items-center border-b px-4 h-12">
      <ibep-button
        (click)="toggleMenu()"
        [buttonProps]="{
          pushGtm: true,
          content: 'CloseMenu' | translate,
          size: 's'
        }"
        class="text-primary text-xs"
      >
        <span class="pr-[4px]">{{ 'CloseMenu' | translate }}</span>
        <ibep-close-svg svgStyles="w-5 h-5 pt-[2px]"></ibep-close-svg>
      </ibep-button>
    </div>

    <!-- Menu items  -->
    <div class="px-4">
      <nav class="grid">
        <ng-container *ngIf="!isAuthenticated">
          <a
            [routerLink]="[]"
            [queryParams]="{ view: 'signIn' }"
            (click)="toggleMenu()"
            class="text-header py-2 text-sm border-b border-backgroundGray h-[52px] flex items-center justify-between hover:text-primary group"
          >
            <button
              type="button"
              class="inline-flex items-center text-xs text-grayMedium group-hover:text-primary"
            >
              <span>{{ 'SignIn' | translate }}</span>
            </button>

            <span>
              <ibep-chevron-right-svg
                class="text-grayLight group-hover:text-primary"
                svgStyles="w-3 h-3"
              ></ibep-chevron-right-svg>
            </span>
          </a>

          <a
            [routerLink]="[]"
            [queryParams]="{ view: 'signUp' }"
            (click)="toggleMenu()"
            class="text-header py-2 text-sm border-b border-backgroundGray h-[52px] flex items-center justify-between hover:text-primary group"
          >
            <button
              type="button"
              class="inline-flex items-center text-xs text-grayMedium group-hover:text-primary"
            >
              <span>{{ 'SignUp' | translate }}</span>
            </button>
            <span>
              <ibep-chevron-right-svg
                class="text-grayLight group-hover:text-primary"
                svgStyles="w-3 h-3"
              ></ibep-chevron-right-svg>
            </span>
          </a>
        </ng-container>

        <ng-container *ngIf="isAuthenticated">
          <a
            [routerLink]="'/' + 'ROUTES.notes-highlights' | localize"
            (click)="toggleMenu()"
            class="text-header py-2 text-sm border-b border-backgroundGray h-[52px] flex items-center justify-between hover:text-primary group"
          >
            <button
              type="button"
              class="inline-flex items-center text-xs text-grayMedium group-hover:text-primary"
            >
              <span>{{ 'YourNotesAndHighlights' | translate }}</span>
            </button>

            <span>
              <ibep-chevron-right-svg
                class="text-grayLight group-hover:text-primary"
                svgStyles="w-3 h-3"
              ></ibep-chevron-right-svg>
            </span>
          </a>
          <ng-container *ngFor="let tab of accountTabs">
            <a
              [routerLink]="[accountRoute]"
              [queryParams]="{ tab: tab.value }"
              (click)="toggleMenu()"
              class="text-header py-2 text-sm border-b border-backgroundGray h-[52px] flex items-center justify-between hover:text-primary group"
            >
              <button
                type="button"
                class="inline-flex items-center text-xs text-grayMedium group-hover:text-primary"
              >
                <span>{{ tab.title }}</span>
              </button>

              <span>
                <ibep-chevron-right-svg
                  class="text-grayLight group-hover:text-primary"
                  svgStyles="w-3 h-3"
                ></ibep-chevron-right-svg>
              </span>
            </a>
          </ng-container>

          <a
            [routerLink]="[]"
            (click)="toggleMenu(); logOut()"
            class="text-header py-2 text-sm border-b border-backgroundGray h-[52px] flex items-center justify-between hover:text-primary group"
          >
            <button
              type="button"
              class="inline-flex items-center text-xs text-grayMedium group-hover:text-primary"
            >
              <span>{{ 'LogOut' | translate }}</span>
            </button>

            <span>
              <ibep-chevron-right-svg
                class="text-grayLight group-hover:text-primary"
                svgStyles="w-3 h-3"
              ></ibep-chevron-right-svg>
            </span>
          </a>
        </ng-container>
      </nav>
    </div>
  </div>
</div>
