import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'ibep-inline-note',
  templateUrl: './inline-note.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InlineNoteComponent {
  @Input() props: {
    text: string;
    id: string;
    type: string;
    icon: string;
  };
}
