import { ReadingPlan } from './readingplans.tags.interface';

export enum SearchCategory {
  ALL = 'ALL',
  BIBLE = 'BIBLE',
  EBC = 'EBC',
  PAGES = 'PAGES',
  POSTS = 'POSTS',
  READING_PLANS = 'READING_PLANS',
  EPISODES = 'EPISODES',
}

export interface SearchCategoryButton {
  value: SearchCategory;
  text: string;
  checked: boolean;
  hidden?: boolean;
}

export interface SearchSuggestion {
  bibleId: string;
  source: string;
  sourceLocal: string;
  text: string;
  type: 0 | 1;
}

export interface SearchSuggestionsWebSocketResponse {
  query: string;
  suggestions: SearchSuggestion[];
}

export interface SearchSuggestionsWebSocketMessage {
  action: string;
  query: string;
  bibleId: string;
}

export interface SiblingVerse {
  id: string;
  bookId: string;
}

export interface BibleContentItem {
  id: string;
  name: string;
  checked: boolean;
}

export interface BibleSortingItem {
  id: 'canonical' | 'relevance';
  name: string;
  checked: boolean;
}

export interface BibleSearchContent {
  type: 'verse' | 'heading' | 'studycontent';
  translationId: string;
  bookId: string;
  chapterId: string;
  verseId: string;
  text: string;
  fullName?: string;
  bookNameShortLocal: string;
  matches: string[];
  checked?: boolean;
  hover?: boolean;
  slug: string;
  linkInternal: string;
}

export interface BibleVerseItem {
  type: 'verse' | 'heading' | 'studycontent';
  bibleId: string;
  bookId: string;
  chapterId: string;
  title?: string;
  content: string;
  id: string;
  idRange: string[];
  slug: string;
  linkInternal: string;
  fullName?: string;
  bookNameShortLocal: string;
  checked?: boolean;
  hover?: boolean;
}

export interface SearchData<T> {
  pages: number;
  total: number;
  commonTotal?: number;
  items: T[];
}

export interface BibleExactMatch {
  bookId?: string;
  chapterId?: string;
  verseId?: string;
  passageId?: string;
}

export interface ContentTypeTotal {
  contentType: 'verse' | 'heading' | 'studycontent';
  total: number;
}

export interface ContentTypeTotal {
  contentType: 'verse' | 'heading' | 'studycontent';
  total: number;
}

export interface BookTotal {
  name?: string;
  id: string;
  total: number;
  hidden?: boolean;
}

export interface TestamentTotal {
  id: string;
  total: number;
  hidden?: boolean;
  books: BookTotal[];
}

export interface BibleSearchResult {
  bibleId: string;
  query: string;
  limit?: number;
  page?: number;
  total: number;
  exactMatch?: BibleExactMatch;
  verses?: BibleVerseItem[];
  text?: SearchData<BibleSearchContent>;
  headers?: SearchData<BibleSearchContent>;
  footnotes?: SearchData<BibleSearchContent>;
  totalsByContentType?: ContentTypeTotal[];
  totalsByTestament?: TestamentTotal[];
}

export interface SiteContentSearchResult {
  id: number;
  slug: string;
  title: string;
  content: string;
  linkInternal: string;
}

export class SearchResultCategory<T> {
  name: SearchCategory;
  title: string;
  items: T[];
  total: number;
  pages?: number;
  lastPage: number;
  totalsByContentType?: ContentTypeTotal[];
  totalsByTestament?: TestamentTotal[];
}

export interface ReadingPlanForEpisode {
  id: number;
  title: string;
  name: string;
}

export interface ReadingPlanOrEpisodeItem {
  id: number;
  isVerseOfTheDay: boolean;
  slug: string;
  title: string;
  acf: ReadingPlan;
  content?: string;
  linkInternal: string;
  type?: 'readingPlan' | 'episode';
  plans: ReadingPlanForEpisode[];
}

export interface ReadingPlansOrEpisodesSearchResult {
  items: ReadingPlanOrEpisodeItem[];
  pages?: number;
  total: number;
  commonTotal?: number;
}

export interface EbcItem {
  id: number;
  slug: string;
  title: string;
  acf: any;
  content?: string;
  linkInternal: string;
}

export interface EbcSearchResult {
  items: EbcItem[];
  pages?: number;
  total: number;
}
