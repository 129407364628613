import { Injectable } from '@angular/core';
import {
  AbstractBackendService,
  AbstractEnvironmentService,
  AbstractLanguageService,
  AbstractStorageService,
} from '@ibep/interfaces';
import { MONTH } from '@ibep/shared/util';
import { Observable, of } from 'rxjs';
import { switchMap, tap, shareReplay } from 'rxjs/operators';
import { ConfigData } from '..';
import { Store, StoreSettings, StoreSettingsTTL } from './store';

@Injectable({
  providedIn: 'root',
})
export class UserNotesListData extends Store<any> {
  constructor(
    storage: AbstractStorageService,
    environment: AbstractEnvironmentService,
    backendService: AbstractBackendService,
    languageService: AbstractLanguageService,
    config: ConfigData
  ) {
    super(storage, environment, backendService, languageService, {
      storeTtl: { default: 1 * MONTH } as StoreSettingsTTL,
      storeKey: 'userNotesList',
      brand$: config.getBrand(),
      doNotStoreLocally: true,
    } as StoreSettings);
  }

  /**
   * Get a list of user notes
   *
   * @returns {Observable<any>}
   * @memberof UserNotesListData
   */
  public get({
    page,
    limit,
    bibleId,
    sortField,
    sortOrder,
    filterScope,
    filterScopeId,
  }: {
    page: number;
    limit: number;
    bibleId?: string;
    sortField: string;
    sortOrder: string;
    filterScope?: string;
    filterScopeId?: string;
  }): Observable<any> {
    // create query params string from input params, only include the defined params
    const queryParams = Object.entries({
      page,
      limit,
      bibleId,
      sortField,
      sortOrder,
      filterScope,
      filterScopeId,
    })
      .filter(([, value]) => value !== undefined)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');

    const key = queryParams;
    const remoteData$ = this.backendService
      .get<any>({
        endpoint: `user/notes/list?${queryParams}`,
        options: {
          headers: {
            'x-brand': this.brand.id,
          },
        },
      })
      .pipe(shareReplay(1));

    return this.localData$.pipe(
      switchMap((localData) => {
        // check if there is local data available
        if (localData?.[key]) {
          return of(localData[key]);
        }
        // if no local data, fetch data from api
        return remoteData$.pipe(
          tap((remoteData: any) => {
            // write returned data from api to the store
            // this.setState(
            //   {
            //     [key]: remoteData.data,
            //   },
            //   true
            // );
          })
        );
      })
    );
  }
}
