import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  PLATFORM_ID,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BaseComponent, LanguageService } from '@ibep/fe/shared/core';
import {
  ConfigLocalizedData,
  UserData,
  UserInfoData,
} from '@ibep/fe/shared/data';
import { AbstractAuthService, AvailableLanguage } from '@ibep/interfaces';
import { combineLatest, takeUntil } from 'rxjs';

@Component({
  selector: 'ibep-language-picker',
  templateUrl: './language-picker.component.html',
})
export class LanguagePickerComponent extends BaseComponent implements OnInit {
  public currentLanguage: string;
  public currentRoute: string;
  public langList;
  public showDropdown = false;
  @ViewChild('langButton')
  private langButton!: ElementRef;
  @ViewChild('langDropdown')
  private langDropdown!: ElementRef;
  private listenFn: any;
  private isAuthenticated: boolean;
  private isbrowser: boolean;

  @Input() languages: AvailableLanguage[] = [];

  @Output() languageChanged = new EventEmitter<string>();

  constructor(
    private readonly _langService: LanguageService,
    private readonly _configData: ConfigLocalizedData,
    private readonly _userInfoData: UserInfoData,
    private readonly _userData: UserData,
    private readonly _renderer: Renderer2,
    private readonly _router: Router,
    private readonly _ref: ChangeDetectorRef,
    private readonly _authService: AbstractAuthService,
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(DOCUMENT)
    public document: Document
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.languages?.length) {
      this.langList = this.languages.map((language) => ({
        languageCode: language.languageCode,
        localName: this._langService.getLanguageName(
          language.languageCode,
          true
        ),
      }));
      this._configData.getConfig().subscribe((config) => {
        this.isbrowser = config.platform === 'browser';
      });
      this._authService.isAuthenticated$.subscribe((isAuthenticated) => {
        this.isAuthenticated = isAuthenticated;
      });

      // subcribe to language changes
      this._langService.currentLanguage$
        .pipe(takeUntil(this._destroy))
        .subscribe((language) => {
          // set the currentLanguage
          this.currentLanguage = this.langList.filter(
            (lang) => lang.languageCode === language
          )?.[0]?.localName;
        });
      // subscribe to route changes
      this.currentRoute = this._router.url;
      this._router.events.pipe(takeUntil(this._destroy)).subscribe((val) => {
        if (val instanceof NavigationEnd) {
          this.currentRoute = val.urlAfterRedirects;
        }
      });
    }
  }

  /**
   * Change the language
   * @param language
   */
  public changeLanguage(language) {
    if ((this.isbrowser, this.isAuthenticated)) {
      combineLatest([
        this._userData.getAuthData(),
        this._userInfoData.getUserInfo(),
      ]).subscribe(([userAuth, userInfo]) => {
        this._langService.changeLanguage(language, userInfo, userAuth);
      });
    } else {
      this._langService.changeLanguage(language, null, null);
    }

    this.toggleDropdown();
  }

  /**
   *  Toggle the languages dropdown
   */
  public toggleDropdown() {
    if (isPlatformBrowser(this.platformId)) {
      if (this.showDropdown) {
        this.showDropdown = !this.showDropdown;
        // scroll prevention mobile dropdown
        this._renderer.removeClass(this.document.body, 'overflow-y-hidden');
        this._renderer.removeClass(this.document.body, 'lg:overflow-y-scroll');
      } else {
        this._registerListener();
        this.showDropdown = !this.showDropdown;
        // scroll prevention mobile dropdown
        this._renderer.addClass(this.document.body, 'overflow-y-hidden');
        this._renderer.addClass(this.document.body, 'lg:overflow-y-scroll');
      }
    }
  }

  /**
   *  To be able to close the popup when clicking outside the popup, we need to set up a listener
   */
  private _registerListener() {
    // listen for clicks outside popup
    this.listenFn = this._renderer.listen('window', 'click', (e: Event) => {
      if (
        !this.langButton?.nativeElement.contains(e.target) &&
        !this.langDropdown?.nativeElement.contains(e.target) &&
        this.showDropdown
      ) {
        // close the popup
        this.showDropdown = false;
        // unregister the listener
        this.listenFn();
        // we need to detect changes manually
        this._ref.detectChanges();
      }
    });
  }
}
