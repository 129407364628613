import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { ReaderOptions, ReaderStateInterface } from '@ibep/fe/shared/data';
import { Config } from '@ibep/interfaces';
import { TranslateService } from '@ngx-translate/core';

const fonts = {
  montserrat: 'Montserrat',
  roboto: 'Roboto',
  poppins: 'Poppins',
  titilliumWeb: 'TitilliumWeb',
  openSans: 'Open Sans',
  lato: 'Lato',
  raleway: 'Raleway',
  notoSans: 'Noto Sans',
  'noto-serif': 'Noto Serif',
  notoSerif: 'Noto Serif',
  merriweather: 'Merriweather',
  ubuntu: 'Ubuntu',
  'pt-serif': 'PT Serif',
  ptSans: 'PT Sans',
  oswald: 'Oswald',
  'fira-sans': 'Fira Sans',
  nunitoSans: 'Nunito Sans',
  quicksand: 'Quicksand',
  sourceSansPro: 'Source Sans Pro',
};

// TODO: This component needs to be refactored - Use modal service
@Component({
  selector: 'ibep-options-picker',
  templateUrl: './options-picker.component.html',
  styleUrls: ['./options-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptionsPickerComponent implements OnChanges {
  @Input() readerOptions: ReaderOptions = <ReaderOptions>{};
  @Input() isAuthenticated: boolean;
  @Input() userAccountSystem: boolean;
  @Input() readerState: ReaderStateInterface;
  @Input() useEBC: boolean;
  @Input() config: Config;
  @Input() allowParallelReadingFor: 'all' | 'registered' | 'premium';

  @Output() readerStateSet = new EventEmitter<any>();
  @Output() webfontLoad = new EventEmitter<any>();
  @Output() pushGtmTag = new EventEmitter<object>();
  @Output() ebcToggle = new EventEmitter<boolean>();
  @Output() translationPickerOpen = new EventEmitter<boolean>();

  public readingMode: string;
  public brandFont: string;

  constructor(
    private readonly _translateService: TranslateService,
    private readonly _router: Router
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.readerOptions &&
      changes.readerOptions?.currentValue?.readingMode !==
        changes.readerOptions?.previousValue?.readingMode
    ) {
      const readingModeTranslateKey =
        changes.readerOptions.currentValue.readingMode.charAt(0).toUpperCase() +
        changes.readerOptions.currentValue.readingMode.slice(1);
      this.readingMode = this._translateService.instant(
        readingModeTranslateKey
      );
    }

    if (changes.readerState) {
      if (
        changes.readerState.currentValue?.readingMode !==
          changes.readerState.previousValue?.readingMode ||
        changes.readerState.firstChange
      ) {
        this.setFontFamily(
          changes.readerState.currentValue.readerOptions.fontType
        );
      }
    }

    if (
      changes.config &&
      changes.config?.currentValue?.readingMode !==
        changes.config?.previousValue?.readingMode
    ) {
      this.brandFont = fonts[this.config.theme.font];
    }
  }

  /**
   * Save readeroptions to the store
   *
   * @param {string} option
   * @param {string} [state]
   * @memberof OptionsPickerComponent
   */
  public setReaderOption(event: MouseEvent, option: string, state?: string) {
    // we need it not to call setReaderOption($event, 'readingMode', 'custom')
    // each time when we toggle custom options
    if (!state) {
      event.stopPropagation();
    }

    const { readerOptions } = this;
    let readerOptionsData: any;

    if (
      (!this.isAuthenticated && option === 'showUserHighlights') ||
      (!this.isAuthenticated && option === 'showUserNotes')
    ) {
      this._router.navigate([], {
        queryParams: { view: 'signUp' },
      });
      return;
    }

    if (state === 'easy' || state === 'advanced' || state === 'custom') {
      const readingModeTranslateKey =
        state.charAt(0).toUpperCase() + state.slice(1);

      this.readingMode = this._translateService.instant(
        readingModeTranslateKey
      );
    }

    if (state !== 'easy' && state !== 'advanced') {
      // save option to the store
      readerOptionsData = {
        [option]:
          state || !this.readerOptions[option as keyof typeof readerOptions],
      };

      this.readerStateSet.emit({
        readerOptions: readerOptionsData,
      });

      // we only load fonts when they are selected
      if (option === 'fontType') {
        this.setFontFamily(state as string);
      }
    } else {
      // set easy and advanced reading modes
      readerOptionsData = {
        [option]: state,
        showVerseNumbers: true,
        showFootnotes: state !== 'easy',
        showCrossReferences: state !== 'easy',
        showUserNotes: state !== 'easy' && this.isAuthenticated,
        showUserHighlights: state !== 'easy' && this.isAuthenticated,
      };

      this.readerStateSet.emit({
        readerOptions: readerOptionsData,
      });
    }

    this.onGtmTagPush({
      event: 'reader_options',
      font_size: readerOptionsData.fontSize,
      font_family: readerOptionsData.fontType,
      reading_options: readerOptionsData,
    });
  }

  public toggleEbc() {
    this.ebcToggle.emit(true);
  }

  public openTranslationPicker() {
    this.translationPickerOpen.emit(true);
  }

  /**
   * Conditionally load fonts
   *
   * @param {string} font
   * @returns
   * @memberof OptionsPickerComponent
   */
  setFontFamily(font: string) {
    this.webfontLoad.emit(font);
  }

  public onGtmTagPush(tag: object): void {
    this.pushGtmTag.emit(tag);
  }
}
