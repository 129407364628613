import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'ibep-quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuoteComponent implements OnInit {
  renderBlocks = false;

  ngOnInit(): void {
    this.renderBlocks = this.content?.innerBlocks?.length > 0;
  }

  @Input() content: any;
}
