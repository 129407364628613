<footer class="bg-gradientPrimary bg-opacity-5">
  <div
    class="max-w-container m-auto grid grid-cols-1 pl-4 pr-4 lg:grid-cols-3 xl:p-0"
  >
    <div class="menu pt-9 lg:py-14">
      <h3 class="text-lg text-grayMedium lg:text-base font-bold pb-4">
        {{ 'WebsiteTitle' | translate }}
      </h3>
      <nav>
        <ul>
          <li class="h-10" *ngFor="let item of config.menus.footer.items">
            <ibep-link
              [linkProps]="{
                link: item.link,
                isExternalLink: item.linkType === 'external',
                linkStyles:
                  'h-10 text-xs text-grayMedium lg:underline hover:text-secondary transition flex justify-between'
              }"
            >
              <span [innerHTML]="item.title"></span>
              <ibep-chevron-right-svg
                class="text-grayLight lg:hidden"
                svgStyles="w-3 h-3"
              ></ibep-chevron-right-svg>
            </ibep-link>
          </li>
        </ul>
      </nav>
    </div>

    <div class="description text-grayMedium pt-4 lg:pt-14 lg:py-14">
      <h3
        class="text-lg lg:text-base font-bold pb-3"
        [innerHTML]="config.footerContent?.textTitle"
      ></h3>

      <p
        class="footer-link text-xs leading-6 lg:pr-28"
        [innerHTML]="config.footerContent?.textDescription"
      ></p>
    </div>

    <div class="mobile py-9 lg:py-14 grid grid-cols-1">
      <div
        class="newsletter text-grayMedium lg:block"
        *ngIf="config.footerContent?.newsletterTitle"
      >
        <h3
          class="block text-lg lg:text-base font-bold pb-3"
          [innerHTML]="config.footerContent.newsletterTitle"
        ></h3>

        <p
          class="block text-xs mb-6 leading-6"
          [innerHTML]="config.footerContent.newsletterSubttitle"
        ></p>

        <a
          class="group"
          [href]="config.footerContent?.link"
          target="_blank"
          *ngIf="config.footerContent?.newsletterButtonText"
        >
          <ibep-button
            class="text-sm mb-4 lg:mb-0 lg:mr-3"
            [buttonProps]="{
              pushGtm: true,
              name: 'newsletter_subscribe',
              content: config.footerContent.newsletterButtonText,
              link: config.footerContent?.link,
              size: 'l'
            }"
          >
            <span
              class="text-secondary font-bold group-hover:text-primary ml-2 px-4 line-clamp-1"
              [innerHTML]="config.footerContent.newsletterButtonText"
            ></span>
          </ibep-button>
        </a>
      </div>

      <div
        *ngIf="
          config.mobileAppLinks?.googlePlayStore ||
          config.mobileAppLinks?.appleStore
        "
        class="mobile py-9"
        [ngClass]="{ 'lg:pt-0': !config.footerContent.newsletterTitle }"
      >
        <span class="pb-4 text-lg lg:text-base"
          ><b>{{ 'DownloadOurApp' | translate }}</b></span
        >

        <div class="flex pt-4">
          <div
            *ngIf="config.mobileAppLinks?.googlePlayStore"
            class="flex flex-col"
          >
            <a
              class="cursor-pointer"
              [href]="config.mobileAppLinks?.googlePlayStore"
              target="_blank"
              (click)="onStoreClick(config.mobileAppLinks?.googlePlayStore)"
            >
              <div class="android h-11 w-32 bg-pink-50 text-3xs mr-4">
                <img
                  class="h-11 w-32"
                  src="/assets/icons/android_lr.png"
                  alt=""
                />
              </div>
              <span class="text-base text-grayLight mt-2">{{
                'Android' | translate
              }}</span>
            </a>
          </div>
          <div *ngIf="config.mobileAppLinks?.appleStore" class="flex flex-col">
            <a
              class="cursor-pointer"
              [href]="config.mobileAppLinks?.appleStore"
              target="_blank"
              (click)="onStoreClick(config.mobileAppLinks?.appleStore)"
            >
              <div class="ios h-11 bg-pink-50 text-3xs">
                <img
                  class="h-11 w-[8.25rem]"
                  src="/assets/icons/ios_lr.png"
                  alt=""
                />
              </div>
              <span class="text-base text-grayLight mt-2">{{
                'iOS' | translate
              }}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>

<div class="max-w-container m-auto py-3">
  <ibep-sub-footer
    [appVersion]="appVersion"
    [config]="config"
    [currentLanguage]="currentLanguage"
    [isDefaultLanguage]="isDefaultLanguage"
    (pushSocialTag)="onSocialClick($event)"
  ></ibep-sub-footer>
</div>
