<div>
  <!-- Title  -->
  <h4 *ngIf="props.attrs.title" class="font-bold text-2xl mb-3 px-3 sm:px-0">
    {{ props.attrs.title }}
  </h4>
  <div
    *ngIf="props.attrs.image || props.attrs.content"
    class="grid gap-2 mb-3"
    [ngClass]="{ 'lg:grid-cols-2': props.attrs.image && props.attrs.content }"
  >
    <!-- Image  -->
    <div
      *ngIf="props.attrs.image"
      [ngClass]="{
        'order-last': props.attrs.image_location === 'right',
        'col-span-2': props.attrs.image_location === 'full'
      }"
    >
      <img
        [ngSrc]="props.attrs.image?.sizes.medium"
        [width]="props.attrs.image?.sizes['large-width']"
        [height]="props.attrs.image?.sizes['large-height']"
        [alt]="props.attrs.image?.alt"
      />
    </div>
    <!-- Content  -->
    <div
      *ngIf="props.attrs.content"
      class="px-3 sm:px-0"
      [ngClass]="{
        'col-span-2': props.attrs.image_location === 'full'
      }"
      [innerHTML]="props.attrs.content"
    >
      <!-- Commented out for now, maybe have to use this one instead off the innerHTML -->
      <!-- <ibep-paragraph [html]="props.attrs.content"></ibep-paragraph> -->
    </div>
  </div>

  <!-- Buttons  -->
  <div *ngIf="props.attrs.buttons?.length" class="flex gap-1 px-3 sm:px-0">
    <ibep-link
      *ngFor="let button of props.attrs.buttons"
      [linkProps]="{
        link: button.linkurl,
        isExternalLink: false
      }"
    >
      <ibep-button
        [buttonProps]="{
          content: button.title,
          link: button.linkurl,
          style: 'secondary',
          size: 'l'
        }"
      >
        {{ button.title }}
      </ibep-button>
    </ibep-link>
  </div>
</div>
