<div class="mx-5 lg:mx-0 border border-secondary rounded-lg">
  <div #boxWrapper class="lg:flex">
    <div
      class="lg:w-[calc(75%-1.75rem)] px-4 py-3 bg-secondary bg-opacity-[.08]"
    >
      <h3
        class="font-bold text-[19px] tracking-wider"
        *ngIf="triggerBoxSettings?.title"
      >
        <span [innerHTML]="triggerBoxSettings!.title"></span>
      </h3>
      <div class="grid grid-cols-1 lg:grid-cols-2 mt-8 lg:gap-8 px-5 lg:px-0">
        <ibep-paragraph
          *ngIf="triggerBoxSettings?.leftSideContent"
          [html]="triggerBoxSettings!.leftSideContent"
        ></ibep-paragraph>
        <ibep-paragraph
          *ngIf="triggerBoxSettings?.rightSideContent"
          [html]="triggerBoxSettings!.rightSideContent"
        ></ibep-paragraph>
      </div>
    </div>

    <div
      class="hidden lg:block w-7 bg-secondary bg-opacity-[.08] border-y-secondary border-l-[28px] border-l-transparent border-opacity-[.12]"
      [style.border-top-width.px]="boxWrapper.clientHeight / 2"
      [style.border-bottom-width.px]="boxWrapper.clientHeight / 2"
    ></div>

    <div
      class="py-5 lg:py-0 lg:w-1/4 bg-secondary bg-opacity-20 flex items-center"
    >
      <div class="w-full px-10">
        <div class="text-center">
          <ibep-button
            (click)="openModal('signUp')"
            [buttonProps]="{
              pushGtm: true,
              content: 'CreateYourFree*' | translate,
              style: 'cta',
              size: 'xl'
            }"
          >
            <b class="text-xs px-5">{{ 'CreateYourFree*' | translate }}</b>
          </ibep-button>
        </div>
        <div
          class="text-center mt-3"
          *ngIf="
            triggerBoxSettings?.findOutMoreFirstButton?.name &&
            triggerBoxSettings?.findOutMoreFirstButton?.link
          "
        >
          <ibep-link
            [linkProps]="{
              link: triggerBoxSettings!.findOutMoreFirstButton.link,
              isExternalLink: false,
              linkStyles:
                'h-10 text-xs text-grayMedium lg:underline text-secondary hover:text-primary transition'
            }"
          >
            <span>{{ triggerBoxSettings?.findOutMoreFirstButton?.name }}</span>
          </ibep-link>
        </div>
      </div>
    </div>
  </div>
</div>
