import { Component, Input } from '@angular/core';

@Component({
  selector: 'ibep-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
})
export class ToggleComponent {
  @Input() isActive = true;
  @Input() isDisabled = true;
}
