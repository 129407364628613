import { Component } from '@angular/core';

@Component({
  selector: 'bible-pm',
  template: `
    <p class="pm">
      <ng-content></ng-content>
    </p>
  `,
  styleUrls: ['./pm.component.scss'],
})
export class PmComponent {}
