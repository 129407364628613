import { getLCP, getFID, getCLS } from 'web-vitals';
import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Environment } from '@ibep/interfaces';

@Injectable({
  providedIn: 'root',
})
export class PerformanceService {
  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject('ENVIRONMENT') private environment: Environment
  ) {}

  init() {
    if (
      isPlatformBrowser(this.platformId) &&
      this.environment.displayPerformanceLogs
    ) {
      getCLS(console.info);
      getFID(console.info);
      getLCP(console.info);
    }
  }
}
