import { DOCUMENT } from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'ibep-forgot-password',
  templateUrl: './forgot-password.component.html',
})
export class ForgotPasswordComponent implements OnChanges, OnInit {
  @Output() formSubmit = new EventEmitter<{
    email: string;
  }>();

  @Input() resetPasswordProps: {
    error: object | undefined;
    formDisabled: boolean;
    success: boolean;
  };

  public errors: { code: string; message: string } | undefined;

  public success = false;

  public form: UntypedFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl(undefined, [
      Validators.email,
      Validators.required,
      Validators.maxLength(50),
    ]),
  });

  constructor(
    @Inject(DOCUMENT)
    public document: Document
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    // check if we need to disable or enable the form
    if (
      !changes.resetPasswordProps?.isFirstChange() &&
      changes.resetPasswordProps?.currentValue.formDisabled !==
        changes.resetPasswordProps?.previousValue.formDisabled
    ) {
      this.form[
        changes.resetPasswordProps?.currentValue.formDisabled
          ? 'disable'
          : 'enable'
      ]();
    }

    // check for errors
    if (
      !changes.resetPasswordProps?.isFirstChange() &&
      changes.resetPasswordProps?.currentValue.error !==
        changes.resetPasswordProps?.previousValue.error
    ) {
      this.errors = changes.resetPasswordProps?.currentValue.error;
    }
  }

  ngOnInit(): void {
    this.resetPasswordProps.success = false;
  }

  public get email() {
    return this.form.get('email');
  }

  public onSubmit() {
    if (this.form.invalid) {
      return;
    }
    this.errors = undefined;
    this.resetPasswordProps.success = false;
    this.form.disable();

    const { email } = this.form.value;
    this.formSubmit.emit({ email });
  }
}
