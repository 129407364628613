<!-- Breadcrumbs  -->
<div
  *ngIf="!isMobileAppView"
  class="border-b border-grayExtraLight h-10 bg-grayExtraLight lg:bg-white"
>
  <div class="max-w-container m-auto px-6 py-3">
    <ibep-breadcrumbs
      [props]="{
        currentRouteTitle: plan?.title,
        parentRoutes: parentRoutes
      }"
    ></ibep-breadcrumbs>
  </div>
</div>
<ng-container *ngIf="!plan">
  <div class="h-20 col-span-4 text-center">
    <ibep-page-loading></ibep-page-loading>
  </div>
</ng-container>
<ng-container *ngIf="plan">
  <!-- Progressbar  -->
  <div class="mb-4 sticky top-0 z-10">
    <ibep-scroll-progress-bar></ibep-scroll-progress-bar>
  </div>
  <!-- Hero -->
  <ibep-hero
    [heroProps]="{
      title: plan.title,
      subtitle: plan.subline,
      author: plan.author,
      publishDate: plan.publishDate,
      dateFormat,
      showPublishDate: plan.showPublishDate,
      tags: plan.tags,
      image: plan.image?.large
    }"
  ></ibep-hero>
  <div
    class="flex lg:gap-32 gap-7 flex-col lg:flex-row lg:px-0 mb-8 text-grayMedium max-w-container mx-4 xl:mx-auto mt-10"
  >
    <div
      class="lg:pl-20 basis-3/5"
      [ngClass]="{
        '!mx-auto !pl-0 max-w-[610px]':
          !plan.sideContentBlock?.title &&
          !plan.sideContentBlock?.content &&
          !plan.sideContentBlock?.buttonText
      }"
    >
      <ibep-paragraph
        class="pb-10"
        *ngIf="plan.description"
        [html]="plan.description"
      ></ibep-paragraph>
      <ibep-episodes-list
        *ngIf="plan.episodes"
        [episodes]="plan.episodes"
        [planSlug]="plan.slug"
        [hasFixedDates]="plan.hasFixedDates"
        [dateFormat]="dateFormat"
        [tab]="tab"
      >
      </ibep-episodes-list>
    </div>
    <div
      class="basis-2/5"
      *ngIf="
        plan.sideContentBlock?.title ||
        plan.sideContentBlock?.content ||
        plan.sideContentBlock?.buttonText
      "
    >
      <div class="lg:sticky lg:top-4">
        <ibep-sidebar-block
          [props]="{
            imageSrc: plan.sideContentBlock?.image?.sizes?.large,
            imageAlt: plan.sideContentBlock?.image?.alt,
            title: plan.sideContentBlock?.title,
            content: plan.sideContentBlock?.content,
            buttonLink:
              plan.sideContentBlock?.linkType === 'internal'
                ? plan.sideContentBlock?.linkInternal
                : plan.sideContentBlock?.linkExternal,
            buttonText: plan.sideContentBlock?.buttonText,
            linkType: plan.sideContentBlock?.linkType
          }"
        ></ibep-sidebar-block>
      </div>
    </div>
  </div>
</ng-container>
