import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { TranslateModule } from '@ngx-translate/core';
import { FeWebCoreModule } from '@ibep/fe/web/core';
import { FeWebComponentsSharedModule } from '@ibep/fe/web/components-shared';
import { FeWebComponentsBibleModule } from '@ibep/fe/web/components-bible';
import { FeWebComponentsBibleSharedModule } from '@ibep/fe/web/components-bible-shared';
import { LogoComponent } from './atoms/logo/logo.component';
import { AlertComponent } from './molecules/alert/alert.component';
import { BlockItemGridComponent } from './molecules/block-item-grid/block-item-grid.component';
import { BlockItemComponent } from './molecules/block-item/block-item.component';
import { LoginComponent } from './molecules/login/login.component';
import { MenuFlyOutComponent } from './molecules/menu-fly-out/menu-fly-out.component';
import { MenuMobileComponent } from './molecules/menu-mobile/menu-mobile.component';
import { ToastComponent } from './molecules/toast/toast.component';
import { FooterComponent } from './organisms/footer/footer.component';
import { StyleguideComponent } from './util/styleguide/styleguide.component';
import { DashboardHeroComponent } from './molecules/dashboard-hero/dashboard-hero.component';
import { EpisodesListComponent } from './molecules/episodes-list/episodes-list.component';
import { ButtonHighlightComponent } from './atoms/button-highlight/button-highlight.component';
import { MenuSearchbarComponent } from './molecules/menu-searchbar/menu-searchbar.component';
import { LanguagePickerComponent } from './molecules/language-picker/language-picker.component';
import { SubFooterComponent } from './organisms/sub-footer/sub-footer.component';
import { AboutComponent } from './molecules/about/about.component';
import { ChapterPickerComponent } from './molecules/chapter-picker/chapter-picker.component';
import { BiblePickerComponent } from './molecules/bible-picker/bible-picker.component';
import { TranslationPickerComponent } from './molecules/translation-picker/translation-picker.component';
import { LinkComponent } from './atoms/link/link.component';
import { ModalComponent } from './molecules/modal/modal.component';
import { ParagraphComponent } from './molecules/paragraph/paragraph.component';
import { CallToActionComponent } from './molecules/call-to-action/call-to-action.component';
import { InlineNoteComponent } from './molecules/inline-note/inline-note.component';
import { InlineNotePassageComponent } from './molecules/inline-note-passage/inline-note-passage.component';
import { DonationComponent } from './molecules/donation/donation.component';
import { TestimonialComponent } from './molecules/testimonial/testimonial.component';
import { TableComponent } from './molecules/table/table.component';
import { ColumnsComponent } from './molecules/columns/columns.component';
import { SeparatorComponent } from './atoms/separator/separator.component';
import { QuoteComponent } from './molecules/quote/quote.component';
import { HeadingComponent } from './atoms/heading/heading.component';
import { EmbedComponent } from './molecules/embed/embed.component';
import { ImageComponent } from './atoms/image/image.component';
import { HeroComponent } from './molecules/hero/hero.component';
import { PaginationComponent } from './molecules/pagination/pagination.component';
import { SelectComponent } from './atoms/select/select.component';
import { SearchResultItemsComponent } from './molecules/search-result-items/search-result-items.component';
import { ButtonToggleGroupComponent } from './atoms/button-toggle-group/button-toggle-group.component';
import { ContentPageComponent } from './organisms/content-page/content-page.component';
import { ReadingPlansOverviewComponent } from './organisms/reading-plans-overview-page/reading-plans-overview-page.component';
import { ListComponent } from './molecules/list/list.component';
import { AudioEmbedComponent } from './molecules/audio-embed/audio-embed.component';
import { BreadcrumbsComponent } from './molecules/breadcrumbs/breadcrumbs.component';
import { ScrollProgressBarComponent } from './molecules/scroll-progress-bar/scroll-progress-bar.component';
import { RenderGutenbergBlocksComponent } from './organisms/render-gutenberg-blocks/render-gutenberg-blocks.component';
import { SidebarBlockComponent } from './molecules/sidebar-block/sidebar-block.component';
import { PagetreeNavComponent } from './molecules/pagetree-nav/pagetree-nav.component';
import { SearchResultsContentComponent } from './molecules/search-results-content/search-results-content.component';
import { SearchSuggestionsComponent } from './molecules/search-suggestions/search-suggestions.component';
import { TagsPickerComponent } from './molecules/tags-picker/tags-picker.component';
import { ReadingPlanPageComponent } from './organisms/reading-plan-page/reading-plan-page.component';
import { EpisodePageComponent } from './organisms/episode-page/episode-page.component';
import { TeaserHeroComponent } from './molecules/teaser-hero/teaser-hero.component';
import { TeaserListComponent } from './molecules/teaser-list/teaser-list.component';
import { SignupComponent } from './molecules/signup/signup.component';
import { AccountVerificationComponent } from './molecules/account-verification/account-verification.component';
import { ForgotPasswordComponent } from './molecules/forgot-password/forgot-password.component';
import { AccountSettingsComponent } from './organisms/account-settings/account-settings.component';
import { TabsComponent } from './molecules/tabs/tabs.component';
import { ValidationMessageComponent } from './atoms/validation-message/validation-message.component';
import { MenuAuthMobileComponent } from './molecules/menu-auth-mobile/menu-auth-mobile.component';
import { AccountTriggerBoxContentComponent } from './molecules/account-trigger-box-content/account-trigger-box-content.component';
import { AccountTriggerBarContentComponent } from './molecules/account-trigger-bar-content/account-trigger-bar-content.component';
import { EnvelopeContentComponent } from './molecules/envelope-content/envelope-content.component';
import { EbcPageComponent } from './organisms/ebc-page/ebc-page.component';
import { DeletedAccountComponent } from './molecules/deleted-account/deleted-account.component';
import { BecomePremiumComponent } from './molecules/become-premium/become-premium.component';
import { ContentRowComponent } from './molecules/content-row/content-row.component';
import { BiblepassageComponent } from './molecules/biblepassage/biblepassage.component';
import { TableOfContentsComponent } from './molecules/table-of-contents/table-of-contents.component';
import { BooksPickerComponent } from './molecules/books-picker/books-picker.component';
import { BibleContentPickerComponent } from './molecules/bible-content-picker/bible-content-picker.component';
import { VerseOfTheDayContainerComponent } from './organisms/verse-of-the-day-container/verse-of-the-day-container.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    TranslateModule.forChild(),
    LocalizeRouterModule,
    ReactiveFormsModule,
    FeWebCoreModule,
    FeWebComponentsSharedModule,
    FeWebComponentsBibleSharedModule,
    FeWebComponentsBibleModule,
    NgOptimizedImage,
  ],
  declarations: [
    BlockItemComponent,
    HeroComponent,
    FooterComponent,
    AlertComponent,
    ToastComponent,
    BlockItemGridComponent,
    LoginComponent,
    LogoComponent,
    MenuFlyOutComponent,
    MenuMobileComponent,
    StyleguideComponent,
    DashboardHeroComponent,
    TeaserHeroComponent,
    TeaserListComponent,
    EpisodesListComponent,
    ButtonHighlightComponent,
    MenuSearchbarComponent,
    LanguagePickerComponent,
    SubFooterComponent,
    AboutComponent,
    ChapterPickerComponent,
    BiblePickerComponent,
    TranslationPickerComponent,
    LinkComponent,
    ModalComponent,
    ListComponent,
    ContentPageComponent,
    ReadingPlansOverviewComponent,
    ReadingPlanPageComponent,
    EpisodePageComponent,
    ParagraphComponent,
    CallToActionComponent,
    InlineNoteComponent,
    DonationComponent,
    TestimonialComponent,
    TableComponent,
    ColumnsComponent,
    SeparatorComponent,
    QuoteComponent,
    HeadingComponent,
    EmbedComponent,
    ImageComponent,
    PaginationComponent,
    SelectComponent,
    SearchResultItemsComponent,
    ButtonToggleGroupComponent,
    AudioEmbedComponent,
    BreadcrumbsComponent,
    ScrollProgressBarComponent,
    RenderGutenbergBlocksComponent,
    SidebarBlockComponent,
    PagetreeNavComponent,
    InlineNotePassageComponent,
    SearchResultsContentComponent,
    SearchSuggestionsComponent,
    TagsPickerComponent,
    SignupComponent,
    AccountVerificationComponent,
    ForgotPasswordComponent,
    MenuAuthMobileComponent,
    AccountSettingsComponent,
    TabsComponent,
    AccountTriggerBoxContentComponent,
    AccountTriggerBarContentComponent,
    ValidationMessageComponent,
    EnvelopeContentComponent,
    EbcPageComponent,
    DeletedAccountComponent,
    BecomePremiumComponent,
    ContentRowComponent,
    BiblepassageComponent,
    TableOfContentsComponent,
    BooksPickerComponent,
    BibleContentPickerComponent,
    VerseOfTheDayContainerComponent,
  ],
  exports: [
    BlockItemComponent,
    HeroComponent,
    FooterComponent,
    AlertComponent,
    ToastComponent,
    BlockItemGridComponent,
    LoginComponent,
    LogoComponent,
    MenuFlyOutComponent,
    MenuMobileComponent,
    DashboardHeroComponent,
    TeaserHeroComponent,
    EpisodesListComponent,
    TeaserListComponent,
    ButtonHighlightComponent,
    MenuSearchbarComponent,
    LanguagePickerComponent,
    SubFooterComponent,
    AboutComponent,
    ChapterPickerComponent,
    BiblePickerComponent,
    TranslationPickerComponent,
    LinkComponent,
    ModalComponent,
    PaginationComponent,
    SelectComponent,
    SearchResultItemsComponent,
    ButtonToggleGroupComponent,
    ContentPageComponent,
    ReadingPlansOverviewComponent,
    ReadingPlanPageComponent,
    EpisodePageComponent,
    ParagraphComponent,
    CallToActionComponent,
    InlineNoteComponent,
    DonationComponent,
    TestimonialComponent,
    TableComponent,
    ColumnsComponent,
    SeparatorComponent,
    QuoteComponent,
    ListComponent,
    HeadingComponent,
    EmbedComponent,
    ImageComponent,
    AudioEmbedComponent,
    BreadcrumbsComponent,
    ScrollProgressBarComponent,
    RenderGutenbergBlocksComponent,
    SidebarBlockComponent,
    PagetreeNavComponent,
    InlineNotePassageComponent,
    SearchResultsContentComponent,
    SearchSuggestionsComponent,
    TagsPickerComponent,
    SignupComponent,
    AccountVerificationComponent,
    ForgotPasswordComponent,
    MenuAuthMobileComponent,
    AccountSettingsComponent,
    AccountTriggerBoxContentComponent,
    AccountTriggerBarContentComponent,
    ValidationMessageComponent,
    EnvelopeContentComponent,
    EbcPageComponent,
    DeletedAccountComponent,
    BecomePremiumComponent,
    ContentRowComponent,
    BiblepassageComponent,
    TableOfContentsComponent,
    BooksPickerComponent,
    BibleContentPickerComponent,
    VerseOfTheDayContainerComponent,
  ],
})
export class FeWebComponentsModule {}
