import { Pipe, PipeTransform } from '@angular/core';

/**
 * This pipe sanitezes HTML
 */
@Pipe({
  name: 'tildeToSpace',
})
export class TildeToSpacePipe implements PipeTransform {
  transform(content: string): string {
    return content?.replace(/~/g, '&nbsp;');
  }
}
