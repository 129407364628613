import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  makeStateKey,
  StateKey,
  TransferState,
} from '@angular/platform-browser';
import { EnvironmentService } from '@ibep/fe/shared/core';
import { Observable, of } from 'rxjs';
import { version } from '../../../../../../../../version';

/**
 * @Created nx generate @schematics/angular:interceptor --name=BrowserState --project=fe-web-core --no-flat --path=libs/fe/web/core/src/lib/interceptors --no-interactive
 *
 * @export
 * @class BrowserStateInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable({
  providedIn: 'root',
})
export class BrowserStateInterceptor implements HttpInterceptor {
  constructor(
    private transferState: TransferState,
    private _environmentService: EnvironmentService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    // Exit if request METHOD is not GET
    if (request.method === '!GET') {
      return next.handle(request);
    }

    const key: StateKey<string> = makeStateKey<string>(
      `${version}-${this._environmentService.domain}-${request.urlWithParams}`
    );

    const storedResponse = this.transferState.get(key, null);

    if (storedResponse) {
      // if there is a transfer state, we return it
      const response = new HttpResponse({
        body: storedResponse,
        status: 200,
      });
      return of(response);
    }
    // no TransferState has been found in the Browser
    // remove redisTTL header if present
    const headerRemoved = request.clone({
      headers: request.headers.delete('redisTTL'),
    });
    // create new HttpRequest and replace its headers
    const newRequest: HttpRequest<any> = request.clone({
      headers: headerRemoved.headers,
    });
    return next.handle(newRequest);
  }
}
