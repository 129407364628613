import { Component, Input } from '@angular/core';

@Component({
  selector: 'ibep-pagetree-nav',
  templateUrl: './pagetree-nav.component.html',
})
export class PagetreeNavComponent {
  @Input() pagetreeNav: any;
  @Input() parentPage: any;
  @Input() currentPageId: any;
  @Input() ebcTree: boolean;
}
