import { Injectable } from '@angular/core';
import { BOOKS } from '@ibep/shared/util';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';

@Injectable({
  providedIn: 'root',
})
export class EbcLinksFormatterService {
  constructor(
    private readonly _router: Router,
    private readonly _translate: TranslateService,
    private readonly _localize: LocalizeRouterService
  ) {}

  /**
   * Formats EBC links in the provided HTML content.
   *
   * @param htmlContent - The HTML content to format.
   * @param ebcRoute - The EBC route.
   * @param defaultBibleAbbr - The default Bible abbreviation.
   * @returns The formatted HTML content.
   */
  formatEbcLinks(
    htmlContent: string,
    ebcRoute: string,
    defaultBibleAbbr: string
  ): any {
    htmlContent = this._preFormatEbcLinks(
      htmlContent,
      ebcRoute,
      defaultBibleAbbr
    );
    const HTMLElements: any = this.createDOMTree(htmlContent);
    return this._formatHTMLElements(HTMLElements, ebcRoute);
  }

  /**
   * Formats the HTML elements by modifying the anchor links based on certain conditions.
   *
   * @param HTMLElements - The HTML elements to be formatted.
   * @param ebcRoute - The EBC route used for formatting the links.
   * @returns The formatted HTML elements.
   */
  private _formatHTMLElements(HTMLElements: any, ebcRoute: string) {
    const bibleRoute = this._translate.instant('ROUTES.bible');
    if (
      typeof HTMLElements[0] === 'object' &&
      HTMLElements[0] !== null &&
      'getElementsByTagName' in HTMLElements[0]
    ) {
      // get all links in  content
      const anchors = HTMLElements[0].getElementsByTagName('a');
      // German Lexicon migration
      for (let anchor of anchors) {
        if (anchor.href?.includes('table') && anchor.children?.length) {
          // format links for tables
          const tableImgElement = Array.from(anchor?.children || []).find(
            (child: any) => child.dataset.type === 'Asset::Table'
          ) as HTMLImageElement;

          if (tableImgElement) {
            anchor.href = tableImgElement.dataset.imgUrl;
          }
        } else {
          // format links to other lexicon articles
          if (anchor.host.length === 36 && anchor.host.charAt(8) === '-') {
            anchor.dataset.href = `${ebcRoute}/redirect/legacyId/${anchor.host}`;
            anchor.id = 'routerlink';
            anchor.dataset.legacy_id = anchor.host;
          }
          // format Lexicon links to bible reader
          // FIXME: below needs refactoring.
          if (
            anchor.host?.includes('+') ||
            anchor.href?.startsWith('reference://')
          ) {
            anchor.id = 'routerlink';
            const referenceText = anchor.href.startsWith('reference://')
              ? anchor.href.replace('reference://', '')
              : anchor.innerText;

            let split1;
            let split2;
            let splittedChapter;
            if (referenceText?.includes('-') && referenceText?.includes(',')) {
              split1 = referenceText.split('-');
              split2 = referenceText.split(',');
              splittedChapter = split2[0].split(' ');
            } else {
              split1 = anchor.host?.includes('+')
                ? anchor.host?.split('+')
                : referenceText?.includes('+')
                ? referenceText?.split('+')
                : referenceText?.split('%20');

              split2 = split1[1]?.includes('%2C')
                ? split1[1]?.split('%2C')
                : split1[1]?.split(',');

              splittedChapter = [split1[0], split2.join('.')];
              const bookAbbrPath = BOOKS[split1[0]];
              const chapterNumberPath = split2[0] ? `.${split2[0]}` : '';
              let bookRoute = '';

              if (split2[1]?.includes('-')) {
                const verseRange = split2[1].split('-');
                bookRoute = `${bookAbbrPath}${chapterNumberPath}.${verseRange[0]}-${bookAbbrPath}${chapterNumberPath}.${verseRange[1]}`;
              } else {
                bookRoute = `${bookAbbrPath}${chapterNumberPath}${
                  split2[1] ? `.${split2[1]}` : ''
                }`;
              }

              anchor.dataset.href = `/${bibleRoute}/0/${bookRoute}`;
            }
            const bookAbbr = splittedChapter[0];
            const chapterNumber = splittedChapter[1];

            if (!anchor.dataset.href) {
              if (split1.length > 1) {
                // Link to verse range. Example: Ri 8,4-9 or Jos 13,8-11.24-32
                const split3 = split2[1]?.split('-');
                const verseNumberFrom =
                  split3 && split3.length && split3[0]?.replace(/\D/g, '');
                const verseNumberTo =
                  split3 &&
                  split3.length &&
                  split3[1]?.split('.')[0]?.replace(/\D/g, '');
                anchor.dataset.href = `/${bibleRoute}/0/${BOOKS[bookAbbr]}.${chapterNumber}.${verseNumberFrom}-${BOOKS[bookAbbr]}.${chapterNumber}.${verseNumberTo}`;
              } else {
                // Link to verse. Example: Hos 8,14
                const verseNumber = split2[1]?.replace(/\D/g, '');
                anchor.dataset.href = `/${bibleRoute}/0/${
                  BOOKS[bookAbbr]
                }.${chapterNumber}${verseNumber ? `.${verseNumber}` : ''}`;
              }
            }
          }

          if (anchor.className === 'sachwort') {
            anchor.dataset.href = anchor.href
              .replace('http://::', `${ebcRoute}/redirect/legacyId/`)
              .replace('https://::', `${ebcRoute}/redirect/legacyId/`)
              .replace('//:', `${ebcRoute}/redirect/legacyId/`)
              .replace(/:/g, '');
            anchor.id = 'routerlink';
            const legacyId = anchor.href.split('/').pop();
            anchor.dataset.legacy_id = legacyId;
          }

          if (anchor.href.startsWith('article://')) {
            const legacyId = anchor.href
              .replace('article://', '')
              .replace(/\/.{0,}/, '');
            anchor.dataset.href = `${ebcRoute}/redirect/legacyId/${legacyId}`;
            anchor.id = 'routerlink';
            anchor.dataset.legacy_id = legacyId;
          }

          // Check if the current router URL starts with the translated route for '/ROUTES.bible'
          // and if the anchor href includes the translated route for '/ROUTES.ebc'
          if (
            this._router.url.startsWith(
              `${this._localize.translateRoute('/ROUTES.bible')}`
            ) &&
            anchor.href.includes(this._localize.translateRoute('/ROUTES.ebc'))
          ) {
            // If both conditions are met, set the anchor's data-href attribute to its href value
            // and set its id to 'routerlink'
            anchor.dataset.href = `${anchor.href}`;
            anchor.id = 'routerlink';
            const slug = anchor.href.split('/').pop();
            anchor.dataset.slug = slug;
          }
        }
      }
    }

    return HTMLElements;
  }

  /**
   * Pre-format EBC links in the provided HTML string.
   * Replaces placeholders with actual links based on the provided parameters.
   *
   * @param html - The HTML string to pre-format.
   * @param ebcRoute - The EBC route used for generating links.
   * @param defaultBibleAbbr - The default Bible abbreviation used for generating links.
   * @returns The pre-formatted HTML string with replaced links.
   */
  private _preFormatEbcLinks(
    html: string,
    ebcRoute: string,
    defaultBibleAbbr: string
  ) {
    html = html?.replace(/{%[^%]{1,}%}/g, (match) => {
      const chapterId = match.replace(/{%/g, '').replace(/%}/g, '');
      return `<a data-href="${this._localize.translateRoute(
        '/ROUTES.bible'
      )}/${defaultBibleAbbr}/${chapterId}" id="routerlink">${chapterId}</a>`;
    });

    // <a class=\"sachwort\" href=\"//::80177459-3c30-4510-915f-a998cf2780d9::\">

    // create link to OSP media
    html = html?.replace(
      /media_([0-9]{4})_(\S{1,})/g,
      (match, p1, p2) =>
        `<a data-href="/${this._translate.instant(
          'ROUTES.ebc'
        )}/redirect/legacyId/${p1}" id="routerlink" data-legacy_id="${p1}">${p2}</a>`
    );

    // OSP migration: Format links to other OSP articles. Example source: {{topic_9186_Himmel}}
    const splitted = html?.split(/{{|}}/).map((part) => {
      if (part.startsWith('topic_')) {
        const sPart = part.split('_');
        const legacyId = sPart[1];
        return `<a data-href="${ebcRoute}/redirect/legacyId/${legacyId}" id="routerlink" data-legacy_id="${legacyId}">${sPart[2]}</a>`;
      }
      return part;
    });
    return splitted?.join('');
  }

  /**
   * Create DOM tree from wordpress HTML string, so we can work/change the paragraph content in an 'angular' way
   *
   * @param {string} html
   * @memberof EbcLinksFormatterService
   */
  createDOMTree(html: string) {
    // create a DOM with DOMparser
    const parser = new DOMParser();
    const parsedHtml = parser.parseFromString(html, 'text/html');
    // we only need the paragraph element
    this._patchInlineNotesSpan(parsedHtml);
    return parsedHtml.body.childNodes;
  }

  private _patchInlineNotesSpan(parsedHtml: Document): void {
    const inlineNoteSpans = parsedHtml.querySelectorAll('[data-ref]');

    inlineNoteSpans.forEach((span: any, i) => {
      span.dataset.index = i;
    });
  }
}
