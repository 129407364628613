import { Pipe, PipeTransform } from '@angular/core';

/**
 * This pipe highlights matched text bold.
 * NB! Text should not contain inner HTML tags
 */
@Pipe({
  name: 'highlightSearch',
})
export class HighlightSearchPipe implements PipeTransform {
  transform(text: string, query: string): string {
    if (text && query) {
      const queriesExp = query
        .replace(/"|\+|\||\-/g, ' ') // to clean string for highlighting from the special query operators (", +, |, -)
        .replace(/\*/g, '\\S{1,}') // to support asterisk * in query as a pattern for a part of word
        .split(' ') // to support correct highlight for queries with more than one word
        .filter((item) => item); // filter empty spaces

      const patterns = queriesExp.map((queryExp) => new RegExp(queryExp, 'gi'));

      let highlightedText = text.replace(/{%/g, '').replace(/%}/g, '');

      // this regex matches with text between html tags not to break html attributes in cases
      // when their values matches with query
      highlightedText = highlightedText.replace(
        /(?<=\>|^)[^<]{1,}/g,
        (matchFirstLevel) => {
          let match = matchFirstLevel;
          // then we match the query to wrap matches in the bold tag <b>
          patterns.forEach((pattern) => {
            match = match.replace(
              pattern,
              (matchSecondLevel) => `<b>${matchSecondLevel}</b>`
            );
          });
          return match;
        }
      );
      return highlightedText;
    } else {
      return text;
    }
  }
}
