import { Component, OnInit } from '@angular/core';
import { LanguageService } from '@ibep/fe/shared/core';
import { ConfigLocalizedData } from '@ibep/fe/shared/data';
import {
  AnalyticsService,
  ComponentVisibilityService,
} from '@ibep/fe/web/core';
import { version } from '../../../../../../../version';

@Component({
  selector: 'ibep-footer-container',
  templateUrl: './footer-container.component.html',
})
export class FooterContainerComponent implements OnInit {
  public config$ = this._configData.getConfig();
  public isFooterVisible = true;
  public appVersion = version;

  public currentLanguage: string;
  public isDefaultLanguage: boolean;

  constructor(
    private readonly _configData: ConfigLocalizedData,
    private readonly _languageService: LanguageService,
    private readonly _analyticsService: AnalyticsService,
    private readonly _componentVisibilityService: ComponentVisibilityService
  ) {}

  ngOnInit(): void {
    this._componentVisibilityService.isFooterVisible$.subscribe((isVisible) => {
      this.isFooterVisible = isVisible;
    });

    this._languageService.currentLanguage$.subscribe((language) => {
      this.currentLanguage = language;
      this.isDefaultLanguage = this._languageService.isDefaultLanguage;
    });
  }

  public pushStoreTag(link: string): void {
    this._analyticsService.pushGtmTag({
      event: 'app_store_click',
      download_source: link,
    });
  }

  public pushSocialTag(link: string): void {
    this._analyticsService.pushGtmTag({
      event: 'social_click',
      click_url: link,
    });
  }
}
