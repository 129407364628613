import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { SearchCategory, SearchSuggestion } from '@ibep/interfaces';

@Component({
  selector: 'ibep-search-suggestions',
  templateUrl: './search-suggestions.component.html',
  styleUrls: ['./search-suggestions.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SearchSuggestionsComponent implements AfterViewInit {
  public searchCategoryEnum = SearchCategory;

  @Input() bibleId: string;

  @Input() bibleAbbr: string;

  @Input() searchControl: UntypedFormControl;

  @Input() items: SearchSuggestion[] = [];

  @Input() isSuggestionLoading: boolean;

  @Output() openModal = new EventEmitter<string>();

  @Output() closeModal = new EventEmitter<void>();

  @Output() clickSuggestion = new EventEmitter<{
    itemName: string;
    itemPath: string;
  }>();

  @ViewChild('searchInput', { static: false })
  searchInputElement: ElementRef<HTMLInputElement>;

  constructor(
    private readonly _router: Router,
    private readonly _localize: LocalizeRouterService
  ) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.searchInputElement.nativeElement.focus();
    }, 0);
  }

  public onCloseModal(): void {
    this.closeModal.emit();
  }

  public onOpenModal(type: string): void {
    this.openModal.emit(type);
  }

  public clearSearch(): void {
    this.searchControl.setValue('');
  }

  public closeAndClear(): void {
    this.clearSearch();
    this.onCloseModal();
  }

  public goToSearchResults(searchCategory: SearchCategory): void {
    this.closeModal.emit();
    const translatedPath = this._localize.translateRoute('/ROUTES.search');

    setTimeout(() => {
      this._router.navigate([`${translatedPath}/${searchCategory}`], {
        queryParams: {
          query: this.searchControl.value?.trim(),
          bibleId:
            searchCategory === SearchCategory.ALL ||
            searchCategory === SearchCategory.BIBLE
              ? this.bibleId
              : null,
        },
        queryParamsHandling: 'merge',
      });
      // redirect to the search results page does not work without timeout
      // and even with timeout 0 or 10 ms it also does not work
    }, 50);
  }

  public goToBibleReader(
    suggestion: SearchSuggestion,
    suggestionText: HTMLSpanElement
  ): void {
    this.closeModal.emit();
    const fragmentId = suggestion.source.includes('.')
      ? suggestion.source
      : `${suggestion.source}.1`;
    const path = `${this._localize.translateRoute('/ROUTES.bible')}/${
      this.bibleAbbr
    }/${fragmentId}`;
    this._router.navigate([path]);

    this.clickSuggestion.emit({
      itemName: suggestionText.innerText,
      itemPath: path,
    });
  }

  public openTranslationPicker(event: Event): void {
    event.stopPropagation();
    this.onOpenModal('translation');
  }
}
