import { Injectable } from '@angular/core';
import {
  AbstractBackendService,
  AbstractEnvironmentService,
  AbstractLanguageService,
  AbstractStorageService,
} from '@ibep/interfaces';
import { HOUR } from '@ibep/shared/util';
import { Observable, of } from 'rxjs';
import { switchMap, take, tap, shareReplay } from 'rxjs/operators';
import { ConfigData } from '..';
import { Store, StoreSettings, StoreSettingsTTL } from './store';

@Injectable({
  providedIn: 'root',
})
export class PostsData extends Store<any> {
  private language: string;
  constructor(
    storage: AbstractStorageService,
    environment: AbstractEnvironmentService,
    backendService: AbstractBackendService,
    languageService: AbstractLanguageService,
    config: ConfigData
  ) {
    super(storage, environment, backendService, languageService, {
      storeTtl: { default: 1 * HOUR } as StoreSettingsTTL,
      storeKey: 'posts',
      brand$: config.getBrand(),
    } as StoreSettings);
  }

  /**
   * Get the post data
   *
   * @returns {Observable<Post>}
   * @memberof PostData
   */
  public getPosts({
    page,
    limit,
    tagSlug,
    categorySlug,
  }: {
    page: number;
    limit: number;
    tagSlug?: string;
    categorySlug?: string;
  }): Observable<any> {
    let endpoint = `content/posts?language=${this._currentLanguage}&platform=web&page=${page}&limit=${limit}`;
    if (tagSlug) {
      endpoint += `&tags=${tagSlug}`;
    }
    if (categorySlug) {
      endpoint += `&category=${categorySlug}`;
    }

    this.remoteDataItem$ = this.backendService
      .get<any>({
        endpoint,
        options: {
          headers: {
            'x-brand': this.brand.id,
            redisTTL: `${this.storeSettings.storeTtl.default}`,
          },
        },
      })
      .pipe(shareReplay(1));

    return this.localData$.pipe(
      take(1),
      switchMap((localData) => {
        // check if there is local data available
        if (localData[endpoint]) {
          return of(localData[endpoint]);
        }
        // if no local data, fetch data from api
        return this.remoteDataItem$.pipe(
          tap((remoteData: any) => {
            // write returned data from api to the store
            this.setState(
              {
                [endpoint]: remoteData,
              },
              true
            );
          })
        );
      })
    );
  }
}
