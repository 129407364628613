import { Injectable } from '@angular/core';
import { NotificationService } from '@ibep/fe/shared/core';
import { Observable } from 'rxjs';
import { NotificationInterface } from '../interfaces/notification.interface';

@Injectable({
  providedIn: 'root',
  useClass: NotificationService,
})
export abstract class AbstractNotificationService {
  public abstract notification$: Observable<NotificationInterface>;
  public abstract notify(notification: NotificationInterface): void;
}
