import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { BaseComponent } from '@ibep/fe/shared/core';
import { AbstractEnvironmentService, ScreenSize } from '@ibep/interfaces';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'ibep-block-item-grid',
  templateUrl: './block-item-grid.component.html',
  styleUrls: ['./block-item-grid.component.scss'],
})
export class BlockItemGridComponent extends BaseComponent {
  @ViewChild('teaserViewport', { static: false })
  private _viewport: ElementRef<HTMLDivElement>;

  @Input() props: {
    items: any;
    sectionTitle: string;
    isBrowser: boolean;
    background?: boolean;
    autoWidth: boolean;
    horizontalScroll: boolean;
  };

  public activeItem = 0;
  public isMobile: boolean;

  constructor(
    private readonly _environmentService: AbstractEnvironmentService
  ) {
    super();
    this._environmentService.windowWidth$
      .pipe(takeUntil(this._destroy))
      .subscribe((width) => {
        this.isMobile = width <= ScreenSize.lg;
      });
  }

  public setActive(index: number) {
    this.activeItem = index;
    const scrollPosition = 290 * index + 1;
    this._viewport.nativeElement.scrollLeft = Math.max(0, scrollPosition);
  }

  public isVisible(index: number) {
    this.activeItem = index;
  }
}
