import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'ibep-donation',
  templateUrl: './donation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DonationComponent {
  @Input() props: {
    donationItems: any;
    headline: string;
    text: string;
    currency: string;
  };
}
