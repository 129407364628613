import { Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';

// we use this directive to solve an issue with Firefox 113 where svg icons with wrong styles appear in the server side rendered layout
@Directive({ selector: '[ibepHideTillInit]' })
export class HideTillInitDirective implements OnInit {
  constructor(private element: ElementRef, private renderer: Renderer2) {
    this.renderer.setStyle(this.element.nativeElement, 'visibility', 'hidden');
  }

  ngOnInit(): void {
    this.renderer.removeStyle(this.element.nativeElement, 'visibility');
  }
}
