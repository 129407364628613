<ng-container *ngIf="vod">
  <h3 class="text-lg font-bold mb-2" [innerHTML]="headline"></h3>
  <ibep-biblepassage
    [props]="{
      passageId: vod.id,
      bibleAbbr: vod.bible_translation,
      dontAddMarginBottom: true
    }"
  >
  </ibep-biblepassage>
  <div class="flex gap-4 bg-primary bg-opacity-10 px-4 pb-5">
    <a
      [routerLink]="
        '/ROUTES.readingplans' + '/' + vod.rpSlug + '/' + vod.episodeSlug
          | localize
      "
    >
      <span class="text-secondary hover:text-primary">{{
        'OpenEpisode' | translate
      }}</span></a
    >
  </div>
</ng-container>
