<!-- Menu button  -->
<div class="justify-end lg:hidden">
  <button
    type="button"
    class="inline-flex items-center justify-center"
    (click)="toggleMenu()"
  >
    <span class="sr-only">{{ 'OpenMenu' | translate }}</span>
    <span class="uppercase text-[12px] text-grayLight pr-1">{{
      'menu' | translate
    }}</span>
    <div
      class="bg-primary w-6 h-6 rounded-full flex justify-center items-center"
    >
      <ibep-hamburger-svg
        class="text-white pb-1"
        svgStyles="w-4 h-4"
      ></ibep-hamburger-svg>
    </div>
  </button>
</div>
<!-- Mobile Menu -->
<div
  class="fixed inset-x-0 inset-y-0 w-100vw h-100vh z-50 overflow-y-hidden lg:hidden bg-white"
  *ngIf="showMobileMenu"
>
  <div>
    <!-- close menu button  -->
    <div class="flex justify-end items-center border-b px-4 h-12">
      <ibep-button
        (click)="toggleMenu()"
        [buttonProps]="{
          pushGtm: true,
          content: 'CloseMenu' | translate,
          size: 's'
        }"
        class="text-primary text-xs"
      >
        <span class="pr-[4px]">{{ 'CloseMenu' | translate }}</span>
        <ibep-close-svg svgStyles="w-5 h-5 pt-[2px]"></ibep-close-svg>
      </ibep-button>
    </div>

    <!-- Menu items  -->
    <div class="px-4">
      <nav class="grid overflow-x-scroll">
        <!-- First layer  -->
        <ng-container *ngFor="let menuItem of menuItems; let i1 = index">
          <ng-container *ngIf="activeMenuLevel === 1">
            <ibep-link
              [linkProps]="{
                link: !menuItem.items.length ? menuItem.link : null,
                linkStyles: menuItem.items.length
                  ? 'disabled text-header py-2 text-sm border-b border-backgroundGray h-[52px] flex items-center justify-between hover:text-primary'
                  : 'text-header py-2 text-sm border-b border-backgroundGray h-[52px] flex items-center justify-between hover:text-primary',
                isExternalLink: menuItem.linkType === 'external'
              }"
              routerLinkActive="active"
              (linkClicked)="
                menuItem.items.length
                  ? setActiveMenuItem([i1], 2)
                  : toggleMenu()
              "
            >
              <button
                type="button"
                class="inline-flex items-center text-xs text-grayMedium text-left"
              >
                <span [innerHTML]="menuItem.title"></span>
              </button>
              <span>
                <ibep-chevron-right-svg
                  *ngIf="!menuItem.items.length"
                  class="text-grayLight"
                  svgStyles="w-3 h-3"
                ></ibep-chevron-right-svg>
              </span>

              <ibep-arrow-right-svg
                *ngIf="menuItem.items.length"
                svgStyles="w-4 h-4"
              >
              </ibep-arrow-right-svg>
            </ibep-link>
          </ng-container>
          <!-- Second Layer  -->
          <ng-container
            *ngIf="activeMenuLevel === 2 && compare(activeMenuItem, [i1])"
          >
            <div
              class="font-bold text-xs py-3 cursor-pointer"
              (click)="setActiveMenuItem([], 1)"
            >
              <ibep-arrow-left-svg
                class=""
                svgStyles="w-4 h-4"
              ></ibep-arrow-left-svg>
              <span [innerHTML]="menuItem.title"></span>
            </div>
          </ng-container>
          <ng-container
            *ngFor="let childItem of menuItem.items; let i2 = index"
          >
            <ng-container
              *ngIf="activeMenuLevel === 2 && compare(activeMenuItem, [i1])"
            >
              <h4
                *ngIf="childItem.linkType === 'sectionTitle'"
                class="group text-header py-2 text-sm border-b border-backgroundGray w-full hover:text-primary cursor-pointer h-[52px]"
                (click)="setActiveMenuItem([i1, i2], 3)"
              >
                <span class="flex h-full items-center justify-between">
                  <span [innerHTML]="childItem.title"></span>
                  <ibep-arrow-right-svg
                    *ngIf="childItem.items.length"
                    svgStyles="w-4 h-4"
                  >
                  </ibep-arrow-right-svg>
                </span>
              </h4>
              <ibep-link
                *ngIf="childItem.linkType !== 'sectionTitle'"
                [linkProps]="{
                  link: !childItem.items.length ? childItem.link : null,
                  linkStyles: childItem.items.length
                    ? 'disabled text-header py-2 text-sm border-b border-backgroundGray h-[52px] flex items-center justify-between hover:text-primary'
                    : 'text-header py-2 text-sm border-b border-backgroundGray h-[52px] flex items-center justify-between hover:text-primary',
                  isExternalLink: childItem.linkType === 'external'
                }"
                (linkClicked)="
                  childItem.items.length
                    ? setActiveMenuItem([i1, i2], 3)
                    : toggleMenu()
                "
              >
                <button
                  type="button"
                  class="inline-flex items-center text-xs text-grayMedium text-left"
                >
                  <span [innerHTML]="childItem.title">{{
                    childItem.title
                  }}</span>
                </button>
                <ibep-chevron-right-svg
                  *ngIf="!childItem.items.length"
                  class="text-grayMedium"
                  svgStyles="w-4 h-4"
                ></ibep-chevron-right-svg>
                <ibep-arrow-right-svg
                  *ngIf="childItem.items.length"
                  svgStyles="w-4 h-4"
                >
                </ibep-arrow-right-svg>
              </ibep-link>
            </ng-container>
            <!-- Third layer  -->
            <ng-container
              *ngIf="activeMenuLevel === 3 && compare(activeMenuItem, [i1, i2])"
            >
              <div class="font-bold text-xs py-3 cursor-pointer">
                <span (click)="setActiveMenuItem([], 1)">
                  <ibep-arrow-left-svg
                    class=""
                    svgStyles="w-4 h-4"
                  ></ibep-arrow-left-svg>
                  <span [innerHTML]="menuItem.title"></span>
                </span>
                <span (click)="setActiveMenuItem([i1], 2)">
                  <ibep-arrow-left-svg
                    class="ml-2"
                    svgStyles="w-4 h-4"
                  ></ibep-arrow-left-svg>
                  <span [innerHTML]="childItem.title"></span>
                </span>
              </div>
            </ng-container>
            <ng-container
              *ngFor="let subChildItem of childItem.items; let i3 = index"
            >
              <ng-container
                *ngIf="
                  activeMenuLevel === 3 && compare(activeMenuItem, [i1, i2])
                "
              >
                <h4
                  *ngIf="subChildItem.linkType === 'sectionTitle'"
                  class="group flex items-center py-2 font-bold"
                >
                  <span [innerHTML]="subChildItem.title"></span>
                </h4>
                <ibep-link
                  *ngIf="subChildItem.linkType !== 'sectionTitle'"
                  [linkProps]="{
                    link: subChildItem.link,
                    linkStyles: subChildItem.items.length
                      ? 'disabled text-header py-2 text-sm border-b border-backgroundGray h-[52px] flex items-center justify-between hover:text-primary'
                      : 'text-header py-2 text-sm border-b border-backgroundGray h-[52px] flex items-center justify-between hover:text-primary',
                    isExternalLink: subChildItem.linkType === 'external'
                  }"
                  (linkClicked)="
                    subChildItem.items.length
                      ? setActiveMenuItem([i1, i2, i3], 4)
                      : toggleMenu()
                  "
                >
                  <button
                    type="button"
                    class="inline-flex items-center text-xs text-grayMedium text-left"
                  >
                    <span [innerHTML]="subChildItem.title"></span>
                  </button>
                  <ibep-chevron-right-svg
                    *ngIf="!subChildItem.items.length"
                    class="text-grayMedium"
                    svgStyles="w-4 h-4"
                  ></ibep-chevron-right-svg>
                  <ibep-arrow-right-svg
                    *ngIf="subChildItem.items.length"
                    svgStyles="w-4 h-4"
                  >
                  </ibep-arrow-right-svg>
                </ibep-link>
              </ng-container>
              <!-- Fourth layer  -->
              <ng-container
                *ngIf="
                  activeMenuLevel === 4 && compare(activeMenuItem, [i1, i2, i3])
                "
              >
                <div class="font-bold text-xs leading-6 py-3 cursor-pointer">
                  <span (click)="setActiveMenuItem([], 1)">
                    <ibep-arrow-left-svg
                      class=""
                      svgStyles="w-4 h-4"
                    ></ibep-arrow-left-svg>
                    <span [innerHTML]="menuItem.title"></span>
                  </span>
                  <span (click)="setActiveMenuItem([i1], 2)">
                    <ibep-arrow-left-svg
                      class="ml-2"
                      svgStyles="w-4 h-4"
                    ></ibep-arrow-left-svg>
                    <span [innerHTML]="childItem.title"></span>
                  </span>
                  <span (click)="setActiveMenuItem([i1, i2], 3)">
                    <ibep-arrow-left-svg
                      class="ml-2"
                      svgStyles="w-4 h-4"
                    ></ibep-arrow-left-svg>
                    <span [innerHTML]="subChildItem.title"></span>
                  </span>
                </div>
              </ng-container>
              <ng-container
                *ngFor="
                  let subsubChildItem of subChildItem.items;
                  let i4 = index
                "
              >
                <ng-container
                  *ngIf="
                    activeMenuLevel === 4 &&
                    compare(activeMenuItem, [i1, i2, i3])
                  "
                >
                  <ibep-link
                    [linkProps]="{
                      link: subsubChildItem.link,
                      linkStyles: subsubChildItem.items.length
                        ? 'disabled text-header py-2 text-sm border-b border-backgroundGray h-[52px] flex items-center justify-between hover:text-primary'
                        : 'text-header py-2 text-sm border-b border-backgroundGray h-[52px] flex items-center justify-between hover:text-primary',
                      isExternalLink: subsubChildItem.linkType === 'external'
                    }"
                    (linkClicked)="toggleMenu()"
                  >
                    <button
                      type="button"
                      class="inline-flex items-center text-xs text-grayMedium text-left"
                    >
                      <span>{{ subsubChildItem.title }}</span>
                    </button>
                    <ibep-chevron-right-svg
                      *ngIf="!subsubChildItem.items.length"
                      class="text-grayMedium"
                      svgStyles="w-4 h-4"
                    ></ibep-chevron-right-svg>
                    <ibep-arrow-right-svg
                      *ngIf="subsubChildItem.items.length"
                      svgStyles="w-4 h-4"
                    >
                    </ibep-arrow-right-svg>
                  </ibep-link>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </nav>
    </div>

    <!-- Language picker  -->
    <div class="mt-4 px-4" *ngIf="availableLanguages.length > 1">
      <ibep-language-picker
        [languages]="availableLanguages"
      ></ibep-language-picker>
    </div>
  </div>
</div>
