<div class="bg-secondary bg-opacity-10 py-8 my-10 px-4 lg:px-0">
  <div class="max-w-[610px] mx-auto">
    <h3 class="text-2xl font-bold mb-3" [innerHTML]="props.headline"></h3>
    <p class="mb-3" [innerHTML]="props.text | safeHtml"></p>
    <div class="grid grid-cols-2 lg:grid-cols-4 gap-4">
      <div
        *ngFor="let item of props.donationItems"
        class="bg-white rounded-lg flex flex-col justify-between text-center p-4 border border-[#F3D9AA] basis-full lg:max-w-[138px] hover:shadow-md"
      >
        <div>
          <h3 class="text-cta text-[30px] font-bold mb-2">
            {{ props.currency }}{{ item.amount }}
          </h3>
          <p class="text-2xs text-grayLight mb-3">
            <ibep-paragraph [html]="item.text"></ibep-paragraph>
          </p>
        </div>

        <a
          [href]="
            item.linkType === 'internal' ? item.linkInternal : item.linkExternal
          "
          target="{{ item.linkType === 'internal' ? '_self' : '_blank' }}"
          class="w-full"
        >
          <ibep-button
            [buttonProps]="{
              pushGtm: true,
              content: item.buttonText,
              link:
                item.linkType === 'internal'
                  ? item.linkInternal
                  : item.linkExternal,
              style: 'cta',
              size: 'm',
              fullWidth: true
            }"
            class="w-full"
          >
            <b class="text-xs line-clamp-1" [innerHTML]="item.buttonText"></b>
          </ibep-button>
        </a>
      </div>
    </div>
  </div>
</div>
