import { Injectable, Injector } from '@angular/core';
import { ConfigLocalizedData } from '@ibep/fe/shared/data';
import { AbstractAnalyticsService, Config } from '@ibep/interfaces';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService implements AbstractAnalyticsService {
  private _config: Config = <Config>{};
  private _gtmId: string;
  private _gtmService: GoogleTagManagerService;

  constructor(
    private readonly _injector: Injector,
    private readonly _configData: ConfigLocalizedData
  ) {
    this._configData.getConfig().subscribe((config: Config) => {
      this._config = config;
      this._gtmId = this._configData.gtmId;
      if (this._gtmId) {
        this._gtmService = <GoogleTagManagerService>(
          this._injector.get(GoogleTagManagerService)
        );
      }
    });
  }

  /**
   * Initialize the analytics service.
   *
   * @memberof AnalyticsService
   */
  public init(): void {
    if (this.isGtmAllowed()) {
      // enable auto events
      this._gtmService.addGtmToDom();
    }
  }

  /**
   * Push GTM tag.
   *
   * @memberof AnalyticsService
   */
  public pushGtmTag(tag: object): void {
    if (this.isGtmAllowed()) {
      this._gtmService.pushTag(tag);
    }
  }

  /**
   * Check conditions to enable the GTM
   *
   * @private
   * @memberof AnalyticsService
   */
  private isGtmAllowed(): boolean {
    return this._config.platform === 'browser' && Boolean(this._gtmId);
  }
}
