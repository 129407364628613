import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TriggerBoxSettings } from '@ibep/interfaces';

@Component({
  selector: 'ibep-account-trigger-box-content',
  templateUrl: './account-trigger-box-content.component.html',
})
export class AccountTriggerBoxContentComponent {
  @Input() triggerBoxSettings?: TriggerBoxSettings;

  @Output() handleOpenModal: EventEmitter<string> = new EventEmitter();

  public openModal(type: string): void {
    this.handleOpenModal.emit(type);
  }
}
