import { Inject, Injectable } from '@angular/core';
import { Auth } from '@ibep/fe/web/module-auth';
import {
  AbstractBackendService,
  AbstractEnvironmentService,
  AbstractLanguageService,
  AbstractStorageService,
  UserDataStorage,
} from '@ibep/interfaces';
import { YEAR } from '@ibep/shared/util';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Store, StoreSettings, StoreSettingsTTL } from './store';
import { WINDOW } from '@ibep/fe/shared/core';

@Injectable({
  providedIn: 'root',
})
export class UserData extends Store<UserDataStorage> {
  constructor(
    readonly storage: AbstractStorageService,
    readonly environment: AbstractEnvironmentService,
    readonly backendService: AbstractBackendService,
    languageService: AbstractLanguageService,
    @Inject(WINDOW) private _window: Window
  ) {
    super(storage, environment, backendService, languageService, {
      storeTtl: { default: 1 * YEAR } as StoreSettingsTTL,
      storeKey: 'userAuth',
    } as StoreSettings);
  }

  public getAuthData(): Observable<Auth> {
    // fallback solution for multilang brands where user has a non default preferred language and is redirect to this language (see auth service login method)
    if (
      this.environment.platform === 'browser' &&
      this._window.localStorage?.userAuth
    ) {
      const userAuth: Auth = JSON.parse(this._window.localStorage.userAuth);
      setTimeout(() => {
        this.setState({ auth: userAuth });
      }, 500);

      return of(userAuth);
    }
    return this.getState().pipe(map((data) => data.auth));
  }

  public setAuthData(data: UserDataStorage['auth']): Observable<void> {
    return this.getState().pipe(
      take(1),
      map((current) => this.setState({ ...current, auth: data }))
    );
  }

  public resetData(): void {
    this.setState({ auth: <UserDataStorage['auth']>{} });
  }
}
