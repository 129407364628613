import { Injectable } from '@angular/core';
import { ThemeService } from '@ibep/fe/web/core';

@Injectable({
  providedIn: 'root',
  useClass: ThemeService,
})
export abstract class AbstractThemeService {
  public abstract init(): void;
}
