<!-- Breadcrumbs  -->
<div
  *ngIf="!isMobileAppView"
  class="border-b border-grayExtraLight h-10 bg-grayExtraLight lg:bg-white"
>
  <div class="max-w-container m-auto px-6 py-3">
    <ibep-breadcrumbs
      [props]="{
        currentRouteTitle: episode?.title || '',
        parentRoutes: parentRoutes
      }"
    ></ibep-breadcrumbs>
  </div>
</div>
<ng-container *ngIf="!plan">
  <div class="col-span-4 text-center h-20">
    <ibep-page-loading></ibep-page-loading>
  </div>
</ng-container>
<ng-container *ngIf="plan">
  <!-- Progressbar  -->
  <div class="mb-4 sticky top-0 z-10">
    <ibep-scroll-progress-bar></ibep-scroll-progress-bar>
  </div>
  <!-- Hero -->
  <ibep-hero
    [heroProps]="{
      title: episode.title,
      subtitle: episode.subline,
      author: plan.author,
      publishDate: plan.publishDate,
      dateFormat,
      showPublishDate: plan.showPublishDate,
      tags: [],
      image: episode.image?.large ?? plan.image?.large,
      minutes: episode.minutes_to_read
    }"
  ></ibep-hero>
  <div
    class="flex lg:gap-32 flex-col lg:flex-row lg:px-0 text-grayMedium max-w-container lg:mx-4 xl:mx-auto mt-10"
  >
    <div class="basis-4/6">
      <div class="pb-10">
        <ibep-paragraph
          class="lg:pl-20 block"
          *ngIf="episode.intro"
          [html]="episode.intro"
        ></ibep-paragraph>
        <h2
          class="text-[#040632] mb-4 mx-4 lg:mx-0 lg:pl-20 font-bold text-[24px] leading-[32px]"
        >
          {{ 'BibleText' | translate }}
        </h2>
        <div *ngFor="let scripture of episode.scripture; last as isLast">
          <!-- <div
            class="text-[#7A8592] mx-4 lg:mx-0 text-[15px] leading-[24px] lg:leading-[28px] mb-3 lg:pl-20"
          >
            {{ scripture | formatChapterTitle: bible:true }}
          </div> -->
          <div class="lg:px-20 text-[17px] mx-4 lg:mx-0">
            <ibep-bible-content-container
              *ngIf="bible"
              [props]="{
                bibleId: bible.id,
                passageId: scripture
              }"
            ></ibep-bible-content-container>
          </div>
          <svg
            ibepHideTillInit
            *ngIf="!isLast"
            class="w-full mt-5 h-5"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
          >
            <rect width="100%" height="4" style="fill: rgb(229, 234, 241)" />
          </svg>
        </div>
        <div
          *ngIf="episode.body"
          class="mt-10 w-full bg-primary bg-opacity-10 py-10"
        >
          <div class="px-4 lg:px-20 text-[17px] leading-[28px]">
            <ibep-paragraph [html]="episode.body"></ibep-paragraph>
          </div>
        </div>
        <div
          class="text-[14px] mt-8 px-4 lg:px-20 flex flex-row justify-between"
        >
          <div>
            <ibep-link
              [ngClass]="{
                'text-grayDark': prevEpisodeLink,
                'text-grayLight': !prevEpisodeLink
              }"
              [linkProps]="{
                link: prevEpisodeLink,
                isExternalLink: false,
                isDisabled: !prevEpisodeLink,
                linkStyles: 'text-xs flex items-center flex-wrap'
              }"
            >
              <ibep-prev-svg></ibep-prev-svg>
              <span class="m2-4 hidden 2sm:inline">{{
                'PreviousEpisode' | translate
              }}</span>
            </ibep-link>
          </div>
          <div>
            <ibep-link
              [ngClass]="{
                'text-grayDark': nextEpisodeLink,
                'text-grayLight': !nextEpisodeLink
              }"
              [linkProps]="{
                link: nextEpisodeLink,
                isDisabled: !nextEpisodeLink,
                isExternalLink: false,
                linkStyles:
                  'text-xs flex justify-end items-center flex-wrap-reverse'
              }"
            >
              <span class="mr-2 hidden 2sm:inline">{{
                'NextEpisode' | translate
              }}</span>
              <ibep-next-svg></ibep-next-svg>
            </ibep-link>
          </div>
        </div>
      </div>
    </div>
    <div class="basis-2/6"></div>
  </div>
</ng-container>
