import { Injectable } from '@angular/core';
import {
  AbstractBackendService,
  AbstractEnvironmentService,
  AbstractLanguageService,
  AbstractStorageService,
} from '@ibep/interfaces';
import { HOUR } from '@ibep/shared/util';
import { Observable, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { ConfigData } from '..';
import { Store, StoreSettings, StoreSettingsTTL } from './store';

@Injectable({
  providedIn: 'root',
})
export class ReadingPlanData extends Store<any> {
  constructor(
    storage: AbstractStorageService,
    environment: AbstractEnvironmentService,
    backendService: AbstractBackendService,
    languageService: AbstractLanguageService,
    config: ConfigData
  ) {
    super(storage, environment, backendService, languageService, {
      storeTtl: { default: 1 * HOUR } as StoreSettingsTTL,
      storeKey: 'plan',
      brand$: config.getBrand(),
    } as StoreSettings);
  }

  /**
   * Get the post data
   *
   * @returns {Observable<ReadingPlan>}
   * @memberof ReadingPlanData
   */

  public getReadingPlan(
    readingPlanSlug: string,
    language: string
  ): Observable<any> {
    const endPoint = `content/reading-plans/${encodeURIComponent(
      readingPlanSlug
    )}?language=${encodeURIComponent(this._currentLanguage)}`;

    const cacheKey = readingPlanSlug + language;

    this.remoteDataMap$[cacheKey] = this.backendService
      .get<any>({
        endpoint: endPoint,
        options: {
          headers: {
            'x-brand': this.brand.id,
            redisTTL: `${this.storeSettings.storeTtl.default}`,
          },
        },
      })
      .pipe(
        map((remoteData: any) => {
          // fix date format for episodes (replace day and month)
          if (remoteData.data.hasFixedDates) {
            remoteData.data?.episodes?.map((episode: any) => {
              if (episode.fixedDate) {
                const splittedDate = episode.fixedDate.split('-');
                episode.formattedFixedDate = `${splittedDate[1]}/${splittedDate[0]}/${splittedDate[2]}`;
              }
            });
          }
          return remoteData;
        })
      );

    return this.localData$.pipe(
      take(1),
      switchMap((localData) => {
        if (localData?.[cacheKey]) {
          return of(localData[cacheKey]);
        }

        return this.remoteDataMap$[cacheKey].pipe(
          tap((remoteData: any) => {
            // write returned data from api to the store
            this.setState(
              {
                [cacheKey]: remoteData,
              },
              true
            );
          })
        );
      })
    );
  }
}
