import { Component, Input } from '@angular/core';
import { Episode, ReadingPlan, BibleMetadata } from '@ibep/interfaces';

@Component({
  selector: 'ibep-episode-page',
  templateUrl: './episode-page.component.html',
})
export class EpisodePageComponent {
  @Input() isMobileAppView: boolean;
  @Input() parentRoutes: any;
  @Input() episode: Episode;
  @Input() plan: ReadingPlan;
  @Input() bible: BibleMetadata;
  @Input() nextEpisodeLink: string;
  @Input() prevEpisodeLink: string;
  @Input() dateFormat: string;
}
