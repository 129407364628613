import { Component } from '@angular/core';

@Component({
  selector: 'bible-qd',
  template: `
    <p class="qd">
      <ng-content></ng-content>
    </p>
  `,
  styleUrls: ['./qd.component.scss'],
})
export class QdComponent {}
