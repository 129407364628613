import { Component, Input } from '@angular/core';

@Component({
  selector: 'ibep-swipe-svg',
  template: `
    <svg
      ibepHideTillInit
      width="25px"
      height="15px"
      fill="currentColor"
      class="inline m-auto"
      [ngClass]="svgStyles"
      viewBox="0 0 25 15"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <title>hand-swipe-horizontal</title>
      <g
        id="🖹-Content-pages"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
        stroke-linejoin="round"
      >
        <g
          id="Content-page-mobile"
          transform="translate(-102.000000, -4890.000000)"
          stroke="#889FBC"
        >
          <g id="Group-12" transform="translate(102.507000, 4890.000000)">
            <g id="Group" transform="translate(0.000000, 1.479000)">
              <line
                x1="3.33066907e-16"
                y1="3.021"
                x2="5"
                y2="3.021"
                id="Path"
                stroke-linecap="round"
              ></line>
              <polyline
                id="Path"
                stroke-linecap="round"
                points="2 5.521 3.33066907e-16 3.021 2 0.521"
              ></polyline>
              <line
                x1="23"
                y1="3.021"
                x2="18"
                y2="3.021"
                id="Path"
                stroke-linecap="round"
              ></line>
              <polyline
                id="Path"
                stroke-linecap="round"
                points="21 5.521 23 3.021 21 0.521"
              ></polyline>
              <path
                d="M10,1 C10,0.449 10.447,0 11,0 C11.553,0 12,0.449 12,1 L12,5.756 L15.92,6.924 C16.574,7.113 16.967,7.781 16.814,8.445 C16.681,9.018 16.071,12.435 15.964,13.021 L9.844,13.021 L6.783,8.429 C5.568,6.574 8.619,5.929 10,9.021 L10,1 Z"
                id="Path"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  `,
  styles: [],
})
export class SwipeComponent {
  @Input() svgStyles: string;
}
