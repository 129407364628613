<ng-template #htmlElements let-item="item" let-i="i">
  <ng-container [ngSwitch]="item.nodeName">
    <!-- IFRAME  -->
    <span
      *ngSwitchCase="'IFRAME'"
      [innerHTML]="item.outerHTML | safeHtml"
    ></span>
    <!-- H2  -->
    <ng-container *ngSwitchCase="'H2'"
      ><h2 class="text-24px text-[#040632] leading-[32px] font-bold mb-4">
        <ng-container
          [ngTemplateOutlet]="subHtmlElements"
          [ngTemplateOutletContext]="{
            item: item
          }"
        ></ng-container></h2
    ></ng-container>
    <!-- H3  -->
    <ng-container *ngSwitchCase="'H3'"
      ><h3 class="text-19px text-[#040632] leading-[32px] font-bold mb-4">
        <ng-container
          [ngTemplateOutlet]="subHtmlElements"
          [ngTemplateOutletContext]="{
            item: item
          }"
        ></ng-container></h3
    ></ng-container>
    <!-- H4  -->
    <ng-container *ngSwitchCase="'H4'"
      ><h4 class="text-17px text-[#040632] leading-[32px] font-bold mb-4">
        <ng-container
          [ngTemplateOutlet]="subHtmlElements"
          [ngTemplateOutletContext]="{
            item: item
          }"
        ></ng-container></h4
    ></ng-container>
    <!-- BLOCKQUOTE -->
    <ng-container *ngSwitchCase="'BLOCKQUOTE'"
      ><blockquote class="pb-4 pl-4">
        <ng-container
          [ngTemplateOutlet]="subHtmlElements"
          [ngTemplateOutletContext]="{
            item: item
          }"
        ></ng-container></blockquote
    ></ng-container>
    <!-- P  -->
    <ng-container *ngSwitchCase="'P'"
      ><p class="pb-4 leading-[1.5]">
        <ng-container
          [ngTemplateOutlet]="subHtmlElements"
          [ngTemplateOutletContext]="{
            item: item
          }"
        ></ng-container></p
    ></ng-container>
    <!-- Text  -->
    <span *ngSwitchCase="'#text'">{{ item.data }}</span>
    <!-- Image  -->
    <span *ngSwitchCase="'IMG'">
      <ibep-image
        [img]="{
          src: item.src,
          alt: item.alt,
          caption: HTMLElements[0]?.innerText,
          style: item.style,
          srcLg: item.dataset.imgUrl
        }"
        [inline]="inlineImage"
        [disableLightbox]="
          item.dataset.type === 'Asset::Table' || disableLightbox
        "
        [imageGallery]="imageGallery"
        [galleryIndex]="galleryIndex"
        [galleryTotal]="galleryTotal"
        [lightBoxState]="imageLightBox"
        (openSiblingLightBox)="siblingLightBox($event)"
      ></ibep-image>
    </span>
    <!-- Supperscript  -->
    <span *ngSwitchCase="'SUP'"
      ><sup class="align-super text-3xs">
        <ng-container
          [ngTemplateOutlet]="subHtmlElements"
          [ngTemplateOutletContext]="{
            item: item
          }"
        ></ng-container> </sup
    ></span>
    <!-- Bold text  -->
    <span *ngSwitchCase="'STRONG'"
      ><strong class="font-bold">
        <ng-container
          [ngTemplateOutlet]="subHtmlElements"
          [ngTemplateOutletContext]="{
            item: item
          }"
        ></ng-container> </strong
    ></span>
    <span *ngSwitchCase="'B'"
      ><b class="font-bold">
        <ng-container
          [ngTemplateOutlet]="subHtmlElements"
          [ngTemplateOutletContext]="{
            item: item
          }"
        ></ng-container> </b
    ></span>
    <!-- Italic text  -->
    <span *ngSwitchCase="'EM'"
      ><em class="italic">
        <ng-container
          [ngTemplateOutlet]="subHtmlElements"
          [ngTemplateOutletContext]="{
            item: item
          }"
        ></ng-container> </em
    ></span>
    <span *ngSwitchCase="'VIDEO'">
      <video controls [innerHTML]="item.innerHTML | safeHtml"></video>
    </span>
    <span *ngSwitchCase="'I'"
      ><i class="italic">
        <ng-container
          [ngTemplateOutlet]="subHtmlElements"
          [ngTemplateOutletContext]="{
            item: item
          }"
        ></ng-container> </i
    ></span>
    <!-- Strike trough text  -->
    <span *ngSwitchCase="'S'"
      ><s class="line-through">
        <ng-container
          [ngTemplateOutlet]="subHtmlElements"
          [ngTemplateOutletContext]="{
            item: item
          }"
        ></ng-container> </s
    ></span>
    <!-- Break  -->
    <span *ngSwitchCase="'BR'"><br /></span>
    <!-- Anchor link  -->
    <span *ngSwitchCase="'A'">
      <!-- External link  -->
      <a
        *ngIf="item.id !== 'routerlink'"
        [href]="item.href"
        [target]="item.target"
        class="break-words text-secondary hover:text-primary group"
      >
        <span>
          <ng-container
            [ngTemplateOutlet]="subHtmlElements"
            [ngTemplateOutletContext]="{
              item: item
            }"
          ></ng-container>
        </span>
        <ibep-box-arrow-up-right-svg
          class="px-0.5 pl-1 text-secondary group-hover:text-primary"
          svgStyles="w-3 h-3"
        ></ibep-box-arrow-up-right-svg>
      </a>
      <!-- Internal link  -->
      <a
        *ngIf="item.id === 'routerlink'"
        (click)="internalRedirect(item.dataset)"
        class="break-words text-secondary hover:text-primary group cursor-pointer"
      >
        <span>
          <ng-container
            [ngTemplateOutlet]="subHtmlElements"
            [ngTemplateOutletContext]="{
              item: item
            }"
          ></ng-container>
        </span>
        <ibep-box-arrow-up-right-svg
          class="px-0.5 pl-1 text-secondary group-hover:text-primary"
          svgStyles="w-3 h-3"
        ></ibep-box-arrow-up-right-svg
      ></a>
    </span>
    <ng-container *ngIf="!showRegularBullets">
      <ul *ngSwitchCase="'UL'" class="mb-4">
        <li
          *ngFor="let listItem of item.children"
          class="my-2 relative mt-4 leading-6 pl-8"
        >
          <ibep-check-circle-fill-svg
            svgStyles="absolute left-0"
          ></ibep-check-circle-fill-svg>
          <ng-container
            [ngTemplateOutlet]="subHtmlElements"
            [ngTemplateOutletContext]="{
              item: listItem
            }"
          ></ng-container>
        </li>
      </ul>
    </ng-container>
    <ng-container *ngIf="showRegularBullets">
      <ul *ngSwitchCase="'UL'" class="mb-4">
        <li
          *ngFor="let listItem of item.children"
          class="list-disc my-2 relative"
        >
          <ng-container
            [ngTemplateOutlet]="subHtmlElements"
            [ngTemplateOutletContext]="{
              item: listItem
            }"
          ></ng-container>
        </li>
      </ul>
    </ng-container>

    <ng-container *ngSwitchCase="'OL'">
      <ol class="mb-4">
        <li
          *ngFor="let listItem of item.children; let i = index"
          class="my-2 relative mt-4 leading-6 pl-8 list-none"
        >
          <span class="absolute left-0">{{ i + 1 }}.</span>
          <ng-container
            [ngTemplateOutlet]="subHtmlElements"
            [ngTemplateOutletContext]="{
              item: listItem
            }"
          ></ng-container>
        </li>
      </ol>
    </ng-container>

    <ng-container
      *ngSwitchCase="'SPAN'"
      (click)="
        handleInlineNoteClick(item.dataset?.ref?.trim(), item.dataset?.index)
      "
      class="cursor-pointer hover:text-primary group"
    >
      <ng-container *ngIf="item.className !== 'note-reference-selector'">
        <span [style]="item.attributes.style?.nodeValue">
          <ng-container
            [ngTemplateOutlet]="subHtmlElements"
            [ngTemplateOutletContext]="{
              item: item
            }"
          ></ng-container>
        </span>
      </ng-container>

      <!-- Inline Note  -->
      <ng-container *ngIf="item.className === 'note-reference-selector'">
        <span
          class="pr-1 cursor-pointer hover:text-primary group"
          (click)="
            handleInlineNoteClick(
              item.dataset?.ref?.trim(),
              item.dataset?.index
            )
          "
        >
          <ng-container
            [ngTemplateOutlet]="subHtmlElements"
            [ngTemplateOutletContext]="{
              item: item
            }"
          ></ng-container>
          <!-- Down icon  -->
          <svg
            ibepHideTillInit
            *ngIf="
              !activeNotes.has(
                item.dataset?.ref?.trim() + '-' + item.dataset?.index
              )
            "
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            class="ml-1 w-4 h-4 inline text-grayLight group-hover:text-primary"
            fill="currentColor"
          >
            <path
              d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM377.6 248.3l-104 112C269 365.2 262.7 368 256 368s-13.05-2.781-17.59-7.656l-104-112c-6.484-7-8.219-17.19-4.406-25.94C133.8 213.7 142.5 208 152 208h208c9.547 0 18.19 5.656 21.1 14.41C385.8 231.2 384.1 241.3 377.6 248.3z"
            />
          </svg>
          <!-- Up icon  -->
          <svg
            ibepHideTillInit
            *ngIf="
              activeNotes.has(
                item.dataset?.ref?.trim() + '-' + item.dataset?.index
              )
            "
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            class="ml-1 w-4 h-4 inline text-grayLight group-hover:text-primary"
            fill="currentColor"
          >
            <path
              d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM381.1 289.6C378.2 298.3 369.5 304 360 304h-208c-9.547 0-18.19-5.656-22-14.41c-3.812-8.75-2.078-18.94 4.407-25.94l104-112c9.094-9.75 26.09-9.75 35.19 0l104 112C384.1 270.7 385.8 280.8 381.1 289.6z"
            />
          </svg>
          <!-- The actual note  -->
          <div
            class="group-hover:text-grayMedium"
            *ngIf="
              activeNotes.has(
                item.dataset?.ref?.trim() + '-' + item.dataset?.index
              )
            "
            [innerHTML]="
              activeNotes.get(
                item.dataset?.ref?.trim() + '-' + item.dataset?.index
              )?.innerHTML || '' | safeHtml
            "
          ></div>
        </span>
      </ng-container>
    </ng-container>

    <span *ngSwitchDefault>
      <ng-container
        [ngTemplateOutlet]="subHtmlElements"
        [ngTemplateOutletContext]="{
          item: item
        }"
      ></ng-container>
    </span>
  </ng-container>
</ng-template>

<ng-template #subHtmlElements let-item="item">
  <span *ngIf="!item.childNodes" [innerHTML]="item.innerHTML | safeHtml"></span>
  <ng-container *ngIf="item.childNodes">
    <ng-container *ngFor="let subItem of item.childNodes">
      <ng-container
        [ngTemplateOutlet]="htmlElements"
        [ngTemplateOutletContext]="{
          item: subItem
        }"
      ></ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<p class="text-grayMedium">
  <!-- Loop over the HTML elements from the paragraph -->
  <ng-container *ngFor="let item of HTMLElements">
    <ng-container
      [ngTemplateOutlet]="htmlElements"
      [ngTemplateOutletContext]="{
        item: item
      }"
    ></ng-container>
  </ng-container>
</p>
