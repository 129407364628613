<div
  class="flex flex-col lg:flex-row lg:px-0 text-grayMedium rounded-lg"
  [ngClass]="{
    'max-w-container mx-4 xl:mx-auto': heroType === 'image'
  }"
>
  <div
    class="w-full flex flex-col justify-around items-start hero"
    [ngClass]="{
      'rounded-lg lg:rounded-l-2xl lg:rounded-r-none lg:w-[533px] lg:h-hero':
        heroType === 'image',
      'lg:h-[288px] px-4 lg:px-5 ': heroType === 'noImage',
      '!bg-none px-4 lg:px-5 justify-start ': heroType === 'simple'
    }"
  >
    <div
      [ngClass]="{
        'w-full max-w-container mx-auto':
          heroType === 'noImage' || heroType === 'simple'
      }"
    >
      <div
        [ngClass]="{
          'max-w-[610px] lg:pr-4 m-auto':
            (heroType === 'noImage' || heroType === 'simple') &&
            !props.sidebarActive,
          'lg:w-4/6 flex lg:justify-center max-w-[610px] lg:min-w-[610px] mx-auto lg:mx-[unset] lg:max-w-none':
            (heroType === 'noImage' || heroType === 'simple') &&
            props.sidebarActive,
          'lg:w-[533px]': heroType === 'image'
        }"
      >
        <div
          [ngClass]="{
            'lg:!w-[610px] !mx-auto':
              heroType === 'noImage' || heroType === 'simple',
            'lg:-mt-8': heroType === 'noImage',
            'lg:w-[610px]': heroType === 'simple',
            'lg:max-w-[376px] mx-auto lg:-mt-16': heroType === 'image'
          }"
        >
          <!-- Author / publish date  -->
          <div
            class="lg:mb-2 lg:px-3 px-0"
            *ngIf="props.author || props.authors || props.showPublishDate"
          >
            <span
              class="uppercase text-grayLight lg:text-white text-3xs lg:opacity-80 font-bold"
              [ngClass]="{
                'lg:!text-grayLight': heroType === 'simple'
              }"
              ><span *ngIf="props.showPublishDate">{{
                props.publishDate
                  | date : props.dateFormat
                  | localizeDate : props.dateFormat
              }}</span>
              <span *ngIf="props.author && props.showPublishDate"> • </span>
              <span *ngIf="props.author" [innerHTML]="props.author"></span>
              <ng-container *ngIf="props.authors">
                <ng-container
                  *ngFor="let author of props.authors; let isLast = last"
                >
                  <a
                    class="hover:text-secondary"
                    [routerLink]="decodeSlug(author.link) | localize"
                    [ngClass]="{ 'pointer-events-none': !author.link }"
                  >
                    <span [innerHTML]="author.name"></span
                    >{{ isLast ? '' : ', ' }}</a
                  >
                </ng-container>
              </ng-container>
            </span>
          </div>
          <!-- Tags  -->
          <div
            class="mb-2 lg:mb-6 ml-3 sm:ml-0"
            *ngIf="props.tags"
            [ngClass]="{
              'lg:mb-2': heroType === 'simple'
            }"
          >
            <a *ngFor="let tag of props.tags">
              <span
                class="inline-block bg-grayExtraLight lg:bg-white lg:text-white text-xs px-2 py-1 lg:opacity-70 lg:bg-opacity-10 rounded-lg mr-2"
                [innerHTML]="tag.name"
                [ngClass]="{
                  'lg:text-grayDark lg:bg-grayExtraLight': heroType === 'simple'
                }"
              ></span>
            </a>
          </div>
          <!-- Title  -->
          <h1
            class="font-bold text-3xl lg:text-[36px] leading-[48px] px-4 lg:px-3 mb-2 lg:mb-0 lg:text-white w-full"
            [ngClass]="{ 'lg:text-grayDark': heroType === 'simple' }"
            [innerHTML]="props.title"
          ></h1>
          <h2
            class="text-xl text-[17px] leading-[24px] lg:leading-[28px] lg:mt-4 lg:mb-0 lg:text-white"
            *ngIf="props.subtitle"
            [innerHTML]="props.subtitle"
          ></h2>
          <h3
            class="text-lg lg:text-white text-grayLight lg:leading-[28px] leading-[24px] text-[14px]"
            *ngIf="props.minutes"
          >
            {{
              'VAR_minRead'
                | translate
                  : {
                      N: props.minutes
                    }
            }}
          </h3>
        </div>
      </div>
    </div>
  </div>

  <!-- Image  -->
  <div
    *ngIf="props.image?.url && props.contentType !== 'ebc'"
    class="lg:h-hero lg:rounded-r-2xl lg:bg-cover lg:bg-center mt-3 lg:mt-0 h-fit relative"
    [ngClass]="{
      'lg:w-[717px]': heroType === 'image'
    }"
  >
    <div
      class="flex absolute inset-0 lg:bg-black lg:opacity-5 h-full flex-col"
    ></div>
    <img
      [ngSrc]="props.image.url"
      fill
      class="hidden lg:block object-cover lg:rounded-r-2xl"
      [src]="props.image.url"
    />
    <div class="lg:hidden !aspect-video">
      <img
        fill
        [ngSrc]="props.image.url"
        class="lg:rounded-r-2xl object-cover"
        [src]="props.image.url"
      />
    </div>
  </div>
</div>
