import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Bible, BibleMetadata, DailyVerse } from '@ibep/interfaces';

@Component({
  selector: 'ibep-dashboard-hero',
  templateUrl: './dashboard-hero.component.html',
  styleUrls: ['./dashboard-hero.component.scss'],
})
export class DashboardHeroComponent {
  @Input() heroProps: {
    dailyVerse: DailyVerse;
    bible: Bible;
    bibleMetaData: BibleMetadata;
    dailyVerseImage?: string;
  };

  @Input() currentBibleAbbrs: string[] = [];
  @Input() currentBibleChapter: string;
  @Input() currentBibleChapterFullname: string;
  @Input() isBrowser: boolean;

  @Output() openModalClicked = new EventEmitter<boolean>();
  @Output() pushGtmTag = new EventEmitter<object>();

  /**
   * Open bible picker modal
   *
   * @memberof DashboardHeroComponent
   */
  public openModal() {
    this.openModalClicked.emit(true);
  }

  public onGtmTagPush(tag: object): void {
    this.pushGtmTag.emit(tag);
  }
}
