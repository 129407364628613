import { Injectable } from '@angular/core';
import { LanguageService } from '@ibep/fe/shared/core';
import { Observable } from 'rxjs';
import { UserInfo } from '../interfaces/user-data.interface';
import { Auth } from '@ibep/fe/web/module-auth';

type Iso639Part = '1' | '2' | '2B' | '3';

@Injectable({
  providedIn: 'root',
  useClass: LanguageService,
})
export abstract class AbstractLanguageService {
  public abstract currentLanguage$: Observable<string>;
  public abstract getLanguageName(
    lang: string,
    local: boolean,
    iso: Iso639Part
  ): void;

  public abstract changeLanguage(
    lang: string | undefined,
    profile: UserInfo | null,
    auth: Auth | null
  ): void;
  public abstract isDefaultLanguage: boolean;
  public abstract currentLanguage: string;
}
