<!-- TODO: This component needs to be refactored : use modal service  -->
<ibep-picker>
  <!-- Project the toggle button content inside the ibep-picker  -->
  <ng-container toggleContent>
    <span
      class="lg:text-grayLight h-full flex items-center text-[10px] hover:text-primary"
      aria-label="bible reader options selector"
    >
      <span class="lg:font-bold uppercase">{{ readingMode }}</span>
      <span class="ml-1 mr-1 hidden lg:inline">{{ 'MODE' | translate }}</span>
      <ibep-gear-svg class="-ml-2 mt-1 text-grayMedium"></ibep-gear-svg
    ></span>
  </ng-container>

  <!-- Project the Picker Content inside the ibep-picker  -->
  <ng-container pickerContent>
    <div class="pl-7 mb-5 text-xs font-bold">
      <h1>{{ 'ReaderOptions' | translate }}</h1>
    </div>

    <div class="flex flex-col gap-6">
      <!-- Study the bible  -->
      <div class="block lg:hidden">
        <h2 class="uppercase text-grayMedium text-4xs mt-3 mb-1">
          {{ 'StudyTheBible' | translate }}
        </h2>
        <div class="flex flex-col text-xs text-grayLight">
          <div
            class="py-3 border-b border-highlightText flex justify-between"
            (click)="openTranslationPicker()"
          >
            <div class="flex hover:text-primary">
              <ibep-add-translation-svg class="mr-2"></ibep-add-translation-svg>
              <span>{{ 'AddATranslation' | translate }}</span>
              <span
                *ngIf="allowParallelReadingFor === 'registered'"
                class="relative w-4 h-4 ml-1 rounded-full text-primary bg-highlightText border border-grayExtraLight px-[3px]"
                ><ibep-lock-svg
                  svgStyles="w-2 h-2 absolute top-[3px] right-[3px]"
                ></ibep-lock-svg
              ></span>
              <span
                *ngIf="allowParallelReadingFor === 'premium'"
                class="text-secondary text-[9px] bg-white uppercase font-bold px-[2px]"
              >
                <span>{{ 'Premium' | translate }}</span></span
              >
            </div>

            <ibep-chevron-right-svg
              class="text-grayLight mr-2"
              svgStyles="w-3 h-3 font-bold"
            ></ibep-chevron-right-svg>
          </div>
          <div
            *ngIf="useEBC"
            class="py-2 border-b border-highlightText flex justify-between cursor-pointer"
            (click)="toggleEbc()"
          >
            <div>
              <ibep-exclamation-svg
                svgStyles="w-4 h-4"
                class="inline mr-3"
              ></ibep-exclamation-svg>
              <span class="pt-1">{{ 'ShowAdditionalInfo' | translate }}</span>
            </div>

            <ibep-toggle
              [isActive]="readerState.ebcState.showEbc"
              [isDisabled]="false"
            ></ibep-toggle>
          </div>
        </div>
      </div>
      <!-- Reading Mode  -->
      <div>
        <div>
          <h2 class="uppercase text-grayMedium text-4xs my-3">
            {{ 'ReadingMode' | translate }}
          </h2>
        </div>
        <div class="flex flex-col gap-2">
          <button
            class="text-left border border-grayLight py-2 px-4 rounded-lg cursor-pointer"
            (click)="setReaderOption($event, 'readingMode', 'easy')"
            [ngClass]="{
              isActive: readerOptions.readingMode === 'easy'
            }"
          >
            <h3 class="text-primary font-bold text-sm">
              {{ 'Easy' | translate }}
            </h3>
            <p class="text-grayLight text-3xs">
              {{ 'LessOptionsBut*' | translate }}
            </p>
          </button>
          <button
            class="text-left border border-grayLight py-2 px-4 rounded-lg cursor-pointer"
            (click)="setReaderOption($event, 'readingMode', 'advanced')"
            [ngClass]="{
              isActive: readerOptions.readingMode === 'advanced'
            }"
          >
            <h3 class="text-primary font-bold text-sm">
              {{ 'Advanced' | translate }}
            </h3>
            <p class="text-grayLight text-3xs">
              {{ 'AllTheOptions*' | translate }}
            </p>
          </button>
          <button
            class="text-left border border-grayLight py-2 px-4 rounded-lg cursor-pointer"
            (click)="setReaderOption($event, 'readingMode', 'custom')"
            [ngClass]="{
              isActive: readerOptions.readingMode === 'custom'
            }"
          >
            <h3 class="text-primary font-bold text-sm">
              {{ 'Custom' | translate }}
            </h3>
            <p class="text-grayLight text-3xs">
              {{ 'CustomizeYourReading*' | translate }}
            </p>
            <div
              class="flex flex-col divide-y text-xs text-grayMedium leading-10"
            >
              <button
                (click)="setReaderOption($event, 'showVerseNumbers')"
                [disabled]="readerOptions.readingMode !== 'custom'"
                class="flex flex-row justify-between"
                [ngClass]="{
                  'text-grayMedium': readerOptions.readingMode === 'custom',
                  'text-gray-300': readerOptions.readingMode !== 'custom'
                }"
              >
                <span>{{ 'ShowVerseNumbers' | translate }}</span
                ><ibep-toggle
                  [isActive]="readerOptions.showVerseNumbers === true"
                  [isDisabled]="readerOptions.readingMode !== 'custom'"
                ></ibep-toggle>
              </button>
              <button
                (click)="setReaderOption($event, 'showFootnotes')"
                [disabled]="readerOptions.readingMode !== 'custom'"
                class="flex flex-row justify-between"
                [ngClass]="{
                  'text-grayMedium': readerOptions.readingMode === 'custom',
                  'text-gray-300': readerOptions.readingMode !== 'custom'
                }"
              >
                <span>{{ 'ShowFootnotes' | translate }}</span
                ><ibep-toggle
                  [isActive]="readerOptions.showFootnotes === true"
                  [isDisabled]="readerOptions.readingMode !== 'custom'"
                ></ibep-toggle>
              </button>
              <button
                (click)="setReaderOption($event, 'showCrossReferences')"
                [disabled]="readerOptions.readingMode !== 'custom'"
                class="flex flex-row justify-between"
                [ngClass]="{
                  'text-grayMedium': readerOptions.readingMode === 'custom',
                  'text-gray-300': readerOptions.readingMode !== 'custom'
                }"
              >
                <span>{{ 'ShowCrossReferences' | translate }}</span
                ><ibep-toggle
                  [isActive]="readerOptions.showCrossReferences === true"
                  [isDisabled]="readerOptions.readingMode !== 'custom'"
                ></ibep-toggle>
              </button>

              <button
                *ngIf="userAccountSystem"
                (click)="setReaderOption($event, 'showUserNotes')"
                [disabled]="readerOptions.readingMode !== 'custom'"
                class="flex flex-row justify-between"
                [ngClass]="{
                  'text-grayMedium': readerOptions.readingMode === 'custom',
                  'text-gray-300':
                    readerOptions.readingMode !== 'custom' || !isAuthenticated
                }"
              >
                <span>{{ 'ShowMyNotes' | translate }}</span>
                <div>
                  <span
                    *ngIf="!isAuthenticated"
                    class="w-4 h-4 ml-1 rounded-full text-primary bg-highlightText border border-grayExtraLight px-[5px]"
                    ><ibep-lock-svg svgStyles="w-2 h-2 mb-[3px]"></ibep-lock-svg
                  ></span>
                  <span class="mt-1">
                    <ibep-toggle
                      [isActive]="readerOptions.showUserNotes === true"
                      [isDisabled]="readerOptions.readingMode !== 'custom'"
                    ></ibep-toggle>
                  </span>
                </div>
              </button>
              <button
                *ngIf="userAccountSystem"
                (click)="setReaderOption($event, 'showUserHighlights')"
                [disabled]="readerOptions.readingMode !== 'custom'"
                class="flex flex-row justify-between"
                [ngClass]="{
                  'text-grayMedium': readerOptions.readingMode === 'custom',
                  'text-gray-300':
                    readerOptions.readingMode !== 'custom' || !isAuthenticated
                }"
              >
                <span>{{ 'ShowMyHighlights' | translate }}</span>
                <div>
                  <span
                    *ngIf="!isAuthenticated"
                    class="w-4 h-4 ml-1 rounded-full text-primary bg-highlightText border border-grayExtraLight px-[5px]"
                    ><ibep-lock-svg svgStyles="w-2 h-2 mb-[3px]"></ibep-lock-svg
                  ></span>
                  <ibep-toggle
                    [isActive]="readerOptions.showUserHighlights === true"
                    [isDisabled]="readerOptions.readingMode !== 'custom'"
                  ></ibep-toggle>
                </div>
              </button>
            </div>
          </button>
        </div>
      </div>

      <!-- Font Size -->
      <div>
        <div>
          <h2 class="uppercase text-grayMedium text-4xs my-3">
            {{ 'FontSize' | translate }}
          </h2>
        </div>

        <div class="flex flex-row border border-grayLight rounded-lg">
          <button
            class="w-full text-2xs text-center py-1 px-4 rounded-lg cursor-pointer m-[0.2rem]"
            (click)="setReaderOption($event, 'fontSize', 'text-bible-sm')"
            [ngClass]="{
              isActive: readerOptions.fontSize === 'text-bible-sm'
            }"
          >
            aA
          </button>
          <button
            class="w-full text-base text-center py-1 px-4 rounded-lg cursor-pointer m-[0.2rem]"
            (click)="setReaderOption($event, 'fontSize', 'text-bible-md')"
            [ngClass]="{
              isActive: readerOptions.fontSize === 'text-bible-md'
            }"
          >
            aA
          </button>
          <button
            class="w-full text-2xl text-center py-1 px-4 rounded-lg cursor-pointer m-[0.2rem]"
            (click)="setReaderOption($event, 'fontSize', 'text-bible-lg')"
            [ngClass]="{
              isActive: readerOptions.fontSize === 'text-bible-lg'
            }"
          >
            aA
          </button>
        </div>
      </div>

      <!--Font Family -->
      <div>
        <div>
          <h2 class="uppercase text-grayMedium text-4xs my-3">
            {{ 'Font' | translate }}
          </h2>
        </div>

        <div class="border border-grayLight rounded-lg">
          <!-- Brand font  -->
          <div
            *ngIf="
              config?.theme &&
              config.theme.font !== 'notoSans' &&
              config.theme.font !== 'notoSerif'
            "
            role="button"
            class="p-2 cursor-pointer rounded-lg m-[0.2rem]"
            style="font-family: {{ config.theme.font }}"
            (click)="setReaderOption($event, 'fontType', config.theme.font)"
            [ngClass]="{
              isActive: readerOptions.fontType === config.theme.font
            }"
          >
            {{
              config.theme.font.charAt(0).toUpperCase() +
                config.theme.font.slice(1)
            }}
          </div>
          <!-- Noto Sans -->
          <div
            role="button"
            class="p-2 cursor-pointer rounded-lg m-[0.2rem]"
            (click)="setReaderOption($event, 'fontType', 'notoSans')"
            [ngClass]="{
              isActive: readerOptions.fontType === 'notoSans'
            }"
          >
            <img
              class="h-4"
              src="/assets/fontNotoSans.png"
              alt="Font Noto Sans"
            />
          </div>
          <!-- Noto Serif -->
          <div
            role="button"
            class="p-2 cursor-pointer rounded-lg m-[0.2rem]"
            (click)="setReaderOption($event, 'fontType', 'noto-serif')"
            [ngClass]="{
              isActive: readerOptions.fontType === 'noto-serif'
            }"
          >
            <img
              class="h-4"
              src="/assets/fontNotoSerif.png"
              alt="Font Noto Serif"
            />
          </div>
          <!-- Quicksand -->
          <div
            role="button"
            class="p-2 cursor-pointer rounded-lg m-[0.2rem]"
            (click)="setReaderOption($event, 'fontType', 'quicksand')"
            [ngClass]="{
              isActive: readerOptions.fontType === 'quicksand'
            }"
          >
            <img
              class="h-4"
              src="/assets/fontQuicksand.png"
              alt="Font Quicksand"
            />
          </div>
          <!-- Fira Sans -->
          <div
            role="button"
            class="p-2 cursor-pointer rounded-lg m-[0.2rem]"
            (click)="setReaderOption($event, 'fontType', 'fira-sans')"
            [ngClass]="{
              isActive: readerOptions.fontType === 'fira-sans'
            }"
          >
            <img
              class="h-4"
              src="/assets/fontFiraSans.png"
              alt="Font Fira Sans"
            />
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ibep-picker>
