<!-- Language picker -->
<div class="relative">
  <a (click)="toggleDropdown()">
    <!-- the button  -->
    <button
      #langButton
      type="button"
      class="relative h-8 w-full lg:w-fit-content lg:min-w-[120px] rounded bg-white py-1 pl-3 pr-10 text-left shadow-sm border border-grayExtraLight focus:outline-none sm:text-sm cursor-pointer group"
    >
      <span class="inline-flex w-full truncate group-hover:text-primary">
        <span class="truncate" [innerHTML]="currentLanguage"></span>
      </span>
      <span
        class="absolute inset-y-0 right-0 flex items-center pr-2 text-[#C0D0E4]"
      >
        <ibep-chevron-down-svg
          *ngIf="!showDropdown"
          svgStyles="w-3 h-3"
        ></ibep-chevron-down-svg>
        <ibep-chevron-up-svg
          *ngIf="showDropdown"
          svgStyles="w-3 h-3"
        ></ibep-chevron-up-svg>
      </span>
    </button>
  </a>
  <!-- the dropdown -->
  <div
    #langDropdown
    *ngIf="showDropdown"
    [ngClass]="{
      'opacity-0': !showDropdown,
      'opacity-100': showDropdown
    }"
    class="fixed left-0 right-0 top-0 lg:top-[38px] bottom-0 lg:absolute z-[666] lg:-mt-1 lg:h-fit lg:max-h-64 overflow-y-auto rounded bg-white py-1 text-base shadow-lg border border-grayExtraLight border-t-0 focus:outline-none sm:text-sm transition ease-in duration-100 pb-1"
  >
    <div
      class="lg:hidden px-3 mt-2 mb-4 cursor-pointer"
      (click)="toggleDropdown()"
    >
      <ibep-arrow-left-svg svgStyles="w-4 h-4 mr-2"></ibep-arrow-left-svg>
      <span class="truncate">{{ 'ChooseACategory' | translate }}</span>
    </div>
    <ul>
      <a
        *ngFor="let language of langList; let i = index"
        (click)="changeLanguage(language)"
        [href]="i === 0 ? '' : language.languageCode"
      >
        <li
          class="text-grayMedium hover:text-primary relative cursor-pointer select-none py-2 px-3 text-xs flex justify-between"
        >
          <span> {{ language.localName }}</span>
          <ibep-chevron-right-svg
            svgStyles="w-3 h-3 font-bold text-[#C0D0E4]"
          ></ibep-chevron-right-svg>
        </li>
      </a>
    </ul>
  </div>
</div>
