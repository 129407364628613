import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BaseComponent } from '@ibep/fe/shared/core';
import { ConfigLocalizedData } from '@ibep/fe/shared/data';
import { AccountTriggerBarService, AnalyticsService } from '@ibep/fe/web/core';
import {
  AbstractAuthService,
  PlatformType,
  TriggerBarSettings,
} from '@ibep/interfaces';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'ibep-account-trigger-bar-container',
  templateUrl: './account-trigger-bar-container.component.html',
})
export class AccountTriggerBarContainerComponent
  extends BaseComponent
  implements OnInit
{
  public hidden? = true;
  public isUrlAllowed: boolean;
  public isAuthenticated: boolean;
  public triggerBarSettings: TriggerBarSettings;
  public platform: PlatformType;
  public userAccountSystem: boolean;

  constructor(
    private readonly _router: Router,
    private readonly _analyticsService: AnalyticsService,
    private readonly _translate: TranslateService,
    private readonly _accountTriggerBarService: AccountTriggerBarService,
    private readonly _authService: AbstractAuthService,
    private readonly _configData: ConfigLocalizedData
  ) {
    super();
  }

  ngOnInit(): void {
    this.checkBarVisibilityState();

    this._router.events.pipe(takeUntil(this._destroy)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isUrlAllowed = this.checkAllowedUrls();
      }
    });

    this._authService.isAuthenticated$
      .pipe(takeUntil(this._destroy))
      .subscribe((isAuthenticated: boolean) => {
        this.isAuthenticated = isAuthenticated;
      });

    this._configData
      .getConfig()
      .pipe(takeUntil(this._destroy))
      .subscribe((config) => {
        this.platform = config.platform;
        this.userAccountSystem =
          config.brand.brandUIPreferences?.userAccountSystem;
      });

    this._configData
      .getAccountSettings()
      .pipe(takeUntil(this._destroy))
      .subscribe((accountSettings) => {
        this.triggerBarSettings = accountSettings?.triggerBar;
      });
  }

  public closeBar(): void {
    this._accountTriggerBarService.closeBar();
    this.hidden = true;
  }

  private checkBarVisibilityState(): void {
    this.isUrlAllowed = this.checkAllowedUrls();
    this._accountTriggerBarService
      .isBarHidden()
      .pipe(takeUntil(this._destroy))
      .subscribe((hidden) => {
        this.hidden = hidden;
      });
  }

  public openModal(event: string): void {
    this._router.navigate([], {
      queryParams: { view: event },
    });
  }

  private checkAllowedUrls(): boolean {
    return (
      this._router.url === '/' ||
      this._router.url === `/${this._translate.instant('ROUTES.bible')}` ||
      this._router.url.startsWith(
        `/${this._translate.instant('ROUTES.bible')}/`
      ) ||
      this._router.url.startsWith(
        `/${this._translate.instant('ROUTES.search')}/`
      )
    );
  }

  public pushGtmTag(tag: object): void {
    this._analyticsService.pushGtmTag(tag);
  }
}
