<div class="mb-12">
  <ng-container *ngIf="parentPage">
    <a [routerLink]="'/' + parentPage.slug | localize"
      ><h3
        class="text-lg font-bold pb-2 hover:text-primary"
        [innerHTML]="parentPage.title"
      ></h3></a
  ></ng-container>
  <ng-container *ngFor="let page of pagetreeNav">
    <a [routerLink]="'/' + (page.slug | localize)"
      ><div
        class="border-b border-grayExtraLight py-4 text-xs hover:text-primary flex justify-between"
        [ngClass]="{ 'font-bold': currentPageId === page.id }"
      >
        <span [innerHTML]="page.title"></span>
        <ibep-chevron-right-svg
          svgStyles="w-3 h-3 font-bold"
        ></ibep-chevron-right-svg></div
    ></a>
    <ng-container *ngIf="page.children">
      <div *ngFor="let childPage of page.children" class="pl-4">
        <a
          [routerLink]="
            '/' +
            (ebcTree
              ? (parentPage.slug | localize) + '/' + (childPage.slug | localize)
              : (childPage.slug | localize))
          "
          ><div
            class="border-b border-grayExtraLight py-4 text-xs hover:text-primary flex justify-between"
            [ngClass]="{ 'font-bold': currentPageId === childPage.id }"
          >
            <span [innerHTML]="childPage.title"></span>
            <ibep-chevron-right-svg
              svgStyles="w-3 h-3 font-bold"
            ></ibep-chevron-right-svg></div
        ></a>
      </div>
    </ng-container>
  </ng-container>
</div>
