import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FontsService {
  constructor(@Inject(PLATFORM_ID) private platformId: any) {}

  /**
   * Conditionally load webfonts.
   * This function uses: https://github.com/typekit/webfontloader
   *
   * @param {string} font
   * @memberof FontsService
   */
  async loadWebfont(font: string) {
    if (isPlatformBrowser(this.platformId)) {
      // we import package here, because it breaks ssr
      const WebFont = await import('webfontloader');
      WebFont.load({
        custom: {
          families: [font],
          urls: [`/assets/fonts/${font}/${font}.css`],
        },
      });
    }
  }
}
