import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'ibep-sidebar-block',
  templateUrl: './sidebar-block.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarBlockComponent {
  @Input() props: {
    imageSrc: string;
    imageAlt: string;
    content: string;
    buttonText?: string;
    buttonLink?: string;
    linkType?: string;
    title: string;
  };
}
