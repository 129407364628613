import { Component, Input } from '@angular/core';
import { ModalOptions } from '@ibep/interfaces';
import { ModalService } from '@ibep/fe/web/core';
import { Router } from '@angular/router';

@Component({
  selector: 'ibep-modal',
  templateUrl: './modal.component.html',
  styles: [
    `
      /* Scrollbar config for Chrome, Safari and Opera */
      .modal::-webkit-scrollbar {
        display: auto;
      }

      /* Scrollbar config for IE, Edge and Firefox */
      .modal {
        overflow-y: auto;
        -ms-overflow-style: auto; /* IE and Edge */
        scrollbar-width: auto; /* Firefox */
      }
    `,
  ],
})
export class ModalComponent {
  @Input() modalOptions: Partial<ModalOptions> = {};

  constructor(
    private readonly _modalService: ModalService,
    private readonly _router: Router
  ) {}

  public clickedOutside(event: MouseEvent) {
    if (this.modalOptions && this.modalOptions.closeOnClickOutSide === false) {
      return;
    }

    let shouldSkip = false;
    event.composedPath().forEach((eventItem) => {
      // @ts-ignore
      if (eventItem.id === 'full-pop-up') {
        shouldSkip = true;
      }
    });
    if (shouldSkip) {
      return;
    }
    this._modalService.clickedOutside();
  }

  public close() {
    this._modalService.closeModal();
    if (this.modalOptions.clearQueryParams) {
      this._router.navigate([], {
        queryParams: {},
      });
    }
  }
}
