import { Injectable } from '@angular/core';
import {
  AbstractBackendService,
  AbstractEnvironmentService,
  AbstractLanguageService,
  AbstractStorageService,
} from '@ibep/interfaces';
import { MONTH } from '@ibep/shared/util';
import { combineLatest, Observable, of } from 'rxjs';
import { switchMap, take, tap, shareReplay } from 'rxjs/operators';
import { ConfigData } from '..';
import { Store, StoreSettings, StoreSettingsTTL } from './store';

export interface UserNote {
  noteId: string;
  content: string;
  title: string;
  verseOrgIds: string[];
  currentBibleVerseIds: string[];
  contentEditValue?: string;
  activeColumn?: string;
  id?: string;
  titleEditValue?: string;
  createdAt?: string;
  timestamp?: string;
}

@Injectable({
  providedIn: 'root',
})
export class UserNotesData extends Store<any> {
  constructor(
    storage: AbstractStorageService,
    environment: AbstractEnvironmentService,
    backendService: AbstractBackendService,
    languageService: AbstractLanguageService,
    config: ConfigData
  ) {
    super(storage, environment, backendService, languageService, {
      storeTtl: { default: 1 * MONTH } as StoreSettingsTTL,
      storeKey: 'userNotes',
      brand$: config.getBrand(),
      doNotStoreLocally: true,
    } as StoreSettings);
  }

  /**
   * Get user notes for a chapter, user notes are not connected to a specific bible translation
   *
   * @returns {Observable<any>}
   * @memberof UserNotesData
   */
  public get({
    chapterId,
    bibleId,
  }: {
    chapterId: string;
    bibleId?: string;
  }): Observable<any> {
    this.remoteDataMap$[`${chapterId}-${bibleId}`] = this.backendService
      .get<any>({
        endpoint: `user/notes/${chapterId}${
          bibleId ? `?bibleId=${bibleId}` : ''
        }`,
        options: {
          headers: {
            'x-brand': this.brand.id,
          },
        },
      })
      .pipe(shareReplay(1));

    return this.localData$.pipe(
      switchMap((localData) => {
        // check if there is local data available
        if (localData?.[`${chapterId}-${bibleId}`]) {
          return of(localData[`${chapterId}-${bibleId}`]);
        }
        // if no local data, fetch data from api
        return this.remoteDataMap$[`${chapterId}-${bibleId}`].pipe(
          tap((remoteData: any) => {
            // write returned data from api to the store
            this.setState(
              {
                [`${chapterId}-${bibleId}`]: remoteData.data,
              },
              true
            );
          })
        );
      })
    );
  }

  public add(
    note: UserNote,
    chapterId: string,
    activeColumn: string,
    bibleId?: string
  ) {
    const postNote$ = this.backendService
      .post<any>({
        endpoint: `user/notes`,
        options: {
          body: note,
          headers: {
            'x-brand': this.brand.id,
          },
        },
      })
      .pipe(shareReplay(1));

    delete note.activeColumn;
    delete note.id;
    delete note.contentEditValue;
    delete note.titleEditValue;
    if (!note.title) {
      note.title = '';
    }

    return this.localData$.pipe(
      take(1),
      switchMap((localData) => combineLatest([postNote$, of(localData)])),
      tap(([remoteData, localData]) => {
        const newData = [...localData[`${chapterId}-${bibleId}`]];
        if (remoteData && remoteData.data) {
          remoteData.data.activeColumn = activeColumn;
          newData.push(remoteData.data);
          // write returned data from api to the store
          this.setState(
            {
              [`${chapterId}-${bibleId}`]: newData,
            },
            true
          );
        }
      })
    );
  }

  public delete({
    noteId,
    chapterId,
    bibleId,
  }: {
    noteId: string;
    chapterId?: string;
    bibleId?: string;
  }) {
    const deleteNote$ = this.backendService
      .delete<any>({
        endpoint: `user/notes/${noteId}`,
        options: {
          headers: {
            'x-brand': this.brand.id,
          },
        },
      })
      .pipe(shareReplay(1));

    return this.localData$.pipe(
      take(1),
      switchMap((localData) => combineLatest([deleteNote$, of(localData)])),
      tap(([remoteData, localData]) => {
        if (chapterId) {
          const newData = localData[`${chapterId}-${bibleId}`].filter(
            (note: any) => note.noteId !== noteId
          );
          this.setState(
            {
              [`${chapterId}-${bibleId}`]: newData,
            },
            true
          );
        }
      })
    );
  }

  public update({
    note,
    chapterId,
    bibleId,
  }: {
    note: UserNote;
    chapterId?: string;
    bibleId?: string;
  }) {
    // we don't want to send these properties to the backend
    delete note.contentEditValue;
    delete note.titleEditValue;
    delete note.createdAt;
    delete note.timestamp;

    // create a new observable to update the note
    const updateNote$ = this.backendService
      .put<any>({
        endpoint: `user/notes`,
        options: {
          body: note,
          headers: {
            'x-brand': this.brand.id,
          },
        },
      })
      .pipe(shareReplay(1));

    // get the local data and update the note
    return this.localData$.pipe(
      take(1),
      switchMap((localData) => combineLatest([updateNote$, of(localData)])),
      tap(([remoteData, localData]) => {
        if (chapterId) {
          // update the changed note in the local store
          const newData = [
            ...localData[`${chapterId}-${bibleId}`].map((oldNote: any) => {
              if (oldNote.noteId === note.noteId) {
                return remoteData.data;
              }
              return oldNote;
            }),
          ];
          this.setState(
            {
              [`${chapterId}-${bibleId}`]: newData,
            },
            true
          );
        }
      })
    );
  }
}
