import { Component, Input } from '@angular/core';

@Component({
  selector: 'bible-qm',
  template: `
    <p [class]="item.style">
      <ng-content></ng-content>
    </p>
  `,
  styleUrls: ['./qm.component.scss'],
})
export class QmComponent {
  @Input() item: any;
}
