import { Component } from '@angular/core';
/**
 * @export
 * @class RqComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'bible-rq',
  templateUrl: './rq.component.html',
  styleUrls: ['./rq.component.scss'],
})
export class RqComponent {}
