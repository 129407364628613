import { Component } from '@angular/core';

@Component({
  selector: 'bible-pmc',
  template: `
    <p class="pmc">
      <ng-content></ng-content>
    </p>
  `,
  styleUrls: ['./pmc.component.scss'],
})
export class PmcComponent {}
