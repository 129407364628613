<div
  class="flex flex-col justify-between bg-primary bg-opacity-10 lg:rounded-lg p-6 lg:w-72 md:w-72 w-full"
>
  <img
    *ngIf="props.imageSrc"
    class="w-full object-cover pb-2"
    [src]="props.imageSrc"
    [alt]="props.imageAlt"
  />
  <h3 class="flex-none text-lg font-bold pb-2" [innerHTML]="props.title"></h3>
  <div class="grow flex flex-col">
    <ibep-paragraph
      class="text-[14px] grow leading-[24px]"
      [html]="props.content"
    ></ibep-paragraph>
    <div class="mt-3">
      <ibep-link
        [linkProps]="{
          link: props.buttonLink,
          isExternalLink: props.linkType === 'external'
        }"
      >
        <ibep-button
          [buttonProps]="{
            pushGtm: true,
            link: props.buttonLink,
            content: props.buttonText,
            style: 'default',
            size: 'm'
          }"
          *ngIf="props.buttonText"
        >
          <b
            class="text-xs text-secondary line-clamp-1"
            [innerHTML]="props.buttonText"
          ></b>
        </ibep-button>
      </ibep-link>
    </div>
  </div>
</div>
