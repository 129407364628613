<ng-container *ngIf="config$ | async">
  <!-- Header -->
  <ibep-header-container *ngIf="!isMobileAppView"></ibep-header-container>

  <!-- Main -->
  <main>
    <ibep-searchbar-container
      *ngIf="!isMobileAppView"
    ></ibep-searchbar-container>

    <ibep-account-trigger-bar-container
      *ngIf="!isMobileAppView"
    ></ibep-account-trigger-bar-container>

    <!-- All feature libs are lazy loaded inside the router outlet  -->
    <router-outlet></router-outlet>
  </main>

  <!-- Footer  -->
  <ibep-footer-container *ngIf="!isMobileAppView"></ibep-footer-container>

  <!-- Notifications -->
  <ibep-notifications-container
    *ngIf="!isMobileAppView"
  ></ibep-notifications-container>

  <!-- Loading progressbar -->
  <ngx-loading-bar></ngx-loading-bar>
</ng-container>
<ng-container *ngIf="!config">
  <div
    style="
      overflow: hidden;
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    "
  ></div>
  <ibep-page-loading-main></ibep-page-loading-main>
</ng-container>
