<div class="flex justify-between items-center m-4 select-none px-4 lg:px-0">
  <ibep-chevron-left-svg
    svgStyles="w-10 h-10 p-2 border border-[#C0D0E4] hover:border-primary rounded-full"
    class="inline"
    [ngClass]="{
      'text-[#C0D0E4]': actualPage === 1,
      'cursor-pointer': actualPage !== 1
    }"
    (click)="goToPage(actualPage - 1)"
  ></ibep-chevron-left-svg>
  <div class="text-secondary font-bold">
    <span
      class="mx-2"
      (click)="goToPage(1)"
      [ngClass]="{
        'text-[#C0D0E4]': actualPage === 1,
        'cursor-pointer hover:text-primary': actualPage !== 1
      }"
      >1</span
    >
    <span
      *ngIf="lastPage > 1"
      class="mx-2"
      (click)="goToPage(2)"
      [ngClass]="{
        'text-[#C0D0E4]': actualPage === 2,
        'cursor-pointer hover:text-primary': actualPage !== 2
      }"
      >2</span
    >
    <span *ngIf="lastPage > 5 && middlePage !== 3" class="mx-2">...</span>
    <span
      *ngIf="lastPage > 2"
      class="mx-2"
      (click)="goToPage(middlePage)"
      [ngClass]="{
        'text-[#C0D0E4]': actualPage === middlePage,
        'cursor-pointer hover:text-primary': actualPage !== middlePage
      }"
      >{{ middlePage }}</span
    >
    <span *ngIf="lastPage > 5 && middlePage !== lastPage - 2" class="mx-2"
      >...</span
    >
    <span
      *ngIf="lastPage > 4"
      class="mx-2"
      (click)="goToPage(lastPage - 1)"
      [ngClass]="{
        'text-[#C0D0E4]': actualPage === lastPage - 1,
        'cursor-pointer hover:text-primary': actualPage !== lastPage - 1
      }"
      >{{ lastPage - 1 }}</span
    >
    <span
      *ngIf="lastPage > 3"
      class="mx-2"
      (click)="goToPage(lastPage)"
      [ngClass]="{
        'text-[#C0D0E4]': actualPage === lastPage,
        'cursor-pointer hover:text-primary': actualPage !== lastPage
      }"
      >{{ lastPage }}</span
    >
  </div>
  <ibep-chevron-right-svg
    svgStyles="w-10 h-10 p-2 border border-[#C0D0E4] hover:border-primary rounded-full"
    class="inline"
    [ngClass]="{
      'text-[#C0D0E4]': actualPage === lastPage,
      'cursor-pointer': actualPage !== lastPage
    }"
    (click)="goToPage(actualPage + 1)"
  ></ibep-chevron-right-svg>
</div>
