<div
  class="absolute top-0 left-0 right-0 bottom-0"
  [ngClass]="{ 'top-20': !isSearchPage }"
>
  <!-- Picker header -->
  <div
    class="w-full h-8 relative ml-6 mb-2 font-semibold text-left"
    *ngIf="isSearchPage"
  >
    {{ 'SelectTranslation' | translate }}
  </div>
  <!-- Search books -->
  <div
    class="w-full h-8 pt-[8px] relative mb-1"
    [ngClass]="{ 'pr-6': isSearchPage }"
  >
    <ibep-search-svg
      class="absolute"
      svgStyles="w-3 h-3 ml-5 mt-1.5"
    ></ibep-search-svg>
    <input
      class="w-full h-8 text-sm rounded-[40px] text-content100 pl-10 pr-4 border border-grayExtraLight z-20"
      type="search"
      name="search"
      [placeholder]="'SearchForATranslation' | translate"
      #searchInput
    />
  </div>
  <!-- Translations  -->
  <div
    class="absolute w-full"
    [ngClass]="{
      'bottom-[10.5rem] lg:bottom-[5.5rem] top-14': !isSearchPage,
      'bottom-0 top-24': isSearchPage
    }"
  >
    <div class="overflow-auto h-full">
      <h2
        *ngIf="maxActiveColumns > columnsLimitEnum.SINGLE"
        class="text-xs text-left text-grayMedium font-bold mb-4"
      >
        {{ 'SelectTranslations' | translate }}
        <span class="lg:hidden">
          ({{
            'max_VAR'
              | translate
                : {
                    N: 2
                  }
          }})</span
        ><span class="hidden lg:inline"
          >({{
            'max_VAR'
              | translate
                : {
                    N: 5
                  }
          }})</span
        >
      </h2>
      <div *ngFor="let language of languages; let i = index">
        <ng-container *ngIf="!isUserNotesTab || i > 0">
          <div
            class="uppercase text-left text-4xs text-grayMedium font-bold mt-3"
          >
            {{ language.nameLocal }}
          </div>
          <ng-container *ngFor="let bible of language.bibles">
            <a
              class="block px-3 py-2 text-xs border-grayExtraLight bg-opacity-5"
              [ngClass]="{
                'border-b': language.bibles.length > 1,
                'bg-primary': bible.isSelected
              }"
              (click)="
                bible.isDisabled ? openDisabledModal(bible.accessLevel) : null
              "
            >
              <div class="relative flex items-start py-1">
                <div class="flex items-center">
                  <!-- if multiselect show checkbox -->
                  <input
                    *ngIf="maxActiveColumns > columnsLimitEnum.SINGLE"
                    [id]="bible.id"
                    [name]="bible.id"
                    type="checkbox"
                    [checked]="bible.isSelected"
                    (click)="toggleBible(bible)"
                    [disabled]="
                      bible.isDisabled ||
                      (bibleSelection.length > 4 &&
                        !bibleSelection.includes(bible.id)) ||
                      (bibleSelection.length === 1 &&
                        bibleSelection.includes(bible.id))
                    "
                    class="focus:ring-primary h-4 w-4 text-primary border-grayLight rounded cursor-pointer"
                  />
                  <!-- if single select show radio -->
                  <input
                    *ngIf="maxActiveColumns === columnsLimitEnum.SINGLE"
                    [id]="bible.id"
                    [name]="bible.id"
                    type="radio"
                    [checked]="bible.isSelected"
                    (click)="toggleBible(bible)"
                    [disabled]="bible.isDisabled"
                    class="focus:ring-primary h-4 w-5 -ml-0.5 text-primary border-grayLight cursor-pointer"
                  />
                </div>
                <div class="ml-3 text-xs">
                  <label
                    [for]="bible.id"
                    class="cursor-pointer flex justify-between flex-row text-left line-clamp-1 hover:text-primary"
                    [ngClass]="{
                      'text-grayLight': bible.isDisabled,
                      'text-grayMedium': !bible.isDisabled
                    }"
                    >{{ bible.name }}
                    <span class="pl-2 text-grayLight">{{
                      bible.abbreviation
                    }}</span>
                    <span class="pl-2 text-grayLight" *ngIf="checkAudio(bible)">
                      <ibep-audio-svg svgStyles="w-4 h-4"></ibep-audio-svg
                    ></span>

                    <div
                      *ngIf="
                        bible.accessLevel === 'registered' && !isAuthenticated
                      "
                      class="relative inline-block w-4 h-4 rounded-full bg-highlightText border border-grayExtraLight px-[3px] ml-2"
                    >
                      <span class="w-4 h-4 text-primary">
                        <ibep-lock-svg
                          svgStyles="absolute top-[3px] right-[3px] w-2 h-2"
                        ></ibep-lock-svg>
                      </span>
                    </div>
                  </label>
                </div>
                <div
                  *ngIf="bible.accessLevel === 'premium' && !isPremium"
                  class="text-secondary text-4xs bg-secondary bg-opacity-10 ml-auto uppercase font-bold px-[2px]"
                >
                  <span>{{ 'Premium' | translate }}</span>
                </div>
              </div>
            </a>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
  <div
    *ngIf="!isSearchPage"
    class="absolute bottom-[5.5rem] lg:bottom-0 left-0 right-0 mt-6 px-4 pb-4 bg-white"
  >
    <div class="absolute bottom-14 right-0 left-0 h-10"></div>
    <a
      [routerLink]="
        '/ROUTES.bible/' + getBibleSelectedAbbrs() + '/' + selectedChapter
          | localize
      "
      (click)="closeModal(); onGtmTagPush(getBibleSelectedAbbrs().join())"
    >
      <ibep-button
        [buttonProps]="{
          pushGtm: true,
          content: 'ShowSelectedTranslations' | translate,
          link:
            '/ROUTES.bible/' + getBibleSelectedAbbrs() + '/' + selectedChapter
            | localize,
          style: 'secondary',
          size: 'l',
          fullWidth: true
        }"
        class="w-full"
      >
        <b class="font-bold px-2 text-center w-full">{{
          'ShowSelectedTranslations' | translate
        }}</b>
      </ibep-button>
    </a>
  </div>
</div>
