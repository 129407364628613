import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'ibep-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      /* Hide scrollbar for Chrome, Safari and Opera */
      .breadcrumb::-webkit-scrollbar {
        display: none;
      }

      /* Hide scrollbar for IE, Edge and Firefox */
      .breadcrumb {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
      }
    `,
  ],
})
export class BreadcrumbsComponent {
  @Input() props: { currentRouteTitle: string; parentRoutes?: any };
}
