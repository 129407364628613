<div
  (click)="changeEbcModalPosition()"
  class="group absolute top-2 left-0 right-0 mx-auto cursor-grab lg:hidden px-8 py-2"
  [ngClass]="{
    'z-40': !showCategoryFilter
  }"
>
  <div
    class="h-1 w-14 bg-[#C0D0E4] group-hover:bg-primary rounded-xl mx-auto"
  ></div>
</div>
<div
  class="flex relative flex-col h-full overflow-y-scroll overflow-x-hidden no-scrollbar overscroll-contain"
>
  <!-- EBC controls  -->
  <div class="p-5 bg-white sticky top-0 z-10">
    <h2 class="text-lg font-bold">{{ 'AdditionalInfo' | translate }}</h2>
    <div class="mt-6">
      <!-- category filter -->
      <div *ngIf="ebcCategories?.length">
        <h4 class="uppercase text-3xs">{{ 'FilterByCategory' | translate }}</h4>
        <div>
          <div class="relative mt-1">
            <button
              #categoryFilterButton
              (click)="toggleCategoryFilter()"
              type="button"
              class="relative w-full rounded-md bg-white py-2 pl-3 pr-10 text-left shadow-sm border border-grayExtraLight focus:outline-none sm:text-sm cursor-pointer group"
            >
              <span
                class="inline-flex w-full truncate group-hover:text-primary"
              >
                <span class="truncate">{{
                  'FilterByCategory' | translate
                }}</span>
              </span>
              <span class="absolute inset-y-0 right-0 flex items-center pr-2">
                <ibep-chevron-down-svg
                  *ngIf="!showCategoryFilter"
                  svgStyles="w-3 h-3"
                ></ibep-chevron-down-svg>
                <ibep-chevron-up-svg
                  *ngIf="showCategoryFilter"
                  svgStyles="w-3 h-3"
                ></ibep-chevron-up-svg>
              </span>
            </button>
            <!-- category filter popup  -->
            <div
              #categoryFilter
              *ngIf="showCategoryFilter"
              [ngClass]="{
                'top-[290px]': ebcModalPosition === '50%',
                'top-0': ebcModalPosition !== '50%',
                'opacity-0': !showCategoryFilter,
                'opacity-100': showCategoryFilter
              }"
              class="fixed lg:top-0 left-0 right-0 bottom-0 lg:absolute !z-[1000] lg:mt-9 lg:w-full h-full lg:h-fit lg:max-h-60 overflow-auto rounded-b-md bg-white py-1 pb-24 lg:pb-1 text-base shadow-lg border border-grayExtraLight border-t-0 focus:outline-none sm:text-sm transition ease-in duration-100 overflow-x-hidden overscroll-contain"
            >
              <div class="lg:hidden px-3 py-3 cursor-pointer z-50">
                <a (click)="toggleCategoryFilter()"
                  ><ibep-arrow-left-svg
                    svgStyles="w-4 h-4"
                  ></ibep-arrow-left-svg>
                  <span class="ml-3">{{
                    'SelectAdditionalInfo*' | translate
                  }}</span></a
                >
              </div>
              <ul
                class="lg:pb-0"
                [ngClass]="{
                  'pb-64': ebcModalPosition === '50%'
                }"
              >
                <li
                  *ngFor="let category of ebcCategories || []"
                  class="text-grayMedium relative cursor-pointer select-none py-2 px-3 text-xs"
                  role="option"
                >
                  <!-- main category  -->
                  <div
                    class="flex justify-between border-b border-[#EFF7FF] py-2 group"
                  >
                    <div class="flex items-center">
                      <input
                        type="checkbox"
                        [id]="category.termId"
                        [disabled]="!category.count"
                        class="focus:ring-primary h-4 w-4 text-primary border-[#C0D0E4] rounded cursor-pointer"
                        [(ngModel)]="category.checked"
                        (ngModelChange)="filterEbcByCategory(category)"
                      />
                      <label
                        class="font-bold truncate cursor-pointer group-hover:text-primary ml-2"
                        [innerHTML]="category.name"
                        [for]="category.termId"
                      ></label>
                    </div>
                    <div>
                      <span class="ml-2 truncate text-[#C0D0E4]">
                        {{
                          'VAR_items'
                            | translate
                              : {
                                  N: category.count
                                }
                        }}
                      </span>
                    </div>
                    <div>
                      <span class="pr-1">
                        <ibep-chevron-up-svg
                          svgStyles="w-3 h-3"
                        ></ibep-chevron-up-svg>
                      </span>
                    </div>
                  </div>

                  <!-- sub category  -->
                  <ul class="my-2 border-l-4 border-[#EFF7FF]">
                    <li
                      *ngFor="let subcategory of category.subcategories"
                      class="text-grayMedium relative cursor-pointer select-none py-2 px-3 text-xs"
                      role="option"
                    >
                      <div class="flex justify-between group">
                        <div class="flex items-center">
                          <input
                            type="checkbox"
                            [id]="subcategory.termId"
                            [disabled]="!subcategory.count"
                            class="focus:ring-primary h-4 w-4 text-primary border-[#C0D0E4] rounded cursor-pointer"
                            [(ngModel)]="subcategory.checked"
                            (ngModelChange)="
                              filterEbcByCategory(category, subcategory)
                            "
                          />
                          <label
                            class="truncate cursor-pointer group-hover:text-primary ml-2"
                            [innerHTML]="subcategory.name"
                            [for]="subcategory.termId"
                          ></label>
                        </div>
                        <div>
                          <span class="ml-2 pr-6 truncate text-[#C0D0E4]">
                            {{
                              'VAR_items'
                                | translate
                                  : {
                                      N: subcategory.count
                                    }
                            }}
                          </span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
              <div class="mx-3 fixed bottom-3 left-0 right-0 lg:hidden">
                <ibep-button
                  (click)="toggleCategoryFilter()"
                  [buttonProps]="{
                    style: 'secondary',
                    size: 'l',
                    fullWidth: true
                  }"
                  class="w-full"
                >
                  <b class="font-bold px-2 text-center w-full">{{
                    'UseSelection' | translate
                  }}</b>
                </ibep-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- filter by tag  -->
      <div class="mt-4" *ngIf="tags?.length">
        <h4 class="uppercase text-3xs">{{ 'FilterByTag' | translate }}</h4>
        <div class="mt-2">
          <span
            *ngFor="let tag of tags"
            (click)="filterEbcByTag(tag.slug)"
            class="inline-block bg-[#EFF7FF] text-xs px-2 py-1 rounded-md mr-2 cursor-pointer"
            [ngClass]="{ 'bg-primary text-white': tag.slug === chosenTagSlug }"
            [innerHTML]="tag.name"
          ></span>
        </div>
      </div>
    </div>
  </div>
  <!-- EBC articles  -->
  <div class="text-xs z-0" *ngIf="!isLoadingEbc">
    <ng-container *ngFor="let article of filteredArticles">
      <!-- Regular  -->
      <a
        [routerLink]="[]"
        [queryParams]="
          article.accesslevel === 'Premium' && !isPremium
            ? { view: 'premium' }
            : article.accesslevel === 'Registered' && !isAuthenticated
            ? { view: 'signUp' }
            : { ebc: true, slug: article.slug }
        "
        (click)="pushEbcArticleGtmEvent(article)"
      >
        <div
          class="relative bg-white border border-white hover:border-primary group m-[7px] pl-3 pt-3 pb-2 text-grayMedium cursor-pointer min-h-[48px] grid"
          [ngClass]="{
            'grid-cols-1 pr-10': !article.image,
            'grid-cols-4 pr-3': article.image,
            'pt-7':
              (article.accesslevel === 'Premium' && !isPremium) ||
              (article.accesslevel === 'Registered' && !isAuthenticated)
          }"
        >
          <div
            [ngClass]="{
              'col-span-1': !article.image,
              'col-span-3': article.image
            }"
          >
            <div class="relative min-h-[64px]">
              <h4
                *ngIf="article.title"
                [innerHTML]="article.title"
                class="font-bold pb-1 group-hover:text-primary"
              ></h4>
              <p
                class="max-h-12 overflow-hidden"
                *ngIf="article.description"
                [innerHTML]="article.description | safeHtml"
              ></p>
              <div
                class="grad absolute -bottom-2 left-0 right-0 w-full h-[30px]"
              ></div>
            </div>
          </div>
          <div #imageContainer *ngIf="article.image" class="mb-2">
            <ibep-image-picture
              *ngIf="article.image"
              [imgStyles]="'aspect-video w-full object-cover'"
              [image]="article.image"
              [containerWidth]="imageContainer?.clientWidth"
            ></ibep-image-picture>
            <!-- <img
              [ngSrc]="article.image.sizes?.thumbnail"
              [width]="article.image.sizes?.['thumbnail-width']"
              [height]="article.image.sizes?.['thumbnail-height']"
            /> -->
          </div>
          <div
            *ngIf="article.accesslevel === 'Premium' && !isPremium"
            class="absolute top-2 right-2 text-secondary text-4xs bg-secondary bg-opacity-10 ml-auto uppercase font-bold px-[2px]"
          >
            <span>{{ 'Premium' | translate }}</span>
          </div>
          <div
            *ngIf="article.accesslevel === 'Registered' && !isAuthenticated"
            class="absolute top-2 right-2 w-4 h-4 rounded-full bg-highlightText border border-grayExtraLight px-[3px]"
          >
            <span class="w-4 h-4 text-primary"
              ><ibep-lock-svg
                svgStyles="w-2 h-2 absolute top-[3px] right-[3px]"
              ></ibep-lock-svg
            ></span>
          </div>
        </div>
      </a>
    </ng-container>
  </div>
  <!-- Loading indicator -->
  <ng-container *ngIf="isLoadingEbc">
    <ibep-page-loading></ibep-page-loading>
  </ng-container>
</div>
