import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Config } from '@ibep/interfaces';

@Component({
  selector: 'ibep-sub-footer',
  templateUrl: './sub-footer.component.html',
  styleUrls: ['./sub-footer.component.scss'],
})
export class SubFooterComponent {
  @Input() config: Config;
  @Input() appVersion: string;
  @Input() currentLanguage: string;
  @Input() isDefaultLanguage: boolean;

  @Output() pushSocialTag = new EventEmitter<string>();

  public onSocialClick(link: string): void {
    this.pushSocialTag.emit(link);
  }
}
