import { Injectable } from '@angular/core';
import {
  webSocket,
  WebSocketSubject,
  WebSocketSubjectConfig,
} from 'rxjs/webSocket';
import {
  AbstractEnvironmentService,
  AbstractWebSocketService,
} from '@ibep/interfaces';
import { Observable, Subject, Subscription, timer } from 'rxjs';
import { MINUTE } from '@ibep/shared/util';

const NO_DATA_STATUS_CODE = 4404;
const FORBIDDEN_STATUS_CODE = 4403;
const TIMEOUT_STATUS_CODE = 4408;
const INACTIVE_CONNECTION_TIMEOUT = 5 * MINUTE;

@Injectable({
  providedIn: 'root',
})
export class WebSocketService implements AbstractWebSocketService {
  private _url: string;
  private _openConnectionParams: string;
  private _webSocket$: WebSocketSubject<any>;
  private _closeConnectionTimer$: Subscription;
  private _messagesSubject = new Subject();
  private _closeConnectionSubject = new Subject();
  private _openConnectionSubject = new Subject();
  private _isConnectionOpen: boolean;
  private _isConnectionOpening: boolean;
  private _lastMessage: any;

  constructor(environmentService: AbstractEnvironmentService) {
    this._url = environmentService.webSocketUrl;

    this._openConnectionSubject.subscribe(() => {
      this._isConnectionOpen = true;
      this._isConnectionOpening = false;
      if (this._lastMessage) {
        this._webSocket$?.next(this._lastMessage);
      }
    });

    this._closeConnectionSubject.subscribe(() => {
      this._isConnectionOpen = false;
    });
  }

  public get message$(): Observable<any> {
    return this._messagesSubject.asObservable();
  }

  public get isConnectionOpen(): boolean {
    return this._isConnectionOpen;
  }

  public openConnection(connectionParams: string): Observable<any> {
    if (!connectionParams) {
      throw new Error('Connection params are empty');
    }

    this._openConnectionParams = connectionParams;
    this._isConnectionOpening = true;

    this._webSocket$ = webSocket({
      url: `${this._url}?${connectionParams}`,
      closeObserver: this._closeConnectionSubject,
      openObserver: this._openConnectionSubject,
    } as WebSocketSubjectConfig<any>);

    this._webSocket$.subscribe((data) => {
      switch (data.errorCode) {
        case NO_DATA_STATUS_CODE: {
          this._messagesSubject.next(null);
          break;
        }
        case FORBIDDEN_STATUS_CODE: {
          this._messagesSubject.next(null);
          break;
        }
        case TIMEOUT_STATUS_CODE: {
          this.closeConnection(TIMEOUT_STATUS_CODE).subscribe(() => {
            this.send(this._lastMessage);
          });
          break;
        }
        default:
          this._messagesSubject.next(data);
          break;
      }
    });
    return this._openConnectionSubject.asObservable();
  }

  public send(message: any, queryParams?: string) {
    this._lastMessage = message;

    if (queryParams) {
      this._openConnectionParams = queryParams;
    }

    if (this.isConnectionOpen) {
      this._webSocket$?.next(message);

      this._closeConnectionTimer$?.unsubscribe();
      this._closeConnectionTimer$ = timer(
        INACTIVE_CONNECTION_TIMEOUT
      ).subscribe(() => {
        this.closeConnection();
      });
      return;
    }

    if (this._isConnectionOpening) {
      return;
    }

    this.openConnection(this._openConnectionParams);
  }

  public closeConnection(code?: number, reason?: string): Observable<any> {
    if (code || reason) {
      this._webSocket$?.error({ code, reason });
    } else {
      this._webSocket$?.complete();
    }
    return this._closeConnectionSubject.asObservable();
  }
}
