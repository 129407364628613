import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ContentType, ReadingPlan, Tag } from '@ibep/interfaces';

@Component({
  selector: 'ibep-reading-plans-overview-page',
  templateUrl: './reading-plans-overview-page.component.html',
})
export class ReadingPlansOverviewComponent {
  public contentTypeEnum = ContentType;

  @Input() tags: Tag[];
  @Input() readingPlans: ReadingPlan[];
  @Input() selectedTagSlug: string;
  @Input() morePlansAvailable: boolean;
  @Input() description: string;
  @Input() isBrowser: boolean;
  @Input() isLoading: boolean;

  @Output() loadMoreClicked = new EventEmitter<void>();

  public onLoadMore() {
    this.loadMoreClicked.emit();
  }
}
