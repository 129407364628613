import { Component, Input } from '@angular/core';
import { AnalyticsService } from '@ibep/fe/web/core';

export interface ButtonProps {
  size?: 's' | 'm' | 'm-flexible' | 'l' | 'xl';
  state?: 'active' | 'inactive';
  style?:
    | 'default'
    | 'primary'
    | 'secondary'
    | 'conversion'
    | 'highlight'
    | 'white'
    | 'white-transparent'
    | 'gray'
    | 'gray-light'
    | 'premium'
    | 'cta';
  shadow?: boolean;
  fullWidth?: boolean;
  autoWidth?: boolean;
  disabled?: boolean;
  locked?: boolean;
  content?: string;
  link?: string | any[];
  name?: string;
  pushGtm?: boolean;
  title?: string;
}

@Component({
  selector: 'ibep-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input()
  buttonProps: ButtonProps = <ButtonProps>{
    size: 'm',
    state: 'active',
    style: 'default',
    fullWidth: false,
    autoWidth: false,
  };

  constructor(private readonly _analyticsService: AnalyticsService) {}

  public pushIbepButtonTag(): void {
    if (this.buttonProps.pushGtm) {
      this._analyticsService.pushGtmTag({
        event: 'button_click',
        button_url: this.buttonProps.link,
        button_text: this.buttonProps.content,
        button_name: this.buttonProps.name,
      });
    }
  }
}
