import { Injectable } from '@angular/core';
import {
  AbstractBackendService,
  AbstractEnvironmentService,
  AbstractLanguageService,
  AbstractStorageService,
  AccountSettings,
  Bible,
  Brand,
  Config,
  ConfigDTO,
  EnvironmentType,
  GetBrandConfigResponse,
  MenuItem,
  Menus,
  PlatformType,
  ThemeColors,
} from '@ibep/interfaces';
import { HOUR } from '@ibep/shared/util';
import { Observable, of } from 'rxjs';
import {
  filter,
  map,
  shareReplay,
  switchMap,
  take,
  tap,
  catchError,
} from 'rxjs/operators';
import { Store, StoreSettings, StoreSettingsTTL } from './store';

@Injectable({
  providedIn: 'root',
})
export class ConfigData extends Store<Config> {
  private _domain: string;
  private _platform: PlatformType;
  private _environment: EnvironmentType;
  private _menuItemsMapped = false;
  private _gtmId: string;

  constructor(
    storage: AbstractStorageService,
    environment: AbstractEnvironmentService,
    backendService: AbstractBackendService
  ) {
    super(
      storage,
      environment,
      backendService,
      { currentLanguage$: of('default') } as unknown as AbstractLanguageService,
      {
        storeTtl: { default: 1 * HOUR } as StoreSettingsTTL,
        storeKey: 'config',
      } as StoreSettings
    );

    this._domain = this.environment.domain;
    this._platform = this.environment.platform;
    this._environment = this.environment.environment;
    this.remoteDataItem$ = this.backendService
      .get<GetBrandConfigResponse>({
        endpoint: `brands/config`,
        options: {
          params: {
            brandEntitlements: true,
            platform: 'web',
            domain: this._domain,
            includeLocalization: true,
          },
          headers: {
            redisTTL: `${this.storeSettings.storeTtl.default}`,
          },
        },
      })
      .pipe(
        shareReplay(1),
        catchError((err) =>
          of(this._getFallbackConfig() as GetBrandConfigResponse)
        ),
        map((config: GetBrandConfigResponse) => config.data)
      );
  }

  /**
   * Get the config data
   *
   * @returns {Observable<Config>}
   * @memberof ConfigData
   */
  public getConfig(): Observable<Config> {
    return this.localData$.pipe(
      take(1),
      shareReplay(),
      switchMap((localData: Config) => {
        // check if there is local data available
        if (localData && Object.keys(localData).length > 0) {
          this._setGtmId(localData?.tagManagerSettings?.gtmContainerId);
          return of(localData);
        }
        // if no local data, fetch data from api
        return this.remoteDataItem$.pipe(
          map((config: ConfigDTO) => {
            if (
              this._platform === 'browser' &&
              config.menus &&
              config.menus.main
            ) {
              config.menus.main = {
                ...config.menus.main,
                items: this.mapMenuItems(config.menus.main.items),
              };
            }
            this._setGtmId(config?.tagManagerSettings?.gtmContainerId);
            return {
              ...config,
              domain: this._domain,
              environment: this._environment,
              platform: this._platform,
            };
          }),
          tap((remoteData: Config) => {
            // write returned data from api to the store
            this.setState(remoteData);
          })
        );
      })
    );
  }

  /**
   * Get the account settings from the config object
   * @returns {Observable<AccountSettings>}
   * @memberof ConfigData
   */
  public getAccountSettings(): Observable<AccountSettings> {
    return this.getConfig().pipe(
      filter((config: Config) => Boolean(config)),
      map((config: Config) => config.accountSettings)
    );
  }

  /**
   * Get the brand from the config object
   * @returns {Observable<Brand>}
   * @memberof ConfigData
   */
  public getBrand(): Observable<Brand> {
    return this.getConfig().pipe(
      filter((config: Config) => Boolean(config)),
      map((config: Config) => config.brand)
    );
  }

  public getUserAccountSystem(): Observable<boolean> {
    return this.getConfig().pipe(
      filter((config: Config) => Boolean(config)),
      map(
        (config: Config) => config.brand.brandUIPreferences?.userAccountSystem
      )
    );
  }

  /**
   * Get the menus from the config object
   * @returns {Observable<Menus>}
   * @memberof ConfigData
   */
  public getMenus(): Observable<Menus> {
    return this.getConfig().pipe(map((config) => config.menus));
  }

  /**
   * Get the colors from the custom theme object
   * @returns {Observable<ThemeColors>}
   * @memberof ConfigData
   */
  public getColors(): Observable<ThemeColors> {
    return this.getConfig().pipe(map((config) => config.theme?.colors));
  }

  /**
   * Public function to get the brand entitlements from the config object
   * @returns {Observable<Entitlement[]>}
   * @memberof ConfigData
   */
  public getBrandEntitlements(): Observable<Bible[]> {
    return this.getConfig().pipe(map((config) => config.bibles));
  }

  /**
   * Returns a list of the available brand languages
   *
   * @returns availableLanguages: Array<{
   * defaultBibleId?: string;
   * languageCode: string;
   * localeCode: string;
   * }>;
   * @memberof ConfigData
   */
  public getBrandLanguages(): Observable<Brand['availableLanguages']> {
    return this.getBrand().pipe(
      map((brand: Brand) => brand.availableLanguages)
    );
  }

  /**
   * Map menu items
   *
   * @private
   * @param {*} oldMenuItems
   * @returns
   * @memberof ConfigData
   */
  private mapMenuItems(oldMenuItems: MenuItem[]) {
    if (!this._menuItemsMapped) {
      const newMenuItems = oldMenuItems.map((oldItem: any) => {
        const item = { ...oldItem };
        // For the 2nd level menu items, we check if there are sections (a section has a section title + menu items).
        // if there is a section, we add an extra layer to the menu data
        if (item.items.length) {
          const newItems: any[] = [];
          item.items.forEach((subItem: any) => {
            // section with title
            if (subItem.linkType === 'sectionTitle') {
              newItems.push(subItem);
              // if the first menu item does not start with a title
            } else if (newItems.length === 0) {
              newItems.push({ items: [subItem] });
            } else if (newItems[newItems.length - 1]?.items) {
              newItems[newItems.length - 1].items.push(subItem);
            } else {
              newItems[newItems.length - 1].items = [subItem];
            }
          });
          item.items = newItems;
        }

        return item;
      });
      this._menuItemsMapped = true;
      return newMenuItems;
    }
    return oldMenuItems;
  }

  private _getFallbackConfig() {
    // TODO: move to config file?
    return {
      data: {
        isFallback: true,
        brand: {
          availableLanguages: [
            {
              defaultBibleId: '01b58d476a09e6a6-01',
              languageCode: 'nl',
              localeCode: 'nl-NL',
            },
          ],
          countryCode: 'NL',
          'countryCode-languageCode': 'NL-nl',
          defaultLanguageCode: 'nl',
          defaultLocaleCode: 'nl-NL',
          id: 'NBG',
        },
        localization: {},
        theme: {
          colors: {
            primary: '#36afc5',
            secondary: '#dd6268',
            callToAction: '#005262',
          },
        },
      },
    } as unknown;
  }

  private _setGtmId(id: string | undefined): void {
    if (this._platform === 'browser' && id && id !== this._gtmId) {
      this._gtmId = id;
    }
  }

  get gtmId(): string {
    return this._gtmId;
  }
}
