import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MenuItem } from '@ibep/interfaces';
import { debounceTime, Subject } from 'rxjs';

const ITEM_HEIGHT = 32;
const MENU_OFFSET = 64;
const DEFAULT_ACTIVE_MENU_ITEM = 666;
const MOUSE_EVENTS_DELAY = 100;
const TOGGLE_DELAY = 300;

@Component({
  selector: 'ibep-menu-fly-out',
  templateUrl: './menu-fly-out.component.html',
  styleUrls: ['./menu-fly-out.component.scss'],
})
export class MenuFlyOutComponent {
  @Input() menuItems: MenuItem;

  private clearSelection$ = new Subject<boolean>();
  private dropdownToggle$ = new Subject<boolean>();

  public activeMenuItem = DEFAULT_ACTIVE_MENU_ITEM; // index of menu item
  public activeCol = DEFAULT_ACTIVE_MENU_ITEM; // index of column
  public flyOutHeight?: number;
  public shouldOpen: boolean;

  @Input() colWidth: number;

  @Output() dropdownToggle = new EventEmitter<boolean>();

  @Output() pushGtmTag = new EventEmitter<object>();

  constructor() {
    this.dropdownToggle$
      .pipe(debounceTime(TOGGLE_DELAY))
      .subscribe((shouldOpen: boolean) => {
        this.shouldOpen = shouldOpen;
        this.dropdownToggle.emit(shouldOpen);
      });
    this.clearSelection$
      .pipe(debounceTime(MOUSE_EVENTS_DELAY))
      .subscribe((shouldClearSelection) => {
        if (shouldClearSelection) {
          this.setActiveItemAndCol();
        }
      });
  }

  /**
   * Set the active menu item and column for the 2nd level
   *
   * @param {number} col
   * @param {number} item
   * @memberof MenuFlyOutComponent
   */
  public setActiveItemAndCol(
    col: number = DEFAULT_ACTIVE_MENU_ITEM,
    item: number = DEFAULT_ACTIVE_MENU_ITEM,
    secondLevelSection?: HTMLDivElement, // offsetTop's needed for calculating correctly when the second level section is not on the first row
    subItemsAmount?: number
  ) {
    this.activeMenuItem = item;
    this.activeCol = col;
    this.flyOutHeight = this.calculateSubmenuHeight(
      secondLevelSection?.offsetTop || 0,
      subItemsAmount
    );
  }

  public menuItemOnMouseEvent(shouldShow: boolean): void {
    this.clearSelection$.next(!shouldShow);
    this.dropdownToggle$.next(shouldShow);
  }

  private calculateSubmenuHeight(
    secondLevelSectionOffsetTop: number,
    itemsAmount?: number
  ): number | undefined {
    return itemsAmount
      ? MENU_OFFSET + secondLevelSectionOffsetTop + itemsAmount * ITEM_HEIGHT
      : undefined;
  }

  public onGtmTagPush(event: MouseEvent, tag: object): void {
    this.pushGtmTag.emit(tag);
    event.stopPropagation();
  }

  public onMenuLinkClick(event: MouseEvent, tag: object): void {
    this.shouldOpen = false;
    this.onGtmTagPush(event, tag);
  }
}
