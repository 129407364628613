import { Injectable } from '@angular/core';
import { FontsService } from '@ibep/fe/web/core';
import {
  AbstractBackendService,
  AbstractEnvironmentService,
  AbstractLanguageService,
  AbstractStorageService,
  GetBibleMetadataResponse,
} from '@ibep/interfaces';
import { MONTH } from '@ibep/shared/util';
import { Observable, of } from 'rxjs';
import { map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { ConfigData } from './config.data';
import { Store, StoreSettings, StoreSettingsTTL } from './store';

@Injectable({
  providedIn: 'root',
})
export class BibleMetaData extends Store<any> {
  constructor(
    storage: AbstractStorageService,
    environment: AbstractEnvironmentService,
    backendService: AbstractBackendService,
    languageService: AbstractLanguageService,
    config: ConfigData,
    private readonly fontService: FontsService
  ) {
    super(storage, environment, backendService, languageService, {
      storeTtl: { default: 1 * MONTH } as StoreSettingsTTL,
      storeKey: 'bibleMetadata',
      brand$: config.getBrand(),
    } as StoreSettings);
  }

  public getBible({
    bibleId,
  }: {
    bibleId: string;
  }): Observable<GetBibleMetadataResponse> {
    this.remoteDataMap$[bibleId] = this.backendService
      .get<GetBibleMetadataResponse>({
        endpoint: `bibles/${bibleId}/metadata`,
        options: {
          headers: {
            'x-brand': this.brand.id,
            Authorization: 'Bearer anonymous',
            redisTTL: `${this.storeSettings.storeTtl.default}`,
          },
        },
      })
      .pipe(shareReplay(1));

    return this.localData$.pipe(
      take(1),
      switchMap((localData) => {
        // check if there is local data available
        if (localData?.[bibleId]) {
          return of(localData[bibleId]);
        }
        // if no local data, fetch data from api
        return this.remoteDataMap$[bibleId].pipe(
          tap((remoteData) => {
            // save data from api to the store
            this.setState(
              {
                [bibleId]: remoteData,
              },
              true
            );
          })
        );
      }),
      map((res: any) => {
        // sort testaments by api provided order
        res.data.testaments = res.data.testaments.sort(
          (a: any, b: any) => a.order - b.order
        );
        return res;
      }),
      tap((res: any) => {
        if (res.data.language?.id === 'grc') {
          this.fontService.loadWebfont('greek');
        }
        if (res.data.language?.id === 'hbo') {
          this.fontService.loadWebfont('hebrew');
        }
      })
    ) as Observable<GetBibleMetadataResponse>;
  }
}
