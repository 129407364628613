import { Component, Input } from '@angular/core';

@Component({
  selector: 'ibep-validation-message',
  templateUrl: './validation-message.component.html',
})
export class ValidationMessageComponent {
  @Input() message: string;
  @Input() type: 'error' | 'success' | 'info' = 'error';
}
