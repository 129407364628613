import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { BibleService } from '@ibep/fe/shared/bible';
import { ConfigData, ChapterData } from '@ibep/fe/shared/data';
import {
  Bible,
  Config,
  GetChapterStudyContentResponse,
} from '@ibep/interfaces';
import { Observable, Subscription, forkJoin } from 'rxjs';

@Component({
  selector: 'ibep-bible-content-container',
  templateUrl: './bible-content-container.component.html',
})
export class BibleContentContainerComponent implements OnInit, OnDestroy {
  @Input() props: {
    passageId: string;
    bibleId: string;
    bibleAbbr: string;
    passage?: any;
    counter?: number;
    id?: string;
    limit?: number;
  };

  @Input() showLinkToReader = false;
  @Input() showBibleTitle = true;

  public bibleContentArray: any[] = [];
  public isBrowser: boolean;
  public scriptDirection: 'LTR' | 'RTL';
  public scriptLanguage: string | undefined;
  public error = false;
  public linkToReader: string;
  public label: string;

  public readerState = {
    selectedBibles: {},
    readerOptions: {
      showVerseNumbers: true,
      showFootnotes: false,
      showCrossReferences: false,
      showUserNotes: false,
      showUserHighlights: false,
    },
  };

  private _subscriptions: Subscription[] = [];
  private _isRendered: boolean = false;
  private chapters$: Observable<GetChapterStudyContentResponse>[];

  constructor(
    private readonly _chapterData: ChapterData,
    private readonly _configData: ConfigData,
    private readonly _bibleService: BibleService,
    private readonly _ref: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this._subscriptions.push(
      this._chapterData.EmitGetChapter$.subscribe((id) => {
        if (id === this.props.id) {
          if (!this._isRendered) {
            this.renderChapter();
          }
        }
      })
    );

    // if no bibleId provided
    if (!this.props.bibleId && this.props.bibleAbbr) {
      this.props.bibleId = this._bibleService.getBibleIdFromAbbr(
        this.props.bibleAbbr
      );
    }
    // if no bible abbreviation provided
    if (!this.props.bibleAbbr && this.props.bibleId) {
      this.props.bibleAbbr = this._bibleService.getBibleAbbrFromId(
        this.props.bibleId
      );
    }

    if (!this.props.passage?.bible_translation) {
      this.props.passage = {
        ...this.props.passage,
        bible_translation: this.props.bibleAbbr,
      };
    }

    // GEN.25.29-GEN.25.34
    // from_book
    if (!this.props.passage.from_book) {
      this.props.passage.from_book = this.props.passageId
        ?.split('-')[0]
        ?.split('.')[0];
    }
    // from_chapter
    if (!this.props.passage.from_chapter) {
      this.props.passage.from_chapter = this.props.passageId
        ?.split('-')[0]
        ?.split('.')[1];
    }
    // from_verse
    if (!this.props.passage.from_verse) {
      this.props.passage.from_verse = this.props.passageId
        ?.split('-')[0]
        ?.split('.')[2];
    }
    // toBook
    if (!this.props.passage.toBook) {
      this.props.passage.toBook = this.props.passageId
        ?.split('-')[1]
        ?.split('.')[0];
    }
    // toChapter
    if (!this.props.passage.toChapter) {
      this.props.passage.toChapter = this.props.passageId
        ?.split('-')[1]
        ?.split('.')[1];
    }
    // toVerse
    if (!this.props.passage.toVerse) {
      this.props.passage.toVerse = this.props.passageId
        ?.split('-')[1]
        ?.split('.')[2];
      this.props.passage.to_verse = this.props.passageId
        ?.split('-')[1]
        ?.split('.')[2];
    }

    // get the chapter IDs
    let chapterIds = [
      this.props.passageId.split('-')[0].split('.').slice(0, 2).join('.'),
    ];

    if (
      Number(this.props.passage.from_chapter) <
      Number(this.props.passage.toChapter)
    ) {
      chapterIds.push(
        this.props.passageId.split('-')[1].split('.').slice(0, 2).join('.')
      );

      chapterIds = this._bibleService.getChapterRange(
        chapterIds[0],
        chapterIds[1]
      );
    }

    // get the chapters data
    this.chapters$ = chapterIds.map((chapterId: string) => {
      return this._chapterData.getChapter({
        bibleId: this.props.bibleId,
        chapterId,
      });
    });

    this.readerState.selectedBibles = {
      bibleAbbrs: [this.props.bibleAbbr],
      bibleIds: [this.props.bibleId],
    };

    this.bibleContentArray = [];

    if (
      !Number.isInteger(this.props?.limit) ||
      Number(this.props?.counter) < Number(this.props?.limit)
    ) {
      this.renderChapter();
    }

    // check if we are in the browser
    this._subscriptions.push(
      this._configData.getConfig().subscribe((config: Config) => {
        this.isBrowser = config.platform === 'browser';
        this.scriptDirection =
          config.bibles.find((bible: Bible) => {
            if (this.props.bibleId) {
              return bible.id === this.props.bibleId;
            } else {
              return bible.abbreviation === this.props.bibleAbbr;
            }
          })?.language?.scriptDirection || 'LTR';

        this.scriptLanguage = config.bibles.find((bible: Bible) => {
          if (this.props.bibleId) {
            return bible.id === this.props.bibleId;
          } else {
            return bible.abbreviation === this.props.bibleAbbr;
          }
        })?.language?.id;
      })
    );
  }

  renderChapter() {
    this._subscriptions.push(
      forkJoin(this.chapters$).subscribe({
        next: (responseArray: GetChapterStudyContentResponse[]) => {
          this.error = false;

          responseArray.forEach(
            (res: GetChapterStudyContentResponse, index: number) => {
              // we fetched the complete chapter, but we only need the part from the verse range
              let activeverse = false;
              if (
                (index === 0 &&
                  this.props.passage.from_verse !== 'null' &&
                  this.props.passage.from_verse) ||
                (index === responseArray.length - 1 &&
                  this.props.passage.toVerse !== 'null' &&
                  this.props.passage.toVerse)
              ) {
                const filteredChapter = res.data.chapter.content
                  .map((passageItem, passageIndex: number) => {
                    const passage = {
                      ...passageItem,
                      title: res.data.chapter.title,
                    };
                    if (passageItem.content && passageItem.style !== 'r') {
                      // we need to destructure array not to loose data for bible reader after filtering
                      // also we patch the headers data (verseId field) to render them for verse ranges
                      passage.content = passageItem.content.map(
                        (contentItem: any) => {
                          const contentData = { ...contentItem };
                          if (
                            contentData.type === 'text' &&
                            !contentData.verseId
                          ) {
                            contentData.verseId =
                              res.data.chapter.content[
                                passageIndex + 1
                              ]?.content?.find((item: any) => item.verseId)
                                ?.verseId ||
                              res.data.chapter.content[
                                passageIndex + 2
                              ]?.content?.find((item: any) => item.verseId)
                                ?.verseId;
                          }

                          return contentData;
                        }
                      );
                    }

                    // check if the passage is part of the verse range
                    if (
                      passage.content?.some((part: any) => {
                        return this.isVerseInRange(
                          part.verseId,
                          index === 0
                            ? this.props.passage.from_verse
                            : undefined,
                          this.props.passage.toVerse &&
                            this.props.passage.toVerse !== 'null' &&
                            this.props.passage.toVerse !==
                              this.props.passage.from_verse
                            ? index === responseArray.length - 1
                              ? this.props.passage.toVerse
                              : undefined
                            : this.props.passage.from_verse
                        );
                      })
                    ) {
                      // if the passage is part of the verse range, we need to filter the content and set it as an active verse
                      if (!activeverse) {
                        activeverse = true;
                      }
                      passage.content = passage.content.filter((part: any) => {
                        // condition for the single verse range like GEN.1.6
                        if (
                          !this.props.passageId.includes('-') &&
                          this.props.passageId.split('.')[2]
                        ) {
                          return part.verseId
                            ? part.verseId === this.props.passageId
                            : passage;
                        } else {
                          // condition for the verse ranges including more than one verse
                          return part.verseId
                            ? this.isVerseInRange(
                                part.verseId,
                                index === 0
                                  ? this.props.passage.from_verse
                                  : undefined,
                                this.props.passage.toVerse &&
                                  this.props.passage.toVerse !== 'null' &&
                                  this.props.passage.toVerse !==
                                    this.props.passage.from_verse
                                  ? index === responseArray.length - 1
                                    ? this.props.passage.toVerse
                                    : undefined
                                  : this.props.passage.from_verse
                              )
                            : passage;
                        }
                      });
                      return passage;
                    }
                    // fix for some passage that have no content
                    if (passage.content === undefined && activeverse) {
                      return passage;
                    }
                    activeverse = false;
                    return false;
                  })
                  .filter((passage) => passage);
                this.bibleContentArray.push(filteredChapter);
              } else {
                const filteredChapter = res.data.chapter.content
                  .map((passageItem) => {
                    const passage = {
                      ...passageItem,
                      title: res.data.chapter.title,
                    };
                    return passage;
                  })
                  .filter((passage) => passage);
                this.bibleContentArray.push(filteredChapter);
              }
            }
          );

          // label
          this.label = `${responseArray[0].data.chapter.title}${
            // eslint-disable-next-line no-nested-ternary
            this.props.passage.from_verse &&
            this.props.passage.from_verse !== 'null'
              ? // eslint-disable-next-line no-nested-ternary
                this.props.passage.from_chapter === this.props.passage.toChapter
                ? `:${this.props.passage.from_verse}${
                    this.props.passage.toVerse &&
                    this.props.passage.toVerse !== 'null' &&
                    this.props.passage.toVerse !== this.props.passage.from_verse
                      ? `-${this.props.passage.toVerse}`
                      : ''
                  }`
                : this.props.passage.toVerse &&
                  this.props.passage.toVerse !== this.props.passage.from_verse
                ? `:${this.props.passage.from_verse}-${this.props.passage.toChapter}:${this.props.passage.toVerse}`
                : `:${this.props.passage.from_verse}`
              : this.props.passage.toChapter
              ? `-${this.props.passage.toChapter}`
              : ''
          }`;
          // create link to bible reader
          this.linkToReader = `/ROUTES.bible/${
            this.props.passage.bible_translation
          }/${this.props.passage.from_book}.${this.props.passage.from_chapter}${
            // eslint-disable-next-line no-nested-ternary
            this.props.passage.from_verse &&
            this.props.passage.from_verse !== 'null'
              ? this.props.passage.from_chapter ===
                  this.props.passage.toChapter &&
                this.props.passage.toVerse &&
                this.props.passage.toVerse !== 'null' &&
                this.props.passage.toVerse !== this.props.passage.from_verse
                ? `.${this.props.passage.from_verse}-${this.props.passage.from_book}.${this.props.passage.from_chapter}.${this.props.passage.toVerse}`
                : `.${this.props.passage.from_verse}`
              : ''
          }`;

          this._ref.detectChanges();
          this._isRendered = true;
        },
        error: () => {
          this.error = true;
          this._ref.detectChanges();
        },
      })
    );
  }

  isVerseInRange(
    verseId: string,
    fromVerse?: string,
    toVerse?: string
  ): boolean {
    if (verseId?.includes('-')) {
      const chunks = verseId.split('-');
      return (
        this.isVerseInRange(chunks[0], fromVerse, toVerse) &&
        this.isVerseInRange(chunks[1], fromVerse, toVerse)
      );
    }

    const verseIdNumber = Number(verseId?.split('.')[2]);
    const fromVerseNumber = Number(fromVerse);
    const toVerseNumber = Number(toVerse);

    if (fromVerseNumber && toVerseNumber) {
      return verseIdNumber >= fromVerseNumber && verseIdNumber <= toVerseNumber;
    } else if (fromVerseNumber) {
      return verseIdNumber >= fromVerseNumber;
    } else if (toVerseNumber) {
      return verseIdNumber <= toVerseNumber;
    } else {
      return false;
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
