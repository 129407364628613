<nav
  class="lg:h-[72px] hidden lg:flex items-center flex-grow justify-end lg:flex-1"
>
  <!-- Menu items  -->
  <!-- First level  -->
  <ng-container *ngFor="let menuItem of menuItems.items; let i1 = index">
    <!--We need that after block only for Mac PC-->
    <div
      class="group inline-block after:block after:h-2 after:content-[' '] lg:h-[72px] border-b-4 border-transparent hover:border-secondary transition ease-in duration-150 flex items-center"
      (mouseleave)="menuItem.items.length && menuItemOnMouseEvent(false)"
      [ngClass]="{
        'menu-item-container': shouldOpen
      }"
      (click)="
        onGtmTagPush($event, {
          event: 'menu_lvl1_click',
          link_text: menuItem.title,
          link_urk: menuItem.link
        })
      "
    >
      <div
        class="h-full"
        (mouseover)="menuItem.items.length && menuItemOnMouseEvent(true)"
      >
        <!-- Internal link  -->
        <a
          *ngIf="menuItem.linkType === 'internal'"
          [routerLink]="menuItem.link | localize"
          [title]="menuItem.title"
          [ngClass]="{
            disabled: menuItem.items.length
          }"
          class="px-3 h-full flex align-middle text-sm cursor-pointer border-b-4 border-transparent"
        >
          <button type="button" class="button item inline-flex items-start">
            <div class="pt-[26px]">
              <span [innerHTML]="menuItem.title"></span>
              <ibep-chevron-down-svg
                *ngIf="menuItem.items.length"
                svgStyles="w-3 h-3 ml-2"
              ></ibep-chevron-down-svg>
            </div>
          </button>
        </a>

        <!-- External link  -->
        <a
          *ngIf="menuItem.linkType === 'external'"
          target="_blank"
          [href]="menuItem.link"
          [title]="menuItem.title"
          [ngClass]="{
            disabled: menuItem.items.length
          }"
          class="px-3 h-full flex align-middle text-sm cursor-pointer border-b-4 border-transparent"
        >
          <button type="button" class="button item inline-flex items-start">
            <div class="pt-[26px]">
              <span [innerHTML]="menuItem.title"></span>
              <ibep-chevron-down-svg
                *ngIf="menuItem.items.length"
                svgStyles="w-3 h-3 ml-2"
              ></ibep-chevron-down-svg>
            </div>
          </button>
        </a>
        <div
          *ngIf="menuItem.items.length"
          class="pointer-events-none flyout absolute z-30 inset-0 top-[72px] h-100vh w-full bg-grayDark bg-opacity-20 transform shadow-lg"
        >
          <div
            id="trans"
            class="pointer-events-auto border-t pb-4 opacity-50 group-hover:opacity-100 transition-opacity duration-1000"
          >
            <div class="bg-white">
              <div
                #flyOutContainer
                class="relative max-w-container mx-auto"
                [style.min-height.px]="flyOutHeight"
              >
                <div class="grid gap-y-3 grid-cols-4 pb-9">
                  <!-- Second level -->
                  <ng-container
                    *ngFor="let childItem of menuItem.items; let i2 = index"
                  >
                    <div
                      class="pt-9 text-xs text-dark relative"
                      #secondLevelSection
                      [ngClass]="{
                        'bg-gray-400': menuStyle === 'bgGray'
                      }"
                    >
                      <div
                        [ngClass]="{
                          hidden:
                            (i2 === activeCol + 1 && activeCol !== 3) ||
                            (i2 === 2 && activeCol === 3)
                        }"
                      >
                        <h3
                          class="pb-2 px-6 border-l-4 border-transparent font-bold tracking-wider"
                          *ngIf="childItem.title"
                        >
                          <span [innerHTML]="childItem.title"></span>
                        </h3>
                        <div>
                          <!-- Third level -->
                          <ng-container
                            *ngFor="
                              let subChildItem of childItem.items;
                              let i3 = index
                            "
                          >
                            <!-- menu item with subitems -->
                            <a
                              *ngIf="subChildItem.items.length"
                              (mouseover)="
                                setActiveItemAndCol(
                                  i2,
                                  i3,
                                  secondLevelSection,
                                  subChildItem.items.length
                                )
                              "
                              routerLinkActive="font-bold"
                              [title]="subChildItem.title"
                              class="group flex items-center py-2 w-full cursor-pointer px-6"
                              [ngClass]="{
                                'text-primary bg-primary bg-opacity-[0.06] border-secondary':
                                  activeMenuItem === i3 && activeCol === i2,
                                'pl-7': activeMenuItem !== i3 || i2 === 3,
                                'border-r-4':
                                  activeMenuItem === i3 &&
                                  activeCol === i2 &&
                                  i2 === 3,
                                'border-l-4':
                                  activeMenuItem === i3 &&
                                  activeCol === i2 &&
                                  i2 !== 3
                              }"
                            >
                              <span *ngIf="subChildItem.title">
                                <span [innerHTML]="subChildItem.title"></span>
                              </span>
                              <span *ngIf="subChildItem.items.length">
                                <ibep-chevron-right-svg
                                  class="text-grayLight"
                                  svgStyles="w-4 h-4 pl-1"
                                ></ibep-chevron-right-svg>
                              </span>
                            </a>
                            <!-- Section title  -->
                            <h4
                              *ngIf="subChildItem.linkType === 'sectionTitle'"
                              class="group flex items-center py-2 font-bold px-6 border-l-4 border-transparent"
                            >
                              <span [innerHTML]="subChildItem.title"></span>
                            </h4>
                            <!-- Internal link  -->
                            <a
                              *ngIf="
                                !subChildItem.items.length &&
                                subChildItem.linkType === 'internal'
                              "
                              [title]="subChildItem.title"
                              (mouseover)="setActiveItemAndCol()"
                              [routerLink]="subChildItem.link | localize"
                              (click)="
                                onMenuLinkClick($event, {
                                  event: 'menu_lvl2_click',
                                  link_text: subChildItem.title,
                                  link_urk: subChildItem.link
                                })
                              "
                              class="group flex items-center py-2 hover:text-secondary px-6 border-l-4 border-transparent"
                            >
                              <span [innerHTML]="subChildItem.title"></span>
                            </a>
                            <!-- external link  -->
                            <a
                              *ngIf="
                                !subChildItem.items.length &&
                                subChildItem.linkType === 'external'
                              "
                              (mouseover)="setActiveItemAndCol()"
                              [href]="subChildItem.link"
                              (click)="
                                onMenuLinkClick($event, {
                                  event: 'menu_lvl2_click',
                                  link_text: subChildItem.title,
                                  link_urk: subChildItem.link
                                })
                              "
                              target="_blank"
                              [title]="subChildItem.title"
                              class="group flex items-center py-2 hover:text-secondary px-6 border-l-4 border-transparent"
                            >
                              <span [innerHTML]="subChildItem.title"></span>
                            </a>

                            <!-- Fourth level  -->
                            <ng-container
                              *ngIf="activeMenuItem === i3 && activeCol === i2"
                            >
                              <div
                                class="absolute bg-primary bg-opacity-[0.06] top-0 h-full transition-all z-50"
                                [style.left.px]="
                                  activeCol !== 3 ? colWidth : unset
                                "
                                [style.right.px]="
                                  activeCol === 3 ? colWidth : unset
                                "
                                [style.width.px]="colWidth"
                              >
                                <div class="py-7 px-6">
                                  <div class="pt-2">
                                    <ng-container
                                      *ngFor="
                                        let subsubChildItem of subChildItem.items;
                                        let i4 = index
                                      "
                                    >
                                      <!-- Section title  -->
                                      <h4
                                        *ngIf="
                                          subsubChildItem.linkType ===
                                          'sectionTitle'
                                        "
                                        class="group flex items-center py-2 font-bold"
                                      >
                                        <span
                                          [innerHTML]="subsubChildItem.title"
                                        ></span>
                                      </h4>
                                      <!-- Internal link  -->
                                      <a
                                        *ngIf="
                                          subsubChildItem.linkType ===
                                          'internal'
                                        "
                                        [title]="subsubChildItem.title"
                                        [routerLink]="
                                          subsubChildItem.link | localize
                                        "
                                        (click)="
                                          onMenuLinkClick($event, {
                                            event: 'menu_lvl3_click',
                                            link_text: subsubChildItem.title,
                                            link_urk: subsubChildItem.link
                                          })
                                        "
                                        class="flex items-center py-2 rounded-md hover:text-secondary"
                                      >
                                        <span
                                          [innerHTML]="subsubChildItem.title"
                                        ></span>
                                      </a>
                                      <!-- External link  -->
                                      <a
                                        *ngIf="
                                          subsubChildItem.linkType ===
                                          'external'
                                        "
                                        [href]="subsubChildItem.link"
                                        (click)="
                                          onMenuLinkClick($event, {
                                            event: 'menu_lvl3_click',
                                            link_text: subsubChildItem.title,
                                            link_urk: subsubChildItem.link
                                          })
                                        "
                                        [title]="subsubChildItem.title"
                                        target="_blank"
                                        class="flex items-center py-2 rounded-md hover:text-secondary"
                                      >
                                        <span
                                          [innerHTML]="subsubChildItem.title"
                                        ></span>
                                      </a>
                                    </ng-container>
                                  </div>
                                </div>
                              </div>
                            </ng-container>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</nav>
