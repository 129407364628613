<div class="alertTop w-full fixed top-0" [style.top.px]="i * 40">
  <input
    type="checkbox"
    class="hidden"
    [id]="'alert-' + notificationProps.type + i"
  />

  <label
    class="close cursor-pointer flex items-center justify-between w-full p-2 shadow text-white"
    [ngClass]="{
      'bg-primary': notificationProps.style === 'primary',
      'bg-secondary': notificationProps.style === 'secondary',
      'bg-success': notificationProps.style === 'success',
      'bg-error': notificationProps.style === 'error',
      'bg-warning': notificationProps.style === 'warning',
      'bg-info': notificationProps.style === 'info'
    }"
    title="close"
    [for]="'alert-' + notificationProps.type + i"
  >
    <p>{{ notificationProps.body }}</p>

    <svg
      ibepHideTillInit
      class="fill-current text-white"
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <path
        d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
      ></path>
    </svg>
  </label>
</div>
