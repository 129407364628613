import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, InjectionToken, PLATFORM_ID } from '@angular/core';
import { NAVIGATOR, WINDOW } from '@ibep/fe/shared/core';
import { AbstractServiceWorkerService, Environment } from '@ibep/interfaces';
import { BehaviorSubject, fromEvent, merge, Observable } from 'rxjs';
import { filter, first, map, startWith } from 'rxjs/operators';
import { Workbox } from 'workbox-window';

/**
 * The service worker service contains functionality related to the IBEP service worker for web applications.
 * This service is instantiated inside the APP_INITIALIZER, before the application bootstraps.
 * The service worker only works when a production build is running on a https connection.
 * @export
 * @class ServiceWorkerService
 */
@Injectable({
  providedIn: 'root',
})
export class ServiceWorkerService implements AbstractServiceWorkerService {
  private readonly _newVersionAvailable = new BehaviorSubject(false);
  private readonly _applicationUpdateOngoing = new BehaviorSubject(false);
  private readonly _serviceWorkerReady = new BehaviorSubject(false);

  public readonly newVersionAvailable$: Observable<boolean> =
    this._newVersionAvailable.asObservable();
  public readonly applicationUpdateOngoing$: Observable<boolean> =
    this._applicationUpdateOngoing.asObservable();

  public readonly applicationOnline$: Observable<boolean>;
  public runningStandAlone = false;

  private readonly sw = {
    file: '/sw.js',
    registerOptions: {},
    updateInterval: 4 * 60 * 60 * 1000, // every 4h
  };

  private swRegistration: ServiceWorkerRegistration | undefined;
  private serviceWorkerAvailable = false;
  private visible = true;

  constructor(
    @Inject(PLATFORM_ID)
    private platformId: InjectionToken<Record<string, unknown>>,
    @Inject(WINDOW) private readonly window: Window,
    @Inject(NAVIGATOR) private readonly navigator: Navigator,
    @Inject('ENVIRONMENT') private environment: Environment
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.applicationOnline$ = merge(
        fromEvent(this.window, 'offline'),
        fromEvent(this.window, 'online')
      ).pipe(
        map(() => this.navigator.onLine),
        startWith(this.navigator.onLine)
      );

      this.registerServiceWorker();
      this.checkRunningStandAlone();
      this.registerVisibileChangeListener();
    }
  }

  public isEnvironmentReady(): Promise<any> {
    const swReady$ = new Observable((observer) => {
      this._serviceWorkerReady
        .pipe(
          filter((serviceWorkerReady: boolean) => serviceWorkerReady),
          first()
        )
        .subscribe((_) => {
          observer.next(true);
          observer.complete();
        });
    });

    return swReady$.toPromise();
  }

  /**
   * This functions checks if there is a new version of the service worker/application available
   * If this is the case it will update the service worker. The new service worker goes into waiting state..
   * @private
   * @returns
   * @memberof ServiceWorkerService
   */
  private async checkForUpdate() {
    try {
      return await this.swRegistration?.update();
    } catch (err) {
      console.error('sw.js could not be updated', err);
    }
  }

  /**
   * This listener checks if a user is opening the browser tab where the application was already running (for a long time)
   * this is a good moment to check if there is an new version of the application available.
   * @private
   * @memberof ServiceWorkerService
   */
  private registerVisibileChangeListener() {
    if (isPlatformBrowser(this.platformId)) {
      fromEvent(document, 'visibilitychange').subscribe(async () => {
        this.visible = document.visibilityState === 'visible';
        // only check for update if the page becomes visible
        if (this.visible) {
          this.checkForUpdate();
        }
      });
    }
  }

  /**
   * Register the service worker in the browser and listen for service worker life cycle events.
   * https://web.dev/service-worker-lifecycle/
   * https://developer.chrome.com/docs/workbox/service-worker-lifecycle/
   *
   * @memberof ServiceWorkerService
   */
  private async registerServiceWorker() {
    // only do this in the browser
    if (isPlatformBrowser(this.platformId)) {
      // check if service workers are supported
      // only register service worker if enabled for this environment
      this.serviceWorkerAvailable =
        'serviceWorker' in this.navigator &&
        this.environment.serviceWorker.initServiceWorker;

      if (!this.serviceWorkerAvailable) {
        this._serviceWorkerReady.next(true);
        return;
      }

      // Create a new workbox instance
      const wb = new Workbox(this.sw.file, this.sw.registerOptions);

      wb.addEventListener('activated', async (event) => {
        if (!event.isUpdate) {
          // Send a message telling the service worker to claim the clients
          wb.messageSW({ type: 'CLIENTS_CLAIM' });

          // The service worker is ready, so we can bootstrap the app
          // this._serviceWorkerReady.next(true);
        }
      });

      // we use this waiting listener to check if new version of the service worker gets a waiting state.
      // If this is the case we activate this new service worker, and remove the old one
      wb.addEventListener('waiting', (event) => {
        this._newVersionAvailable.next(true);
        // let anybody interested know we are updating the application
        this._applicationUpdateOngoing.next(true);
        wb.messageSkipWaiting();
      });

      // Add a listener for when a new service worker becomes actived
      wb.addEventListener('controlling', (event) => {
        this._applicationUpdateOngoing.next(false);
        this._serviceWorkerReady.next(true);
        // The new service worker is activated and the precached assets should all be available now,
        // so we can finally reload the page
        window.location.reload();
      });

      // register the service worker in the browser
      try {
        this.swRegistration = await wb.register();
        // Check on an interval if there is a new version of the application available
        setInterval(async () => {
          this.checkForUpdate();
        }, this.sw.updateInterval);

        if (this.navigator.serviceWorker.controller) {
          this._serviceWorkerReady.next(true);
        }
      } catch (e) {
        console.error('error registering service worker', e);
      }
    }
  }

  /**
   * This functions checks if the application is running in standalone mode or in a browser tab
   *
   * @private
   * @memberof ServiceWorkerService
   */
  private checkRunningStandAlone(): void {
    // only do this in the browser
    if (isPlatformBrowser(this.platformId) && 'matchMedia' in window) {
      if ((this.navigator as any).standalone) {
        console.info('SW Launched: Installed (iOS)');
        this.runningStandAlone = true;
      } else if (this.window.matchMedia('(display-mode: standalone)').matches) {
        console.info('SW Launched: Installed');
        this.runningStandAlone = true;
      } else {
        console.info('SW Launched: Browser Tab');
      }
    }
  }
}
