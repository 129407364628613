<div
  class="relative group h-full min-h-[20rem] max-h-[23rem] lg:w-auto lg:basis-full bg-white border border-grayExtraLight rounded-lg hover:shadow-[0_1.25rem_1.25rem_-1rem_rgba(0,0,0,0.1)] overflow-hidden"
  [ngClass]="{
    'w-[290px] xl:w-[300px]': !autoWidth,
    'w-auto': autoWidth
  }"
>
  <ibep-link
    [linkProps]="{
      link:
        blockItemProps.linkType === 'external'
          ? blockItemProps.linkExternal
          : blockItemProps.linkInternal === '/slug' &&
            blockItemProps.postType === 'post'
          ? ('ROUTES.post' | translate) + '//' + blockItemProps.slug
          : blockItemProps.linkInternal,
      isExternalLink: blockItemProps.linkType === 'external'
    }"
  >
    <ibep-image-picture
      *ngIf="blockItemProps.image"
      [imgStyles]="'aspect-video w-full object-cover'"
      [image]="blockItemProps.image"
      [containerWidth]="300"
    ></ibep-image-picture>

    <h6
      class="mx-4 mt-3 text-[19px] leading-6 font-bold text-grayDark"
      [innerHTML]="blockItemProps.title"
    ></h6>

    <p
      *ngIf="blockItemProps.teaserDescription || blockItemProps.description"
      class="mx-4 mt-2 mb-14 text-grayLight text-xs leading-6 tracking-wide"
      [innerHTML]="
        blockItemProps.teaserDescription || blockItemProps.description
      "
    ></p>
    <div class="grad absolute bottom-0 w-full h-[80px]"></div>
    <div
      class="absolute bottom-0 h-10 w-full mx-3 pb-4 text-xs text-secondary group-hover:text-primary font-bold"
    >
      <ibep-arrow-right-svg
        svgStyles="w-6 h-6"
        class="inline"
      ></ibep-arrow-right-svg>
      <span
        class="group-hover:underline group-hover:text-primary"
        *ngIf="
          blockItemProps.type === 'default' ||
          blockItemProps.type === 'podcast' ||
          !blockItemProps.type
        "
        >{{ 'ReadMore' | translate }}</span
      >
      <span
        class="group-hover:underline group-hover:text-primary"
        *ngIf="blockItemProps.type === 'video'"
        >{{ 'WatchVideo' | translate }}</span
      >
      <span
        class="group-hover:underline group-hover:text-primary"
        *ngIf="blockItemProps.type === 'audio'"
        >{{ 'ListenToAudio*' | translate }}
      </span>
    </div>
    <!-- Audio icon  -->
    <div
      *ngIf="blockItemProps.type === 'audio'"
      class="absolute w-8 h-8 top-2 right-2 bg-black bg-opacity-40 rounded-md text-white"
    >
      <span class="top-[1px] left-[5px] absolute">
        <ibep-audio-svg svgStyles="w-6 h-6"></ibep-audio-svg>
      </span>
    </div>

    <!-- Podcast icon  -->
    <div
      *ngIf="blockItemProps.type === 'podcast'"
      class="absolute w-8 h-8 top-2 right-2 bg-black bg-opacity-40 rounded-md text-white"
    >
      <span class="top-[1px] left-[5px] absolute">
        <ibep-podcast-svg svgStyles="w-4 h-4"></ibep-podcast-svg>
      </span>
    </div>
    <!-- Video icon  -->
    <div
      *ngIf="blockItemProps.type === 'video'"
      class="absolute w-8 h-8 top-2 right-2 bg-black bg-opacity-40 rounded-md text-white"
    >
      <span class="top-[1px] left-[5px] absolute"
        ><ibep-video-svg svgStyles="w-5 h-5"></ibep-video-svg
      ></span>
    </div>
  </ibep-link>
</div>
