<div class="w-full" *ngIf="isLoading">
  <ibep-loading-svg svgStyles="w-12 h-12 mx-auto "></ibep-loading-svg>
</div>

<div
  *ngIf="!isLoading"
  [ngClass]="{
    'blur-[2px]': isLoading,
    '!font-hebrew': scriptLanguage === 'hbo',
    '!font-greek': scriptLanguage === 'grc'
  }"
  [style.direction]="scriptDirection?.toLowerCase()"
>
  <ng-container *ngFor="let biblePassage of bibleContent; let i = index">
    <!-- Render the content template  -->
    <ng-container
      [ngTemplateOutlet]="contentTemplate"
      [ngTemplateOutletContext]="{
        biblePassageContent: biblePassage,
        i: i
      }"
    ></ng-container>
  </ng-container>
</div>

<!-- Content Template -->
<ng-template #contentTemplate let-biblePassage="biblePassageContent" let-i="i">
  <!-- First level: Chapter content: https://ubsicap.github.io/usx/structure.html#chapter-content -->
  <ng-template #passageTemplate>
    <ibep-bible-passage
      [isSearchPage]="isSearchPage"
      [isBrowser]="isBrowser"
      [isAuthenticated]="isAuthenticated"
      [item]="biblePassage.content"
      [passageIndex]="i"
      [chapter]="bibleContent"
      [customStyling]="customStyling"
      [hovered]="hovered"
      [selected]="selected"
      [highlighted]="highlighted"
      [flashed]="flashed"
      [bibleAbbr]="bibleAbbr"
      [readerState]="readerState"
      [studyContent]="studyContent"
      [userNotes]="userNotes"
      [userHighlights]="userHighlights"
      [createdNoteVerseOrgIds]="createdNoteVerseOrgIds"
      [isHighlightsPage]="isHighlightsPage"
      (pushGtmTag)="onGtmTagPush($event)"
      (userNoteDeleted)="deleteUserNote($event)"
      (userNoteUpdated)="updateUserNote($event)"
    ></ibep-bible-passage>
  </ng-template>

  <!-- Paragraph  -->
  <ng-container
    *ngIf="biblePassage.type === 'paragraph'"
    [ngSwitch]="biblePassage.style"
  >
    <!-- P -->
    <bible-p *ngSwitchCase="'p'">
      <ng-container *ngTemplateOutlet="passageTemplate"></ng-container>
    </bible-p>
    <!-- S  -->
    <bible-s *ngSwitchCase="'s'" [item]="biblePassage"
      ><ng-container
        innerContent
        *ngTemplateOutlet="passageTemplate"
      ></ng-container
    ></bible-s>
    <bible-s *ngSwitchCase="'s1'" [item]="biblePassage"
      ><ng-container
        innerContent
        *ngTemplateOutlet="passageTemplate"
      ></ng-container
    ></bible-s>
    <bible-s *ngSwitchCase="'s2'" [item]="biblePassage"
      ><ng-container
        innerContent
        *ngTemplateOutlet="passageTemplate"
      ></ng-container
    ></bible-s>
    <bible-s *ngSwitchCase="'s3'" [item]="biblePassage"
      ><ng-container
        innerContent
        *ngTemplateOutlet="passageTemplate"
      ></ng-container
    ></bible-s>
    <!-- Q  -->
    <bible-q *ngSwitchCase="'q'" [item]="biblePassage">
      <ng-container
        innerContent
        *ngTemplateOutlet="passageTemplate"
      ></ng-container>
    </bible-q>
    <bible-q *ngSwitchCase="'q1'" [item]="biblePassage">
      <ng-container
        innerContent
        *ngTemplateOutlet="passageTemplate"
      ></ng-container>
    </bible-q>
    <bible-q *ngSwitchCase="'q2'" [item]="biblePassage">
      <ng-container
        innerContent
        *ngTemplateOutlet="passageTemplate"
      ></ng-container>
    </bible-q>
    <bible-q *ngSwitchCase="'q3'" [item]="biblePassage">
      <ng-container
        innerContent
        *ngTemplateOutlet="passageTemplate"
      ></ng-container>
    </bible-q>
    <bible-q *ngSwitchCase="'q4'" [item]="biblePassage">
      <ng-container
        innerContent
        *ngTemplateOutlet="passageTemplate"
      ></ng-container>
    </bible-q>
    <!-- PM  -->
    <bible-pm *ngSwitchCase="'pm'">
      <ng-container *ngTemplateOutlet="passageTemplate"></ng-container
    ></bible-pm>
    <!-- PMC  -->
    <bible-pmc *ngSwitchCase="'pmc'"
      ><ng-container *ngTemplateOutlet="passageTemplate"></ng-container
    ></bible-pmc>
    <!-- PMO  -->
    <bible-pmo *ngSwitchCase="'pmo'"
      ><ng-container *ngTemplateOutlet="passageTemplate"></ng-container
    ></bible-pmo>
    <!-- PMR  -->
    <bible-pmr *ngSwitchCase="'pmr'"
      ><ng-container *ngTemplateOutlet="passageTemplate"></ng-container
    ></bible-pmr>
    <!-- PO TODO: added in usx 3.0, cannot find styles https://ubsicap.github.io/usx/parastyles.html#po -->
    <!-- TODO: could not find any documentation -->
    <bible-po *ngSwitchCase="'po'"
      ><ng-container *ngTemplateOutlet="passageTemplate"></ng-container
    ></bible-po>
    <!-- IMT -->
    <bible-imt *ngSwitchCase="'imt'" [item]="biblePassage.content">
      <ng-container
        innerContent
        *ngTemplateOutlet="passageTemplate"
      ></ng-container>
    </bible-imt>
    <bible-imt *ngSwitchCase="'imt1'" [item]="biblePassage.content">
      <ng-container
        innerContent
        *ngTemplateOutlet="passageTemplate"
      ></ng-container>
    </bible-imt>
    <bible-imt *ngSwitchCase="'imt2'" [item]="biblePassage.content">
      <ng-container
        innerContent
        *ngTemplateOutlet="passageTemplate"
      ></ng-container>
    </bible-imt>
    <bible-imt *ngSwitchCase="'imt3'" [item]="biblePassage.content">
      <ng-container
        innerContent
        *ngTemplateOutlet="passageTemplate"
      ></ng-container>
    </bible-imt>
    <bible-imt *ngSwitchCase="'imt4'" [item]="biblePassage.content">
      <ng-container
        innerContent
        *ngTemplateOutlet="passageTemplate"
      ></ng-container>
    </bible-imt>
    <!-- B  -->
    <bible-b *ngSwitchCase="'b'"></bible-b>
    <!-- CD  -->
    <bible-cd *ngSwitchCase="'cd'">
      <ng-container *ngTemplateOutlet="passageTemplate"></ng-container>
    </bible-cd>
    <!-- CL  -->
    <bible-cl *ngSwitchCase="'cl'">
      <ng-container *ngTemplateOutlet="passageTemplate"></ng-container>
    </bible-cl>
    <!-- CLS  -->
    <bible-cls *ngSwitchCase="'cls'">
      <ng-container *ngTemplateOutlet="passageTemplate"></ng-container>
    </bible-cls>
    <!-- D  -->
    <bible-d *ngSwitchCase="'d'">
      <ng-container *ngTemplateOutlet="passageTemplate"></ng-container>
    </bible-d>
    <!-- IEX  -->
    <bible-iex *ngSwitchCase="'iex'">
      <ng-container *ngTemplateOutlet="passageTemplate"></ng-container>
    </bible-iex>
    <!-- IP  -->
    <bible-ip *ngSwitchCase="'ip'">
      <ng-container *ngTemplateOutlet="passageTemplate"></ng-container>
    </bible-ip>
    <!-- TODO: could not find any documentation -->
    <bible-lh *ngSwitchCase="'lh'">
      <ng-container *ngTemplateOutlet="passageTemplate"></ng-container>
    </bible-lh>
    <!-- LI -->
    <bible-li
      *ngSwitchCase="'li'"
      [item]="biblePassage.content"
      [style]="biblePassage.style"
    >
      <ng-container *ngTemplateOutlet="passageTemplate"></ng-container>
    </bible-li>
    <bible-li
      *ngSwitchCase="'li1'"
      [item]="biblePassage.content"
      [style]="biblePassage.style"
    >
      <ng-container *ngTemplateOutlet="passageTemplate"></ng-container>
    </bible-li>
    <bible-li
      *ngSwitchCase="'li2'"
      [item]="biblePassage.content"
      [style]="biblePassage.style"
    >
      <ng-container *ngTemplateOutlet="passageTemplate"></ng-container>
    </bible-li>
    <bible-li
      *ngSwitchCase="'li3'"
      [item]="biblePassage.content"
      [style]="biblePassage.style"
    >
      <ng-container *ngTemplateOutlet="passageTemplate"></ng-container>
    </bible-li>
    <bible-li
      *ngSwitchCase="'li4'"
      [item]="biblePassage.content"
      [style]="biblePassage.style"
    >
      <ng-container *ngTemplateOutlet="passageTemplate"></ng-container>
    </bible-li>
    <!-- LIM TODO: could not find any documentation -->
    <bible-lim *ngSwitchCase="'lim'">
      <ng-container *ngTemplateOutlet="passageTemplate"></ng-container>
    </bible-lim>
    <!-- M -->
    <bible-m *ngSwitchCase="'m'">
      <ng-container *ngTemplateOutlet="passageTemplate"></ng-container>
    </bible-m>
    <!-- MI -->
    <bible-mi *ngSwitchCase="'mi'">
      <ng-container *ngTemplateOutlet="passageTemplate"></ng-container>
    </bible-mi>
    <!-- MR -->
    <bible-mr *ngSwitchCase="'mr'">
      <ng-container *ngTemplateOutlet="passageTemplate"></ng-container>
    </bible-mr>
    <!-- MS -->
    <bible-ms *ngSwitchCase="'ms'" [item]="biblePassage.content">
      <ng-container *ngTemplateOutlet="passageTemplate"></ng-container>
    </bible-ms>
    <bible-ms *ngSwitchCase="'ms1'" [item]="biblePassage.content">
      <ng-container *ngTemplateOutlet="passageTemplate"></ng-container>
    </bible-ms>
    <bible-ms *ngSwitchCase="'ms2'" [item]="biblePassage.content">
      <ng-container *ngTemplateOutlet="passageTemplate"></ng-container>
    </bible-ms>
    <bible-ms *ngSwitchCase="'ms3'" [item]="biblePassage.content">
      <ng-container *ngTemplateOutlet="passageTemplate"></ng-container>
    </bible-ms>
    <!-- MTE  TODO: could not find any styles https://ubsicap.github.io/usx/parastyles.html#mte-->
    <!-- <bible-mte *ngSwitchCase="'mte'"></bible-mte> -->
    <!-- PC -->
    <bible-pc *ngSwitchCase="'pc'">
      <ng-container *ngTemplateOutlet="passageTemplate"></ng-container>
    </bible-pc>
    <!-- PI -->
    <bible-pi *ngSwitchCase="'pi'" [item]="biblePassage.content">
      <ng-container *ngTemplateOutlet="passageTemplate"></ng-container>
    </bible-pi>
    <bible-pi *ngSwitchCase="'pi1'" [item]="biblePassage.content">
      <ng-container *ngTemplateOutlet="passageTemplate"></ng-container>
    </bible-pi>
    <bible-pi *ngSwitchCase="'pi2'" [item]="biblePassage.content">
      <ng-container *ngTemplateOutlet="passageTemplate"></ng-container>
    </bible-pi>
    <bible-pi *ngSwitchCase="'pi3'" [item]="biblePassage.content">
      <ng-container *ngTemplateOutlet="passageTemplate"></ng-container>
    </bible-pi>
    <!-- QA -->
    <bible-qa *ngSwitchCase="'qa'">
      <ng-container *ngTemplateOutlet="passageTemplate"></ng-container>
    </bible-qa>
    <!-- QC -->
    <bible-qc *ngSwitchCase="'qc'">
      <ng-container *ngTemplateOutlet="passageTemplate"></ng-container>
    </bible-qc>
    <!-- QD  TODO: could not find any documentation-->
    <bible-qd *ngSwitchCase="'qd'">
      <ng-container *ngTemplateOutlet="passageTemplate"></ng-container>
    </bible-qd>
    <!-- QM -->
    <bible-qm *ngSwitchCase="'qm'" [item]="biblePassage.content">
      <ng-container *ngTemplateOutlet="passageTemplate"></ng-container>
    </bible-qm>
    <bible-qm *ngSwitchCase="'qm1'" [item]="biblePassage.content">
      <ng-container *ngTemplateOutlet="passageTemplate"></ng-container>
    </bible-qm>
    <bible-qm *ngSwitchCase="'qm2'" [item]="biblePassage.content">
      <ng-container *ngTemplateOutlet="passageTemplate"></ng-container>
    </bible-qm>
    <bible-qm *ngSwitchCase="'qm3'" [item]="biblePassage.content">
      <ng-container *ngTemplateOutlet="passageTemplate"></ng-container>
    </bible-qm>
    <!-- QR -->
    <bible-qr *ngSwitchCase="'qr'">
      <ng-container *ngTemplateOutlet="passageTemplate"></ng-container>
    </bible-qr>
    <!-- R -->
    <bible-r *ngSwitchCase="'r'">
      <ng-container *ngTemplateOutlet="passageTemplate"></ng-container>
    </bible-r>
    <!-- SD TODO: could not find any documentation -->
    <bible-sd *ngSwitchCase="'sd'">
      <ng-container *ngTemplateOutlet="passageTemplate"></ng-container>
    </bible-sd>
    <!-- SP -->
    <bible-sp *ngSwitchCase="'sp'">
      <ng-container *ngTemplateOutlet="passageTemplate"></ng-container>
    </bible-sp>
    <!-- SR -->
    <bible-sr *ngSwitchCase="'sr'">
      <ng-container *ngTemplateOutlet="passageTemplate"></ng-container
    ></bible-sr>
    <!-- H -->
    <bible-h *ngSwitchCase="'h'">
      <ng-container *ngTemplateOutlet="passageTemplate"></ng-container>
    </bible-h>
    <!-- DEFAULT Switchcase -->
    <ng-container *ngSwitchDefault>
      <ng-container *ngTemplateOutlet="passageTemplate"></ng-container>
    </ng-container>
  </ng-container>

  <!-- Study Sidebar Content -->
  <ng-container *ngIf="biblePassage.type === 'study'">
    <ibep-expandable-section
      [contentType]="biblePassage.image ? 'image' : 'text'"
    >
      <ng-container
        *ngFor="let content of biblePassage.content.content; let i = index"
      >
        <ng-container
          [ngTemplateOutlet]="contentTemplate"
          [ngTemplateOutletContext]="{
            biblePassageContent: content
          }"
        ></ng-container>
      </ng-container>
    </ibep-expandable-section>
  </ng-container>

  <!-- Table  -->
  <ng-container *ngIf="biblePassage.type === 'table'">
    <table class="table-auto w-full text-2xs my-2">
      <tr
        *ngFor="let row of biblePassage.content"
        class="even:bg-grayExtraLight w-full"
      >
        <td
          *ngFor="let col of row.content"
          class="py-2 px-3"
          [ngClass]="{
            'font-bold text-xs': col.style === 'th1' || col.style === 'th2'
          }"
        >
          <ng-container *ngFor="let el of col.content">
            {{ el.content }}
          </ng-container>
        </td>
      </tr>
    </table>
  </ng-container>
</ng-template>
