<span [ngSwitch]="item.style">
  <h4 *ngSwitchCase="'s1'" [class]="item.style" class="!text-2lg">
    <ng-container [ngTemplateOutlet]="contentTemplate"></ng-container>
  </h4>
  <h5 *ngSwitchCase="'s2'" [class]="item.style" class="!text-lg">
    <ng-container [ngTemplateOutlet]="contentTemplate"></ng-container>
  </h5>
  <h6 *ngSwitchDefault [class]="item.style" class="!text-base">
    <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
  </h6>
</span>

<ng-template #contentTemplate> <ng-content></ng-content></ng-template>
