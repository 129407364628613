import { Component, OnInit, TemplateRef } from '@angular/core';
import { ModalService } from '@ibep/fe/web/core';
import { ModalOptions } from '@ibep/interfaces';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'ibep-modal-container',
  template: `<ibep-modal *ngIf="isOpen && template" [modalOptions]="options">
    <ng-container *ngTemplateOutlet="template"></ng-container>
  </ibep-modal>`,
})
export class ModalContainerComponent implements OnInit {
  public isOpen = false;
  public template?: TemplateRef<any>;
  public options: Partial<ModalOptions> = {};

  constructor(private modalService: ModalService) {}

  public ngOnInit() {
    this.modalService.isOpen$
      .pipe(
        switchMap((isOpen) => {
          this.isOpen = isOpen;
          if (isOpen) {
            return this.modalService.currentModal$;
          }

          return of({ template: undefined, options: {} });
        })
      )
      .subscribe((current) => {
        this.template = current.template;
        this.options = current.options;
      });
  }
}
