import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpBackendService } from '@ibep/fe/shared/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
  useClass: HttpBackendService,
})
export abstract class AbstractBackendService {
  public abstract get<T>({
    baseUrl,
    endpoint,
    options,
  }: {
    baseUrl?: string;
    endpoint: string;
    options?: {
      params?: any;
      headers?: { [header: string]: string | string[] };
      clearHeaders?: boolean;
    };
  }): Observable<T>;

  public abstract put<T>({
    baseUrl,
    endpoint,
    options,
  }: {
    baseUrl?: string;
    endpoint: string;
    options?: {
      body?: any;
      params?: HttpParams;
      headers?: { [header: string]: string | string[] };
    };
  }): Observable<T>;

  public abstract post<T>({
    baseUrl,
    endpoint,
    options,
  }: {
    baseUrl?: string;
    endpoint: string;
    options?: {
      body?: any;
      params?: HttpParams;
      headers?: { [header: string]: string | string[] };
      withCredentials?: boolean;
    };
  }): Observable<T>;

  public abstract delete<T>({
    baseUrl,
    endpoint,
    options,
  }: {
    baseUrl?: string;
    endpoint: string;
    options?: {
      body?: any;
      params?: HttpParams;
      headers?: { [header: string]: string | string[] };
    };
  }): Observable<T>;
}
