import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  BibleVerseItem,
  EbcItem,
  ReadingPlanOrEpisodeItem,
  SearchCategory,
  SiteContentSearchResult,
} from '@ibep/interfaces';

@Component({
  selector: 'ibep-search-result-items',
  templateUrl: './search-result-items.component.html',
  styleUrls: ['./search-result-items.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SearchResultItemsComponent {
  public categoryEnum = SearchCategory;
  public checkedItem: BibleVerseItem;

  @Input() items:
    | BibleVerseItem[]
    | SiteContentSearchResult[]
    | ReadingPlanOrEpisodeItem[]
    | EbcItem[]
    | any[];
  @Input() isAuthenticated: boolean;
  @Input() isPremium: boolean;
  @Input() isTablet: boolean;
  @Input() selectedBible: string;
  @Input() category: SearchCategory;
  @Input() query: string;
  @Input() CONTENT_CATEGORIES: SearchCategory[] = [];

  @Output() checkedVerse = new EventEmitter<BibleVerseItem>();
  @Output() clickedItem = new EventEmitter<
    BibleVerseItem | SiteContentSearchResult
  >();
  @Output() pushGtmTag = new EventEmitter<object>();

  public checkItem(event: MouseEvent, item: BibleVerseItem): void {
    if (this.checkedItem) {
      this.checkedItem.checked = false;
    }
    this.checkedItem = item;
    this.checkedItem.checked = true;
    this.checkedVerse.emit(this.checkedItem);

    this.onGtmTagPush({
      event: 'button_click',
      button_name: 'quick_view',
      button_text: 'Quick view',
    });

    event.stopPropagation();
  }

  public clickItem(item: BibleVerseItem | SiteContentSearchResult): void {
    this.clickedItem.emit(item);
  }

  public onHover(item: BibleVerseItem, state: boolean): void {
    item.hover = state;
  }

  public onGtmTagPush(tag: object): void {
    this.pushGtmTag.emit(tag);
  }
}
