import { Component, OnInit } from '@angular/core';
import {
  AbstractNotificationService,
  NotificationInterface,
} from '@ibep/interfaces';

@Component({
  selector: 'ibep-notifications-container',
  templateUrl: './notifications-container.component.html',
})
export class NotificationsContainerComponent implements OnInit {
  toasts: NotificationInterface[] = [];

  alerts: NotificationInterface[] = [];

  constructor(
    private readonly _notificationsService: AbstractNotificationService
  ) {}

  ngOnInit(): void {
    // subscribe to notifications
    this._notificationsService.notification$.subscribe((notification) => {
      if (notification.type === 'toast') {
        this.toasts = [notification, ...this.toasts];
        setTimeout(() => this.toasts.pop(), notification.delay || 6000);
      } else {
        this.alerts = [notification, ...this.alerts];
        setTimeout(() => this.alerts.pop(), notification.delay || 6000);
      }
    });
  }
}
