import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { fromEvent, map } from 'rxjs';

@Component({
  selector: 'ibep-scroll-progress-bar',
  templateUrl: './scroll-progress-bar.component.html',
})
export class ScrollProgressBarComponent implements OnInit {
  progressValue = 0;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(DOCUMENT)
    public document: Document
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      fromEvent(window, 'scroll')
        .pipe(
          map(() => {
            const winScroll =
              this.document.body.scrollTop ||
              this.document.documentElement.scrollTop;
            const height =
              this.document.documentElement.scrollHeight -
              this.document.documentElement.clientHeight;
            return Math.round((winScroll / height) * 100);
          })
        )
        .subscribe((val) => {
          this.progressValue = val;
        });
    }
  }
}
