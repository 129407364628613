import {
  Directive,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
} from '@angular/core';

interface SwiperOptions {
  isEnabled: boolean;
}

@Directive({ selector: '[ibepHorizontalSwiper]' })
export class HorizontalSwiperDirective {
  private readonly DEFAULT_OPTIONS: SwiperOptions = { isEnabled: true };

  @Input() swiperEnabled?: boolean;

  private get isEnabled() {
    if (this.swiperEnabled === true || this.swiperEnabled === false) {
      return this.swiperEnabled;
    }

    return this.DEFAULT_OPTIONS.isEnabled;
  }

  @Output() swipeLeftEvent = new EventEmitter<void>();
  @Output() swipeRightEvent = new EventEmitter<void>();

  /**
   * Used to enable vertical scrolling when the directive is added
   *
   * @private
   * @memberof HorizontalSwiperDirective
   */
  @HostBinding('style.touch-action') private styling = 'pan-y';

  @HostListener('swipeLeft', ['$event'])
  onSwipeLeft(event: any) {
    if (event && this.isEnabled) {
      this.swipeLeftEvent.emit();
    }
  }

  @HostListener('swipeRight', ['$event'])
  onSwipeRight(event: any) {
    if (event && this.isEnabled) {
      this.swipeRightEvent.emit();
    }
  }
}
