import { Component, Input } from '@angular/core';
import { TeaserItem } from '@ibep/interfaces';

/**
 * A Single Block Item, which could contain a (blog | news) post
 */
@Component({
  selector: 'ibep-block-item',
  templateUrl: './block-item.component.html',
  styleUrls: ['./block-item.component.scss'],
})
export class BlockItemComponent {
  @Input() blockItemProps: TeaserItem;
  @Input() autoWidth: boolean;
  @Input() horizontalScroll: boolean;
  @Input() isMobile: boolean;
}
