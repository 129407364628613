import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NotificationInterface } from '@ibep/interfaces';

@Component({
  selector: 'ibep-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent {
  @Input() notificationProps: NotificationInterface = <NotificationInterface>{};
  @Input() i = 0;

  @Output() remove = new EventEmitter<number>();
}
