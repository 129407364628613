import { Component, Input } from '@angular/core';

@Component({
  selector: 'ibep-chevron-up-svg',
  template: `
    <svg
      ibepHideTillInit
      xmlns="http://www.w3.org/2000/svg"
      class="inline m-auto"
      fill="currentColor"
      [ngClass]="svgStyles"
      viewBox="0 0 16 16"
    >
      <path
        fill-rule="evenodd"
        d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"
      />
    </svg>
  `,
  styles: [],
})
export class ChevronUpComponent {
  @Input() svgStyles: string;
}
