import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { HttpBackendService } from '@ibep/fe/shared/core';
import { FeWebComponentsModule } from '@ibep/fe/web/components';
import {
  AbstractAuthService,
  AbstractBackendService,
  AbstractEnvironmentService,
} from '@ibep/interfaces';
import { TranslateModule } from '@ngx-translate/core';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { AuthService } from './services/auth.service';

export function apiAuthBaseUrlFactory(
  abstractEnvironmentService: AbstractEnvironmentService
) {
  return abstractEnvironmentService.authBaseUrl;
}

export function apiGlobalHeadersFactory() {
  return {};
}
@NgModule({
  imports: [CommonModule, FeWebComponentsModule, TranslateModule.forChild()],
  providers: [
    {
      provide: 'API_BASE_URL',
      useFactory: apiAuthBaseUrlFactory,
      deps: [AbstractEnvironmentService],
    },
    {
      provide: 'API_GLOBAL_HEADERS',
      useFactory: apiGlobalHeadersFactory,
      deps: [AbstractEnvironmentService],
    },
    {
      provide: AbstractBackendService,
      useClass: HttpBackendService,
    },
    {
      provide: AbstractAuthService,
      useClass: AuthService,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  declarations: [],
})
export class ModuleAuthModule {}
