import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { ConfigLocalizedData } from '@ibep/fe/shared/data';
import {
  AbstractEnvironmentService,
  AbstractThemeService,
  Config,
  PlatformType,
} from '@ibep/interfaces';
import { hexToRGB } from '@ibep/shared/util';
/**
 * This services handles the IBEP (brand) theming/styling for web applications.
 * Also look at the tailwind.config.js file and ...
 *
 * @export
 * @class ThemeService
 */
@Injectable({
  providedIn: 'root',
})
export class ThemeService implements AbstractThemeService {
  private _renderer: Renderer2;

  /**
   * Only load fetch user preferences from storage when not on server
   */
  private _platform: PlatformType;

  constructor(
    rendererFactory: RendererFactory2,
    private configData: ConfigLocalizedData,
    private readonly _environmentService: AbstractEnvironmentService,
    @Inject(DOCUMENT) private document: Document
  ) {
    // create and initialize the renderer so we can manipulate the DOM
    this._renderer = rendererFactory.createRenderer(null, null);
    this._platform = this._environmentService.platform;

    // get the config data
    this.configData.getConfig().subscribe((config: Config) => {
      if (config.theme) {
        this._loadFonts(config.theme.font);
      }
      // Set the brand ID on the body, this will load the
      // related variables from tailwind.scss file
      this._renderer.addClass(this.document.body, config.brand.id);

      // apply a custom colors theme if it is set up in wordpress
      if (config.theme.colors) {
        this._setCustomColor(
          '--color-primary',
          hexToRGB(config.theme.colors.primary)
        );
        this._setCustomColor(
          '--color-gradient-primary',
          hexToRGB(
            config.theme.colors.gradientFirstColor ||
              config.theme.colors.primary
          )
        );
        this._setCustomColor(
          '--color-gradient-secondary',
          config.theme.colors.gradientSecondColor ||
            this._colorShade(config.theme.colors.primary, -140)
        );
        this._setCustomColor(
          '--color-primary-dark',
          this._colorShade(config.theme.colors.primary, -140)
        );
        this._setCustomColor(
          '--color-secondary',
          hexToRGB(config.theme.colors.secondary)
        );
        this._setCustomColor('--color-cta', config.theme.colors.callToAction);
      }

      // add the favicon
      if (config.brand.website?.faviconUrl && this.document) {
        const linkElement = this.document.createElement('link');
        linkElement.setAttribute('rel', 'icon');
        linkElement.setAttribute('type', 'image/x-icon');
        linkElement.setAttribute('href', config.brand.website.faviconUrl);

        this.document.head.appendChild(linkElement);
      }
    });
  }

  /**
   * Initialize the theme service
   * Called by fe-web-main: AppComponent   *
   * @memberof ThemeService
   */
  public init(): void {}

  /**
   * Dynamically load the brand fonts (defined in wordpress)
   * @param font
   */
  private _loadFonts(font: string): void {
    if (
      font &&
      (this._platform === 'server' ||
        this._environmentService.domain.includes('.local'))
    ) {
      const fontVariants = [
        { name: 'Regular', style: 'font-style:normal;font-weight:normal;' },
        { name: 'Italic', style: 'font-style:italic;font-weight:normal;' },
        { name: 'Bold', style: 'font-style:normal;font-weight:bold;' },
        { name: 'BoldItalic', style: 'font-style:italic;font-weight:bold;' },
      ];
      // capitalize first letter of fontname
      const capFont = font.charAt(0).toUpperCase() + font.slice(1);
      const ttfFont = false; //font === 'poppins' || font === 'titilliumWeb';

      const style: HTMLStyleElement = this.document.createElement('style');
      // apply the font to the html element
      style.innerHTML = `
        html {
          font-family: '${capFont}', sans-serif;
        }
        `;

      const fontCapitalized = font[0].toUpperCase() + font.substring(1);

      fontVariants.forEach((variant) => {
        // add add font-face fot each font variant
        style.innerHTML += ttfFont
          ? `
        @font-face {
          font-family:"${capFont}";
          src: local('${capFont} ${variant.name}'), local('${capFont}-${variant.name}'),
            url('assets/fonts/${font}/${fontCapitalized}-${variant.name}.ttf') format('ttf'),
          font-display: swap;
          ${variant.style}
        }
        `
          : `
        @font-face {
          font-family:"${capFont}";
          src: local('${capFont} ${variant.name}'), local('${capFont}-${variant.name}'),
            url('assets/fonts/${font}/${font}-${variant.name}.woff2') format('woff2'),
            url('assets/fonts/${font}/${font}-${variant.name}.woff') format('woff');
          font-display: swap;
          ${variant.style}
        }
        `;
        // create a preload link element for eacht font
        const link: HTMLLinkElement = this.document.createElement('link');
        link.setAttribute('rel', 'preload');
        link.setAttribute(
          'href',
          `/assets/fonts/${font}/${ttfFont ? fontCapitalized : font}-${
            variant.name
          }.${ttfFont ? 'ttf' : 'woff2'}`
        );
        link.setAttribute('as', 'font');
        link.setAttribute('type', ttfFont ? 'font/ttf' : 'font/woff2');
        link.setAttribute('crossorigin', '');
        this.document.head.appendChild(link);
      });
      this.document.head.appendChild(style);
    }
  }

  /**
   * set a custom color scheme
   * @param colorName
   * @param value
   * @param element
   */
  private _setCustomColor(
    colorName: string,
    value: string,
    element = this.document.body
  ): void {
    this._renderer.setStyle(element, colorName, value, 2);
  }

  /**
   * Create darker or lighter color variant
   *
   * @private
   * @param {*} col  This should be HEX color variable
   * @param {*} amt  for example use -20 or 20
   * @returns
   * @memberof ThemeService
   */
  private _colorShade(col: any, amt: number): string {
    col = col.replace(/^#/, '');
    if (col.length === 3)
      col = col[0] + col[0] + col[1] + col[1] + col[2] + col[2];

    let [r, g, b] = col.match(/.{2}/g);
    [r, g, b] = [
      parseInt(r, 16) + amt,
      parseInt(g, 16) + amt,
      parseInt(b, 16) + amt,
    ];

    r = Math.max(Math.min(255, r), 0).toString(16);
    g = Math.max(Math.min(255, g), 0).toString(16);
    b = Math.max(Math.min(255, b), 0).toString(16);

    const rr = (r.length < 2 ? '0' : '') + r;
    const gg = (g.length < 2 ? '0' : '') + g;
    const bb = (b.length < 2 ? '0' : '') + b;

    return `#${rr}${gg}${bb}`;
  }
}
