<div class="text-center">
  <span>
    <svg
      version="1.1"
      width="77px"
      class="m-auto"
      height="74px"
      viewBox="0 0 77.0 74.0"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <clipPath id="i0">
          <path d="M1440,0 L1440,1024 L0,1024 L0,0 L1440,0 Z"></path>
        </clipPath>
        <clipPath id="i1">
          <path
            d="M36.956,27.316 L38.424,28.216 C34.3470985,31.6326501 31.9944513,36.6807338 32,42 C31.9977073,44.0436744 32.3440061,46.0727691 33.024,48.0000021 L8,48.0000021 C4.90606214,48.0022312 2.08820494,46.2202363 0.764,43.424 L27.044,27.32 L32,30.344 L36.956,27.316 Z M0,10.788 L23.212,24.972 L0,39.204 L0,10.788 Z M64,10.788 L64,30.684 C60.5854993,26.4519055 55.4377759,23.9942352 50,24 C47.2774348,23.9964932 44.5897021,24.6120045 42.14,25.8 L40.788,24.972 L64,10.788 Z M56,0 C59.7326938,0 62.9694756,2.58087475 63.8,6.22 L32,25.656 L0.2,6.22 C1.03052437,2.58087475 4.26730616,0 8,0 L56,0 Z"
          ></path>
        </clipPath>
        <clipPath id="i2">
          <path
            d="M14,0 C21.7319865,0 28,6.2680135 28,14 C28,21.7319865 21.7319865,28 14,28 C6.2680135,28 0,21.7319865 0,14 C0,6.2680135 6.2680135,0 14,0 Z M18.5134743,7.05955432 C17.998846,7.18858647 17.5565757,7.51681431 17.284,7.972 L12.604,15.772 L10.416,13.584 C9.63396479,12.8019648 8.36603521,12.8019648 7.584,13.584 C6.80196479,14.3660352 6.80196479,15.6339648 7.584,16.416 L10.68,19.508 C11.3329891,20.1622605 12.2511286,20.4789651 13.1686181,20.366428 C14.0861076,20.253891 14.9004891,19.7246796 15.376,18.932 L20.716,10.028 C20.9887395,9.57291247 21.0694778,9.02810248 20.9404457,8.51347426 C20.8114135,7.99884604 20.4831857,7.55657567 20.028,7.284 C19.5729125,7.01126048 19.0281025,6.93052217 18.5134743,7.05955432 Z"
          ></path>
        </clipPath>
        <clipPath id="i3">
          <path
            d="M8.15541136,0 C10.0523418,0 11.5901077,1.56700338 11.5901077,3.5 L11.5901077,8.932 L11.6843165,8.928 C12.2947111,8.904 13.1367024,8.896 13.7451343,9.02 C14.2537076,9.13643288 14.7420582,9.33063213 15.1935949,9.596 C15.695148,9.88098016 16.0837403,10.3349436 16.2926977,10.88 L16.5517719,10.856 C17.1641292,10.808 18.1101427,10.78 18.8245596,11.072 C19.5507525,11.368 20.1768486,12.046 20.5517212,12.504 C20.684271,12.6652832 20.8119062,12.8306952 20.9344445,13 L22.0747637,13 C23.1837655,13.0000424 24.2410021,13.4781101 24.9851343,14.3160289 C25.7292665,15.1539477 26.0908498,16.2735208 25.9805041,17.398 L25.4486169,22.828 C25.3527658,23.806076 25.0558451,24.7526171 24.5771853,25.606 L21.8392417,30.488 C21.3153106,31.4226592 20.3401402,31.9996528 19.2838276,32 L6.75209256,32 C5.76778201,32 4.84862764,31.4985898 4.30266338,30.664 L1.49013774,26.364 C1.05490584,25.699352 0.782331054,24.9380915 0.695250865,24.144 L0.0181250119,17.932 C-0.150037047,16.3861818 0.869006769,14.965736 2.36549464,14.66 L4.720715,14.18 L4.720715,3.5 C4.720715,1.56700338 6.25848094,0 8.15541136,0 Z"
          ></path>
        </clipPath>
        <clipPath id="i4">
          <path
            d="M6.19255036,0 C7.00552054,0 7.66456308,0.671572875 7.66456308,1.5 L7.66456308,14 C7.66456308,14.5522847 8.10392478,15 8.6459049,15 C9.18788502,15 9.62724672,14.5522847 9.62724672,14 L9.62724672,8.934 L9.79603751,8.926 C10.4182082,8.902 11.046267,8.91 11.3975874,8.98 C11.660587,9.034 11.9746163,9.172 12.2768696,9.344 C12.4279963,9.428 12.5712722,9.638 12.5712722,9.972 L12.5712722,14 C12.5712722,14.5522847 13.0106339,15 13.552614,15 C14.0945941,15 14.5339558,14.5522847 14.5339558,14 L14.5339558,10.87 C14.6032308,10.8625658 14.6725831,10.8558987 14.7420003,10.85 C15.3622083,10.802 15.8882075,10.83 16.1315803,10.93 C16.3631769,11.022 16.7203854,11.344 17.0854445,11.79 C17.2444219,11.982 17.3798471,12.17 17.4779813,12.308 L17.4779813,15 C17.4779813,15.5522847 17.9173429,16 18.4593231,16 C19.0013032,16 19.4406649,15.5522847 19.4406649,15 L19.4406649,13 L20.1119027,13 C20.6665925,13 21.1953844,13.239162 21.5674842,13.6583499 C21.939584,14.0775378 22.1202419,14.6375972 22.0647729,15.2 L21.5328856,20.63 C21.4641987,21.327819 21.2522182,22.0031084 20.9107149,22.612 L18.1727713,27.496 C17.9981276,27.8075531 17.6730708,28 17.3209666,28 L4.78923156,28 C4.46100394,28 4.15459325,27.8324504 3.97275517,27.554 L1.16022952,23.254 C0.899090384,22.8552112 0.735545512,22.3984549 0.683297398,21.922 L0.0061715446,15.712 C-0.0505632863,15.1960344 0.289635239,14.7216484 0.789282314,14.62 L2.757854,14.22 L2.757854,16 C2.757854,16.5522847 3.1972157,17 3.73919582,17 C4.28117594,17 4.72053763,16.5522847 4.72053763,16 L4.72053763,1.5 C4.72053763,0.671572875 5.37958018,0 6.19255036,0 Z"
          ></path>
        </clipPath>
      </defs>
      <g transform="translate(-688.0 -300.0)">
        <g clip-path="url(#i0)">
          <g transform="translate(397.0 300.0)">
            <g transform="translate(291.0 0.0)">
              <g clip-path="url(#i1)">
                <polygon
                  points="0,0 64,0 64,48.0000042 0,48.0000042 0,0"
                  stroke="none"
                  fill="#879EBB"
                ></polygon>
              </g>
              <g transform="translate(36.0 28.0)">
                <g clip-path="url(#i2)">
                  <polygon
                    points="0,0 28,0 28,28 0,28 0,0"
                    stroke="none"
                    fill="#13D281"
                  ></polygon>
                </g>
              </g>
              <g transform="translate(51.0 42.0)">
                <g clip-path="url(#i3)">
                  <polygon
                    points="0,0 26,0 26,32 0,32 0,0"
                    stroke="none"
                    fill="#000000"
                  ></polygon>
                </g>
                <g transform="translate(1.9628609991468693 2.0)">
                  <g clip-path="url(#i4)">
                    <polygon
                      points="1.21430643e-16,0 22.0746193,0 22.0746193,28 1.21430643e-16,28 1.21430643e-16,0"
                      stroke="none"
                      fill="#FFFFFF"
                    ></polygon>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </span>

  <ng-content></ng-content>

  <a
    (click)="close()"
    [routerLink]="[] | localize"
    [queryParams]="{}"
    class="font-medium text-secondary hover:text-primary"
    >{{ 'CloseThisWindow' | translate }}</a
  >
</div>
