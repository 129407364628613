import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'ibep-call-to-action',
  templateUrl: './call-to-action.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CallToActionComponent {
  @Input() props: {
    headline: string;
    text: string;
    buttonText?: string;
    linkType: string;
    linkInternal?: string;
    linkExternal?: string;
  };
}
