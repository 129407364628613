import { Component, Input } from '@angular/core';
import { WJCustomStyling } from '@ibep/interfaces';
/**
 * https://ubsicap.github.io/usx/master/charstyles.html
 * Words of Jesus.
 * @example: AMP (Amplified Bible) MRK.3 <<<====
 * highlights text in red
 * DOCS: https://github.com/americanbible/scripture-styles/search?q=.wj
 * DOCS: https://github.com/americanbible/scripture-styles/blob/master/dist/css/scripture-styles-nested.css
 * @export
 * @class WjComponent
 */
@Component({
  selector: 'bible-wj',
  template: `<span
    class="wj"
    [style.color]="
      wjCustomStyling?.enabled
        ? wjCustomStyling?.customColor
          ? wjCustomStyling?.customColor
          : wjCustomStyling?.disableRedColor
          ? null
          : '#cc0000'
        : '#cc0000'
    "
    [ngClass]="wjCustomStyling?.enabled ? {
      'font-bold': wjCustomStyling?.enableBold,
      'italic': wjCustomStyling?.enableItalic,
    } : null"
    ><ng-content></ng-content
  ></span>`,
  styles: [],
})
export class WjComponent {
  @Input() wjCustomStyling?: WJCustomStyling;
}
