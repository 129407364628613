<ibep-bible-chapter *ngFor="let bibleContent of bibleContentArray">
  <h3
    *ngIf="showBibleTitle"
    class="text-xl font-bold mt-5 mb-4 text-grayMedium"
  >
    {{ bibleContent[0].title }}
  </h3>
  <ibep-render-bible-content
    [bibleContent]="bibleContent"
    [isBrowser]="isBrowser"
    [bibleAbbr]="props.bibleAbbr"
    [scriptDirection]="scriptDirection"
    [scriptLanguage]="scriptLanguage"
    [readerState]="readerState"
  ></ibep-render-bible-content>
</ibep-bible-chapter>

<div class="mt-2">
  <span class="mr-2 text-grayLight"> {{ label }}</span>
  <span class="mr-3 text-[#C0D0E4]">{{ props.passage.bible_translation }}</span>
  <a [routerLink]="linkToReader | localize">
    <span class="text-secondary hover:text-primary">{{
      'OpenInBible*' | translate
    }}</span></a
  >
</div>
<div class="text-center" *ngIf="!bibleContentArray.length && !error">
  <ibep-page-loading></ibep-page-loading>
</div>
<div class="mb-6" *ngIf="error">{{ 'SomethingWentWrong' | translate }}</div>
