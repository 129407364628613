import { Component, Input } from '@angular/core';
import { InformationSection } from '@ibep/interfaces';

@Component({
  selector: 'ibep-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
})
export class AboutComponent {
  @Input() informationContent: InformationSection;
}
