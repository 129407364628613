import { Component, Input } from '@angular/core';

@Component({
  selector: 'bible-pi',
  template: `
    <p [class]="item.style">
      <ng-content></ng-content>
    </p>
  `,
  styleUrls: ['./pi.component.scss'],
})
export class PiComponent {
  @Input() item: any;
}
