import { Injectable } from '@angular/core';
import { ServiceWorkerService } from '@ibep/fe/web/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
  useClass: ServiceWorkerService,
})
export abstract class AbstractServiceWorkerService {
  public abstract isEnvironmentReady(): Promise<any>;
  public abstract newVersionAvailable$: Observable<boolean>;
  public abstract applicationUpdateOngoing$: Observable<boolean>;
  public abstract applicationOnline$: Observable<boolean>;
  public abstract runningStandAlone: boolean;
}
